@import '../../../constants/_variables.scss';
.new_mainContainers {
	padding: 0px 40px 0px 40px;
	// height: 80vh;
	align-self: flex-start;
	width: 100%;
	.header_content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 40px 0px 24px 0px;
		position: sticky;
		z-index: 1;
		top: 0;
		background: $grayscale-200;
		.new_desgin_new_roles_button {
			display: flex;
			align-items: center;
			gap: 10px;
			.new_desgin_cancel {
				background: $grayscale-200;
			}
		}
	}
	.new_role_bg {
		background: #f7f8fa;
	}
	.new_design_new_roles {
		background: #ffffff;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 16px 0px;
		margin-bottom: 40px;
		.hover_select_all {
			margin: 0px;
			font-family: 'Montserrat-Medium';
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 14px;
			color: $grayscale-900;
			padding: 8px 16px 8px 16px;
			cursor: pointer;
		}
		.new_desgin_chip {
			font-family: 'Montserrat-Medium';
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 14px;
			color: $grayscale-900;
			padding: 8px 16px 8px 16px;
		}
		.userbackground {
			.user {
				padding: 5px 0px 5px 0px;
				display: flex;
				flex-direction: row;
				margin-left: 20px;
				margin-top: 7px;
				.check_image {
					width: 25px;
					height: 25px;
				}
				.user_title {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					margin-left: 10px;
					line-height: 26px;
					color: $primaryColor5;
				}
				.user_description {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					color: $greyColor7;
				}
			}
		}
		.loader-role {
			width: 100px;
			height: 100px;

			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			margin: auto;
		}
		.userbackground1 {
			background-color: #f7f8fa;
			// margin-top: 10px;
			.user {
				padding: 5px 0px 5px 0px;
				display: flex;
				flex-direction: row;
				margin-left: 20px;
				margin-top: 7px;
				.user_title {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					margin-left: 10px;
					line-height: 26px;
					color: $primaryColor5;
				}
				.user_description {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					color: $greyColor7;
				}
			}
		}

		.align_horizontal {
			padding: 0px 0px;
		}
		.toggle_para1 {
			margin-top: 10px;
		}
		.content_list {
			padding: 10px 0px 10px 10px;
			.value {
				color: #00344e;
			}
			.value2 {
				color: #9a9ea6;
			}
			.value3 {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				color: #9a9ea6 !important;
			}
			.value4 {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				color: #484b52 !important;
			}
		}

		.toggle_content1:nth-child(2) {
			.content_list1 {
				margin-left: 30px;
			}
		}
		.content_list1 {
			display: flex;
			align-items: center;
			gap: 16px;
			padding: 16px 32px;
			cursor: pointer;
			.new-design-roles {
				div {
					border-radius: 4px !important;
					height: 14px !important;
				}
			}
			.value {
				color: #00344e;
			}
			.value2 {
				color: #9a9ea6;
			}
			.value3 {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				color: #9a9ea6 !important;
			}
			.value4 {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				color: #484b52 !important;
			}
			&:hover {
				background: $grayscale-200;
			}
		}

		.toggle_content1:nth-child(2) {
			.content_list1 {
				margin-left: 30px;
			}
		}
		.save_change {
			padding: 32px 0px 32px 0px;
			button {
				width: 340px;
				min-width: 340px;
				min-height: 48px;
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				color: $greyColor2;
			}
		}
		.card_action_pannel {
			width: 100%;
			height: 70px;
			border: 1px solid $primaryYellow;
			border-radius: 8px;
			margin-top: 32px;
			display: flex;
			align-items: center;
			.explem {
				width: 35px;
				height: 35px;
				margin-left: 10px;
			}
			.action_approve {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: #484b52;
			}
		}

		.delete_member {
			padding: 0px 0px 0px 0px;
			&,
			.delete-button-class {
				button {
					display: flex;
					justify-content: space-evenly;
					padding: 8px 16px;

					div {
						margin: 0 !important;
						img {
							width: inherit !important;
						}
					}
				}
			}
			button {
				height: 32px;
				min-height: 32px;
				width: 160px;
				padding: 0px 0px;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 16px;
				color: #00344e;
				color: $primaryColor5;
				img {
					height: 20px;
					width: 20px;
				}
			}
		}
		.campany {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
		.switch {
			height: 29px;
			margin-top: 7px;
			width: 50px;
			.ant-switch-handle::before {
				width: 19px;
				height: 19px;
			}
		}
		.button_user {
			width: 35%;
			background: $greyColor2;
			border: 0px;
			color: $greyColor4;
		}
		.remove_member {
			display: none !important;
		}
		.approve_content_list {
			padding-top: 10px;
			margin-top: 20px;
			background-color: #f7f8fa;
			.user {
				padding: 12px 0px 16px 0px;
				display: flex;
				flex-direction: column;
				margin-left: 20px;
				margin-top: 0px;
				.user_title {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 16px;
					color: $primaryColor5;
				}
			}
			.main_content {
				width: 100%;
				.content_list {
					width: 100%;
					margin-top: 1%;
					.horizontal {
						width: 100%;
						height: 0.5px;
						background: $greyColor3;
					}
					.content_detail_toggle {
						padding: 15px 0px 15px 0px;
						.toggle_switch {
							display: flex;
							align-items: center;
						}
						.header {
							span {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 14px;
								line-height: 16px;
								// color: #00344e;
							}
						}
						.detail {
							span {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 12px;
								line-height: 14px;
								color: #484b52;
							}
						}
					}
					.switch {
						height: 30px;
						width: 40%;
						margin-left: 20px;
					}
				}
			}
		}
	}
}
.header_content1 {
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 16px 32px;
	span {
		font-family: Montserrat-Medium;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 18px;
		color: $primaryColor5;
	}
}
.new_design_role {
	.mainmodal {
		.container {
			.image-row {
				margin-bottom: 0px;
			}
			.modal-title {
				text-align: center;
				margin-bottom: 5px;
			}
		}
	}
}
.Team-permission-new-pop {
	.ant-modal-content {
		.ant-modal-body {
			height: 25vh !important;
			.message_container {
				height: 70%;
				margin-top: 10px;
			}
		}
	}
}
@media only screen and (max-width: 600px) {
	.new_mainContainers {
		padding: 0px 15px 0px 14px;
		margin-top: 10vh;
		.save_change {
			display: flex;
			justify-content: center;
			padding: 23px 0px 80px 0px;
		}
	}
}
