@import '../../../constants/_variables.scss';
.forgot_password__container {
	.forgot_password_left_area {
		height: 100vh;
		padding-left: 8%;
		.forgot_password__header {
			span {
				font-size: 28pt;
				line-height: 32pt;
				color: $primaryColor5;
			}
		}
		.forgot_password_logo_row {
			height: 35%;
			.forgot_password_logo {
				padding-top: 4%;
			}
		}
		.forgot_password_form_label {
			padding-top: 7%;
			.ant-form-item {
				margin-bottom: 0;
				padding-bottom: 5%;
				// &.username_form_item {
				// 	input {
				// 		letter-spacing: 1px;
				// 	}
				// 	input::placeholder {
				// 		visibility: hidden;
				// 	}
				// 	input:focus::placeholder {
				// 		visibility: visible !important;
				// 	}
				// }
			}
			.custom_username_input {
				letter-spacing: 1px;
			}
			.forgot_password_submit {
				padding-top: 5%;
			}
		}
		.forgot_password_help {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			a {
				span {
					color: $primaryColor4;
					font-weight: 300;
					line-height: 16pt;
					text-decoration: underline;
					text-underline-position: under;
				}
			}
		}
	}
	.forgot_password_right_area_container {
		height: 100vh;
		.forgot_password_right_area {
			height: 100%;
			.we_brand,
			.are_new {
				background-color: $greyColor1;
				padding: 8% 10px 10px 10px;
				.we_brand_text,
				.are_new_text {
					text-align: right;
					span {
						font-size: 80px;
						font-weight: bolder;
						line-height: 86px;
						color: $secondaryColor1;
					}
				}
				.laptop_img {
					background: none;
					width: 140%;
					margin-left: 40%;
				}
			}
			.are_new {
				background-color: $secondaryColor1;
				position: inherit;
				.are_new_text {
					text-align: left;
					span {
						color: $greyColor1;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.forgot_password__container {
		.forgot_password_left_area {
			padding: 3%;
			.forgot_password__header {
				padding-right: 2.5%;
				span {
					font-size: 16pt;
					line-height: 20pt;
					color: $primaryColor5;
				}
			}
			.forgot_password_logo_row {
				height: 12%;
				.forgot_password_logo {
					padding-top: 4%;
				}
			}
			.forgot_password_form_label {
				padding-top: 4%;
				.forgot_password_submit {
					padding-top: 3%;
				}
			}
		}
		.forgot_password_right_area_container {
			display: none;
		}
	}
}
