@import '../../../constants/variables';
.card-order-status-col-presentational {
	width: 100%;
	height: 100%;
	align-self: flex-start;
	.card_order_status_container {
		padding: 0px 135px 0px 135px;
		.card_order_status_title {
			padding: 0px 0px 8px 0px;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				color: $primaryColor5;
			}
		}
		.card_order_status_description {
			padding: 8px 0px 8px 0px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: $greyColor7;
			}
		}
		.card_order_status_card_section {
			padding: 8px 0px;
			min-height: 500px;
			// overflow: scroll;
			// height: calc(100vh - 70px);

			.card_order_status_card_column {
				padding-left: 16px;
				width: 90%;
				margin: 12px 0px;
				border-radius: 24px;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				&.feedbackWarning {
					background: $feedbackWarning;
				}
				&.feedbackSuccess {
					background: $feedbackSuccess;
				}
				&.feedbackError {
					background: $feedbackError;
				}
				.order_details_card {
					background: $greyColor1;
					box-shadow: none !important;
					border: none !important;
					border-radius: 24px !important;
					padding: 12px;
					cursor: pointer;
					.ant-card-body {
						padding: 8px;
					}
				}
			}
		}
	}
}

//Track Card Order Status Page
.track_card_order_status_container {
	padding: 0px 135px 0px 135px;
	.track_card_order_status_title {
		padding: 0px 0px 8px 0px;
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.track_card_order_status_description {
		padding: 8px 0px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.track_card_order_status_steps_progress_bar {
		padding: 8px 0px 6px 0px;
		.step_container {
			.dot_content_container {
				.date_content {
					padding-left: 0px;
					.date {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 18px;
						color: $primaryColor5;
					}
					.content {
						span {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 12px;
							line-height: 14px;
							color: $greyColor7;
						}
					}
				}
			}
		}
	}
	.track_card_order_status_location {
		display: flex;
		align-items: center;
		padding: 8px 0px;
		img {
			height: 24px;
			width: 24px;
		}
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	> .track_card_order_status_button_section {
		padding: 8px 0px;
		width: 100%;
		.track_card_order_status_view_button {
			padding: 0px 16px 0px 0px;
			button {
				width: 100%;
				min-width: 183px;
				height: 32px;
				min-height: 32px;
				padding: 3px;
				border-radius: 16px;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-size: 14px;
				font-weight: 300;
				line-height: 16px;
			}
		}
		.track_card_order_status_cancel_button {
			padding: 0px 0px 0px 16px;
			button {
				width: 100%;
				min-width: 149px;
				height: 32px;
				min-height: 32px;
				padding: 3px;
				border-radius: 22px;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-size: 14px;
				font-weight: 300;
				line-height: 16px;
				img {
					height: 15px;
					width: 15px;
					margin-right: 6px;
				}
			}
		}
		.track_card_order_status_approve_button {
			padding: 0px 16px 0px 0px;
			button {
				width: 106px;
				min-width: 106px;
				height: 32px;
				min-height: 32px;
				padding: 3px;
				border-radius: 16px;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-size: 14px;
				font-weight: 300;
				line-height: 16px;
			}
		}
		.cancel_order_link_button {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $primaryColor4;
			text-align: center;
			// text-decoration: underline;
			// text-underline-position: under;
			padding: 0px;
			width: auto;
		}
	}
}

.track_card_order_status_container {
	display: flex;
	flex-direction: column;
	padding: 40px;
	height: 100%;
	width: 100%;
	background-color: #f7f8fa;
	.employee-status-card-header-contianer {
		display: flex;
		justify-content: space-between;
		height: fit-content;
		width: 100%;
		.employee-button-container {
			display: flex;
			flex-direction: row;
			gap: 8px;
		}
	}

	.employee-stepper-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 32px;
		gap: 32px;
		margin-top: 28px;
		width: 100%;
		height: fit-content;

		/* Colors/Neutral/neutral-white */

		background: #ffffff;
		/* Shadows/shadow-lg */

		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
	}

	.address-box-container {
		display: flex;
		flex-direction: column;

		padding: 32px;
		gap: 16px;

		width: 100%;
		height: 130px;
		margin-top: 26px;
		/* Colors/Neutral/neutral-white */

		background: #ffffff;
		/* Shadows/shadow-lg */

		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;

		.address-section {
			display: flex;
			flex-direction: row;
			width: 100%;
			height: fit-content;
			align-items: center;
			gap: 16px;
		}
	}
}

//View Card Order Details Page
.view_card_order_details_container {
	padding: 90px 135px 0px 135px;
	width: 100%;
	.download_emp_list {
		display: flex;
		justify-content: space-evenly;
		padding: 8px !important;
		img {
			height: inherit;
			display: none;
		}
	}
	.view_card_order_details_title {
		padding: 0px 0px 25px 0px;
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.view_card_order_details_description {
		padding: 0px 0px 25px 0px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor6;
		}
	}
	.view_card_order_details_search_field {
		.edenRedSearch {
			.search_image {
				height: 24px;
				width: 24px;
			}
		}
	}
	.view_card_order_employee_details_card_section {
		display: flex;
		flex-direction: row;
		flex-flow: wrap;
		padding: 12px 0px;
		.employee_details_card_column {
			padding: 4% 1% 0% 0%;
			.employee_details_card {
				padding: 16px;
				border-radius: 24px;
				height: 98px;
				min-height: 98px;
				.ant-card-body {
					padding: 0px;
				}
			}
		}
	}
	.view_card_order_employee_details_download_button {
		padding: 16px 16px 0px 0px;
		button {
			width: 219px;
			height: 32px;
			min-height: 32px;
			padding: 3px;
			border-radius: 16px;
			font-family: Montserrat-Regular !important;
			font-style: normal;
			font-size: 14px;
			font-weight: 300;
			line-height: 16px;
		}
	}
}

.contentloader {
	position: absolute;
	width: 100%;
	z-index: 1;
	background: rgba(0, 0, 0, 0.1);
	border-radius: 30px;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.card_order_details_container_wrapper {
	// height: 100%;
	padding: 0px 40px 40px 40px;
	padding-bottom: 0;
	.infinite-scroll-component {
		padding: 0px;
	}
	.title {
		padding: 40px 0px 24px 0px;
		position: sticky;
		top: 0px;
		z-index: 1;
		background: #f7f8fa;
		font-family: 'Montserrat-Bold';
		font-weight: 700;
		font-size: 24px;
		color: #00344e;
		margin: 0px;
	}

	.container_wrapper {
		background-color: #ffff;
		border-radius: 8px;
		padding: 32px;
		height: 100%;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		@media screen and (max-width: 870px) {
			padding: 1rem;
		}
	}

	// @media screen and (max-width: $small) {
	//   //do Smth
	// }
	@media screen and (max-width: 870px) {
		padding: 2rem;
	}
}

.card_status_desc_text {
	font-family: 'Montserrat-Light';
	font-weight: 300;
	font-size: 14px;
	color: #484b52;
	margin: 0;
	margin-bottom: 8px;
}
.card_status_wrapper {
	margin-top: 16px;
	background-color: #8ef9b3;
	padding-left: 20px;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	box-shadow: 0px 16px 32px -24px #bdbdbd;
	&:last-child {
		margin-bottom: 12px;
	}
	.status_body_wrapper {
		padding-left: 24px;
		padding-right: 32px;
		padding-top: 24px;
		padding-bottom: 24px;
		background-color: #ffff;
		width: 100%;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 24px;

		.status_body {
			display: flex;
			align-items: center;
			gap: 24px;

			.body_contents {
				h1 {
					margin: 0;
					font-family: 'Montserrat-Medium';
					font-weight: 500;
					font-size: 16px;
					color: #00344e;
				}

				h3 {
					margin: 0;
					margin-bottom: 8px;
					font-family: 'Montserrat-Light';
					font-weight: 300;
					font-size: 14px;
					color: #484b52;
					word-wrap: break-word;
				}

				p {
					margin: 0;
					font-family: 'Montserrat-Medium';
					font-weight: 300;
					font-size: 12px;
					color: #181919;
				}
			}

			@media screen and (max-width: 740px) {
				gap: 10px;
			}
		}

		.navigation_indicator_wrapper {
			margin-left: auto;
			height: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-direction: row;

			p {
				margin: 0;
				padding-bottom: 0.1px;
				border-bottom: 0.2px solid #0e5274;
				font-family: 'Montserrat-Medium';
				font-weight: 500;
				font-size: 14px;
				color: #0e5274;
				cursor: pointer;
			}
			.link-arrow {
				width: 30px;
				height: 25px;
			}

			@media screen and (max-width: 740px) {
				margin: 0;

				div {
					display: none;
				}
			}
		}
	}
	@media screen and (max-width: 740px) {
		padding-left: 10px;
		.status_body_wrapper {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 10px;
		}
	}
}

.view_card_details_title_container_wrapper {
	margin: 40px 40px 24px 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.title_section {
		h1 {
			font-family: 'Montserrat-Bold';
			font-weight: 700;
			font-size: 24px;
			color: #00344e;
		}
		h3 {
			font-family: 'Montserrat-Light';
			font-weight: 300;
			font-size: 12px;
			color: #484b52;
		}
	}

	.buttons_wrapper {
		display: flex;
		align-items: center;
		gap: 24px;
	}
}

.view_card_details_table_wrapper {
	padding: 32px;
	margin: 0 40px 24px 40px;
	background-color: #ffff;
	border-radius: 8px;
	.edenred-table {
		thead {
			tr {
				th {
					padding: 17px 16px;
					background: #ffffff;
				}
			}
		}
		tbody {
			tr {
				.index_3 {
					a {
						text-align: end;
						display: flex;
						align-items: center;
						img {
							width: 20px;
							margin-left: 8px;
						}
					}
				}
			}
		}
	}
	.header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 24px;
		.search-option {
			width: 320px;
		}

		.ant-input-affix-wrapper {
			max-width: 340px;
		}

		.no_of_data_to_show {
			display: flex;
			align-items: center;

			p {
				margin: 0 9px;
				font-family: Montserrat-Light;
				color: #484b52;
				font-size: 12px;
			}
		}
	}
}

.view_card_details_table_cell_data {
	margin: 0px;
	font-family: 'Montserrat-Light';
	font-weight: 300;
	font-size: 12px;
	color: #181919;

	&.navigate_link {
		color: #0e5274;
		cursor: pointer;
		font-family: 'Montserrat-Medium';
		font-weight: 500;
		font-size: 14px;
		text-decoration: underline;
	}
}

.display_none_min_width_600px {
	@media screen and (min-width: 600px) {
		display: none;
	}
}

.card_status_table_pagination_wrapper {
	margin-top: 26px;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font-family: 'Montserrat-Light';
	font-style: normal;
	font-weight: 300;
	font-size: 10px;
	line-height: 12px;
}
