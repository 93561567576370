@import '../../../constants/_variables.scss';

.payroll_timings_container {
	display: flex;
	align-self: flex-start;
	padding: 40px;
	width: 100%;
	.payroll_timings_title {
		padding: 0px 0px 8px 0px;
	}

	.payroll_timings_card_section {
		display: flex;
		flex-direction: Column;
		width: 100%;
		background-color: #ffffff;
		padding: 32px 31px 32px 31px;
		margin-top: 18px;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		.wps-and-nonwps-section {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.payroll_timings_card_title {
				color: $primaryColor5;
			}

			.payroll_timings_wps-nonwps_card {
				display: flex;
				flex-direction: column;
				width: 48%;
				.timing-card-container {
					display: flex;
					flex-direction: column;
					border: 1px solid #eff1f5;
					border-radius: 8px;
					width: 99%;
					margin-top: 16px;
					border-radius: 8px;
					overflow: auto;
					.timing-card-section {
						display: flex;
						flex-direction: column;
						width: 100%;
						.field-section {
							display: flex;
							flex-direction: row;
							border-bottom: 1px solid #eff1f5;

							.heading-color {
								color: #00344e;
							}
							.value-color {
								color: #181919;
							}
							.received-column {
								display: flex;
								flex-direction: row;
								width: 50%;
								height: 48px;
								align-items: center;
								padding: 17px 16px;
							}
							.expected-column {
								display: flex;
								flex-direction: row;
								width: 50%;
								height: 48px;
								align-items: center;
							}
						}
					}
				}
				.payroll_timings_card {
					border: 2px solid $greyColor3;
					border-radius: 24px;
					box-shadow: none;
					padding: 17px;
					margin-top: 10px;
				}
			}
			.payroll_timings_wps-nonwps_card_mobile {
				padding: 4px 0px 4px 12px;
				.payroll_timings_card {
					border: 2px solid $greyColor3;
					border-radius: 24px;
					box-shadow: none;
					padding: 17px;
					margin-top: 10px;
				}
			}
		}
		.payroll_timings_footer {
			display: flex;
			flex-direction: column;
			margin-top: 16px;
			.margin-top-16 {
				margin-top: 16px;
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.payroll_timings_container {
		padding: 64px 4px 4px 16px;
		.payroll_timings_title {
			padding: 4px 0px 4px 0px;
			span {
				font-size: 20px;
				line-height: 24px;
			}
		}
		.payroll_timings_card_section {
			display: flex;
			flex-direction: column;
			padding: 0px 4px 0px 0px;
			height: 40%;
			.payroll_timings_card_title {
				font-size: 16px;
				line-height: 18px;
			}
			.payroll_timings_wps-nonwps_card {
				padding: 8px 4px 4px 0px;
				.payroll_timings_card {
					border: none;
					box-shadow: none;
					border-radius: 24px;
					padding: 0px;
					.ant-card-body {
						padding: 0px !important;
					}
				}
			}
			.payroll_timings_wps-nonwps_card_mobile {
				padding: 4px 4px 4px 0px;
				.payroll_timings_card {
					border: none;
					box-shadow: none;
					border-radius: 24px;
					padding: 0px;
					.ant-card-body {
						padding: 0px !important;
					}
				}
			}
		}
		.payroll_timings_footer {
			padding: 0px 4px 4px 0px;
			span {
				font-size: 12px;
				line-height: 14px;
				color: $greyColor5;
			}
		}
	}
}
