.item_header {
	color: #00344e;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 42px 0px 24px 0px;
	justify-content: space-between;
	.item_header_text {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
}
