.filter-contents {
	position: absolute;
	height: auto;
	background: #ffffff;
	box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
	border-radius: 0 0 24px 24px;
	width: -webkit-fill-available;
	.payroll-plus-dropdownMenus {
		overflow: scroll;
		max-height: 200px;
		.ant-menu-item-selected {
			background-color: unset !important;
		}
	}
	.payroll-plus-new-dropdown-select {
		font-family: 'Montserrat-Medium';
		font-style: normal;
		font-weight: 500 !important;
		font-size: 10px;
		line-height: 12px;
		color: #00344e;
		padding: 10px;
		overflow-y: scroll;
	}
	.payroll-plus-new-dropdown {
		font-family: 'Montserrat-regular';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		color: #00344e;
	}
	@media only screen and (min-device-width: 1200px) and (max-device-width: 1550px) and (orientation: landscape) {
		/* For landscape layouts only */
		.payroll-plus-new-dropdown {
			font-family: 'Montserrat-regular';
			font-style: normal;
			font-weight: 300;
			font-size: 10px;
			line-height: 16px;
			color: #00344e;
		}
	}
}

.max-width-100-percentage {
	max-width: 100%;
}
.tooltip-style {
	.tooltipdown {
		left: -20px;
		width: 260px;
		z-index: 10;
	}
}
.toolTipCheckBox {
	.ant-tooltip-inner {
		width: 250 px;
		font-family: Montserrat-Regular;
		font-weight: 300;
		font-size: 12px;
		line-height: 14px;
		border-radius: 8px;
		background-color: #181919;
		text-align: center;
		padding: 10px;
	}
}
