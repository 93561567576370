.cards_block_container {
	background: #ffffff;
	box-shadow: 0px 16px 32px -24px #bdbdbd;
	border-radius: 8px;
	padding: 18px 16px;
	height: 248px;
	max-height: 248px;
	.cards_block_card_col_1,
	.cards_active_card_col_1 {
		display: grid;
		place-items: center;
		padding-top: 12px;
	}
	.cards_active_card {
		display: grid;
		align-items: center;
		height: 100%;
		.cards_active_text {
			margin-top: 8px;
		}
	}
	.cards_block_card {
		.ant-card-body {
			display: flex;
		}
		.cards_block_card_col_1 {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 5px;
			padding-top: 24px;
			padding-bottom: 24px;
			.card_blocked_title_text {
				margin-top: 8px;
			}
			.card_blocked_desc_text {
				text-align: center;
			}
		}
		.cards_block_card_col_2 {
			.yellow_card {
				.ant-card-body {
					display: grid;
					gap: 5px;
				}
				display: flex;
				align-items: center;
				padding: 12px;
				height: 210px;
				border-radius: 8px;
				border: 1px solid #ffdc91;
				background-color: #ffdc9166;
				.yellow_card_col_1 {
					display: flex;
					gap: 5px;
					align-items: end;
				}
			}
		}
	}
}

.cards_block_loader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}
