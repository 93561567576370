@import '../../../../constants/_variables.scss';

.containers {
	width: inherit;
	display: flex;
	flex-direction: column;
	// justify-content: center;
	align-items: center;
	background-color: $neutral-white;

	.title-box {
		width: inherit;
		padding: 0 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 700;
		font-size: 24px;
		font-family: $font-family-medium;
		color: $blue-500;
		background-color: $white-2;
	}
	.content-wrapper {
		display: flex;
		flex-direction: column;
		gap: 24px;
		width: inherit;
		padding: 0 40px;
		overflow-y: auto;
		max-height: calc(100vh - 168px);
		background-color: $white-2;

		.main-content {
			width: inherit;
			background-color: $neutral-white;
			display: flex;
			align-items: center;
			margin-bottom: 24px;
			border-radius: 8px;
			box-shadow: 0px 16px 32px -24px #bdbdbd;

			.hero-content {
				width: inherit;
				padding: 32px;
				display: flex;
				flex-direction: column;
				gap: 16px;

				.info_tag {
					width: inherit;
					background: rgba(206, 232, 255, 0.25);
					display: flex;
					justify-content: flex-start;
					align-items: center;
					font-size: 14px;
					font-weight: 400;
					padding: 12px 16px;
					gap: 16px;
					border-radius: 8px;
					.rightIcon {
						margin: 0;
					}
				}
				.upload-options-wrapper {
					display: flex;
					width: inherit;
					gap: 16px;
					justify-content: center;
					align-items: center;
					.option-wrapper {
						flex: 1.5;
						display: flex;
						gap: 8px;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						.option-content {
							display: flex;
							padding: 16px;
							gap: 16px;
							align-items: center;
							justify-content: center;
							flex-direction: column;
							background-color: $grayscale-50;
							.icon-container {
								height: 56px;
								box-shadow: 0px 4px 8px #ececec;
							}
							.description-center {
								font-size: 12px;
								text-align: center;
							}
						}
					}
				}
			}
			.steps-wrapper {
				width: inherit;
				padding: 32px;
				display: flex;
				flex-direction: column;
				gap: 16px;
				display: flex;
				align-items: center;
				.step-description-style {
					background-color: $grayscale-50;
					padding: 16px 24px;
					display: flex;
					align-items: center;
					.description {
						align-items: center;
					}
				}
				.step-3-container {
					width: inherit;
					font-size: 12px;
					display: flex;
					flex-direction: column;
					gap: 8px;
				}
			}
		}
	}
}
.error-data-container {
	width: inherit;
}
