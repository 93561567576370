.hint_card {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 8px 14px;
	border-radius: 8px;
	img {
		width: 21px;
	}
}
