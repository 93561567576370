@import '../../../constants/variables';

.view-all-invoices-container {
	padding: 0px 40px 40px 40px;
	.view-all-invoices-header {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: $grayscale-200;
		padding: 40px 0px 28px 0px;
		.go-back-btn {
			width: fit-content;
		}
	}
	width: 100%;
	.view-all-invoices-table {
		display: grid;
		padding: 32px;
		background-color: #ffffff;
		// edenred table style changing
		th {
			background: inherit;
		}
		.table-cell {
			margin: 0px;
		}
		.status-chip {
			text-transform: capitalize;
		}
		.table_row {
			.index_3 {
				display: flex;
				justify-content: end;
			}
		}
		.table_pagination_container {
			display: flex;
			align-items: center;
			justify-content: end;
			.table_pagination {
				margin: 0;
				padding: 0;
			}
		}
		.page-entries-container{
			display: flex;
			justify-content: end;
			.page-entries{
				display: flex;
				align-items: center;
			}
		}
	}
}
