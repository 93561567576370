@import '../../../../constants/_variables.scss';

.content_wrapper {
	display: flex;
	flex-direction: column;
	gap: 32px;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 32px;
	background: $neutral-white;
	box-shadow: 0px 16px 32px -24px $plaincolor;
	border-radius: 8px;

	.info_tag {
		width: 100%;
		background: rgba(206, 232, 255, 0.25);
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 14px;
		font-weight: 400;
		padding: 12px 16px;
		gap: 16px;
		border-radius: 8px;
		.rightIcon {
			margin: 0;
		}
	}
	.list_frame {
		display: flex;
		gap: 16px;
		flex-direction: column;
		width: 100%;
		.list_container {
			width: 100%;
			display: flex;
			gap: 8px;
			flex-wrap: wrap;
			.list_option_box {
				display: block;
				width: max-content;
				height: max-content;
				font-weight: 500;
				// padding: 16px;
				border: 1px solid $grayscale-300;
				background-color: $neutral-white;
				border-radius: 8px;
				.checkbox-class {
					gap: 12px;
				}
			}
			.active {
				border: 1px solid $blue-2;
			}
		}
	}
	.list_option_box {
		display: block;
		width: max-content;
	}
}
