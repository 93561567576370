.setupPermissions-container {
	padding: 0px 140px 0px 140px;
	background-color: #f7f8fa;
	display: flex;
	justify-content: center;

	.assign-permission-container {
		width: 960px;
	}
}

.setup-claims-container {
	display: flex;
	padding: 0px 240px 0px 240px;
	width: 100%;
	// min-width: 960px;
	background-color: #f7f8fa;
	align-items: center;
	justify-content: center;
	.new_mainContainers {
		padding: 0px;
		width: 960px;
	}
}

// style={{ marginTop: '40px', padding: '0px 140px 0px 140px', background: '#F7F8FA' }}
