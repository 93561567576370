// new design text properties
$font-family-medium: 'Montserrat-Medium';
$font-family-light: 'Montserrat-Light';
$font-style-normal: normal;
$font-weight-500: 500;
$font-weight-300: 300;
$font-weight-700: 700;
$font-size-10px: 10px;
$font-size-12px: 12px;
$line-height-12px: 12px;
$line-height-14px: 14px;
//new design colors
$blue-100: #71b0d0;
$blue-200: #4991b6;
$blue-300: #2b769c;
$blue-400: #0e5274;
$blue-500: #00344e;
$blue-light: #2b769c;
$darkblue: #00344e;
$feedback-success: #8ef9b3;
$feedback-error: #ff9691;
$feedback-warning: #ffdc91;
///////////////
$red-50: #fff6f5;
$red-100: #f58178;
$red-200: #f75448;
$red-300: #f72717;
$red-400: #c40e00;
$red-500: #ab0c00;
$blue-gradient-light: linear-gradient(93.76deg, #2b769c 1.98%, #71b0d0 100%);
$blue-gradient-normal: linear-gradient(93.76deg, #0e5274 1.98%, #4991b6 100%);
$blue-gradient-dark: linear-gradient(93.76deg, #00344e 1.98%, #2b769c 100%);
$red-gradient-light: linear-gradient(93.76deg, #f72717 1.98%, #f75448 100%);
$red-gradient-normal: linear-gradient(93.76deg, #d10f00 1.98%, #f72717 100%);
$red-gradient-dark: linear-gradient(93.76deg, #c40e00 1.98%, #f72717 100%);
$grayscale-50: #fbfcfd;
$grayscale-100: #f9fafb;
$grayscale-200: #f7f8fa;
$grayscale-300: #eff1f5;
$grayscale-400: #dfe1e6;
$grayscale-500: #9a9ea6;
$grayscale-600: #737780;
$grayscale-700: #484b52;
$grayscale-800: #313338;
$grayscale-900: #181919;
$gray-light: #eff1f5;
$gray: #bdbdbd;
$black: #1a1a1a;
$grayscale-gradient-light: linear-gradient(93.76deg, #f7f8fa 1.98%, #f9fafb 100%);
$grayscale-gradient-normal: linear-gradient(93.76deg, #9a9ea6 1.98%, #eff1f5 100%);
$grayscale-gradient-dark: linear-gradient(93.76deg, #484b52 1.98%, #9a9ea6 100%);
$neutral-white: #ffffff;
$neutral-black: #000000;
$secondary-blue: #cee8ff;
$secondary-brown: #fef5e8;
$secondary-teal: #ccece6;
$secondary-teal-25: rgba(204, 236, 230, 0.25);
$secondary-purple: #e2d2fe;
$secondary-blue: #ffbecb;
$secondary-gold: #eedcba;
$feedbox-success: #8ef9b3;
$feedbox-warning: #ffdc91;
$feedbox-info: #0e8aff;
$feedbox-error: #ff9691;
$red-color: #d52b1e;
$red-color-2: #ea6257;
$blue: #2b769c;
$blue-2: #71b0d0;
$white: #eff1f5;
$white-2: #f7f8fa;
$plaincolor: #bdbdbd;
$whitecolor: #e5e7eb;
//old design colors
$primaryColor1: #71b0d0;
$primaryColor2: #4991b6;
$primaryColor3: #2b769c;
$primaryColor4: #0e5274;
$primaryColor5: #00344e;
$primaryColor6: #112669;
$primaryColor7: #e3effb;
$secondaryColor1: #ea6257;
$secondaryColor2: #dd483d;
$secondaryColor3: #d52b1e;
$secondaryColor4: #b91f13;
$secondaryColor5: #a51105;
$secondaryColor6: #ff00003d;
$greyColor1: #ffffff;
$greyColor2: #f7f8fa;
$greyColor3: #eff1f5;
$greyColor4: #dfe1e6;
$greyColor5: #9a9ea6;
$greyColor6: #737780;
$greyColor7: #484b52;
$greyColor8: #181919;
$greyColor9: #f8f9f9;
$greyColor10: #626666;
$feedBackRed: #e60a14;
$feedBackYellow: #e4dd3d;
$feedbackError: #ff9691;
$feedbackWarning: #e4dd3d;
$feedbackWarning2: #e4a13d;
$feedbackSuccess: #78be20;
$imageBackground: #e4fad2;
$WarningYellow: #f8f5c0;
$WarningYellow2: #eedcba;
$primaryGradient1: linear-gradient(135deg, #0088ca 0%, #a4d7f1 100%);
$primaryGradient2: linear-gradient(135deg, #006393 0%, #0088ca 100%);
$primaryGradient3: linear-gradient(135deg, #071a57 0%, #006393 98.96%);
$secondaryGradient1: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%);
$secondaryGradient2: linear-gradient(135deg, #a51105 0%, #d52b1e 98.96%);
$greyGradient1: linear-gradient(135deg, #dfe1e6 0%, #f7f8fa 98.96%);
$greyGradient2: linear-gradient(135deg, #9a9ea6 0%, #dfe1e6 98.96%);
$greyGradient3: linear-gradient(135deg, #484b52 0%, #9a9ea6 98.96%);
$black: #000;
$grey: #999;
$primaryYellow: #e5e076;
$greenColor1: #e4fad2;
$greenColor2: #cbf8c0;
$greenColor3: #508909;
$orangeColor1: #f8d4c0;
$pinkColor1: #f8c0d4;
$toast: #f2f8e9;
$landingTextColor: #00344e;
$landingBg: #f7f8fa;
