.banner_card {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 20px;
	background: #fffaf0;
	border: 1px solid #eedcba;
	border-radius: 8px;
	width: 100%;
	.banner_info {
		font-family: 'Montserrat-Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		color: #00344e;
		margin: 0px;
	}
	.banner_container {
		display: flex;
		align-items: center;
		gap: 20px;
		.banner_button {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 8px 20px;
			background: #ffffff;
			box-shadow: 0px 6px 20px -13px rgba(0, 0, 0, 0.5);
			border-radius: 8px;
			font-family: 'Montserrat-Regular';
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: #041243;
			cursor: pointer;
		}
		.banner_image {
			cursor: pointer;
			width: 20px;
		}
	}
}
