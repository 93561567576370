.payroll_run_main_container {
	display: flex;
	flex-direction: row;
	background: #ffffff;
	border-radius: 8px;
	box-shadow: 0px 16px 32px -24px #bdbdbd;
	margin-bottom: 20px;
	padding: 32px;
	overflow: scroll;
	// height: 649px;
	width: 100%;
	&::-webkit-scrollbar {
		width: 3px;
	}
	.dropdown-container {
		width: 50%;
		padding-right: 24px;
		.dropdown-inner-container {
			display: flex;
			flex-direction: column;
		}
	}
	.contant_container {
		width: 100%;
		margin-top: 24px;
		.width_60 {
			width: 50%;
			padding-right: 24px;
		}
		.col_left {
			display: flex;
			flex-direction: row;
			margin-top: 8px;
		}
		.cross_salary_left {
			display: flex;
			flex-direction: row;
			margin-top: 24px;
			border-bottom: 1px solid #eff1f5;
			padding-bottom: 4px;
		}
		.cross_salary_left1 {
			display: flex;
			flex-direction: column;
			margin-top: 24px;
		}
		.payroll_run_cross_salary {
			display: flex;
			flex-direction: column;
			margin-top: 8px;
		}
		.date-validate-picker1 {
			font-family: Montserrat-Light;
			font-style: normal;
			font-weight: 300;
			font-size: 10px;
			line-height: 5px;
			color: #e60a14;
		}

		.salary_text {
			font-family: 'Montserrat-Bold';
			font-size: 24px;
			color: #0e5274;
		}

		.salary_list3 {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: 8px;
			&:nth-child(1) {
				margin-top: 0px;
			}
			.payroll-run-input {
				width: 25%;
				max-width: 130px;
				padding-right: 16px;
			}
			.payrollrun1 {
				background-color: #f7f8fa;
				text-align: end;
			}
		}
		.cross_text {
			font-family: 'Montserrat-Bold';
			font-size: 16px;
			color: #00344e;
		}
		.cross_text1 {
			font-family: 'Montserrat-Bold';
			font-size: 12px;
			color: #00344e;
		}
		.textbox {
			display: flex;
			flex-direction: row;
		}
		.textbox_text {
			display: flex;
			flex-direction: row;
		}
		.salary_list {
			width: 100%;
			display: flex;
			flex-direction: row;
			margin-top: 8px;
			&:nth-child(1) {
				margin-top: 0px;
			}
			.payroll-run-input {
				width: 25%;
				max-width: 130px;
				padding-right: 16px;
			}
		}
		.addition_col {
			display: flex;
			flex-direction: column;
			margin-top: 16px;
		}
		.add_addition {
			width: fit-content;
			display: flex;
			flex-direction: row;
			&:disabled {
				border-color: #dfe1e6 !important;
			}
		}

		.addition_col1 {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: 40px;
			.check_image {
				width: 25px;
				height: 25px;
			}
			.gratutity_checkbox {
				margin: 40px 0px;
			}
			.gratutity_text {
				font-family: 'Montserrat-Light';
				font-size: 14px;
				color: #00344e;
			}
			.payroll_run_profile_check_box {
				background: #8ef9b3;
				border-radius: 4px;
				width: 100%;
				height: 12px;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: 8px;
					height: 8px;
				}
			}
		}
		.addition_col2 {
			display: flex;
			flex-direction: column;
			margin-top: 40px;
		}
		.addition_col3 {
			width: 100%;
			display: flex;
			flex-direction: column;
		}
		.cross_salary_left2 {
			width: 100%;
			display: flex;
			flex-direction: row;
			margin-top: 24px;
			border-bottom-style: solid;
			border-bottom-width: thin;
			border-bottom-color: #dfe1e6;
			padding-bottom: 8px;
		}
		.company_setting {
			font-family: 'Montserrat-Regular';
			font-size: 12px;
			color: #484b52;
			.text_company {
				font-family: 'Montserrat-bold';
				font-size: 12px;
				font-weight: bold;
				line-height: 18px;
				color: #4991b6;
				text-decoration: underline;
				cursor: pointer;
			}
		}
		.salary_list2 {
			width: 100%;
			margin-top: 16px;
			display: flex;
			flex-direction: row;
			align-items: center;
			.input_component_main {
				width: 25%;
				max-width: 130px;
				padding-right: 16px;
			}
		}
		.input_text_part {
			display: flex;
			flex-direction: row;
			margin-top: 8px;

			.dropdown-inner-container {
				display: flex;
				flex-direction: column;
				.ant-select {
					display: flex;
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px !important;
					line-height: 14px;
					letter-spacing: 0.5px;
					height: 30px;
					color: #737780;
					.ant-select-selector {
						display: flex;
						align-items: center;
						height: 30px;
						width: 100%;
						border: 2px solid #dfe1e6;
						box-sizing: border-box;
						border-radius: 10px;
						background-color: #f7f8fa;
					}
					.ant-select-selection-item {
						padding-right: 18px;
						justify-content: space-around;
						margin-right: 65px !important;
					}
					.ant-select-selection-placeholder {
						font-family: Montserrat-Light;
						font-style: normal;
						font-weight: 300;
						font-size: 12px;
						line-height: 14px;
						color: #9a9ea6 !important;
					}
					.ant-select-arrow {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 20px;
						color: #11266900;
						content: url('../../../assets/icons/not_focus/Down.svg');
						height: 20px;
						width: 20px;
						margin-top: -10px;
						margin-right: 0px;
					}
					.ant-select-arrow ::after {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 20px;
						color: #11266900;
						content: url('../../../assets/icons/not_focus/Up.svg');
						height: 20px;
						width: 20px;
						margin-top: -10px;
						margin-right: 0px;
					}
					.ant-select-item {
						font-family: 'Montserrat-Medium';
						font-size: 12pt;
						font-weight: 300;
						background: #ffffff;
					}
					.ant-select-dropdown {
						background: #ffffff;
						box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
						border-radius: 15px !important;
					}
				}
				.ant-select:active {
					background: #ffffff;
					box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
					border-radius: 8px;
					outline: none;
					z-index: 10;
				}
			}
		}
		.input_text_part1 {
			display: flex;
			flex-direction: row;
			margin-top: 8px;
			.dropdown-inner-container {
				display: flex;
				flex-direction: column;
				width: 50%;
				padding-right: 24px;
				.ant-select {
					display: flex;
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px !important;
					line-height: 14px;
					letter-spacing: 0.5px;
					height: 30px;
					color: #737780;
					.ant-select-selector {
						display: flex;
						align-items: center;
						height: 30px;
						width: 100%;
						border: 2px solid #dfe1e6;
						box-sizing: border-box;
						border-radius: 10px;
						background-color: #f7f8fa;
					}
					.ant-select-selection-item {
						padding-right: 18px;
						justify-content: space-around;
						margin-right: 65px !important;
					}
					.ant-select-selection-placeholder {
						font-family: Montserrat-Light;
						font-style: normal;
						font-weight: 300;
						font-size: 12px;
						line-height: 14px;
						color: #9a9ea6 !important;
					}
					.ant-select-arrow {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 20px;
						color: #11266900;
						content: url('../../../assets/icons/not_focus/Down.svg');
						height: 20px;
						width: 20px;
						margin-top: -10px;
						margin-right: 0px;
					}
					.ant-select-arrow ::after {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 20px;
						color: #11266900;
						content: url('../../../assets/icons/not_focus/Up.svg');
						height: 20px;
						width: 20px;
						margin-top: -10px;
						margin-right: 0px;
					}
					.ant-select-item {
						font-family: 'Montserrat-Medium';
						font-size: 12pt;
						font-weight: 300;
						background: #ffffff;
					}
					.ant-select-dropdown {
						background: #ffffff;
						box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
						border-radius: 15px !important;
					}
				}
				.ant-select:active {
					background: #ffffff;
					box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
					border-radius: 8px;
					outline: none;
					z-index: 10;
				}
			}
		}
		.input-text1 {
			width: 208px;
			height: 30px;
			background: #f7f8fa;
			border: 2px solid #dfe1e6;
			box-sizing: border-box;
			border-radius: 8px;
			text-align: unset !important;
		}
		.input-text11 {
			width: 220px;
			height: 30px;
			background: #f7f8fa;
			border: 2px solid #dfe1e6;
			box-sizing: border-box;
			border-radius: 8px;
			text-align: unset !important;
		}
		.input-text2 {
			width: 35px;
			height: 30px;
			background: #f7f8fa;
			border: 2px solid #dfe1e6;
			box-sizing: border-box;
			border-radius: 8px;
		}
		.input-text22 {
			padding-right: 16px;
			width: 25%;
			max-width: 130px;
		}

		.input-text21 {
			padding-right: 16px;
			width: 25%;
			max-width: 130px;
		}

		.dropdown-settings {
			font-family: 'Montserrat';
			font-size: 14px;
			text-align: center;
		}
		.delete_button {
			width: 36px;
			height: 32px;
			background: #f9fafb;
			border-radius: 8px;
			padding: 8px 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
		.delete_button1 {
			width: 36px;
			height: 32px;
			background: #f9fafb;
			border-radius: 8px;
			padding: 8px 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: not-allowed;
			pointer-events: none;
		}
		.delete_image1 {
			opacity: 0.5;
		}
		.ant-input {
			background-color: #f7f8fa;
			text-align: end;
		}
		.ant-input-affix-wrapper {
			height: 30px;
			width: 100px;
			background: #f7f8fa;
			border: 2px solid #dfe1e6;
			box-sizing: border-box;
			border-radius: 10px;
			font-weight: bold;
			span {
				font-family: 'Montserrat-Medium';
				font-weight: 500;
				font-size: 12px;
				color: #00344e;
			}
		}
		.payroll-setting-main-header1 {
			display: flex;
			.payroll-flatpickrs {
				width: 100%;
				.date_single_picker {
					width: 50%;
					padding-right: 24px;
				}
			}
		}
		.dropdown-container1 {
			width: 100%;
			.dropdown-inner-container {
				display: flex;
				flex-direction: column;
				width: 50%;
				padding-top: 8px;
				padding-right: 24px;
				.ant-select {
					display: flex;
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 20px;
					letter-spacing: 0.5px;
					height: 56px;
					width: 100% !important;
					min-height: 52px;
					color: #dfe1e6;

					.ant-select-selector {
						display: flex;
						align-items: center;
						height: 56px;
						width: 100%;
						border: 2px solid #dfe1e6;
						box-sizing: border-box;
						border-radius: 8px;
						background-color: #f7f8fa;
					}

					.ant-select-selection-item {
						padding-right: 18px;
						margin-right: 65px !important;
						align-items: center;
						display: flex;
						color: rgb(72, 75, 82);
					}

					.ant-select-selection-placeholder {
						font-family: Montserrat-Light;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 18px;
						letter-spacing: 0.5px;
						color: #9a9ea6 !important;
					}

					.ant-select-arrow {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 20px;
						color: #11266900;
						content: url('../../../assets/icons/not_focus/Down.svg');
						height: 28px;
						width: 28px;
						margin-top: -15px;
						margin-right: 0px;
					}

					.ant-select-arrow ::after {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 20px;
						color: #11266900;
						content: url('../../../assets/icons/not_focus/Up.svg');
						height: 28px;
						width: 28px;
						margin-top: -15px;
						margin-right: 0px;
					}

					.ant-select-item {
						font-family: 'Montserrat-Medium';
						font-size: 14pt;
						font-weight: 500;
						background: #ffffff;
					}

					.ant-select-dropdown {
						background: #ffffff;
						box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
						border-radius: 15px !important;
					}
				}

				.ant-select:active {
					background: #ffffff;
					box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
					border-radius: 8px;
					outline: none;
					z-index: 10;
				}
			}
		}
	}

	.buttons {
		margin-top: 20px;
		margin-bottom: 30px;

		.button_inner_container {
			display: flex;
			flex-direction: row;
			margin-bottom: 20px;
		}

		.save_button {
			width: 110px;
			height: 48px;
			min-height: unset;
			font-family: 'Montserrat-Bold';
			font-size: 16px;
		}

		.cancle_button {
			width: 110px;
			height: 48px;
			min-height: unset;
			font-family: 'Montserrat-Bold';
			font-size: 16px;
		}
	}
}
