@import '../../../constants/variables';

.status-toast-wrapper {
	min-height: fit-content;
	min-width: 220px;
	max-width: 50%;
	max-height: 70px;
	position: fixed;
	z-index: 5;
	transform: translateX(-50%);
	left: 50%;
	border-radius: 8px;
	padding: 16px;
	transition: bottom 0.7s;

	font-family: 'Montserrat-Light';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	text-align: center;

	&.show {
		bottom: 20px;
	}

	&.hide {
		bottom: -20%;
	}

	&.success {
		background-color: $feedback-success;
		color: $grayscale-700;
	}

	&.warning {
		background-color: $feedback-warning;
		color: $grayscale-700;
	}

	&.error {
		background-color: $feedback-error;
		color: $grayscale-700;
	}

	> P {
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@media only screen and (max-width: 800px) {
	.status-toast-wrapper {
		max-width: 90%;
	}
}
