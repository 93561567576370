.tos_page_outer {
	display: flex;
	flex-direction: column;
	text-align: justify;
	.edenred-logo {
		margin-bottom: 20px;
	}
	.tos_table {
		width: 100%;
		td,
		th {
			border: 1px solid;
			padding: 10px;
		}
		th {
			width: 50%;
		}
		.table-header {
			.empty-header {
				width: 2%;
			}
		}
		.table-body {
			.a-tag {
				color: blue;
				font-size: 14px;
			}
		}
		.text-underline {
			text-decoration: underline;
		}
	}
}
