.payroll_wps_nonwps {
	.mainmodal {
		width: 500px;
		.payroll_wps_nonWps {
			text-align: center;
			.payroll_wps_nonWps_popup {
				display: flex;
				align-items: center;
				gap: 24px;
				margin-top: 24px;
				margin-bottom: 32px;
				.payroll_popup_card1 {
					display: flex;
					align-items: center;
					flex-direction: column;
					text-align: center;
					border: 1px solid #dfe1e6;
					padding: 32px 16px;
					border-radius: 8px;
					cursor: pointer;
					position: relative;
					span:nth-child(2) {
						margin-top: 8px;
					}
					.red_tick {
						position: absolute;
						top: -10px;
						right: -10px;
					}
				}
				.selected {
					background: #fff6f5;
					border: 1px solid #f72717;
				}
				.not_selected {
					background: #ffffff;
					border: 1px solid #dfe1e6;
				}
			}
		}
		.payroll_wps_nonWs_alert {
			padding: 16px;
			background: #fef5e8;
			border-radius: 8px;
			margin-bottom: 24px;
			.link_tag {
				text-decoration: underline;
				color: #2b769c;
				margin: 0px;
			}
		}
		.payroll_wps_nonWps_popup_button {
			display: flex;
			justify-content: center;
			.buttonnew {
				width: 200px;
			}
		}
	}
	.payroll_month_year_select {
		display: flex;
		align-items: center;
		flex-direction: column;
		text-align: center;
		.month_year_dropdown {
			display: flex;
			align-items: center;
			margin-top: 24px;
			gap: 8px;
			.month_dropdown {
				width: 198px;
				.multiSelect-dropdown-container .dropdown_header .dropdown_inner .dropDown-menu {
					flex-wrap: nowrap;
					.dropdown_default {
						margin: 0px;
						padding: 0px;
					}
				}
			}
			.year_dropdown {
				width: 114px;
				.multiSelect-dropdown-container .dropdown_header .dropdown_inner .dropDown-menu {
					flex-wrap: nowrap;
					.dropdown_default {
						margin: 0px;
						padding: 0px;
					}
				}
			}
		}
		.payroll_month_year_button {
			display: flex;
			justify-content: center;
			margin-top: 32px;
			.buttonnew {
				width: 200px;
			}
		}
	}
	.payroll_now_later {
		display: flex;
		align-items: center;
		flex-direction: column;
		text-align: center;
		.payroll_now_later_button {
			display: flex;
			align-items: center;
			gap: 16px;
			margin-top: 24px;
			.buttonnew {
				width: 164px;
			}
		}
	}
	.payroll_select_date {
		display: flex;
		align-items: center;
		flex-direction: column;
		text-align: center;
		.paroll_select_date_dropdown {
			margin-top: 24px;
			width: 100%;
		}
		.payroll_select_date_button {
			display: flex;
			align-items: center;
			gap: 16px;
			margin-top: 24px;
			.buttonnew {
				width: 164px;
			}
		}
	}
}

.highlighted-text {
	font-family: "Montserrat-Bold";
}

@media only screen and (max-width: 400px) {
	.payroll_wps_nonwps {
		.mainmodal {
			width: 340px;
		}
	}
}
