.pending-alerts-conatiner {
	display: flex;
	flex-direction: column;
	padding: 40px;
	align-items: flex-start;
	width: 100%;
	.pending-alerts-col {
		display: flex;
		flex-direction: column;
		width: 100%;
		.pending-alerts-content-text {
			display: flex;
			flex-direction: column;
			width: 100%;
			margin-top: 25px;
			background-color: #ffffff;
			padding: 33px 32px 33px 32px;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;
			.edenredEmployeesCard_main {
				border-radius: 0px;
			}
		}
	}
}
