.row-company-setting {
	width: 100%;
	align-self: flex-start;
	justify-content: center;
	margin-bottom: 40px;

	.company-setting-headerRow {
		position: sticky;
		top: 0;
		z-index: 1;
		background: #f7f8fa;
		justify-content: center;
		width: 100%;
		padding: 0px 40px;
	}

	.company-setting-header {
		width: 100%;
		margin: 42px 0px 26px 0px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 32px;
	}

	.company-setting-title {
		font-family: 'Montserrat-Bold';
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 28px;
		color: #00344e;
	}

	.company-setting-cancelBtn {
		background: #f7f8fa;

		.secondary {
			margin-top: 0px;
		}
	}

	.company-setting-main-container {
		padding: 0px 40px;

		.ant-tabs {
			overflow: visible;
		}

		.ant-tabs-top {
			.ant-tabs-nav {
				background: #ffffff;
				border-radius: 8px;
				padding: 16px 20px 16px 20px;
				margin: 0 0 24px 0;

				.ant-tabs-nav-wrap {
					.ant-tabs-nav-list {
						.ant-tabs-ink-bar {
							display: none;
						}
						.ant-tabs-tab {
							&:nth-child(1) {
								border-left-width: 1px;
							}
						}
						&:last-child {
							.ant-tabs-tab {
								border-radius: 0px 8px 8px 0px;
							}
						}

						.ant-tabs-tab {
							border: none;
							padding: 10px 20px;
							background: #ffffff;
							border-width: 1px 1px 1px 0px;
							border-style: solid;
							border-color: #dfe1e6;
							margin: 0px;

							&:first-child {
								border-radius: 8px 0px 0px 8px;
							}
						}

						.ant-tabs-tab-active {
							border-left: none;
							background: linear-gradient(93.76deg, #d10f00 1.98%, #f72717 100%);
						}
					}
				}
			}
		}

		.ant-tabs-tab-btn {
			font-family: 'Montserrat-Medium';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: #484b52;
		}

		.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
			font-family: 'Montserrat-Medium';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: #ffffff;
		}
	}

	.ant-tabs-top > .ant-tabs-nav::before,
	.ant-tabs-bottom > .ant-tabs-nav::before,
	.ant-tabs-top > div > .ant-tabs-nav::before,
	.ant-tabs-bottom > div > .ant-tabs-nav::before {
		border-bottom: 0px !important;
	}

	.subtext-company {
		color: #484b52;
		font-size: 14px !important;
		font-style: normal;
		font-weight: 300;
		line-height: 16px;
		font-family: 'Montserrat-Light';
	}

	.company-setting-btn1 {
		display: flex;
		justify-content: space-between;
		padding: 0px 10px !important;
		box-shadow: none !important;
		border: 1px solid #dfe1e6 !important;
		box-sizing: border-box;
		border-radius: 22px !important;
		background: #f7f8fa !important;
		font-size: 14px;
		color: #484b52;
		font-family: 'Montserrat-Regular';
		height: 32px !important;
		min-height: unset !important;
		width: fit-content;
	}

	.company-setting-tabpane-row1 {
		width: 100%;
		background: #ffffff;
		padding: 24px;
		border-radius: 8px;

		.overtime-container {
			margin-top: 5px;
			margin-bottom: 5px;
		}

		.overtime-heading {
			font-family: 'Montserrat-Bold';
			font-size: 24px;
			line-height: 28px;
			color: #00344e;
			margin-top: 0px;
			margin-bottom: 12px;
		}
		.button-with-252 {
			width: 252px;
		}
		.button-with-200 {
			width: 200px;
		}
		.company-setting-column1-sub3 {
			.company-setting-addBtn {
				margin-top: 0px;
				font-family: 'Montserrat-Medium';
			}

			.secondary-btn {
				background: transparent;
			}
		}

		.company-setting-column1 {
			padding: 24px;
			background: #f9fafb;
			border-radius: 8px;
			margin-bottom: 24px;
			width: 100%;

			.company-setting-column1-sub1-main {
				margin-bottom: 8px;
			}
			.company-setting-column1-sub2 {
				margin-bottom: 16px;
			}
			.company-setting-column1-sub1 {
				margin-bottom: 16px;
			}

			.company-setting-column1-sub1 {
				display: flex;
				row-gap: 16px !important;
				column-gap: 16px;

				.overtime_policy {
					margin: 18px 0 0px 0;
				}

				.title-color {
					font-family: 'Montserrat-Medium';
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 18px;
					color: #00344e;
				}

				.for-height {
					height: 22px;
					width: 22px;
					cursor: pointer;
				}
			}

			.company-setting-column2-sub2 {
				margin-top: 24px;
				&:nth-child(1) {
					margin-top: 0px;
				}
				.for-brackets {
					position: relative;
					margin-left: 20px;
				}

				.for-curved-bracket {
					position: absolute;
					margin-top: -55px;
					margin-left: -30px;
					height: 132px;
				}

				.salary-per-hour {
					display: flex;
					align-items: center;
					gap: 10px;
					margin-bottom: 0px;
					margin-left: 12px;
					font-family: 'Montserrat-Light';
					font-size: 14px;
					line-height: 16px;
					color: #484b52;

					.hint-padding {
						width: 8px;
					}
				}
			}

			.month-text {
				color: #484b52;
				margin-left: 16px;
			}
		}

		.inc-dec-box {
			display: flex;
			flex-direction: row;
			width: 100px;
			align-items: center;
		}

		.company-setting-column3-sub3 {
			display: flex;
			align-items: center;
		}
	}

	.company-setting-btn:disabled {
		background: #f7f8fa !important;
	}
	.company-setting-column5 {
		display: flex;
		gap: 10px;
		justify-content: flex-end;
		// .buttonnew {
		// 	width: 85px;
		// }
		.secondary {
			margin-top: 0px;
		}

		.company-set-cancel:disabled {
			background: white !important;
			margin-right: 16px;
			color: #484b52 !important;
			border: 2px solid #f7f8fa !important;
			color: #dfe1e6 !important;
		}

		.company-set-save:disabled {
			background: #f7f8fa !important;
			color: #dfe1e6 !important;
		}

		.company-setting-btn {
			width: 106px;
			height: 48px;
			border-radius: 28px;
			background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%) !important;
			color: white !important;
			box-shadow: none !important;
		}

		.company-set-cancel {
			background: white !important;
			margin-right: 16px;
			color: #484b52 !important;
			border: 2px solid #dfe1e6 !important;
			font-family: 'Montserrat-Bold';
			font-size: 16px;
			line-height: 18px;
		}

		.company-set-save {
			font-family: 'Montserrat-Medium';
			font-size: 16px;
			line-height: 18px;
		}
	}

	.company-setting-setup {
		margin: 20px 0 10px 0;

		.company-setting-btn {
			height: 48px;
			border-radius: 28px;
			background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%) !important;
			color: white !important;
			box-shadow: none !important;
		}

		.save_button {
			width: 196px;
			margin-bottom: 15px;

			.company-set-save:disabled {
				background: #f7f8fa !important;
				color: #dfe1e6 !important;
			}

			.company-set-save {
				font-family: 'Montserrat-Medium';
				font-size: 16px;
				line-height: 18px;
			}
		}

		.cancel_button {
			width: 106px;

			.company-set-cancel:disabled {
				background: white !important;
				margin-right: 16px;
				color: #484b52 !important;
				border: 2px solid #f7f8fa !important;
				color: #dfe1e6 !important;
			}

			.company-set-cancel {
				background: white !important;
				margin-right: 16px;
				color: #484b52 !important;
				border: 2px solid #dfe1e6 !important;
				font-family: 'Montserrat-Bold';
				font-size: 16px;
				line-height: 18px;
			}
		}
	}
}

.payroll-policy-sub1 {
	display: flex;
	align-items: center;
	// margin-top: 12px;
	.hour-input {
		width: 68px;
		.form-field__label {
			background-color: #f7f8fa !important;
		}

		.input-box {
			height: 32px;
		}
	}
	.basic-sal-dropdown3 {
		width: 170px;
	}
	.divide-padding {
		display: flex;
		align-self: center;
		justify-content: center;
		margin: 0px 10px;
		img {
			width: 10px;
		}
	}

	.info-box-payroll-run {
		margin-left: 1%;

		.tooltip {
			padding: 4px;

			p {
				margin: 0;
			}

			.tooltip_content {
				font-family: 'Montserrat-Light';
				font-size: 12px;
				line-height: 18px;
				letter-spacing: 0.6px;
				padding-bottom: 15px;
				color: #ffffff;
			}

			.tooltip_eg {
				font-family: 'Montserrat-Light';
				font-size: 11px;
				line-height: 18px;
				letter-spacing: 0.6px;
				color: #ffffff;
			}

			.tooltip_eg1 {
				font-family: 'Montserrat-Light';
				font-size: 11px;
				line-height: 18px;
				letter-spacing: 0.6px;
				padding-bottom: 5px;
				color: #ffffff;
			}

			.type {
				font-family: Montserrat-regular;
				font-size: 12px;
				font-weight: 300;
				line-height: 14px;
				letter-spacing: 0em;
				text-align: center;
			}
		}
		.tooltipup {
			left: 0;
			margin-left: -98px;
			width: 225px;
		}
	}
}

.sal-text-policy1 {
	padding-right: 16px;
}

.company-setting-dragger {
	.image-compoment {
		margin-left: -6px;
		margin-top: -17px;
		width: 316px !important;
		height: 164px !important;
		border-radius: 16px !important;
	}

	.ant-upload-drag {
		border-radius: 16px !important;
	}

	.ant-upload.ant-upload-drag {
		border: 2px dashed #dfe1e6 !important;
		background-color: #f7f8fa !important;
		width: 316px !important;
		height: 164px !important;
	}
}

.basic-sal-dropdown2 {
	display: flex;

	.multiSelect-dropdown-container .dropdown_header .dropdown_inner {
		height: 32px;
	}

	.multiSelect-dropdown-container .dropdown_header .dropdown_inner .dropDown-menu .dropdown_default {
		background: none;
	}

	.ant-select-arrow {
		padding-right: 20px;
	}

	.icons-payroll-run {
		padding: 0px 10px;
	}

	.ant-select-selector {
		background: #f7f8fa !important;
		border: 2px solid #dfe1e6 !important;
		border-radius: 8px !important;
		width: 100% !important;
		height: 56px !important;
		display: flex !important;
		align-items: center !important;
		padding: 0 10px !important;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background: #ffffff !important;
		box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25) !important;
		border-radius: 8px !important;
	}

	.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
	.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
		display: flex;
	}

	.ant-select-single .ant-select-selector::after,
	.ant-select-single .ant-select-selector .ant-select-selection-item::after,
	.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
		width: 30px !important;
	}

	.ant-select-selection-placeholder {
		color: #484b52 !important;
	}

	.ant-select {
		width: 100% !important;
		font-family: 'Montserrat-regular';
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 18px;
		color: #484b52;
	}
}

.basic-sal-dropdown4 {
	display: flex;
	align-items: center;

	.floating-label {
		width: 50%;
		margin-right: 10px;
	}

	.floating-input {
		width: 100%;
		height: 56px;
		display: flex;
		align-items: center;
		font-size: 16px !important;
	}

	.form-field__label {
		background-color: red !important;
		padding-top: 5px;
	}
}

.overtime-text1 {
	font-family: 'Montserrat-Light';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	color: #484b52;
}

.company-setting-column2-sub2 {
	display: flex;
	align-items: center;

	.overtime-container1 {
		width: 30%;
	}
}

.overtime-container2 {
	width: 20%;
}

.render-addition {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	padding: 16px;
	height: 48px;
	background: #eff1f5;
	border-radius: 8px;
	align-items: center;
	cursor: pointer;
	.render-ad-text {
		font-family: 'Montserrat-Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: #313338;
	}
}
.payroll-run-company-modal {
	.duplicate_msg {
		font-family: 'Montserrat-Light';
		font-style: normal;
		font-weight: 300;
		font-size: 10px;
		line-height: 18px;
		color: #e60a14;
		margin: -10px 0 0 0;
	}
	.floating-label {
		margin: 10px 0px !important;
	}

	.ant-modal {
		width: 458px !important;
	}

	.ant-modal-body {
		height: 29vh !important;
	}
}

.request-leave-dropdown-list {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

	.ant-select-item-option {
		display: flex;
		align-items: center;
		padding: 18px 0 !important;
		margin-left: 24px !important;
		margin-right: 10px !important;

		&:last-child {
			border: none !important;
		}
	}

	.ant-select-item-option-content {
		width: 80% !important;
	}

	.ant-select-item-option::before {
		display: inline-block;
		width: 26px;
		height: 26px;
	}

	.ant-select-item-option::after {
		display: inline-block;
		width: 26px;
		height: 26px;
		margin-right: 5px;
		content: '';
		background: url('../../../assets/icons/not_focus/EmptyBox.svg') no-repeat 0 0;
		background-size: 100%;
	}

	.ant-select-item-option-selected::before {
		display: none !important;
	}

	.ant-select-item-option-selected::after {
		display: inline-block;
		width: 26px;
		height: 26px;
		margin-right: 5px;
		content: '';
		background: url('../../../assets/icons/focus_state/Check.svg') no-repeat 0 0;
		background-size: 100%;
	}

	.salary-options {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		::placeholder {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
}

.company-setting-dragger {
	box-sizing: border-box;
	border-radius: 16px;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 16px;
	.main-container-dragger {
		height: unset;
		.dragger {
			height: unset;
		}
	}
	.ant-upload-list-item-info {
		display: none;
		bottom: 46px;
		position: absolute;
		text-align: center;
		width: 90%;
		margin-left: 30px;
	}

	.ant-upload-list-item-error .ant-upload-list-item-name {
		color: black;
	}

	.ant-upload-list-text .ant-upload-list-item-name,
	.ant-upload-list-picture .ant-upload-list-item-name {
		margin-left: 24px;
	}

	.anticon svg {
		color: black;
	}

	.ant-upload-list-text {
		width: 95%;

		.ant-upload-text-icon {
			display: none;
		}
	}

	.attachement-inner-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-left: 5px;
	}
}

.routing_container_disable {
	opacity: 0.5;
	pointer-events: none;
	cursor: not-allowed;
}

.company-setting-column4 {
	margin-bottom: 0px !important;

	.card_error {
		padding-top: 15px;
	}

	.no_image {
		.dragger {
			.dragger-container {
				width: 65%;

				.imageuploadIndicator {
					img {
						position: absolute;
						top: 0;
						width: 65%;
						height: 100%;
						border-radius: 8px;
					}
				}
			}
		}
	}
}

.ant-tooltip {
	max-width: 359px !important;
}

.new_design_overtime {
	padding: 32px !important;

	.company-setting-column1 {
		padding: 0px !important;
		margin-bottom: 0px !important;
		background: none !important;

		.payroll-policy-sub1 {
			&:last-child {
				padding-bottom: 0px !important;
			}
			.company-setting-column1-sub1-head {
				.overtime-amount {
					font-family: 'Montserrat-Medium';
					font-size: 18px;
					line-height: 20px;
					color: #00344e;
					margin-bottom: 8px;
				}

				.directly-enter {
					font-family: 'Montserrat-Regular';
					font-size: 14px;
					line-height: 16px;
					color: #484b52;
					margin-bottom: 0px;
					font-style: normal;
					font-weight: 300;
					margin-bottom: 24px;
				}
			}
		}
		.payroll-policy-sub1-bottom {
			margin-top: 24px;
		}
	}
}

@media screen and (max-width: 912px) {
	.payroll-policy-sub1 .info-box-payroll-run {
		.img {
			width: 23px;
		}
	}
}
