@import '../../../constants/_variables.scss';

.ant-select-dropdown {
	border-radius: 24px;
	.ant-select-item-option {
		display: flex;
		border-bottom: 1.5px solid $greyColor4;
		padding: 8px 12px 8px 12px;
		margin: 0px 12px 0px 12px;
		background: white;
		.ant-select-item-option-content {
			flex: auto;
			overflow: hidden;
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 18px;
			color: $primaryColor5;
		}
	}
	.ant-select-item-option-selected {
		.ant-select-item-option-content {
			font-family: Montserrat-Medium;
		}
	}
	.ant-select-item-option-selected::after {
		display: inline-block;
		width: 24px;
		height: 24px;
		margin-right: 5px;
		content: '';
		background: url('../../../assets/icons/focus_state/Check.svg') no-repeat 0 0;
		background-size: 100%;
	}
}
.ibmEdenRedDropdown {
	&.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		background-color: #fff !important;	
	}
}
.edenRedDropdown {
	&.dropdownfield {
		.ant-select:not(.ant-select-disabled):hover
			.ant-select-focused:not(.ant-select-disabled)
			.ant-select-single:not(.ant-select-customize-input).ant-select-selector {
			border-color: white !important;
			border-right-width: 0px !important;
		}
		.ant-select {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: #484b52;
		}
		.ant-select-selector {
			background-color: #fff;
			border: 2px solid #dfe1e6;
			box-sizing: border-box;
			border-radius: 8px;
			width: 100%;
			height: 48px;
			min-height: 48px;
			padding: 0 11px;
			display: flex;
			align-items: center;
		}
		.ant-select-arrow {
			color: #11266900;
			content: url('../../../assets/icons/not_focus/Down.svg');
			height: 28px;
			width: 28px;
			margin-top: -15px;
		}
	}
}

.form-labels-dropdown {
	font-family: Montserrat-Regular;
	font-style: normal;
	font-weight: 300;
	color: #737780;
	background-color: #ffff;
}

.form-labels-dropdown::after {
	content: ' *';
	color: red;
}
