@import '../../../constants/variables';
.tooltip {
	position: relative;
	display: inline-block;
	cursor: pointer;
	.tooltipup {
		visibility: hidden;
		background-color: $greyColor8;
		color: $neutral-white;
		font-family: 'Montserrat-Light';
		line-height: 14px;
		font-weight: 300;
		text-align: center;
		border-radius: 8px;
		padding: 9px 9px;
		position: absolute;
		z-index: 1;
		bottom: 110%;
		left: 50%;
		margin-left: -60px;
	}

	.tooltipup::after {
		content: '';
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 8px;
		border-style: solid;
		border-color: $black transparent transparent transparent;
	}
	&:hover .tooltipup {
		visibility: visible;
	}

	// Down

	.tooltipdown {
		visibility: hidden;
		background-color: $greyColor8;
		color: $neutral-white;
		font-family: 'Montserrat-Light';
		line-height: 14px;
		font-weight: 300;
		text-align: center;
		border-radius: 8px;
		padding: 9px 9px;
		position: absolute;
		z-index: 1;
		top: 110%;
		left: 50%;
		margin-left: -60px;
	}

	.tooltipdown::after {
		content: '';
		position: absolute;
		bottom: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 8px;
		border-style: solid;
		border-color: transparent transparent $black transparent;
	}

	&:hover .tooltipdown {
		visibility: visible;
	}

	// Right
	.tooltipright {
		visibility: hidden;
		width: 105px;
		background-color: $greyColor8;
		color: $neutral-white;
		font-family: 'Montserrat-Light';
		line-height: 12px;
		font-weight: 300;
		text-align: center;
		border-radius: 8px;
		padding: 10px 10px;
		position: absolute;
		z-index: 1;
		top: 5%;
		left: 100%;
		margin-left: 6px;
	}

	.tooltipright::after {
		content: '';
		position: absolute;
		top: 50%;
		right: 100%;
		margin-top: -8px;
		border-width: 8px;
		border-style: solid;
		border-color: transparent $black transparent transparent;
	}
	&:hover .tooltipright {
		visibility: visible;
	}

	// Left
	.tooltipleft {
		visibility: hidden;
		width: 105px;
		background-color: $greyColor8;
		color: $neutral-white;
		font-family: 'Montserrat-Light';
		line-height: 12px;
		font-weight: 300;
		text-align: center;
		border-radius: 8px;
		padding: 10px 10px;
		position: absolute;
		z-index: 1;
		top: 5%;
		right: 100%;
		margin-right: 6px;
	}

	.tooltipleft::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 100%;
		margin-top: -8px;
		border-width: 8px;
		border-style: solid;
		border-color: transparent transparent transparent $black;
	}
	&:hover .tooltipleft {
		visibility: visible;
	}
}
