@import '../../constants/variables';

.Salary_report_UsefulFiles_container {
	align-self: flex-start;
	padding: 0px 40px 40px 40px;
	width: 100%;

	.UsefulFiles_header {
		position: sticky;
		top: 0%;
		z-index: 1;
		padding: 40px 0px 28px 0px;
		background: $grayscale-200;
	}

	.card_content_list {
		background: $neutral-white;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 33px 0%;

		.UsefulFiles_Card {
			.edenredCard_main {
				padding: 24px 28px;
				background: $neutral-white;
				border: none;

				.edencard_web_view_useful_files {
					justify-content: space-evenly;
					.payrollIcons {
						width: 40px;
						height: 40px;
						background: linear-gradient(93.76deg, #f7f8fa 1.98%, #f9fafb 100%);
						border-radius: 8px;
						display: flex;
						align-items: center;
						justify-content: center;
						// margin-right: 16px;

						img {
							width: 24px;
						}
					}

					.download_icon {
						img {
							width: 24px;
							height: 24px;
						}
					}
					.usefull_file_card {
						gap: 15px;
					}
				}
			}
		}
	}
}
