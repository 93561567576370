.edenRedSearch {
	border: 2px solid #dfe1e6;
	box-sizing: border-box;
	border-radius: 8px;
	&.ant-input-affix-wrapper {
		&:hover {
			border: 2px solid #2b769c !important;
			box-shadow: none;
		}
		&:focus-within,
		&:focus {
			border: 2px solid #2b769c !important;
			box-shadow: none;
		}
	}
}
