@import '../../../../constants/variables';
.step-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;
	.step-title {
		color: $blue-500;
		font-size: 12px;
		font-family: 'Montserrat-Medium';
		font-weight: 500;
		line-height: 14px;
	}
	.description-wrapper {
		display: flex;
		width: 100%;
		padding: 8px 16px;
		border-radius: 8px;
		align-items: center;
		justify-content: space-between;
		.btnStyle {
			width: 175px;
			height: 32px;
		}
		.description {
			display: flex;
			gap: 10px;
			width: 70%;
			color: $blue-500;
			font-size: 12px;
			font-family: 'Montserrat-Medium';
			font-weight: 500;
			line-height: 14px;
			align-items: center;
			.description-icon {
				width: 22px;
				height: 22px;
			}
		}
		.edit-btn-white {
			background-color: white;
		}
	}
}
