.unemployment-insurance-banner {
    &.alert-error-box {
        padding: 26px;
        background: linear-gradient(92.9deg, #FF8C87 -18.88%, #F9CA66 136.12%);
    }
    &.sort-alert-error-box {
        padding: 16px;
        width: 100%;
        background: linear-gradient(92.9deg, #FF8C87 -18.88%, #F9CA66 136.12%);
        display: flex;
        gap: 16px;
        border-radius: 8px;
        .info-content {
            width: 90%;
            h4 {
                color: #00344E;
            }
            p {
                margin: 0;
                font-size: 16px;
                line-height: 18px;
                font-weight: 500;
            }
        }
        .info-action {
            display: flex;
            justify-content: end;
            align-items: center;
            a {
                background-color: #FFFFFF;
                padding: 8px 16px 10px;
                border-radius: 8px;
            }
        }
        h4 {
            font-size: 18px;
            color: #313338;
            font-weight: 700;
            font-family: 'Montserrat-Bold';
            margin-bottom: 6px;
            line-height: 20px;
        }
        .info-icon {
            display: flex;
            align-items: center;
            img {
                width: 42px;
            }
        }
    }
    &.alert-info-box {
        padding: 0;
        background: linear-gradient(273deg, #E1F5FF 8.38%, #9BCDE7 100%);
        box-shadow: 0px 16px 32px -24px #BDBDBD;
        .alert-info-content {
            padding: 26px;
            h4 {
                color: #0E5274;
            }
            .small-desc {
                .item {
                    color: #00344E;
                    align-items: center;
                }
            }
        }
        .info-img {
            padding-left: 50px;
            padding-top: 15px;
            display: flex;
            align-items: end;
        }
    }
    &.sort-alert-info-box {
        padding: 16px;
        width: 100%;
        background: linear-gradient(273deg, #E1F5FF 8.38%, #9BCDE7 100%);;
        display: flex;
        gap: 16px;
        border-radius: 8px;
        .info-content {
            width: 90%;
            h4 {
                color: #00344E;
            }
            p {
                color: #00344E;
                margin: 0;
                font-size: 16px;
                line-height: 18px;
                font-weight: 500;
            }
        }
        .info-action {
            display: flex;
            justify-content: end;
            align-items: center;
            a {
                background-color: #FFFFFF;
                padding: 8px 16px 10px;
                border-radius: 8px;
            }
        }
        h4 {
            font-size: 18px;
            color: #313338;
            font-weight: 700;
            font-family: 'Montserrat-Bold';
            margin-bottom: 6px;
            line-height: 20px;
        }
        .info-icon {
            display: flex;
            align-items: center;
            img {
                width: 42px;
            }
        }
    }
    display: block;
    .move-icon {
        transition: transform 0.4s;
        padding-left: 5px;
    }
    .ref_link {
        position: relative;
        font-family: 'Montserrat-Bold';

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #181919;
            left: 0;
            bottom: 0;
        }
    }
    .info-action {
        min-width: 153px;
        img {
            width: auto;
        }
        &:hover {
            .move-icon {
                transform: translateX(5px);
            }
        }
        &:not(:hover) { 
            .move-icon {
                transform: translateX(0x);
            }
        }
    }
    .info-img {
        min-height: calc(150px - 46px);
        width: 338px;
    }
    .info {
        min-height: calc(150px - 46px);
        width: 338px;
        background-color: #FFF6F5;
        border-radius: 8px;
        padding: 24px 34px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Montserrat-Bold';
        box-shadow: 6px 7px 0px 0px #ED806E;
        small {
            font-size: 18px;
            text-align: center;
            line-height: 20px;
            color: #484B52;
        }
        p {
            font-size: 16px;
            margin-bottom: 8px;
            color: #AB0C00;
            line-height: 18px;
        }
        h2 {
            margin-bottom: 0;
            font-size: 40px;
            background: linear-gradient(135deg, #D52B1E 0%, #EA6257 98.96%);
			background-clip: text;
			-webkit-text-fill-color: transparent;
			-webkit-background-clip: text;
			-moz-background-clip: text;
			-ms-background-clip: text;
            font-weight: 700;
            line-height: 54px;
            padding-bottom: 9px;
        }
    }
    .alert-info-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: 'Montserrat-Regular';
        
        h4 {
            font-size: 18px;
            line-height: 20px;
            padding-bottom: 13px;
            color: #313338;
        }
        h3 {
            font-size: 32px;
            margin-bottom: 6px;
            line-height: 36px;
            color: #00344E;
        }

        h4, h3{
            font-family: 'Montserrat-Bold';
        }
        .small-desc {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 19px;
            display: block;
            .item {
                margin-bottom: 14px;
                display: flex;
                gap: 8px;
                color: #313338;
            }
        }
    }
}
.avoid-fines-heading {
    font-size: 24px;
    font-family: 'Montserrat-Bold';
    font-size: 20px;
    line-height: 24px;
    color: #00344E;
}
.avoid-fines-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.avoid-fines {
    background-color: #FFFFFF;
    border-radius: 8px;
    color: #2B769C;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding: 38px 26px;
    min-height: calc(252px - 64px);
    .avoid-fines-title {
        font-family: 'Montserrat-Bold';
        font-size: 20px;
        line-height: 24px;
        color: #0E5274;
    }
    .avoid-fines-content {
        font-size: 16px;
        line-height: 18px;
    }
    .avoid-info {
        width: 40%;
    }
    .avoid-img {
        width: 60%;
        align-items: center;
        display: flex;
        justify-content: center;
        img {
            width: 100%;
        }
    }
    .avoid-fines-points {
        display: flex;
        .item {
            display: flex;
            align-items: center;
        }
        .circle {
            height: 4px;
            display: inline-block;
            width: 4px;
            border-radius: 50%;
            background-color: #DFE1E6;
            margin: 0 8px;
        } 
    }
}