.help_section_container {
	padding: 0px 40px 0px 40px;
	background: #f7f8fa;
	.help_section_row {
		width: 100%;
		display: block;
		.edenred_about_card {
			background-color: #ffffff;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			padding: 32px;
			border-radius: 8px 8px 0px 0px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.address {
				display: flex;
				gap: 19px;
				.edenred {
					font-family: Montserrat-medium;
					font-size: 16px;
					font-weight: 500;
					line-height: 18px;
					letter-spacing: 0em;
					text-align: left;
					color: #00344e;
					margin-bottom: 4px;
				}
				.single-business {
					font-family: Montserrat-light;
					font-size: 14px;
					font-weight: 300;
					line-height: 16px;
					letter-spacing: 0em;
					text-align: left;
					color: #0e5274;
					margin-bottom: 0px;
				}
				.download {
					font-family: Montserrat-medium;
					font-size: 14px;
					font-weight: 500;
					line-height: 16px;
					letter-spacing: 0em;
					text-align: left;
				}
			}
			.download_buttons {
				display: flex;
				gap: 16px;
			}
		}
	}
	.help_section_contents {
		display: flex;
		flex-direction: column;
		gap: 40px;
	}
}
