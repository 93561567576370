@import '../../../constants/_variables.scss';
.invoice-presentational {
	padding: 0px 40px 40px 40px;
	background: #f7f8fa;
	width: 100%;
	align-self: flex-start;
	display: block;
	.header {
		display: flex;
		align-items: center;
		padding: 42px 0px 24px 0px;
		justify-content: space-between;
		position: sticky;
		top: 0;
		z-index: 1;
		background: $grayscale-200;
		.header_text {
			p {
				font-family: Montserrat-bold;
				font-size: 24px;
				font-weight: 700;
				line-height: 28px;
				letter-spacing: 0em;
				text-align: left;
				color: #00344e;
				margin-bottom: 8px;
			}
			span {
				font-family: Montserrat-light;
				font-size: 14px;
				font-weight: 300;
				line-height: 16px;
				letter-spacing: 0em;
				text-align: left;
				color: #484b52;
			}
		}
		.secondary-btn {
			font-family: 'Montserrat-Medium';
		}
		.buttonnew {
			width: 118px;
			img {
				margin-right: 0px;
			}
		}
	}
	.questions {
		padding: 32px 32px 32px 32px;
		background: #ffffff;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		.accordian-main-contents {
			margin-bottom: 0px;
		}
	}
}
