@import '../../../../constants/variables';
.invite-team-memeber {
	justify-content: center;
	align-self: flex-start;
	padding: 0px 40px;
	.new_desgin_main {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 40px 0px 24px 0px;
		width: 100%;
		.new_design_desc {
			width: 60%;
			.invite-team-title {
				font-family: 'Montserrat-Bold';
				font-style: normal;
				font-weight: 700;
				font-size: 24px;
				line-height: 28px;
				color: $blue-500;
				margin-bottom: 8px;
			}
			.invite-team-desc {
				font-family: 'Montserrat-Regular';
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: #484b52;
				letter-spacing: 0.5px;
				margin: 0px;
			}
		}
		.new_desgin_button {
			display: flex;
			align-items: center;
			gap: 10px;
			.primary-btn {
				&:disabled {
					opacity: 1;
				}
			}
			.new_desgin_cancel,
			.new_desgin_next {
				font-family: 'Montserrat-Medium';
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				cursor: pointer;
				background: $grayscale-200;
			}
			.new_desgin_cancel {
				color: $grayscale-700;
			}
			.new_desgin_next {
				color: $neutral-white;
			}
		}
	}
	.invite-team-employee {
		display: flex;
		align-items: center;
		column-gap: 16px;
		background: #f7f8fa;
		.employee-select {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: $neutral-white;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;
			padding: 16px 32px;
			width: 100%;
			label {
				margin-left: 0%;
				.new-design-roles {
					margin-left: 16px !important;
				}
			}
			.employee-selected-text {
				margin: 0;
				font-family: 'Montserrat-Medium';
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: $blue-500;
			}
			.employee-select-text {
				margin: 0;
				font-family: 'Montserrat-Regular';
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: $grayscale-700;
				padding-top: 2px;
			}
			.new_admin_check_box {
				background-color: rgb(142, 249, 179);
				border-radius: 4px;
				width: 100%;
				height: 14px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.invite-item-field {
		margin-top: 24px;
		background: $neutral-white;
		box-shadow: 0px 16px 32px -24px $plaincolor;
		border-radius: 8px;
		padding: 25px 24px;
		.payroll-setting-dropdown {
			.multiSelect-dropdown-container .dropdown_header .dropdown_inner .dropDown-menu .dropdown_default {
				background: none;
				.dropdown_inner_list_main {
					margin-left: 0px;
				}
			}
		}
		.invite-team-email {
			margin-top: 24px;
		}
	}
	.invite-item-field-new {
		margin-top: 24px;
		background: $neutral-white;
		box-shadow: 0px 16px 32px -24px $plaincolor;
		border-radius: 8px;
		padding: 32px;
		.invite-team-new {
			padding-bottom: 16px;
			&:last-child {
				padding-bottom: 0px;
			}
			.form-field__label {
				color: #737780;
				font-size: 10px !important;
				z-index: 1;
				background-color: #f7f8fa !important;
				padding-top: 3px;
			}
		}
	}
}
@media only screen and (max-width: 600px) {
	.invite-team-memeber {
		margin-top: 10vh;
	}
}
