.new-upload {
	margin-bottom: 7%;
}
.forEmployees {
	margin-top: 5%;
}
.ant-spin-dot {
	color: red !important;
	margin-left: 5px;
}
.spanA {
	.forHere {
		font-family: 'Montserrat-Medium' !important;
		font-weight: 700;
		color: #00344e !important;
	}
}
.topCont {
	.newDragger {
		width: 100% !important;
		height: 238px;
	}
}
.hr-upload {
	.topCont .newDragger {
		height: 300px;
	}
	.ant-upload-list-item-info {
		width: 100% !important;
	}
	.ant-upload-list-text {
		width: 100% !important;
	}
}

.forClickHere {
	color: #00344e;
}
.dragger_card_container {
	.ant-upload-list-text-container,
	.ant-upload-list-picture-container {
		display: flex;
		justify-content: center;
		.ant-upload-list-item {
			width: 95%;
		}
	}
}
.upload-container-body {
	padding: 0px 40px 40px 40px;
	overflow-x: hidden;
	align-self: flex-start;
}
.upload_employees_in_bulk_title {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 24px;
}
.drag-drop-container {
	margin-top: 24px;
	background: #ffffff;
	box-shadow: 0px 16px 32px -24px #bdbdbd;
	border-radius: 8px;
	padding: 32px;
}
.update_container_heading {
	font-family: 'Montserrat-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 20px;
	color: #00344e;
}
.upload_delete_employees_spreadsheet_template_hint {
	padding: 12px 0px;
	height: 48.68px;
	background: #f9fafb;
	border-radius: 8px;
	padding-left: 16px;
	margin-top: 16px;
	span {
		font-family: Montserrat-Regular;
		font-style: normal;
		font-weight: 300;
		font-size: 12px;
		line-height: 14px;
		// color: $greyColor7;
		margin-bottom: 8px;
	}
	.Here-text {
		font-family: 'Montserrat-Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		text-decoration-line: underline;
		width: 95%;
		color: #0e5274;
		cursor: pointer;
		padding-left: 3px;
	}
}
