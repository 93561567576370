.setup-workflow {
	display: flex;
	justify-content: center;
	background: rgb(247, 248, 250);
	// height: 100%;
	height: fit-content;
	min-height: 100%;
	.approval-workflow-main-header {
		padding: 0px;
		width: 960px;
		.approval-workflow-header {
			.company-setting-setup {
				margin: 20px 0 10px 0;
				.save_button,
				.cancel_button {
					.company-setting-btn {
						border-radius: 28px;
						background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%) !important;
						color: white !important;
						box-shadow: none !important;
					}
				}
				.save_button {
					width: 196px;
					margin-bottom: 15px;
					.company-set-save:disabled {
						background: #f7f8fa !important;
						color: #dfe1e6 !important;
					}
					.company-set-save {
						font-family: 'Montserrat-Medium';
						font-size: 16px;
						line-height: 18px;
						width: 198px;
						padding: 10px;
					}
				}
				.cancel_button {
					width: 106px;
					.company-set-cancel:disabled {
						background: white !important;
						margin-right: 16px;
						color: #484b52 !important;
						border: 2px solid #f7f8fa !important;
						color: #dfe1e6 !important;
					}
					.company-set-cancel {
						background: white !important;
						margin-right: 16px;
						color: #484b52 !important;
						border: 2px solid #dfe1e6 !important;
						font-family: 'Montserrat-Regular';
						font-size: 14px;
						line-height: 16px;
						width: 95px;
						padding: 8px;
					}
				}
			}
		}
	}
}
