.header-wrapper {
	width: 100%;
	height: 72px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	padding: 0 40px;
	@media screen and (min-width: 1400px) {
		padding: 0 240px;
	}
	.header {
		display: flex;
		width: inherit;
		justify-content: flex-end;
	}
}
