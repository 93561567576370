.accordian-main-contents {
	margin-bottom: 5%;
	.frequently-asked-heading {
		font-family: 'Montserrat-Bold';
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 36px;
		text-align: center;
		color: #00344e;
		margin-top: 5%;
	}
	.frequently-asked-div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #f7f8fa;
		border-radius: 8px 8px 0px 0px;
		padding: 15px 32px;
	}
	.accordian-questions {
		font-family: 'Montserrat-Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 18px;
		color: #00344e;
		margin: 0px;
	}
	.frequently-asked-answer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #ffffff;
		border-radius: 0px 0px 8px 8px;
		padding: 16px 32px;
		border: 1px solid #f7f8fa;
	}
	.accordian-answer {
		font-family: 'Montserrat-Light';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		color: #737780;
		margin: 0px;
		.accordian_link {
			font-family: 'Montserrat-Medium';
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: #00344e;
			text-decoration: underline;
		}
	}
}
