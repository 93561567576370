.setupEmpDocs {
	.hr-upload {
		padding: 0px;
		.forEmployees {
			margin-top: 2%;
			.hr-upload-inner {
				// padding: 0px;
			}
		}
		.topCont {
			.newDragger {
				height: 238px;
			}
			.whole {
				padding: 0px 0px 25px 0px;
			}
		}
	}
}
.spanAA {
	font-family: 'Montserrat-Regular';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	color: #484b52;
	.forHereNew {
		font-family: 'Montserrat-Bold' !important;
		font-weight: 700;
		color: #0e5274 !important;
		padding-left: 5px;
		padding-right: 5px;
	}
}
.setup-company-popup {
	.ant-modal-content {
		.ant-modal-body {
			height: 25vh !important;
		}
	}
	.main_popup_container {
		.message_container {
			height: 74%;
		}
		.routing_container {
			height: 26%;
		}
	}
}
