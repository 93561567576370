.approve-container {
	width: 100%;
	display: flex;
	justify-self: space-between;
	justify-content: center;
	flex-direction: column;
	align-self: flex-start;
	p {
		margin: 0px;
	}
	.new_upload_preview {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
		.new_preview_entries {
			display: flex;
			align-items: center;
			gap: 10px;
			p {
				font-family: 'Montserrat-Light';
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: #484b52;
			}
			.multi-select-dropdown-component {
				width: 60px;
				.multiSelect-dropdown-container {
					.dropdown_header {
						.dropdown_inner {
							height: 32px;
							position: relative;
							.dropDown-menu {
								margin: 0 0 0 -8px;
								.dropdown_default {
									background: none;
									.dropdown_inner_list_main {
										font-family: 'Montserrat-Medium';
										font-style: normal;
										font-weight: 500;
										font-size: 12px;
										line-height: 14px;
										color: #9a9ea6;
									}
								}
							}
							.dropdown_arrow {
								position: absolute;
								right: 7px;
								.arrow-inner {
									height: 15px;
									width: 15px;
								}
							}
						}
						.dropdown_list {
							margin: 0px;
							.dropdown_inner_list {
								div {
									justify-content: center;
									label {
										padding: 0px !important;
										span {
											div {
												width: 14px !important;
												height: 14px !important;
												border-width: 1px !important;
												border-radius: 4px !important;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.new_preview_search_filter {
			display: flex;
			align-items: center;
			gap: 10px;
			width: 50%;
			.new_design_search {
				width: 100%;
				.input-label-top {
					display: none;
				}
				.input-box {
					height: 32px;
					img {
						margin-right: 5px;
					}
					input {
						&::placeholder {
							font-family: 'Montserrat-Medium';
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							line-height: 14px;
							color: #9a9ea6;
						}
					}
				}
			}
			.new_design_filter {
				display: flex;
				align-items: center;
				gap: 10px;
				border: 1px solid #dfe1e6;
				border-radius: 8px;
				padding: 10px;
				height: 32px;
				p {
					font-family: 'Montserrat-medium';
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #484b52;
				}
			}
		}
	}
	.hr_preview_table {
		.edenred-table {
			thead > tr > th {
				font-family: 'Montserrat-Medium';
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 14px;
				color: #00344e;
				padding-top: 16px;
				padding-bottom: 16px;
			}
			tbody > tr > td {
				padding: 16px 8px;
			}
		}
	}
	.hr-pagination-bar {
		display: flex;
		align-items: center;
		gap: 10px;
		justify-content: flex-end;
		margin-top: 21px;
		.hr-pagination-count {
			font-family: 'Montserrat-Light';
			font-style: normal;
			font-weight: 300;
			font-size: 10px;
			line-height: 12px;
			color: #484b52;
			margin-right: -30px;
		}
		.pagination-container {
			margin: 0px;
		}
	}
}
.approve-container {
	.ant-table-container table > thead > tr:first-child th:first-child {
		justify-content: left;
	}
	.ant-table-container table {
		tbody {
			tr {
				&:last-child {
					border-bottom: none;
				}
			}
		}
	}
}
.approve-inner-div {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 40px 40px 26px 40px;
	position: sticky;
	top: 0;
	z-index: 1;
	background: #f7f8fa;
	.new_design_hr_upload {
		display: flex;
		align-items: center;
		gap: 10px;
		.new_hr_upload_cancel {
			font-family: 'Montserrat-Medium';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: #484b52;
			background: #f7f8fa;
		}
	}
	.button-container {
		margin: 0px;
	}
}
.approve-header-div {
	font-family: 'Montserrat-Bold';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
}
.approve-header {
	font-style: normal;
	font-weight: 700;
	font-size: 24px !important;
	font-family: 'Montserrat-bold' !important;
	line-height: 28px;
	color: #00344e;
}
.approve-header-div p {
	font-family: 'Montserrat-Light';
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 14px;
	color: #484b52;
	margin: 0px;
	padding-top: 8px;
}
.hr_upload_preview {
	margin: 0 40px 40px 40px;
}
.table-container {
	width: 100%;
	background: #ffffff;
	box-shadow: 0px 16px 32px -24px #bdbdbd;
	border-radius: 8px;
	padding: 32px;
}
.ant-table-thead > tr > th {
	color: #00344e;
	font-family: 'Montserrat-Medium' !important;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	text-align: left !important;
	border-bottom: 1px solid #eff1f5;
}
.tables-detail {
	font-family: 'Montserrat-Light';
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 14px;
	color: #181919;
}
.ant-table-column-sorter {
	position: absolute !important;
	right: 0px !important;
}
.ant-progress-outer {
	display: none !important;
}
