@import '../../../constants/_variables.scss';

.main-wrapper {
	width: inherit;
	padding: 0 40px;
	.container_title_box {
		width: inherit;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		font-weight: 700;
		font-size: 24px;
		font-family: $font-family-medium;
		color: $blue-500;
	}
	.hero-content {
		width: inherit;
		display: flex;
		gap: 24px;
		flex-direction: column;
	}
	.image-row {
		display: flex;
		margin-bottom: 8px;
		justify-content: center;
		.image-container {
			width: auto;
			img {
				padding: 14px;
				border-radius: 50%;
				background: #f7f8fa;
			}
		}
	}
}
