@import '../../../../constants/_variables.scss';

.stepper-container {
	width: inherit;
	display: flex;
	gap: 32px;
	.progress-container {
		width: inherit;
		display: flex;
		flex-direction: column;
		gap: 8px;
		cursor: pointer;
		.progress-bar {
			height: 8px;
			border-radius: 8px;
			background: $grayscale-300;
		}
		.active-bar {
			background: $feedback-success;
			opacity: 0.5;
		}
		.completed-bar {
			background: $feedback-success;
			opacity: 1;
		}
		.missing-bar {
			background: #ffdc91;
			opacity: 1;
		}
		.disabled-text {
			color: $grayscale-400;
			font-family: 'Montserrat-Bold';
			font-style: normal;
			font-weight: 700;
		}
		.active-text {
			color: $blue-500;
			font-family: 'Montserrat-Bold';
			font-style: normal;
			font-weight: 700;
		}
	}
}
