@import '../../../constants/variables';
.employee-announcement-wrapper {
	width: 100%;
	height: 100%;
	background: $grayscale-200;
	display: flex;
	flex-direction: column;
	align-items: center;
	.announcement-text-container {
		margin: 0 240px;
	}
}
