.leave-to-approve-web {
	width: 100%;
	align-self: flex-start;
}

.heading-color {
	color: #00344e;
	cursor: pointer;
}

.Unpaid {
	background: rgba(204, 236, 230, 0.25);
}

.Annual {
	background: #fef5e8;
}

.Bereavement {
	background: rgba(255, 220, 145, 0.25);
}

.Paternal {
	background: rgba(206, 232, 255, 0.25);
}

.Sick {
	background: #ffe5e5;
}

.visa-passport-title-color {
	color: #484b52;
}

.visa-passport-date-color {
	color: #9a9ea6;
}

.mob-leave-approve-text1 {
	font-size: 20px !important;
	color: #00344e !important;
}

.text-container-leave-approve1 {
	padding: 10px 0px;
}

.Leave_approve_main_container {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0px 40px 0px 40px;

	.wps_payroll_container_page {
		background-color: #f7f8fa;
		display: flex;
		flex-direction: column;
		width: 100%;
		position: sticky;
		padding: 40px 0px 24px 0px;
		z-index: 1;
		top: 0;
		justify-content: space-between;
		flex-direction: row;
		.buttonnew {
			width: fit-content;
		}
		.page {
			margin-top: 7px;
		}

		.Request_button {
			border: 2px solid #71b0d0;
			width: 145px;
			height: 40px;
			min-height: unset;
			border-radius: 8px;
		}
	}

	.update-and-request-button-container {
		display: flex;
		flex-direction: row;
	}

	.center {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 32px;
		height: 282px;
		background: #ffffff;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;

		.empty_image {
			width: 40px;
			height: 40px;
			position: relative;
		}

		.empty_image1 {
			width: 272px;
			height: 82px;
			position: absolute;
			bottom: 55px;
		}

		.status_request {
			color: #484b52;
			margin-top: 8px;
		}

		.status_new_changes {
			margin-top: 24px;
			gap: 14px;
			display: flex;
			.invite_portal {
				// width: 280px;
			}
			.to_leave {
				// width: 224px;
			}
		}

		.Request_button1 {
			width: 140px;
		}
		.status_new_changes {
			justify-content: center;
			align-items: center;
			gap: 20px;
			margin-top: 25px;
			.invite_portal,
			.to_leave {
				display: flex;
				align-items: center;
				justify-content: center;
				background: #ffffff;
				padding: 8px 16px 8px 10px;
				border-radius: 8px;
				.status_test {
					font-family: 'Montserrat-Medium';
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 20px;
					color: #00344e;
					margin: 0px;
				}
			}
		}
	}

	.card_component {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;

		.each-card {
			width: 49%;
			height: 398px;
			background-color: #ffffff;
			margin-bottom: 24px;
			border-radius: 8px;
			box-shadow: 0px 16px 32px -24px #bdbdbd;

			.card-info-container {
				justify-content: space-between;
				height: 28%;

				.card-name {
					width: 60%;
					height: 100%;
					display: flex;
					flex-direction: column;
					padding: 24px 20px 0px 24px;

					.employeeid-department {
						display: flex;
						flex-direction: column;
						margin-top: 5px;

						.employeeid-department-font {
							color: #484b52;
						}
					}
				}

				.card-status {
					width: 40%;
					height: 100%;
					display: flex;
					justify-content: flex-end;

					.status-container {
						height: 75%;
						width: 100%;
						border-bottom-left-radius: 10px;
						border-top-right-radius: 5px;

						.status-image-container {
							display: flex;
							flex-direction: row;
							padding: 24px 0px 24px 24px;
							align-items: center;

							.status-image {
								height: 40px;
								width: 40px;
							}
						}

						.leave-type-container {
							display: flex;
							flex-direction: column;
							width: 105px;
							height: 30px;
							justify-content: flex-start;
							margin-left: 8px;

							.leave-status-type-font {
								margin-top: 4px;
							}
						}
					}
				}
			}

			.employee-section-container {
				width: 100%;
				display: flex;
				height: 19%;
				padding: 0px 24px 0px 24px;

				.Date-info-container {
					display: flex;
					align-items: center;
					height: 100%;
					padding: 10px 0px 10px 0px;
					width: 100%;
					border-top: #eff1f5 solid;
					border-bottom: #eff1f5 solid;
					border-width: 1.5px;

					.Date-container {
						display: flex;
						height: 36px;
						flex-direction: column;
						padding-right: 10px;
						border-right: #eff1f5 solid;
						border-width: 1.5px;

						.date-view {
							margin-top: 5px;
						}
					}

					.overlapping-Leaves {
						display: flex;
						height: 36px;
						flex-direction: row;
						align-items: center;
						width: 138px;
						justify-content: end;

						.overlapingContainer {
							display: flex;
							flex-direction: row;
							align-items: center;

							.leave-arrow-style {
								height: 14px;
								width: 14px;
								margin-left: 5px;
							}
						}
					}

					.Date-container:nth-child(2) {
						display: flex;
						flex-direction: column;
						padding-right: 15px;
						padding-left: 15px;
						border-right: #eff1f5 solid;
						border-width: 1.5px;

						.date-view {
							margin-top: 5px;
						}
					}

					.Date-container:nth-child(3) {
						display: flex;
						flex-direction: column;
						padding-right: 15px;
						padding-left: 9px;
						border-right: none;
						border-width: none;

						.date-view {
							margin-top: 5px;
						}
					}
				}
			}

			.employee-visa-passport-section-container {
				width: 100%;
				display: flex;
				height: 12%;
				padding: 0px 24px 0px 24px;

				.visa-passport-info-container {
					display: flex;
					padding: 10px 0px 10px 0px;
					width: 100%;
					border-bottom: #eff1f5 solid;
					border-width: 1.5px;
					align-items: center;
					justify-content: space-between;
					gap: 12px;

					.Passport-status-box-style {
						display: flex;
						width: 50%;
						align-items: center;
					}

					.visa-status-box-style {
						display: flex;
						width: 50%;
						align-items: center;
					}
				}
			}

			.notes-and-attachment-container {
				display: flex;
				flex-direction: row;
				height: 26%;
				padding: 15px 24px 0px 24px;

				.notes-container {
					display: flex;
					flex-direction: column;
					width: 50%;
				}

				.attachment-container {
					display: flex;
					flex-direction: column;
					width: 50%;

					.attachment-icon {
						align-items: flex-start;
						display: block;
						height: 21px;
						margin-right: 6px;
					}

					.attachment-text {
						color: #0e5274;
						text-decoration: underline;
						word-break: break-all;
					}
				}
			}

			.employee-leave-status-and-button-container {
				display: flex;
				flex-direction: row;
				width: 100%;
				padding: 0px 24px 0px 24px;

				.approve-status-style {
					display: flex;
					flex-direction: row;
					width: 50%;
					justify-content: flex-start;
					align-items: center;

					.status-style {
						margin-right: 6px;
					}
				}

				.leave-approve-button {
					display: flex;
					flex-direction: row;
					width: 50%;
					align-items: center;
					justify-content: flex-end;

					.reject-button-style {
						padding: 8px, 16px, 8px, 16px;
						border-color: #dfe1e6;
						margin-bottom: 0px;
						line-height: 15px;
						font-family: 'Montserrat-Medium';
						width: 80px;
						&:disabled {
							border: 1px solid #f7f8fa;
						}
					}

					.approve-button-style {
						padding: 8px, 16px, 8px, 16px;
						margin-bottom: 0px;
						margin-left: 10px;
						line-height: 15px;
						width: 92px;
						&:disabled {
							color: #eff1f5;
							background: #f7f8fa;
						}
					}
				}
			}
		}

		.card {
			width: 100%;
			height: auto;
			background: #ffffff;
			box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
			border-radius: 16px;
		}

		.card_sick {
			width: 100%;
			height: auto;
			background: #ffffff;
			box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
			border-radius: 16px;
		}

		.name-card {
			width: 100%;
			height: auto;
			border-top-left-radius: 16px;
			border-top-right-radius: 16px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		.name-width {
			display: flex;
			flex-direction: column;
			width: 450px;
			border-bottom-style: solid;
			border-bottom-width: thin;
			border-bottom-color: #eff1f5;
			margin-left: 20px;
		}

		.name {
			font-family: montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			line-height: 20px;
			color: '#0E5274';
			margin-top: 20px;
			cursor: pointer;
		}

		.des {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
			margin-top: 7px;
			margin-bottom: 10px;
			color: #626666;
			opacity: 0.6;
		}

		.Annual_leave {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 400px;
			height: auto;
			background-color: #fff7e8;
			border-top-right-radius: 16px;
			border-bottom-left-radius: 16px;
			position: relative;
			border-top-left-radius: 0;

			.leave_Type_row {
				display: flex;
				flex-direction: column;
				margin-left: 5px;
				margin-top: 10px;
				margin-bottom: 10px;
			}
		}

		.Annual_leave::before {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: -7px;
			height: 20px;
			width: 20px;
			background: #fff7e8;
			border-bottom-right-radius: 15px;
		}

		.Annual_leave::after {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: -10px;
			width: 10px;
			height: 20px;
			background: white;
			border-top-right-radius: 10px;
		}

		.Sick_leave {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 400px;
			height: auto;
			background-color: #ffe5e5;
			border-top-right-radius: 16px;
			border-bottom-left-radius: 16px;
			position: relative;
			border-top-left-radius: 0;

			.leave_Type_row {
				display: flex;
				flex-direction: column;
				margin-left: 5px;
				margin-top: 10px;
				margin-bottom: 10px;
			}
		}

		.Sick_leave::before {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: -7px;
			height: 20px;
			width: 20px;
			background: #ffe5e5;
			border-bottom-right-radius: 15px;
		}

		.Sick_leave::after {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: -10px;
			width: 10px;
			height: 20px;
			background: white;
			border-top-right-radius: 10px;
		}

		.Unpaid_leave {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 400px;
			height: auto;
			background-color: #e5fff6;
			border-top-right-radius: 16px;
			border-bottom-left-radius: 16px;
			position: relative;
			border-top-left-radius: 0;

			.leave_Type_row {
				display: flex;
				flex-direction: column;
				margin-left: 5px;
			}
		}

		.Unpaid_leave::before {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: -7px;
			height: 20px;
			width: 20px;
			background: #e5fff6;
			border-bottom-right-radius: 15px;
		}

		.Unpaid_leave::after {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: -10px;
			width: 10px;
			height: 20px;
			background: white;
			border-top-right-radius: 10px;
		}

		.Maternity_leave {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 400px;
			height: auto;
			background-color: #fee5ff;
			border-top-right-radius: 16px;
			border-bottom-left-radius: 16px;
			position: relative;
			border-top-left-radius: 0;

			.leave_Type_row {
				display: flex;
				flex-direction: column;
				margin-left: 5px;
				margin-top: 10px;
				margin-bottom: 10px;
			}
		}

		.Maternity_leave::before {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: -7px;
			height: 20px;
			width: 20px;
			background: #fee5ff;
			border-bottom-right-radius: 15px;
		}

		.Maternity_leave::after {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: -10px;
			width: 10px;
			height: 20px;
			background: white;
			border-top-right-radius: 10px;
		}

		.Paternal_leave {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 400px;
			height: auto;
			background-color: #e5ebff;
			border-top-right-radius: 16px;
			border-bottom-left-radius: 16px;
			position: relative;
			border-top-left-radius: 0;

			.leave_Type_row {
				display: flex;
				flex-direction: column;
				margin-left: 5px;
				margin-top: 10px;
				margin-bottom: 10px;
			}
		}

		.Paternal_leave::before {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: -7px;
			height: 20px;
			width: 20px;
			background: #e5ebff;
			border-bottom-right-radius: 15px;
		}

		.Paternal_leave::after {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: -10px;
			width: 10px;
			height: 20px;
			background: white;
			border-top-right-radius: 10px;
		}

		.Hajj_leave {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 400px;
			height: auto;
			background-color: #fff4d4;
			border-top-right-radius: 16px;
			border-bottom-left-radius: 16px;
			position: relative;
			border-top-left-radius: 0;

			.leave_Type_row {
				display: flex;
				flex-direction: column;
				margin-left: 5px;
				margin-top: 10px;
				margin-bottom: 10px;
			}
		}

		.Hajj_leave::before {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: -7px;
			height: 20px;
			width: 20px;
			background: #fff4d4;
			border-bottom-right-radius: 15px;
		}

		.Hajj_leave::after {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: -10px;
			width: 10px;
			height: 20px;
			background: white;
			border-top-right-radius: 10px;
		}

		.Umrah_leave {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 400px;
			height: auto;
			background-color: #ececec;
			border-top-right-radius: 16px;
			border-bottom-left-radius: 16px;
			position: relative;
			border-top-left-radius: 0;

			.leave_Type_row {
				display: flex;
				flex-direction: column;
				margin-left: 5px;
			}
		}

		.Umrah_leave::before {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: -7px;
			height: 20px;
			width: 20px;
			background: #ececec;
			border-bottom-right-radius: 15px;
		}

		.Umrah_leave::after {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: -10px;
			width: 10px;
			height: 20px;
			background: white;
			border-top-right-radius: 10px;
		}

		.Bereavement_leave {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 400px;
			height: auto;
			background-color: #f0e5ff;
			border-top-right-radius: 16px;
			border-bottom-left-radius: 16px;
			position: relative;
			border-top-left-radius: 0;

			.leave_Type_row {
				display: flex;
				flex-direction: column;
				margin-left: 5px;
			}
		}

		.Compassionate_leave::before {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: -7px;
			height: 20px;
			width: 20px;
			background: #f0e5ff;
			border-bottom-right-radius: 15px;
		}

		.Bereavement_leave::after {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: -10px;
			width: 10px;
			height: 20px;
			background: white;
			border-top-right-radius: 10px;
		}

		.Compensatory_leave {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 400px;
			height: auto;
			background-color: #f0ffe5;
			border-top-right-radius: 16px;
			border-bottom-left-radius: 16px;
			position: relative;
			border-top-left-radius: 0;

			.leave_Type_row {
				display: flex;
				flex-direction: column;
				margin-left: 5px;
				margin-top: 10px;
				margin-bottom: 10px;
			}
		}

		.Compensatory_leave::before {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: -7px;
			height: 20px;
			width: 20px;
			background: #f0ffe5;
			border-bottom-right-radius: 15px;
		}

		.Compensatory_leave::after {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: -10px;
			width: 10px;
			height: 20px;
			background: white;
			border-top-right-radius: 10px;
		}

		.image {
			margin-left: 20px;
			margin-right: 10px;
		}

		.leave-type {
			font-style: normal !important;
			font-weight: bold !important;
			font-size: 14px;
			color: #00344e;
		}

		.balance {
			font-family: 'Montserrat-Regular';
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
			margin-top: 7px;
			color: #484b52;
		}

		.con {
			width: 100%;
			height: auto;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			border-bottom-style: solid;
			border-bottom-width: thin;
			border-bottom-color: #eff1f5;
			margin-left: 20px;
			margin-right: 20px;
		}

		.Sick {
			width: 100%;
			height: auto;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			border-bottom-style: solid;
			border-bottom-width: thin;
			border-bottom-color: #eff1f5;
			margin-left: 20px;
		}

		.details {
			display: flex;
			flex-direction: column;
			width: 430px;
			margin-bottom: 10px;
			margin-top: 10px;
		}

		.overlapping {
			display: flex;
			flex-direction: column;
			width: 320px;
			margin-top: 20px;
			margin-bottom: 10px;
		}

		.passport {
			display: flex;
			flex-direction: row;
			gap: 4px;
			width: 48%;
		}

		.flexRow {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: 4px;
			padding-right: 8px;
			img {
				margin-top: 0px;
			}

			span {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.image1 {
			width: 16px;
			height: 16px;
		}

		.pass_text {
			display: flex;
			flex-direction: row;
		}

		.text1 {
			font-family: 'Montserrat-Medium';
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 14px;
			margin: 0px;
			color: #00344e !important;

			.text1_pass {
				font-family: 'Montserrat-Regular';
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				margin-top: 6px;
				color: #00344e;
			}
		}

		.text2 {
			color: #484b52;
			word-break: break-all;
			// text-overflow: ellipsis;
			// overflow: hidden;
			// white-space: nowrap;
		}

		.text21 {
			font-family: 'Montserrat-regular';
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
			letter-spacing: 0.4;
			margin-top: 4px;
			color: #484b52;
			opacity: 0.3;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.image11 {
			filter: grayscale(20);
			opacity: 0.2;
		}

		.image3 {
			margin-left: 5px;
		}

		.text3 {
			font-family: 'Montserrat-Regular';
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
			margin-top: 3px;
			margin-left: 13px;
			color: #00344e;
			text-decoration: underline;
			word-break: break-all;
		}

		.text31 {
			font-family: 'Montserrat-Regular';
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
			margin-top: 3px;
			color: #00344e;
			opacity: 0.3;
		}

		.text4 {
			font-size: 12px !important;
			color: #00344e !important;
		}

		.text5 {
			margin-top: 5px;
			font-family: 'Montserrat-Regular';
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
			color: #2b769c;
			text-decoration: underline;
		}

		.status {
			width: 100%;
			height: 75px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		.status-approve {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			margin-left: 20px;
		}

		.status_approve_button {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			.Reject_button {
				width: 90px;
				height: 32px;
				margin-right: 45px;
				font-size: 14px;
				font-family: 'Montserrat-Regular';
				min-height: unset;
			}

			.Reject_button:disabled {
				color: #dfe1e6 !important;
				background-color: transparent !important;
			}

			.Approve_button {
				color: #dfe1e6 !important;
			}

			.Approve_button {
				width: 100px;
				height: 32px;
				font-family: 'Montserrat-Regular';
				font-size: 14px;
				margin-left: 10px;
				margin-right: 10px;
				min-height: unset;
			}
		}

		.text6 {
			font-family: 'Montserrat-Bold';
			font-style: normal;
			font-weight: bold;
			font-size: 12px;
			line-height: 14px;
			color: #00344e;
		}

		.text7 {
			font-family: 'Montserrat-Regular';
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
			color: #484b52;
			margin-left: 5px;
		}

		.request-leave-cancel-popup {
			width: 500px !important;
			height: 132px !important;
		}
	}
}
.free_trail_first_slide {
	.carousel_main_tour {
		.slider_main {
			.image_slider {
				width: 93%;
				height: 290px;
			}
		}
	}
}
@media screen and (min-width: 800px) {
	.main-mob-parent-popup {
		display: none;
	}

	.leave-to-approve-mobile {
		display: none !important;
	}
}

@media screen and (min-width: 600px) {
	.Leave_approve_main_container {
		.icon {
			display: none !important;
		}
	}
}

@media screen and (max-width: 800px) {
	.leave-approve-outer-web {
		display: none;
	}

	.request-leave-cancel-popup1 {
		display: none !important;

		.ant-modal-body,
		.ant-modal-wrap,
		.ant-modal-centered,
		.ant-modal-mask {
			display: none !important;
		}

		.main_popup_container {
			display: none;
		}
	}

	.leave-appove-mob-popup,
	.mobile-leave-approve-popup {
		display: none !important;
	}

	.mobile-leave-approve-popup {
		display: none !important;
	}

	.cancel-button-leaveapprove {
		background: #ffff !important;
		border: 2px solid #dfe1e6 !important;
		color: #484b52 !important;
		box-shadow: none !important;
		font-family: 'Montserrat-Medium' !important;
	}

	.accept-button-leaveapprove {
		color: #ffff !important;
		background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%);
		box-shadow: none !important;
		font-family: 'Montserrat-Medium' !important;
	}

	.leave-to-approve-web {
		display: none !important;
	}

	.icon-centered-leaveap {
		display: flex;
		justify-content: center;
		padding: 0px 0px;
		margin-top: 10px;
	}

	.icon-centered-leaveap1 {
		display: flex;
		justify-content: flex-end;
		margin-right: 20px;
		padding: 0px 0px;
		margin-top: 10px;
	}

	.mob-btn-leave-ap {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		width: 100%;
		margin-bottom: 8px;
	}

	.message-leave-mob {
		padding: 20px 0px;
		height: 70px;
		margin-left: 15px;
	}

	.message-leave-mob1 {
		padding: 20px 0px;
		height: 100px;
		margin-left: 15px;
	}

	.main-mob-parent-popup {
		padding: 10px;
	}

	.main-mob-parent-popup1 {
		background-color: white;
		position: absolute;
		bottom: 60px;
		box-shadow: 0px -10px 10px rgba(174, 182, 183, 0.5);
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		width: 100%;
	}

	.wps_payroll_container_page {
		.page {
			margin-top: 30px;

			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: #00344e;
				display: none;
			}
		}

		.update-and-request-button-container {
			.secondary-btn {
				font-family: 'Montserrat-medium';
			}
		}

		.Request_button {
			display: none !important;
		}
	}

	.center {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;

		.empty_image {
			width: 40px;
			height: 40px;
			position: relative;
			margin-top: 30px;
		}

		.text {
			display: flex !important;
			align-items: center !important;
			justify-content: center !important;
			font-family: 'Montserrat-Regular';
			text-align: center !important;
			font-size: 16px;
		}
		.Request_button1 {
			width: 140px;
		}
		.status_new_changes {
			justify-content: center;
			align-items: center;
			gap: 20px;
			margin-top: 20px;
			.invite_portal,
			.to_leave {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 15px;
				background: #ffffff;
				padding: 8px 16px 8px 10px;
				border-radius: 8px;
				margin-top: 10px;
				cursor: pointer !important;
				.status_test {
					font-family: 'Montserrat-Medium';
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 20px;
					color: #00344e;
					margin: 0px;
				}
			}
		}
	}

	.main-mob-leave-approve {
		padding: 30px 20px 70px 20px;
		height: 114vh;
	}

	.main-inner-card {
		border-radius: 16px;
		width: 100%;
		box-shadow: 0px 5px 10px 0px #aeb6b740;

		.sub-inner-card {
			width: 100%;
			border-radius: 16px;

			.mob-secound-text-con {
				display: flex;
				justify-content: center;
				flex-direction: column;
				padding: 10px;
			}
		}

		.temp-class {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #b1e98a;
			border-radius: 16px;
			border-bottom-right-radius: 0px;
			border-top-left-radius: 0px;
			height: 64px;
		}

		.icon-container-leave-Compensatory {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #f0ffe5;
			border-radius: 16px;
			border-bottom-right-radius: 0px;
			border-top-left-radius: 0px;
			height: 64px;
		}

		.icon-container-leave-Compassionate {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #f0e5ff;
			border-radius: 16px;
			border-bottom-right-radius: 0px;
			border-top-left-radius: 0px;
			height: 64px;
		}

		.icon-container-leave-Umrah {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #ececec;
			border-radius: 16px;
			border-bottom-right-radius: 0px;
			border-top-left-radius: 0px;
			height: 64px;
		}

		.icon-container-leave-Annual {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #fff7e8;
			border-radius: 16px;
			border-bottom-right-radius: 0px;
			border-top-left-radius: 0px;
			height: 64px;
		}

		.icon-container-leave-Hajj {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #fff4d4;
			border-radius: 16px;
			border-bottom-right-radius: 0px;
			border-top-left-radius: 0px;
			height: 64px;
		}

		.icon-container-leave-Paternal {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #e5ebff;
			border-radius: 16px;
			border-bottom-right-radius: 0px;
			border-top-left-radius: 0px;
			height: 64px;
		}

		.icon-container-leave-Maternity {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #fee5ff;
			border-radius: 16px;
			border-bottom-right-radius: 0px;
			border-top-left-radius: 0px;
			height: 64px;
		}

		.icon-container-leave-Unpaid {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #e5fff6;
			border-radius: 16px;
			border-bottom-right-radius: 0px;
			border-top-left-radius: 0px;
			height: 64px;
		}

		.icon-container-leave-Sick {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #ffe5e5;
			border-radius: 16px;
			border-bottom-right-radius: 0px;
			border-top-left-radius: 0px;
			height: 64px;
		}
	}

	.down-arrow-mob {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.name-leave-mob {
		font-family: 'Montserrat';
		font-size: 16px !important;
		font-weight: 700 !important;
		color: #00344e;
	}

	.name-leave-mob-sub {
		font-family: 'Montserrat';
		font-size: 12px !important;
		font-weight: 300 !important;
		color: #626666;
		opacity: 0.6;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.mob-sub-text-leave {
		font-family: 'Montserrat-Medium';
		font-weight: 500 !important;
		font-size: 12px !important;
		color: #00344e !important;
	}

	.mob-sub-sub-text-leave {
		font-weight: 300 !important;
		font-size: 12px !important;
		color: #484b52;
	}

	.leave-collapse {
		.ant-collapse-item {
			border: none !important;
			border-bottom-right-radius: 16px !important;
			border-bottom-left-radius: 16px !important;

			.ant-collapse-header {
				padding: 0px !important;
				height: auto !important;
				background-color: white !important;
				border-radius: 0px !important;
			}
		}
	}

	.ant-collapse {
		border: none !important;
		border-bottom-right-radius: 16px !important;
		border-bottom-left-radius: 16px !important;
	}

	.mob-flex-leave {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.leave-typo-date {
		font-family: 'Montserrat-Regular';
		font-weight: 300;
	}

	.icons-mob-leave {
		padding-right: 10px;
		width: 34px;
		height: 24px;
	}

	.icons-mob-leave2 {
		padding-right: 10px;
	}

	.icons-mob-leave1 {
		padding-right: 10px;
		filter: grayscale(20);
		opacity: 0.2;
	}

	.link-leave-tag {
		color: #00344e;
		text-decoration: underline;
		padding-left: 10px;
		font-family: 'Montserrat-Regular';
		font-size: 12px;
		margin-top: 7px;
	}

	.link-leave-tag1 {
		opacity: 0.3;
		color: #00344e;
		padding-left: 10px;
		font-family: 'Montserrat-Regular';
		font-size: 12px;
	}

	.text-opacity {
		opacity: 0.3;
	}

	.view-more-tag {
		color: #2b769c;
		text-decoration: underline;
		font-family: 'Montserrat-Regular';
		font-size: 12px;
	}

	.bottom-container-leavemob {
		display: flex;
		flex-direction: row;
		width: 100%;
		align-items: center;
		justify-content: end;
		height: 45px;
	}

	.leave-btn-mob {
		width: 90px;
		height: 32px;
		font-family: 'Montserrat-Regular';
		font-size: 14px;
		border-radius: 90px;
		margin: 10px 10px 10px 0px !important;
		min-height: unset;
	}

	.leave-btn-mob-reject {
		background-color: white !important;
		border: 1px solid #edeef1;
	}

	.leave-btn-mob-approve {
		background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%);
		color: #f7f8fa;
	}

	.leave-btn-mob-approve:active {
		background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%);
	}

	.loader-leave--mob {
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
	}

	.textval {
		word-break: break-all;
	}
}

.toast-approve-leave {
	transition: ease-in-out 5s;
	position: absolute;
	bottom: 40px;
	left: 40%;
	z-index: 1;
	text-align: center;
	animation: fade 3s linear;

	.toat-outer .toast {
		padding: 16px 4px 16px 4px;
	}
}

@keyframes fade {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}
