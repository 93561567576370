@import '../../../constants/variables';
.error-data-container {
	width: 896px;
	background: $neutral-white;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	margin-top: 8px;
	.error-message-container {
		width: 100%;
		background: $red-50;
		border-radius: 8px;
		display: flex;
		align-items: center;
		margin-bottom: 8px;
		.error-icon {
			margin: 0px 17px;
		}
		.error-message {
			margin-bottom: 0px;
			display: flex;
			align-items: center;
			height: 52px;
		}
	}
	.error-list {
		.card-error {
			.card_action {
				background: #fff6f5;
				border-radius: 8px;
				border: none;
				padding: 3px;
				.tip_web-view {
					font-family: 'Montserrat-Light';
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: #00344e;
				}
			}
			.errorTable {
				border: none;
			}
			.view-text {
				margin-right: 10px;
			}
		}
	}
}
