.leaveapprove-table {
	display: flex;
	flex-direction: column;
	background: #f7f8fa;
	height: 100%;
	.bulk-employee-detail-container {
		padding: 0px;
		width: 960px;
		align-self: center;
		margin-top: 32px;
	}
}
