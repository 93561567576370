@import '../../../constants/variables';

.personalInfoContainer {
	background: $neutral-white;
	box-shadow: 0px 16px 32px -24px #bdbdbd;
	border-radius: 8px;
	padding: 32px;
	margin-bottom: 24px;
	.padding_right_16px {
		padding-right: 16px;
	}
	.date-picker-input_profile {
		height: 32px;
		border: 1px solid #9a9ea6;
		border-radius: 8px;
		width: 100%;
		padding: 0px 0px 0px 10px;
		font-family: $font-family-medium;
		font-style: $font-style-normal;
		font-weight: $font-weight-500;
		font-size: $line-height-12px;
		line-height: $line-height-14px;
		color: $grayscale-900;
	}

	.profile_dob_error {
		height: 32px;
		border: 1px solid #ff9691;
		border-radius: 8px;
		width: 100%;
		padding: 0px 0px 0px 10px;
	}

	.multiSelect-dropdown-container {
		.dropdown_header {
			.dropdown_inner {
				.dropDown-menu {
					.dropdown_default {
						margin: 0px;

						.dropdown_inner_list_main {
							margin-left: 0px;
						}
					}
				}
			}
		}
	}

	.personalInfo {
		font-family: 'Montserrat-Bold';
		font-size: 16px;
		line-height: 18px;
		color: #00344e;
	}

	.forEdit {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		border-radius: 32px;
		border: 1px solid #dfe1e6;
	}

	.personalRow {
		display: flex;
		justify-content: space-between;
		margin-bottom: 24px;
	}

	.edit {
		// margin-bottom: 0px;
		font-size: 14px;
		color: #00344e;
		line-height: 16px;
	}

	.view-employee-editbotton {
		.for-button-padding {
			padding: 6px 16px !important;
			font-family: 'Montserrat-Regular';
			font-size: 14px;
			line-height: 16px;
			font-weight: 300;
			color: #00344e;

			.for-edit-button {
				margin-bottom: 0px;
				padding-left: 5px;
			}
		}
	}

	.edenRedButton {
		min-height: 0px;
	}

	.ForHeading {
		font-family: 'Montserrat-Medium';
		font-size: 14px;
		line-height: 20px;
		font-weight: 500;
		color: #484b52;
		margin-bottom: 0px;
	}

	.forValue {
		font-family: 'Montserrat-Light';
		font-size: 14px;
		line-height: 20px;
		font-weight: 300;
		color: #737780;
		margin-bottom: 0px;
	}
}

.For-card-row {
	.carddisable {
		opacity: 0.4;
		pointer-events: none;
		background: $grayscale-100;
		border-radius: 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 8px;
		margin-top: 8px;

		&:first-child {
			margin-top: 32px;
		}
	}

	.card-row {
		background: $grayscale-100;
		border-radius: 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		cursor: pointer;
		padding: 8px;
		margin-top: 8px;

		&:first-child {
			margin-top: 32px;
		}
		img {
			width: 16px;
			height: 16px;
		}
	}

	.card-row-Delete {
		background-color: #fce7e6;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		cursor: pointer;
	}

	.card-detail-space {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;

		.emp_card_icon {
			display: flex;
			align-items: center;
			justify-content: center;
			background: $neutral-white;
			border-radius: 4px;
			padding: 8px;

			img {
				width: 16px;
				height: 16px;
			}
		}

		.card-names {
			padding-left: 8px;
			text-align: left;
		}
	}
}

.For-card-row-bank {
	justify-content: flex-end;
	margin-bottom: 5%;

	.card-row-Delete {
		background-color: #fce7e6;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 1%;
		padding: 1%;
		width: 24%;
		padding-right: 2%;
		cursor: pointer;
	}

	.card-detail-space {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.card-names {
		font-family: 'Montserrat-Medium';
		font-size: 12px;
		line-height: 16px;
		font-weight: 500;
		color: #484b52;
		margin-bottom: 0px;
		padding-left: 10px;
	}

	@media screen and (max-width: 1000px) {
		.card-row-Delete {
			width: 48%;
		}
	}
}

// .card-row-Delete-new{
// 	display: flex;
// 	justify-content: space-between;
// 	align-items: center;
// 	margin-top: 1%;
// 	cursor: pointer;
// 	border: 1px solid #DFE1E6;
// 	border-radius: 22px;
// 	.card-names-new{
// 		font-family: 'Montserrat-Medium';
// 		font-size: 12px;
// 		line-height: 16px;
// 		font-weight: 500;
// 		color: #484b52;
// 		margin-bottom: 0px;
// 		padding-left: 2%;
// 	}
// }
.card-row-Delete-new {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 2%;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-right: 2%;
	padding-left: 1%;
	border-radius: 22px;
	border: 1px solid #dfe1e6;
	cursor: pointer;

	.card-names-new {
		font-family: 'Montserrat-light';
		font-size: 12px;
		line-height: 16px;
		font-weight: 500;
		color: #484b52;
		margin-bottom: 0px;
		margin-left: 14px;
	}

	.delNew {
		width: 12px;
	}
}

.invite_emp_to_portal {
	margin-top: 32px;

	.card-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #f7f8fa;
		padding: 15px;

		.card-detail-space {
			display: flex;
			align-items: center;
			cursor: pointer;
			.invite_portal_profile_icon {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 8px;
				width: 32px;
				height: 32px;
				background: $neutral-white;
				border-radius: 4px;
			}

			.card-names {
				font-family: 'Montserrat-Medium';
				font-size: 12px;
				line-height: 14px;
				font-weight: 500;
				color: #484b52;
				margin: 0px 0px 0px 8px;
			}
		}
	}
}

.invite_EmpToPortal_PopUp {
	.ant-modal-content {
		.ant-modal-body {
			height: 40vh !important;

			.main_popup_container {
				.message_container {
					height: 78% !important;

					.popup_heading_div {
						margin-top: 20px !important;
					}
				}

				.routing_container {
					height: 22% !important;
				}
			}
		}
	}
}

.invite_emp_disable {
	.ant-modal-content {
		.ant-modal-body {
			.main_popup_container {
				.routing_container {
					background-color: #eff1f5;

					span {
						font-family: 'Montserrat-Medium';
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 20px;
						text-align: center;
						color: #dfe1e6;
					}
				}
			}
		}
	}
}
