@import '../../constants/variables';

.Insurance_presentational_container {
	width: 100%;
	align-self: flex-start;

	.dashed-wrapper {
		align-items: center;
		display: flex;
		justify-content: center;
	}

	.c-dashed-line__path {
		animation: c-dashed-line-path 3s ease-in-out infinite alternate;
		fill: none;
		stroke: #31cc89;
		stroke-dasharray: 940;
		/* this is the entire length of the line */
		stroke-dashoffset: 940;
		/* this is the entire length of the line */
		stroke-width: 10;
	}

	.c-dashed-line__dash {
		fill: none;
		stroke: #ffffff;
		/* this must match the background color */
		stroke-dasharray: 16 10;
		/* draws a 10px dash line with a 16px gap between */
		stroke-width: 12;
		/* make the dashed line slightly bigger than the one it's covering */
	}

	@keyframes c-dashed-line-path {
		from {
			stroke-dashoffset: 940;
		}

		to {
			stroke-dashoffset: 0;
		}
	}

	.line-top {
		width: 140px;
		height: 174px;
		z-index: -1;
		background: transparent;
		border-top: dashed 4px #c8c8c7;
		padding: 40px 40px;
		border-radius: 90%;
		margin: 20px -50px 0;
	}

	.Insurance-presentational {
		width: 100%;
		padding: 40px 40px 40px 40px;

		.Insurance-content {
			margin-top: 8px;
		}

		.health-inusrance {
			display: flex;
			flex-direction: row;

			.health_inc_banner {
				padding-right: 16px;

				.health_inc_left_card {
					background: $neutral-white;
					box-shadow: 0px 16px 32px -24px #bdbdbd;
					border-radius: 8px;
					padding: 48px 32px;
					height: 100%;

					img {
						max-width: 100%;
						height: auto;
					}
				}
			}

			.data_slip {
				background: $neutral-white;
				box-shadow: 0px 16px 32px -24px #bdbdbd;
				border-radius: 8px;
				padding: 32px;

				.transparent-success {
					position: absolute;
					top: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					width: 100%;
					height: 100%;
					background-color: white;
					background-color: rgba(255, 255, 255, 0.98);
					left: 0;

					.thankyou-text {
						font-family: Montserrat-bold;
						font-size: 24px;
						color: #00344e;
					}

					.thankyou-subtext {
						font-family: Montserrat-medium;
						font-size: 16px;
						color: #484b52;
						width: 80%;
						text-align: center;
						padding-top: 10px;
					}

					color: #fff;
					// opacity: 0.8;
				}
			}

			.health_inc_change_password_input {
				margin-top: 32px;

				.ant-form-item {
					margin-bottom: 16px;

					&:last-child {
						margin-bottom: 0px;
					}
				}

				.health_inc_update_password {
					margin-top: 32px;
					.primary-btn {
						&:disabled {
							background: $grayscale-200;
						}
					}
					.ant-form-item-control {
						align-items: flex-end;
					}
				}
			}
		}

		.health_all_elements {
			padding: 40px 0px 0px 0px;
		}

		.main-card-container {
			margin-top: 16px;

			.insurance_card_container {
				padding-right: 16px;

				&:last-child {
					padding-right: 0px;
				}

				.card-containers {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					padding: 24px 10px;
					background: $neutral-white;
					box-shadow: 0px 16px 32px -24px #bdbdbd;
					border-radius: 8px;
					height: 146px;

					.card-image-container {
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 10px;
						border-radius: 8px;
						width: 68px;
					}

					.compareqoutes {
						background: #fef5e8;
					}

					.bestprice {
						background: #cee8ff;
					}

					.customizedplan {
						background: #ffbecb;
					}

					.allinone {
						background: #ccece6;
					}
				}
			}
		}

		.health-mobile-view {
			display: none;
		}

		.mobile-view-Insurance-content {
			display: none;
		}

		.mobile-view-Insurance-heading {
			display: none;
		}
	}
}

@media only screen and (max-width: 600px) {
	.Insurance_presentational_container {
		width: 100%;
		height: 100%;

		.Insurance-presentational {
			width: 100%;
			padding: 65px 2% 0% 3%;
			overflow-y: scroll;
			text-align: center;

			//padding: 52px 135px 10px 135px;
			.Insurance-content {
				padding: 30px 0px 16px 0px;
				text-align: center;
			}

			.mobile-view-Insurance-content {
				padding: 30px 0px 16px 0px;

				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 18px;
					line-height: 20px;
					color: #484b52;
				}
			}

			.health-inusrance {
				display: flex;
				flex-direction: column;
				padding: 0% 0%;

				.data_slip {
					padding: 10px 10px 10px 10px;
					// position: relative;
				}
			}

			.health_all_elements {
				display: none;
			}

			.health-mobile-view,
			.mobile-view-Insurance-content,
			.mobile-view-Insurance-heading {
				text-align: center;
				display: block;
			}

			.health-isurance-web {
				display: none;
			}
		}
	}
}

.heath_inc_toast {
	position: absolute;
	bottom: 40px;
	width: 100%;
	display: flex;
	justify-content: center;
	transition: ease-in-out 5s;
	animation: 3s ease 0s normal forwards 1 fade;
	// animation: fade 3s linear;
}

@keyframes fade {
	0% {
		opacity: 0;
	}

	60% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@media only screen and (max-width: 400px) {
	.Insurance_presentational_container .Insurance-presentational .ant-tabs-tab {
		margin: 0px 12px 0px 0px;
	}
}
