.recons-card-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 110px;
	border: 2px solid #f7f8fa;
	border-radius: 8px;

	.recons-card-section {
		display: flex;
		flex-direction: row;
		width: 100%;

		.recons-account-container {
			display: flex;
			.rcons-card-section {
				display: flex;
				flex-direction: column;
				height: auto;
				width: 100%;
				border-radius: 8px;
				background-color: #f7f8fa;
				padding: 18px 24px 8px 24px;
				justify-content: center;
				.account-title-section {
					display: flex;
					flex-direction: row;
					justify-content: center;
					.account-title {
						font-family: 'Montserrat-Light';
						font-style: normal;
						font-weight: 300;
						font-size: 12px;
						line-height: 14px;
					}
				}

				.editable-recons-account-number {
					display: flex;
					height: 30px;
					margin-top: 8px;
					flex-direction: column;
					.reconc-account-input {
						height: 30px;
						width: 195px;
						font-weight: bolder;
						color: #484b52;
					}
					.input_img {
						bottom: 0px;
					}

					.reconc-account-input::placeholder {
						color: #484b52;
						font-family: 'Montserrat-medium';
						font-size: 10px;
						line-height: 12px;
					}
					.reconc-account-input-error {
						height: 30px;
						width: 195px;
						font-weight: bolder;
						color: #484b52;
						border-color: #ab0c00;
					}
					.input_img {
						bottom: 0px;
					}

					.error-container {
						display: flex;
						font-family: 'Montserrat-medium';
						font-size: 8px;
						line-height: 10px;
						margin-top: 7px;
						padding-left: 5px;
						color: #ab0c00;
					}
				}

				.recons-account-number {
					display: flex;
					width: 100%;
					margin-top: 4px;
					justify-content: center;

					.account-style {
						font-family: 'Montserrat-bold';
						font-size: 20px;
						line-height: 24px;
						font-weight: bolder;
						color: #484b52;
					}
				}
			}
		}

		.reconc-text-container {
			display: flex;
			flex-direction: column;
			width: 75%;
			// background-color: aqua;
			padding: 13px 5px 13px 16px;
			justify-content: center;
			.rcons-text-section {
				display: flex;
				flex-direction: column;
				row-gap: 8px;
				.recoci-text {
					font-family: 'Montserrat-light';
					font-style: normal;
					font-size: 14px;
					line-height: 16px;
					color: #484b52;
					.clickHere_text {
						color: #0e5274;
						text-decoration: underline;
						font-family: 'Montserrat-medium';
						font-style: normal;
						margin-left: 2px;
					}
				}
			}
		}
	}
}
