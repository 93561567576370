@import '../../../constants/variables.scss';

.department-multi-select-container {
	margin: 32px 0px 24px 0px;

	.title {
		margin-bottom: 8px;
	}

	.tag {
		padding: 12px 16px;
		border-radius: 10px;
		color: #00344e;
		width: fit-content;
		background-color: #eff1f5;
		margin-right: 5px;
		cursor: pointer;

		img {
			width: 30px;
			margin-left: 10px;
		}
	}

	.edenRedButton {
		.outline-g {
			border: none !important;
			width: 50%;
		}
	}

	.department-button-parent {
		margin-top: 10px;
		width: fit-content;
		border: 2px solid #dfe1e6;
		border-radius: 28px;

		.edenRedButton {
			padding: 8px 25px;
			min-height: unset;
		}
	}
}

.department-text-header {
	font-family: montserrat-Bold;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	color: #00344e;
	margin-bottom: 15px;
}

.department-modal-container {
	width: 60% !important;
	height: 65vh !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	.ant-modal-body {
		// overflow-y: scroll;
		height: fit-content !important;
		padding: 0;
	}

	.ant-modal-content {
		width: 50%;
		border-radius: 10px;
		overflow: hidden;
	}

	.derpartment_item {
		cursor: pointer;
		padding: 10px 0;
		border-top: 1px solid #dfe1e6;

		&:last-child {
			border-bottom: 1px solid #dfe1e6;
		}

		img {
			width: 30px;
		}
	}

	.edenRedButton {
		border-radius: 0;
	}
}

.dropdown_with_no_margin {
	padding-right: 16px;

	.ant-select {
		display: flex;
		font-family: Montserrat-Medium;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 20px;
		height: 52px;
		min-height: 52px;
		color: $primaryColor5;

		// width: 119px;
		.ant-select-selector {
			// background: pink;
			display: flex;
			align-items: center;
			height: 52px;
			width: 100%;
			border: none;
			border-radius: 16px;
			box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
		}

		.ant-select-selection-item {
			padding-right: 18px;
			// display: flex;
			justify-content: space-around;
			margin-right: 0 !important;
		}

		.ant-select-arrow {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			color: #11266900;
			content: url('../../../assets/icons/not_focus/Down.svg');
			height: 28px;
			width: 28px;
			margin-top: -15px;
			margin-right: 0px;
		}

		.ant-select-item {
			font-family: 'Montserrat-Medium';
			font-size: 14pt;
			font-weight: 500;
		}
	}
}