@import './../../../constants/variables';

.Payroll_history_container {
	width: 100%;
	align-self: flex-start;
	.Payroll_status_card {
		.infinite-scroll-component {
			padding: 0 40px 40px 40px;
		}
		.payroll_status_header {
			padding: 40px 0px 24px 0px;
			position: sticky;
			z-index: 1;
			top: 0px;
			background: $grayscale-200;
			.click_payroll_mobile_view {
				display: none;
			}
		}
		.payroll_card_container {
			padding: 32px;
			background: #ffffff;
			border-radius: 8px;
			margin-bottom: 22px;
			&:last-child {
				margin-bottom: 0px;
			}
			.payroll_history_month_mobile_view {
				display: none;
			}
			.payroll_history_submit {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}
			.card_details {
				margin-top: 16px;
				.edenredCard_main {
					padding: 0%;
					border-radius: 8px;
					height: 100%;
					box-shadow: 0px 16px 32px -24px #bdbdbd;
					border: none;
					.ant-card-body {
						padding: 0px;
						height: 100%;
						.edenredCard_payroll_history {
							border-radius: 9px;
							justify-content: space-between;
							.payroll_history_col_first {
								border-top-left-radius: 8px;
								border-bottom-left-radius: 8px;
							}
							.payroll_history_col_second {
								margin-left: 16px;
								border-top-left-radius: 8px;
								border-bottom-left-radius: 8px;
								background: $neutral-white;
								padding: 24px 0px 24px 24px;
								.card_details_container {
									display: flex;
									align-items: center;
									.card_image {
										display: flex;
										align-items: center;
										justify-content: center;
										padding: 8px;
										background: #f7f8fa;
										border-radius: 8px;
										width: 48px;
										height: 48px;
										img {
											width: 48px;
											height: 48px;
										}
									}
									.card_inner_details {
										margin-left: 24px;
										display: flex;
										flex-direction: column;
										span {
											&:nth-child(2) {
												margin-top: 4px;
											}
											&:nth-child(3) {
												margin-top: 8px;
											}
										}
									}
								}
							}
							.payroll_history_col_third {
								padding: 24px 32px 24px 0px;
								align-items: unset;
								justify-content: unset;
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
	}
}
.payroll-status-filename-con {
	display: flex;
	justify-content: flex-end;
}
.modal_cancel_payroll {
	width: 100%;
	align-items: center;
	display: flex;
	justify-content: center;
	height: 100%;
	.Modal_Edenred {
		background: #ffff;
		box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.5);
		border-radius: 16px;
		width: 27%;
		.Remove_user {
			padding: 5% 10% 0% 10%;
		}
	}
}
////// new step component
.payroll-step-component-container {
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 40px;

	.payroll-status-header-container {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between;

		.button-container-payroll-status {
			display: flex;
			flex-direction: row;
			gap: 8px;
		}
	}
	.payroll-step-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 32px;
		width: 100%;
		height: fit-content;
		margin-top: 28px;
		overflow-x: auto;
		/* Colors/Neutral/neutral-white */

		background: #ffffff;
		/* Shadows/shadow-lg */

		box-shadow: 0px 16px 32px -24px #bdbdbd;

		.step-component-detail-container {
			display: flex;
			flex-direction: row;
			width: 100%;
			height: fit-content;
			margin-top: 8px;
			.step-component-first-section {
				display: flex;
				padding-right: 8px;
				flex-direction: row-reverse;
				border-right: 1px solid #dfe1e6;
			}
		}
		.step-component-main {
			display: flex;
			flex-direction: row;
			width: 100%;
			height: fit-content;
			margin-top: 37px;
		}
	}
	.divider {
		display: flex;
		flex-direction: row;
		flex-direction: row;
		width: 100%;
		padding-left: 32px;
		padding-right: 32px;

		background: white;
		.payroll-divider {
			display: flex;
			flex-direction: row;
			flex-direction: row;
			height: 1px;
			width: 100%;
			border-top: 1px solid #eff1f5;
		}
	}

	.next-step-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 32px;
		width: 100%;
		height: fit-content;
		/* Colors/Neutral/neutral-white */

		background: #ffffff;
		/* Shadows/shadow-lg */

		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		align-items: flex-start;

		.payroll_status_steps {
			display: flex;
			flex-direction: column;
			gap: 16px;
			margin-top: 17px;
			.payroll_status_steps_container {
				display: flex;
				align-items: center;
				padding: 0% 1% 1.5% 0%;
				gap: 16px;

				.payroll_status_step_icon {
					display: flex;
					width: 24px;
					height: 24px;
					border-radius: 8px;
					align-self: center;
					justify-content: center;
					border-radius: 8px;
					background: $greyColor1;
					box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
					span {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 16px;
						color: $primaryColor3;
					}
					.step-number-section {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						padding: 10px;
						gap: 10px;

						width: 32px;
						height: 32px;

						/* Colors/Grayscale/grayscale-100 */

						background: #f9fafb;
						border-radius: 8px;
					}
				}
				.payroll_status_step_content {
					display: flex;
					padding: 0px 4px 0px 10px;

					.payroll-status-link {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 300;
						font-size: 12px;
						line-height: 14px;
						text-decoration: underline;
						text-underline-position: under;
						color: #0e5274;
					}
				}
			}
		}
		.error-box-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 8px;
			gap: 8px;
			width: 100%;
			height: 52px;

			/* Colors/Red/red-50 */

			background: #fff6f5;
			border-radius: 8px;
		}
	}
}
///////ending
// payroll_status_container_css
.payroll_status_container {
	display: flex;
	flex-direction: row;
	padding: 0% 135px 0% 135px;
	.payroll_status_header {
		padding: 0% 0% 0.5% 0%;
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.payroll_status_sub_header {
		padding: 0.5% 2% 0.5% 0%;
		span {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 18px;
			color: $primaryColor5;
		}
	}
	.payroll_status_description {
		padding: 16px 2% 0.5% 0%;
	}

	.ant-divider-horizontal {
		border-top: 2px solid #dfe1e6;
		margin: 16px 0;
	}

	.payroll_status_payroll_amount_details {
		padding: 0.5% 2% 0.5% 0%;
		.desktop_view_content {
			display: flex;
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 18px;
			color: $primaryColor5;
		}
		.mobile_view_content {
			display: none;
		}
	}
	.payroll_status_payroll_steps_progress_bar {
		padding: 1.5% 0% 1.5% 0%;
	}
	.payroll_status_yellow_card_mobile_view {
		display: none;
	}
	.payroll_status_button_section {
		width: 100%;
		.payroll_status_view_button_mobile {
			display: none;
		}
		.payroll_status_view_button {
			row-gap: 10px;
			padding: 0px 10px 0px 0px;
			button {
				//width: unset;
				// max-width: 183px;
				height: 34px;
				min-height: 32px;
				padding: 3px;
				border-radius: 16px;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-size: 14px;
				font-weight: 300;
				line-height: 16px;
			}
		}
		.payroll_status_cancel_button {
			padding: 0px 0px 0px 0px;
			button {
				//width: 90%;
				// max-width: 149px;
				height: 34px;
				min-height: 32px;
				padding: 3px;
				border-radius: 22px;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-size: 14px;
				font-weight: 300;
				line-height: 16px;
				width: 150px;
				img {
					height: 15px;
					width: 15px;
					margin-right: 6px;
				}
			}
		}
	}
}

.card_error {
	display: flex;
	justify-content: center;
	padding: 4px 0px;
	.card_action {
		width: 100%;
		min-height: 30px;
		border: 1px solid $feedBackRed;
		background-color: #fff6f5;
		border-radius: 8px;
		.ant-card-body {
			padding: 4px;
		}
		img {
			width: 70%;
		}
		.tip_web-view {
			display: flex;
			align-items: center;
		}
		.tip_mobile-view {
			display: none;
		}
	}
}

// Direct_PAF_Payroll_status_Container
.direct_paf_payroll_status_container {
	display: flex;
	flex-direction: row;
	padding: 0% 135px 0% 135px;
	.direct_paf_payroll_status_content_container {
		.direct_paf_payroll_status_header {
			padding: 0% 0% 0.5% 0%;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				color: $primaryColor5;
			}
		}
		.direct_paf_payroll_status_description {
			padding: 16px 2% 0.5% 0%;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: $greyColor7;
			}
		}
	}

	.ant-divider-horizontal {
		border-top: 2px solid #dfe1e6;
		margin: 16px 0;
	}

	.direct_paf_payroll_status_payroll_amount_details {
		padding: 0.5% 2% 0.5% 0%;
		.desktop_view_content {
			display: flex;
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 18px;
			color: $primaryColor5;
		}
		.mobile_view_content {
			display: none;
		}
	}
	.direct_paf_payroll_status_payroll_steps_progress_bar {
		padding: 1.5% 0% 1.5% 0%;
	}
	.direct_paf_payroll_status_mobile_view_message {
		display: none;
	}
	.direct_paf_payroll_status_button_section {
		width: 100%;
		.direct_paf_payroll_status_view_button_mobile {
			display: none;
		}
		.direct_paf_payroll_status_view_button {
			padding: 0px 16px 0px 0px;
			button {
				width: 100%;
				min-width: 183px;
				height: 32px;
				min-height: 32px;
				padding: 3px;
				border-radius: 16px;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-size: 14px;
				font-weight: 300;
				line-height: 16px;
			}
		}
	}
}

//failed-employ-detail
.failed_employe_details {
	padding: 0px 40px 40px 40px;
	width: 100%;
	align-self: flex-start;
	.card_status_view_header {
		padding: 40px 0px 24px 0px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: $grayscale-200;
		position: sticky;
		z-index: 1;
		top: 0px;
		width: 100%;
	}
	.failed_employe_details_sub_header {
		display: flex;
		align-items: center;
		gap: 16px;
		.secondary-btn {
			width: 118px;
		}
	}
	.view_payroll_status_container {
		padding: 32px;
		background: $neutral-white;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		width: 100%;
		.failed_employe_box {
			width: 100%;
			justify-content: space-between;
			.search_download_detail {
				// width: 70%;
				flex-wrap: wrap;
				display: flex;
				align-items: center;
				gap: 16px;
				.failed_employe_download_button {
					display: flex;
					align-items: center;
					gap: 8px;
				}
			}
			.failed_employe_dselect_entries {
				display: flex;
				align-items: center;
				gap: 8px;
				.multi-select-dropdown-component {
					width: 68px;
					.dropdown_header {
						.dropdown_list {
							.dropdown_inner_list {
								div {
									label {
										padding: 4px 0px 0px 13px !important;
									}
								}
							}
						}
					}
				}
			}
		}
		.failed_employe_header_table {
			width: 100%;
			margin-top: 16px;
			.edenred-table {
				thead {
					tr {
						th {
							padding: 16px 17px;
						}
					}
				}
			}
			.column_link {
				img {
					width: 18px;
					height: 18px;
					margin-left: 4px;
				}
			}
		}
		.view_payroll_pagination {
			width: 100%;
			margin-top: 24px;
			justify-content: flex-end;
			align-items: center;
			.pagination-container {
				margin: 0;
				padding: 0;
			} 
		}
	}
	.ant-divider-horizontal {
		border-top: 1px solid $greyColor4;
		opacity: 0.9;
		margin: 12px 0px;
	}
	.failed_employe_header_row {
		width: 100%;
		padding: 16px 0px 8px 0px;
		.failed_employ_table_header {
			span {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 14px;
				color: #9a9ea6;
			}
		}
	}

	.failed_employe_content_row {
		width: 100%;
		padding: 8px 0px 8px 0px;
		.failed_employ_table_content {
			display: flex;
			align-items: center;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: #00344e;
				img {
					height: 25px;
					width: 25px;
				}
			}
			&.link {
				justify-content: flex-end;
				span {
					cursor: pointer;
				}
			}
		}
		.failed_employee_horizontal_content {
			border: 0px solid #dfe1e6;
			background: #dfe1e6;
		}
	}
}

.failed_employe_details_mobile_view {
	display: none !important;
}

.ellipsis {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media only screen and (max-width: 600px) {
	.card_error {
		display: flex;
		justify-content: center;
		padding: 4px 0px;
		.card_action {
			width: 95%;
			min-height: 30px;
			border: 1px solid $feedBackRed;
			border-radius: 8px;
			.ant-card-body {
				padding: 4px;
			}
			img {
				width: 60%;
			}
			.tip_web-view {
				display: none !important;
			}
			.tip_mobile-view {
				display: flex !important;
				font-size: 12px;
				display: flex;
				align-items: center;
			}
		}
	}

	// payroll_history_container
	.Payroll_history_container {
		width: 100%;
		padding: 1% 4%;
		padding-top: 64px;
		overflow-y: scroll;
		height: 89%;
		.payroll_history {
			span {
				font-size: 16pt;
			}
		}
		.click_payroll {
			display: none !important;
		}
		.click_payroll_mobile_view {
			display: flex !important;
		}
		.payroll_history_month {
			display: none !important;
		}
		.payroll_history_month_mobile_view {
			display: flex !important;
			flex-direction: column;
			.month {
				font-size: 10px;
			}
		}
		.payroll_history_horizontal {
			border: 1px solid #eff1f5;
			margin: 16px 0%;
		}
		.payroll_history_submit {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			font-size: 10px;
			padding-bottom: 2%;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 10px;
				line-height: 14px;
				color: #484b52;
			}
		}
		.card_details {
			padding-bottom: 4px;
			margin-bottom: 4px;
			.payroll_history_status_card {
				border-radius: 24px;
				.edenredCard_main {
					.ant-card-body {
						width: 100%;
						padding: 0%;
					}
				}
			}
			.payroll_history_edenred_Card {
				border-radius: 20px;
			}
		}
	}
	.edenredCard_main .ant-card-body {
		width: 100%;
		padding: 0% !important;
		height: 100%;
	}
	.modal_cancel_payroll {
		width: 100%;
		align-items: flex-end;
		display: flex;
		justify-content: center;
		height: 100%;
		.Modal_Edenred {
			width: 100%;
		}
		.Modal_Edenred .user_login_mobile {
			margin: 0px;
			padding-left: 6%;
			padding-top: 1%;
			padding-bottom: 2%;
			color: $primaryColor5;
		}
		.Modal_Edenred .button_mobile_role {
			display: flex !important;
			justify-content: space-evenly;
			width: 100%;
			padding-bottom: 10%;
			padding-top: 5%;
		}
	}

	//Payroll Status Page (Mobile View)
	.payroll_status_container {
		padding: 65px 10px 70px 10px;
		overflow-y: scroll;
		.payroll_status_content_container {
			padding: 0px 0px 0.5% 8px;
			.payroll_status_header {
				span {
					font-size: 16px;
					line-height: 18px;
				}
			}
			.payroll_status_sub_header {
				display: none;
			}
			.payroll_status_steps {
				display: none;
			}
		}
		.ant-divider-horizontal {
			display: none;
		}

		.payroll_status_payroll_amount_details {
			padding: 0.5% 2% 0.5% 8px;
			.desktop_view_content {
				display: none;
			}
			.mobile_view_content {
				display: inline-grid !important;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: $greyColor6;
			}
		}
		.payroll_status_payroll_steps_progress_bar {
			padding: 12px 0% 1.5% 0%;
		}
		.payroll_status_yellow_card_mobile_view {
			display: flex !important;
			flex-direction: column;
			padding: 10px 4px 10px 6px;
			.tooltip_yellow_card {
				min-height: 30px;
				border: 1px solid #e5e076;
				border-radius: 8px;
				.ant-card-body {
					padding: 4px;
					.tip_note_content {
						display: flex;
						align-items: center;
						.tip_note_icon {
							align-items: center;
							img {
								height: 24px;
								width: 24px;
							}
						}
						.tip_note_message {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 12px;
							line-height: 14px;
							height: 28px;
							color: $greyColor6;
						}
					}
				}
			}
			.payroll_status_step_content {
				display: flex;
				padding: 6px 4px 6px 8px;
				span {
					display: inline-grid;
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					color: $greyColor6;
				}
				a {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					text-decoration: underline;
					text-underline-position: under;
					color: $primaryColor4;
				}
			}
			.expected_salary_payout_message {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: $greyColor6;
			}
		}
		.payroll_status_button_section {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			.payroll_status_view_button {
				display: none;
			}
			.payroll_status_view_button_mobile {
				display: flex !important;
				padding: 1% 3% 0% 3%;
				button {
					font-size: 14px;
					font-weight: 300;
					padding: 0px;
					min-width: 129px;
					min-height: 32px;
					width: 129px;
					height: 32px;
				}
			}
			.payroll_status_cancel_button {
				padding: 1% 3% 0% 3%;
				button {
					display: flex;
					justify-content: space-evenly;
					font-size: 9pt;
					font-weight: 600;
					// padding: 3%;
					border: none;
					text-decoration: underline;
					text-underline-position: under;
					img {
						// height: 15px;
						// width: 15px;
						display: none;
					}
				}
			}
		}
	}

	// Direct PAF Payroll Status Container (Mobile View)
	.direct_paf_payroll_status_container {
		padding: 65px 3% 1% 4px;
		overflow-y: scroll;
		.direct_paf_payroll_status_content_container {
			padding: 0px 0px 0.5% 8px;
			.direct_paf_payroll_status_header {
				span {
					font-size: 16px;
					line-height: 18px;
				}
			}
			.direct_paf_payroll_status_sub_header {
				display: none;
			}
			.direct_paf_payroll_status_description {
				display: none;
			}
			.direct_paf_payroll_status_steps {
				display: none;
			}
		}
		.ant-divider-horizontal {
			display: none;
		}
		.direct_paf_payroll_status_payroll_amount_details {
			padding: 0.5% 2% 0.5% 8px;
			.desktop_view_content {
				display: none;
			}
			.mobile_view_content {
				display: inline-grid !important;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: $greyColor6;
			}
		}
		.direct_paf_payroll_status_mobile_view_message {
			display: flex !important;
			padding: 16px 8px 16px 12px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: $greyColor6;
			}
		}
		.direct_paf_payroll_status_button_section {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			.direct_paf_payroll_status_view_button {
				display: none;
			}
			.direct_paf_payroll_status_view_button_mobile {
				display: flex !important;
				padding: 1% 3% 0% 3%;
				button {
					font-size: 14px;
					font-weight: 300;
					padding: 0px;
					min-width: 129px;
					min-height: 32px;
					width: 129px;
					height: 32px;
				}
			}
		}
	}

	.failed_employe_details {
		display: none !important;
	}
	.failed_employe_details_mobile_view {
		display: flex !important;
		width: 100%;
		height: 90vh;
		overflow: scroll;
		padding: 64px 5px 0px 13px;
		.failed_content {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
			color: #737780;
			padding: 10px 0px;
		}
		.employee_card_column {
			padding: 8px 0px 0px 0px;
			.employee_card {
				padding: 16px;
				border: none;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				border-radius: 16px;
				min-height: 86px;
				// height: 86px;
				max-height: auto;
				.ant-card-body {
					display: flex;
					align-content: center;
				}
			}
		}
		.download_employee_button_section {
			display: flex;
			justify-content: center;
			padding: 16px 0px;
			button {
				width: auto;
				padding: 12px;
				font-family: Montserrat-Light;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				box-shadow: none;
				height: 32px;
				min-height: 32px;
			}
		}
	}
}

.contentloader {
	position: absolute;
	width: 100%;
	z-index: 1;
	background: rgba(0, 0, 0, 0.1);
	border-radius: 30px;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.payrollScroll {
	width: 100%;
	// height: 500px;
	// overflow-y: scroll;
}
.card-details_left {
	width: 50%;
	float: left;
}
