@import '../../../constants/variables';
@mixin text($family, $fontsize, $fontweight, $fontlineheight, $letterspacing, $text-align) {
	font-family: $family;
	font-size: $fontsize;
	font-weight: $fontweight;
	line-height: $fontlineheight;
	letter-spacing: $letterspacing;
	text-align: $text-align;
}
@mixin flexes {
	display: flex;
	align-items: center;
}
@mixin size($height, $width) {
	height: $height;
	align-items: $width;
}

.profile {
	cursor: pointer;
}
.imgLoading {
	filter: blur(10px);
	clip-path: inset(0);
}
.navbar {
	width: fit-content;
	// height: 100vh;
	.side-nav-outer {
		flex: 0 0 245px;
		max-width: 245px;
		min-width: 245px;
		transition: all 0.4s;
		background-color: $neutral-white;
		height: 100%;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		// margin-left: 30px;
		.side-nav-inner {
			padding: 0px 10px 0px 10px;
			display: flex;
			flex-direction: column;
			height: 100vh;
			max-height: 100vh;
			.make_scroll {
				flex-grow: 1;

				overflow-y: auto;
				.side-nav-items {
					// border-bottom:1px solid $grayscale-300;
					// border-top: 1px solid $grayscale-300;
				}
				.side-nav-items:last-child {
					// border-bottom:none;
					border-top: none;
				}
			}

			.side-nav-top {
				.profile {
					display: flex;
					margin: 0px 0px 20px 0px;
					padding-top: 24px;
					.profile-content {
						display: flex;
						justify-content: center;
						flex-direction: column;
						margin-left: 9px;
						width: 71%;
						.side-nav-user-name {
							color: $blue-500;
							@include text(Montserrat-medium, 16px, 500, 18px, 0em, left);
							margin-bottom: 0px;
							text-overflow: ellipsis;
							overflow: hidden;
						}
						.verifier {
							color: $blue-300;
							@include text(Montserrat-light, 10px, 300 light, 12px, 0em, left);
							margin-bottom: 0px !important;
						}
					}
					.profile-icon {
						.navicon {
							@include size(16px, 16px);
							height: 15px;
							width: 15px;
							cursor: pointer;
							// margin-top: 32px;
						}
					}
				}
				.pending-outer {
					border-bottom: 1px solid $grayscale-300;
					.pending {
						background: $feedbox-warning;
						width: 100%;
						padding: 12px 0px 12px 9px;
						border-radius: 8px;
						display: flex;
						gap: 8px;
						margin-bottom: 18px;
						.pending-content {
							width: 66%;
						}
						.pending-arrow {
							display: flex;
							align-items: center;
							.pending-arrow-image {
								height: 14px;
								width: 14px;
							}
						}
						.image {
							align-items: center;
							.pending-image {
								@include size(32px, 33.5px);
								width: 33.5px;
								height: 32px;
								background: rgba(255, 255, 255, 0.5);
								border-radius: 8px;
							}
						}
						.pending-content {
							.alert {
								@include text(Montserrat-medium, 12px, 500, 14px, 0em, left);
								color: $blue-500;
								margin-bottom: 0px;
								padding-left: 0px;
								padding-right: 0px;
								margin-top: -4px;
							}
							.details {
								@include text(Montserrat-light, 10px, 300 light, 12px, 0em, left);
								color: $blue-500;
								margin-bottom: 0px;
								margin-top: -8px;
							}
						}
					}
				}
			}

			.experience {
				padding: 26px 23px 0px 24px;
				background: $red-50;
				width: 180px;
				border-radius: 8px;

				margin: 67px 0px 32px 16px;
				.experience-content {
					margin-bottom: 0px;
					width: 100%;
					@include text(Montserrat-bold, 14px, 700, 16px, 0em, center);
					color: $blue-500;
				}
				button {
					padding: 8px 16px 8px 16px;
					background: $red-gradient-normal;
					box-shadow: 0px 8px 16px -12px $secondaryColor3;
					border: none;
					border-radius: 8px;
					@include text(Montserrat-medium, 14px, 500, 16px, 0em, left);
					color: $neutral-white;
					margin: 16px 0px 21px 10px;
				}
			}

			.noresponsive {
				position: relative;
				cursor: pointer;
				.fs-nav-out-active {
					background: $red-50;
					border-radius: 8px;
				}
				.fs-nav-out {
					display: flex;
					padding: 12px 0px 12px 16px;
					cursor: pointer;
					.fs-nav-item {
						position: relative;

						display: flex;
						gap: 8px;
						width: 100%;
						align-items: center;
						.icn-chips {
							.chips {
								height: 15px;
								@include text(Montserrat-medium, 8px, 500, 10px, 0em, left);
								margin-left: 8px;
								margin-top: 4px;
							}
						}
						.fs-icon {
							margin-left: 8px;
						}
						.small {
							height: 16px;
						}
						.big {
							height: 16px;
						}
						.fs-nav-label-out {
							@include text(Montserrat-light, 12px, 300 light, 14px, 0em, left);
							color: $grayscale-600;
							@include flexes;
						}
						.fs-nav-label-active {
							@include text(Montserrat-medium, 12px, 500 light, 14px, 0em, left);
							color: $blue-500;
							@include flexes;
						}

						&.active {
							&:before {
								content: '';
								background-color: $secondaryColor3;
								border-radius: 4px;
								position: absolute;
								left: -5px;
								top: 50%;
								transform: translateY(-50%);
								bottom: 0;
								height: 6px;
								width: 6px;
							}
						}
					}
					.fs-nav-arrow {
						.fs-sub-menu-arrow {
							@include size(17px, 17px);
							margin-right: 8px;
						}
					}
				}
				.submenu {
					cursor: pointer;
					.fs-nav-submenu {
						@include flexes;
						gap: 8px;
						padding: 10px 2px;
						@include text(Montserrat-light, 12px, 300 light, 14px, 0em, left);
						color: $grayscale-600;
						padding-left: 49px;
						cursor: pointer;
					}
					.fs-nav-submenu-active {
						@include flexes;
						gap: 8px;
						padding: 10px 2px;
						color: $blue-500;
						@include text(Montserrat-medium, 12px, 500, 14px, 0em, left);
						padding-left: 49px;
					}
				}
			}
		}
	}
}
.responsive {
	// margin-left: 30px;
	.navicon {
		cursor: pointer;
		// margin-top: 32px;
	}
	.side-nav-inner {
		display: flex;
		flex-direction: column;
		height: 100vh;
		max-height: 100vh;
		.make_scroll {
			flex-grow: 1;
			overflow-y: auto;
			.side-nav-items {
				// border-bottom:1px solid $grayscale-300;
				border-top: 1px solid $grayscale-300;
			}
			.side-nav-items:last-child {
				// border-bottom:none;
				border-top: none;
			}
			.side-nav-items:nth-child(4) {
				margin-bottom: 66px;
			}
		}
	}

	.side-nav-outer {
		height: 100%;
		max-height: 100vh;
		// overflow: hidden;
		transition: all 0.4s;
		flex: 0 0 80px;
		max-width: 90px;
		min-width: 90px;
		background-color: $neutral-white;
		height: 100%;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		.fs-icon {
			margin-left: 38px;
			width: 14px;
			margin-bottom: 4px;
		}
		.small {
			height: 14px;
		}
		.big {
			height: 16px;
		}
		.fs-nav-label-out {
			//styleName: Typography/Light/B3 Light 10pt;
			@include text(Montserrat-light, 10px, 300 light, 12px, 0em, left);
			color: $grayscale-600;
			text-align: center;
		}
		.fs-nav-label-active {
			//styleName: Typography/Light/B3 Light 10pt;
			@include text(Montserrat-medium, 10px, 500, 12px, 0em, left);
			color: $blue-500;
			text-align: center;
		}
		.fs-nav-arrow {
			display: none;
		}
		.fs-nav-out {
			padding: 12px 0px 12px 0px;
		}
		.fs-nav-out-active {
			background: $red-50;
			border-radius: 8px;
		}
		.pending-outer {
			padding: 0px 10px;
			border-bottom: 1px solid $grayscale-300;
			.pending {
				background: $feedbox-warning;
				width: 100%;
				padding: 10px 0px 10px 0px;
				border-radius: 8px;
				margin-bottom: 18px;
				display: flex;
				justify-content: center;
				.pending-arrow {
					display: none;
				}
				.image {
					align-items: center;
					.pending-image {
						@include size(32px, 33.5px);
						width: 33.5px;
						height: 32px;
						background: rgba(255, 255, 255, 0.5);
						border-radius: 8px;
					}
				}
				.pending-content {
					display: none;
				}
			}
		}

		.experience {
			display: none;
		}
		.profile-image {
			display: none;
		}
		.profile-content {
			display: none;
		}
		.profile-icon {
			display: flex;
			justify-content: center;
			width: 100%;
			// margin-top: 32px;
			// margin-bottom: 52px;
			margin-bottom: 25px;
			padding-top: 30px;
		}
		.side-nav-inner {
			.fs-nav-item {
				position: relative;
				.icn-chips {
					.chips {
						position: absolute;
						top: 6px;
						height: 8px;
						width: 8px;
						padding: 0px 0px 0px 0px;
						left: 58px;

						.text {
							display: none !important;
						}
					}
				}
			}
		}
	}
	.noresponsive {
		position: relative;
		cursor: pointer;
		display: grid;
	}

	.submenu {
		display: block;
		position: fixed;
		left: 97px;
		z-index: 9999;
		width: 184px;
		// top: 0px;
		background: $neutral-white;
		box-shadow: 0 0 10px rgb(174 182 183 / 25%);
		.submenu:last-child {
			// margin-bottom: 16px;
		}
		.fs-nav-submenu {
			display: flex;
			color: $blue-500;
			@include text(Montserrat-light, 12px, 300 light, 14px, 0em, left);
			color: $blue-500;

			justify-content: left;
			cursor: pointer;
			padding: 16px 0px 0px 24px;
			.label {
				margin-bottom: 0px;
				text-align: center;
				width: 100%;
				display: flex;
				justify-content: left;
				align-items: center;
			}
		}
		.fs-nav-submenu:last-child {
			margin-bottom: 16px;
		}
		.fs-nav-submenu-active {
			@include text(Montserrat-medium, 12px, 500, 14px, 0em, left);
		}
	}
}

@media only screen and (max-width: 600px) {
	.navbar {
		display: none;
	}

	.responsive {
		display: none;
	}
}
.noresponsive {
	.submenu {
		display: block;
		visibility: hidden;
		transition: visibility 0.5s;
	}
}
.hide-hover {
	display: block;
	visibility: visible;
	.submenu {
		visibility: visible;
		.fs-nav-submenu {
			visibility: visible;
		}
	}
}
.hover {
	&:hover {
		.submenu {
			visibility: visible;
			transition: visibility 0.5s;
			&:hover {
				visibility: visible;
				transition: visibility 0.5s;
				.fs-nav-submenu {
					visibility: visible;
					transition: visibility 0.5s;
					&:hover {
						visibility: visible;
						transition: visibility 0.5s;
					}
				}
			}
		}
	}
}
