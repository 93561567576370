.chips {
	width: fit-content;
	height: fit-content;
	padding: 3px 8px 3px 8px;
	border-radius: 8px;
	font-family: 'Montserrat-Light';
	font-size: 12px;
	line-height: 14px;
	font-weight: 300;
	color: #181919;
}
