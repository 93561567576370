.payroll-nodata-outter {
	padding: 40px 0px;
	overflow-y: hidden !important;
	display: flex;
	align-items: center;
	justify-content: center;
}
.payroll-nodata-inner {
	img {
		width: 100%;
	}
	.payroll-nodata-text {
		color: #000000;
		padding: 15px 0px 15px 0px;
		font-family: Montserrat-Regular;
		font-weight: 300;
		font-style: normal;
		font-size: 16px;
		line-height: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.payroll-nodata-button {
		button {
			background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%);
			border: #d52b1e;
			padding: 18px, 32px, 18px, 32px;
			width: 198px;
			height: 48px;
			border-radius: 28px;
			font-family: Montserrat-Regular;
			font-weight: 500;
			font-size: 18px;
			color: #f7f8fa;
			cursor: pointer;
		}
	}
}

@media only screen and (max-width: 1400px) {
	.payroll-nodata-inner {
		img {
			width: 45%;
		}
	}
}

@media only screen and (min-width: 1600px) {
	.payroll-nodata-inner {
		img {
			width: 90%;
		}
	}
}
