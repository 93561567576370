.payroll-fund-transfer-popup {
	display: flex;
	width: 522px !important;
	height: 492px;
	flex-direction: column;
	box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.5);
	border-radius: 40px !important;
	padding: 32px;
	background-color: white;
	.ant-modal-content {
		position: none;
		border-radius: none;
		box-shadow: none;
		.ant-modal-body {
			.fund-transfer-section {
				display: flex;
				flex-direction: column;
				width: 100%;
				align-items: center;
				justify-content: center;
				.account-number-payroll-transfund-card-section {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					padding: 24px;
					gap: 16px;
					height: 156px;
					background: #f7f8fa;
					border-radius: 32px;
					.card-title-style {
						font-family: 'Montserrat-medium';
						font-style: normal;
						font-weight: 500;
						font-size: 20px;
						line-height: 24px;
						text-align: center;
						color: #00344e;
					}
					.account-number-holder {
						display: flex;
						flex-direction: row;
						align-items: flex-start;
						padding: 16px 32px;
						// width: 242px;
						height: 68px;
						background: #ffffff;
						border-radius: 24px;
						.acct-number-style {
							font-family: 'Montserrat-medium';
							font-style: normal;
							font-weight: 700;
							font-size: 32px;
							line-height: 36px;
							text-align: center;
							color: #484b52;
						}
					}

					.reconc-account-input {
						height: 40px;
						width: 346px;
						font-weight: bolder;
						color: #484b52;
					}
					.reconc-account-input::placeholder {
						color: #484b52;
						font-family: 'Montserrat-medium';
						font-size: 14px;
						line-height: 18px;
					}

					.input_img {
						bottom: 3px;
					}
				}
				.fund-transfer-info-section {
					display: flex;
					flex-direction: column;
					margin-top: 32px;
					align-items: center;
					justify-content: center;
					.info-title {
						font-family: 'Montserrat-bold';
						font-style: normal;
						font-weight: 700;
						font-size: 24px;
						line-height: 28px;
						text-align: center;
						color: #00344e;
					}

					.tranfer-fun-info-style {
						font-family: 'Montserrat-light';
						font-style: normal;
						font-weight: 300;
						font-size: 18px;
						line-height: 20px;
						margin-top: 9px;
						text-align: center;
					}

					.tranfer-fun-info-style2 {
						font-family: 'Montserrat-light';
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 16px;
						margin-top: 32px;
						text-align: center;

						.click-here-style {
							font-family: 'Montserrat-medium';
							font-style: normal;
							font-weight: 300;
							font-size: 14px;
							line-height: 16px;
							color: #0e5274;
							text-decoration: underline;
							cursor: pointer;
						}
					}
				}
				.fund-transfer-button-container {
					display: flex;
					margin-top: 32px;

					.undertood-style {
						font-family: 'Montserrat-medium';
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 20px;
						text-align: center;
						color: #f7f8fa;
					}
				}
			}
		}
	}
}
