.select-dropdown-container {
	width: 100%;
	position: relative;
	.dropdown_header {
		width: 100%;
		cursor: pointer;
		.dropdown_inner {
			-webkit-transition: 0.5s;
			display: flex;
			justify-content: space-around;
			align-items: center;
			width: 100%;
			padding: 7px;
			background-color: #ffffff;
			border-radius: 8px;
			height: 32px;
			position: relative;
			.dropdown_default {
				width: 100%;
				padding: 8px 4px 8px 8px;
				.dropdown_inner_list_main {
					font-family: 'Montserrat-bold';
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					color: #181919;
					line-height: 14px;
					margin-right: 8px;
				}
				.dropdown_inner_list_sub {
					font-family: 'Montserrat-light';
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					color: #9a9ea6;
					line-height: 14px;
				}
			}
		}
		.dropdown_arrow {
			.arrow-inner {
				height: 1.5em;
				width: 1.5em;
			}
		}
		.dropdown {
			width: 100%;
			border: none;
			outline: none;
			text-decoration: none;
		}
		.dropdown_list {
			// position: absolute;
			margin-top: 4px;
			margin-left: 2.5px;
			height: auto;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			background: #ffffff;
			border-radius: 8px;
			.dropdown_inner_list {
				max-height: 11em;
				overflow: auto;
				&::-webkit-scrollbar {
					width: 2px;
				}
			}
			.dropdown_list_search_main {
				width: 100%;
				height: 32px;
				border: 1px solid #dfe1e6;
				border-radius: 8px;
				padding: 9px 12px;
				display: flex;
				align-items: center;
				-webkit-transition: 0.5s;
				position: sticky;
				top: 0;
				background-color: #ffffff;
				input {
					color: #181919;
					font-family: 'Montserrat-Regular';
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					padding-left: 9.31px;
					&::placeholder {
						color: #9a9ea6;
						font-family: 'Montserrat-light';
						font-size: 12px;
						line-height: 14px;
					}
				}
			}
			.dropdown_list_search_main:focus-within {
				border: 1px solid #71b0d0;
			}
			.dropdown-list-sub {
				display: flex;
				justify-content: space-between;
				.dropdown_inner_list {
					display: flex;
					padding: 8px 4px 8px 8px;
					cursor: pointer;
					.dropdown_inner_list_main {
						font-family: 'Montserrat-bold';
						font-style: normal;
						font-weight: 500;
						font-size: 12px;
						color: #181919;
						line-height: 14px;
						margin-right: 8px;
					}
					.dropdown_inner_list_sub {
						font-family: 'Montserrat-light';
						font-style: normal;
						font-weight: 500;
						font-size: 12px;
						color: #9a9ea6;
						line-height: 14px;
					}
				}
				img {
					padding-right: 8px;
				}
			}
		}
	}
}
.select-dropdown-without-placeholder {
	margin-top: 25px;
	margin-bottom: 20px;
}
