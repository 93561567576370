.billing-screen {
	width: 100%;
	height: 100%;
	justify-content: center;
	background-color: #f7f8fa;
	margin-top: -30px;
	padding-top: 4%;
	align-content: flex-start;
	.for-flex-align {
		display: flex;
		justify-content: space-between;
		margin-bottom: 0%;
	}
	.billing-info {
		font-family: 'Montserrat-Bold';
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 28px;
		color: #00344e;
	}
	.back-button {
		font-family: 'Montserrat-Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: #484b52;
		border-radius: 8px;
		border: 0px;
		padding: 8px 16px;
		height: 32px;
		margin-right: 7px;
		cursor: pointer;
		border: 1px solid #dfe1e6;
		border-radius: 8px;
		background: none;
	}
	.continue-button {
		background: linear-gradient(93.76deg, #d10f00 1.98%, #f72717 100%);
		box-shadow: 0px 8px 16px -12px #d52b1e;
		border-radius: 8px;
		border: 0px;
		padding: 8px 16px;
		font-family: 'Montserrat-Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: #ffffff;
		cursor: pointer;
	}
	.billing-info-outer {
		background: #ffffff;
		border-radius: 8px;
		height: fit-content;
		margin-top: 0%;
		padding: 2%;
	}
	.billing-info-inner {
		background-color: #fbfcfd;
		border-radius: 8px;
		padding: 3% 2% 3% 2%;
	}
	.billing-info-text {
		font-family: 'Montserrat-Light';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		color: #484b52;
	}
	.billing-info-value {
		font-family: 'Montserrat-Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: #181919;
	}
	.billing-info-value-red {
		font-family: 'Montserrat-Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: #f72717;
	}
	.billing-info-Total {
		font-family: 'Montserrat-Bold';
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 20px;
		color: #181919;
	}
	.billing-info-div {
		display: flex;
		justify-content: space-between;
		padding-top: 2%;
	}
	.billing-info-inside-text {
		font-family: 'Montserrat-Light';
		font-style: normal;
		font-weight: 300;
		font-size: 12px;
		line-height: 14px;
		color: #737780;
	}
	.hint-box {
		border-radius: 8px;
		display: flex;
		align-items: center;
		.image-box {
			width: 32px;
			height: 32px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: #fef5e8;
			border-radius: 8px;
		}
		.hint-box-text {
			font-family: 'Montserrat-Regular';
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			margin-top: 12px;
			color: #484b52;
			padding-left: 16px;
		}
	}
}
.for-landing-model-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: 10%;
	padding-bottom: 2%;
	.landing-modal-header {
		font-family: 'Montserrat-Bold';
		font-style: normal;
		font-weight: 700;
		font-size: 22.8281px;
		line-height: 25px;
		color: #00344e;
		text-align: center;
		span {
			color: #f72717;
		}
	}
	.landing-modal-text {
		font-family: 'Montserrat-Regular';
		font-style: normal;
		font-weight: 300;
		font-size: 17.7552px;
		line-height: 20px;
		text-align: center;
		color: #737780;
		.not-avail-text {
			font-family: 'Montserrat-Medium';
		}
	}
}
