.for-text-center {
	.landing_page_header {
		background: linear-gradient(180deg, #eef5ff 0%, rgba(237, 248, 255, 0.9) 76.48%, rgba(237, 249, 255, 0) 100%);
	}
	.top-heading-text {
		font-family: 'Montserrat-Bold';
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 36px;
		text-align: center;
		color: #00344e;
	}
	.card-color {
		background-color: #fff6f5;
		padding: 5% 0 3% 0;
	}
	.price-card-bg {
		background-image: url('../../../assets/icons/not_focus/bg1.svg');
		// height: 1124px;
		background-repeat: no-repeat;
		background-size: cover;
		padding-bottom: 5%;
		margin-top: 3%;
		.price-card-heading {
			font-family: 'Montserrat-Bold';
			font-style: normal;
			font-weight: 700;
			font-size: 32px;
			line-height: 36px;
			text-align: center;
			color: #00344e;
			margin-top: 76px;
		}
		.total-count-row {
			display: flex;
			justify-content: center;
			gap: 16px;
			align-items: center;
			text-align: center;
		}
		.total-employees {
			font-family: 'Montserrat-Medium';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 18px;
			margin-bottom: 0px;
			color: #00344e;
		}
		.total-employee-counter {
			background: #feebe9;
			border-radius: 8px;
			padding: 8px;
			padding-left: 12px;
			padding-right: 12px;
			.input-box {
				width: 114px;
				padding-top: 8px;
				padding-bottom: 8px;
				margin-left: 10px;
				margin-right: 10px;
				border: 0px;
				border-radius: 8px;
				color: #00344e;
				font-family: 'Montserrat-Light';
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				text-align: center;
			}
		}
		.price-card-row {
			display: flex;
		}
	}
	.toolTipOverlay .ant-tooltip-inner {
		height: 28px !important;
		padding-bottom: 0px;
	}
}
@media screen and (max-width: 990px) {
	.for-text-center {
		text-align: center;
	}
}
.small-content{
	//styleName: Heading/Light/H5;
font-family: Montserrat;
font-size: 16px;
font-weight: 300;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
color:#737780


}
.param-text{
	font-family: Montserrat-light;
font-size: 14px;
font-style: italic;
font-weight: 300;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
color: #00344E;

}
.change-content{
	margin-bottom: -15px;
}