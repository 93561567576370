.personalInfoContainerNew {
	background-color: #f7f8fa;
	padding: 3%;
	padding-bottom: 15px;
	padding-top: 20px;
	margin-top: 1%;

	.personalInfo {
		font-family: 'Montserrat-Bold';
		font-size: 16px;
		line-height: 18px;
		color: #00344e;
	}
	.forBottomMargin {
		margin-bottom: 10px;
		position: relative;

		&:disabled {
			font-family: 'Montserrat-Regular';
			font-size: 14px;
			color: #484b52;
			line-height: 16px;
		}
	}
	.form-field__label {
		color: #737780;
		font-size: 10px !important;
		z-index: 1;
		display: flex;
		align-items: center;
		background-color: #f7f8fa !important;
		padding-top: 3px;
	}
	.ant-form-item {
		margin-bottom: 0px;
	}
	.ant-select {
		width: 100%;
	}
	.edenRedDropdown.dropdownfield .ant-select-selector {
		background-color: #f7f8fa;
		padding: 0 11px;
	}
	.form-labels-dropdown {
		background-color: #f7f8fa;
		color: #9a9ea6;
		left: -2px;
		top: 15px;
	}
	.ant-form-item-explain {
		margin-bottom: 12px;
	}
}
.saveCancelRow {
	margin-top: 34px;
	margin-bottom: 5%;
	.for-cancel {
		font-family: 'Montserrat-Bold';
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 18px;
		text-align: center;
		background: #f7f8fa;
		color: #484b52;
		margin-top: 0px;
		box-shadow: none;
		border: 1px solid #dfe1e6;
		&:disabled {
			background-color: #ffffff !important;
			font-family: 'Montserrat-Bold';
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 18px;
			text-align: center;
			color: #dfe1e6;
			margin-top: 0px;
			box-shadow: none;
			border: 1px solid #dfe1e6;
		}
		.for-save {
			font-family: 'Montserrat-Medium';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 20px;
		}
	}
}
