.main-div-hr {
	.first-div,
	.serach-box {
		width: 100px !important;
	}
	.serach-box {
		width: 100% !important;
	}
}
.forLogo {
	height: 39px;
	width: auto;
}
.cloud {
	height: 24px;
	width: auto;
}
.topLogo {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.downloadImg {
	display: flex;
	justify-content: flex-end;
}
.expDoc {
	font-family: 'Montserrat-Bold';
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #00344e;
	margin-top: 10px;
	margin-bottom: 10px;
}
.headContainer {
	// margin-top: 10px;
	height: 100%;
	position: relative;
	padding-bottom: 0;
}
.filter-button {
	background-color: #ffffff;
	width: 20px;
	border-radius: 8px;
	display: flex;
}

.hr {
	&.active {
		&:before {
			content: '';
			width: 10px;
			height: 10px;
			background: #d52b1e;
			border-radius: 50%;
			position: absolute;
			left: -5px;
			top: -5px;
			z-index: 1;
		}
	}
}
.startingPanel {
	margin-top: 10%;
	padding: 5%;
}
.site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
	margin-bottom: 24px;
	overflow: hidden;
	background: #ffffff;
	box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.5);
	border-radius: 16px;
	border-radius: 10px !important;
}
.ant-collapse-header {
	position: relative;
	height: 96px;
	padding: 12% !important;
	padding-left: 40px;
	color: rgba(0, 0, 0, 0.85);
	line-height: 1.5715;
	cursor: pointer;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.mobFilter {
	background-color: #ffffff;
	border: 2px solid #f7f8fa;
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
}
.search {
	border: 2px solid #f7f8fa;
	background-color: #ffffff;
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	border-radius: 8px;
}
.forSearch {
	margin: 0px;
	font-size: 16px;
	font-family: 'Montserrat-Regular';
	line-height: 18px;
	padding-left: 10px;
}
.valid-date {
	border-color: #484b52 !important;
}
.default-date {
	border-color: #dfe1e6 !important;
}
.expired-date {
	border-color: #e60a14 !important;
}
.expired-soon {
	border-color: #e4dd3d !important;
}
/* .navigationIcon {
	position: absolute;
	margin-top: 10%;
	margin-left: 40%;
} */
/* @media screen and (min-width: 700px) {
	body {
		display: none;
	}
} */
.onlyFilter {
	background-color: #ffffff;
}
.forWarnings {
	height: 40px;
	background-color: #eff1f5;
	border-radius: 8px;
	width: 40px;
	display: flex;
}
.summaryRow {
	margin-top: 5%;
	flex-wrap: nowrap;
	overflow-x: scroll;
}
.summaryRow::-webkit-scrollbar {
	display: none;
}
.summary {
	display: flex;
	width: 60vw;
	padding-bottom: 10px;
}
.summaryDocs {
	font-family: 'Montserrat-medium';
	margin-bottom: 3%;
	font-size: 12px;
	line-height: 14px;
	color: black;
}
.entireSummary {
	margin-left: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.forEmploy {
		margin: 0;
	}
}
.forEmploy {
	font-family: 'Montserrat-regular';
	color: black;
	font-size: 10px;
	line-height: 12px;
	font-weight: 300;
	margin-top: 0%;
}
.docImage {
	// padding-left: 7px;
	// padding-top: 5px;
}
.saveChangeOverlay {
	position: fixed;
	bottom: 60px;
	right: 0;
	left: 0;
	padding: 0 10px;
}
.saveChange {
	background-color: #ffffff;
	margin-bottom: 0;
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	height: 165px;
	width: 100%;
	border-radius: 20px;
	padding: 10px;
	box-shadow: 0px 0 20px rgba(174, 182, 183, 0.5);
}
.cancelContainer {
	background-color: #ffffff;
	margin-top: 10%;
	height: 280px;
	width: 100%;
	border-radius: 20px;
	padding: 10px;
	box-shadow: 0px -10px 10px rgba(174, 182, 183, 0.5);
	position: absolute;
	bottom: 0%;
	left: 0;
}
.changesWillNot {
	font-family: Montserrat-Regular;
	font-size: 16px;
	line-height: 18px;
	font-weight: 300;
	color: #737780;
}
.doYou {
	font-family: 'Montserrat-Bold';
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #00344e;
	margin-top: 20px;
	margin-bottom: 10px;
}
.Mobcancel {
	height: 48px;
	border: 1px solid #dfe1e6;
	color: #484b52;
	font-family: 'Montserrat-Bold';
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
	margin-top: 30px;
}
.saveChange1 {
	display: none;
}
.Mobprimary {
	// border-radius: 28px;
	// border: none;
	// font-weight: 500;
	// line-height: 20px;
	// text-align: center;
	// color: #f7f8fa;
	// outline: none;
	// width: 100%;
	// min-height: 48px;
	font-size: 16px !important;
	height: 48px;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%);
	box-shadow: 0px 10px 20px rgb(233 180 175 / 75%);
	margin-top: 30px;
}
.opacityContainer {
	opacity: 0.5;
}
.opacityContainer1 {
	opacity: 1;
}
@media screen and (max-width: 600px) {
	.web-view {
		display: none;
	}
}
@media screen and (min-width: 600px) {
	.mob-view {
		display: none;
	}
}

.infinite-scroll-component {
	padding: 0 8px 20px 8px;
	&::-webkit-scrollbar {
		width: 0; /* width of the entire scrollbar */
	}
	&::-webkit-scrollbar-track {
		background: white; /* color of the tracking area */
	}
	&::-webkit-scrollbar-thumb {
		background-color: white; /* color of the scroll thumb */
		border-radius: 20px; /* roundness of the scroll thumb */
		border: 3px solid white; /* creates padding around scroll thumb */
	}
}

.laborContainer {
	.flatpickr-input:focus {
		border: 1px solid#2B769C !important;
	}
}

.valid-date:focus,
.default-date:focus,
.expired-date:focus,
.expired-soon:focus {
	border: 1px solid#2B769C !important;
}
