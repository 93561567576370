@import '../../../constants/_variables.scss';

.header-title-color {
	color: #00344e;
}

.cell-even-color {
	background-color: #fbfcfd;
}

.bankdetails_container {
	display: flex;
	padding: 40px 40px 0px 40px;
	background-color: #f7f8fa;
	align-self: flex-start;
	width: 100%;
	.bankdetails_title {
		display: flex;
		flex-direction: column;
		// padding: 3% 1% 1% 2%;

		.bankdetail-info-container {
			margin-top: 8px;
			color: #484b52;
		}
	}
	.funds_invoice_tabs_container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		height: 64px;
		background: white;
		border-radius: 8px;
		padding-left: 32px;
		margin-top: 25px;
		.invoice-tab-switch-container {
			display: flex;
			flex-direction: row;
			height: fit-content;
			.button-bank-detail-left {
				border: 1px solid #dfe1e6;
				border-radius: 8px 0px 0px 8px;
				width: 187px;
				height: 32px;
				padding: 8px 16px;
			}

			.button-bank-detail-right {
				border: 1px solid #dfe1e6;
				border-radius: 0px 8px 8px 0px;
				width: 132px;
				height: 32px;
				margin-left: -1px;
				padding: 8px 16px;
			}
		}

		.ant-tabs-nav {
			position: inherit;
		}
		.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
			width: 239px;
		}
		.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 18px;
			text-align: center;
			color: #a51105;
		}
		.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
			padding-left: 24px;
			padding-right: 35px;
			border-bottom: 2px solid #dfe1e6;
			padding-bottom: 1px;
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 18px;
			text-align: center;
			color: #9a9ea6;
		}

		.ant-tabs-tab-btn:focus,
		.ant-tabs-tab-remove:focus,
		.ant-tabs-tab-btn:active,
		.ant-tabs-tab-remove:active {
			color: $secondaryColor5;
		}
		.ant-tabs-tab:hover {
			color: $secondaryColor5;
		}
		.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
			background: #d52b1e;
			height: 3px;
		}
		.bank_details_card {
			border: 2px solid $greyColor3;
			border-radius: 24px;
			box-shadow: none;
		}
	}
	.fund-info-and-transfer-fund-container {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		padding: 32px;
		margin-top: 24px;
		background-color: white;
		width: 100%;
		height: fit-content;
		gap: 32px;
		/* Colors/Neutral/neutral-white */

		// background: red;
		/* Shadows/shadow-lg */

		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;

		.info-cell {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 0px;
			width: 62%;
			height: 336px;
			border: 1px solid #eff1f5;
			border-radius: 8px;
			// background-color: #737780;

			.each-info-cell {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				padding: 0px;
				width: 100%;
				height: 48px;

				.name {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: center;
					padding: 17px 16px;
					width: 215px;
					height: 100%;
				}
				.value {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					padding: 16px 0px;
					width: 477px;
					height: 100%;
				}
			}
		}
		.bank-info {
			display: flex;
			flex-direction: column;
			align-self: flex-start;
			padding: 32px 24px;
			gap: 16px;
			width: 35%;
			height: fit-content;

			background: #f1f9ff;
			border-radius: 8px;
			.bank-title {
				display: flex;
				width: 100%;
				justify-content: center;
				.bank-acc-title {
					color: #484b52;
				}
			}
			// .accnumber-or-tranfer-code {
			// 	display: flex;
			// 	flex-direction: row;
			// 	justify-content: center;
			// 	align-items: center;
			// 	padding: 8px 16px;
			// 	background-color: #ffffff;
			// 	width: 100%;
			// 	height: 32px;
			// 	border-radius: 8px;
			// 	.acc-number-style {
			// 		color: #484b52;
			// 	}

			// div {
			// width: 100%;

			// .inplace-acc-editor-icon {
			// 	position: absolute;
			// 	display: flex;
			// 	margin-left: 252px;
			// }
			// }
			// }
			// }

			.accnumber-box {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				padding: 8px 16px;
				background-color: #ffffff;
				width: 100%;
				padding: 8px 16px;
				height: 32px;
				border-radius: 8px;
				.acc-number-style {
					color: #484b52;
				}
			}

			.inplace-acc-editor {
				width: 100%;
			}
			// .editable-input-box {
			// 	display: flex;
			// 	flex-direction: row;
			// 	justify-content: center;
			// 	align-items: center;
			// 	padding: 8px 16px;
			// 	background-color: #ffffff;
			// 	width: 100%;
			// 	height: 32px;
			// 	border-radius: 8px;
			// 	.acc-number-style {
			// 		color: #484b52;
			// 	}
			// }

			.info-box {
				display: flex;
				flex-direction: row;
				.info-style {
					text-align: center;
					color: #181919;
				}
			}
			.click-here-box {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 16px;
				gap: 8px;
				width: fit-content;
				// height: 102px;

				border: 1px dashed #0e8aff;
				border-radius: 8px;
				.click-here-style {
					display: flex;
					.click-here-para-style {
						color: #484b52;
						.click-here-font-style {
							font-family: 'Montserrat-medium';
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							line-height: 12px;
							color: #00344e;
							cursor: pointer;
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
	.enter-accnt-number-pop-up-container {
		display: flex;
		padding: 0px 24px 0px 24px;
		flex-direction: column;

		.bank-acct-title {
			display: flex;
			text-align: center;
			width: 100%;
			justify-content: center;
		}
		.bank-acct-info {
			display: flex;
			text-align: center;
			margin-top: 8px;
		}
		.bank-acct-input {
			margin-top: 24px;

			.input-text-edit-icon {
				position: absolute;
				display: flex;
				margin-left: 327px;
			}
		}
	}

	.proceed-container {
		display: flex;
		flex-direction: column;
		padding: 0px 24px 0px 24px;
		width: 100%;
		.proceed-header {
			display: flex;
			width: 100%;
			justify-content: center;
			.proceed-acct-header-style {
				text-align: center;
			}
		}

		.proceed-info {
			display: flex;
			width: 100%;
			margin-top: 8px;
			.proceed-acct-info {
				text-align: center;
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.bankdetails_container {
		padding: 64px 1% 1% 2%;
		align-self: flex-start;
		.bankdetails_title {
			padding: 1% 1% 1% 2%;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: #00344e;
			}
		}
		.bankdetails_description {
			padding: 1% 1% 1% 2%;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: #737780;
			}
		}
		.funds_invoice_tabs_container {
			padding: 1% 1% 2% 2%;
			.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
				width: 239px;
			}
			.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				text-align: center;
				color: #a51105;
			}
			.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
				color: $greyColor6;
				font-family: 'Montserrat-Regular';
				font-size: 10pt;
				font-weight: 300;
				padding-left: 10px;
				padding-right: 10px;
				border-bottom: 2px solid #dfe1e6;
				padding-bottom: 5px;
			}
			.bank_details_card {
				padding: 0%;
				border: none;
				box-shadow: none;
				.ant-card-body {
					padding: 0% !important;
				}
			}
		}
	}
}
