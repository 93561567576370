@import '../../../constants/variables';
.header-outer {
	// height: 72px;
	background: $neutral-white;
	padding: 24px 40px;
	width: 100%;
	display: flex;
	.content {
		width: 95%;
		display: flex;
		gap: 18px;
		.arrow {
			// width: 95%;
			display: flex;
			align-items: center;
			.arrowimage {
				height: 16px;
			}
		}
		.company {
			display: flex;
			gap: 8px;
			// width: 58%;
			.companyname {
				font-family: Montserrat-medium;
				font-size: 16px;
				font-weight: 500;
				line-height: 18px;
				letter-spacing: 0em;
				text-align: left;
				color: $blue-500;
				margin-bottom: 0px;
				display: flex;
				align-items: center;
			}
			.coporateid {
				//styleName: Typography/Light/B1 Light 14pt;
				font-family: Montserrat;
				font-size: 14px;
				font-weight: 300;
				line-height: 16px;
				letter-spacing: 0em;
				text-align: left;
				color: $blue-light;
				margin-bottom: 0px;
				display: flex;
				align-items: center;
			}
		}
	}
	.image {
		width: 5%;
		display: flex;
		justify-content: end;
		.headerimage {
			height: 24px;
		}
	}
}
