@import '../../../constants/_variables.scss';

@media only screen and (max-width: 600px) {
	.add_employees_container {
		padding: 65px 0px 0px 0px;
		.add_employees_title {
			padding: 12px 16px 12px 14px;
			span {
				font-size: 20px;
				line-height: 24px;
			}
		}
		.add_employees_type_card_section {
			display: flex;
			flex-direction: column;
			.add_employee_card_column {
				padding: 8px;
				.edenredEmployeesCard_main {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 80px;
					min-height: 80px;
					.ant-card-body {
						padding: 4px;
					}
				}
			}
		}
	}

	//----upload-multiple-employees-page----
	.upload-speadsheet-col-content {
		width: 100%;
		height: 100%;
		.upload_employees_spreadsheet_container {
			padding: 65px 0px 65px 0px;
			.upload_employees_spreadsheet_title {
				padding: 8px 16px 12px 14px;
				span {
					font-size: 20px;
					line-height: 24px;
				}
			}
			.ant-form {
				width: 100%;
			}
			.upload_employees_dragger_container {
				width: 100%;
				display: flex;
				justify-content: center;
				padding: 5px 0px 5px 0px;
				.upload_dragger {
					background: #ffffff;
					box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
					border-radius: 16px;
					width: 180px;
					.upload_dragger_desktop_row {
						display: none;
					}
					.upload_dragger_mobile_row {
						display: flex !important;
						width: 100%;
						.upload_indication_image {
							padding: 4px;
							img {
								width: 96px;
								height: 96px;
							}
						}
						.dragger_mobile_view_hint {
							padding: 6px;
							span {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 14px;
								line-height: 16px;
								text-align: center;
								color: $primaryColor5;
							}
						}
						.after_file_upload {
							display: inline-grid;
							padding: 6px;
							.file_name_icon {
								position: initial;
								.file_name {
									font-family: Montserrat-Medium;
									font-style: normal;
									font-weight: 500;
									font-size: 12px;
									line-height: 14px;
									text-align: center;
									color: $primaryColor5;
									padding: 4px 0px;
								}
								.updeleteicon {
									position: inherit;
									text-align: center;
									cursor: pointer;
									svg {
										fill: $primaryColor5;
									}
								}
							}
							.file_size {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 12px;
								line-height: 14px;
								text-align: center;
								color: $primaryColor5;
								padding: 4px 0px;
							}
						}
					}
				}
				.ant-upload-list-item {
					display: none;
				}
			}
			.download_spreadsheet_template_hint {
				padding: 12px 8px 12px 16px;
				span {
					font-size: 16px;
					line-height: 18px;
					color: $greyColor6;
				}
			}
			.download_spreadsheet_template_button {
				padding: 12px 0px;
				display: flex;
				justify-content: center;
				button {
					width: 228px;
					min-width: 228px;
				}
			}

			.errorNote {
				border: 1px solid $feedBackRed;
				box-sizing: border-box;
				border-radius: 8px;
				display: flex;
				align-items: center;
				flex-direction: row;
				width: auto;
				padding: 5px;
				margin: 8px 8px 8px 12px;
				.anticon {
					padding: 10px 8px;
					svg {
						background: $secondaryColor6;
						border-radius: 50%;
					}
				}
				.errmsg {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 16px;
					color: $greyColor7;
				}
			}

			.validate_table {
				width: -webkit-fill-available;
				margin: 10px 12px;
				border-radius: 8px;
				border: 1px solid $greyColor4;
				border-collapse: separate;
				thead,
				tbody {
					tr {
						th,
						td {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 12px;
							line-height: 14px;
							color: $greyColor7;
							padding: 8px;
							border-right: 1px solid $greyColor4;
							border-bottom: 1px solid $greyColor4;
						}
						th {
							color: $greyColor5;
						}
					}
				}
				tr:last-child td {
					border-bottom: none !important;
				}
				tr td:last-child,
				tr:first-child th:last-child {
					border-right: none !important;
				}
			}

			.upload_employees_spreadsheet_next_button {
				padding: 6px 16px;
				margin-top: 50px;
			}
		}
	}

	//---- multiple-employees-submit page----
	.multiple_employees_submit_container {
		padding: 65px 0px 0px 0px;
		width: 100%;
		.multiple_employees_submit_button_row {
			width: 100%;
			display: flex;
			align-items: center;
			padding: 8px 4px 6px 12px;
			.multiple_employees_submit_title {
				span {
					font-size: 20px;
					line-height: 24px;
				}
			}
			.delete_multiple_employees_submit_button {
				button {
					font-size: 12px;
					line-height: 14px;
				}
			}
		}
		.multiple_employees_submit_hint {
			padding: 4px 4px 4px 12px;
			span {
				color: $greyColor6;
			}
		}
		.submit_multiple_employees_list_section {
			padding: 4px 4px 4px 12px;
			.multiple_employees_list_row {
				.multiple_employees_name_employee_id_title {
					span {
						font-size: 14px;
						line-height: 16px;
					}
				}
				.multiple_employees_name_employee_id_column {
					span {
						font-size: 16px;
						line-height: 18px;
					}
				}
			}
			.ant-divider-horizontal {
				margin: 12px 0px;
			}
		}
	}
	.multiple_employees_submit_modal {
		width: 100%;
		height: 100%;
		button {
			font-weight: bold;
		}

		.Modal_Edenred {
			width: 100%;
			position: absolute;
			bottom: 0;
			z-index: 10;
		}
		.Modal_Edenred .Remove_user {
			padding: 0% 5%;
			color: #00344e;
		}
		.Modal_Edenred .user_login_mobile {
			padding: 1% 0% 6% 5%;
			margin: 0%;
			color: #737780;
		}
	}

	//---multiple-employees-next-steps-page----
	.multiple_employees_next_steps_container {
		display: flex;
		flex-direction: row;
		padding: 64px 2% 1% 2%;
		.multiple_employees_next_steps_header {
			padding: 1% 2% 0% 2%;
			span {
				font-size: 20px;
				line-height: 24px;
			}
		}
		.multiple_employees_next_steps_message {
			padding: 3% 2% 2% 2%;
			span {
				font-size: 16px;
				line-height: 18px;
				color: $greyColor6;
			}
		}
		.multiple_employees_next_steps_status {
			padding: 1% 1% 1% 1%;
			.multiple_employees_next_steps_status_container {
				display: flex;
				align-items: center;
				padding: 1% 1% 0% 1%;
				.multiple_employees_next_steps_status_icon {
					display: contents;
					img {
						background: $greyColor1;
						box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
						border-radius: 16px;
					}
				}
				.multiple_employees_next_steps_status_content {
					padding: 1% 1% 0% 4%;
					span {
						font-size: 14px;
						line-height: 16px;
						color: $greyColor7;
						a {
							font-size: 14px;
							line-height: 16px;
							color: $primaryColor4;
							text-transform: lowercase;
						}
					}
				}
			}
		}
		.multiple_employees_next_steps_button {
			display: flex;
			justify-content: center;
			padding: 4% 1% 0% 1%;
			button {
				font-size: 14px;
				line-height: 16px;
				color: $primaryColor5;
				padding: 1%;
				width: 238px;
				min-width: 238px;
			}
		}
	}
}
