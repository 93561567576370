.for-flex-alignment {
	display: flex;
	justify-content: space-between;
	gap: 24px;
}
.get-discount-text {
	font-family: 'Montserrat-Bold';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	color: #f7f8fa;
	margin-top: 14px;
}
.early-bird-text {
	font-family: 'Montserrat-Light';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	color: #f7f8fa;
	margin-top: 14px;
	padding-left: 12px;
}
.get-early-bird {
	font-family: 'Montserrat-Regular';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	color: #ffffff;
	margin-top: 14px;
	text-decoration: underline;
	cursor: pointer;
}
.arrow-image {
	width: 20px;
	padding-left: 5px;
	cursor: pointer;
}
.payroll-heading {
	font-family: 'Montserrat-Bold';
	font-style: normal;
	font-weight: 700;
	font-size: 32px;
	line-height: 36px;
	color: #00344e;
	margin-bottom: 12px;
}
.payroll-paragraph {
	font-family: 'Montserrat-Light';
	font-weight: 300;
	font-size: 18px;
	line-height: 24px;
	color: #484b52;
}
.payroll-upgrade-button {
	font-family: 'Montserrat-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #ffffff;
	background: linear-gradient(93.76deg, #d10f00 1.98%, #f72717 100%);
	box-shadow: 0px 8px 16px -12px #d52b1e;
	border-radius: 8px;
	padding: 8px 16px;
	border: none;
	cursor: pointer;
}
