.forContainer {
	min-height: 96px;
	width: auto;
	background-color: #ffffff;
	border: 1px solid #f1f1f1;
	padding: 10px;
	border-radius: 16px;
	margin-top: 2%;
	text-transform: scale(1.08);
	box-shadow: 0px 6px 13px 0px rgb(174 182 183 / 32%);
}
.allSigns {
	position: relative;
	display: grid;
	justify-items: right;
}
.alertIcon {
	width: auto;
	position: absolute;
}
.docIcon {
	width: auto;
	position: absolute;
	margin-top: 5px;
	margin-right: -3px;
}
.warningIcon {
	width: auto;
	position: absolute;
}
.navigationIcon {
	display: flex;
	justify-content: center;
}
.uptoIcon {
	height: 35px;
	width: auto;
	position: absolute;
}
.navigationIcon1 {
	display: none;
}
.forAlign {
	margin-bottom: 3%;
	font-family: 'Montserrat-Medium';
	font-size: 14px;
	line-height: 16px;
	font-weight: 500;
	color: #00344e;
	text-decoration: underline;
}
.nameSymbol {
	display: flex;
	justify-content: space-between;
}
.innerContainer {
	min-height: 56px;
	width: 100%;
	border: 1px solid rgba(174, 182, 183, 0.5);
	border-radius: 8px;
	margin-top: 2%;
}
.navigationUp {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}
.detail {
	width: 100%;
}
.outerContainer {
	position: relative;
}
.laborContainer {
	outline: none;
	font-size: 14px;
	padding: 4px 4px;
	display: block;
	width: 100%;
	height: 56px;
	box-sizing: border-box;
	background-color: transparent;
	border: none;
	border-radius: 8px;
	border: 2px solid #dfe1e6;
	padding-left: 3%;
	margin-top: 5%;
}
.Labour-headings {
	position: absolute;
	/* -webkit-transition: 0.2s ease all; */
	margin-left: 3%;
	padding: 0 5px;
	font-family: Montserrat-Regular;
	font-style: normal;
	font-weight: 300;
	top: -6px;
	font-size: 8pt;
	color: #9a9ea6;
	background-color: #ffffff;
	// z-index: 1;
}
