.company_setup {
	display: flex;
	background-color: #f7f8fa;
	flex-direction: column;
	width: 100%;
	height: fit-content;
	min-height: 100%;
	align-items: center;
	padding-left: 239px;
	padding-right: 239px;
	.company_setup_header {
		// margin: 25px 0 15px -25px;
		// span {
		// 	font-family: 'Montserrat-Bold';
		// 	font-style: normal;
		// 	font-weight: 700;
		// 	font-size: 24px;
		// 	line-height: 28px;
		// 	color: #00344e;
		// }
	}
	.company_setup_container {
		display: flex;
		flex-direction: column;
		width: 960px;

		.row-company-setting {
			width: 960px;
			.company-setting-headerRow {
				padding: 0px;
				.company-setting-setup .cancel_button .company-set-cancel {
					border: 1px solid #dfe1e6 !important;
					font-family: 'Montserrat-regular';
					font-size: 14px;
					line-height: 16px;
					width: 100px;
					height: unset;
					padding: 8px;
				}
			}
			.company-setting-main-container {
				padding: 0px;
			}
		}
		.row-company-setting .company-setting-tabpane-row1 .overtime-container {
			display: none;
		}
	}
}
