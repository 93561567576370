.invoice_left_section {
	width: 287px;
	display: grid;
	gap: 10px;
	min-height: 400px;
	background: #ffffff;
	margin-right: 24px;
	padding: 32px;
	box-shadow: 0px 16px 32px -24px #bdbdbd;
	border-radius: 8px;
	.pending_invoice_amount_container {
		display: flex;
		flex-direction: column;
		align-items: center;
		.pending_invoice_amount_loader {
			background: #cee8ff;
			display: flex;
			border-radius: 12px;
			align-items: center;
			justify-content: center;
			height: 64px;
			width: 64px;
		}
	}
	.invoice_options_container {
		border-top: 1px solid #eff1f5;
		border-bottom: 1px solid #eff1f5;
		padding: 16px 0;
		display: grid;
		gap: 8px;
		.icon-container{
			height: 32px;
			width: 32px;
			background-color: #FFFFFF;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
