@import '../../../../constants/_variables.scss';

.replacement-card-container {
	width: 100%;
	padding: 20px 135px 0px 135px;
	.replacement-card-header {
		width: 100%;
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.replacement-card-subheader {
		width: 100%;
		padding: 24px 0px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.replacement-card-location {
		display: flex;
		align-items: center;

		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $primaryColor5;
		}
		img {
			width: 30px;
			height: 30px;
		}
	}
	// .replacement-order {
	// 	padding-top: 30px;

	// 	.replacement-card-change {
	// 		padding-right: 15px;
	// 		span {
	// 			font-family: Montserrat-Medium;
	// 			font-style: normal;
	// 			font-weight: 500;
	// 			font-size: 16px;
	// 			line-height: 18px;
	// 			text-align: right;
	// 			color: $primaryColor5;
	// 		}
	// 		.replacement-card {
	// 			span {
	// 				font-family: Montserrat-Regular;
	// 				font-style: normal;
	// 				font-weight: 300;
	// 				font-size: 16px;
	// 				line-height: 18px;
	// 				color: $greyColor6;
	// 			}
	// 		}
	// 	}

	// 	.replacement-horizontal {
	// 		border: 0.1px solid $greyColor4;
	// 		margin-top: 15px;
	// 		margin-bottom: 15px;
	// 	}
	// }
	.replacement-excluding {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding-top: 10px;
		// .excluding {
		// 	display: flex;
		// 	justify-content: flex-end;
		// 	padding-right: 25px;
		// 	span {
		// 		font-family: Montserrat-Regular;
		// 		font-style: normal;
		// 		font-weight: 300;
		// 		font-size: 10px;
		// 		line-height: 12px;
		// 		color: $greyColor5;
		// 	}
		// }
		button {
			margin-top: 25px;
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 20px;
			text-align: center;
			color: $greyColor2;
		}
	}
}
.replacement-modal {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100%;
	.Modal_Edenred {
		width: 29%;
		.Remove_user {
			padding-top: 15px;
			span {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				display: flex;
				align-items: flex-end;
				color: $primaryColor5;
			}
		}
		.user_login {
			padding-top: 4px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: $greyColor7;
			}
		}
	}
}
// transaction-detail-container
.transaction-detail-container {
	padding: 0px 40px 40px 40px;
	align-self: flex-start;
	.transaction-detail-header {
		padding: 40px 0px 24px 0px;
		display: flex;
		align-items: center;
		gap: 8px;
		span {
			width: 100%;
		}
	}
	.form-record {
		background: #ffffff;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 32px;
		.ant-form {
			.ant-form-item {
				margin: 0px !important;
				padding: 8px 0px;
				.inputlabelrequired::after {
					content: ' *';
					color: red;
				}
			}
		}
		.transaction-details-input {
			margin-top: 24px;
			width: 100%;
			.margin-top-16 {
				margin-top: 16px;
			}
			.transaction-record1 {
				padding-right: 32px;
				.floating-label {
					.form-field__label {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						color: $greyColor6;
					}
				}
			}
		}
		.transaction-button {
			padding-top: 25px;
		}
	}
}
.transaction-modal {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100%;
	.Modal_Edenred {
		width: 30%;
		.Button_container .Yes {
			color: $greyColor1;
			font-weight: bold;
			background: $secondaryColor3;
			display: flex;
			align-items: center;
			justify-content: center;
			border-bottom-right-radius: 16px;
		}
		.Remove_user {
			padding-top: 30px;
			span {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				color: $primaryColor5;
			}
		}
		.user_login {
			padding-top: 4px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 15px;
				line-height: 18px;
				color: $greyColor7;
			}
		}
	}
}

// change-bank-account
.change-bank-account-container {
	padding: 10px 135px 0px 135px;

	.change-bank-account-header {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.change-bank-account-content {
		display: flex;
		flex-direction: column;
		padding: 8px 0px;
		span {
			padding: 8px 0px;
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}

	.change-bank-account-button {
		padding: 12px 15px 0px 0px;
	}
}
// change-bank-number-button
.change-bank-account-number {
	padding: 10px 135px 0px 135px;

	.change-bank-account-header {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.change-bank-account-content {
		padding: 30px 0px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	form.ant-form.ant-form-horizontal {
		width: 100%;
	}
	.ant-form {
		.ant-form-item {
			.inputlabelrequired::after {
				content: ' *';
				color: red;
			}
		}
	}
	.form-field__label {
		font-family: Montserrat-Regular;
		font-style: normal;
		font-weight: 300;
		color: $greyColor6;
	}
}
// request-statement
.request-statement-container {
	padding: 20px 135px 0px 135px;
	width: 100%;
	.request-statement-user {
		padding: 24px 0px 0px 0px;
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.request-statement-content {
		padding: 24px 0px 0px 0px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.request-statement-dropdown {
		width: 100%;
		display: flex;
		flex-direction: row;
		padding: 24px 0px 0px 0px;
		.request-statement-from {
			span {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				color: $primaryColor5;
				padding-bottom: 4px;
			}
			.request-statement-desktop-row {
				display: flex;
				width: 100%;
				.request-statement-date {
					padding-right: 12px;
					.ant-select {
						display: flex;
						font-family: 'Montserrat-Medium';
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 20px;
						color: $primaryColor5;
						.ant-select-selector {
							display: flex;
							align-items: center;
							justify-content: center;
							height: 52px;
							width: 100%;
							border: none;
							border-radius: 16px;
							box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
						}
						.ant-select-arrow {
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 20px;
							color: #11266900;
							content: url('../../../../assets/icons/not_focus/Down.svg');
							height: 28px;
							width: 28px;
							margin-top: -15px;
						}
						.ant-select-item {
							font-family: 'Montserrat-Medium';
							font-size: 14pt;
							font-weight: 500;
						}
					}
				}
			}
			.request_statement_select_mobile_row {
				display: none;
			}
		}
	}
	.request-statement-horizontal {
		height: 0.001px;
		max-height: 0.001px;
		background: $greyColor4;
		margin: 32px 0px 0px 0px;
	}
	.request-statement-card {
		padding: 24px 0px 0px 0px;
		.request-statement-cols {
			padding: 7px 18px 0px 0px;
			.request-statement-softCopy {
				background: $greyColor1;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				border-radius: 16px;
				height: 80px;
				min-height: 80px;

				.request-statement-image {
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.request-statement-text {
					display: flex;
					text-align: center;
					align-items: center;
					span {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 20px;
						color: $primaryColor5;
					}
				}
			}
		}
	}
	.request-statement-reuestbutton {
		width: 100%;
		padding: 32px 0px 0px 0px;
	}
	.request-statement-content-charge {
		padding: 32px 0px 0px 0px;
		span {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 18px;
			color: $primaryColor5;
		}
	}
	.request-statement-either {
		padding-top: 20px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.soft-copy-email-sending-button {
		margin-top: 18px;
		width: 100%;
	}
}
// statement-delivered
.statement-delivered-container {
	padding: 10px 135px 0px 135px;
	.statement-delivered-header {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.statement-delivered-form {
		padding-top: 40px;
		.ant-form {
			.ant-form-item {
				margin: 0px !important;
				padding: 8px 0px;
				.inputlabelrequired::after {
					content: ' *';
					color: red;
				}
			}
		}
		.statement-delivered-fromItem {
			padding-right: 15px;
			.form-field__label {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				color: $greyColor6;
			}
		}
	}
}
// preparing sttaement
.preparing-statement-container {
	display: none;
}
.employee-document-container {
	display: none;
}
.view-salary-history-container {
	display: none;
}

@media only screen and (max-width: 600px) {
	.replacement-card-container {
		padding: 65px 15px 80px 15px;
		width: 100%;
		.replacement-card-header {
			width: 100%;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: $primaryColor5;
			}
		}
		.replacement-card-subheader {
			width: 100%;
			padding-top: 4px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: $greyColor6;
			}
		}
		.replacement-card-location {
			display: flex;
			align-items: center;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: $primaryColor5;
			}
			img {
				width: 35px;
				height: 35px;
			}
		}
		// .replacement-order {
		// 	.replacement-card-change {
		// 		span {
		// 			font-family: Montserrat-Medium;
		// 			font-style: normal;
		// 			font-weight: 500;
		// 			font-size: 16px;
		// 			line-height: 18px;
		// 			text-align: right;
		// 			color: $primaryColor5;
		// 		}
		// 		.replacement-card {
		// 			span {
		// 				font-family: Montserrat-Regular;
		// 				font-style: normal;
		// 				font-weight: 300;
		// 				font-size: 16px;
		// 				line-height: 18px;
		// 				color: $greyColor7;
		// 			}
		// 		}
		// 	}
		// 	.replacement-horizontal {
		// 		border: 0.1px solid $greyColor4;
		// 		margin-top: 8px;
		// 		margin-bottom: 8px;
		// 	}
		// }
		.replacement-excluding {
			display: flex;
			flex-direction: column;
			width: 100%;
			padding-top: 10px;
			// .excluding {
			// 	display: flex;
			// 	justify-content: flex-end;
			// 	padding-right: 33px;
			// 	span {
			// 		font-family: Montserrat-Regular;
			// 		font-style: normal;
			// 		font-weight: 300;
			// 		font-size: 10px;
			// 		line-height: 12px;
			// 		color: $greyColor5;
			// 	}
			// }
			button {
				margin-top: 25px;
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 18px;
				text-align: center;
				color: $greyColor2;
			}
		}
	}
	.replacement-modal {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		min-height: 100%;
		.Modal_Edenred {
			border-top-left-radius: 20px;
			box-shadow: 0px -10px 20px rgba(174, 182, 183, 0.25);
			height: 225px;
			border-top-right-radius: 20px;
			width: 100%;
			display: flex;
			align-items: flex-end;
			.Remove_user span {
				display: flex;
				align-items: flex-end;
				font-family: 'Montserrat-Bold';
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: $primaryColor5;
			}
			.button_mobile_role {
				display: flex !important;
				justify-content: space-evenly;
				width: 100%;
				padding: 0px 15px 59px 3px;
			}
		}
	}
	// transaction-detail-container
	.transaction-detail-container {
		padding: 65px 15px 0px 15px;
		.transaction-detail-header {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				color: $primaryColor5;
			}
		}
		.transaction-details-input {
			width: 100%;
			.transaction-record {
				padding: 12px 12px 0px 0px;
			}
		}
	}
	.transaction-modal {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		min-height: 100%;
		.Modal_Edenred {
			width: 100%;
			display: flex;
			align-items: flex-end;
			height: 220px;
			min-height: 220px;
			.button_mobile_role {
				padding: 0px;
				.gobackButton {
					padding: 0px 8px;
					span {
						font-family: 'Montserrat-Bold';
						font-style: normal;
						font-weight: bold;
						font-size: 16px;
						line-height: 18px;
						text-align: center;
						color: $greyColor7;
					}
				}
			}
			.Remove_user span {
				font-family: 'Montserrat-Bold';
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: $primaryColor5;
			}
			.user_login_mobile {
				display: flex !important;
				margin: 5px 25px 15px 20px;
			}
			.user_login_mobile {
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: $greyColor6;
				}
			}
			.button_mobile_role {
				padding: 0px 15px 16px 10px;
			}
		}
	}
	// change-bank-account
	.change-bank-account-container {
		padding: 65px 15px 0px 15px;
		.change-bank-account-header {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				color: $primaryColor5;
			}
		}
		.change-bank-account-content {
			display: flex;
			flex-direction: column;
			padding: 8px 0px;
			span {
				padding: 8px 0px;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: $greyColor6;
			}
		}
		.change-bank-account-button {
			padding: 12px 15px 0px 0px;
			button {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				text-align: center;
			}
		}
	}
	// change-bank-number-button
	.change-bank-account-number {
		padding: 65px 15px 0px 15px;
		.change-bank-account-header {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: $primaryColor5;
			}
		}
		.change-bank-account-content {
			padding: 0px 0px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: $greyColor6;
			}
		}
		.change-bank-account-input {
			padding: 20px 0px 20px 0px;
		}
		.form-field__label {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			color: $greyColor6;
		}
	}
	// request-statement
	.request-statement-container {
		padding: 65px 15px 70px 15px;
		width: 100%;
		.request-statement-user {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: $primaryColor5;
			}
		}
		.request-statement-content {
			padding-top: 0px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: $greyColor6;
			}
		}
		.request-statement-dropdown {
			display: inline;
			flex-direction: column;
			padding: 8px 0px;
			.request-statement-from {
				padding: 6px 0px;
				.request-statement-desktop-row {
					display: none;
				}
				.request_statement_select_mobile_row {
					display: flex !important;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					width: 100%;
					padding: 4px 0px 4px 0px;
					.request_statement_select_month {
						display: flex;
						justify-content: space-between;
						align-items: center;
						height: 48px;
						min-height: 48px;
						// width: 156px;
						// min-width: 156px;
						padding: 4px;
						background: $greyColor1;
						box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
						border-radius: 16px;
						span {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 16px;
							color: $primaryColor5;
						}
						img {
							width: 24px;
							height: 24px;
						}
					}
					.request_statement_select_year {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						height: 48px;
						min-height: 48px;
						width: 156px;
						min-width: 156px;
						padding: 4px;
						background: $greyColor1;
						box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
						border-radius: 16px;
						span {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 16px;
							color: $primaryColor5;
						}
						img {
							width: 24px;
							height: 24px;
						}
					}
				}
			}
		}
		.request-statement-horizontal {
			display: none !important;
		}
		.request-statement-card {
			.request-statement-softCopy {
				background: $greyColor1;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				border-radius: 16px;
				height: 78px;
				min-height: 78px;
				// width: 158px;
				// min-width: 158px;
				padding: 12px;
				.request-statement-image {
					img {
						width: 30px;
						height: 30px;
					}
				}
				.request-statement-text {
					display: flex;
					justify-content: center;
					align-items: flex-start;
				}
			}
		}
		.request-statement-either {
			padding-top: 0px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: $greyColor6;
			}
		}
	}
	.statement-delivered-container {
		padding: 65px 15px 0px 15px;
		.statement-delivered-header {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: $primaryColor5;
			}
		}
		.statement-delivered-form {
			padding-top: 5px;
		}
	}
	// preparing sttaement
	.preparing-statement-container {
		display: flex !important;
		padding: 65px 15px 0px 15px;
		.preparing-statement-header {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: $primaryColor5;
			}
		}
		.preparing-statement-statement {
			height: 180px;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				height: 120px;
			}
		}
		.preparing-statement-content {
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: $greyColor6;
			}
		}
	}
	// employee-documment
	.employee-document-container {
		display: flex !important;
		padding: 65px 15px 0px 15px;
		.employee-document-header {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: $primaryColor5;
			}
		}
		.employee-document-form {
			padding-top: 30px;
			.ant-form-item {
				margin-bottom: 10px;
				vertical-align: top;
			}
			.form-field__label {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				color: $greyColor5;
			}
		}
	}
	.view-salary-history-container {
		display: flex !important;
		padding: 65px 15px;
		.view-salary-history-header {
			margin: 12px 0px;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: $primaryColor5;
			}
		}
		.view-salary-history-card {
			height: 66px;
			min-height: 66px;
			margin-top: 10px;
			border: 2px solid $greyColor3;
			box-sizing: border-box;
			border-radius: 16px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 12px;
			.main-payroll {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: $primaryColor5;
			}
			.amount {
				padding-top: 5px;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: $greyColor7;
			}
		}
		.view-salary-history {
			padding-top: 25px;
			button {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 18px;
			}
		}
	}
}
.message-text-alignment {
	.popup_heading_text {
		padding-top: 0px !important;
	}
}
