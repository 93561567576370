@import '../../../constants/_variables.scss';

.not_paid_error_container {
	padding: 0% 9% 0% 9.5%;
	.not_paid_warning_card {
		display: flex;
		justify-content: space-around;
		align-items: center;
		padding: 0% 1% 2% 0%;
		.not_paid_error_message_warning {
			img {
				height: 60px;
				width: 60px;
			}
		}
		.not_paid_error_message_title {
			span {
				font-size: 20pt;
				font-weight: bold;
				line-height: 24px;
				color: $primaryColor5;
			}
		}
	}
	.not_paid_error_message_advise {
		padding: 0% 1% 1% 0%;
		span {
			font-size: 14pt;
			font-weight: 400;
			line-height: 20pt;
			color: $greyColor7;
		}
	}
	.not_paid_error_message_button {
		padding: 1% 1% 0% 0%;
		width: 100%;
		button {
			font-family: 'Montserrat-Medium';
			font-weight: 600;
		}
	}
}

.not_submitted_error_container {
	padding: 0% 9% 0% 9.5%;
	.not_submitted_warning_card {
		display: flex;
		justify-content: space-around;
		align-items: center;
		padding: 0% 1% 2% 0%;
		.not_submitted_error_message_warning {
			img {
				height: 60px;
				width: 60px;
			}
		}
		.not_submitted_error_message_title {
			span {
				font-size: 20pt;
				font-weight: bold;
				line-height: 24px;
				color: $primaryColor5;
			}
		}
	}
	.not_submitted_error_message_advise {
		padding: 0% 1% 1% 0%;
		span {
			font-size: 14pt;
			font-weight: 400;
			line-height: 20pt;
			color: $greyColor7;
		}
	}
	.not_submitted_error_message_button {
		padding: 1% 1% 0% 0%;
		width: 100%;
		button {
			font-family: 'Montserrat-Medium';
			font-weight: 600;
		}
	}
}

@media only screen and (max-width: 600px) {
	.not_paid_error_container {
		padding: 4% 3.5% 0% 3.5%;
		.not_paid_warning_card {
			padding: 0% 1% 2% 0%;
			.not_paid_error_message_warning {
				img {
					height: 50px;
					width: 50px;
				}
			}
			.not_paid_error_message_title {
				span {
					font-size: 16pt;
					font-weight: 900;
				}
			}
		}
		.not_paid_error_message_advise {
			padding: 0% 1% 1% 0%;
			span {
				font-size: 12pt;
				font-weight: 300;
			}
		}
		.not_paid_error_message_button {
			padding: 1% 1% 0% 0%;
			width: 100%;
		}
	}

	.not_submitted_error_container {
		padding: 4% 3.5% 0% 3.5%;
		.not_submitted_warning_card {
			padding: 0% 1% 2% 0%;
			.not_submitted_error_message_warning {
				img {
					height: 50px;
					width: 50px;
				}
			}
			.not_submitted_error_message_title {
				span {
					font-size: 16pt;
					font-weight: 900;
				}
			}
		}
		.not_submitted_error_message_advise {
			padding: 0% 1% 1% 0%;
			span {
				font-size: 12pt;
				font-weight: 300;
			}
		}
		.not_submitted_error_message_button {
			padding: 1% 1% 0% 0%;
			width: 100%;
		}
	}
}
