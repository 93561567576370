@import '../../../constants/_variables.scss';
.errorTable {
	width: 100%;
	border-radius: 8px;
	border: 1px solid $greyColor4;
	border-collapse: separate;
	.new_error_table {
		font-family: 'Montserrat-Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		color: $blue-500;
		background: #f7f8fa;
		width: 100%;
		.new_error_header {
			padding: 17px 16px;
		}
	}
	.new_error_table_data {
		font-family: 'Montserrat-Regular';
		font-style: normal;
		font-weight: 300;
		font-size: 12px;
		line-height: 14px;
		color: $grayscale-900;
		background: $neutral-white;
		border-bottom: 1px solid $grayscale-300;
		width: 100%;
		.new_error_column {
			word-break: break-word;
			padding: 17px 16px;
			p {
				margin: 0px;
			}
		}
	}
	thead,
	tbody {
		tr {
			th,
			td {
				padding: 10px 24px;
				font-size: 14px;
				border-right: 1px solid $greyColor4;
				border-bottom: 1px solid $greyColor4;
			}
			th {
				color: #9a9ea6;
			}
		}
	}

	tr:last-child td {
		border-bottom: none !important;
	}
	tr td:last-child,
	tr:first-child th:last-child {
		border-right: none !important;
	}
	.btnforpayroll {
		background: transparent !important;
		padding: 8px 16px 8px 10px !important;
		width: fit-content !important;
		display: flex !important;
		flex-direction: row !important;
		justify-content: center !important;
		align-items: center !important;
		border: 1px solid #dfe1e6 !important;
		cursor: pointer;
		border-radius: 8px;
		font-family: 'Montserrat-Regular';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		color: #484b52 !important;
	}
	.ignore-style {
		margin-left: 8.5px;
		color: #484b52;
	}
	.btnforpayrollRemove,
	.btnForSalaryExceeds {
		background: transparent !important;
		height: 35px !important;
		width: 100% !important;
		display: flex !important;
		flex-direction: row !important;
		justify-content: center !important;
		align-items: center !important;
		border: 1px solid #dfe1e6 !important;
		border-radius: 8px !important;
		font-family: 'Montserrat-Regular';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		color: #0e5274;
	}
	.add-employee-popup {
		z-index: 10;
	}
}

.view-text {
	font-size: 14px;
	float: right;
	color: $primaryColor4;
	position: relative;
	margin-top: 5px;
	cursor: pointer;
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		background-color: $primaryColor4;
		height: 1px;
	}
}

@media only screen and (max-width: 600px) {
	.errorTable {
		width: -webkit-fill-available;
		border-radius: 8px;
		border: 1px solid $greyColor4;
		border-collapse: separate;
		thead,
		tbody {
			tr {
				th,
				td {
					padding: 4px 8px;
					font-size: 14px;
					border-right: 1px solid $greyColor4;
					border-bottom: 1px solid $greyColor4;
				}
				th {
					color: #9a9ea6;
				}
			}
		}

		tr:last-child td {
			border-bottom: none !important;
		}
		tr td:last-child,
		tr:first-child th:last-child {
			border-right: none !important;
		}
	}

	.view-text {
		font-size: 14px;
		float: right;
		color: $primaryColor4;
		position: relative;
		margin-top: 5px;
		cursor: pointer;
		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			background-color: $primaryColor4;
			height: 1px;
		}
	}
}

@media screen and (max-width: 1520px) {
	.errorTable .btnforpayrollRemove {
		font-size: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.errorTable .btnforpayroll {
		font-size: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.upload-spreadsheet-col-container .UploadSpreadsheet img {
		// width: 12px !important;
	}
	.errorTable tr td:last-child,
	.errorTable tr:first-child th:last-child {
		padding: 0px 4px;
	}
	.errorTable tr td:last-child,
	.errorTable tr:first-child th:last-child {
		padding: 0px 4px;
	}
}
