.main-container-dragger {
	height: 20vh;
	width: 100%;

	.dragger {
		height: 20vh;
		width: 100%;
		cursor: pointer;
		position: relative;
		border: none;

		.ant-upload.ant-upload-btn {
			padding: 0px;
		}

		.ant-upload.ant-upload-disabled {
			cursor: pointer;
		}

		.dragger-container {
			width: 100%;
			height: 100%;
			border-radius: 8px;
			border: 2px dashed #eff1f5;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.imageuploadIndicator {
				padding-top: 25px;
				display: flex;
				flex-direction: row;
				justify-content: center;

				img {
					width: calc(30 / 16) em;
					height: calc(30 / 16) em;
				}
			}

			.dragger_hint {
				padding-bottom: 20px;
				padding-top: 13px;
				display: inline-grid;

				.dragger_text {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					text-align: center;
					color: #00344e;

					.deleteIcon {
						position: relative;
						margin-left: 5px;
						z-index: 1;
					}
				}

				.dragger_text_2 {
					display: flex;
					align-items: center;
					justify-content: center;
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 10px;
					line-height: 12px;
					text-align: center;
					color: #484b52;

					.deleteIcon {
						position: relative;
						margin-left: 5px;
						width: 18px;
						z-index: 1;
					}
				}

				.dragger_subtext {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 10px;
					line-height: 15px;
					text-align: center;
					color: #484b52;
				}

				.dragger_error_text {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 10px;
					line-height: 15px;
					color: #a51105;
				}
			}
		}

		.file-input {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			cursor: pointer;
			opacity: 0;

			&:focus {
				outline: none;
			}
		}
	}

	.ant-upload-list.ant-upload-list-text {
		display: none;
	}
}
.loader {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}
.loader-border {
	width: 40px;
	height: 40px;
	border: 2px solid #00344e;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
}
@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
