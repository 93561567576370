@import '../../../../constants/variables';
.edenRedButton.primary:disabled {
	background: #eff1f5 !important;
}
.hr-table-outer {
	padding: 0px 40px;
	.new_hr_table {
		background: $neutral-white;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 32px;
		margin-top: 16px;
		margin-bottom: 40px;
		.main-div-hr {
			justify-content: space-between;
			margin: 0px !important;
			.hr_dropDown_entries {
				display: flex;
				align-items: center;
				gap: 10px;
				p {
					font-family: 'Montserrat-Regular';
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					color: $grayscale-700;
					margin: 0px;
				}
				.entries_check_box {
					background: #8ef9b3;
					border-radius: 4px;
					width: 100%;
					height: 13px;
					display: flex;
					align-items: center;
					justify-content: center;
					img {
						width: 6px;
						height: 6px;
					}
				}
				.multi-select-dropdown-component {
					width: 60px;
					.multiSelect-dropdown-container .dropdown_header .dropdown_inner {
						height: 32px;
						position: relative;
						border: 1px solid #dfe1e6 !important;
						.dropDown-menu {
							margin: 0 0 0 -8px;
							.dropdown_default {
								background: none;
								.dropdown_inner_list_main {
									font-family: 'Montserrat-Medium';
									color: #9a9ea6;
								}
							}
						}
						.dropdown_arrow {
							position: absolute;
							right: 8px;
							.arrow-inner {
								height: 15px;
								width: 15px;
							}
						}
					}
					.multiSelect-dropdown-container .dropdown_header .dropdown_list {
						margin: 0px;
						.dropdown_inner_list {
							div {
								justify-content: center;
								label {
									padding: 0px !important;
									span {
										div:nth-child(1) {
											width: 14px !important;
											height: 14px !important;
											border-width: 1px !important;
											border-radius: 4px !important;
										}
									}
								}
							}
						}
					}
				}
			}
			.secound-div {
				display: flex;
				align-items: center;
				gap: 8px;
				width: 65% !important;
				.serach-box {
					border: 1px solid #dfe1e6;
					height: 32px;
					margin-right: 0px;
					input {
						&::placeholder {
							font-family: 'Montserrat-Medium';
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							line-height: 14px;
							color: #9a9ea6;
						}
					}
					img {
						width: 15px !important;
						height: 15px !important;
					}
				}
				.first-div {
					.hr {
						.filterText {
							font-family: 'Montserrat-Medium';
							color: $grayscale-700;
						}
					}
					.main_filter_button {
						.filter_conatiner {
							height: 32px;
						}
					}
				}
			}
			.download-div {
				.download-button-hr {
					width: 107px;
					height: 32px;
					font-family: 'Montserrat-Medium';
					color: $grayscale-700;
					justify-content: unset;
					gap: 8px;
				}
			}
		}
		.runpayroll-table-wrapper {
			margin-top: 15px;
			.edenred-table {
				thead tr th {
					font-family: 'Montserrat-Medium';
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					color: #00344e;
					padding: 16px 10px;
					background: #fbfcfd;
				}
				td {
					padding: 16px 10px;
				}
				tbody tr {
					&:last-child {
						border-bottom: none;
					}
				}
			}
			.ant-table {
				width: 99% !important;
			}
			.ant-table-header {
				position: sticky;
				top: -1px;
				z-index: 1;
				height: 78px !important;
				padding-bottom: 12px !important;
				.ant-table-column-sorter {
					margin-right: 7px !important;
				}
				&::before {
					content: '';
					position: absolute;
					background-color: transparent;
					bottom: -50px;
					height: 50px;
					width: 100%;
					border-top-right-radius: 16px;
					border-top-left-radius: 16px;
					box-shadow: 0 -25px 0 0 #fffefe;
				}
				.ant-table-column-sorter-inner {
					margin-left: 11px !important;
				}
				.ant-table-cell:first-child {
					padding-top: 1px;
				}
			}
		}
		.hr-pagination-bar {
			margin-top: 14px;
			display: flex;
			align-items: center;
			align-self: flex-end;
			width: 100%;
			justify-content: flex-end;
			.hr-pagination-count {
				font-family: 'Montserrat-Regular';
				font-style: normal;
				font-weight: 300;
				font-size: 10px;
				line-height: 12px;
				color: #484b52;
				margin-right: -30px;
			}
			.pagination-container {
				margin: 0px;
				display: flex;
				align-items: flex-end;
			}
		}
	}
}
.hr-table-presentation-outer {
	padding-top: 0px !important;
	.runpayroll-content {
		padding: 0px !important;
	}
}

.download-div {
	position: relative;
}

.edenRedButton.outline-g {
	padding: 8px 12px !important;
	font-family: 'Montserrat-regular';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
}
.footer-div-in {
	position: relative;
	bottom: 0;
	align-self: center;
	width: 95% !important;
}
.footer-container {
	position: absolute;
	transition-property: transform;
	-webkit-transform: translateZ(0);
	transition: 200ms ease-out;
	-webkit-transform: scaleY(1);
	transform: scaleY(1);
}
.sub-header-div {
	width: 100%;
	justify-content: space-between;
}
.sub-inner-div {
	display: flex;
	margin: 15px 0px;
	font-size: 10px;
	line-height: 12px;
	font-weight: 300;
	font-style: normal;
	font-family: 'Montserrat-medium';
	text-align: left;
	width: 95%;
}
.sub-inner-div h1 {
	font-style: normal;
	font-size: 12px;
	padding-right: 20px;
	line-height: 14px;
	font-family: 'Montserrat-medium';
	max-width: 210px;
	font-weight: 500;
	color: #000000;
}
.sub-header-div .sub-inner-div img {
	width: 45px;
	height: 45px;
}
.sub-inner-div .inner-sub-div {
	border: 1px transparent;
	border-radius: 8px;
	justify-content: center;
	text-align: center;
	background-color: #eff1f5;
	height: 45px;
	width: auto;
}
.sub-header-div1 {
	padding-top: 5px;
	padding-left: 7px;
	padding-right: 70px;
}
.sub-header-div2 {
	padding-top: 5px;
	padding-right: 68px;
	padding-left: 7px;
}
.sub-header-div3 {
	padding-top: 5px;
	padding-right: 70px;
	padding-left: 7px;
}
.sub-header-div4 {
	padding-top: 5px;
	padding-left: 7px;
}
.footer-div-in .button-div {
	display: flex;
	justify-content: flex-end;
	padding-right: 20px;
}
.tabNames {
	color: #484b52;
	font-size: 19px;
}
.fil-image {
	height: 20px;
	width: 20px;
	color: #00344e;
}
.download-button-hr {
	cursor: pointer;
	width: 157px;
	height: 40px;
	border: 1px solid #dfe1e6;
	box-sizing: border-box;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px;
	font-family: 'Montserrat-Regular';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	color: #00344e;
	position: relative;
}
.status_tooltip {
	.tooltip {
		padding: 0px;
		.tooltipup {
			margin-left: -35px;
		}
	}
}
.column-detail {
	gap: 2px;
	.status_border {
		width: 24px;
		height: 24px;
		border-radius: 4.8px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 2px;
		.missingdate {
			width: 16px;
			height: 16px;
		}
	}
	.uptodate {
		background: #e6f7f3;
	}
	.expired {
		background: #fff6f5;
	}
	.expiresoon {
		background: #fef5e8;
	}
	.missingdate {
		background: #eaf2f9;
	}
	font-size: 12px;
	font-family: 'Montserrat-light';
	line-height: 14px;
	color: #181919;
	display: flex;
	position: relative;
}
.ant-table {
	width: 99%;
}
.hr_document_new {
	.hr-table-presentation-outer {
		padding-top: 0px;
		align-items: center;
		.runpayroll-content {
			padding: 0px;
		}
		.forHrdoc {
			.run-payroll-table-header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 40px 40px 24px 40px;
				position: sticky;
				top: 0;
				z-index: 1;
				background: #f7f8fa;
				.new_design_hr {
					display: flex;
					align-items: center;
					gap: 8px;
					.new_hr_update,
					.new_hr_cancel {
						background: $grayscale-200;
						font-family: 'Montserrat-Medium';
						height: 32px;
					}
					.buttonnew {
						height: 32px;
						font-family: 'Montserrat-Medium';
					}
				}
			}
			.run-payroll-table-filter-wrapper {
				margin: 0px !important;
				padding-top: 0px;
				.webSummary {
					align-items: center;
					justify-content: space-between;
					padding: 0px 40px;
					.summary {
						padding-bottom: 0px;
						&:last-child {
							.hr_card_new {
								margin-right: 0px;
							}
						}
						.hr_card_new {
							display: flex;
							align-items: center;
							padding: 15px 10px 15px 22px;
							background: $neutral-white;
							border-radius: 8px;
							align-items: center;
							gap: 5px;
							width: 100%;
							margin-right: 16px;
							height: 64px;
							cursor: pointer;
							.forWarnings {
								display: flex;
								align-items: center;
								justify-self: center;
								padding: 6px;
								gap: 10px;
								border-radius: 6.4px;
								width: unset;
								height: unset;
							}
							.entireSummary {
								.summaryDocs {
									font-family: 'Montserrat-Medium';
									font-style: normal;
									font-weight: 500;
									font-size: 12px;
									line-height: 14px;
									color: $blue-500;
									.forEmploy {
										font-family: 'Montserrat-Light';
										font-style: normal;
										font-weight: 300;
										font-size: 12px;
										line-height: 14px;
										color: $grayscale-800;
									}
								}
							}
						}
						.expired {
							background: #fff6f5;
						}
						.warring {
							background: #fef5e8;
						}
						.missing {
							background: #eaf2f9;
						}
						.uptodate {
							background: #e6f7f3;
						}
					}
				}
			}
		}
	}
}
.table-detail {
	height: 22px;
	cursor: pointer;
	border: 1px solid #eff1f5;
	box-sizing: border-box;
	border-radius: 4px;
	font-family: Montserrat-Light;
	font-style: normal;
	font-weight: 200;
	font-size: 12px !important;
	line-height: 14px;
	color: #484b52;
	width: 100%;
	background-color: white;
	padding-left: 10px;
	padding-right: 10px;
}

// Hiding the calender icon
.custom-date-input {
	::placeholder {
		font-size: 12px !important;
	}
	input {
		outline: none;
		text-align: center;
		&::placeholder {
			font-size: 12px;
		}
		&:focus-within {
			border-radius: 4px;
		}
	}
	input[type='date']::-webkit-calendar-picker-indicator {
		position: absolute;
		right: 10px;
	}
}
.filter-inner-div h1 {
	font-weight: bold;
}
.filter-container {
	padding: 10px;
	background: #ffffff;
	box-shadow: 0px 10px 20px rgb(174 182 183 / 50%);
	border-radius: 16px;
	width: 99%;
	min-height: 220px;
	padding-left: 23px;
	justify-content: space-between;
	z-index: 10;
	position: absolute;
	margin-top: 6px;
	color: #00344e;
}
.filter-div {
	display: flex;
	padding: 6px;
}
.filter-inner-container {
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	padding: 6px;
	margin-right: 8px;
	border: 1px solid #dfe1e6;
	box-sizing: border-box;
	border-radius: 22px;
	cursor: pointer;
	min-width: 130px;
	z-index: 4;
	&.loading {
		padding: 0;
		height: 38px !important;
		border: 1px solid transparent;
		cursor: not-allowed;
		span {
			width: 100%;
			height: 100%;
			.react-loading-skeleton {
				border-radius: 22px;
				line-height: unset;
				height: 100% !important;
			}
		}
	}
}

.filter-hidden {
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	pointer-events: none;
	padding: 6px;
	margin-right: 8px;
	border: 1px solid #dfe1e6;
	box-sizing: border-box;
	border-radius: 22px;
	cursor: not-allowed;
	min-width: 130px;
	z-index: 4;
	opacity: 0.1;
}
.download-disable {
	pointer-events: none;
	opacity: 0.3;
	cursor: not-allowed;
}
.filter-inner-div {
	width: 55%;
	padding: 6px;
}
.download-dropdown-wrapper {
	position: relative;
}
.download-container {
	background: $neutral-white;
	box-shadow: 0px 8px 24px -12px #bdbdbd;
	border-radius: 8px;
	justify-content: space-between;
	z-index: 10;
	position: absolute;
	width: 162px;
	right: 0;
}

.download-inner-div {
	padding: 8px 17px;
	border-radius: 16px;
	font-size: 18px;
	width: 100%;
	font-weight: bold;
	cursor: pointer;
	color: #00344e;
	font-size: 14px;
}

.download-inner-container {
	padding-bottom: 4px;
	display: flex;
	&:hover {
		background: $grayscale-50;
	}
	.image,
	.text {
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.text {
		justify-content: flex-start;
		color: $grayscale-700;
		font-family: Montserrat-Regular;
	}
}
.download-inner-container img {
	width: 25px;
}
.button-div .cancel {
	height: 48px;
	border: 1px solid #dfe1e6;
	color: #484b52;
	font-family: 'Montserrat-Bold';
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
}
.forEmployees {
	font-family: 'Montserrat-regular';
	color: #000000;
	font-size: 10px;
	line-height: 12px;
	font-weight: 300;
	margin-top: 0%;
}
.summaryDocuments {
	display: flex;
}
.summaryField {
	flex-wrap: nowrap;
	display: flex;
	justify-content: space-between;
}

.filterText {
	font-family: 'Montserrat-light';
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 0px;
	color: #00344e;
}
.name-detail {
	font-size: 12px;
	font-family: 'Montserrat-Medium';
	line-height: 14px;
	color: #2b769c;
	font-weight: 500;
	text-decoration: underline;
	cursor: pointer;
}
.next-btn-button {
	font-family: Montserrat-Medium;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 20px;
	text-align: center;
	color: #f7f8fa;
	margin-top: 0px;
}
@media screen and (max-width: 900px) {
	.next-btn-button {
		width: 139px !important;
		font-size: 15px;
	}
}

.inner-button-div {
	display: flex;
	align-items: center;
	width: 50%;
}

.cancelSave-btn {
	font-family: 'Montserrat-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 20px;
	text-align: center;
	color: #f7f8fa;
	margin-top: 0px;
}
.disableHrBtn:disabled {
	border: 1px solid #dfe1e6 !important;
	background: #eff1f5 !important;
	height: 52px !important;
}

.edenRedButton.primary {
	margin-top: 0px;
}
.sub-footer-payroll {
	display: flex;
	justify-content: space-between;
}
.sub-footer-payroll {
	display: flex;
	justify-content: space-between !important;
	-ms-flex-direction: row;
	flex-direction: row;
	width: 97%;
}
.newDownload {
	height: 20px;
	width: auto;
}
.forExpiryDate {
	display: flex;
	flex-direction: column;
}
.filterHeading {
	font-family: 'Montserrat-bold';
	color: #00344e;
	font-size: 14px;
	line-height: 16px;
}
.clearAll,
.esc {
	cursor: pointer;
	font-family: 'Montserrat-regualar';
	font-size: 12px;
	color: #00344e;
}
.esc {
	font-size: 10px;
	padding-left: 10px;
	font-weight: bold;
}
.hr-table-presentation-outer {
	.filter-bottom-text {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
.hr-doc-footer {
	position: fixed !important;
	width: 80% !important;
}
.hr_toast {
	transition: ease-in-out 5s;
	position: absolute;
	bottom: 40px;
	left: 50%;
	z-index: 1;
	animation: fade 3s linear;
}
@keyframes fade {
	0% {
		opacity: 0;
	}
	20%,
	50%,
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@media screen and (max-width: 1400px) {
	.hr-doc-footer {
		width: 75% !important;
	}
}
@media screen and (max-width: 1000px) {
	.hr-doc-footer {
		width: 70% !important;
	}
}
.hr_alert_popup {
	z-index: 2;
}
