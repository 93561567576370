@import '../../../../constants/variables';
.ant-table-wrapper {
	::-webkit-scrollbar {
		width: 10px;
		height: 7px;
	}
}

.fs-button-outline {
	&.edenRedButton {
		&.outline-g {
			padding: 4px !important;
		}
	}
}

.Manage-emp-nodata {
	.ant-table-body {
		overflow: hidden !important;
		min-height: 290px !important;
	}
}
.Manage-payroll-nodata {
	.ant-table-body {
		overflow: hidden !important;
		max-height: 56vh !important;
	}
}

.Manage-employee-container {
	width: 100%;
	height: 100%;
	.manage-employee-header {
		padding: 16px 30px;
		display: flex;
		justify-content: space-between;
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
		.manage-employee-subscription-text {
			border: 1px solid #eedcba;
			background-color: #fffaf0;
			border-radius: 8px;
			display: flex;
			justify-content: space-around;
			padding: 0px 19px 0px 16px;
			.manage-employee-inside-text {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 14px;
				color: #00344e;
				margin-top: 12px;
				width: 500px;
			}
			button {
				width: 127px;
				background: #ffffff;
				box-shadow: 0px 6px 20px -13px rgba(0, 0, 0, 0.5);
				border: 1px solid #ffffff;
				border-radius: 8px;
				color: #041243;
				margin-top: 12px;
				margin-bottom: 12px;
				margin-right: 2%;
				cursor: pointer;
			}
		}
	}
	.manage-employee-tab {
		padding-left: 30px !important;
		padding-right: 22px !important;
		// .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
		// .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
		// 	display: flex;
		// 	justify-content: center;
		// }
		.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			text-align: center;
			color: $secondaryColor5;
		}
		.ant-tabs-ink-bar {
			position: absolute;
			background: $secondaryColor3;
		}
		.ant-tabs-tab {
			width: 180px;
			cursor: pointer;
			display: flex;
			justify-content: center;
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			text-align: center;
			color: $greyColor5;
			border-bottom: 2px solid $greyColor4;
			padding: 5px 0px;
		}
		.ant-tabs-tab:hover {
			color: $greyColor5;
		}
		.ant-tabs-tab-btn:focus,
		.ant-tabs-tab-remove:focus,
		.ant-tabs-tab-btn:active,
		.ant-tabs-tab-remove:active {
			color: $secondaryColor5;
		}
		.ant-tabs-top {
			overflow: unset !important;
		}
		.ant-tabs-nav::before,
		.ant-tabs-bottom > .ant-tabs-nav::before,
		.ant-tabs-top > div > .ant-tabs-nav::before,
		.ant-tabs-bottom > div > .ant-tabs-nav::before {
			position: absolute;
			right: 0;
			left: 0;
			border-bottom: none;
			content: '';
		}
		.ant-tabs-nav .ant-tabs-nav-more {
			display: none;
		}

		.manage-employee-col {
			width: 100%;
			.manage-employee-filtersearchcol {
				width: 100%;
				padding-bottom: 10px;
				.filter {
					display: flex;
					justify-content: space-between;
					.manage-employee-filter {
						cursor: pointer;
						width: 103px;
						height: 40px;
						border: 2px solid $greyColor3;
						box-sizing: border-box;
						border-radius: 8px;
						display: flex;
						align-items: center;
						justify-content: space-evenly;
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 16px;
						color: $greyColor7;
						img {
							width: 25px;
							height: 25px;
						}
					}
					.new-employee-button {
						// padding-top: 10px;
						// padding-left: 9%;
						flex: 0 1 29vh;
						button {
							min-height: 32px;
							height: 38px;
							width: 230px;
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 14px;
							line-height: 16px;
							color: $primaryColor5;
							padding: 0px 0px !important;
							margin-left: 16px;
							margin-right: 16px;
							border-radius: 8px;
							img {
								width: 25px;
								height: 25px;
							}
						}
					}
					.new-employee-upload-button {
						position: relative;
						button {
							min-height: 32px;
							height: 38px;
							width: 230px;
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 14px;
							line-height: 16px;
							color: $primaryColor5;
							padding: 0px 0px !important;
							border-radius: 8px;
						}
					}
					.update_delete_emp_drop {
						position: absolute;
						background: #ffffff;
						box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.5);
						border-radius: 8px;
						z-index: 2;
						top: 100%;
						justify-content: space-around;
						right: 0;
						cursor: pointer;
						.update_emp,
						.delete_emp {
							display: flex;
							align-items: center;
							justify-content: space-between;
							width: 92%;
							padding: 10px 0 10px 0;
							span {
								font-family: 'Montserrat-Regular';
								font-style: normal;
								font-weight: 300;
								font-size: 14px;
								line-height: 16px;
								color: #484b52;
							}
							img {
								width: 28px;
								height: 28px;
							}
						}
						.update_emp {
							border-bottom: 1px solid #dfe1e6;
						}
					}
				}
				.emp-search {
					border: 2px solid $greyColor3;
					box-sizing: border-box;
					border-radius: 8px;
					height: 40px;
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor6;
					max-width: 58%;
					margin-left: 16px !important;
					img {
						width: 25px;
						height: 25px;
					}
				}
				.blue_button {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					a {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 16px;
						color: $primaryColor4;
						text-decoration: underline;
						text-underline-position: under;
						padding: 8px 12px;
						width: unset;
					}
				}
				.dropdown-view {
					width: 100%;
					padding-top: 3px;
					.manage-employee-card-dropdown {
						padding: 10px;
						background: $greyColor1;
						box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.5);
						border-radius: 16px;
						width: 99%;
						margin: 2px;
						min-height: 64px;
						z-index: 10;
						position: absolute;

						.dropdown {
							padding: 5px 5px 0px 5px;
						}
						.filter-content {
							display: flex;
							justify-content: flex-end;
							align-items: flex-end;
							padding-top: 8px;
							.clear-select {
								width: 100%;
								display: flex;
								justify-content: flex-end;
								flex-direction: row;
								.clear {
									cursor: pointer;
									padding-right: 15px;
									span {
										font-family: Montserrat-Regular;
										font-style: normal;
										font-weight: 300;
										font-size: 12px;
										line-height: 14px;
										text-align: right;
										color: $primaryColor5;
									}
								}
							}
						}
					}
				}
			}
			.manage-employees-table {
				width: 100%;
				margin-bottom: 5px;

				.manage-employee-table {
					.ant-table-column-sorter-up.active,
					.ant-table-column-sorter-down.active {
						color: $greyColor2;
					}
					.ant-table-thead {
						.column_icon {
							cursor: pointer;
							text-align: center;
						}
						.header_icon {
							cursor: pointer;
							text-align: center;
						}
					}
					.ant-table-pagination.ant-pagination {
						margin-top: 23px !important;
					}
					.ant-table {
						width: 100% !important;
					}
					table > thead > tr:first-child th:first-child {
						display: table-cell;
						// text-align: center !important;
						border-top-left-radius: 16px;
					}
					.ant-table-tbody {
						background: white;
					}
					.ant-table-header {
						// padding-left: 13px;
						margin-bottom: 5px;
						position: sticky !important;
						top: -1px !important;
						z-index: 1 !important;
						padding: unset !important;
						margin-bottom: 13px;
						height: 63px !important;
						.ant-table-column-sorter {
							margin-right: 15px !important;
							padding: unset;
						}
					}
					.ant-table-body {
						// padding-left: 13px;
						overflow-y: unset !important;
						max-height: unset !important;
						// height: 320px;
					}

					.ant-table-cell {
						font-family: Montserrat-Medium;
						font-family: Montserrat-Light;
						font-style: normal;
						font-weight: 500;
						font-size: 12px;
						line-height: 14px;
						color: #ffffff;
						text-transform: capitalize;
						.column_icon {
							cursor: pointer;
							height: 33px;
							width: 33px;
						}
					}
				}
			}
			.manage-employee-footer {
				background: $greyColor2;
			}
		}
	}
	.manage-employee-footer {
		// position: sticky !important;
		background: $greyColor2;
		// min-height: 90px;
		min-height: 90px;
		bottom: 0px;
		width: 100%;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		margin: 0px 20px 0px 25px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.manage-employee-row {
			width: 97%;
			padding-left: 10px;
			display: flex;
			// justify-content: space-between;
			justify-content: flex-end;
			.manage-employee-button {
				padding-top: 10px;
				// padding-left: 9%;
				flex: 0 1 29vh;
				button {
					min-height: 32px;
					height: 32px;
					width: 223px;
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $primaryColor5;
					padding: 0px 0px !important;
					img {
						width: 25px;
						height: 25px;
					}
				}
			}
			.manage-employee-delete {
				padding-right: 4%;
				button {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 20px;
					color: $primaryColor5;
					padding: 0px 0px;
					display: flex;
					justify-content: space-evenly;
					// width: auto;
					img {
						width: 30px;
						height: 30px;
					}
				}
			}
			.manage-employee-add {
				// padding-right: 42px;
				display: flex;
				justify-content: flex-end;
				button {
					width: 206px;
					min-width: 206px;
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 20px;
					text-align: center;
					color: $greyColor2;
					padding: 0px 0px;
				}
			}
		}
	}
}

.manage-employee-mobile-view {
	display: none;
}
.employe-dropdown {
	cursor: pointer;
	border: 1px solid $greyColor4;
	box-sizing: border-box;
	border-radius: 22px;
	display: flex;
	justify-content: space-evenly;
	font-family: Montserrat-Regular;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	color: $primaryColor5;
	align-items: center;
	padding: 5px;
	z-index: 1;
	margin-top: 5px;
	img {
		width: 25px;
		height: 25px;
	}
}
.nk-dropdown-content {
	position: absolute;
	min-height: 140px;
	max-height: 276px;
	overflow-y: scroll;
	background: $greyColor1;
	box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
	border-radius: 16px;
	box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
	border-bottom-right-radius: 24px;
	border-top-left-radius: 24px;
	border-top-right-radius: 24px;
	padding: 16px 0px 0px 12px;
	margin-top: -5px;
	z-index: 1;
	border-bottom-left-radius: 24px;
	width: 100%;
	.select-all {
		span {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 10px;
			line-height: 12px;
			color: $primaryColor5;
			cursor: pointer;
		}
	}
	.check-card-mobile {
		border: 1px solid $greyColor4;
		box-sizing: border-box;
		border-radius: 22px;
		margin: 4px 0px;
		min-height: 32px;
		max-height: auto;
		padding: 5px 10px;
		margin-right: 2px;
		font-family: Montserrat-Regular;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		color: $primaryColor5;
		align-items: center;
		display: flex;
		img {
			width: 20px;
			height: 20px;
		}
	}
}

.name {
	font-family: Montserrat-Regular;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 20px;
	color: $primaryColor4;
}
.valid_detail {
	font-family: Montserrat-Regular;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	color: $greyColor7;
	// padding-left: 10px;
}
.valid_detail_1 {
	// padding-left: 10px;
	align-items: center;
	// justify-content: space-around;
	column-gap: 12px;
	display: flex;
	align-items: center;
	justify-content: end;
	.emp_card {
		border: 1px solid #dfe1e6;
		border-radius: 8px;
		width: 140px;
		// height: 32px;
		padding-top: 7px;
		padding-bottom: 7px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		p {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
			margin: 0;
		}
		.invite_to_portal {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
			margin: 0;
			padding-left: 16px;
			padding-right: 16px;
			min-width: max-content;
		}
	}
	.emp_card_replace {
		width: 60%;
	}
	.emp_card_reissue {
		border: 1px solid #dfe1e6;
		border-radius: 8px;
		width: 125px;
		// height: 32px;
		padding-top: 7px;
		padding-bottom: 7px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: max-content;
		cursor: pointer;
		p {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
			margin: 0;
			padding-left: 16px;
			padding-right: 16px;
		}
	}
	.emp_delete {
		// background: #f7f8fa;
		border: 1px solid #dfe1e6;
		border-radius: 8px;
		padding: 8px 10px;
		display: flex;
		// align-items: center;
		// justify-content: center;
		width: 36px;
		height: 32px;
		.emp_trash_img {
			width: 13px;
			height: 14px;
		}
	}
}
.resend_invitation {
	font-family: Montserrat-Medium;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	color: $greyColor7;
	margin: 0;
	padding-left: 16px;
	padding-right: 16px;
	min-width: max-content;
}

.toolTipOverlay {
	.ant-tooltip-inner {
		width: 146px;
		height: 58px;
		font-family: Montserrat-Regular;
		font-weight: 300;
		font-size: 12px;
		line-height: 14px;
		border-radius: 8px;
		background-color: #181919;
		text-align: center;
	}
}
.empId {
	margin-left: -10px;
}

.ReactModal__Overlay {
	z-index: 2;
	.modal_card_emploee {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		.Modal_Edenred {
			width: 27%;
			.delete_modal {
				display: flex;
				justify-content: center;
				padding: 8px;
				text-align: center;
				width: 100%;
			}
		}

		.Remove_user {
			.user {
				padding-top: 5%;
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				color: $primaryColor5;
			}
			.user_login {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: $greyColor6;
			}
		}
	}
}

// .manage-employee-filter
.manage-employee-filter-container {
	display: none;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
	opacity: 0;
}

// ---Delete Employees In Bulk Page---
.delete_employees_in_bulk_container {
	padding: 0px 40px 40px 40px;
	overflow-x: hidden;
	align-self: flex-start;
	.delete_employees_in_bulk_title {
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: fit-content;
		position: sticky;
		top: 0px;
		z-index: 1;
		padding: 40px 0px 0px 0px;
		.ant-form-item {
			margin-bottom: 0px;
		}
		// span {
		// 	font-family: Montserrat-Bold;
		// 	font-style: normal;
		// 	font-weight: bold;
		// 	font-size: 24px;
		// 	line-height: 28px;
		// 	color: $primaryColor5;
		// }
	}
	.ant-form {
		width: 100%;
		.delete_employees_in_bulk_dragger_container {
			// padding: 16px 0px;
			// padding-top: 32px;
			background-position: top;
			background-size: 10px 1px;
			background-repeat: repeat-x;
			.ant-upload.ant-upload-drag .ant-upload {
				padding: 8px 0;
			}
			.delete_employees_dragger {
				.delete_employees_dragger_desktop_row {
					display: flex;
					width: 100%;
					.delete_employees_indication_image {
						padding: 6px;
						img {
							width: 120px;
							height: 120px;
						}
					}
					.delete_employees_dragger_hint {
						padding: 16px;
						display: inline-grid;
						.drag_and_drop_text {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 20px;
							color: $primaryColor5;
							text-align: center;
							color: $primaryColor5;
							padding: 4px 0px;
						}
						.click_here_text {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 16px;
							line-height: 18px;
							color: $primaryColor5;
							text-align: center;
							color: $primaryColor5;
							padding: 4px 0px;
						}
					}
					.after_file_upload {
						padding: 16px;
						display: inline-grid;
						.file_name {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 20px;
							text-align: center;
							color: $primaryColor5;
							padding: 4px 0px;
						}
						.file_size {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 16px;
							line-height: 18px;
							text-align: center;
							color: $primaryColor5;
							padding: 4px 0px;
						}
					}
				}
				.upload_dragger_mobile_row {
					display: none;
				}
			}
			.ant-upload-list-item {
				display: none;
			}
		}
		.delete_employees_card_title {
			span {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 18px;
				color: $primaryColor5;
			}
		}
		.delete_employees_time_card_row {
			padding: 16px 0px;
			.delete_employees_time_card_column {
				padding-right: 8px;
				.delete_employees_time_select_card {
					background: $greyColor1;
					box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.5);
					border-radius: 16px;
					height: 56px;
					min-height: 56px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}
				.delete_employees_time_unselect_card {
					background: $greyColor1;
					box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
					border-radius: 16px;
					height: 56px;
					min-height: 56px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}
				.ant-card-body {
					padding: 4px;
					width: 100%;
					height: 100%;
					.card_body_content_row {
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						.delete_employees_select_image {
							display: flex;
							justify-content: center;
							img {
								width: 30px;
								height: 30px;
							}
						}
						.delete_employees_time_name {
							span {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 16px;
								line-height: 18px;
								color: $greyColor7;
							}
						}
					}
				}
			}
		}
		.delete_employees_in_bulk_submit_button {
			// padding: 12px 0px;
		}
		.download_delete_employees_spreadsheet_template_hint {
			padding: 12px 0px;
			height: 48.68px;
			background: #f9fafb;
			border-radius: 8px;
			padding-left: 16px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: $greyColor7;
				margin-bottom: 8px;
			}
			.Here-text {
				font-family: 'Montserrat-Medium';
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				text-decoration-line: underline;
				width: 95%;
				color: #0e5274;
				cursor: pointer;
				padding-left: 3px;
			}
		}
		.download_delete_employees_spreadsheet_template_button {
			padding: 12px 0px;
			button {
				height: 32px;
				min-height: 32px;
				width: 214px;
				min-width: 214px;
				padding: 4px;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				border: 1px solid $greyColor4;
				box-sizing: border-box;
				border-radius: 22px;
				.download_image {
					height: 25px;
					width: 25px;
				}
			}
		}
	}
}
.delete_container_heading {
	font-family: 'Montserrat-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 20px;
	color: #00344e;
	margin-top: 24px;
}
.delete-employee-button {
	background: #ffffff;
	border: 1px solid #eff1f5;
	box-shadow: 0px 8px 24px -12px #bdbdbd;
	border-radius: 8px;
	width: 516px;
	padding-left: 24px;
	margin-top: 4px;
	cursor: pointer;
	padding: 8px 32px;
}
.delete-employee-button-uncheck {
	background: #ffffff;
	border: 1px solid #eff1f5;
	border-radius: 8px;
	width: 516px;
	padding-left: 24px;
	margin-top: 4px;
	cursor: pointer;
	padding: 8px 32px;
}
.delete-conatiner-text {
	font-family: 'Montserrat-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	padding-left: 12px;
	color: #00344e;
}
.delete-container {
	background-color: #ffffff;
	padding: 32px;
	border-radius: 8px;
	padding-top: 0px;
	margin-top: 2%;
	width: 100%;
	box-shadow: 0px 16px 32px -24px #bdbdbd;
}
.button_back_new {
	padding: 8px 16px;
}
//---- Delete Employees In Bulk submit page----
.delete-employee-in-bulk-submit {
	width: 100%;
	height: 80vh;
	.delete_employee_view_card_details_title_container_wrapper {
		width: 100%;
		.title_section {
			margin: 0;
		}
	}
	.delete_multiple_employees_submit_container {
		// padding: 0px 135px 0px 135px;
		width: 100%;
		.delete_multiple_employees_submit_button_row {
			width: 100%;
			.delete_multiple_employees_submit_title {
				padding: 6px 0px;
				span {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 24px;
					line-height: 28px;
					color: $primaryColor5;
				}
			}
			.delete_multiple_employees_submit_button {
				padding: 6px 0px;
				button {
					background: $secondaryGradient1;
					box-shadow: 0px 5px 10px rgba(233, 180, 175, 0.75);
					border-radius: 16px;
					min-height: 32px;
					min-width: 99px;
					width: 99px;
					height: 32px;
					padding: 2px;
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
				}
			}
		}
		.delete_multiple_employees_submit_hint {
			padding: 8px 0 8px 0;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: $greyColor7;
			}
		}
		.delete_multiple_employees_submit_list_section {
			padding: 8px 0 8px 0;
			.delete_multiple_employees_list_row {
				.delete_multiple_employees_name_employee_id_title {
					span {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 12px;
						line-height: 14px;
						color: $greyColor5;
					}
				}
				.delete_multiple_employees_name_employee_id_column {
					span {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 18px;
						color: $primaryColor5;
					}
				}
			}
			.ant-divider-horizontal {
				margin: 16px 0px;
			}
		}
	}
}
.delete_multiple_employees_submit_modal {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	.Modal_Edenred .Remove_user {
		padding: 5% 10% 0% 10%;
		font-family: 'Montserrat-medium';
		color: $primaryColor5;
	}
	.Modal_Edenred .user_login_mobile {
		margin: 0%;
	}
	.Modal_Edenred {
		width: 30%;
		.Modal_Edenred .Remove_user {
			font-size: 18px;
			color: $primaryColor5;
			padding: 2% 10%;
		}
		.edenredTypo.bold {
			font-family: 'Montserrat-Medium';
		}
		.Button_container .Yes {
			color: $greyColor1;
			font-weight: bold;
			background: $secondaryColor3;
			display: flex;
			align-items: center;
			justify-content: center;
			border-bottom-right-radius: 16px;
		}
	}
}

//---- Update Employees Emirates ID Page ----
.update_employees_emirates_id_container {
	padding: 0px 135px 0px 135px;
	width: 100%;
	.update_employees_emirates_id_title {
		padding: 0px 0px 16px 0px;
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.update_employees_emirates_id_hint {
		padding: 16px 0px 16px 0px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 18px;
			line-height: 20px;
			color: $greyColor7;
		}
	}

	.update_employees_emirates_id_c3pay_option {
		padding: 4px 0px 8px 0px;
		.option_card {
			padding: 4px 0px 16px 0px;
			.ant-card {
				border: 2px solid $greyColor3;
				box-sizing: border-box;
				border-radius: 16px;
				width: 141px;
				height: 56px;
				min-width: 141px;
				min-height: 56px;
				cursor: context-menu;
				.ant-card-body {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 10px;
					img {
						height: 32px;
						width: 32px;
					}
					span {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 18px;
						color: $greyColor7;
					}
				}
			}
		}
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}

	.update_employees_emirates_id_wetransfer_option {
		.option_card {
			padding: 4px 0px 16px 0px;
			.ant-card {
				border: 2px solid $greyColor3;
				box-sizing: border-box;
				border-radius: 16px;
				width: 141px;
				height: 56px;
				min-width: 141px;
				min-height: 56px;
				cursor: context-menu;
				.ant-card-body {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 10px;
					img {
						height: 28px;
						width: 28px;
						padding-left: 2px;
					}
					span {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 18px;
						color: $greyColor7;
						padding-left: 4px;
					}
				}
			}
		}
		.wetransfer_steps_container {
			.wetransfer_step_row {
				display: flex;
				align-items: center;
				padding: 0px 2px 4px 0px;
				.wetransfer_step_icon {
					width: 24px;
					max-width: 24px;
					span {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 16px;
						color: $primaryColor3;
					}
				}
				.wetransfer_step_content {
					span {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 16px;
						color: $greyColor7;
					}
				}
			}
		}
	}
}
.emp-parent {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex: 0 1 0vh;
}
.Manage-employee-container .manage-employee-footer .manage-employee-row .manage-employee-delete button {
	display: flex;
	justify-content: space-evenly;
}

.manage_employees_action_header_wrapper {
	padding: 40px 0 24px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	width: 100%;
	top: 0px;
	z-index: 2;
	background-color: #f7f8fa;

	.action_buttons_wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 8px;
		width: 58%;
		.delete_btn {
			width: 34%;
			max-width: 158px;
		}
		.upload_btn {
			width: 37%;
			max-width: 166px;
		}
		.add_btn {
			width: 30%;
			max-width: 143px;
		}
		.width_100_percentage {
			width: 100%;
		}
	}

	.title {
		font-family: 'Montserrat-Bold';
		font-weight: 700;
		font-size: 24px;
		color: #00344e;
		margin: 0;
	}

	@media only screen and (max-width: 980px) {
		padding: 2rem 0 1rem 0;
	}
}

.tabs_section_wrapper {
	background-color: white;
	padding-top: 16px;
	padding-bottom: 16px;
	padding-left: 32px;
	padding-right: 32px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-radius: 8px;

	.tab {
		background-color: white;
		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 16px;
		padding-right: 16px;
		border-width: 1px;
		border-style: solid;
		border-color: #dfe1e6;
		font-family: 'Montserrat-Medium';
		font-weight: 500;
		font-size: 14px;
		cursor: pointer;
		.badge {
			background: #f7f8fa;
			padding-top: 2px;
			padding-bottom: 2px;
			padding-left: 4px;
			padding-right: 4px;
			font-family: 'Montserrat-Medium';
			font-weight: 500;
			font-size: 14px;
			border-radius: 4px;
			margin-left: 8px;

			&.active {
				background: #f58178;
				color: white;
			}
		}

		&.active {
			background: $red-gradient-normal;
			border: none;
			color: white;
		}

		&.first_child {
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
		}

		&.last_child {
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
		}
	}
}

.employee_table_header_wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;

	.search_and_download_wrapper {
		display: flex;
		gap: 10px;
		align-items: center;
		.buttonnew {
			width: 244px;
		}
	}

	.no_of_data_to_show {
		display: flex;
		align-items: center;

		p {
			margin: 0 9px;
			font-family: Montserrat-Light;
			color: #484b52;
			font-size: 12px;
		}
	}
}

.employee_manage_table_search {
	border: 2px solid $greyColor3;
	box-sizing: border-box;
	border-radius: 8px;
	height: 32px;
	font-family: Montserrat-Regular;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	color: $greyColor6;
	max-width: 58%;
	width: 340px;
	img {
		width: 25px;
		height: 25px;
	}
}

.employee_manage_table_wrapper {
	padding: 32px;
	background: #ffffff;
	border-radius: 8px;
	margin-top: 24px;
	.edenred-table {
		thead {
			tr {
				th {
					padding: 17px 16px;
					background: $neutral-white;
				}
			}
		}
	}
}

.employee_table_pagination_wrapper {
	margin-top: 26px;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.padding_0 {
	margin: 0;
	padding: 0;
}

.invite_to_portal_popup_wrapper {
	.title {
		font-family: 'Montserrat-Bold';
		font-weight: 700;
		font-size: 18px;
		color: #00344e;
		margin: 0;
		margin-bottom: 8px;
	}

	.sub_title_contents {
		color: #737780;
		margin-bottom: 24px;
		p {
			margin: 0;
			font-family: 'Montserrat-Light';
			font-weight: 300;
			font-size: 14px;
			color: #737780;
		}
	}

	.buttons_wrapper {
		margin-top: 24px;
		gap: 16px;
		display: flex;
		align-items: center;
		width: 100%;

		button {
			width: 100%;
		}
	}
}

.card_replacement_message {
	b {
		font-weight: bold;
	}

	a {
		color: inherit;
		text-decoration: underline;
	}
}


@media only screen and (max-width: 786px) {
	.update_employees_emirates_id_container {
		padding: 0px 12px 0px 12px;
	}
	.ant-table-body {
		// max-height: 30vh !important;
	}
	.manage-employees-table {
		margin-left: 0 !important;
	}
	.Manage-employee-container .manage-employee-tab .manage-employee-col {
		// height: 60vh !important;
	}
}
@media only screen and (min-width: 600px) and (max-width: 1280px) {
	.Manage-employee-container .manage-employee-footer .manage-employee-row .manage-employee-delete button {
		width: 213px !important;
		font-size: 12px !important;
	}
	.Manage-employee-container .manage-employee-footer .manage-employee-row .manage-employee-add button {
		width: 157px !important;
		font-size: 12px !important;
		min-width: 157px !important;
	}
	.Manage-employee-container .manage-employee-footer {
		margin: 0px 0px 0px 17px !important;
	}
}
@media only screen and (max-width: 1000px) and (min-width: 600px) {
	.Manage-employee-container .manage-employee-footer {
		height: 124px;
	}
	.ant-table-body {
		// max-height: 30vh !important;
	}
	.manage-employees-table {
		margin-left: 0 !important;
	}
	.Manage-employee-container .manage-employee-tab .manage-employee-col {
		// height: 60vh !important;
	}
	// .emp-parent {
	// 	width: 55% !important;
	// }
}

@media only screen and (min-width: 600px) {
	html,
	body {
		overflow-y: hidden;
	}
	// .emp-parent {
	// 	width: 42%;
	// }
	.Manage-employee-container .manage-employee-footer {
		justify-content: center !important;
		position: sticky !important;
		bottom: 0 !important;
	}
	.Manage-employee-container {
		.emp-parent {
			margin-right: 15px;
		}
	}
}
@media only screen and (max-width: 1186px) {
	.blue_button {
		justify-content: flex-start !important;
	}
	// .emp-parent {
	// 	width: 50%;
	// }
}

@media only screen and (max-width: 1280px) and (min-width: 786px) {
	.Manage-employee-container .manage-employee-footer .manage-employee-row .manage-employee-delete button {
		font-size: 18px;
	}
	.Manage-employee-container {
		.manage-employee-tab {
			// padding: 0px 65px 0px 135px;
			// height: 70vh !important;
			.ant-tabs-tab {
				margin: 0 6px 0 0;
				width: 220px;
			}

			.manage-employee-col {
				.manage-employees-table {
					width: 100%;
				}
			}
		}
	}
	.ant-table-body {
		// max-height: 30vh !important;
	}
	.manage-employees-table {
		margin-left: 0 !important;
	}
	.Manage-employee-container .manage-employee-tab .manage-employee-col {
		// height: 70vh !important;
	}
}
@media only screen and (max-width: 1700px) and (min-width: 1280px) {
	.Manage-employee-container .manage-employee-footer .manage-employee-row .manage-employee-delete button {
		font-size: 18px;
	}
	.Manage-employee-container .manage-employee-tab .manage-employee-col {
		// height: 56vh !important;
	}
	.ant-table-body {
		// max-height: 29vh !important;
	}
	// .emp-parent {
	// 	width: 56%;
	// }
}
@media only screen and (max-width: 1366px) {
	// .emp-parent {
	// 	width: 52%;
	// }
}
@media only screen and (min-width: 1400px) {
	.Manage-emp-nodata {
		.ant-table-body {
			max-height: 500px !important;
		}
	}
	// .emp-parent {
	// 	width: 47%;
	// }
	.Manage-employee-container .manage-employee-footer .manage-employee-row .manage-employee-delete button {
		font-size: 18px;
	}
	.Manage-employee-container .manage-employee-footer {
		margin: 0px 20px 0px 25px;
	}
	.Manage-employee-container {
		.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
		.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
			// display: flex;
			// justify-content: center;
		}
	}
}

.Manage-employee-container .manage-employee-footer .manage-employee-row .manage-employee-delete button {
	width: 326px;
}
@media only screen and (min-width: 1800px) {
	.Manage-employee-container .manage-employee-tab {
		padding: 0px 140px !important;
	}
	.Manage-employee-container .manage-employee-header {
		padding: 16px 144px;
	}
	.Manage-employee-container .manage-employee-footer .manage-employee-row {
		width: 83% !important;
	}
	.Manage-employee-container .manage-employee-footer {
		justify-content: center !important;
	}
	// .emp-parent {
	// 	width: 41%;
	// }
}
@media only screen and (min-width: 1800px) {
	.Manage-employee-container .manage-employee-tab {
		padding: 0px 140px !important;
	}
	.Manage-employee-container .manage-employee-header {
		padding: 16px 144px;
	}
	.Manage-employee-container .manage-employee-footer .manage-employee-row {
		width: 85% !important;
	}
	.Manage-employee-container .manage-employee-footer {
		justify-content: center !important;
	}
	// .emp-parent {
	// 	width: 41%;
	// }
}
