@import '../../../constants/variables';
.announcement-container {
	margin: 0 40px;
	.new_design_history {
		margin: 24px 0 40px 0;
		border-radius: 8px;
		.new_design_ann_his {
			background: #ffffff;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;
			.new_his_header {
				position: sticky;
				top: 0px;
				z-index: 1;
				background: #ffffff;
				padding: 32px 32px 32px 32px;
				color: $darkblue;
				font-size: 18px;
				font-family: $font-family-medium;
				font-weight: $font-weight-500;
				line-height: 20px;
			}
		}
	}
	.new_ann_his {
		padding: 0px 34px;
		overflow: auto;
		&::-webkit-scrollbar {
			width: 3px;
		}
		.announcement-history {
			padding: 0px 26px 5px 26px;
			.for-date {
				display: flex;
				justify-content: flex-end;
				font-family: 'Montserrat-Medium';
				font-size: 12px;
				font-weight: 500;
				line-height: 15px;
				color: #2b769c;
				margin-top: 12px;
			}
			.announcement-heading {
				font-family: 'Montserrat-Medium';
				font-size: 14px;
				line-height: 16px;
				font-weight: 500;
				color: #484b52;
				margin-top: 16px;
			}
			.announcement-text {
				font-family: 'Montserrat-Light';
				font-size: 12px;
				font-weight: 300;
				line-height: 14px;
				color: #484b52;
				margin-top: 8px;
				margin-bottom: 12px;
			}
		}
	}
	.new_design_no_history {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 0px 32px 32px 32px;
		.no_announcement_record {
			border: 1px dashed #dfe1e6;
			border-radius: 8px;
			width: 100%;
			padding: 60px;
			.history-frame {
				display: flex;
				justify-content: center;
				.no-announcement {
					font-family: 'Montserrat-Medium';
					font-size: 16px;
					font-weight: 500;
					line-height: 18px;
					color: #484b52;
					margin-top: 18px;
					text-align: center;
				}
				.no-announcement-text {
					font-family: 'Montserrat-Light';
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: #737780;
					text-align: center;
					margin-top: 8px;
				}
			}
		}
	}
}

.modal-description {
	.announcement-modal-title-container {
		color: rgb(110, 112, 118);
		font-size: 14px;
		font-family: $font-family-light;
		font-weight: $font-weight-300;
		line-height: 16px;
		margin-top: 16px;
		.announcement-modal-title {
			color: rgb(110, 112, 118);
			font-size: 14px;
			font-family: $font-family-medium;
			font-weight: $font-weight-500;
			line-height: 16px;
			margin-right: 2px;
		}
	}
	.announcement-modal-message-container {
		word-wrap: break-word;
		color: rgb(110, 112, 118);
		font-size: 14px;
		font-family: $font-family-light;
		font-weight: $font-weight-300;
		line-height: 16px;
		margin-top: 16px;
		.announcement-modal-message {
			color: rgb(110, 112, 118);
			font-size: 14px;
			font-family: $font-family-medium;
			font-weight: $font-weight-500;
			line-height: 16px;
			margin-right: 2px;
		}
	}
}
