@media only screen and (max-width: 600px) {
	.runpayroll-table,
	.runpayroll-bottom-fixed-card,
	.filter-dropdown-wrapper {
		display: none;
	}

	.runpayroll-content {
		padding: 64px 16px 16px 16px !important;
		// padding-top: 40px !important;
		padding-bottom: 0px !important;

		.run-payroll-table-filter-wrapper {
			margin: 0px !important;
		}
	}

	.run-payroll-table-filter-wrapper {
		display: none;
	}

	.run-payroll-mobile-table-view {
		display: block;
		padding: 0px 16px;
		background: white;
		margin-bottom: 175px;
		.run_payroll_mobile_view {
			.run_payroll_card {
				.card_list_padding {
					margin: 10px 0px;
				}
			}
		}
	}

	.filter_button_view {
		position: fixed !important;
		bottom: 0;
		z-index: 2;
		right: 0px;
		left: 0px;
	}

	.floating-input.runpayroll {
		width: 188px !important;
	}

	.payroll {
		.edenRedSwitch.ant-switch-checked {
			background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%) !important;
		}
	}

	.runpayroll {
		width: 100% !important;
	}
}
