@import '../../../constants/variables';

.payroll-setting-main-header {
	display: flex;
	justify-content: center;
	overflow: scroll;
	// height: 649px;
	padding-bottom: 20px;
	&::-webkit-scrollbar {
		width: 3px;
	}
	.disableButton {
		font-family: Montserrat-Light;
		font-style: normal;
		font-weight: 300;
		font-size: 10px;
		line-height: 18px;
		color: #e60a14;
		padding-right: 15px;
	}

	.payroll-setting-container {
		background: $neutral-white;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 32px;

		.payroll_setting_date_select {
			justify-content: space-between;
			margin-top: 16px;
			.padding-right-32 {
				padding-right: 32px;
			}
			.payroll-setting-date {
				padding-top: 5px;
			}
		}

		.payroll-setting-limit {
			margin-top: 16px;
		}

		.payroll-setting-select {
			display: flex;
			align-items: center;
			margin-top: 16px;
			gap: 32px;

			.css-110do72,
			.css-1by9rry {
				background-color: #9a9ea6;
			}

			.payroll-radio-img {
				display: flex;
				align-items: center;
				gap: 8px;

				img {
					width: 22px;
					height: 22px;
				}
			}

			.payroll-img-cmp {
				width: 31px;
				margin-top: -1px;
			}

			.payroll-setting-radio-false {
				font-family: $font-family-light;
				font-style: normal;
				font-weight: 300;
				color: $grayscale-700;
			}

			.payroll-setting-radio-true {
				font-family: $font-family-medium;
				font-style: normal;
				font-weight: 300;
				color: $grayscale-900;
			}
		}
	}

	.payroll-setting-headers {
		background: $neutral-white;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 32px;
		margin-top: 24px;

		.payroll_setting_department {
			display: flex;
			justify-content: space-between;

			.payroll_setting_department_content {
				display: flex;
				flex-direction: column;

				span:nth-child(2) {
					margin-top: 16px;
				}
			}

			.payroll_setting_department_button {
				img {
					width: 11px;
				}
			}
		}

		.payroll-setting-inner {
			display: flex;
			margin-top: 16px;
			justify-content: space-between;
			.padding-right-32 {
				padding-right: 32px;
			}
			.payroll-setting-dropdown {
				.multiSelect-dropdown-container {
					margin-top: 8px;
				}

				.Active {
					position: absolute;
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 300;
					line-height: 14px;
					color: #9a9ea6;
					padding-top: 0px;
					top: -9px !important;
					font-size: 14px;
					left: 17px;
					z-index: 1;
					background: #f7f8fa;
					letter-spacing: 0.4px;
				}

				.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
					background: #ffffff;
				}

				.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input,
				.ant-select-selection-item {
					cursor: pointer !important;

					&:focus {
						caret-color: transparent;
					}
				}

				.ant-select {
					display: flex;
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 20px;
					letter-spacing: 0.5px;
					height: 56px;
					color: #484b52;

					.ant-select-selector {
						display: flex;
						align-items: center;
						height: 56px;
						width: 100%;
						border: 2px solid #f7f8fa;
						box-sizing: border-box;
						border-radius: 8px;
						background: #f7f8fa;
					}

					.ant-select-selection-item {
						padding-right: 18px;
						justify-content: space-around;
					}

					.ant-select-selection-placeholder {
						font-family: Montserrat-Light;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 18px;
						letter-spacing: 0.5px;
						color: #9a9ea6 !important;
					}

					.ant-select-arrow {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 20px;
						color: #11266900;
						content: url('../../../assets/icons/not_focus/Down.svg');
						height: 28px;
						width: 28px;
						margin-top: -15px;
						margin-right: 0px;
					}

					.ant-select-arrow ::after {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 20px;
						color: #11266900;
						content: url('../../../assets/icons/not_focus/Up.svg');
						height: 28px;
						width: 28px;
						margin-top: -15px;
						margin-right: 0px;
					}

					.ant-select-item {
						font-family: 'Montserrat-Medium';
						font-size: 14pt;
						font-weight: 500;
						background: #ffffff;
					}

					.ant-select-dropdown {
						background: #ffffff;
						box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
						border-radius: 15px !important;
					}
				}
			}

			.ant-input {
				border: 2px solid #dfe1e6;
				box-sizing: border-box;
				border-radius: 8px;
				padding-left: 19px;
				background: #f7f8fa;
				height: 60px;

				&:focus {
					background: #ffffff;
				}
			}

			.ant-input-affix-wrapper {
				height: 60px;
				width: 100%;
				background: #f7f8fa;
				margin-top: 10px;
				padding-left: 25px;
				border: 2px solid #dfe1e6;
				box-sizing: border-box;
				border-radius: 8px;
				font-weight: bold;

				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 18px;
					color: #00344e;
					text-align: center;
				}
			}

			.payroll-setting-dropdown-input {
				.Active {
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 14px;
					color: #9a9ea6;
					padding-top: 0px;
					top: -5px !important;
					background: #f7f8fa;
					letter-spacing: 0.4px;
				}

				.ant-input::placeholder {
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: #9a9ea6;
					letter-spacing: 0.5px;
					padding-left: 10px;
				}
			}

			.ant-input {
				background: #f7f8fa;
				font-size: 16px;
			}
		}
	}

	.payroll-setting-sub-header {
		background: $neutral-white;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 32px;
		margin-top: 24px;

		.payroll_setting_basic_salary {
			display: flex;
			align-items: center;
			span {
				&:nth-child(1) {
					width: 50%;
				}
			}
		}
		.payroll_setting_salary_allowance {
			font-family: $font-family-medium;
			font-weight: 500;
			font-style: normal;
			font-size: $font-size-10px;
			line-height: 12px;
			margin-top: 8px;
		}
		.Active {
			font-family: Montserrat-Light;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 14px;
			color: #9a9ea6;
			padding-top: 0px;
			top: 4px !important;
			background: #f7f8fa;
			letter-spacing: 0.4px;
			z-index: 1;
		}

		.ant-input-affix-wrapper {
			height: 60px;
			width: 100%;
			background: #f7f8fa;
			margin-top: 10px;
			padding-left: 25px;
			border: 2px solid #dfe1e6;
			box-sizing: border-box;
			border-radius: 8px;

			span {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 18px;
				color: #00344e;
				text-align: center;
			}
		}

		.ant-input-affix-wrapper-focused,
		.ant-input:focus {
			background: #ffffff;
		}

		.payroll-setting-input {
			margin-top: 16px;
			// padding-right: 32px;
		}

		.ant-input {
			background: #f7f8fa;
			font-size: 16px;
			top: -1px;
			outline: none;
		}

		.payroll-setting-sub-inner-main {
			display: flex;
			width: 100%;

			.payroll-setting-sub-dropdown {
				margin-top: 8px;
				.Active {
					position: absolute;
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 14px;
					color: #9a9ea6;
					padding-top: 0px;
					top: -8px !important;
					left: 16px;
					z-index: 1;
					background: #f7f8fa;
					letter-spacing: 0.4px;
				}

				.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
					background: #ffffff;
				}

				.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input,
				.ant-select-selection-item {
					cursor: pointer !important;

					&:focus {
						caret-color: transparent;
					}
				}

				.ant-select {
					display: flex;
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 20px;
					letter-spacing: 0.5px;
					height: 56px;
					color: #484b52;

					.ant-select-selector {
						display: flex;
						align-items: center;
						height: 56px;
						width: 100%;
						border: 2px solid #f7f8fa;
						box-sizing: border-box;
						border-radius: 8px;
						background: #f7f8fa;
						cursor: pointer;
					}

					.ant-select-selection-item {
						padding-right: 18px;
						justify-content: space-around;
					}

					.ant-select-selection-placeholder {
						font-family: Montserrat-Light;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 18px;
						letter-spacing: 0.5px;
						color: #9a9ea6 !important;
					}

					.ant-select-arrow {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 20px;
						color: #11266900;
						content: url('../../../assets/icons/not_focus/Down.svg');
						height: 28px;
						width: 28px;
						margin-top: -15px;
						margin-right: 0px;
					}

					.ant-select-arrow ::after {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 20px;
						color: #11266900;
						content: url('../../../assets/icons/not_focus/Up.svg');
						height: 28px;
						width: 28px;
						margin-top: -15px;
						margin-right: 0px;
					}

					.ant-select-item {
						font-family: 'Montserrat-Medium';
						font-size: 14pt;
						font-weight: 500;
						background: #ffffff;
					}

					.ant-select-dropdown {
						background: #ffffff;
						box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
						border-radius: 15px !important;
					}
				}
			}

			.payroll-setting-sub-input {
				margin-left: 32px;
				margin-top: 8px;
				.ant-input {
					padding-top: 2px;
				}

				.ant-input::placeholder {
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: #9a9ea6;
					letter-spacing: 0.5px;
				}
			}

			.payroll-setting-sub-img {
				background: #f9fafb;
				border-radius: 8px;
				margin-left: 16px;
				display: flex;
				justify-content: center;
				width: 36px;
				height: 32px;
				cursor: pointer;
				margin-top: 8px;
				padding: 8px 10px;
			}
		}
		.payroll_setting_add {
			width: 163px;
		}
	}

	.payroll-setting-add-alowance {
		margin-top: 24px;
	}

	.payroll-setting-gross {
		display: flex;
		align-items: center;

		p {
			font-family: 'Montserrat-Bold';
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 20px;
			color: $blue-300;
			padding-left: 4px;
			margin: 0px;
		}
	}

	.payroll-img-plus {
		width: 11px;
	}

	.payroll-setting-button {
		display: flex;
		margin-top: 15px;

		.payroll-setting-sub-button {
			margin-right: 10px;
		}

		.payroll-cancel {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 16px;
			line-height: 20px;
			text-align: center;
			color: #484b52;
			background: white;
			border: 2px solid;
			box-shadow: none;
			border: 2px solid #dfe1e6;
			box-sizing: border-box;
			border-radius: 90px;
			height: 48px;
			width: 97%;

			&:disabled {
				background: white !important;
			}
		}

		.payroll-save {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 20px;
			height: 48px;
			width: 97%;
		}
	}

	.content-Loader-payroll {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50vh;
	}
}

.payroll-save-popup {
	width: 500px !important;

	.popup_heading_text {
		font-size: 20px !important;
	}

	.message_container {
		height: 70% !important;
	}

	.routing_container {
		height: 33% !important;
	}
}

.payroll-date-validate-picker {
	font-family: Montserrat-Light;
	font-style: normal;
	font-weight: 300;
	font-size: 10px;
	line-height: 18px;
	color: #e60a14;
}

@media screen and (max-width: 770px) {
	.payroll-setting-main-header {
		background: none;

		.payroll-setting-headers {
			.payroll-setting-inner {
				display: block !important;

				.payroll-setting-dropdown-input {
					padding-top: 10px;

					.Active {
						top: 5px !important;
					}
				}
			}
		}

		.payroll-setting-sub-img {
			max-width: 9% !important;
		}
	}
}

.payroll-setting-valid {
	font-family: Montserrat-Light;
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 18px;
	color: #e60a14;
}

@media screen and (min-width: 1700px) {
	.payroll-setting-main-header {
		.payroll-setting-button {
			.payroll-save {
				margin-left: -37% !important;
				width: 60% !important;
			}

			.payroll-cancel {
				width: 60% !important;
			}
		}
	}
}
