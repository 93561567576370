@import '../../../constants/variables';
.fade-right {
    transition: all 0.4s;
    animation: fadeIn 0.2s linear;
}

@keyframes fadeIn {
    0% {
        transform: translateX(-5px);
        transform: translateY(-5px);
        opacity: 0.2;
    }

    25% {
        transform: translateX(-4px);
        transform: translateY(-4px);
        opacity: 0.4;
    }

    50% {
        transform: translateX(-3px);
        transform: translateY(-3px);
        opacity: 0.6;
    }

    75% {
        transform: translateX(-2px);
        transform: translateY(-2px);
        opacity: 0.8;
    }

    100% {
        transform: translateX(0px);
        transform: translateY(0px);
        opacity: 0.9;
    }
}

.invoice-proof-of-upload {
    background-color: #FFFFFF;
    padding: 24px;
}
.proof_of_invoice_presentational_container {
    .invoice-details {
        .ant-picker {
            border: 1px solid #dfe1e6;
            border-radius: 8px;
        }
        .ant-picker-focused {
            border: 1px solid #71b0d0;
            box-shadow: none;
        }
    }
    font-family: "Montserrat-Medium";
    padding: 0px 40px 40px 40px;
    .proof_of_invoice_transfer_header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: sticky;
        top: 0%;
        z-index: 1;
        background: $grayscale-200;
        padding: 40px 0px 28px 0px;
        height: 100px;
        .buttonnew {
            width: 87px;
        }
    }
    .invoice-btn {
        background: #F9FAFB;
        cursor: pointer;
        display: flex;
        width: 223px;
        height: 48px;
        align-items: center;
        padding: 8px;
        border-radius: 8px;
    }
    width: 100%;
    .inavtive {
        opacity: 0.2;
        cursor: not-allowed;
        pointer-events: none;
    }
    .loader {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }
    
    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .secondary_loader {
		border: 1px solid #dfe1e6;
		border-bottom-color: $red-color-2;
	}

}

.invoice-proof-header {
    min-height: 164px;
    display: flex;
    // align-items: center;
    flex-direction: column;

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #EFF1F5;
        padding: 15px 0;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
    }

}

.cash-deposit-bank-details {
    .cash-deposit-row {
        display: flex;
        font-size: 12px;
        width: 100%;
        padding-top: 8px;
        .cash-deposit-label {
            font-weight: 700;
            width: 20%;
        }
    }
}

.invoice-secondary-btn {
    button {
        width: auto;
    }
}

.highlite-text {
    min-height: calc(116px - 32px);
    padding: 16px 12px;
    background: #EEDCBA;
    border-radius: 8px;
}
.payment-icon {
    display: flex;
    align-items: center;
    padding-left: 26px;
    .payment-history-icon-container {
        align-items: center;
        background-color: #F7F8FA;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        height: 48px;
        padding: 10px;
        width: 48px;
    }
}

#view-invoice-proof {
    img {
        width: 100%;
    }
}

.invoice-history-card {
    .separator {
        border: 1px solid rgba(255, 255, 255, 0) rgba(233, 233, 233, 1) rgba(255, 255, 255, 0);
        // border-image-source: linear-gradient(90deg, rgba(255, 255, 255, 0) -0.02%, #E9E9E9 51.54%, rgba(255, 255, 255, 0) 99.98%);
        height: 0px;
        width: 1056px;
        left: 32px;
        top: 32px;
        border-radius: 0px;
        display: block;
    }

    .payment-history {
        box-shadow: 0px 16px 32px -24px #BDBDBD;
        background-color: #FFFFFF;
        display: flex;
        height: 108px;
        margin: 20px 0;
        position: relative;
        width: 100%;

        &__done {
            &:before {
                background-color: #8EF9B3;
                content: "";
                position: absolute;
                width: 18px;
                left: -10px;
                border-radius: 8px 0 0 8px;
                height: 100%;
            }
        }

        &__pending {
            &:before {
                background-color: #FFDC91;
                content: "";
                position: absolute;
                width: 18px;
                left: -10px;
                border-radius: 8px 0 0 8px;
                height: 100%;
            }
        }

        &__fail {
            &:before {
                background-color: #FF9691;
                content: "";
                position: absolute;
                width: 18px;
                left: -10px;
                border-radius: 8px 0 0 8px;
                height: 100%;
            }
        }
    }
    .underline {
        color: #0E5274;
        font-size: 14px;
        font-weight: 700;
        text-decoration: underline;
    }

    .payment-left {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 25px;
        z-index: 9;
        border-radius: 8px 0 0 8px;
        background-color: #FFFFFF;
    }

    .payment-right {
        width: 20%;
        text-align: right;
        padding: 15px 20px;
    }

    p {
        margin-bottom: 8px;
        line-height: 16px;
    }

    .h5 {
        margin-bottom: 4px;
    }

    .payment-status {
        line-height: 14px;
        color: #181919;
        font-size: 12px;
        font-weight: 700;
    }
}

.invoice-history-wrapper {
    min-height: 400px;
    padding: 32px;
    background-color: #FFFFFF;
    margin: 0 40px 40px 40px;
    border-radius: 0px 0px 8px 8px;
}

.invoice-bank-details {
    .bankdetails_container {
        padding: 0;
    }
}

.payment-history-header {
    row-gap: 0px;
    min-height: 96px;
    display: flex;
    justify-content: space-between;
    margin: 0 40px;
    align-items: center;
}