@import '../../../constants/_variables.scss';
.profile-dashboard-presentational {
	width: 100%;
	padding: 40px 40px 0px 40px;
	align-self: flex-start;
	.preview-profile-image {
		img {
			height: 60px;
		}
	}
	.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		background-color: #fff !important;
	}
	.profile_email {
		.input_img {
			left: 90%;
		}
	}
	.profile-dashboard-col {
		width: 100%;
		.header {
			.profile-header {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				color: #00344e;
			}
			.profile-header-buttons {
				display: flex;
			}

			.go-back-button {
				width: 120px;
				cursor: pointer;
				margin-right: 8px;
				span {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 14px;
					line-height: 16px;
					color: $primaryColor5;
				}
			}
			.save-button {
				width: 75px;
				cursor: pointer;
				span {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 14px;
					line-height: 16px;
					color: white;
					&:disabled {
						span {
							color: #dfe1e6 !important;
						}
					}
				}
			}
			.change-password-button {
				width: 200px;
				cursor: pointer;
				margin-right: 8px;
				span {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 14px;
					line-height: 16px;
					color: $primaryColor5;
				}
			}
		}
		.profile-body {
			margin-top: 28px;
			// row-gap: 10px;
			column-gap: 10px;
			.profile-dashboard-image {
				width: calc((100% - 65%) - 5px);
				// height: 309px;
				display: flex;
				flex-direction: column;
				padding: 32px !important;
				background: #ffffff;
				box-shadow: 0px 16px 32px -24px #bdbdbd;
				border-radius: 8px;
				// margin-right: 24px;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 24px;
					line-height: 28px;
					color: #00344e;
					margin-bottom: 24px;
				}
			}

			.profile-dashboard-input {
				width: calc((100% - 35%) - 5px);
				// width: 63%;
				// height: 309px;
				display: flex;
				flex-direction: column;
				padding: 32px !important;
				background: #ffffff;
				box-shadow: 0px 16px 32px -24px #bdbdbd;
				border-radius: 8px;

				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 24px;
					line-height: 28px;
					color: #00344e;
					margin-bottom: 24px;
				}

				> section {
					display: grid;
					grid-template-columns: 1fr 1fr;
					column-gap: 16px;
					row-gap: 16px;
					.font-bold {
						.input-text-newbox {
							color: #181919 !important;
							line-height: 14px;
							font-weight: 700;
							font-family: Montserrat-Bold;
						}
					}
					.username-input {
						span {
							font-size: 10px;
							font-family: Montserrat-Medium;
							color: #181919;
						}
						.input-note {
							width: 100%;
							// padding: 20px 12px;
							.editable {
								border: 1px solid #9a9ea6;
							}
						}
					}
					.username-input1 {
						// margin-right: 20px;
						span {
							font-size: 10px;
							font-family: Montserrat-Medium;
							color: #181919;
						}
						.input-note {
							width: 100%;
							// padding: 20px 12px;
							.editable {
								border: 1px solid #9a9ea6;
							}
							.input-text-newbox {
								color: #181919 !important;
								line-height: unset;
							}
						}
					}
					.phone-number-input {
						span {
							font-size: 10px;
							font-family: Montserrat-Medium;
							color: #181919;
						}
						.input-note {
							line-height: 14px;
							font-weight: 700;
							width: 100%;
							// padding: 20px 12px;
							.editable {
								border: 1px solid #9a9ea6;
							}
							.prefix {
								font-size: 12px;
								line-height: 14px;
								font-weight: 700;
								margin-bottom: 0;
								font-family: Montserrat-Bold;
								padding-right: 4px;
								color: #181919 !important;
							}
						}
						.phone-number-update-text {
							margin-bottom: 0;
							text-decoration: underline;
							color: #0e5274;
							cursor: pointer;
						}
					}
				}
			}
		}
		.form-content {
			width: 100%;
			.col-input-field {
				padding: 0px 18px 0px 0px;
				.ant-form-item {
					margin-bottom: 10px;
					.inputlabelrequired::after {
						content: ' *';
						color: red;
					}
				}
			}
		}
		.ant-form label {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			color: #737780;
		}
	}
	.profile-change-password {
		padding-top: 32px;
		button {
			min-height: 32px;
			height: 32px;
			width: 171px;
			min-width: 171px;
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			text-align: center;
			color: #484b52;
			padding: 0px 0px;
		}
	}
}

.mobile-number-modal {
	.mainmodal {
		width: 384px;
		.container {
			display: grid;
			width: unset;
		}
	}
	.otp-container {
		width: 100%;
		gap: 32px;
		display: grid;
		.otp-input-container {
			margin-left: auto;
			margin-right: auto;
			.otp-input-wrapper {
				.otp-input {
					display: flex;
					gap: 16px;
					.input-field {
						height: 40px;
						width: 40px;
						font-size: 24px;
						line-height: 28px;
						padding: 10px;
						text-align: center;
						border-radius: 5px;
						font-weight: bold;
						outline: none;
						transition: all 0.1s;
					}
					.input-field:focus {
						border: 1px solid #71b0d0;
					}
				}
				.error-msg {
					color: #f72717;
					width: 100%;
					display: flex;
					justify-content: end;
					margin-top: 10px;
				}
			}
		}
		.otp-validation {
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;
			.resend-otp-text {
				text-decoration: underline;
				color: #0e5274;
			}
			.verify-btn {
				width: 50%;
				span {
					overflow: unset;
				}
				.verify-button {
					background-color: #eff1f5;
					color: #dfe1e6;
					font-family: inherit;
					font-style: normal;
					border: none;
				}
			}
		}
	}
	.modal-desc {
		margin-bottom: 20px;
	}
	// .button-flex {
	// 	.one-button {
	// 		margin-top: 15px;
	// 	}
	// }
	.update-number-field-modal {
		.error-msg {
			color: #f72717;
			width: 100%;
			display: flex;
			justify-content: end;
			margin-top: 10px;
		}
		.input-number-icon {
			line-height: 14px;
			padding-right: 4px;
		}
	}
}

@media only screen and (max-width: 600px) {
	.profile-dashboard-presentational {
		padding: 65px 10px 65px 15px;
		width: 100%;
		.profile-dashboard-col {
			.header {
				span {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 20px;
					line-height: 24px;
					color: #00344e;
				}
			}
			.profile-dashboard-image {
				display: flex;
				justify-content: center;
				padding: 35px 0px;
			}
			.form-content {
				.col-input-field {
					padding: 0px 0px;
				}
				.floating-input {
					border: 2px solid #f7f8fa;
					box-sizing: border-box;
					border-radius: 8px;
				}
			}
			.profile-change-password {
				padding-top: 20px;
				display: flex;
				justify-content: center;
			}
		}
		.edenred-dropdown {
			border: 2px solid #f7f8fa;
			box-sizing: border-box;
			border-radius: 8px;
		}
	}
}
