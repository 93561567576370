// .main-box {
// 	display: flex;
// 	font-size: 10px;
// 	margin-left: 3em;
// 	margin-top: 1em;
// 	width: 81%;
// 	box-shadow: 0px 8px 24px -12px #bdbdbd;
// 	margin-bottom: 2em;
// 	.box-icon {
// 		display: flex;
// 		border-radius: 0.8em;
// 		justify-content: center;
// 		align-self: center;
// 		box-shadow: 0px 8px 24px -12px #bdbdbd;
// 		align-items: center;
// 		padding: 0.9em;
// 		height: 100%;
// 	}
// 	.Help {
// 		background: #fef5e8;
// 	}
// 	.Success {
// 		background: #8ef9b3;
// 	}
// 	.Warning {
// 		background: #ffdc91;
// 	}
// 	.Error {
// 		background: #ff9691;
// 	}
// 	.icon {
// 		height: 20px;
// 	}
// 	.box-text {
// 		margin-left: 8px;
// 		margin-bottom: 2px;
// 		display: flex;
// 		align-items: center;
// 		color: #00344e;
// 	}
// 	.box-content {
// 		width: 100%;
// 		min-height: 43px;
// 		display: flex;
// 		align-self: center;
// 		box-shadow: 0px 8px 24px -12px #bdbdbd;
// 		align-items: center;
// 		background-color: #ffffff;
// 	}
// 	.box-desc {
// 		margin-left: 8px;
// 		color: #00344e;
// 	}
// 	.box {
// 		font-family: Montserrat-light;
// 		font-size: 14px;
// 		font-style: normal;
// 		font-weight: 300;
// 		line-height: 18px;
// 		letter-spacing: 0em;
// 		text-align: left;
// 	}
// }
@import '../../../constants/variables';
.feedbox {
	display: flex;
	.Help {
		background: $secondary-brown;
	}
	.Success {
		background: $feedbox-success;
	}
	.Warning {
		background: $feedbox-warning;
	}
	.Error {
		background: $feedbox-error;
	}
	.icon {
		height: 20px;
	}
	.imagebox {
		// height: 40px;
		width: 40px;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 8px 0px;
	}
	.content-box {
		box-shadow: 0px 8px 24px -12px $gray;
		padding: 12px 0px;
		width: 75%;
		.box-text {
			margin-bottom: 2px;
			font-family: Montserrat-light;
			font-size: 14px;
			font-weight: 300, light;
			line-height: 16px;
			letter-spacing: 0em;
			text-align: left;
			color: $primaryColor5;
			display: flex;
			align-items: center;
			margin-left: 8px;
		}
		.box-desc {
			font-family: Montserrat-light;
			font-size: 14px;
			font-weight: 300, light;
			line-height: 16px;
			letter-spacing: 0em;
			text-align: left;
			color: $primaryColor5;
			display: flex;
			align-items: center;
			margin-left: 8px;
		}
	}
}
