.trade-license-banner{   	
		padding: 16px;
		width: 100%;
		background: #CEE8FF;
		display: flex;
		gap: 16px;
		border-radius: 8px;
		.info-content {
			width: 90%;			
		}
		.info-action {
			display: flex;
			justify-content: end;
			align-items: center;
            width: 116px;
			.info-action-link {
				background-color: #FBFCFD;
				padding: 8px 16px;
				border-radius: 8px;
                cursor: pointer;
			}
		}		
		.info-icon {
			display: flex;
			align-items: center;
			img {
				width: 24px;
			}
		}

}