.landing_page_main_model {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.for-success-modal {
	background: #ffffff;
	box-shadow: 0px 8px 24px -12px #bdbdbd;
	border-radius: 8px;
	.ant-modal-body {
		height: 70% !important;
	}
}
