@import './../../../constants/variables';

.run-payroll-table-presentation-wrapper {
	width: 100%;
	display: flex;
	justify-self: space-between;
	flex-direction: column;
	align-self: flex-start;
	.disabled {
		cursor: not-allowed;
	}
	.runpayroll-content {
		width: 100%;
		padding: 0px 40px 40px 40px;
		.run-payroll-table-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 40px 0px 24px 0px;
			background: $grayscale-200;
			box-shadow: 0px 8px 24px -12px #f7f8fa;
			position: sticky;
			top: 0%;
			z-index: 1;
			.run_payroll_header_content,
			.unSub_payroll_amount {
				display: flex;
				align-items: center;
				gap: 8px;
			}
			.run_payroll_header_content {
				.buttonnew {
					width: 68px;
				}
			}
		}
		.run-payroll-table-filter-wrapper {
			background: $neutral-white;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;
			padding: 32px;
			.filter-area {
				.run_payroll_search_download {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					.top-bar {
						display: flex;
						align-items: center;
						gap: 8px;
						width: 75%;
						.search-runPayroll {
							width: 50%;
						}
						.buttonnew {
							font-family: 'Montserrat-Medium';
							white-space: nowrap;
							width: 25%;
						}
					}
					.run_payroll_dropDown_entries {
						display: flex;
						align-items: center;
						gap: 10px;
						.entries_check_box {
							background: #8ef9b3;
							border-radius: 4px;
							width: 100%;
							height: 13px;
							display: flex;
							align-items: center;
							justify-content: center;
							img {
								width: 6px;
								height: 6px;
							}
						}
						.multiSelect-dropdown-container .dropdown_header .dropdown_inner {
							height: 32px;
							width: 48px;
							position: relative;
							border: 1px solid #dfe1e6 !important;
							.dropDown-menu {
								margin: 0 0 0 -8px;
								.dropdown_default {
									background: none;
									.dropdown_inner_list_main {
										font-family: 'Montserrat-Medium';
										color: #9a9ea6;
									}
								}
							}
							.dropdown_arrow {
								position: absolute;
								right: 8px;
								.arrow-inner {
									height: 15px;
									width: 15px;
								}
							}
						}
						.multiSelect-dropdown-container .dropdown_header .dropdown_list {
							margin: 0px;
							.dropdown_inner_list {
								div {
									justify-content: center;
									label {
										padding: 0px !important;
										span {
											div:nth-child(1) {
												width: 14px !important;
												height: 14px !important;
												border-width: 1px !important;
												border-radius: 4px !important;
											}
										}
									}
								}
							}
						}
					}
				}
				.filter {
					width: 20%;
				}
				.search-container {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				.search-container-1 {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
				.search-container-2 {
					display: flex;
					align-items: center;
					justify-content: flex-end;
				}
				.serach-box {
					width: 100%;
					img {
						width: 14px;
						height: 14px;
					}
				}
				.repeat-container {
					margin-left: 16px;
					width: 30%;
					text-align: center;
					border: 2px solid #00344e;
					height: 40px;
					display: flex;
					align-items: center;
					border-radius: 8px;
					flex-direction: row;
					justify-content: center;

					.Repeat-text {
						font-family: Montserrat-Regular;
						font-weight: 300px;
						color: #00344e;
						font-size: 14px;
						text-overflow: ellipsis;
					}
				}
				.repeat-container-selected {
					min-width: 240px;
					max-width: 240px;
					text-align: center;
					border: 2px solid #71b0d0;
					height: 40px;
					display: flex;
					align-items: center;
					border-radius: 8px;
					flex-direction: row;
					justify-content: center;

					.Repeat-text {
						font-family: Montserrat-Regular;
						font-weight: 300;
						color: #00344e;
						font-size: 14px;
						text-overflow: ellipsis;
					}
				}
				.repeat-container-unselected {
					min-width: 240px;
					max-width: 240px;
					text-align: center;
					border: 2px solid #71b0d0;
					height: 40px;
					display: flex;
					align-items: center;
					border-radius: 8px;
					flex-direction: row;
					justify-content: center;

					.Repeat-text {
						font-family: Montserrat-Regular;
						font-weight: 300;
						color: #00344e;
						font-size: 14px;
						text-overflow: ellipsis;
					}
				}
			}

			.filter-button {
				.fil-image {
					height: 25px;
					width: 25px;
				}
				cursor: pointer;
				width: 103px;
				height: 32px;
				border: 2px solid #eff1f5;
				box-sizing: border-box;
				border-radius: 8px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 12px;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: #484b52;
			}

			.filter-dropdown-wrapper {
				width: 100%;
				.filter-dropdown {
					padding: 10px;
					background: #ffffff;
					box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.5);
					border-radius: 16px;
					width: 100%;
					min-height: 64px;
					justify-content: space-between;
					z-index: 10;
					position: absolute;
					margin-top: 6px;
				}
			}
			.payroll_pagination {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin-top: 24px;
				.pagination-container {
					margin: 0px;
					padding: 0px;
				}
			}
		}

		.filter-content {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			.clear-select {
				width: 100%;
				display: flex;
				justify-content: flex-end;
				flex-direction: row;
				.clear {
					cursor: pointer;
					padding-right: 15px;
					span {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 12px;
						line-height: 14px;
						text-align: right;
						color: $primaryColor5;
					}
				}
			}
		}
		.runpayroll-previous {
			position: relative;
			.prev-payroll-text-container {
				margin: 2% 0px;
			}
			.prev-payroll-text {
				font-family: Montserrat-medium;
				font-size: 16px;
				color: #00344e;
			}
			.paper-box-container {
				display: flex;
				flex-direction: row;
				justify-content: space-evenly;
				margin-bottom: 10px;
				height: 206px;
			}

			.icon-box-container {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				margin-bottom: 3%;
				.icon-box1 {
					height: 32px;
					width: 32px;
					border: 1px solid #dfe1e6;
					box-sizing: border-box;
					border-radius: 8px;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: white;
				}
				.icon-box1-nonactive {
					height: 32px;
					width: 32px;
					border: 1px solid #dfe1e6;
					box-sizing: border-box;
					border-radius: 8px;
					display: flex;
					justify-content: center;
					align-items: center;
					opacity: 0.5;
					background-color: transparent;
				}
				.icon-box2-nonactive {
					height: 32px;
					width: 32px;
					border: 1px solid #dfe1e6;
					box-sizing: border-box;
					border-radius: 8px;
					display: flex;
					justify-content: center;
					align-items: center;
					opacity: 0.5;
					margin-left: 10px;
					background-color: transparent;
				}
				.icon-box2 {
					height: 32px;
					width: 32px;
					border: 1px solid #dfe1e6;
					box-sizing: border-box;
					border-radius: 8px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-left: 10px;
					background-color: white;
				}
				.icon-pagination {
					color: #00344e;
					font-size: 16px;
				}
			}
			.orange-box {
				height: 60px;
				width: 100%;
				background-color: #ea6257;
				border-radius: 16px;
				display: flex;
				justify-content: center;
				.upicon {
					margin-top: 8px;
					width: 25px;
					height: 25.03px;
				}
			}
			.white-box {
				height: 78px;
				width: 100%;
				border-radius: 16px;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				background-color: white;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				bottom: 16px;

				.show-payroll-text {
					font-family: Montserrat-medium;
					font-size: 16px;
					color: #00344e;
				}
			}
			.paper-box {
				height: 206px;
				width: 30%;
				border: 1px solid #dfe1e6;
				box-sizing: border-box;
				border-radius: 16px;
				padding: 20px;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				animation: slide 0.5s ease-in;
				position: relative;
				animation-direction: normal;
				@keyframes slide {
					0% {
						left: 300px;
					}

					100% {
						left: 0px;
					}
				}
				.payroll-card-text1 {
					font-family: Montserrat-bold;
					font-size: 20px;
					color: #00344e;
				}
				.payroll-card-text2 {
					font-family: Montserrat-medium;
					font-size: 14px;
					color: #2b769c;
				}
				.payroll-card-text3 {
					font-family: Montserrat-regular;
					font-size: 12px;
					color: #484b52;
				}
				.payroll-card-text4 {
					font-family: Montserrat-medium;
					font-size: 12px;
					color: #484b52;
				}
				.line {
					border-bottom: 1px solid #dfe1e6;
				}
				.aed-container {
					display: flex;
					justify-content: space-between;
					align-items: center;
					.payroll-card-button {
						width: auto;
						min-height: 32px;
						max-height: 42px;
						border-radius: 22px;
						border: 1px solid #71b0d0;
						background-color: white;
						padding: 0px 15px;
					}
				}
				.cards-accounts {
					display: flex;
					flex-direction: row;
					align-items: center;
					.vertical-line {
						width: 2px;
						background-color: #484b52;
						height: 11px;
						margin: 0px 4px;
						opacity: 0.5;
					}
				}
			}
		}
		.leave-error-text {
			color: #e60a14 !important;
			font-family: Montserrat-Regular;
			font-style: normal !important;
			font-weight: 300 !important;
			font-size: 10px !important;
			list-style: 12px !important;
			position: absolute !important;
			bottom: 0 !important;
		}
		.runpayroll-table-wrapper {
			margin-top: 16px;
			.payroll_table_check_box {
				background: #8ef9b3;
				border-radius: 3px;
				width: 100%;
				height: 13px;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: 8px;
					height: 8px;
				}
			}
			.salary-exceeds-error {
				background: #ff9691;
			}
			.edenred-table {
				td {
					padding: 16px 10px;
				}
				thead {
					tr {
						th {
							font-family: 'Montserrat-Medium';
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							line-height: 14px;
							color: $blue-500;
						}
					}
				}
			}
			.runpayroll-table-padding {
				.ant-table-container {
					margin-bottom: 14%;
				}
			}
			.runpayroll-table {
				.ant-table-column-sorter-up.active,
				.ant-table-column-sorter-down.active {
					color: $greyColor2;
				}

				.ant-table-thead {
					.column_icon {
						cursor: pointer;
						text-align: center;
					}
					.header_icon {
						cursor: pointer;
						text-align: center;
					}
				}
				.ant-table-body {
					max-height: unset !important;
					overflow: unset !important;
					table {
						width: unset !important;
					}
				}
				.ant-table-tbody {
					background: white;
				}
				.ant-table-cell {
					font-family: Montserrat-Medium;
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					color: #ffffff;
					text-transform: capitalize;
					.column_icon {
						cursor: pointer;
						height: 33px;
						width: 33px;
					}
				}
				.edenRedSwitch.ant-switch-checked {
					background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%);
				}

				.row-selected {
					background: #f7f8fa;
					background: #f4f6fa;
					.ant-table-tbody {
						.ant-table-cell {
							background: #f7f8fa;
							background: #f4f6fa;
						}
					}

					.checkNo {
						color: '#9a9ea6';
						font-family: Montserrat-Medium;
					}
					.checkNo-false {
						color: #484b52;
						font-family: Montserrat-Medium;
					}
				}
			}
		}
	}
	.footer-parent-payroll {
		display: flex;
		justify-content: space;
		position: fixed !important;
		bottom: 0 !important;
		width: 85% !important;
	}
	.sub-footer-payroll {
		display: flex;
		justify-content: center;
		flex-direction: row;
		width: 97%;
	}
	.runpayroll-bottom-fixed-card {
		display: flex;
		justify-content: center;
		background: #f7f8fa;
		margin-bottom: 0px;
		height: 100px;
		padding-bottom: 0;
		border-top-left-radius: 28px;
		border-top-right-radius: 28px;
		margin: 0px 28px;
		width: 100%;
		.left-content {
			display: flex;
			align-items: center;
			justify-content: center;
			.left-content-box {
				display: inline-block;
				text-align: left;
				.header {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: #737780;
				}
				.body {
					margin-top: 5px;
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: #484b52;
				}
			}
		}
		.upload-btn {
			display: flex;
			justify-content: flex-end;
			padding: 0px 0px;
			align-items: center;
			button {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				text-align: center;
				color: #484b52;
			}
			.edenRedButton.outline-g {
				border: 2px solid #dfe1e6;
				padding: 0px 1px;
				box-sizing: border-box;
				color: #00344e;
				height: 48px;
				width: 303px;
				min-height: 48px;
				min-width: 303px;
			}
		}
		.next-btn {
			padding-left: 15px;
			button {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				text-align: center;
				color: $greyColor2;
				width: 88% !important;
			}
			button[disabled] {
				color: $greyColor4;
				background: $greyColor3;
				border: none;
			}
		}
	}
}

.run-payroll-mobile-table-view {
	display: none;
}

//  NK-DROPDOWN-CSS

.nk-dropdwon {
	position: relative;
	margin: 10px 5px;
	.nk-dropdown-input-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		border: 1px solid #dfe1e6;
		box-sizing: border-box;
		border-radius: 22px;
		cursor: pointer;
		min-width: 180px;

		z-index: 4;
		.input {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: #00344e;
		}
		.icon {
			height: 20px;
			width: 20px;
			cursor: pointer;
		}
	}
	.nk-dropdown-content {
		position: absolute;
		max-height: 310px;
		overflow-y: scroll;
		width: 100%;
		background: #ffffff;
		box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
		border-bottom-right-radius: 24px;
		padding: 16px 0px 0px 7px;
		margin-top: -1px;
		z-index: 2;
		border-bottom-left-radius: 24px;

		.select-all {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 10px;
			line-height: 12px;
			color: #00344e;
			cursor: pointer;
		}
		.check-card {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0px 0px;
			cursor: pointer;

			.img-icon {
				height: 30px;
				width: 30px;
				cursor: pointer;
			}
			.dropdown-button {
				margin: 5px 0px;
				text-align: left;
				white-space: pre-wrap;
				word-break: break-all;
				padding: 0px 0px;
				font-size: 13px;
				font-family: Montserrat-Regular;
				min-height: 32px !important;
				display: flex;
				justify-content: flex-start;
			}
		}
	}
}
@media only screen and (max-width: 1600px) {
	.run-payroll-table-presentation-wrapper .runpayroll-content .leave-error-text {
		font-size: 8px !important;
	}
}
@media only screen and (max-width: 1290px) {
	.run-payroll-table-presentation-wrapper .runpayroll-content .leave-error-text {
		font-size: 7px !important;
	}
}
@media only screen and (max-width: 600px) {
	.Payroll_history_container .infinite-scroll-component {
		padding: 0px !important;
		width: 100% !important;
	}
	.Payroll_history_container .payroll_history_month_mobile_view {
		width: 100% !important;
	}
	.payroll-status-filename-con {
		justify-content: flex-start;
	}
	.run-payroll-table-presentation-wrapper .runpayroll-bottom-fixed-card .upload-btn .edenRedButton.outline-g {
		width: 100% !important;
	}
	.upload-payroll-btn {
		width: 89% !important;
		justify-content: center;
		display: flex;
	}
	.run-payroll-table-presentation-wrapper .runpayroll-content .run-payroll-table-filter-wrapper .filter-area .search-container-1 {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding-left: 18px;
		padding-top: 5px;
	}
	.run-payroll-table-presentation-wrapper .sub-footer-payroll {
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
		-ms-flex-direction: column !important;
		flex-direction: column !important;
		width: 100% !important;
		align-items: center !important;
	}
	.run-payroll-table-presentation-wrapper .runpayroll-bottom-fixed-card {
		height: 170px !important	;
		margin: 0px 13px !important;
	}
	.run-payroll-table-presentation-wrapper .footer-parent-payroll {
		position: fixed !important;
		width: 77% !important;
		bottom: 56px !important;
	}
	.run-payroll-mobile-table-view {
		margin-bottom: 236px !important;
	}
	.run-payroll-table-presentation-wrapper .runpayroll-bottom-fixed-card .upload-btn {
		justify-content: center !important;
		width: 100%;
	}
	.run-payroll-table-presentation-wrapper .runpayroll-bottom-fixed-card .next-btn {
		padding: 0px 18px;
		width: 100% !important;
		display: flex !important;
		justify-content: center;
	}
}

@media only screen and (max-width: 1340px) {
	.runpayroll-table-wrapper {
		th:first-child {
			div {
				position: relative;
				left: 0px;
				// top: 6px !important;
			}
		}
	}
}
.ant-table-container table > thead > tr:first-child th:first-child {
	display: table-cell;
	border-top-left-radius: 16px;
}

@media only screen and (min-width: 1800px) {
	.sub-footer-payroll {
		width: 85% !important;
	}
}
@media only screen and (max-width: 350px) {
	.run-payroll-table-presentation-wrapper .runpayroll-bottom-fixed-card .upload-btn .edenRedButton.outline-g {
		width: 100% !important;
	}
	.run-payroll-table-presentation-wrapper .runpayroll-content .run-payroll-table-filter-wrapper .filter-area .search-container-1 {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding-left: 18px;
		padding-top: 5px;
	}
	.run-payroll-table-presentation-wrapper .runpayroll-bottom-fixed-card .next-btn {
		padding: 0 !important;
	}
	.upload-payroll-btn {
		width: 100% !important;
		justify-content: center;
		display: flex;
	}
}
@media only screen and (max-width: 900px) {
	.run-payroll-table-presentation-wrapper .runpayroll-bottom-fixed-card .upload-btn .edenRedButton.outline-g {
		width: 232px;
		min-width: 232px;
		font-size: 15px;
	}
	.run-payroll-table-presentation-wrapper .runpayroll-bottom-fixed-card .next-btn button {
		width: 208px;
		font-size: 15px;
	}
}
@media only screen and (max-width: 767px) {
	.run-payroll-table-presentation-wrapper .runpayroll-bottom-fixed-card .next-btn {
		padding-left: 0px;
	}
}
