.headContainer {
	padding: 10px;
}
.filters {
	font-family: 'Montserrat-Bold';
	font-weight: bold;
	font-size: 20px;
	margin-top: 20%;
	line-height: 24px;
	color: #00344e;
}
.expDate {
	font-family: 'Montserrat-Medium';
	font-size: 14px;
	line-height: 16px;
	color: #00344e;
}
.no-margin {
	margin: 0px;
}
.contents {
	font-family: 'Montserrat-Regular';
	font-size: 14px;
	line-height: 16px;
	color: #00344e;
	padding-left: 10px;
	margin: 0px;
}

.entireContent {
	border: 1px solid #dfe1e6;
	display: flex;
	margin-top: 10px;
	border-radius: 999px;
	padding: 9px 16px 9px 14px;
	display: flex;
	text-align: center;
	margin-right: 8px;
}
.entireContenthidden {
	border: 1px solid #dfe1e6;
	display: flex;
	margin-top: 10px;
	border-radius: 999px;
	padding-left: 5%;
	padding-right: 5%;
	padding-top: 2%;
	padding-bottom: 2%;
	display: flex;
	text-align: center;
	opacity: 0.1;
}
.docType {
	font-family: 'Montserrat-Medium';
	font-size: 14px;
	line-height: 16px;
	color: #00344e;
	margin-top: 24px;
}
.forCheck {
	width: 16px;
}
.checkbox {
	border: 1px solid red;
}
.Mobprimary {
	margin-top: 30px;
	font-family: 'Montserrat-Medium';
	font-size: 14px;
}
.secondary {
	background-color: #f7f8fa;
	color: #dfe1e6;
	font-family: 'Montserrat-Medium';
	font-size: 16px;
	/* box-shadow: 0px 10px 20px rgb(233 180 175 / 75%); */
	margin-top: 30px;
}
