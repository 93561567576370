// rechecking-failed-employee
.rechecking-failed-employee-col {
	width: 100%;
	height: 100%;
	.rechecking-failed-employee-row {
		padding: 125px 135px 0px 135px;
		width: 100%;
		.rechecking-run-payroll {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				color: #00344e;
			}
		}
		.salary {
			margin-top: 24px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: #484b52;
			}
		}
		.aed-employee {
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: #484b52;
			}
		}
		.header {
			width: 100%;
			margin-top: 32px;
			.row-1 {
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					color: #9a9ea6;
				}
			}
		}
		.content {
			width: 100%;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: #00344e;
			}
			.arrow {
				display: flex;
				justify-content: flex-end;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					color: #00344e;
					img {
						width: 32px;
						height: 32px;
					}
				}
			}
		}
		.button-run {
			display: flex;
			flex-direction: row;
			width: 100%;
			.payroll-button {
				button {
					padding: 8px 24px;
					width: 171px;
					height: 32px;
					min-height: 32px;
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
				}
			}
			.download-button {
				margin-left: 24px;
				button {
					padding: 8px 24px;
					height: 32px;
					min-height: 32px;
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					text-align: center;
					color: #484b52;
				}
			}
		}
		.cards {
			display: none;
		}
	}
}
@media only screen and (max-width: 600px) {
	// rechecking-failed-employee
	.rechecking-failed-employee-col {
		width: 100%;
		height: 100%;
		.rechecking-failed-employee-row {
			padding: 65px 15px 70px 15px;
			width: 100%;
			.rechecking-run-payroll {
				span {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 16px;
					line-height: 18px;
					color: #00344e;
				}
			}
			.salary {
				margin-top: 8px;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					color: #737780;
				}
			}
			.aed-employee {
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					color: #737780;
				}
			}
			.header {
				display: none !important;
			}
			.divider {
				display: none !important;
			}
			.content {
				display: none !important;
			}
			// width: 100%;
			.cards {
				display: flex !important;
				background: #ffffff;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				border-radius: 16px;
				width: 100%;
				padding: 8px;
				height: 86px;
				min-height: 86px;
				margin: 8px 0px;
				.user-card {
					.image-arrow {
						display: flex;
						align-items: center;
						justify-content: flex-end;
						img {
							width: 24px;
							height: 24px;
						}
					}
					.mobile-view-content {
						.user {
							span {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 18px;
								line-height: 20px;
								color: #00344e;
							}
						}
						.id {
							span {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 12px;
								line-height: 14px;
								color: #484b52;
							}
						}
						.amount {
							span {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 10px;
								line-height: 14px;
								color: #484b52;
							}
						}
					}
				}
			}
			.button-run {
				display: flex;
				flex-direction: column;
				width: 100%;
				.payroll-button {
					margin-top: 24px;
					button {
						width: 100%;
						height: 48px;
						min-height: 48px;
						font-family: Montserrat-Bold;
						font-style: normal;
						font-weight: bold;
						font-size: 16px;
						line-height: 18px;
					}
				}
				.download-button {
					margin-top: 16px;
					display: flex;
					justify-content: center;
					button {
						padding: 0px 0px;
						height: 32px;
						width: 203px;
						min-width: 203px;
						min-height: 32px;
						font-family: Montserrat-Light;
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 16px;
						color: #00344e;
					}
				}
			}
		}
	}
}
