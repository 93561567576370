@import '../../../../constants/variables.scss';

.container-full {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-self: flex-start;

	.header-container {
		display: flex;
		justify-content: center;
		width: 100%;
		overflow: hidden;

		.content-Loader-leave {
			display: flex;
			align-items: center;
			justify-content: 'center';
			width: '100%';
			margin-left: 100vh;
		}

		.ant-layout-content {
			align-items: unset !important;
		}

		.pointer {
			.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input,
			.ant-select-selection-item {
				cursor: pointer !important;

				&:focus {
					caret-color: transparent;
				}
			}
		}

		.header-inner-container {
			width: 100%;
			padding-left: 40px;
			padding-right: 40px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-top: 40px;

			.submit-button-container {
				display: flex;
				flex-direction: row;
			}
		}

		.employee_request_header {
			width: 100%;
			padding: 28px 40px 0px 40px;

			.employee_request {
				display: flex;
				align-items: center;
				background: #ffffff;
				border-radius: 8px;
				padding: 16px 32px;

				.request_leave_emp {
					&:nth-child(1) {
						border-bottom-right-radius: 0px;
						border-top-right-radius: 0px;
						border-right: none;
						width: 120px;
					}

					&:nth-child(2) {
						border-bottom-left-radius: 0px;
						border-top-left-radius: 0px;
						width: 128px;
					}
				}
			}
		}

		.request-leave-container {
			display: flex;
			height: 100%;
			width: 100%;
			margin-top: 28px;
			overflow: hidden;

			.request-leave-section {
				margin: 0px 40px 0px 40px;
				height: fit-content;
				width: 100%;
				background-color: #ffffff;
				padding: 32px;
				border-radius: 8px;
				box-shadow: 0px 16px 32px -24px #bdbdbd;
				margin-bottom: 40px;

				.request-leave-form-container {
					display: flex;
					flex-direction: row;
					width: 100%;
					justify-content: space-between;

					.input-container {
						display: flex;
						flex-direction: column;
						width: 51%;

						.employee-input-section {
							display: flex;
							flex-direction: column;
							width: 100%;
							.error-message-container {
								display: flex;
								width: 100%;
								margin-top: 8px;

								.clk-here-text {
									text-decoration: underline;
								}
							}
							.each-input {
								display: flex;
								flex-direction: column;

								.input-headings {
									color: #181919;
									margin-bottom: 8px;
								}
								.multiSelect-dropdown-container {
									.dropdown_header {
										.dropdown_inner {
											height: 32px;

											.dropDown-menu {
												.dropdown_placeHolder {
													font-size: 12px;
													line-height: 14px;
													color: #9a9ea6;
													font-family: 'Montserrat-Light';
												}

												.dropdown_default {
													background: none;
													padding: 4px 4px 4px 0px;

													.dropdown_inner_list_main {
														margin-left: 0px;
													}

													.dropdown_inner_list_sub {
														display: none;
													}
												}
											}
										}
									}
								}
							}

							.each-input:nth-child(2) {
								margin-top: 16px;
							}

							.each-input:nth-child(3) {
								margin-top: 16px;
							}

							.days-balance-overlapping-container {
								margin-top: 16px;
								display: flex;
								flex-direction: column;

								.overlapping-section {
									display: flex;
									flex-direction: row;
									width: 100%;
									border: 1px solid #eff1f5;
									border-radius: 8px;
									background-color: #fbfcfd;
									padding: 16px;
									height: 46px;
									justify-content: space-between;
									.overlapping-leave-balance-value {
										.overlapping-container {
											display: flex;
											flex-direction: row;
											justify-content: flex-start;
											align-items: center;
											width: 95px;
											height: 14px;
											border-right: 1px solid #dfe1e6;

											.balance-and-days-style {
												color: #484b52;

												.red-text-minus {
													color: #e60a14 !important;
												}
											}
										}

										.overlapping-container:nth-child(2) {
											display: flex;
											flex-direction: row;
											width: 70px;

											border-right: none;
											padding-left: 16px;
										}
									}

									.overlapping-leave-balance-value-over {
										.overlapping-container {
											display: flex;
											flex-direction: row;
											width: 140px;

											border-right: none;

											.overlaping-leaves-style {
												color: #0e8aff;
												display: flex;
												width: 100%;
												flex-direction: row;
												justify-content: end;
												cursor: pointer;

												.arrow-leave {
													color: #0e8aff;
													margin-left: 5px;
												}
											}
										}
									}
								}
							}

							.validate-box {
								margin-top: 8px;

								.visa-passport-section {
									display: flex;
									flex-direction: column;
									height: 16px;
									width: 100%;

									.visa-passport-container {
										display: flex;
										flex-direction: row;
										width: 100%;
									}
								}
							}

							.leave-picker-container {
								display: flex;
								margin-top: 16px;
								flex-direction: column;

								.input-headings {
									color: #181919;
									margin-bottom: 8px;
								}
							}
						}
					}

					.notes-attachment-conatiner {
						display: flex;
						flex-direction: column;
						width: 45%;
						height: 278px;

						.note-header-container {
							width: 100%;
							margin-top: -7px;

							.note-typo {
								font-style: normal;
								font-weight: 500;
								font-size: 16px;
								line-height: 18px;
								letter-spacing: 0.5px;
								color: #00344e;
							}

							.note-leave-container {
								.input-note {
									width: 100%;
									align-items: baseline;
									resize: none;
									height: 72px;
									color: #9a9ea6;
									margin-top: 5px;
									border-radius: 8px;

									.input-text-newbox {
										color: #181919;
									}
								}
							}
						}

						.attachement-container {
							width: 100%;
							color: #00344e;
							font-style: normal;
							font-weight: 500;
							font-size: 16px;
							line-height: 18px;
							letter-spacing: 0.5px;
							margin-top: 16px;
							background-position: top;
							background-size: 10px 1px;
							background-repeat: repeat-x;

							.attachment-section {
								display: flex;
								width: 100%;
								.main-container-dragger {
									height: 0px;
								}
								.dragger-container-div {
									display: flex;
									width: 100%;

									.dragger {
										height: 137px;
									}
								}

								.attachment-upload-typo {
									margin-left: 26px;
								}

								.ant-upload.ant-upload-drag {
									border-radius: 16px;
									border: 2px dashed #dfe1e6;
									box-shadow: none;
								}

								.inner-type {
									font-family: Montserrat-Light;
									font-style: normal;
									font-weight: 300;
									font-size: 10px;
									line-height: 12px;
									letter-spacing: 0.5px;
									color: #737780;
									padding-top: 1px;
								}

								.ant-col {
									width: 100% !important;
								}

								.attachement-header {
									background: #ffffff;
									box-sizing: border-box;
									border-radius: 16px;
									width: 100%;
									align-items: center;
									margin-top: 8px;

									.ant-upload-list-item-info {
										bottom: 46px;
										position: absolute;
										text-align: center;
										width: 90%;
										margin-left: 30px;
									}

									.ant-upload-list-item-error .ant-upload-list-item-name {
										color: black;
									}

									.ant-upload-list-text .ant-upload-list-item-name,
									.ant-upload-list-picture .ant-upload-list-item-name {
										margin-left: 24px;
									}

									.anticon svg {
										color: black;
									}

									.ant-upload-list-text {
										width: 95%;

										.ant-upload-text-icon {
											display: none;
										}
									}

									.attachement-inner-container {
										display: flex;
										flex-direction: column;
										justify-content: center;
										align-items: center;
										margin-left: 5px;
									}
								}

								.image-compoment {
									width: 70px;
								}

								.upload-container {
									display: flex;
									justify-content: center;
									padding: 4px 0px;

									.upload-card-container {
										width: 100%;
										min-height: 30px;
										border: 1px solid $feedBackRed;
										border-radius: 8px;

										.ant-card-body {
											padding: 4px;
										}

										img {
											width: 25px;
										}

										.upload-web-view {
											display: flex;
											align-items: center;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.dropdown-container {
			width: 100%;

			.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
				line-height: 30px;
				min-width: 30px;
			}

			.dropdown-inner-container {
				display: flex;
				flex-direction: column;
				width: 94%;
				padding-top: 16px;
				padding-right: 16px;

				.ant-select {
					display: flex;
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 20px;
					letter-spacing: 0.5px;
					height: 56px;
					min-height: 52px;
					color: #484b52;

					.ant-select-selector {
						display: flex;
						align-items: center;
						height: 56px;
						width: 100%;
						border: 2px solid #f7f8fa;
						box-sizing: border-box;
						border-radius: 8px;
					}

					.ant-select-selection-item {
						justify-content: space-around;
					}

					.ant-select-selection-placeholder {
						font-family: Montserrat-Light;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 18px;
						letter-spacing: 0.5px;
						color: #9a9ea6 !important;

						&::after {
							width: 100px;
						}
					}

					ant-select-selection-search :placeholder-shown {
						content: 'yua';
						background: red;
					}

					.ant-select-arrow {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 20px;
						color: #11266900;
						content: url('../../../../assets/icons/not_focus/Down.svg');
						height: 28px;
						width: 28px;
						margin-top: -15px;
						margin-right: 0px;

						&:focus {
							cursor: pointer;
						}
					}

					.ant-select-arrow ::after {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 20px;
						color: #11266900;
						content: url('../../../../assets/icons/not_focus/Up.svg');
						height: 28px;
						width: 28px;
						margin-top: -15px;
						margin-right: 0px;

						&:focus {
							cursor: pointer;
						}
					}

					.ant-select-item {
						font-family: 'Montserrat-Medium';
						font-size: 14pt;
						font-weight: 500;
						background: #ffffff;

						&:focus {
							cursor: pointer;
						}
					}

					.ant-select-dropdown {
						background: #ffffff;
						box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
						border-radius: 15px !important;

						&:focus {
							cursor: pointer;
						}
					}
				}

				.ant-select-selection-item:focus {
					content: 'Search by Employee ID, Name...' !important;
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					letter-spacing: 0.5px;
					color: #9a9ea6 !important;
				}

				.ant-select:active {
					background: #ffffff;
					box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
					border-radius: 8px;
					outline: none;
					z-index: 10;
				}
			}
		}

		.dropdown-container1 {
			width: 100%;

			.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
				line-height: 30px;
				min-width: 30px;
			}

			.dropdown-inner-container {
				display: flex;
				flex-direction: column;
				width: 94%;
				padding-top: 16px;
				padding-right: 16px;

				.ant-select {
					display: flex;
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 20px;
					letter-spacing: 0.5px;
					height: 56px;
					min-height: 52px;
					color: #484b52;

					.ant-select-selector {
						display: flex;
						align-items: center;
						height: 56px;
						width: 100%;
						border: 2px solid #e60a14;
						box-sizing: border-box;
						border-radius: 8px;
					}

					.ant-select-selection-item {
						justify-content: space-around;
					}

					.ant-select-selection-placeholder {
						font-family: Montserrat-Light;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 18px;
						letter-spacing: 0.5px;
						color: #9a9ea6 !important;

						&::after {
							width: 100px;
						}
					}

					ant-select-selection-search :placeholder-shown {
						content: 'yua';
						background: red;
					}

					.ant-select-arrow {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 20px;
						color: #11266900;
						content: url('../../../../assets/icons/not_focus/Down.svg');
						height: 28px;
						width: 28px;
						margin-top: -15px;
						margin-right: 0px;

						&:focus {
							cursor: pointer;
						}
					}

					.ant-select-arrow ::after {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 20px;
						color: #11266900;
						content: url('../../../../assets/icons/not_focus/Up.svg');
						height: 28px;
						width: 28px;
						margin-top: -15px;
						margin-right: 0px;

						&:focus {
							cursor: pointer;
						}
					}

					.ant-select-item {
						font-family: 'Montserrat-Medium';
						font-size: 14pt;
						font-weight: 500;
						background: #ffffff;

						&:focus {
							cursor: pointer;
						}
					}

					.ant-select-dropdown {
						background: #ffffff;
						box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
						border-radius: 15px !important;

						&:focus {
							cursor: pointer;
						}
					}
				}

				.ant-select-selection-item:focus {
					content: 'Search by Employee ID, Name...' !important;
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					letter-spacing: 0.5px;
					color: #9a9ea6 !important;
				}

				.ant-select:active {
					background: #ffffff;
					box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
					border-radius: 8px;
					outline: none;
					z-index: 10;
				}
			}
		}

		.textred {
			font-family: Montserrat-Light;
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 18px;
			color: #e60a14;
		}

		.dropdown-validation {
			display: flex;
			flex-direction: row;
			// gap: 20px;
			width: 100%;
			.Passport-status-box-style {
				align-items: center;
				gap: 4px;
				margin-right: 40px;

				.image1 {
					width: 16px;
				}
			}

			.visa-status-box-style {
				align-items: center;
				gap: 4px;

				.image1 {
					width: 16px;
				}
			}
		}

		.header-date-container {
			width: 79.5%;

			.header-sub-container {
				display: flex;
				padding-top: 5px;
			}

			.date-validation {
				margin-top: 15px;
			}

			.header-dropdown {
				display: flex;
				flex-direction: row;
			}

			.Active {
				position: absolute;
				font-family: Montserrat-Light;
				font-style: normal;
				font-weight: 300;
				line-height: 14px;
				color: #9a9ea6;
				padding-top: 0px;
				top: 5px !important;
				font-size: 14px;
				left: 9px;
				z-index: 1;
				background: #ffffff;
				letter-spacing: 0.4px;
			}

			.date-validate-picker1 {
				font-family: Montserrat-Light;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 18px;
				color: #e60a14;
			}
		}

		.note-header-container {
			width: 100%;

			.note-typo {
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 18px;
				letter-spacing: 0.5px;
				color: #00344e;
			}
		}

		.inner-type {
			font-family: Montserrat-Light;
			font-style: normal;
			font-weight: 300;
			font-size: 10px;
			line-height: 12px;
			letter-spacing: 0.5px;
			color: #737780;
			padding-top: 1px;
		}

		.input-note::placeholder {
			font-family: Montserrat-Light;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 18px;
			letter-spacing: 0.5px;
			color: #9a9ea6;
			padding-top: 5px;
		}

		.note-leave-container p {
			font-family: Montserrat-Light;
			font-style: normal;
			font-weight: 300;
			font-size: 10px;
			line-height: 12px;
			letter-spacing: 0.5px;
			display: flex;
			justify-content: flex-end;
			color: #9a9ea6;
			width: 100%;
			margin: 0px;
		}

		.attachement-container {
			width: 100%;
			color: #00344e;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 18px;
			letter-spacing: 0.5px;
			background-position: top;
			background-size: 10px 1px;
			background-repeat: repeat-x;

			.attachment-upload-typo {
				margin-left: 26px;
			}

			.ant-upload.ant-upload-drag {
				border-radius: 16px;
				border: 2px dashed #dfe1e6;
				box-shadow: none;
			}

			.inner-type {
				font-family: Montserrat-Light;
				font-style: normal;
				font-weight: 300;
				font-size: 10px;
				line-height: 12px;
				letter-spacing: 0.5px;
				color: #737780;
				padding-top: 1px;
			}

			.ant-col {
				width: 97% !important;
			}

			.attachement-header {
				background: #ffffff;
				box-sizing: border-box;
				border-radius: 16px;
				width: 100%;
				align-items: center;
				margin-top: 7px;

				.ant-upload-list-item-info {
					bottom: 46px;
					position: absolute;
					text-align: center;
					width: 90%;
					margin-left: 30px;
				}

				.ant-upload-list-item-error .ant-upload-list-item-name {
					color: black;
				}

				.ant-upload-list-text .ant-upload-list-item-name,
				.ant-upload-list-picture .ant-upload-list-item-name {
					margin-left: 24px;
				}

				.anticon svg {
					color: black;
				}

				.ant-upload-list-text {
					width: 95%;

					.ant-upload-text-icon {
						display: none;
					}
				}

				.attachement-inner-container {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					margin-left: 5px;
				}
			}

			.image-compoment {
				width: 70px;
			}

			.upload-container {
				display: flex;
				justify-content: center;
				padding: 4px 0px;

				.upload-card-container {
					width: 100%;
					min-height: 30px;
					border: 1px solid $feedBackRed;
					border-radius: 8px;

					.ant-card-body {
						padding: 4px;
					}

					img {
						width: 25px;
					}

					.upload-web-view {
						display: flex;
						align-items: center;
					}
				}
			}
		}

		.button-outer-container {
			padding-bottom: 40px;

			.button-container {
				margin-top: 30px;
				font-size: 16px;
				width: 125px;
				box-shadow: none;
				font-family: 'Montserrat-Bold';
				font-weight: 700;
				background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%);
			}
		}

		.cancel-Container {
			background-color: #ffffff;
			margin-top: 10%;
			height: 240px;
			width: 100%;
			border-radius: 20px;
			padding: 10px;
			box-shadow: 0px -10px 10px rgba(174, 182, 183, 0.5);
			position: absolute;
			bottom: 0%;
			left: 0;

			.cancel-title {
				font-family: 'Montserrat-Bold';
				font-weight: 700;
				font-size: 18px;
				line-height: 20px;
				color: #00344e;
				margin-top: 20px;
				margin-bottom: 10px;
			}

			.Mobcancel {
				box-shadow: none;
				background: whitesmoke;
			}

			.Mobsave {
				font-size: 14px;
			}

			.Mobprimary {
				box-shadow: none;
			}
		}

		.leave-validate {
			font-family: Montserrat-Light;
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 18px;
			color: #e60a14;
			margin: 0;
		}
	}
}

@media screen and (min-width: 600px) {
	.mobile-logo {
		display: none;
	}
}

@media screen and (max-width: 600px) {
	.mobile-logo {
		margin: 12px;
	}

	.header-container {
		margin-top: 0px;
		padding-bottom: 40px;
		overflow: hidden;

		.content-Loader-leave {
			margin-left: 0px;
			margin-top: 32vh;
		}

		.header-inner-container {
			.header-title {
				font-size: 20px !important;
			}
		}

		.header-date-container {
			width: 91.6%;

			.header-sub-container {
				.custom-inner-container {
					.flatpickr-input:focus {
						box-shadow: none;
					}
				}
			}
		}

		.dropdown-container {
			.dropdown-inner-container {
				width: 100%;
			}
		}

		.custom-inner-container input {
			width: 93%;
		}

		.note-header-container {
			.input-note {
				width: 95%;
			}

			P {
				width: 95%;
			}

			.input-note::placeholder {
				font-size: 12px;
				line-height: 14px;
			}
		}

		.attachement-container {
			.attachement-inner-container {
				content: 'Tap to upload';
				font-size: 14px;
				line-height: 16px;
			}

			.ant-col {
				width: 97% !important;
			}
		}
	}

	.mobile-cancel-container {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
}
.request-leave-attachment {
	.dragger-container-div {
		display: block !important;
		.card_error {
			max-width: 120% !important;
			position: relative !important;
			bottom: -183%;
		}
	}
}