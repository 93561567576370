@mixin bank {
	height: 100%;
	width: 100%;
	display: flex;
}
@mixin bordered($fontfamily, $font, $weight, $lineheight, $letterspacing, $textalign, $size) {
	font-family: $fontfamily;
	font-style: $font;
	font-weight: $weight;
	line-height: $lineheight;
	letter-spacing: $letterspacing;
	text-align: $textalign;
	font-size: $size;
}
.card-holder-number {
	align-self: flex-start;
	padding: 0px 40px 40px 40px;
	width: 100%;
	.main-box {
		.c3pay_to_bank_header {
			padding: 40px 0px 24px 0px;
			display: flex;
			align-items: center;
			gap: 8px;
			span {
				width: 100%;
			}
		}
		.cardholder-card {
			width: 100%;
			padding: 32px;
			background: #ffffff;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;
			.bank_details {
				margin-top: 24px;
				display: flex;
				gap: 32px;
			}
			.card-name {
				@include bordered('Montserrat-bold', normal, '700', '28px', '0em', 'left', 24px);
				margin-bottom: 24px;
			}
			.card-title {
				//styleName: Mont_B1 Regular 14pt;
				@include bordered('Montserrat-regular', normal, 300, 16px, '0em', 'left', 14px);
				margin-top: 24px;
				color: #484b52;
			}
			.text-box {
				margin-top: 32px;
				margin-bottom: 32px;
			}
			.card-button-primary {
				// background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%);
				@include bordered(Montserrat-medium, normal, 500, 20px, 0em, center, 18px);
				border-radius: 28px;
				width: -webkit-fill-available;
				border: none;
				height: 48px;
				cursor: pointer;
				&:disabled {
					background: #f7f8fa !important;
				}
			}
		}
	}
}
.bankAccountToast {
	transition: ease-in-out 5s;
	position: absolute;
	bottom: 40px;
	left: 50%;
	z-index: 1;
	animation: fade 3s linear;
	.toat-outer {
		.toast {
			padding: 16px 20px;
		}
	}
	@keyframes fade {
		0% {
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
}
