@import '../../../../constants/_variables.scss';
.page-wrappers {
	width: inherit;
	padding: 0 40px;
	.content-wrapper {
		width: inherit;
		display: flex;
		flex-direction: column;
		gap: 24px;
		.main_wrapper {
			width: inherit;
			display: flex;
			flex-direction: column;
			gap: 32px;
			justify-content: flex-start;
			align-items: flex-start;
			padding: 32px;
			background: $neutral-white;
			box-shadow: 0px 16px 32px -24px $plaincolor;
			border-radius: 8px;
			.info_card {
				width: inherit;
				background: rgba(206, 232, 255, 0.25);
				display: flex;
				justify-content: flex-start;
				align-items: center;
				font-size: 14px;
				font-weight: 400;
				padding: 12px 16px;
				gap: 16px;
				border-radius: 8px;
				.rightIcon {
					margin: 0;
				}
			}
			.hero-wrapper {
				width: inherit;
				display: flex;
				flex-direction: column;
				gap: 16px;
				.step-box {
					width: inherit;
					padding: 16px 24px;
					background-color: #fbfcfd;
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 24px;
					.step-content {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						gap: 4px;
						.step-number {
							font-size: 12px;
							font-family: 'Montserrat-Medium';
							margin-top: 0;
							font-weight: 500;
							color: #2b769c;
						}
						.step-description {
							color: #181919;
							margin-top: 4px;
							font-size: 12px;
							font-family: 'Montserrat-Light';
							font-weight: 300;
						}
					}
				}
			}
		}
	}
}
