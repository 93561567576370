@mixin text($family, $fontsize, $fontweight, $fontlineheight, $letterspacing, $text-align) {
	font-family: $family;
	font-size: $fontsize;
	font-weight: $fontweight;
	line-height: $fontlineheight;
	letter-spacing: $letterspacing;
	text-align: $text-align;
}

.medical {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 90vh;
	.medical-content {
		.medicalinsurancecountry {
			display: flex;
			align-items: center;
			margin-top: 8px;
			margin-bottom: 8px;
			img {
				width: 32px;
			}
			.floating-label {
				width: 100%;
			}
			.edenredTypo.h1 {
				@include text(Montserrat-medium, 16px, 500, 18px, 0em, left);
				color: #00344e;
				margin-left: 8px;
				margin-right: 24px;
			}
		}
		.cancel {
			padding: 8px 24px 8px 24px;
			border: 1px solid #dfe1e6;
			background-color: #fff;
			//styleName: Mont_B1 Regular 14pt;
			@include text(Montserrat-regular, 14px, 300, 16px, 0em, center);
			color: #484b52;
			border-radius: 28px;
			cursor: pointer;
		}
		.submit {
			border-radius: 28px;
			width: 100%;
			border: none;
			padding: 14px 0px 14px 0px;
			margin-bottom: 32px;
			@include text(Montserrat-medium, 18px, 500, 20px, 0em, center);
			color: #dfe1e6;
			pointer-events: none;
			cursor: block;
		}
		.submit-active {
			border-radius: 28px;
			width: 100%;
			border: none;
			padding: 14px 0px 14px 0px;
			margin-bottom: 32px;
			@include text(Montserrat-medium, 18px, 500, 20px, 0em, center);
			background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%);
			box-shadow: 0px 10px 20px 0px #e9b4afbf;
			color: #f7f8fa;
			cursor: pointer;
		}
		.phonenumber {
			border: 2px solid #dfe1e6;
			width: 100%;
		}
		.renewal {
			margin-bottom: 32px;
		}
		.heading {
			@include text(Montserrat-bold, 24px, 700, 28px, 0em, left);
			color: #00344e;
			margin-bottom: 24px;
		}
		.radio-values {
			display: flex;
			justify-content: center;
			background: #f7f8fa;
			margin-bottom: 24px;

			.insurance-select {
				display: flex;
				align-items: center;
				margin-top: 33px;
				margin-bottom: 31px;
				.icon {
					width: 28px;
				}
				.insurance-selected-text {
					@include text(Montserrat-medium, 14px, 500, 16px, 0em, left);
					margin-bottom: 0px;
					color: #00344e;
				}
				.insurance-select-text {
					@include text(Montserrat-regular, 14px, 300, 16px, 0em, left);
					margin-bottom: 0px;
					color: #00344e;
				}
			}
		}
	}
}
