.payroll_wps_nonwps {
	.mainmodal {
		width: 500px;
		.payroll_select_date {
			display: flex;
			align-items: center;
			flex-direction: column;
			text-align: center;
			.paroll_select_date_dropdown {
				margin-top: 24px;
				width: 100%;
			}
			.payroll_select_date_button {
				display: flex;
				align-items: center;
				gap: 16px;
				margin-top: 24px;
				.buttonnew {
					width: 164px;
				}
			}
		}
	}
}
