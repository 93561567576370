.setup_main_route {
	background: #ffffff;
	.header {
		padding: 15px;
		display: flex;
	}
}
.disPlay_none {
	display: none;
}
