.imae_slider_main {
	margin-top: 40px;
	.image_slider {
		display: flex;
		align-items: center;
		gap: 10px;
		padding: 15px 10px 15px 10px;
		border-radius: 5px;
		.main_carousel {
			img {
				width: 100%;
				border-radius: 5px;
			}
		}
	}
	.left-img,
	.right-img {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(255, 255, 255, 0.3);
		border-radius: 8px;
		padding: 7px;
		img {
			width: 35px;
			height: 35px;
		}
	}
	.left-img {
		top: 37%;
		left: 50px;
	}
	.right-img {
		top: 37%;
		right: 50px;
	}
}
