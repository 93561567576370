@import '../../constants/_variables.scss';
.edenred_layout {
	height: 100%;
	display: flex;
	flex-direction: row;
	::-webkit-scrollbar {
		width: 5px;
	}

	.edenred_sider {
		background: $greyColor9 !important;
		flex: 0 0 237px !important;
		max-width: 237px !important;
		min-width: 256px !important;
		width: 237px !important;
		.edenred_sider_main_row {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			min-height: 100vh;
			.ant-menu-vertical .ant-menu-item:not(:last-child),
			.ant-menu-vertical-left .ant-menu-item:not(:last-child),
			.ant-menu-vertical-right .ant-menu-item:not(:last-child),
			.ant-menu-inline .ant-menu-item:not(:last-child) {
				margin-bottom: 0px !important;
			}
			.ant-menu-vertical .ant-menu-item,
			.ant-menu-vertical-left .ant-menu-item,
			.ant-menu-vertical-right .ant-menu-item,
			.ant-menu-inline .ant-menu-item,
			.ant-menu-vertical .ant-menu-submenu-title,
			.ant-menu-vertical-left .ant-menu-submenu-title,
			.ant-menu-vertical-right .ant-menu-submenu-title,
			.ant-menu-inline .ant-menu-submenu-title {
				margin: 0px !important;
			}
			.edenred_sider_top {
				width: 100%;
				max-height: 80vh;
				.edenred_sider_header_alerts {
					padding-top: 0px;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					.edenred_sider_header {
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: center;
						padding: 30px 10px 0px 18px;
						img {
							border-radius: 5rem;
							background: $greyColor1;
							box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
							height: 40px;
							width: 40px;
						}
						span {
							display: block;
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 16px;
							line-height: 18px;
							color: $primaryColor5;
							padding-left: 18px;
						}
						.account_type_tager_text {
							display: block;
							font-family: Montserrat-Regular;
							font-style: normal;
							font-size: 12px;
							line-height: 18px;
							color: #2b769c;
							padding-left: 18px;
						}
					}
					.edenred_sider_pending_alerts {
						padding: 8px 10px 6px 10px;
						.edenred_sider_pending_alerts_card {
							display: flex;
							flex-direction: row;
							justify-content: center;
							align-items: center;
							padding: 5%;
							background-color: $feedBackYellow;
							border-radius: 16px;
							box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
							.edenred_sider_pending_alert_img {
								border-radius: 5rem;
								background: $WarningYellow;
								box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
								height: 34px;
								width: 34px;
							}
							.pending_alert_link_column {
								.pending_alert_link {
									display: flex;
									justify-content: space-around;
									.edenred_sider_pending_alert_msg {
										display: inline-grid;
										padding-left: 2px;
										width: 100%;
										.edenred_sider_pending_alert_msg_title {
											display: flex;
											align-items: flex-end;
											font-size: 12px;
											font-weight: bolder;
											line-height: 14px;
											color: $primaryColor5;
										}
										.edenred_sider_pending_alert_view_details {
											display: flex;
											align-items: flex-end;
											font-size: 10px;
											font-weight: 300;
											line-height: 14px;
											color: $greyColor7;
										}
									}
									.edenred_sider_pending_alert_arrow {
										display: flex;
										justify-content: flex-end;
										align-items: center;
										img {
											height: 16px;
											width: 16px;
										}
									}
								}
							}
						}
					}
				}
				.edenred_sider_menu {
					padding-top: 4px;
					overflow: auto;
					height: 63vh;
					width: 100%;
					.custom_menu {
						width: 100%;
						padding: 2px 1px 1px 1px;
						background: $greyColor9;
						//Ant Menu - Submenu Arrow
						.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
							-webkit-transform: rotate(-45deg) translateX(5px) translateY(2px);
							transform: rotate(-45deg) translateX(5px) translateY(2px);
						}
						.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
							-webkit-transform: rotate(45deg) translateX(5px) translateY(-2px);
							transform: rotate(45deg) translateX(5px) translateY(-2px);
						}
						.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
							-webkit-transform: rotate(45deg) translateX(-2.5px);
							transform: rotate(45deg) translateX(-2.5px);
						}
						.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
							-webkit-transform: rotate(-45deg) translateX(-2px);
							transform: rotate(-45deg) translateX(-2.5px);
						}
						.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
						.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
						.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
						.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
						.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
						.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
						.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
						.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
							position: absolute;
							width: 9.5px;
							height: 1.25px;
							background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 52, 78, 0.85)), to(rgba(0, 52, 78, 0.85)));
							background-image: linear-gradient(to right, rgba(0, 52, 78, 0.85), rgba(0, 52, 78, 0.85));
							border-radius: 2px;
							-webkit-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
								-webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
							transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
								-webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
							transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
								top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
							transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
								top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
							content: '';
						}
						.ant-menu-item {
							height: 38px;
							display: flex;
							align-items: center;
							img {
								height: 24px;
								width: 24px;
							}
							a {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 14px;
								line-height: 16px;
								color: $greyColor6;
							}
							span {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 14px;
								line-height: 16px;
								color: $greyColor6;
								padding-left: 8px;
							}
						}
						.ant-menu-sub {
							background: $greyColor9;
						}

						.ant-menu-item-selected {
							background-color: $greyColor3;
							span {
								font-family: Montserrat-Medium;
								font-style: normal;
								//font-weight: 900;
								font-size: 14px;
								line-height: 16px;
								color: $greyColor7;
							}
							a {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 14px;
								line-height: 16px;
								color: $greyColor7;
							}
						}
						a {
							color: $greyColor7;
						}
					}
					.custom_menu {
						padding: 2px 1px 1px 1px;
						background: $greyColor9;
						//Ant Menu - Submenu Arrow
						.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
							-webkit-transform: rotate(-45deg) translateX(5px) translateY(2px);
							transform: rotate(-45deg) translateX(5px) translateY(2px);
						}
						.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
							-webkit-transform: rotate(45deg) translateX(5px) translateY(-2px);
							transform: rotate(45deg) translateX(5px) translateY(-2px);
						}
						.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
							-webkit-transform: rotate(45deg) translateX(-2.5px);
							transform: rotate(45deg) translateX(-2.5px);
						}
						.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
							-webkit-transform: rotate(-45deg) translateX(-2px);
							transform: rotate(-45deg) translateX(-2.5px);
						}
						.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
						.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
						.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
						.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
						.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
						.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
						.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
						.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
							position: absolute;
							width: 9.5px;
							height: 1.25px;
							background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 52, 78, 0.85)), to(rgba(0, 52, 78, 0.85)));
							background-image: linear-gradient(to right, rgba(0, 52, 78, 0.85), rgba(0, 52, 78, 0.85));
							border-radius: 2px;
							-webkit-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
								-webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
							transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
								-webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
							transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
								top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
							transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
								top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
							content: '';
						}
						.ant-menu-item {
							height: 38px;
							display: flex;
							align-items: center;
							img {
								height: 24px;
								width: 24px;
							}
							a {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 14px;
								line-height: 16px;
								color: $greyColor6;
							}
							span {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 14px;
								line-height: 16px;
								color: $greyColor6;
								padding-left: 8px;
							}
						}
						.ant-menu-sub {
							background: $greyColor9;
						}

						.ant-menu-item-selected {
							background-color: $greyColor3;
							span {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 900;
								font-size: 14px;
								line-height: 16px;
								color: $greyColor7;
							}
							a {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 14px;
								line-height: 16px;
								color: $greyColor7;
							}
						}
						a {
							color: $greyColor7;
						}
					}

					.custom_my_menu {
						padding: 2px 1px 1px 1px;
						background: $greyColor9;
						//Ant Menu - Submenu Arrow
						.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
							-webkit-transform: rotate(-45deg) translateX(5px) translateY(2px);
							transform: rotate(-45deg) translateX(5px) translateY(2px);
						}
						.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
							-webkit-transform: rotate(45deg) translateX(5px) translateY(-2px);
							transform: rotate(45deg) translateX(5px) translateY(-2px);
						}
						.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
							-webkit-transform: rotate(45deg) translateX(-2.5px);
							transform: rotate(45deg) translateX(-2.5px);
						}
						.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
							-webkit-transform: rotate(-45deg) translateX(-2px);
							transform: rotate(-45deg) translateX(-2.5px);
						}
						.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
						.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
						.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
						.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
						.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
						.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
						.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
						.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
							position: absolute;
							width: 9.5px;
							height: 1.25px;
							background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 52, 78, 0.85)), to(rgba(0, 52, 78, 0.85)));
							background-image: linear-gradient(to right, rgba(0, 52, 78, 0.85), rgba(0, 52, 78, 0.85));
							border-radius: 2px;
							-webkit-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
								-webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
							transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
								-webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
							transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
								top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
							transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
								top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
							content: '';
						}
						.ant-menu-item {
							height: 38px;
							display: flex;
							align-items: center;
							img {
								height: 24px;
								width: 24px;
							}
							a {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 14px;
								line-height: 16px;
								color: $greyColor6;
							}
							span {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 14px;
								line-height: 16px;
								color: $greyColor6;
								padding-left: 8px;
							}
						}
						.ant-menu-sub {
							background: $greyColor9;
						}

						.ant-menu-item-selected {
							background-color: $greyColor3;
							span {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 900;
								font-size: 14px;
								line-height: 16px;
								color: $greyColor7;
							}
							a {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 14px;
								line-height: 16px;
								color: $greyColor7;
							}
						}
						a {
							color: $greyColor7;
						}
					}
					.ant-menu-item::after {
						// position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						// background-color: $secondaryColor3;
						// width: 5px;
						border-left: 5px solid $secondaryColor3;
						border-right: 0px;
						// content: '';
					}
					.ant-menu-submenu-title {
						height: 40px;
						margin: 0 !important;
						img {
							height: 24px;
							width: 24px;
						}
						span {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 14px;
							line-height: 16px;
							color: $greyColor6;
							padding-left: 8px;
						}
						&:hover {
							color: $greyColor7;
						}
					}
					.ant-menu-submenu-selected {
						.ant-menu-submenu-title {
							border-left: 5px solid $secondaryColor3;
							border-right: 0px;
							background-color: $greyColor3;
							padding-left: 18px !important;
							span {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 14px;
								line-height: 16px;
								color: $greyColor7;
							}
						}
						.ant-menu-item-selected {
							span {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 14px;
								line-height: 16px;
								color: $greyColor7;
							}
						}
					}
					.ant-menu-submenu {
						.ant-menu-item {
							&:after {
								border: 0;
							}
						}
					}
					.ant-menu-item {
						&.ant-menu-item-only-child {
							&.ant-menu-item-selected {
								background-color: $greyColor9;
								border-left: 0;
							}
						}
					}
				}
			}
			.edenred_sider_footer {
				display: flex;
				flex-direction: column;
				width: 100%;
				justify-content: center;
				align-items: center;
				.custom_menu {
					padding: 1%;
					background: $greyColor9;
					.ant-menu-item {
						min-height: 40px;
						display: flex;
						align-items: center;
						span {
							padding-left: 8px;
						}
						img {
							height: 24px;
							width: 24px;
						}
					}
					.ant-menu-item-selected {
						background-color: $greyColor3;
						span {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 16px;
							color: $greyColor7;
						}
					}
					a {
						color: $greyColor7;
					}
					.ant-menu-item::after {
						top: 0;
						left: 0;
						bottom: 0;
						border-left: 5px solid $secondaryColor3;
						border-right: 0px;
					}
				}
			}
		}
	}
	.site-layout-sub-header-background {
		padding: 0;
		background: white;
		.company_dropdown {
			cursor: pointer;
			border: none;
			box-sizing: border-box;
			border-radius: 22px;
			display: flex;
			justify-content: space-evenly;
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 18px;
			color: $primaryColor5;
			align-items: center;
			margin-top: 13px;
			padding: 5px;
			z-index: 1;
			display: flex;
			img {
				width: 25px;
				height: 25px;
			}
			.count {
				display: inline-block;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: #9a9ea6;
				padding-left: 5px;
			}
			&.expand {
				box-shadow: '0px 5px 10px rgba(174, 182, 183, 0.25)';
				z-index: 2;
				position: inherit;
			}
		}
		.company_list_dropdown_content {
			position: absolute;
			min-height: 140px;
			overflow-y: scroll;
			background: $greyColor1;
			width: 100%;
			z-index: 1;
			background: #ffffff;
			box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.5);
			border-radius: 16px;
			min-width: 400px;
			padding-left: 10px;
			padding-right: 10px;

			.company_select_item_row {
				display: flex;
				align-items: center;
				width: 100%;
				padding: 5px;
				border-bottom: 1px solid #dfe1e6;

				.company_select_item {
					box-sizing: border-box;
					padding: 0px 10px;
					margin-right: 2px;
					font-style: normal;
					line-height: 18px;
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					color: $primaryColor5;
					color: #00344e;
					width: 100%;
					img {
						width: 25px;
						height: 25px;
						margin-right: 5px;
						cursor: pointer;
					}
					.count {
						display: inline-block;
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 18px;
						color: #9a9ea6;
					}
				}
				.company_select_item.active {
					font-weight: 500;
					font-family: Montserrat-Medium;
					width: 100%;
				}
			}
			.ant-divider-horizontal {
				margin: 0px;
			}
		}
		.company_build {
			// padding-left: 9.5%;
			padding-left: 135px;
			.header_content {
				color: $primaryColor5;
				font-size: 16px;
			}
		}
		.logo_header {
			padding: 0px;
		}
		.logo_header_cancel {
			display: none;
		}
	}
	.ant-layout-content {
		margin: 2px 0px 0;
		height: 100%;
		background: $grayscale-200;
		overflow-y: auto;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.main_container_width {
		max-width: 1440px;
		width: 100%;
		align-self: flex-start;
	}
}
.leaves_menu_item {
	.leaves {
		content: url('../../assets/icons/not_focus/AnnualLeave.svg');
		filter: invert(51%) sepia(2%) saturate(2030%) hue-rotate(184deg) brightness(89%) contrast(80%);
	}
	&.ant-menu-submenu-selected {
		.leaves {
			content: url('../../assets/icons/focus_state/AnnualLeave.svg');
			filter: none;
		}
	}
}
.leaves_menu_item {
	span {
		line-height: 12px;
	}
	.employees {
		height: 5vh;
		padding: 0;
		content: url('../../assets/icons/not_focus/AnnualLeave.svg');
	}
	&.ant-menu-item-selected {
		.employees {
			content: url('../../assets/icons/focus_state/AnnualLeave.svg');
			filter: none;
		}
	}
}
.dashboard_menu_item {
	.dashboard {
		content: url('../../assets/icons/not_focus/Dashboard.svg');
		filter: invert(51%) sepia(2%) saturate(2030%) hue-rotate(184deg) brightness(89%) contrast(80%);
	}
	&.ant-menu-item-selected {
		.dashboard {
			content: url('../../assets/icons/focus_state/Dashboard.svg');
			filter: none;
		}
	}
}
.payroll_menu_item {
	.payroll {
		content: url('../../assets/icons/not_focus/Payroll-1.svg');
		filter: invert(51%) sepia(2%) saturate(2030%) hue-rotate(184deg) brightness(89%) contrast(80%);
	}
	&.ant-menu-submenu-selected {
		.payroll {
			content: url('../../assets/icons/focus_state/Payroll.svg');
			filter: none;
		}
	}
}
.employees_menu_item {
	.employees {
		content: url('../../assets/icons/not_focus/EmployeeManage.svg');
		filter: invert(51%) sepia(2%) saturate(2030%) hue-rotate(184deg) brightness(89%) contrast(80%);
	}
	&.ant-menu-submenu-selected {
		.employees {
			content: url('../../assets/icons/focus_state/EmployeeManage.svg');
			filter: none;
		}
	}
}
.team_permissions_menu_item {
	.team_permissions {
		content: url('../../assets/icons/not_focus/Approvals.svg');
		filter: invert(51%) sepia(2%) saturate(2030%) hue-rotate(184deg) brightness(89%) contrast(80%);
	}
	&.ant-menu-item-selected {
		.team_permissions {
			content: url('../../assets/icons/focus_state/Approvals.svg');
			filter: none;
		}
	}
}
.company_menu_item {
	.company {
		content: url('../../assets/icons/not_focus/Info.svg');
		filter: invert(51%) sepia(2%) saturate(2030%) hue-rotate(184deg) brightness(89%) contrast(80%);
	}
	&.ant-menu-submenu-selected {
		.company {
			content: url('../../assets/icons/focus_state/Info.svg');
			filter: none;
		}
	}
}
.documents_menu_item {
	.document {
		content: url('../../assets/icons/not_focus/Document.svg');
		filter: invert(51%) sepia(2%) saturate(2030%) hue-rotate(184deg) brightness(89%) contrast(80%);
	}
	&.ant-menu-item-selected {
		.document {
			content: url('../../assets/icons/focus_state/Document.svg');
			filter: none;
		}
	}
}
.announcement_menu_item {
	.document {
		content: url('../../assets/img/Comments.svg');
		filter: invert(51%) sepia(2%) saturate(2030%) hue-rotate(184deg) brightness(89%) contrast(80%);
	}
	&.ant-menu-item-selected {
		.document {
			content: url('../../assets/img/Comments.svg');
			filter: none;
		}
	}
}
.health_menu_items {
	.healthInfo {
		content: url('../../assets/icons/not_focus/health.svg');
		filter: invert(51%) sepia(2%) saturate(2030%) hue-rotate(184deg) brightness(89%) contrast(80%);
	}
	&.ant-menu-submenu-selected {
		.company {
			content: url('../../assets/icons/not_focus/health.svg');
			filter: none;
		}
	}
}
.help_menu_item {
	.help {
		content: url('../../assets/icons/not_focus/Help.svg');
		filter: invert(51%) sepia(2%) saturate(2030%) hue-rotate(184deg) brightness(89%) contrast(80%);
		opacity: 0.7;
	}
	&.ant-menu-item-selected {
		.help {
			content: url('../../assets/icons/focus_state/Help.svg');
			opacity: 0.7;
			filter: none;
		}
	}
}

.video_tutorial_menu_item {
	.videoIcon {
		content: url('../../assets/icons/not_focus/VideoTutorialNotFocused.svg');
		filter: invert(51%) sepia(2%) saturate(2030%) hue-rotate(184deg) brightness(89%) contrast(80%);
		opacity: 0.7;
	}
	&.ant-menu-item-selected {
		.videoIcon {
			content: url('../../assets/icons/focus_state/VideoTutorialFocused.svg');
			opacity: 0.7;
			filter: none;
		}
	}
}

.logout_menu_item {
	.logout {
		filter: invert(51%) sepia(2%) saturate(2030%) hue-rotate(184deg) brightness(89%) contrast(80%);
		opacity: 0.7;
	}
	&.ant-menu-item-selected {
		.logout {
			filter: none;
			opacity: 0.7;
		}
	}
}
.menu_mobile {
	align-items: flex-end;
	width: 100%;
	display: none;
}
.header_main_container {
	display: none;
}
@media only screen and (max-width: 600px) {
	.header_main_container {
		// display: flex !important;
		.close {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			span {
				cursor: pointer;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				text-align: right;
				color: $primaryColor5;
				padding-right: 5px;
			}
			&.cancel {
				color: $primaryColor4;
			}
		}
		.profile_icon {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding: 4px 0px 8px 0px;
			img {
				height: 35px;
				width: 35px;
			}
		}
	}
	.menu_mobile {
		display: flex;
		flex-direction: row;
		position: fixed;
		bottom: 0;
		width: 100%;
		justify-content: space-evenly;
		z-index: 1;
		.custom_menu {
			display: flex;
			width: 100%;
			align-items: flex-end;
			justify-content: space-between;
			background: $greyColor9;
			height: 60px;
			.ant-menu-item {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: -webkit-fill-available;
				margin: 0px 4px;
				padding: 0px 28px;
				img {
					height: 24px;
					width: 24px;
				}
				a {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor6;
				}
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor6;
				}
			}
			.ant-menu-sub {
				background: $greyColor9;
			}
			.ant-menu-item-selected {
				background-color: $greyColor3;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor7;
				}
				a {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor7;
				}
			}
			a {
				color: $greyColor7;
			}
		}

		.dashboard_menu_item {
			span {
				line-height: 12px;
			}
			.dashboard {
				content: url('../../assets/icons/not_focus/Dashboard.svg');
				height: 5vh;
				padding: 0;
			}
			&.ant-menu-item-selected {
				.dashboard {
					content: url('../../assets/icons/focus_state/Dashboard.svg');
					filter: none;
				}
			}
		}
		.payroll_menu_item {
			span {
				line-height: 12px;
			}
			.payroll {
				height: 5vh;
				padding: 0;
				content: url('../../assets/icons/not_focus/Payroll-1.svg');
			}
			&.ant-menu-item-selected {
				.payroll {
					content: url('../../assets/icons/focus_state/Payroll.svg');
					filter: none;
				}
			}
		}
		.employees_menu_item {
			span {
				line-height: 12px;
			}
			.employees {
				height: 5vh;
				padding: 0;
				content: url('../../assets/icons/not_focus/EmployeeManage.svg');
			}
			&.ant-menu-item-selected {
				.employees {
					content: url('../../assets/icons/focus_state/EmployeeManage.svg');
					filter: none;
				}
			}
		}
		.more_menu_item {
			span {
				line-height: 12px;
			}
			.more {
				height: 5vh;
				padding: 0;
				content: url('../../assets/icons/not_focus/More.svg');
			}
			&.ant-menu-item-selected {
				.more {
					content: url('../../assets/icons/focus_state/More.svg');
					filter: none;
				}
			}
		}
	}
	.edenred_sider {
		display: none;
	}
	.site-layout-sub-header-background {
		display: none !important;
		.company_build {
			display: none;
		}

		.logo_header {
			padding-left: 10px !important;
		}
		.logo_header_cancel {
			display: flex !important;
			a {
				color: #0e5274;
			}
		}
	}
	.edenred_layout {
		.ant-layout-content {
			display: unset;
			align-items: unset;
		}
		.main_container_width {
			height: 100%;
		}
	}
	.ant-menu-submenu-popup {
		display: none !important;
	}
}

@media only screen and (max-width: 780px) and (min-width: 601px) {
	.edenred_layout {
		.site-layout-sub-header-background {
			.company_build {
				padding-left: 1%;
			}
		}
	}
}
.freshdeskHide {
	display: none;
}
.freshdeskShow {
	display: block;
}
