@import '../../constants/_variables.scss';
.more_SubMenuPage_Container {
	.SubMenuPage {
		display: none;
	}
}
@media only screen and (max-width: 600px) {
	.more_SubMenuPage_Container {
		opacity: 0.75;
		background: white;
		height: 90%;
		width: 100%;
		.SubMenuPage {
			display: flex !important;
			.SubMenuPage_card {
				width: 100%;
				display: flex;
				align-items: flex-end;
				position: absolute;
				z-index: 10;
				bottom: 0;
				.SubMenuPage_link_horizontal {
					border: 2px solid #dfe1e6;
					background: #dfe1e6;
					border-radius: 4px;
					width: 20%;
					text-align: center;
				}
				.SubMenuPage_card_content {
					width: 100%;
					padding: 5% 3% 1% 3%;
					box-shadow: 0px -10px 20px rgba(174, 182, 183, 0.25);
					height: auto;
					border-top-left-radius: 24px;
					border-top-right-radius: 24px;
					.submenu_item_card_row {
						padding: 1% 1%;
						display: flex;
						align-items: center;
						.submenuitem_logo {
							img {
								height: 35px;
								width: 35px;
							}
						}
						.submenuitem_title {
							span {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 16px;
								line-height: 18px;
								color: #737780;
							}
						}
						.right_arrow {
							display: flex;
							justify-content: flex-end;
							img {
								height: 25px;
								width: 25px;
							}
						}
					}
					.horizontal_line {
						background: #dfe1e6;
					}
				}
			}
		}
	}
}
