@import '../../../../constants/_variables.scss';

.leave-calculation-presentational {
	padding: 0px 40px 40px 40px;
	width: 100%;
	align-self: flex-start;
	.leave-calculation-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: sticky;
		top: 0;
		z-index: 1;
		background: #f7f8fa;
		padding: 42px 0px 26px 0px;
		p {
			font-family: 'Montserrat-Bold';
			font-style: normal;
			font-weight: 700;
			font-size: 24px;
			line-height: 28px;
			color: $blue-500;
			margin: 0px;
		}
		.secondary-btn {
			font-family: 'Montserrat-Medium';
		}
		.buttonnew {
			width: 118px;
			img {
				margin-right: 0px;
			}
		}
	}
	.payroll-col {
		background: $neutral-white;
		box-shadow: 0px 16px 32px -24px $plaincolor;
		border-radius: 8px;
		width: 100%;
		padding: 32px;
		.wps-rules-header {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				color: $primaryColor5;
			}
		}
		.questions-conter {
			.question {
				padding-top: 24px;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 18px;
					color: $primaryColor5;
				}
			}
			.answer {
				padding-top: 8px;
				display: flex;
				flex-direction: row;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor7;
				}
			}
		}
		.questions-conter-medical {
			.question {
				padding: 24px 0px;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: $grayscale-700;
				}
			}
			.border-table-header {
				border: 1px solid #dfe1e6;
				border-right: none;
				box-sizing: border-box;
				padding: 24px 49px 24px 24px;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: $blue-300;
				}
			}
			.border-table {
				border: 1px solid #dfe1e6;
				border-left: none;
				box-sizing: border-box;
				padding: 24px 61px 24px 24px;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: #484b52;
				}
			}
			.answer {
				padding-top: 8px;
				display: flex;
				flex-direction: row;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor7;
				}
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.leave-calculation-presentational {
		width: 100%;
		padding: 64px 10px 67px 15px;
		.payroll-col {
			width: 100%;
			padding-bottom: 65px;
			.wps-rules-header {
				margin-top: 0px;
				span {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 20px;
					line-height: 24px;
					color: $primaryColor5;
				}
			}
			.questions-conter {
				.question {
					padding-top: 24px;
					span {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 16px;
						color: $grayscale-900;
					}
				}
				.answer {
					padding-top: 8px;
					display: flex;
					flex-direction: column;
					padding-left: 3px;

					.card {
						width: 120px;
						height: 120px;
						background: $greyColor2;
						border-radius: 24px;
					}
					.pay-atleast {
						padding-left: 0px;
					}
					span {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 16px;
						color: #737780;
					}
				}
			}

			.questions-conter-medical {
				.question {
					padding: 12px 0px;
					span {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 16px;
						color: $grayscale-700;
					}
				}
				.border-table-header {
					border: 0px solid #dfe1e6;
					box-sizing: border-box;
					padding: 0px 0px 0px 0px;
					span {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 16px;
						color: #00344e;
					}
				}
				.border-table {
					border: 0px solid #dfe1e6;
					box-sizing: border-box;
					padding: 0px 0px 0px 0px;
					span {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 16px;
						color: #737780;
					}
				}
				.answer {
					padding-top: 8px;
					display: flex;
					flex-direction: row;
					span {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 16px;
						color: $greyColor7;
					}
				}
			}
		}
	}
}
