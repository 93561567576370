.setup-leave-balance-upload {
	display: flex;
	flex-direction: column;
	height: 100%;

	.setup_tabs {
		margin-top: 0px;
		display: flex;
		justify-content: center;
	}
}
