@import './../../../constants/variables';

.company_information_web_presentational {
	align-self: flex-start;
}
.company_information_container {
	width: 100%;
	.company_information_title {
		padding: 40px 40px 24px 40px;
		position: sticky;
		top: 0%;
		z-index: 1;
		background: $grayscale-200;
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.company_documents_details_row {
		width: 100%;
		padding: 32px;
		margin: 32px 40px 0 40px;
		background: #ffffff;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		.company_documents_title {
			span {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 20px;
				color: $primaryColor5;
			}
		}
		.company_documentation_card_section {
			.company_document_card_column {
				padding-left: 0px;
				width: 100%;
				&.feedbackWarning {
					background: $feedbackWarning;
				}
				&.feedbackSuccess {
					background: $feedbackSuccess;
				}
				&.feedbackError {
					background: $feedbackError;
				}
				&.powerOfAttorney {
					background: $greyColor4;
				}
				.company_document_card {
					background: $greyColor1;
					border: none;
					border-radius: 8px;
					padding: 12px;
					cursor: pointer;
					margin: 16px 0px;
					border: 1px solid #dfe1e6;
					padding: 24px;
					&:last-child {
						margin: 16px 0px 0px 0px;
					}
					.ant-card-body {
						display: flex;
						align-items: center;
						// justify-content: center;
						.document_icon {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 44px;
							height: 44px;
						}
						.card_content_section {
							display: inline-grid;
							margin-left: 24px;
							width: 100%;
							span {
								padding: 2px 0px;
							}
							.card_type {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 14px;
								line-height: 16px;
								color: $greyColor7;
							}

							.card_title {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 16px;
								line-height: 18px;
								color: $primaryColor5;
							}
							.license_type {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 16px;
								line-height: 18px;
								color: $greyColor7;
							}
							.license_id {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 16px;
								line-height: 18px;
								color: $greyColor7;
							}
							.hint {
								font-family: Montserrat-Light;
								font-style: normal;
								font-weight: 300;
								font-size: 14px;
								line-height: 16px;
								color: $greyColor7;
							}
							.expires_on {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 16px;
								line-height: 18px;
								color: $greyColor7;
							}

							.document_validity_status {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 16px;
								line-height: 18px;
								&.feedbackWarning {
									color: $feedbackWarning2;
								}
								&.feedbackError {
									color: $feedbackError;
								}
							}
						}
						.document_update_button {
							display: flex;
							align-items: center;
							justify-content: flex-end;
							.view_detail,
							.upload_detail {
								font-family: 'Montserrat-Medium';
								font-style: normal;
								font-weight: 500;
								font-size: 14px;
								line-height: 16px;
								height: 32px;
							}
							.view_detail {
								width: 125px;
								color: #484b52;
							}
							.upload_detail {
								width: 88px;
								color: #ffffff;
							}
						}
					}
				}
			}
		}
	}
	.new_revamp {
		width: 100%;
		padding: 32px 40px 40px 40px;
		.add_new_main_trade_license_section {
			background: #ffffff;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;
			padding: 32px;
			.add_new_main_trade_license_description {
				// padding: 8px 0px 8px 0px;
				span {
					font-family: 'Montserrat-Light';
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $grayscale-700;
				}
			}
			.add_new_main_trade_license_step_button_section {
				margin-top: 16px;
				.add_new_main_trade_license_steps_container {
					display: flex;
					align-items: center;
					padding: 0px 8px 12px 0px;
					&:last-child {
						padding-bottom: 0px;
					}
					.add_new_main_trade_license_step_icon {
						display: flex;
						width: 32px;
						height: 32px;
						max-width: 32px;
						max-height: 32px;
						justify-content: center;
						align-items: center;
						background: $grayscale-100;
						border-radius: 8px;
						span {
							font-family: 'Montserrat-Medium';
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 16px;
							color: $grayscale-700;
						}
					}
					.add_new_main_trade_license_step_content {
						display: flex;
						padding: 0px 4px 0px 10px;
						span {
							font-family: Montserrat-Light;
							font-style: normal;
							font-weight: 300;
							font-size: 14px;
							line-height: 16px;
							color: $grayscale-700;
						}
					}
				}
				button {
					height: 32px;
					width: 209px;
					min-width: 209px;
					min-height: 32px;
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor2;
					padding: 4px;
				}
			}
		}
	}
}

//company_details__sub_page
.company_information_company_details_row {
	width: 100%;
	padding: 0px 40px;
	.company_details_title {
		padding-bottom: 24px;
		span {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 20px;
			color: $primaryColor5;
		}
	}
	.company_details_row {
		width: 100%;
		padding: 32px;
		background: #ffffff;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		// padding: 12px 0px 8px 0px;
		.company_details_name {
			padding: 0px 16px 16px 0px;
			.input-label-top {
				margin-bottom: 8px;
				margin-top: 0px;
			}
			.input-box {
				height: 32px;
			}
		}
		.company_details_area {
			padding: 0px 16px 0px 0px;
		}
		.company_details_address {
			padding: 0px 0px 16px 0px;
			&:last-child {
				padding: 0px;
			}
			.input-label-top {
				margin-bottom: 8px;
				margin-top: 0px;
			}
			.input-box {
				height: 32px;
			}
			.dropdownfield {
				width: 100%;
			}
		}
		.company_details_save {
			padding: 8px 8px 0px 0px;
		}
	}
}

// please-upload-spreadsheet
.please-upload-spreadsheet {
	width: 100%;
	align-self: flex-start;
	padding: 0px 40px;
	.new_design_Info {
		background: #ffffff;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 32px;
		width: 100%;
	}
	.upload-spreadsheet-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 40px 0px 24px 0px;
		background: $grayscale-200;
		.new_desgin_info_button {
			display: flex;
			align-items: center;
			gap: 10px;
			.secondary-btn {
				background: transparent;
			}
		}
	}
	.dragger_card_container {
		background-position: top;
		background-size: 10px 1px;
		background-repeat: repeat-x;
		.ant-upload.ant-upload-drag .ant-upload {
			padding: 8px 0;
		}
		.ant-upload-list-item {
			display: none;
		}
		.whole {
			display: inline-grid;
			padding: 16px 0px;
			.drag-upload {
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 20px;
					text-align: center;
					color: #00344e;
				}
			}
			.click-here {
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					text-align: center;
					color: #00344e;
				}
			}
		}
		.image-spreadsheet {
			img {
				width: 120px;
				height: 120px;
			}
		}
		.after_file_upload {
			display: inline-grid;
			.file_name {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				text-align: center;
				color: $primaryColor5;
				padding: 4px 0px;
			}
			.file_size {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				text-align: center;
				color: $primaryColor5;
				padding: 4px 0px;
			}
		}

		.dragger {
			width: 100%;
			.Upload_row_2 {
				display: none;
			}
		}
	}
	.expiry-document {
		padding-top: 32px;
		span {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 18px;
			color: #00344e;
		}
	}
	.please-row {
		width: 100%;
	}
	.input-field-expiry-date {
		padding-top: 24px;
		.ant-form-item {
			margin-bottom: 0px;
		}
		.form-field__label {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 18px;
			line-height: 20px;
			color: #737780;
		}
	}
}

// company-branch-name
.company-branch-name-container {
	width: 100%;
	padding: 0px 135px 0px 135px;
	.branch-name-header {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: #00344e;
		}
	}
	.branch-name-sub-header {
		padding-top: 24px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: #484b52;
		}
	}
	.company-branch-form {
		width: 100%;
		padding-top: 32px;
		.ant-form-item {
			margin-bottom: 10px;
			.inputlabelrequired::after {
				content: ' *';
				color: red;
			}
		}
		.branch-name-text-field {
			.form-field__label {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				color: #737780;
			}
		}
	}
	.branch-name-button {
		padding-top: 32px;
	}
}

// company-authority
.company-authority-container {
	width: 100%;
	padding: 0px 135px 0px 135px;
	.authority-header {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: #00344e;
		}
	}
	.authority-sub-header {
		padding-top: 24px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: #484b52;
		}
	}
	.company-authority-form {
		width: 100%;
		padding-top: 32px;
		.ant-form-item {
			margin-bottom: 10px;
			.inputlabelrequired::after {
				content: ' *';
				color: red;
			}
		}
		.authority-text-field {
			.form-field__label {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				color: #737780;
			}
		}
	}
	.authority-button {
		padding-top: 32px;
	}
}

// company-establishment-id
.company-establishment-id-container {
	width: 100%;
	padding: 0px 135px 0px 135px;
	.establishment-id-header {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: #00344e;
		}
	}
	.company-establishment-id-form {
		width: 100%;
		padding-top: 32px;
		.ant-form-item {
			margin-bottom: 10px;
			.inputlabelrequired::after {
				content: ' *';
				color: red;
			}
		}
		.establishment-id-text-field {
			.form-field__label {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				color: #737780;
			}
		}
	}
	.establishment-id-button {
		padding-top: 32px;
	}
	.link-button {
		display: flex;
		justify-content: flex-start;
		button {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: #0e5274;
			text-decoration: underline;
			text-underline-position: under;
			padding: 0px 0px;
		}
	}
}

// comapny-industry
.company-industry-container {
	width: 100%;
	padding: 0px 135px 0px 135px;
	.company-industry-header {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: #00344e;
		}
	}
	.company-industry-form {
		width: 100%;
		padding-top: 32px;
		.ant-form-item {
			margin-bottom: 10px;
			.inputlabelrequired::after {
				content: ' *';
				color: red;
			}
		}
		.industry-text-field {
			.form-field__label {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				color: #737780;
			}
		}
	}
	.industry-button {
		padding-top: 32px;
	}
}

// trade_license_container
.trade_licenses_container {
	padding: 50px 135px 0px 135px;
	width: 100%;
	.trade_licenses_details_row {
		width: 100%;
		.trade_licenses_title {
			padding: 0px 0px 12px 0px;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				color: $primaryColor5;
			}
		}
		.trade_licenses_card_section {
			display: flex;
			flex-flow: wrap;
			.trade_license_card_column {
				padding: 4px 8px 8px 0px;
				.trade_license_card {
					background: $greyColor1;
					box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
					border: none;
					border-radius: 24px;
					min-height: 112px;
					padding: 12px;
					cursor: pointer;
					.ant-card-body {
						padding: 16px;
						display: flex;
						align-items: center;
						justify-content: center;
						.trade_license_content {
							display: inline-grid;
							span {
								padding: 2px 0px;
							}
							.trade_license_title {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 20px;
								line-height: 24px;
								color: $primaryColor5;
							}
							.trade_license_type_id {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 16px;
								line-height: 18px;
								color: $greyColor7;
							}
						}
						.trade_license_edit_icon {
							img {
								height: 30px;
								width: 30px;
							}
						}
					}
				}
			}
		}
	}
	.add_new_trade_license_button_section {
		padding: 28px 12px 4px 0px;
		button {
			height: 32px;
			width: 209px;
			min-width: 209px;
			min-height: 32px;
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor2;
			padding: 4px;
		}
	}
}

.company_mobile_presentational {
	display: none;
}
@media only screen and (max-width: 600px) {
	.company_information_web_presentational {
		display: none !important;
	}
	.company_mobile_presentational {
		display: flex !important;
	}
	.trade_licenses_container {
		padding: 0px 0px 0px 0px;
	}
}
