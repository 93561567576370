.group-invoice-wrapper {
    padding: 40px;
    .action-layer {
        display: flex;
        align-items: center;
    }
    .label {
        color: #737780;
        font-family: "Montserrat-Regular";
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        display: inline-block;
    }
    .value {
        color: #00344E;
        font-family: "Montserrat-Regular";
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        padding: 0 15px;
        display: inline-block;
    }
}
.payment-group-header {
    min-height: 96px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.group-invoice-content {
    padding: 32px;
    background-color: #ffffff;
}