:root {
	font-size: 10px;
}

.modal,
.linktab,
.mainmodal {
	justify-content: center;
	display: flex;
}

.modal {
	width: 100%;
	position: fixed;
	z-index: 10;
	padding-top: 100px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
}

.mainmodal {
	box-shadow: 0px 16px 32px -24px #bdbdbd;
	border-radius: 8px;
	align-self: center;
	width: 45.8rem;
	padding: 24px 24px 24px 24px;
	background-color: #ffffff;
}

.container {
	width: 42.6rem;

	.image-close {
		display: flex;
		justify-content: end;
	}

	.celebration {
		display: flex;
		justify-content: center;
		margin-bottom: 24px;
		margin-top: 13px;
	}

	.top-image {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}
}

.image-row {
	display: flex;
	margin-bottom: 8px;
	justify-content: end;

	.image-left {
		width: 100%;
		display: flex;

		.class-close {
			display: flex;
			justify-content: end;
		}

		.image-closer {
			display: flex;
			justify-content: end;
			width: 100%;
			display: flex;
			align-items: center;

			.class-close {
				height: 26px;
				cursor: pointer;
			}
		}
	}

	.image-container {
		width: 53%;
		display: flex;

		.class-close {
			display: flex;
			justify-content: end;
		}

		.image-closer {
			display: flex;
			justify-content: end;
			width: 100%;
			display: flex;
			align-items: center;

			.class-close {
				height: 26px;
				cursor: pointer;
			}
		}
	}
}

.modal-desc,
.modal-title {
	font-style: normal;
	letter-spacing: 0em;
	text-align: center;
}

.modal-title {
	font-family: Montserrat-bold;
	font-size: 18px;
	font-weight: 700;
	line-height: 20px;
	color: #00344e;
	margin: 0px;
}

.titlecenter {
	text-align: center;
}
.titleStart {
	text-align: start;
}

.modal-desc {
	font-family: Montserrat-light;
	font-size: 14px;
	font-weight: 300;
	line-height: 16px;
	margin-top: 8px;
	color: #737780;
}

.primary-button {
	width: -webkit-fill-available;
	margin-bottom: 32px;
}

.btn-space {
	width: 48%;
}

.one-button,
.two-button {
	margin-top: 32px;
}

.one-button {
	width: 100%;
}

.two-button {
	width: 50%;
}

.secondary-link {
	margin-bottom: 33px;
}

.secondary-modal {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	margin-bottom: 3.2rem;
}

.secondary-button,
.secondary-button-one {
	width: 18.9rem;
}

.secondary-button {
	height: 3.2rem;
	margin-top: 3.1rem;
}

.secondarybtn {
	width: 100%;
	margin-top: 16px;
}

.button-flex {
	display: flex;
	gap: 0.8rem;

	.loader {
		border: 2px solid #f7f8fa;
		border-top: 2px solid transparent;
		border-radius: 10px;
		width: 20px;
		height: 20px;
		animation: spin 1s linear infinite;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
}
.secondarybtn {
	.secondary_loader {
		border: 1px solid #dfe1e6;
		border-bottom-color: #ea6257;
		border-radius: 10px;
		width: 20px;
		height: 20px;
		animation: spin 1s linear infinite;
	}
}

.linktab {
	margin-top: 17px;
}

.link {
	color: #0e5274;
	text-decoration: underline;

	&:hover {
		color: #0e5274;
		text-decoration: underline;
	}
}

@media only screen and (max-width: 400px) {
	.mainmodal {
		width: 295px;
	}
}
