@import '../../../constants/_variables.scss';
.approve_employees_request_presentational {
	width: 100%;
	height: 100%;
	align-self: flex-start;
	.approve_requests_employee_container {
		display: flex;
		width: 100%;
		padding-left: 40px;
		padding-right: 40px;
		.approve_requests_employee_title {
			display: flex;
			flex-direction: row;
			width: 100%;
			justify-content: space-between;
			margin-top: 40px;

			.title-name-and-select-section-employee-style {
				.title-font-employee-color {
					color: #00344e;
				}
				.select-all-employee-section {
					margin-left: 17px;
					cursor: pointer;
					text-decoration-line: underline;
					color: #0e5274;
				}
			}
			.button-employee-section {
				display: flex;
				height: 100%;
				justify-content: center;
				flex-direction: row;
			}
		}
		.approve_requests_employee_card_section {
			padding: 32px;
			row-gap: 24px;
			display: flex;
			margin-top: 28px;
			flex-direction: column;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;
			background-color: #ffffff;
			margin-bottom: 40px;

			.approve_requests_employee_card_row {
				.approve_requests_employee_payroll_card {
					border: 1px solid #eff1f5;
					background: #ffffff;
					padding: 0px;
					border-radius: 8px;
				}
			}
		}
	}
}
.modal_approve_request_employee {
	width: 84%;
	background: $greyColor2;
	position: absolute;
	left: 245px;
	bottom: 0;
	display: flex;
	flex-direction: row;
	padding: 2% 1% 2% 8.7%;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	.modal_approve_request_select_deselect_all {
		img {
			cursor: pointer;
		}
		span {
			cursor: pointer;
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 18px;
			color: $primaryColor5;
		}
	}
	.Reject_button {
		button {
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 20px;
			text-align: center;
			color: $greyColor7;
		}
	}
}
.modal_card_approve_employee {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	.Modal_Edenred .Remove_user {
		padding: 8% 5% 4% 8%;
	}
	.Modal_Edenred {
		.user {
			font-family: 'Montserrat-Medium';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 20px;
			color: $primaryColor5;
		}
	}
	.Modal_Edenred .user_login_mobile {
		margin: 0%;
	}
	.Modal_Edenred {
		width: 30%;
		.Modal_Edenred .Remove_user {
			font-size: 18px;
			color: $primaryColor5;
			padding: 2% 10%;
		}
		.edenredTypo.bold {
			font-family: 'Montserrat-Medium';
		}
		.Button_container .Yes {
			color: $greyColor1;
			font-weight: bold;
			background: $secondaryColor3;
			display: flex;
			align-items: center;
			justify-content: center;
			border-bottom-right-radius: 16px;
			height: 7vh !important;
		}
		.Button_container .Go_Back {
			height: 7vh !important;
		}
	}
}
.modal_approve_employee_request_mobile_View {
	display: none;
}

// card reques detail
.card-request-detail-container {
	padding: 40px;
	width: 100%;
	height: 100%;
	display: flex;
	align-self: flex-start;
	.card-request-header-container {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: fit-content;
		justify-content: space-between;

		.request-page-title {
			color: '#00344E';
		}
		.card-request-button-container {
			display: flex;
			flex-direction: row;
			gap: 24px;
		}
	}

	.card-request-sub-header {
		display: flex;
		width: 100%;
		flex-direction: row;
		margin-top: 8px;
	}

	.card-info-board {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 32px;
		gap: 24px;
		width: 100%;
		margin-top: 24px;

		/* Colors/Neutral/neutral-white */

		background: #ffffff;
		/* Shadows/shadow-lg */

		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;

		.search-and-show-count-container {
			display: flex;
			flex-direction: row;
			width: 100%;
			justify-content: space-between;

			.edenRedSearch {
				width: 340px;
			}
			.entries-select-container {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				gap: 9px;
			}
		}

		.table-card-container {
			display: flex;
			width: 100%;
			flex-direction: column;
			padding: 0px !important;
			box-shadow: none;
			.edenred-table thead tr th {
				background-color: #ffffff;
			}
		}

		.emp-card-pagination-container {
			display: flex;
			flex-direction: row;
			width: 100%;
			align-items: center;
			justify-content: flex-end;
			.pagination-container {
				margin: 0px;
				padding-left: 16px;
			}
		}
	}

	///////////////////
	.card-request-detail-header {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.card-request-detail-subcontent {
		padding: 24px 0px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.card-request-detail-searchbutton {
		padding: 5px 0px;
		.card-request-detail-row {
			width: 100%;
			.download {
				display: flex;
				justify-content: flex-end;
				button {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					text-align: center;
					color: $greyColor7;
					height: 32px;
					min-height: 32px;
					padding: 0px 0px;
					width: 219px;
				}
			}
			.approve {
				display: flex;
				justify-content: flex-end;
				button {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					text-align: center;
					color: $greyColor2;
					height: 32px;
					min-height: 32px;
					padding: 0px 0px;
					width: 187px;
				}
			}
		}
	}
	.card-request-header-table {
		padding-top: 20px;
		.card-request-header {
			width: 100%;
			span {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 14px;
				color: $greyColor5;
			}
		}
	}
	.card-request-horizontal {
		margin: 8px 0px 12px 0px;
		background: $greyColor4;
		height: 0.1px;
	}
	.card-request-table-content {
		width: 100%;
		display: flex;
		align-items: center;
		.name {
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: $primaryColor5;
			}
		}
		.view {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				text-align: right;
				color: $primaryColor5;
			}
			img {
				width: 30px;
				height: 30px;
			}
		}
		.card-request-horizontal-view {
			margin: 15px 0px 15px 0px;
			background: $greyColor4;
			height: 0px;
			max-height: 0px;
		}
	}
	.card-request-mobile-view {
		display: none;
	}
}
@media only screen and (max-width: 600px) {
	.approve_employees_request_presentational {
		width: 100%;
		height: 100%;
		.approve-employee-request-container {
			padding: 65px 0px 185px 10px;
			.approve-employee-request-header {
				padding: 10px 0px;
				span {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 20px;
					line-height: 24px;
					color: $primaryColor5;
				}
			}
			.approve-employee-request {
				.approve-card {
					height: 141px;
					min-height: 141px;
					display: flex;
					align-items: center;
					margin: 10px 0px;
					width: 100%;
					box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.5);
					.ant-card-body {
						width: 100%;
					}
				}
			}
		}
	}
	.modal_approve_request_employee {
		display: none !important;
	}
	.modal_card_approve_employee {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: flex-end;
		.Modal_Edenred .Remove_user {
			padding: 5px 15px;
		}
		.Modal_Edenred {
			width: 100%;
			height: 212px;
			.user {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: $primaryColor5;
			}
		}
		.Modal_Edenred .user_login_mobile {
			padding: 0px 0px 12px 20px;
		}
		.Modal_Edenred {
			width: 100%;
			.user_login_mobile {
				padding: 0px 0px 0px 15px;
			}
			.Modal_Edenred .Remove_user {
				font-size: 18px;
				color: $primaryColor5;
				padding: 2% 10%;
			}
			.edenredTypo.bold {
				font-family: 'Montserrat-Medium';
			}
			.Button_container .Yes {
				color: $greyColor1;
				font-weight: bold;
				background: $secondaryColor3;
				display: flex;
				align-items: center;
				justify-content: center;
				border-bottom-right-radius: 16px;
				padding: 0px 15px 10px 15px;
			}
		}
	}
	.modal_approve_employee_request_mobile_View {
		display: flex !important;
		width: 100%;
		// z-index: 100;
		-webkit-transform: translate3d(0, 0, 0);
		box-shadow: 0px -10px 20px rgba(174, 182, 183, 0.25);
		bottom: 0;
		background: $greyColor1;
		position: absolute;
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		padding: 2% 0%;
		.payroll_run_header {
			padding: 2% 0% 0% 3%;
			span {
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: $greyColor6;
			}
		}
		.payroll_run_edenredtypo {
			padding: 0% 3%;
			span {
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 18px;
			}
		}
		.reject_button {
			display: flex;
			flex-direction: row;
		}
		.payroll_run {
			button {
				font-size: 14px;
				line-height: 16px;
				text-align: center;
				color: $primaryColor4;
				text-decoration: underline;
				text-underline-position: under;
			}
		}
	}

	// card reques detail
	.card-request-detail-container {
		padding: 65px 15px 60px 15px;
		display: flex;
		align-items: flex-start;
		overflow-y: auto scroll;

		.card-request-detail-header {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: $primaryColor5;
			}
		}
		.card-request-detail-subcontent {
			padding: 4px 0px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: $greyColor6;
			}
		}
		.card-request-detail-searchbutton {
			display: none !important;
		}
		.card-request-header-table {
			display: none !important;
		}
		.card-request-table-content {
			display: none !important;
		}
		.card-request-mobile-view {
			display: flex !important;
			button {
				height: 32px;
				min-height: 32px;
				font-family: Montserrat-Light;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				margin-right: 5px;
			}
			.card-request-mobile-reject {
				padding: 10px 12px 0px 0px;
			}
			.approverequestcard {
				margin: 5px 0px;
			}
		}
	}
}
