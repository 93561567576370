.step_container_mob {
	display: none;
}
.step_container {
	.dot_content_container {
		width: 20%;
		.all_dot_container {
			width: 100%;
			padding-right: 5px;
		}
		.date_content {
			padding: 10px 0;
			padding-left: 5px;
			.date {
				// font-family: Montserrat-Bold;
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 18px;
				color: #00344e;
			}
			.content {
				// font-size: 10px;
				width: 100%;
				padding-right: 80px;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					color: #484b52;
				}
			}
		}
		.dot_container {
			display: flex;
			justify-content: center;
			align-items: center;
			.dot {
				width: 13px;
				height: 13px;
				border-radius: 8px;
				background-color: #dfe1e6;
				&.processed {
					background-color: #78be20;
				}
			}
			.initial_dot {
				width: 36px;
				height: 36px;
				border-radius: 18px;
				background-color: #dfe1e6;
				display: flex;
				align-items: center;
				justify-content: center;
				&.processed {
					background-color: #78be20;
				}
				&.processing {
					background-color: #e4dd3d;
				}
				&.rejected {
					background-color: #e60a14;
				}
				img {
					width: 30px;
					height: 30px;
				}
			}
		}
	}
}

@media only screen and (max-width: 770px) {
	.step_container {
		display: none;
	}
	.step_container_mob {
		display: block;
		.dot_content_container {
			width: 100%;
			.date_content {
				padding: 10px 0;
				padding-top: 0;
				padding-left: 5px;
				.date {
					font-family: Montserrat-Bold;
				}
				.content {
					font-size: 10px;
					width: 100%;
				}
			}
			.tooltip {
				display: flex;
				justify-content: flex-end;
				.popover {
					width: 30px;
					height: 30px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 30px;
					background: #ffffff;
					box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
					img {
						width: 20px;
						height: 20px;
					}
				}
			}

			.dot_container {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				.dot {
					width: 10px;
					height: 10px;
					margin: 5px 0;
					border-radius: 8px;
					background-color: #dfe1e6;
					&.processed {
						background-color: #78be20;
					}
				}
				.initial_dot {
					width: 36px;
					height: 36px;
					border-radius: 18px;
					background-color: #dfe1e6;
					display: flex;
					align-items: center;
					justify-content: center;
					&.processed {
						background-color: #78be20;
					}
					&.processing {
						background-color: #e4dd3d;
					}
					&.rejected {
						background-color: #e60a14;
					}
					img {
						width: 30px;
						height: 30px;
					}
				}
			}
		}
	}
}

.flxr_spc {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.ant-popover-inner-content {
	font-family: Montserrat-Regular;
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 14px;
	color: #737780;
	width: 80%;
}
