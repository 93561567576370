@import './../../../constants/variables';

.invoice-presentational-container {
	width: 100%;
	padding: 0px 135px 0px 135px;
	.invoice-header {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: #00344e;
		}
	}
	.invoice-content {
		padding: 16px 0px 32px 0px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: #484b52;
		}
	}
	.invoices-card {
		.company-invoices-card {
			border-radius: 24px;
			margin: 12px 0px;
			padding: 24px 24px 24px 24px;
		}
	}
	.invoices-button {
		padding-top: 32px;
		button {
			width: 224px;
			height: 32px;
			min-height: 32px;
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			text-align: center;
			color: #f7f8fa;
			padding: 0px 0px;
		}
	}
}

@media only screen and (max-width: 600px) {
	.invoice-presentational-container {
		width: 100%;
		padding: 0px;
		.invoice-header {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: #00344e;
			}
		}
		.invoice-content {
			padding: 16px 0px 24px 0px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: #737780;
			}
		}
		.invoices-card {
			.company-invoices-card {
				border-radius: 24px;
				margin: 12px 0px;
				padding: 24px 24px 24px 24px;
			}
		}
		.invoices-button {
			width: 100%;
			padding-top: 12px;
			.view-our {
				display: flex;
				align-items: center;
				justify-content: center;

				button {
					width: 224px;
					height: 32px;
					min-height: 32px;
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					text-align: center;
					color: #f7f8fa;
					padding: 0px 0px;
				}
			}
		}
	}
}
