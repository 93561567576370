@import '../../../constants/_variables.scss';
.login_container {
	.left_area {
		height: 100vh;
		padding-left: 8%;
		.login_header {
			span {
				font-size: 28pt;
				font-weight: bold;
				line-height: 32pt;
				color: $primaryColor5;
			}
		}
		.logo {
			height: 28%;
			margin-top: 2%;
		}
		.form_label {
			padding-top: 7%;
			.ant-form {
				width: 105%;
			}
			button {
				font-family: 'Montserrat-Medium';
				font-size: 12pt;
				font-weight: bold;
			}
		}
		.forgot_password_help {
			.forgot_password {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				a {
					font-weight: 300;
					line-height: 16pt;
					text-decoration: underline;
					color: $primaryColor4;
					text-underline-position: under;
				}
			}
			.need_help {
				display: flex;
				justify-content: flex-end;
				align-items: flex-end;
				a {
					font-weight: 300;
					line-height: 16pt;
					text-decoration: underline;
					color: $primaryColor4;
					text-underline-position: under;
				}
			}
		}
	}
	.right_area_container {
		height: 100vh;
		.right_area {
			height: 100%;
			.we_brand,
			.are_new {
				background-color: $greyColor1;
				padding: 8% 10px 10px 10px;
				.we_brand_text,
				.are_new_text {
					text-align: right;
					span {
						font-size: 80px;
						font-weight: bolder;
						line-height: 86px;
						color: $secondaryColor1;
					}
				}
				.laptop_img {
					background: none;
					width: 140%;
					margin-left: 40%;
				}
			}
			.are_new {
				background-color: $secondaryColor1;
				position: inherit;
				.are_new_text {
					text-align: left;
					span {
						color: $greyColor1;
					}
				}
			}
		}
	}
	@media only screen and (max-width: 600px) {
		.left_area {
			padding: 3%;
			.login_header {
				span {
					font-size: 18pt;
					font-weight: 700;
					line-height: 20pt;
				}
			}
			.forgot_password_help {
				.forgot_password {
					a {
						font-size: 9pt;
					}
				}
				.need_help {
					a {
						font-size: 9pt;
					}
				}
			}
			.logo {
				padding-top: 5%;
				height: 10% !important;
			}
			.right_area_container {
				display: none;
			}
		}
	}
}
