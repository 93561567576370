@import '../../../constants/_variables.scss';

.excel_download {
	cursor: pointer;
	.ant-btn:focus > span,
	.ant-btn:active > span {
		position: initial !important;
	}

	button {
		z-index: 2;
		position: absolute;
		cursor: pointer;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
		visibility: hidden;
	}

	button:after {
		content: 'Download Employee List';
		visibility: visible;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 6px;
		right: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.card-request-payroll-detail-container {
	padding: 40px;
	width: 100%;
	height: fit-content;
	display: flex;
	align-self: flex-start;
	.card-request-payroll-header-container {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: fit-content;
		align-items: center;
		justify-content: space-between;

		.request-payroll-page-title {
			color: '#00344E';
		}
		.card-request-payroll-payroll-button-container {
			display: flex;
			flex-direction: row;
			gap: 8px;
			align-items: center;
			.payroll-amount-container {
				margin-right: 8px;
			}
			.cancle-button {
				width: 120px;
			}
			.save-button {
				width: 144px;
			}
		}
	}

	.payroll-card-info-board {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 32px;
		gap: 24px;
		width: 100%;
		margin-top: 24px;

		/* Colors/Neutral/neutral-white */

		background: #ffffff;
		/* Shadows/shadow-lg */

		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;

		.payroll-search-and-show-count-container {
			display: flex;
			flex-direction: row;
			width: 100%;
			justify-content: space-between;
			.gap-container {
				display: flex;
				flex-direction: row;
				gap: 16px;
				.edenRedSearch {
					width: 340px;
				}
			}

			.payroll-entries-select-container {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				gap: 9px;
				width: 165px;
			}
		}

		.payroll-table-card-container {
			display: flex;
			width: 100%;
			flex-direction: column;
			padding: 0px !important;
			box-shadow: none;
			.edenred-table thead tr th {
				background-color: #fbfcfd;
			}
			.edenred-table.td {
				padding: 0px 16px;
				height: 48px;
			}
		}

		.payroll-card-pagination-container {
			display: flex;
			flex-direction: row;
			width: 100%;
			align-items: center;
			justify-content: flex-end;
			.pagination-container {
				margin-bottom: 0px;
				padding-left: 16px;
			}
			// .pagination-container {
			// 	margin: 0px;
			// 	padding-left: 16px;
			// 	margin-bottom: 0px;
			// }
		}
	}
}
.approve_requests_container {
	display: flex;
	width: 100%;
	padding-left: 40px;
	padding-right: 40px;
	.approve_requests_title {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 40px;

		.Title-name-and-select-section-style {
			.title-font-color {
				color: #00344e;
			}
			.select-all-section {
				margin-left: 17px;
				cursor: pointer;
				color: #0e5274;
				text-decoration: underline;
			}
		}
		.button-section {
			display: flex;
			height: 100%;
			justify-content: center;
			flex-direction: row;
		}
	}
	.approve_requests_card_section {
		padding: 32px;
		row-gap: 24px;
		display: flex;
		margin-top: 28px;
		flex-direction: column;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		background-color: #ffffff;
		margin-bottom: 40px;

		.approve_requests_card_row {
			.approve_requests_payroll_card {
				border: 1px solid #eff1f5;
				background: #ffffff;
				padding: 0px;
				border-radius: 8px;
			}
		}
	}
}
.modal_approve_request {
	width: 82%;
	background: #f7f8fa;
	position: absolute;
	left: 250px;
	bottom: 0;
	display: flex;
	flex-direction: row;
	padding: 2% 1% 2% 9.3%;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	.modal_approve_request_select_deselect_all {
		img {
			cursor: pointer;
		}
		span {
			cursor: pointer;
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 18px;
			color: #00344e;
		}
	}
	.Reject_button {
		button {
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 20px;
			text-align: center;
			color: #484b52;
		}
	}
}
.modal_approve_request_mobile_View {
	display: none;
}
.modal_card_approve {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	.Modal_Edenred .Remove_user {
		padding: 5% 10% 0% 10%;
		font-family: 'Montserrat-medium';
		color: #00344e;
	}
	.Modal_Edenred .user_login_mobile {
		margin: 0%;
	}
	.Modal_Edenred {
		width: 30%;
		.Modal_Edenred .Remove_user {
			font-size: 18px;
			color: $primaryColor5;
			padding: 2% 10%;
		}
		.edenredTypo.bold {
			font-family: 'Montserrat-Medium';
		}
		.Button_container .Yes {
			color: #ffff;
			font-weight: bold;
			background: #d52b1e;
			display: flex;
			align-items: center;
			justify-content: center;
			border-bottom-right-radius: 16px;
		}
	}
}

// wps_table_container
.Wps_table_container {
	padding: 50px 135px 0px 135px;
	max-height: 90vh;
	.Wps_table_header {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: #00344e;
		}
	}
	.Wps_table_emplee {
		padding-top: 10px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: #484b52;
		}
	}
	.dowmload_button {
		button {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			text-align: center;
			color: #484b52;
			min-height: 32px;
			padding: 0px;
		}
	}
	.approve_payroll {
		button {
			min-height: 32px;
		}
	}
	.approve_request_horizontal {
		height: 0.1px;
		border: 0.1px solid #dfe1e6;
		background: #dfe1e6;
	}
}
.Wps_table_container_moblie_view {
	display: none;
}

@media only screen and (max-width: 600px) {
	.approve_requests_container {
		padding: 65px 1% 1% 2%;
		width: 100%;
		height: 0px;
		.approve_requests_title {
			padding: 0.5% 1% 1% 2%;
			span {
				font-size: 16pt;
				line-height: 18pt;
			}
		}
		.approve_requests_card_section {
			padding: 2% 0% 28% 0%;
			overflow-y: scroll;
			height: 71vh;
			.approve_requests_card_row {
				padding: 4px 5px;
			}
		}
	}
	.modal_approve_request {
		display: none !important;
	}
	.modal_approve_request_mobile_View {
		display: flex !important;
		width: 100%;
		z-index: 100;
		-webkit-transform: translate3d(0, 0, 0);
		box-shadow: 0px -10px 20px rgba(174, 182, 183, 0.25);
		bottom: 0;
		background: white;
		position: absolute;
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		padding: 2% 0%;
		.payroll_run_header {
			padding: 2% 0% 0% 3%;
			span {
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: #737780;
			}
		}
		.payroll_run_edenredtypo {
			padding: 0% 3%;
			span {
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 18px;
			}
		}
		.reject_button {
			display: flex;
			flex-direction: row;
		}
		.payroll_run {
			button {
				font-size: 14px;
				line-height: 16px;
				text-align: center;
				color: #0e5274;
				text-decoration: underline;
				text-underline-position: under;
			}
		}
	}
	.modal_card_approve {
		width: 100%;
		height: 100%;
		button {
			font-weight: bold;
		}

		.Modal_Edenred {
			width: 100%;
			position: absolute;
			bottom: 0;
			z-index: 10;
		}
		.Modal_Edenred .Remove_user {
			padding: 0% 5%;
			color: #00344e;
		}
		.Modal_Edenred .user_login_mobile {
			padding: 1% 0% 6% 5%;
			margin: 0%;
			color: #737780;
		}
	}
	// wps_table_container
	.Wps_table_container {
		display: none !important;
	}
	.Wps_table_container_moblie_view {
		display: flex !important;
		padding: 64px 2% 0% 4%;
		.approve_request {
			button {
				min-height: 32px;
				width: 152px;
				font-family: 'Montserrat-Light';
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				text-align: center;
				color: #f7f8fa;
			}
		}
		.card_table {
			overflow-y: scroll;
			height: 56.5vh;
			position: absolute;
			.edenredCard_main {
				background: #ffffff;
				border-radius: 16px;
				box-shadow: none;
				border: 2px solid #eff1f5;
				box-sizing: border-box;
				margin: 0%;
			}
		}
		.wps_table-july {
			span {
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: #737780;
			}
		}
	}
}
.Toastify__progress-bar--default {
	background: none;
}
