.un-employment-warning-banner {
	background-color: #fef5e8;
	padding: 12px 16px;
	border-radius: 8px;
	display: flex;
	gap: 16px;
	align-items: center;
	.info-content {
		width: 86%;
		line-height: 17px;
	}
	.info-action {
		width: 114px;
		background-color: #fbfcfd;
		border-radius: 8px;
		padding: 8px 16px;
		cursor: pointer;
	}
}
