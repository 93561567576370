@import './../../../../constants/variables';
.gauge_chart_container {
	height: 85px;
	max-height: 85px;
	text-align: center;
	// margin: 0px 18px;
	.gauge_chart {
		padding: 0px 2px;
	}
	.percentage_text {
		position: absolute;
		top: 25px;
		left: 0px;
		text-align: center;
		width: 100%;
		span {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			line-height: 14px;
			color: $primaryColor3;
			cursor: pointer;
		}
	}
	.label_text {
		position: absolute;
		top: 45px;
		left: 0px;
		text-align: center;
		width: 100%;
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 20px;
			color: $primaryColor5;
			cursor: pointer;
		}
		.total-text:hover {
			text-decoration: underline;
		}
	}
	.gauge_chart_title {
		position: absolute;
		top: 60px;
		left: 0px;
		text-align: center;
		width: 100%;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 10px;
			line-height: 12px;
			color: $primaryColor5;
			cursor: pointer;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-right: 4px;
		}
		.title-text:hover {
			text-decoration: underline;
		}
	}
	@media screen and (max-width: 850px) {
		.gauge_chart_title {
			span {
				font-size: 8px !important;
				line-height: 10px !important;
			}
		}
	}
}
.total_employees_text {
	font-family: 'Montserrat-light';
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	color: #181919;
	margin-bottom: 0px;
	margin-top: 7px;
	// padding-top: 8px;
	span {
		font-family: 'Montserrat-Bold';
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		color: #00344e;
	}
}
