.spanA {
	.forHere {
		font-family: 'Montserrat-Medium' !important;
		font-weight: 700;
		color: #00344e !important;
		padding-left: 5px;
	}
}
.topCont {
	.main-container {
		.dragger {
			width: 100% !important;
			.dragger-container {
				.imageuploadIndicator {
					img {
						width: 40px;
					}
				}
			}
		}
	}
	.newDragger {
		width: 100% !important;
		height: 238px;
	}
}
.hr-upload {
	align-self: flex-start !important;
	height: unset !important;
	width: 100%;
	// max-width: 960px;
	padding: 0px 40px;
	height: 100%;
	.forEmployees {
		margin-top: 0px !important;
		.hr-upload-inner {
			width: 100%;
			// padding: 0 40px;
			.warning-message-container {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 8px;
				gap: 8px;
				width: 100%;
				height: 56px;
				background: #fff6f5;
				border-radius: 8px;
				margin: 32px 0px;
				.errormessage-text-style {
					margin-left: 17px;
				}
			}
			.messageBox {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 8px;
				gap: 8px;

				width: 100%;
				height: 52px;

				background: #f7fbff;
				border-radius: 8px;
				margin-top: 32px;
			}
		}
	}
	.UploadSpreadsheet {
		padding: 0px 40px;
		.UploadSpreadsheet_header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 42px 0px 26px 0px;
			position: sticky;
			top: 0%;
			z-index: 1;
			background: #f7f8fa;
			.new_design_hr_upload {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;
				.new_hr_upload_cancel {
					background: #f7f8fa;
					font-family: 'Montserrat-Medium';
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #484b52;
				}
			}
		}

		.abc {
			display: flex;
			flex-direction: column;
			background: #ffffff;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;
			padding: 32px !important;
			.upload_spreadsheet_steps_container {
				display: flex;
				flex-direction: column;
				p {
					margin: 0px;
				}
				.upload_spreadsheet_steps_row {
					display: flex;
					margin-top: 16px;
					align-items: center;
					.upload_spreadsheet_steps_icon {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						padding: 10px;
						gap: 10px;

						width: 32px;
						height: 32px;

						/* Colors/Grayscale/grayscale-100 */

						background: #f9fafb;
						border-radius: 8px;
					}
					.upload_spreadsheet_steps_content {
						margin-left: 10px;
						// span {
						// 	font-family: 'Montserrat-Light' !important;
						// 	font-style: normal !important;
						// 	font-weight: 300 !important;
						// 	font-size: 14px !important;
						// 	line-height: 16px !important;
						// 	color: #484b52 !important;
						// 	a {
						// 		font-family: 'Montserrat-Light' !important;
						// 		font-size: 14px;
						// 		line-height: 16px;
						// 		color: #0e5274 !important;
						// 		text-decoration-line: underline;
						// 	}
						// }
					}
				}
			}

			.setup_container-view {
				display: flex;
				flex-direction: row;
			}

			.normal_container_view {
				display: flex;
				flex-direction: column;
			}

			///
			.upload_spreadsheet_steps_container_setup {
				display: flex;
				flex-direction: row;
				height: 132px;

				// background: #ffffff;
				// box-shadow: 0px 16px 32px -24px #bdbdbd;
				// border-radius: 8px;
				// padding: 32px !important;
				gap: 32px;
				p {
					margin: 0px;
				}
				.upload_spreadsheet_steps_row {
					display: flex;
					// margin-top: 16px;
					align-items: center;
					.upload_spreadsheet_steps_icon {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						padding: 10px;
						gap: 10px;

						width: 32px;
						height: 32px;

						/* Colors/Grayscale/grayscale-100 */

						background: #f9fafb;
						border-radius: 8px;
					}
					.upload_spreadsheet_steps_content {
						margin-left: 10px;
						span {
							font-family: 'Montserrat-Light' !important;
							font-style: normal !important;
							font-weight: 300 !important;
							font-size: 14px !important;
							line-height: 16px !important;
							color: #484b52 !important;
							a {
								font-family: 'Montserrat-Light' !important;
								font-size: 14px;
								line-height: 16px;
								color: #0e5274 !important;
								text-decoration-line: underline;
							}
						}
					}
				}

				.dragger-section-setup {
					display: flex;
					flex-direction: column;
					width: 50%;
					min-height: 132px;
					.main-container-dragger {
						height: 32px;
						.dragger {
							height: 132px;
						}
					}
				}
			}
		}
		////////
		.topCont {
			background: #ffffff;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;
			padding: 32px;
			margin: 25px 0px 40px 0px;
			.newDragger {
				height: 300px;
			}
		}
		.card_error {
			margin-top: 10px;
			.card_action {
				background: #fff6f5;
				border-radius: 8px;
				border: none;
				padding: 3px;
				img {
					width: 21px;
				}
				.tip_web-view {
					font-family: 'Montserrat-Light';
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: #00344e;
				}
			}
			.errorTable {
				border: none;
			}
		}
		.ant-upload-list-item-info {
			width: 100% !important;
		}
		.ant-upload-list-text {
			width: 100% !important;
		}
	}
}

.forClickHere {
	color: #00344e;
}

.hint-box {
	border: 1px solid #eedcba;
	border-radius: 8px;
	display: flex;
	align-items: center;
	margin: 10px 0 0 0;
	.hint-box-text {
		font-family: 'Montserrat-Regular';
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		margin-top: 15px;
		color: #484b52;
		padding-left: 16px;
	}
}

.dragger_card_container {
	.ant-upload-list-text-container,
	.ant-upload-list-picture-container {
		display: flex;
		justify-content: center;
		.ant-upload-list-item {
			width: 95%;
		}
	}
}
.close-button {
	margin-left: 1%;
	margin-top: 5%;
	padding: 8px 16px;
	border-radius: 28px;
	border: 1px solid #dfe1e6;
	background-color: #ffffff;
	color: #484b52;
	font-family: 'Montserrat-Regular';
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	cursor: pointer;
}
.heading-description {
	font-family: 'Montserrat-Light';
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	margin-top: 12px;
	color: #484b52;
}
.setup_typo_dragger {
	margin: 20px 0 0px 0;
	span {
		font-family: 'Montserrat-Bold';
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 28px;
		color: #00344e;
	}
}
