.divider_main_container {
	width: 100%;
}
.primary_divider {
	background: linear-gradient(270deg, rgba(223, 225, 230, 0) 0%, #dfe1e6 51.04%, rgba(223, 225, 230, 0) 100%);
	height: 1px;
}
.secondary_divider {
	border: 1px solid #eff1f5;
}
