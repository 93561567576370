.card_content_list {
	background-color: white;
	padding: 32px;
	border-radius: 8px;
	box-shadow: 0px 16px 32px -24px #bdbdbd;
	.UsefulFiles_Card {
		padding: 0px;
		cursor: pointer;
		.edenredCard_main {
			cursor: pointer;
			padding: 16px 20px;
		}
		.ant-card-bordered {
			border: none;
		}
		.payrollIcons {
			display: flex;
			width: 40px;
			height: 40px;
			align-items: center;
			justify-content: center;
			background-color: #f7f8fa;
			padding: 8px;
			margin-right: 16px;
			border-radius: 8px;
		}		
		.download_icon {
			img {
				width: 24px;
				height: 24px;
			}
		}
	}
}
