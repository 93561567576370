@import '../../../constants/_variables.scss';

$loaderSize: 40px;

.Modal_Edenred {
	background: #ffff;
	box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.5);
	border-radius: 16px;
	width: 15%;

	.delete_modal {
		display: flex;
		justify-content: center;
		padding: 7%;
	}
	.Remove_user {
		padding: 0% 10%;
	}
	.user_login {
		color: $greyColor7;
		padding: 0% 10% 5% 10%;
	}
	.user_login_mobile {
		display: none;
	}
	.horizontal_mobile {
		display: none;
	}
	.Button_container {
		line-height: 5vh;
		width: 100%;
		display: flex;
		align-items: flex-end;
		cursor: pointer;
		.Go_Back {
			color: $greyColor7;
			font-weight: bold;
			height: 5vh;
			background: $greyColor3;
			display: flex;
			align-items: center;
			justify-content: center;
			border-bottom-left-radius: 16px;
		}
		.Yes {
			color: #ffff;
			font-weight: bold;
			background: $secondaryColor3;
			display: flex;
			height: 5vh;
			align-items: center;
			justify-content: center;
			border-bottom-right-radius: 16px;
			a {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				color: $greyColor2;
			}
			&.singlebutton {
				border-bottom-left-radius: 16px;
			}
			.loader {
				border: 2px solid #f7f8fa;
				border-top: 2px solid transparent;
				border-radius: 20px;
				width: 20px;
				height: 20px;
				animation: spin 1s linear infinite;
				margin: 10px;
			}
		}
	}
	.button_mobile_role {
		display: none !important;
	}
}

@media only screen and (max-width: 600px) {
	.Modal_Edenred {
		background: #ffff;
		box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.5);
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		width: 100%;
		z-index: 2;
		.delete_modal {
			display: none !important;
		}
		.horizontal_mobile {
			display: flex !important;
			justify-content: center;
			align-items: center;
			margin: 4%;
		}
		.hori {
			width: 20%;
			border: 2px solid #dfe1e6;
			border-radius: 12px;
			background: #dfe1e6;
		}
		.user_login {
			display: none !important;
		}
		.user_login_mobile {
			display: flex !important;
			margin: 5%;
		}
		.Remove_user {
			padding-left: 5%;
		}
		.Button_container {
			display: none !important;
		}
		.button_mobile_role {
			display: flex !important;
			justify-content: space-evenly;
			width: 100%;
			// padding-bottom: 10%;
			padding: 0px 15px 10% 15px;
			.gobackButton {
				padding: 0px 8px;
				button {
					height: 48px;
					min-height: 48px;
				}
			}
			.yesButton {
				padding: 0px 8px;
				button {
					height: 48px;
					min-height: 48px;
				}
			}
		}
	}
}
.disablebutton {
	opacity: 0.5;
	box-shadow: 0px 5px 10px rgb(220 224 224 / 25%);
	cursor: not-allowed !important;
	pointer-events: none;
}
