@import '../../../constants/variables';

.transaction_history_presentational {
	width: 100%;
	// height: 80vh;
	align-self: flex-start;
	// margin-top: 40px;
	.transaction-history-container {
		padding: 0px 40px 0px 40px;
		.title_and_button_wrapper {
			padding: 40px 0px 28px 0px;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: row;
			position: sticky;
			top: 0;
			background-color: #f7f8fa;
			z-index: 2;

			h1 {
				font-family: 'Montserrat-Bold';
				font-size: 24px;
				color: $blue-500;
				margin: 0;
			}

			button {
				width: fit-content;
			}
		}
		.transaction-history-col {
			height: 100%;
			background-color: #ffff;
			padding: 32px;
			margin-bottom: 30px;
			.transaction-history-header {
				margin-bottom: 16px;
				span {
					font-family: Montserrat-Regular;
					font-weight: 300;
					font-size: 12px;
					color: $grayscale-600;
				}
				.aed-amount {
					display: flex;
					flex-direction: row;
					align-items: flex-end;
					.integer_part {
						font-family: Montserrat-Bold;
						font-style: normal;
						font-weight: bold;
						font-size: 24px;
						line-height: 28px;
						color: $primaryColor5;
					}
					.decimal_part {
						font-family: Montserrat-Bold;
						font-style: normal;
						font-weight: bold;
						font-size: 18px;
						line-height: 20px;
						color: $primaryColor5;
					}
					.aed {
						padding-left: 5px;
						font-family: Montserrat-Bold;
						font-style: normal;
						font-weight: bold;
						font-size: 18px;
						line-height: 20px;
						color: $primaryColor5;
					}
				}
			}
			.transaction-history-table-header {
				padding: 16px 0px 0px 16px;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					color: $grayscale-500;
				}
			}
			.transaction-history-table-content {
				.ant-divider {
					background: $grayscale-300 !important;
					border-color: #eff1f5;
					// background-color: $grayscale-300;
				}
				.month-header {
					padding-top: 17px;
					padding-left: 16px;
					span {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 12px;
						line-height: 16px;
						color: $blue-300;
					}
				}
				.content-detail {
					padding-left: 16px;
					.transaction_detail_row {
						.transaction_date {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 12px;
							color: #181919;
						}
						.transaction_detail {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 12px;
							color: #181919;
						}
						.transaction_amount {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							color: $primaryColor5;
							&.credit {
								color: $feedbox-info;
							}
							.integer_part {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 12px;
							}
							.decimal_part {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 12px;
							}
						}
					}
				}
				.ant-divider-horizontal {
					display: -ms-flexbox;
					display: flex;
					clear: both;
					width: 100%;
					min-width: 100%;
					margin: 12px 0;
					background: $greyColor4;
				}
			}
		}
	}
	.transaction-history-table-mobile {
		display: none !important;
	}
}

@media only screen and (max-width: 600px) {
	.transaction_history_presentational {
		width: 100%;
		height: 100%;
		.transaction-history-container {
			padding: 65px 15px;
			width: 100%;
			.transaction-history-table-header {
				display: none !important;
			}
			.ant-divider-horizontal {
				display: none !important;
			}
			.transaction-history-table-content {
				display: none !important;
			}
			.transaction-history-table-mobile {
				display: flex !important;
				.month-header {
					padding: 18px 0px 0px 0px;
					span {
						font-family: Montserrat-Bold;
						font-style: normal;
						font-weight: bold;
						font-size: 14px;
						line-height: 16px;
						color: $primaryColor3;
					}
				}
				.content-detail {
					.transaction_detail_section {
						.transaction_date_detail_column {
							display: inline-grid;
							padding-top: 8px;
							.transaction_detail {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 14px;
								line-height: 16px;
								color: #181919;
							}
							.transaction_date {
								font-family: Montserrat-Light;
								font-style: normal;
								font-weight: 300;
								font-size: 12px;
								line-height: 16px;
								color: #181919;
							}
						}
						.transaction_amount_column {
							display: flex;
							justify-content: flex-end;
							align-items: center;
							width: 100%;
							.transaction_amount {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 18px;
								line-height: 20px;
								color: $primaryColor5;
								&.credit {
									color: $greenColor3;
								}
								.integer_part {
									font-family: Montserrat-Medium;
									font-style: normal;
									font-weight: 500;
									font-size: 18px;
									line-height: 20px;
								}
								.decimal_part {
									font-family: Montserrat-Light;
									font-style: normal;
									font-weight: 300;
									font-size: 12px;
									line-height: 14px;
								}
							}
						}
					}
				}
			}
		}
	}
}
