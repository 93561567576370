@import '../../../constants/variables';

.invite_team_member {
	padding: 0% 135px 0% 135px;
	width: 100%;
	.invite_team_member_title {
		padding-bottom: 2.5%;
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: #00344e;
		}
	}
	.title_invite_team_member {
		padding-bottom: 2.5%;
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: #00344e;
		}
	}
	.invite_team_member_mobile_title {
		display: none;
	}
	.invite_team_member_types {
		padding: 0.75%;
		min-width: 85%;
		.invite_team_member_type_card {
			width: 100%;
			padding: 2%;
			background: $greyColor1;
			box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
			border-radius: 16px;
			&.ant-card-bordered {
				border: none;
			}
		}
	}
}

.choose_user_permission {
	padding: 0px 135px 0px 135px;

	.choose_header_content {
		padding: 0px 0px 12px 0px;
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.choose_user_content {
		display: flex;
		flex-direction: column;
		padding: 8px 0px 18px 0px;
		.choose_user {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: $primaryColor5;
			padding-bottom: 4px;
		}
		.choose_payroll {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
			color: $greyColor7;
			padding-top: 4px;
		}
	}
	.choose_align_horizontal_card {
		margin: 4px 0px 0px 0px;
		height: 0.5px;
		border: 1px solid $greyColor3;
		background: $greyColor3;
	}
	.choose_align_horizontal {
		margin: 4px 0px 0px 0px;
		height: 0.5px;
		border: 1px solid $greyColor3;
		background: $greyColor3;
	}
	.choose_content_list {
		padding: 12px 0px 12px 0px;
		.choose_content_list_description {
			padding-left: 8px;
		}
	}
	.choose_send_invite {
		padding: 16px 0px 8px 0px;
		button {
			width: 340px;
			min-width: 340px;
			min-height: 48px;
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 20px;
			color: $greyColor2;
		}
	}
	.choose_company {
		font-family: Montserrat-Regular;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		color: $greyColor7;
	}
	.choose_switch {
		height: 30px;
		width: 40%;
	}
}

.choose_verifier_permission {
	// padding: 6% 3% 0% 9%;
	padding: 0px 135px 0px 135px;
	.choose_verifier_title_mobile_view {
		font-family: Montserrat-Bold;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 28px;
		color: #00344e;
	}
	.action_type {
		font-size: 20px;
	}
	.choose_verifier_scroll_view {
		padding-top: 2%;
		.choose_verifier_main_content {
			width: 100%;
			padding: 1%;
			.choose_verifier_content_list {
				width: 100%;
				.choose_verifier_horizontal_line {
					width: 100%;
				}
				.choose_verifier_content_detail_toggle {
					padding-top: 2%;
					.choose_verifier_toggle_switch {
						display: flex;
						align-items: center;
					}
					.choose_verifier_header {
						span {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 16px;
							color: #00344e;
						}
					}
					.choose_verifier_detail {
						span {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 12px;
							line-height: 14px;
							color: #484b52;
						}
					}
				}
				.choose_verifier_switch {
					height: 30px;
					width: 40%;
				}
			}
		}
	}
	.choose_verifier_card_action_pannel {
		width: 100%;
		margin-top: 2%;
		// padding: 1% 1% 1% 1%;
		height: 10%;
		border: 1px solid $primaryYellow;
		border-radius: 8px;
		.choose_verifier_action_approve {
			font-size: 14px;
		}
	}
	.choose_verifier_send_invites {
		padding-top: 4%;
	}
}

@media only screen and (max-width: 600px) {
	.invite_team_member {
		padding: 65px 5% 2% 5%;
		.invite_team_member_title {
			padding-bottom: 5%;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: #00344e;
			}
		}
		.invite_team_member_mobile_title {
			display: flex !important;
			padding-bottom: 5%;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: #00344e;
			}
		}
		.invite_team_member_types {
			padding-bottom: 1.75%;
			min-width: 85%;

			.invite_team_member_type_card {
				margin-bottom: 0.75%;
				background: $greyColor1;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				border-radius: 16px;
				padding: 6% 4%;
				&.ant-card-bordered {
					border: none;
				}
			}
		}
	}

	.title_invite_team_member {
		display: none !important;
	}

	.choose_user_permission {
		padding: 65px 1% 1% 4px;
		.choose_header_content {
			padding: 2% 1% 1% 3%;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: $primaryColor5;
			}
		}
		.choose_user_content {
			padding: 3% 1% 1% 3%;
			.choose_user {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: $primaryColor5;
			}
			.choose_payroll {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: $greyColor7;
			}
		}
		.choose_user_card_content {
			padding: 3% 3% 2% 3%;
			.choose_align_horizontal_card {
				margin: 2% 0%;
			}
			.choose_content_list {
				padding: 2% 0% 1% 0%;
				.choose_company {
					font-size: 12px;
				}
			}
			.choose_align_horizontal {
				margin: 2% 0% 1% 0%;
			}
		}
		.choose_send_invite {
			display: flex;
			justify-content: center;
			padding: 3% 3% 2% 3%;
			button {
				width: 284px;
				min-width: 284px;
				min-height: 48px;
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 18px;
				padding: 15px 28px 15px 28px;
			}
		}
	}

	.choose_verifier_permission {
		padding: 4%;
		padding-top: 64px;
		.choose_verifier_title_mobile_view {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			line-height: 24px;
			color: #00344e;
		}
		.choose_verifier_card_action_pannel {
			display: none !important;
		}
		.choose_verifier_send_invites {
			padding-top: 5%;
		}
		.choose_verifier_scroll_view {
			overflow-y: scroll;
			padding-top: 3%;
			.choose_verifier_main_content {
				.choose_verifier_content_list {
					.choose_verifier_content_detail_toggle {
						padding: 3% 0%;
						.choose_verifier_header {
							span {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 14px;
								line-height: 16px;
								color: #00344e;
							}
						}
						.choose_verifier_detail {
							span {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 12px;
								line-height: 14px;
								color: #484b52;
							}
						}
					}
				}
			}
		}
	}
}
