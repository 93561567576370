.announcementContainer {
	width: 100%;
	align-self: flex-start;
	display: flex;
	justify-content: center;
	p {
		margin: 0px;
	}
	.heading {
		font-family: 'Montserrat-Bold';
		font-size: 24px;
		font-weight: 700;
		line-height: 28px;
		color: #00344e;
		margin: 0px;
	}
	.content {
		font-family: 'Montserrat-Regular';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		color: #484b52;
		margin-bottom: 20px;
	}
	.content {
		font-family: 'Montserrat-Regular';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		color: #484b52;
		margin-bottom: 20px;
	}
	.heading-history {
		font-family: 'Montserrat-Medium';
		font-size: 18px;
		font-weight: 700;
		line-height: 20px;
		color: #00344e;
	}
	.new_design_ann_header {
		padding: 40px 40px 24px 40px;
		position: sticky;
		top: 0;
		z-index: 1;
		background: #f7f8fa;
	}
	.new_design_announcement {
		padding: 0px 40px;
		.new_ann_message {
			background: #ffffff;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;
			padding: 32px;
			margin-bottom: 24px;
		}
	}
	.notification-title {
		font-family: 'Montserrat-Medium';
		font-size: 10px;
		font-weight: 500;
		line-height: 12px;
		color: #181919;
	}
	.new_design_input {
		.input-label-top {
			display: none;
		}
		.input-box {
			border-radius: 8px;
			overflow: hidden;
			height: 30px;
			padding: 6px 12px;
			margin-top: 8px;
			input {
				align-self: flex-start;
				&::placeholder {
					font-family: 'Montserrat-Medium';
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					color: #9a9ea6 !important;
				}
			}
		}
	}
	.message-area {
		.input-label-top {
			display: none;
		}
		.input-note {
			resize: none;
			border-radius: 8px;
			height: 72px;
			width: 100%;
			overflow: hidden;
			padding-top: 5px;
			padding-left: 10px;
			margin-top: 8px;
			input {
				align-self: flex-start;
				&::placeholder {
					font-family: 'Montserrat-Medium';
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					color: #9a9ea6 !important;
				}
			}
		}
		.for-characters {
			display: flex;
			justify-content: end;
			color: #ed1c24;
			font-size: 10px;
			font-family: 'Montserrat-Light';
			line-height: 0px;
			font-weight: 300;
			padding-top: 10px;
		}
		.for-characters-1 {
			display: flex;
			justify-content: end;
			color: #181919;
			font-size: 8px;
			line-height: 10px;
			font-family: 'Montserrat-Light';
			line-height: 0px;
			font-weight: 300;
			padding-top: 10px;
			margin: 0px;
		}
	}
	.for-characters {
		display: flex;
		justify-content: end;
		color: #ed1c24;
		font-size: 10px;
		font-family: 'Montserrat-Light';
		line-height: 12px;
		font-weight: 300;
		padding-top: 10px;
	}
	.for-characters-1 {
		display: flex;
		justify-content: end;
		color: #181919;
		font-size: 8px;
		font-family: 'Montserrat-Light';
		line-height: 10px;
		font-weight: 300;
		padding-top: 5px;
		margin-bottom: 16px;
	}
	.for-next-button {
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;
		.Send {
			font-family: 'Montserrat-Medium';
			font-size: 14px;
			font-weight: 500;
			line-height: 16px;
			color: #f7f8fa;
			width: 70px;
			&:disabled {
				background-color: #f7f8fa !important;
				color: #dfe1e6 !important;
			}
		}
	}
	.new_design_no_history {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 0px 32px 32px 32px;
		.no_announcement_record {
			border: 1px dashed #dfe1e6;
			border-radius: 8px;
			width: 100%;
			padding: 60px;
			.history-frame {
				display: flex;
				justify-content: center;
				.no-announcement {
					font-family: 'Montserrat-Medium';
					font-size: 16px;
					font-weight: 500;
					line-height: 18px;
					color: #484b52;
					margin-top: 18px;
					text-align: center;
				}
				.no-announcement-text {
					font-family: 'Montserrat-Light';
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: #737780;
					text-align: center;
					margin-top: 8px;
				}
			}
		}
	}
	.ant-divider-horizontal {
		margin: 5px 0px !important;
	}
	.new_design_history {
		padding: 0px 40px;
		margin-bottom: 40px;
		.new_design_ann_his {
			background: #ffffff;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;
			.new_his_header {
				position: sticky;
				top: 92px;
				z-index: 1;
				background: #ffffff;
				padding: 32px 32px 32px 32px;
			}
		}
	}
	.new_ann_his {
		padding: 0px 34px;
		overflow: auto;
		&::-webkit-scrollbar {
			width: 3px;
		}
		.announcement-history {
			padding: 0px 26px 5px 26px;
			.for-date {
				display: flex;
				justify-content: flex-end;
				font-family: 'Montserrat-Medium';
				font-size: 12px;
				font-weight: 500;
				line-height: 15px;
				color: #2b769c;
				margin-top: 12px;
			}
			.announcement-heading {
				font-family: 'Montserrat-Medium';
				font-size: 14px;
				line-height: 16px;
				font-weight: 500;
				color: #484b52;
				margin-top: 16px;
			}
			.announcement-text {
				font-family: 'Montserrat-Light';
				font-size: 12px;
				font-weight: 300;
				line-height: 14px;
				color: #484b52;
				margin-top: 8px;
				margin-bottom: 12px;
			}
		}
	}
	.request-leave-save-popup {
		width: 500px !important;
	}
	.next-Container {
		background-color: #ffffff;
		margin-top: 10%;
		min-height: 398px;
		width: 100%;
		border-radius: 20px;
		box-shadow: 0px -10px 10px rgba(174, 182, 183, 0.5);
		position: absolute;
		bottom: 0%;
		left: 0;
		padding: 8px;
		.Edit {
			font-family: 'Montserrat-Bold';
			font-size: 16px;
			font-weight: 700;
			line-height: 18px;
			color: #484b52;
		}
		.Send {
			font-family: 'Montserrat-Bold';
			font-size: 16px;
			font-weight: 700;
			line-height: 18px;
			color: #f7f8fa;
		}
		.title-row {
			display: flex;
			align-items: center;
		}

		.title-name {
			font-size: 16px;
			font-family: 'Montserrat-Medium';
			color: #737780;
		}
		.title-body {
			padding-left: 3px;
		}
	}
	.new_announcement_toast {
		transition: ease-in-out 5s;
		width: 100%;
		.toat-outer {
			.toast {
				padding: 16px 20px;
			}
		}
	}
	@keyframes fade {
		0% {
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
}
.new-announcement-save-popup {
	.mainmodal {
		.container {
			.image-row {
				margin-bottom: 15px;
			}
			.modal-title {
				padding-bottom: 15px;
			}
			.modal-desc {
				.title-row1 {
					word-break: break-all;
					padding-bottom: 10px;
					.title-name {
						font-family: 'Montserrat-Medium';
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 16px;
						color: #737780;
					}
				}
				.title-row {
					word-break: break-all;
					padding-bottom: 10px;
					.title-name {
						font-family: 'Montserrat-Medium';
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 16px;
						color: #737780;
					}
				}
			}
			.button-flex {
				justify-content: flex-end;
				.secondary-btn {
					width: 128px;
				}
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.announcementContainer {
		padding: 5%;
		display: flex;
		justify-content: flex-start;
		.page-padding {
			padding-bottom: 10vh;
		}
		.for-next-button {
			justify-content: center;
		}

		.heading {
			font-size: 20px;
			margin-top: 5%;
		}
		.heading-history {
			font-size: 20px;
		}
		.message-area {
			.input-note {
				min-height: 122px;
			}
		}
		.announcement-history {
			.for-date {
				font-size: 12px;
				font-weight: 300;
				line-height: 14px;
			}
			.announcement-heading {
				font-size: 14px;
				font-weight: 700;
				line-height: 16px;
			}
			.announcement-text {
				font-size: 12px;
				font-weight: 300;
				line-height: 14px;
			}
		}
	}
}
@media screen and (min-width: 600px) {
	.for-edenlogo {
		display: none;
	}
	.next-Container {
		display: none;
	}
}
