.payroll-run-dropdown-list {
	border-radius: 16px !important;
	.ant-select-item-option-selected::before {
		display: none !important;
	}
	.ant-select-item-option-selected::after {
		display: none !important;
	}
	.dropdown-settings {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		text-align: center !important;
		color: #00344e;
		.a {
			font-family: 'Montserrat-Medium';
			font-size: 14px;
			font-weight: 300 !important;
			color: #4991b6 !important;
			text-decoration: underline;
			margin-left: 5px;
		}
	}
	.rc-virtual-list-holder {
		max-height: 250px !important;
	}
	.ant-select-item-option {
		display: flex;
		align-items: center;
		padding: 18px 0 !important;
		margin-left: 24px !important;
		//	margin-right: 24px !important;
		&:last-child {
			border: none !important;
		}
	}
	.ant-select-item-option::after {
		display: none !important;
	}
	.ant-select-item-option-selected::before {
		display: none !important;
	}
	.ant-select-item-option-selected::after {
		display: none !important;
	}
	.inner-drop-validation {
		display: flex;
	}
	.img-component {
		padding-top: 10px;
	}
	span {
		font-family: Montserrat-Light;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 0.5px;
		display: flex;
		align-items: center;
		color: #484b52;
		padding-top: 10px;
	}
	.option-name1 {
		font-family: Montserrat-Regular !important;
		font-style: normal;
		font-weight: 300 !important;
		font-size: 16px !important;
		line-height: 14px;
		letter-spacing: 0.5px;
		margin-right: 5px;
	}
}
.date-validate-picker {
	font-family: Montserrat-Light;
	font-style: normal;
	font-weight: 300;
	font-size: 10px;
	line-height: 18px;
	color: #e60a14;
}

.payroll-run-addition-dropdown-list {
	border-radius: 16px !important;
	.dropdown-settings {
		font-family: 'Montserrat-Regular' !important;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		text-align: center !important;
		color: #00344e;
		.a {
			font-family: 'Montserrat-Medium' !important;
			font-size: 14px;
			font-weight: 300 !important;
			color: #4991b6 !important;
			text-decoration: underline;
			margin-left: 7px;
		}
	}
	.rc-virtual-list-holder {
		max-height: 400px !important;
	}
	.ant-select-item-option {
		display: flex;
		align-items: center;
		padding: 18px 0 !important;
		margin-left: 24px !important;
		margin-right: 24px !important;
		&:last-child {
			border: none !important;
		}
	}
	.ant-select-item-option::after {
		display: inline-block;
		width: 20px;
		height: 20px;
		margin-right: 5px;
		content: '';
		background: url('../../assets/icons/not_focus/EmptyBox.svg') no-repeat 0 0;
		background-size: 100%;
	}
	.ant-select-item-option-selected::before {
		display: none !important;
	}
	.ant-select-item-option-selected::after {
		display: inline-block;
		width: 20px;
		height: 20px;
		margin-right: 5px;
		content: '';
		background: url('../../assets/icons/focus_state/Check.svg') no-repeat 0 0;
		background-size: 100%;
	}
	.inner-drop-validation {
		display: flex;
	}
	.img-component {
		padding-top: 10px;
	}
	span {
		font-family: 'Montserrat-Light' !important;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 0.5px;
		display: flex;
		align-items: center;
		color: #484b52;
		padding-top: 10px;
	}
	.option-name {
		font-family: 'Montserrat-Light' !important;
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 14px;
		letter-spacing: 0.5px;
		margin-left: 10px;
	}
}

.payroll-run-dropdown-list1 {
	border-radius: 16px !important;
	.rc-virtual-list-holder {
		max-height: 380px !important;
		overflow-y: auto;
	}
	.rc-virtual-list-scrollbar {
		overflow: scroll;
	}
	.ant-select-item-option {
		display: flex;
		align-items: center;
		padding: 18px 0 !important;
		margin-left: 24px !important;
		margin-right: 24px !important;
		&:last-child {
			border: none !important;
		}
	}
	.ant-select-item-option::after {
		display: inline-block;
		width: 26px;
		height: 26px;
		margin-right: 5px;
		content: '';
		background: url('../../assets/icons/not_focus/EmptyBox.svg') no-repeat 0 0;
		background-size: 100%;
	}
	.ant-select-item-option-selected::before {
		display: none !important;
	}
	.ant-select-item-option-selected::after {
		display: inline-block;
		width: 24px;
		height: 24px;
		margin-right: 5px;
		content: '';
		background: url('../../assets/icons/focus_state/Check.svg') no-repeat 0 0;
		background-size: 100%;
	}
	.inner-drop-validation {
		display: flex;
	}
	.img-component {
		padding-top: 10px;
	}
	span {
		font-family: Montserrat-Light;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 0.5px;
		display: flex;
		align-items: center;
		color: #484b52;
		padding-top: 10px;
	}
	.option-name {
		font-family: Montserrat-Light;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 14px;
		letter-spacing: 0.5px;
	}
}
.payroll-run-dropdown-1 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 93%;
	.payroll-run-inner-dropdown-2 {
		display: flex;
		align-items: center;
		&::after {
			display: inline-block;
			width: 16px;
			height: 16px;
			margin-right: 5px;
			content: '';
			background: url('../../assets/icons/focus_state/SalaryUnpaid.svg');
			background-size: 100%;
		}
	}
	.payroll-run-inner-dropdown-3 {
		display: flex;
		align-items: center;
		&::after {
			display: inline-block;
			width: 16px;
			height: 16px;
			margin-right: 5px;
			content: '';
			background: url('../../assets/icons/focus_state/SalaryPaid.svg');
			background-size: 100%;
		}
	}
}
