@import '../../../constants/_variables.scss';

.floating-label {
	position: relative;
	.floating-input {
		font-size: 14px;
		padding: 4px 4px;
		display: block;
		width: 100%;
		height: 48px;
		box-sizing: border-box;
		background-color: transparent;
		border: none;
		border-radius: 8px;
		border: 2px solid $greyColor4;
		padding-left: 3%;
		&:focus {
			outline: none;
			border: 2px solid $primaryColor3;
			padding-top: 10px;
			padding-top: 10px;
		}
	}

	.floating-input-error {
		font-size: 14px;
		padding: 4px 4px;
		display: block;
		width: 100%;
		height: 48px;
		box-sizing: border-box;
		background-color: transparent;
		border: 2px solid #ab0c00;
		border-radius: 8px;
		padding-left: 3%;
		&:focus {
			outline: none;
			border: 2px solid #ab0c00;
			// padding-top: 10px;
			// padding-top: 10px;
		}
	}

	.form-field__label {
		font-family: Montserrat-Regular;
		font-style: normal;
		font-weight: 300;
		color: #737780;
	}
	label {
		color: $grey;
		font-size: 12pt;
		font-weight: 600;
		position: absolute;
		pointer-events: none;
		left: 3px;
		top: 13px;
		transition: 0.2s ease all;
		-moz-transition: 0.2s ease all;
		-webkit-transition: 0.2s ease all;
		margin-left: 3%;
		padding: 0 5px;
	}
	::placeholder {
		color: $greyColor4;
	}
	.floating-input:disabled {
		color: rgba(0, 0, 0, 0.25);
		cursor: not-allowed;
	}
	.floating-input:focus ~ label,
	.floating-input:not(:placeholder-shown) ~ label {
		top: -6px;
		left: -2px;
		font-size: 8pt;
		color: $greyColor5;
		background-color: white;
		// font-family: Montserrat-Regular;
		// font-style: normal;
		// font-weight: 300;
		// font-size: 10px;
		// line-height: 14px;
		// color: #737780;
	}

	*,
	*:before,
	*:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}

	.error-show {
		position: absolute;
		font-size: 10px;
		top: 39px;
		color: #ab0c00;
	}

	.dropdown_loader {
		position: absolute;
		bottom: 7px;
		right: 9px;
		border: 2px solid #dfe1e6;
		border-top: 2px solid transparent;
		border-radius: 15px;
		width: 16px;
		height: 16px;
		animation: spin 1s linear infinite;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
	.input_img {
		position: absolute;
		bottom: 7px;
		right: 0px;
		width: 30px;
		height: 30px;
		cursor: pointer;
	}
	.link_text {
		cursor: pointer;
		position: absolute;
		bottom: 5px;
		left: 85% !important;
		width: 30px;
		height: 30px;
		color: $primaryColor4;
		font-weight: bolder;
	}

	.ant-form-item-has-error {
		.floating-input {
			border: 2px solid #e60a14;
		}
	}

	.ant-form-item-has-error .ant-form-item-explain,
	.ant-form-item-has-error .ant-form-item-split {
		color: #e60a14;
	}

	/**
* Translate down and scale the label up to cover the placeholder,
* when following an input (with placeholder-shown support).
* Also make sure the label is only on one row, at max 2/3rds of the
* field—to make sure it scales properly and doesn't wrap.
*/

	.floating-input::placeholder {
		visibility: hidden;
	}

	:-ms-input-placeholder.floating-input {
		visibility: visible;
	}

	.floating-input:focus::placeholder {
		visibility: visible;
	}
	// input:placeholder-shown + label {
	// 	cursor: text;
	// 	max-width: 66.66%;
	// 	white-space: nowrap;
	// 	overflow: hidden;
	// 	text-overflow: ellipsis;
	// 	transform-origin: left bottom;
	// 	//transform: translate(0, 2.125rem) scale(1.5);
	// }
	// /**
	// * By default, the placeholder should be transparent. Also, it should
	// * inherit the transition.
	// */
	// ::-webkit-input-placeholder {
	// 	opacity: 0;
	// 	transition: inherit;
	// }
	// /**
	// * Show the placeholder when the input is focused.
	// */
	// input:focus::-webkit-input-placeholder {
	// 	opacity: 1;
	// }
	/**
* When the element is focused, remove the label transform.
* Also, do this when the placeholder is _not_ shown, i.e. when 
* there's something in the input at all.
*/
	// input:not(:placeholder-shown) + label,
	// input:focus + label {
	// 	transform: translate(0, 0) scale(1);
	// 	cursor: pointer;
	// }

	// .nk-text-wrapper {
	// 	display: flex;
	// 	flex-direction: row;
	// 	align-items: center;
	// }

	//  Chrome, Safari, Edge, Opera
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	//  Firefox
	input[type='number'] {
		-moz-appearance: textfield;
	}
}
