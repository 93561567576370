@import '../../../constants/_variables.scss';
.width-fit-content {
	width: fit-content;
}
.edenred_main_container {
	width: 100%;
	height: 100%;
	.run_payroll_container {
		padding: 0% 4% 0% 4%;
		.run_payroll_header {
			padding: 1% 6%;
		}
		.run_payroll_filterSearch {
			padding-left: 6%;
			padding-bottom: 0px;
			min-height: 4%;
			.user {
				.filter_button {
					.edenRedButton.outline-g {
						border: 2px solid $greyColor4;
						padding: 0px 30px;
						box-sizing: border-box;
						color: $primaryColor5;
						border-radius: 8px;
						width: 85%;
						min-height: 40px;
						min-width: 103px;
					}
				}
				.search {
					.edenRedSearch {
						height: 6vh;
					}
				}
			}
			img {
				height: 25px;
				width: 25px;
			}

			.show_card_mobile {
				display: none;
			}
		}
		.run_payroll_table {
			padding: 0% 11% 1% 6%;
			overflow: scroll !important;
			img {
				width: 100%;
			}
			.ant-table-container {
				.ant-table-header {
					padding: 0px;
				}
				.ant-table-body {
					padding: 0px;
				}
			}
		}
		.run_payroll_footer {
			width: 100%;
			height: 12vh;
			background: $greyColor2;
			border-top-left-radius: 13px;
			border-top-right-radius: 13px;
			display: flex;
			align-items: center;
			.run_payroll_footer_content {
				width: 100%;
				button {
					font-weight: bold;
				}
				.run_payroll_amount {
					padding: 0% 4%;
				}
				.runpayroll_upload_excel_button {
					a {
						button {
							width: 239px;
							height: 20px;
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 20px;
							color: $greyColor7;
						}
					}
				}
				.next-button {
					padding: 0% 1%;
				}
			}
		}
		.edenRedSwitch.ant-switch-checked {
			background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%);
		}
		.ant-switch {
			background-color: rgba(174, 182, 183, 0.25);
		}
		.show_card_web {
			position: absolute;
			z-index: 10;
			top: 54px;
			width: 100%;
			.run_payroll_dropdown_filter {
				box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.5);
				border-radius: 16px;
				.run_payroll_row {
					display: flex;
					justify-content: space-between;
					.run_payroll_row_dropdown {
						display: flex;
						flex-direction: row;
						justify-content: space-around;
						background: $greyColor1;
						align-items: center;
						width: 221px;
						height: 32px;
						color: $primaryColor5;
						font-size: 14px;
						line-height: 16px;
						border: 1px solid #dfe1e6;
						border-radius: 22px;
						padding: 0.5% 0%;
						z-index: 100;
					}
					.run_payroll_selected_dropdown {
						box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
						border: none;
						background: $greyColor1;
					}
				}
			}
			.dropdown_div_payroll_channel {
				background: #ffffff;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				border-radius: 24px;
				min-height: 20vh;
				margin-top: -4%;
				width: 20%;
				left: 1%;
				padding-top: 4%;
				padding-left: 2%;
				span {
					cursor: pointer;
					font-size: 10px;
					color: $primaryColor5;
				}
				.main_dropdown {
					padding-top: 3%;
					.dropdown_button_container {
						border: 1px solid #dfe1e6;
						box-sizing: border-box;
						border-radius: 22px;
					}
				}
			}
			.dropdown_div_usertype {
				background: #ffffff;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				border-radius: 24px;
				min-height: 20vh;
				margin-top: -4%;
				width: 20%;
				left: 27%;
				padding-top: 4%;
				padding-left: 2%;
				span {
					cursor: pointer;
					font-size: 10px;
					color: $primaryColor5;
				}
				.main_dropdown {
					padding-top: 3%;
					.dropdown_button_container {
						border: 1px solid #dfe1e6;
						box-sizing: border-box;
						border-radius: 22px;
					}
				}
			}
			.dropdown_div_establishment_id {
				background: #ffffff;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				border-radius: 24px;
				min-height: 20vh;
				margin-top: -4%;
				width: 25%;
				left: 53%;
				padding-top: 4%;
				padding-left: 2%;
				span {
					cursor: pointer;
					font-size: 10px;
					color: $primaryColor5;
				}
				.main_dropdown {
					padding-top: 3%;
					.dropdown_button_container {
						border: 1px solid #dfe1e6;
						box-sizing: border-box;
						border-radius: 22px;
						img {
							width: 20px;
							height: 20px;
						}
						span {
							font-family: Montserrat-Regular;
							font-style: 'normal';
							font-weight: 300;
							font-size: 14px;
							line-height: 16px;
							color: #00344e;
						}
					}
				}
			}
			.dropdown_div_bank_name {
				background: #ffffff;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				border-radius: 24px;
				min-height: 20vh;
				margin-top: -4%;
				width: 20%;
				left: 79%;
				padding-top: 4%;
				padding-left: 2%;
				span {
					cursor: pointer;
					font-size: 10px;
					color: $primaryColor5;
				}
				.main_dropdown {
					padding-top: 3%;
					.dropdown_button_container {
						border: 1px solid #dfe1e6;
						box-sizing: border-box;
						border-radius: 22px;
					}
				}
			}
		}
		.all_select {
			display: none;
		}
		.run_payroll_mobile_view {
			display: none;
		}
		img.column_icon {
			cursor: pointer;
			width: 30px;
			height: 30px;
		}
		img.header_icon {
			cursor: pointer;
			width: 26px;
			height: 22px;
			padding-left: 7%;
		}
		.floating-input {
			height: 30px;
			width: 80%;
		}
	}
	.filter_button_view {
		display: none;
	}
	// filter view
	.RunPayrollFilter_mobile_view {
		display: none;
	}
}
// UploadSpreadsheet
.upload-spreadsheet-col-container {
	width: 100%;
	align-self: flex-start;
	.UploadSpreadsheet {
		padding: 0px 40px 0px 40px;
		.UploadSpreadsheet_header {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 40px 0px 24px 0px;
			position: sticky;
			top: 0px;
			background: $grayscale-200;
			box-shadow: 0px 8px 24px -12px #f7f8fa;

			.title {
				font-size: 24px;
				line-height: 28px;
				font-family: 'Montserrat-Bold';
				font-style: normal;
				font-weight: 700;
				margin: 0;
				// color: yellow;
				// flex-grow: 1;
				// flex-basis: 1;
				flex: 1;
			}
		}
		.description-background-color {
			background: $grayscale-50;
		}
		.upload-description-background-color {
			background-color: rgba(255, 246, 245, 1);
		}

		.upload_spreadsheet_card {
			padding: 32px;
			background: $neutral-white;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;
			width: 100%;
			.container-wrapper {
				display: flex;
				gap: 16px;
				margin-bottom: 16px;
				.upload-wrapper {
					.upload-step-wrapper {
						display: flex;
						flex-direction: column;
						gap: 16px;
					}
					.upload_spreadsheet_steps_container {
						.upload_spreadsheet_steps_row {
							display: flex;
							align-items: center;
							margin-top: 8px;
							.upload_spreadsheet_steps_icon {
								display: flex;
								width: 32px;
								height: 32px;
								border-radius: 8px;
								justify-content: center;
								align-items: center;
								background: $grayscale-100;
								span {
									font-family: Montserrat-Medium;
									font-style: normal;
									font-weight: 500;
									font-size: 16px;
									line-height: 18px;
									color: $primaryColor3;
								}
							}
							.upload_spreadsheet_steps_content {
								display: flex;
								padding: 0px 0px 0px 16px;
								a {
									text-decoration: underline;
									text-underline-position: under;
									color: $blue-400;
									margin-left: 3px;
								}
							}
						}
					}
					.card_yellow {
						display: flex;
						justify-content: center;
						padding: 4px 0px;
						.card_action {
							width: 100%;
							min-height: 30px;
							border: 1px solid $primaryYellow;
							border-radius: 8px;
							.ant-card-body {
								padding: 4px;
							}
							img {
								width: 70%;
							}
							.tip_web-view {
								display: flex;
								align-items: center;
							}
							.tip_mobile-view {
								display: none;
							}
						}
					}
					.card_error {
						display: flex;
						justify-content: center;
						padding: 17px 0px;
						.card_action {
							background: #fff6f5;
							border-radius: 8px;
							border: none;
							padding: 3px;
							img {
								width: 20px;
							}
							.ant-card-body {
								padding: 4px;
							}
							.tip_web-view {
								display: flex;
								align-items: center;
							}
							.tip_mobile-view {
								display: none;
							}
						}
					}
					.dragger_card_container {
						padding-top: 17px;
						background-position: top;
						background-size: 10px 1px;
						background-repeat: repeat-x;
						.main-container {
							.dragger {
								width: 100%;
							}
						}
					}
				}
				.ant-upload-list-item-info {
					bottom: 46px;
					position: absolute;
					text-align: center;
					width: 90%;
				}
				.ant-upload-list-item-error .ant-upload-list-item-name {
					color: black;
				}
				.anticon svg {
					// display: none;
					color: black;
				}
				.whole {
					padding: 25px 0px;
				}

				.dragger {
					// width: 100%;
					.Upload_row_2 {
						display: none;
					}
				}
				.ant-upload-list-text {
					width: 95%;
					.ant-upload-text-icon {
						display: none;
					}
				}
				.upload-intro-video-wrapper {
					width: 48%;
					border-radius: 18px;
					box-shadow: 0px 19.2px 38.4px -28.8px #bdbdbd;
				}
			}
			.next_button {
				padding-top: 4%;
				padding-bottom: 20px;
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.check_runpayroll_container {
		padding-top: 64px;
		.check_runpayroll_title {
			padding: 8px 12px;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: $primaryColor5;
			}
		}
		.check_run_payroll {
			padding: 5px 13px;
			.edenredCard_main {
				border: none;
				padding: 8px;
			}
			.card_content_sectionpayroll_check {
				display: flex;
				align-items: center;
			}
		}
		.check_timings_link {
			padding: 8px 0px;
			text-align: center;
			a {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				text-decoration: underline;
				text-underline-position: under;
				color: $primaryColor4;
			}
		}
	}

	.edenred_main_container {
		height: initial;
		.run_payroll_container {
			padding: 0% 0%;
			height: 100%;
			.run_payroll_header {
				color: #00344e;
			}
			.run_payroll_filterSearch {
				.user {
					.show_card_mobile {
						display: flex !important;
						.edenRedButton.outline-g {
							border: 2px solid #dfe1e6;
							padding: 1px 5px;
							font-size: 16px;
							box-sizing: border-box;
							color: #00344e;
							border-radius: 8px;
							height: 5vh;
						}
					}
					.search {
						padding-left: 2%;
						.edenRedSearch {
							height: 5vh;
						}
					}
					.filter_button {
						display: none !important;
					}
				}
			}
			.run_payroll_table {
				display: none !important;
			}
			.run_payroll_footer {
				display: none !important;
			}
			.all_select {
				display: flex !important;
				padding-left: 6%;
				padding-top: 3%;
				font-size: 10px;
			}
			.run_payroll_mobile_view {
				display: flex !important;
				width: 100%;
				padding: 0px 15px;
				overflow-y: scroll;
				height: 45vh;

				.card_list_padding {
					margin: 15px 0px;
				}
			}
			.show_card_web {
				display: none !important;
			}
		}

		.modal_card_filter {
			display: flex;
			justify-content: center;
			align-items: flex-end;
			height: 100%;
			width: 100%;
			.Modal_Edenred .hori {
				display: none;
			}
		}
	}
	// filter page
	.RunPayrollFilter_mobile_view {
		display: flex !important;
		padding: 65px 10px 65px 10px;
		background: white;
		.Filter {
			font-size: 20px;
		}
		.scroll-filter {
			overflow-y: scroll;
			height: 73vh;
			.wps_nonwps {
				padding: 1% 1%;
				button {
					padding: 0% 15px;
					min-height: 32px;
					display: flex;
					justify-content: space-evenly;
					font-size: 14px;
					img {
						width: 25px;
						height: 25px;
					}
				}
			}
		}
		.appy_buttom {
			padding-left: 27%;
			padding-top: 2%;
			padding-bottom: 2%;
			button {
				width: 55%;
				font-size: 16px;
				padding: 0px;
				font-weight: bold;
				border: none;
			}
			.appy_button_apply {
				button {
					width: 55%;
					font-size: 16px;
					padding: 0px;
					font-weight: bold;
					background: #f7f8fa;
					color: #dfe1e6;
					border: none;
				}
			}
		}
	}
	.filter_button_view {
		display: flex !important;
		position: absolute;
		bottom: 0;
		.modal_filter_card {
			width: 100%;
			background: #ffffff;
			box-shadow: 0px -10px 20px rgba(174, 182, 183, 0.25);
			border-top-left-radius: 16px;
			border-top-right-radius: 16px;
			padding: 5%;
			height: 175px;
			z-index: 10;
			.filter_card__amount {
				display: flex;
				flex-direction: column;
			}
			.filter_card__button {
				display: flex;
				justify-content: end;
				button {
					padding: 2%;
				}
			}
		}
		.Deselect {
			text-decoration: underline;
			text-underline-position: under;
			text-align: center;
			color: #0e5274;
			padding-top: 3%;
		}
	}
	.leave-error-text {
		color: #e60a14 !important;
		font-family: Montserrat-Regular;
		font-style: normal !important;
		font-weight: 300 !important;
		font-size: 10px !important;
		list-style: 12px !important;
		position: absolute !important;
		bottom: 0 !important;
		background-color: red;
	}
	// upload spearsheet
	.upload-spreadsheet-col-container {
		width: 100%;
		align-self: flex-start;
		.UploadSpreadsheet {
			padding: 0px 40px 0px 40px;
			.UploadSpreadsheet_header {
				span {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 20px;
					line-height: 24px;
					color: #00344e;
				}
			}

			.upload_spreadsheet_steps_container {
				padding: 8px 0px;
				.upload_spreadsheet_steps_row {
					padding: 0px 2px 4px 2px;
					.upload_spreadsheet_steps_icon {
						width: 32px;
						height: 32px;
						background: $grayscale-100;
						border-radius: 8px;
					}
					.upload_spreadsheet_steps_content {
						padding: 0px 0px 0px 6px;
						span {
							a {
								color: $blue-400;
								text-transform: lowercase;
							}
						}
					}
				}
			}

			.card_yellow {
				display: flex;
				justify-content: center;
				padding: 4px 0px;
				.card_action {
					width: 95%;
					min-height: 30px;
					border: 1px solid #e5e076;
					border-radius: 8px;
					.ant-card-body {
						padding: 4px;
					}
					img {
						width: 60%;
					}
					.tip_web-view {
						display: none !important;
					}
					.tip_mobile-view {
						display: flex !important;
						font-size: 12px;
						display: flex;
						align-items: center;
					}
				}
			}
			.card_error {
				display: flex;
				justify-content: center;
				padding: 17px 0px;
				.card_action {
					background: #fff6f5;
					border-radius: 8px;
					border: none;
					padding: 3px;
					img {
						width: 20px;
					}
					.ant-card-body {
						padding: 4px;
					}
					.tip_web-view {
						display: none !important;
					}
					.tip_mobile-view {
						display: flex !important;
						font-size: 12px;
						display: flex;
						align-items: center;
					}
				}
			}
			.next_button {
				padding-top: 4%;
			}
			.dragger_card_container {
				width: 100%;
				display: flex;
				justify-content: center;
				padding: 5px 0px 5px 0px;
				.ant-upload-list-item-info {
					bottom: 46px;
					width: 100%;
				}
				.dragger {
					background: #ffffff;
					box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
					border-radius: 16px;
					width: 180px;

					.Upload_row_1 {
						display: none;
					}
					.Upload_row_2 {
						display: flex !important;
						width: 100%;
						img {
							width: 100px;
							height: 100px;
						}
					}
				}
			}
		}
	}
}

.ant-upload.ant-upload-drag {
	text-align: center;
	border: 2px dashed #d9d9d9;
	background: white;
	border-radius: 40px;
}

@media only screen and (max-width: 600px) {
	.ant-upload.ant-upload-drag {
		text-align: center;
		border: 0px dashed #d9d9d9;
		background: white;
		border-radius: 40px;
		box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
		border-radius: 16px;
	}
}

.runpayroll {
	height: 30px;
	border: 1px solid #eff1f5;
	box-sizing: border-box;
	border-radius: 8px;
	font-family: Montserrat-Regular;
	font-style: normal;
	font-weight: 300;
	font-size: 12px !important ;
	line-height: 14px;
	color: #484b52;
	.ant-input {
		font-size: 12px !important ;
	}
}

.backactive {
	color: #f7f8fa;
}

.checkNo {
	font-family: Montserrat-Regular;
	font-size: 14px;
	font-weight: 300;
	font-style: normal;
	color: #9a9ea6;
}

.checkNo-false {
	font-family: Montserrat-Regular;
	font-size: 14px;
	font-style: normal;
	color: #9a9ea6;
	font-weight: 300;
}

.tabName {
	font-family: 'Montserrat-Light';
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 14px;
}
.empl_name {
	text-decoration-line: underline;
	color: $blue-300;
}
.emp_id {
	color: $grayscale-900;
}
.payroll_amount_input {
	.input-box {
		height: 22px;
		border-radius: 4px;
	}
	.error-message-text {
		color: #f75448;
	}
	.table-salary-input-error {
		border-color: #f75448;
	}
}

.prehighlight-bold {
	width: 65%;
}
.prehighlight {
	.ant-input-prefix {
		font-family: Montserrat-Regular;
		font-style: normal;
		font-weight: 300;
		font-size: 12px;
		line-height: 14px;
		color: #00344e;
	}
}
