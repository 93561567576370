.document-expire,
.save-model {
	.popup_heading_text {
		padding-top: 0 !important;
	}
}
.modal_container {
	.ant-modal-content {
		border-radius: 20px;
	}
	.main_popup_container {
		height: 100%;
		width: 100%;
		border-radius: 20px;
		overflow: hidden;
		.message_container {
			width: 100%;
			height: 75%;
			padding: 0px 30px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.popup_heading_text {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 16px !important;
				line-height: 18px;
				color: #00344e;
				padding-top: 30px;
			}
			.popup_message_text {
				padding-top: 10px;
				font-family: Montserrat-Regular;
				font-size: 15px;
				font-style: normal;
				font-weight: 300;
				line-height: 18px;
				letter-spacing: 0em;
				text-align: left;
			}
		}
		.routing_container {
			width: 100%;
			height: 25%;
			background-color: #d52b1e;
			display: flex;
			justify-content: center;
			align-items: center;
			.back_router_text {
				font-family: Montserrat-Medium;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px;
				letter-spacing: 0em;
				text-align: center;
				color: #f7f8fa;
				vertical-align: middle;
			}
		}
	}
}
@media (min-width: 200px) and (max-width: 360px) {
	.modal_container .main_popup_container .message_container .popup_heading_text {
		padding: 30px 0px;
		font-size: 13px !important;
	}
}
