@import '../../../constants/variables';

.UsefulFiles_container {
	padding: 0px 135px 0px 135px;
	width: 100%;
	.UsefulFiles_header {
		padding-bottom: 20px;
		padding-left: 2px;
		span {
			color: $primaryColor5;
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
		}
	}
	.card_content_list {
		.UsefulFiles_Card {
			padding: 8px 0px 0px 0px;
			.usefull_file_card_container {
				padding: 24px 20px !important;
			}
		}
	}
}
.disable_download_card {
	cursor: not-allowed;
	.edenredCard_main {
		background-color: #dee1e561;
		pointer-events: none;
	}
}

@media only screen and (max-width: 600px) {
	.UsefulFiles_container {
		padding: 0% 3%;
		padding-top: 65px;
		.UsefulFiles_header {
			padding-bottom: 12px;
			span {
				font-size: 20px;
				color: $primaryColor5;
			}
		}
		.card_content_list {
			overflow-y: scroll;
			height: 71vh;
			.UsefulFiles_Card {
				padding: 4px 0px;
				.edenredCard_main {
					// padding: 2px 5px 3px 5px;
					padding: 0px;
				}
			}
		}
	}
}
