@import '../../../constants/variables';

.upload-details-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	.content-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 0px 40px;
		@media screen and (min-width: 1400px) {
			padding: 0 240px;
		}
		.title-wrapper {
			display: flex;
			flex-direction: column;
			.title-container {
				width: 100%;
				height: 96px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.title {
					color: $darkblue;
					font-size: 26px;
					font-family: $font-family-medium;
					font-weight: $font-weight-700;
					line-height: 28px;
					margin-bottom: 0;
					margin-top: 0;
				}
				.save-btn {
					display: flex;
					gap: 8px;
					.buttonnew {
						padding-right: 10px;
					}
				}
			}
			.stepper-wrapper {
				width: 100%;
				height: 30px;
				margin-bottom: 24px;
			}
		}
		.upload-wrapper {
			max-height: calc(100vh - 222px);
			overflow-y: auto;
			.upload-content-container {
				width: 100%;
				background: $neutral-white;
				border-radius: 8px;
				box-shadow: 0px 16px 32px -24px $gray;
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-bottom: 25px;
				.upload-info-wrapper {
					width: 93%;
					height: 48px;
					background: $secondary-teal-25;
					border-radius: 8px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 32px;
					padding-left: 16px;
					padding-right: 16px;
					.info-content {
						display: flex;
						align-items: center;
						.upload-info {
							color: $darkblue;
							font-size: 14px;
							font-family: $font-family-medium;
							font-weight: $font-weight-500;
							line-height: 16px;
							margin: 0px;
							margin-left: 16px;
						}
					}
					.edit-btn {
						border-radius: 8px;
						background: $grayscale-50;
						border: none;
					}
				}
				.sub-info-wrapper {
					width: 93%;
					height: 48px;
					background: rgba(206, 232, 255, 0.25);
					border-radius: 8px;
					display: flex;
					align-items: center;
					margin-top: 8px;
					padding-left: 16px;
					.info-content {
						display: flex;
						align-items: center;
						.info-icon {
							width: 35px;
							height: 35px;
							margin-left: 18px;
						}
						.description {
							color: $darkblue;
							font-size: 14px;
							font-family: $font-family-medium;
							font-weight: $font-weight-500;
							line-height: 16px;
							margin: 0px;
							margin-left: 16px;
						}
					}
				}

				.upload-type-wrapper {
					width: 93%;
					display: flex;
					margin-top: 32px;
					margin-bottom: 32px;
					justify-content: space-between;
					.upload-type {
						width: 49%;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						.option {
							color: $blue-500;
							font-size: 12px;
							font-family: $font-family-medium;
							font-weight: $font-weight-500;
							line-height: 14px;
							margin: 0px;
							text-align: center;
							margin-bottom: 8px;
						}
						.section-type {
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							background: $grayscale-50;
							border-radius: 8px;

							.excel-icon {
								margin-top: 16px;
							}
							.box-icon {
								width: 98px;
								height: 56px;
								box-shadow: 0px 0px 30px -24px;
								margin-top: 16px;
								border-radius: 8px;
							}
							.type-title {
								color: $blue-500;
								font-size: 16px;
								font-family: $font-family-medium;
								font-weight: $font-weight-500;
								line-height: 18px;
								margin-top: 16px;
								margin-bottom: 0px;
							}
							.type-description {
								text-align: center;
								margin-top: 8px;
								margin-bottom: 0px;
								font-size: 12px;
								font-family: $font-family-light;
								font-weight: $font-weight-300;
								line-height: 14px;
							}
							.upload-btn {
								margin-top: 16px;
								margin-bottom: 16px;
							}
						}
					}
				}
				.dragger-type-wrapper {
					width: 93%;
					display: flex;
					gap: 16px;
					flex-direction: column;
					margin-top: 32px;
					margin-bottom: 32px;
					.edit-data {
						.edit-data-title {
							color: $blue-500;
							font-size: 12px;
							font-family: 'Montserrat-Medium';
							font-weight: 500;
							line-height: 14px;
						}
						.allowanceData {
							width: 100%;
							word-wrap: break-word;
							color: $blue-500;
							font-size: 12px;
							font-family: 'Montserrat-Medium';
							font-weight: 500;
							line-height: 14px;
						}
					}
					.description-background-color {
						background: $grayscale-50;
					}
					.description-color {
						background: #ffbecb;
					}
					.dragger-type {
						width: 100%;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						.step {
							color: $blue-500;
							font-size: 12px;
							font-family: $font-family-medium;
							font-weight: $font-weight-500;
							line-height: 14px;
							margin: 0px;
							margin-bottom: 8px;
						}
						.dragger-wrapper {
							width: 100%;
							background: $grayscale-50;
							border-radius: 8px;
							justify-content: space-around;
							align-items: center;
							.main-container-dragger {
								height: 132px;
								.dragger {
									height: 132px;
								}
							}
						}
					}
				}
			}
		}
	}
}
