@import '../../../constants/_variables.scss';
.video_tutorial_container {
	width: 100%;
	align-self: flex-start;
	// min-height: calc(100vh - 64px);
	// max-height: calc(100vh - 64px);
	padding: 0px 40px 0px 40px;
	.modal_wrapper {
		.ant-modal .video_modal_container {
			width: 80vw;
			.vimeo_container {
				iframe {
					height: 100%;
					width: 100%;
				}
			}
		}
	}

	.header_container {
		// height: 12vh;
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		background-color: #f7f8fa;
		position: sticky;
		z-index: 2;
		top: 0;
		padding-top: 40px;
		.header_text {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
			margin-bottom: 3vh;
		}
	}
	.content_container {
		background-color: #ffffff;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 32px;
	}
	.video_segment_container {
		width: 100%;
		// height: 40vh;
		display: flex;
		flex-direction: column;
		// margin: 0px 0px 40px 0px;

		.video_header {
			width: 100%;
			height: 6.5vh;
			display: flex;
			border-bottom: 1px solid #dfe1e6;
			padding-bottom: 16px;
			.video_title {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: 700;
				font-size: 18px;
				line-height: 20px;
				color: #00344e;
				display: flex;
				padding-top: 10px;
			}
			.paginator_container {
				width: 100%;
				height: 100%;

				.view_all_container {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: flex-end;
					.navigation_holder {
						width: 100%;
						height: 100%;
						justify-content: flex-end;
						align-items: center;
						.paginate_div {
							height: 100%;
							display: flex;
							align-items: center;
							// padding-top: 5px;
						}
						.navigateImage {
							height: 19.02px;
							width: 7.31px;
							// margin-right: 25px;
							cursor: pointer;
						}
					}

					.video_viewall_button {
						// min-width: 6vw;
						// height: 4.5vh;
						// border-radius: 22px;
						// border: 1px solid #626666;
						// background-color: white;
						margin-bottom: -1px;
						text-decoration: underline;

						span {
							// font-family: Montserrat;
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 16px;
							color: #0e5274;
						}
					}
				}
			}
		}
		.video-title-parent {
			display: flex;
			align-items: center;
		}
		.folder_not_found_text {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 18px;
			color: #00344e;
			display: flex;
			padding-top: 10px;
		}

		.video_container {
			height: 200px;
			width: 100%;
			display: flex;
			overflow: visible;
			// justify-content: space-between;
			margin-top: 30px;

			.video_Card_Wrapper {
				height: inherit;
				// width: 250px;
				width: 100%;
				display: flex;
				flex-direction: column;
				cursor: pointer;
				padding-right: 15px;
				.video_holder {
					height: 139px;
					width: 100%;

					.img_style {
						// width: 100%;
						object-fit: contain;
						height: 139px;
						min-width: 100%;
						padding: 0px;
					}
				}

				.video_title_holder {
					height: calc(200px - 139px);
					width: 100%;
					// background: yellow;
					// padding-left: 4px;

					span {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 16px;
						color: #313338;
						padding-top: 5px;
					}
				}
			}

			.video_Card_Wrapper_without_padding {
				height: inherit;
				// width: 250px;
				width: 100%;
				display: flex;
				flex-direction: column;
				cursor: pointer;
				.video_holder {
					height: 139px;
					width: 100%;

					.img_style {
						// width: 100%;
						object-fit: contain;
						height: 139px;
						min-width: 100%;
						padding: 0px;
					}
				}

				.video_title_holder {
					height: calc(200px - 139px);
					width: 100%;
					// background: yellow;
					// padding-left: 4px;

					span {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 16px;
						color: #00344e;
						padding-top: 5px;
					}
				}
			}
		}
	}

	.no_folder_message_container {
		height: 40vh;
		justify-content: center;
		display: flex;
		align-items: center;
	}
	// @media (max-height: 649px) {
	// 	.video_segment_container {
	// 		height: 40vh !important;
	// 	}
	// }
}
.video_title_holder span:hover {
	text-decoration: underline;
}

@media (min-width: 300px) and (max-width: 1200px) {
	.video_segment_container {
		height: 80vh !important;
		.video_container .video_Card_Wrapper {
			margin-bottom: 15px;
		}
		.video_container.video_Card_Wrapper_without_padding {
			margin-bottom: 15px;
		}
	}
	// .video_segment_container:last-child {
	// 	height: 40vh !important;
	// 	.video_container .video_Card_Wrapper {
	// 		margin-bottom: 15px;
	// 	}
	// 	.video_container.video_Card_Wrapper_without_padding {
	// 		margin-bottom: 15px;
	// 	}
	// }
}

@media (max-width: 300px) {
	.video_segment_container {
		height: 80vh !important;
		overflow: auto !important;
		margin: 0px 0px 40px 0px !important;

		.video_container .video_Card_Wrapper {
			margin-bottom: 15px;
		}
		.video_container.video_Card_Wrapper_without_padding {
			margin-bottom: 15px;
		}
	}
	.video_viewall_button {
		span {
			font-size: 8px !important;
		}
	}
}
