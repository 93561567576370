@import '../../../../constants/_variables.scss';
.terminate-employee-presentational-col {
	width: 100%;
	align-self: flex-start;
	padding: 0px 40px 40px 40px;
	.terminate-employee-presentational-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: sticky;
		top: 0;
		z-index: 1;
		background: #f7f8fa;
		padding: 42px 0px 26px 0px;
		p {
			font-family: 'Montserrat-Bold';
			font-style: normal;
			font-weight: 700;
			font-size: 24px;
			line-height: 28px;
			color: #00344e;
			margin: 0px;
		}
		.secondary-btn {
			font-family: 'Montserrat-Medium';
		}
		.buttonnew {
			width: 118px;
			img {
				margin-right: 0px;
			}
		}
	}
	.terminate-employee-presentational-row {
		width: 100%;
		display: flex;
		align-items: center;
		background: $neutral-white;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 32px;
		.header {
			span {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 20px;
				color: $primaryColor5;
			}
		}
		.sub-header {
			padding: 24px 0px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: $grayscale-600;
			}
		}
		.employee-table {
			.table-border {
				border: 1px solid #dfe1e6;
				box-sizing: border-box;
				padding: 24px 25px 24px 24px;
				&:nth-child(1) {
					border-right: none;
				}
				&:nth-child(2) {
					border-left: none;
					border-right: none;
				}
				&:nth-child(3) {
					border-left: none;
				}
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: $blue-300;
				}
			}
		}
		.employee-table1 {
			.table-border {
				border: 1px solid #dfe1e6;
				box-sizing: border-box;
				padding: 24px 25px 24px 24px;
				&:nth-child(1) {
					border-right: none;
				}
				&:nth-child(2) {
					border-left: none;
					border-right: none;
				}
				&:nth-child(3) {
					border-left: none;
				}
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $grayscale-900;
				}
			}
		}
		.terminate-mobile {
			display: none !important;
		}
		.other-rules {
			padding: 24px 0px 8px 0px;
			span {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 18px;
				color: $primaryColor5;
			}
		}
		.effective {
			padding-bottom: 30px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: $grayscale-600;
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.terminate-employee-presentational-col {
		width: 100%;
		height: 100%;
		padding: 66px 15px 0px 15px;
		.terminate-employee-presentational-row {
			width: 100%;
			padding-bottom: 70px;
			.header {
				span {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 20px;
					line-height: 24px;
					color: $primaryColor5;
				}
			}
			.sub-header {
				display: none !important;
			}
			.employee-table {
				display: none !important;
			}
			.employee-table1 {
				display: none !important;
			}
			.terminate-mobile {
				display: flex !important;
				width: 100%;
				.sub-mobile-content {
					padding-top: 24px;
					span {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 18px;
						color: $greyColor6;
					}
				}
				.table-timing {
					padding-top: 16px;
					span {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 18px;
						color: $primaryColor5;
					}
				}
				.table-content-col {
					padding-top: 8px;
					span {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 18px;
						color: $greyColor6;
					}
				}
			}
			.other-rules {
				padding: 24px 0px 8px 0px;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 18px;
					color: $primaryColor5;
				}
			}
			.effective {
				padding-bottom: 30px;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: $greyColor6;
				}
			}
		}
	}
}
