.sharedComponent {
	height: 100%;
	width: 100%;
	padding: 30px;
}
.back_router_text {
	//styleName: Mont_H4 Medium 18pt;
	font-family: Montserrat-Medium;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: center;
	vertical-align: middle;
}

.main_carousel {
	display: flex;
	column-gap: 10px;
	justify-content: center;
	align-items: center;
}
.carousel_Button {
	border: 2px solid #000;
	border-radius: 5px;
	padding: 5px;
}
.customize_button {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid transparent;
	background: #d52b1e;
	border-radius: 50%;
	width: 35px;
	height: 35px;
}
.prop-type-table {
	.border {
		border: 1px solid #e2e2e2;
	}
	th,
	td {
		@extend .border;
		padding: 15px;
		text-align: left;
		width: 250px;
	}
	tr {
		&:nth-child(even) {
			background-color: #f4f4f4;
		}
	}
}
.ed_text {
	color: #0e5274;
	text-decoration-line: underline;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	margin-left: 5px;
}
.table-input {
	.input-label-top {
		margin-top: 1px;
		margin-bottom: 1px;
	}
	.input_field {
		height: 30px;
	}
}
.input-note-test {
	resize: none;
	border-radius: 16px;
	height: 80px;
	width: 100%;
	overflow: hidden;
	// caret-color: #DFE1E6;
	// border: 1px solid #DFE1E6;
	padding-top: 10px;
	padding-left: 10px;
	margin-top: 10px;
	// caret-color: #71b0d0;
	&::placeholder {
		color: #737780 !important;
	}
}

textarea:focus {
	outline: none !important;
	// border: 1px solid #71b0d0;
}
textarea:focus::placeholder {
	color: #dfe1e6 !important;
}

.for-characters-div {
	display: flex;
	justify-content: end;
	color: #737780;
	opacity: 0.5;
	font-size: 10px;
	font-family: 'Montserrat-Light';
	line-height: 0px;
	font-weight: 300;
	padding-top: 10px;
}
