.payroll-flatpickrs {
	.css-1rpz77w {
		position: absolute;
		top: 22px;
		left: 102%;
		border-color: #9a9ea6;
		border-bottom-color: transparent;
	}
	.validate {
		border: 2px solid #e60a14 !important;
	}
}
