.setup-info-page {
	display: flex;
	// align-self: start;
	flex-direction: column;
	justify-content: flex-start;

	padding-top: 34px;
	background-color: #f7f8fa;
	height: 100%;
	align-items: center;
	width: 960px;
	.setup-warning-box {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 0px 26px 0px 0px;
		margin-top: 34px;
		width: 100%;

		/* Colors/Neutral/neutral-white */

		background: #ffffff;
		/* Shadows/shadow-md */

		box-shadow: 0px 8px 24px -12px #bdbdbd;
		border-radius: 8px;

		.warning-icon-holder {
			display: flex;
			flex-direction: row;
			width: 40px;
			height: 100%;
			justify-content: center;
			align-items: center;
			background-color: #ffdc91;
			border-radius: 8px;
		}

		.warning-icon-content {
			display: flex;
			flex-direction: row;
			padding: 16px;
			width: 100%;
		}
	}

	.setup-information-screen {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: fit-content;
		justify-content: space-between;
		width: 960px;
	}

	.setup-information-section {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		height: fit-content;
		width: 100%;
		column-gap: 16px;
		row-gap: 16px;
		margin-top: 34px;
		width: 960px;

		.setup-card-container {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			// gap: 10px;
			// background: red;
			// width: 309px;
			// height: 204px;

			/* Colors/Neutral/neutral-white */

			/* Shadows/Big */
			background-color: white;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 9px;
			cursor: pointer;

			.disabled {
				-webkit-mask-image: linear-gradient(#0000003b, #0000003b);
				filter: grayscale(1);
			}
			.setup-active {
				display: flex;
				flex-direction: column;
				height: fit-content;
				width: fit-content;
				margin: 24px;
				cursor: pointer;
				.setup-card-top-section {
					display: flex;
					flex-direction: row;
					width: 100%;
					height: fit-content;
					justify-content: space-between;
					.setup-image-container {
						display: flex;
						flex-direction: row;
						align-items: flex-start;
						padding: 8px;
						gap: 10px;

						width: 40px;
						height: 40px;

						/* WB 1 */

						background: #ffffff;
						/* Shadows/shadow-md */

						box-shadow: 0px 8px 24px -12px #bdbdbd;
						border-radius: 8px;
					}

					.chips-container {
						display: flex;
						flex-direction: row;
						margin-left: 148px;
					}
				}

				.setup-card-down-section {
					display: flex;
					flex-direction: column;
					width: 100%;
					height: fit-content;
					margin-top: 26px;

					.setup-content-section {
						display: flex;
						flex-direction: row;
						max-width: 261px;
						width: 96%;
						max-height: 24px;
						margin-top: 8px;
					}
					.update-container {
						display: flex;
						flex-direction: row;
						width: 100%;
						height: fit-content;
						justify-content: flex-end;
						margin-top: 30px;
					}
				}
			}
		}
	}
	//////////////////////
	.setup-info-disable {
		background-color: #f7f8fa;
		padding: 2%;
		margin: 1%;
		pointer-events: none;
		.setup-info-card-top {
			display: flex;
			justify-content: space-between;
		}
		.setup-icon-div {
			background-color: #eff1f5;
			border-radius: 4px;
			opacity: 0.1;
		}
		.setup-chip {
			background-color: #eff1f5;
			border-radius: 4px;
			padding: 3px 8px;
			margin-bottom: 0px;
			font-family: 'Montserrat-Light';
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
			color: #dfe1e6;
		}
		.setup-chip-missing {
			background-color: #eff1f5;
			border-radius: 4px;
			padding: 3px 8px;
			margin-bottom: 0px;
			font-family: 'Montserrat-Light';
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
			color: #dfe1e6;
		}
		.setup-chip-completed {
			background-color: #eff1f5;
			border-radius: 4px;
			padding: 3px 8px;
			margin-bottom: 0px;
			font-family: 'Montserrat-Light';
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
			color: #dfe1e6;
		}
		.card-info-heading {
			font-family: 'Montserrat-Bold';
			font-weight: 700;
			font-size: 16px;
			line-height: 18px;
			color: #dfe1e6;
			margin-bottom: 0px;
			margin-top: 8px;
		}
		.card-info-para {
			font-family: 'Montserrat-Regular';
			font-weight: 300;
			font-size: 10px;
			line-height: 12px;
			color: #dfe1e6;
			margin-top: 8px;
		}
		.card-info-button-div {
			display: flex;
			justify-content: flex-end;
			pointer-events: none;
		}
		.card-info-button {
			border: none;
			background-color: #f7f8fa;
			color: #dfe1e6;
			font-family: 'Montserrat-Regular';
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
			cursor: pointer;
			text-decoration: underline;
		}
	}
	.setup-info-cards {
		background-color: #f7f8fa;
		padding: 2%;
		margin: 1%;
		cursor: pointer;
		.setup-info-card-top {
			display: flex;
			justify-content: space-between;
		}
		.setup-icon-div {
			background-color: #ffffff;
			border-radius: 4px;
		}
		.setup-chip {
			background-color: #eec3ba;
			border-radius: 4px;
			padding: 3px 8px;
			margin-bottom: 0px;
			font-family: 'Montserrat-Light';
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
		}
		.setup-chip-missing {
			background-color: #eedcba;
			border-radius: 4px;
			padding: 3px 8px;
			margin-bottom: 0px;
			font-family: 'Montserrat-Light';
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
			color: #181919;
		}
		.setup-chip-completed {
			background-color: #baeed2;
			border-radius: 4px;
			padding: 3px 8px;
			margin-bottom: 0px;
			font-family: 'Montserrat-Light';
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
			color: #181919;
		}
		.card-info-heading {
			font-family: 'Montserrat-Bold';
			font-weight: 700;
			font-size: 16px;
			line-height: 18px;
			color: #00344e;
			margin-bottom: 0px;
			margin-top: 8px;
		}
		.card-info-para {
			font-family: 'Montserrat-Regular';
			font-weight: 300;
			font-size: 10px;
			line-height: 12px;
			color: #484b52;
			margin-top: 8px;
		}
		.card-info-button-div {
			display: flex;
			justify-content: flex-end;
		}
		.card-info-button {
			border: none;
			background-color: #f7f8fa;
			color: #0e5274;
			font-family: 'Montserrat-Regular';
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
			cursor: pointer;
			text-decoration: underline;
		}
	}
	.setup-information-alert {
		display: flex;
		align-items: center;
		border: 1px solid #e4dd3d;
		border-radius: 8px;
		padding: 5px;
		margin-left: 10px;
		.setup-alert-text {
			font-family: 'Montserrat-Regular';
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: #484b52;
			margin: 0;
			padding-left: 5px;
		}
	}
	.continue-button {
		margin-left: 1%;
		margin-top: 2%;
		padding: 11px 28px;
		border-radius: 28px;
		border: 1px solid #dfe1e6;
		background-color: #ffffff;
		color: #484b52;
		font-family: 'Montserrat-Medium';
		font-weight: 500;
		font-size: 16px;
		line-height: 18px;
		cursor: pointer;
	}
	.next-button {
		margin-left: 1%;
		margin-top: 2%;
		padding: 15px 25px 15px 25px;
		background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%);
		box-shadow: 0px 10px 20px rgba(233, 180, 175, 0.75);
		border-radius: 28px;
		border: none;
		outline: none;
		color: #f7f8fa;
		font-family: 'Montserrat-Medium';
		font-weight: 500;
		font-size: 18px;
		line-height: 20px;
		text-align: center;
		cursor: pointer;
		width: 107px;
	}
}
//////////////////////

/////////////////
.setup-skip-popup {
	.ant-modal-content {
		.ant-modal-body {
			height: 25vh !important;
		}
	}
	.main_popup_container {
		.message_container {
			height: 74%;
		}
		.routing_container {
			height: 26%;
		}
	}
}
@media screen and (max-width: 769px) {
	.setup-info-page {
		margin-left: 0%;
	}
}
