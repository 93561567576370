.payroll_warning {
	width: 100%;
	.description {
		font-weight: 700;
		font-size: 19px;
		display: flex;
		align-items: center;
	}
	.timestamp {
		padding-top: 10px;
		font-size: 18px;
		text-align: center;
	}
}
.payroll_warning {
	.coporate-message {
		padding-left: 9px;
	}
}

@media (max-width: 600px) {
	.payroll_warning {
		.coporate-message {
			text-align: center;
			margin-top: 21px;
		}
	}
}
@media (max-width: 600px) {
	.payroll_warning {
		.timestamp {
			text-align: center;
		}
	}
}
@media (max-width: 600px) {
	.payroll_warning {
		.alert-image {
			text-align: center;
			margin-top: 190px;
		}
	}
}
@media (max-width: 767px) {
	.payroll_warning {
		.alert-image {
			text-align: center;
		}
	}
}
