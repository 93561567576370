@import '../../../constants/variables';
.setup_leave_approval {
	// margin: 25px 0 20px 0;
	justify-content: center;
	width: 100%;
	height: 100%;
	background: #f7f8fa;
	.setup_typo {
		margin: 4px 0 18px 0;
		span {
			font-family: 'Montserrat-Bold';
			font-style: normal;
			font-weight: 700;
			font-size: 24px;
			line-height: 28px;
		}
	}
	.setup_tabs {
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 10px 0 0 0;
		.hr-upload {
			.topCont {
				.newDragger {
					height: 238px;
				}
			}
			.whole {
				padding: 0px 0px 25px 0px;
			}
		}
		.approval-workflow-setUp {
			.hint-box {
				.hint-box-text {
					font-family: 'Montserrat-Regular';
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: #484b52;
					a {
						text-decoration: underline;
						color: #0e5274;
					}
				}
			}
			.setup_approvalFlow {
				width: 100%;
				.approval-workflow-main-header {
					justify-content: unset;
					width: 116%;
					.approval-workflow-header {
						.workflow-typo {
							display: none;
						}
					}
					.company-setting-setup {
						margin: 20px 0 10px 0;
						.company-setting-btn {
							height: 48px;
							border-radius: 28px;
							background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%) !important;
							color: white !important;
							box-shadow: none !important;
						}
						.save_button {
							width: 196px;
							margin-bottom: 15px;
							.company-set-save:disabled {
								background: #f7f8fa !important;
								color: #dfe1e6 !important;
							}
							.company-set-save {
								font-family: 'Montserrat-Medium';
								font-size: 16px;
								line-height: 18px;
								width: 200px;
							}
						}
						.cancel_button {
							width: 106px;
							.company-set-cancel:disabled {
								background: white !important;
								margin-right: 16px;
								color: #484b52 !important;
								border: 2px solid #f7f8fa !important;
								color: #dfe1e6 !important;
							}
							.company-set-cancel {
								background: white !important;
								margin-right: 16px;
								color: #484b52 !important;
								border: 1px solid #dfe1e6 !important;
								font-family: 'Montserrat-regular';
								font-size: 14px;
								line-height: 16px;
								width: 100px;
								height: unset;
								padding: 8px;
							}
						}
					}
				}
			}
		}
		.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			text-align: center;
			color: $secondaryColor5;
		}
		.ant-tabs-ink-bar {
			position: absolute;
			background: $secondaryColor3;
		}
		.ant-tabs-tab {
			width: 200px;
			cursor: pointer;
			display: flex;
			justify-content: center;
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			text-align: center;
			color: $greyColor5;
			border-bottom: 2px solid $greyColor4;
			padding: 5px 0px;
			margin-left: 10px;
		}
		.ant-tabs-tab:hover {
			color: $greyColor5;
		}
		.ant-tabs-tab-btn:focus,
		.ant-tabs-tab-remove:focus,
		.ant-tabs-tab-btn:active,
		.ant-tabs-tab-remove:active {
			color: $secondaryColor5;
		}
		.ant-tabs-top > .ant-tabs-nav::before,
		.ant-tabs-bottom > .ant-tabs-nav::before,
		.ant-tabs-top > div > .ant-tabs-nav::before,
		.ant-tabs-bottom > div > .ant-tabs-nav::before {
			position: absolute;
			right: 0;
			left: 0;
			border-bottom: none;
			content: '';
		}
		.ant-tabs-nav .ant-tabs-nav-more {
			display: none;
		}
	}
	.header-container {
		height: 100%;
	}
	.upload-spreadsheet-col-container {
		.UploadSpreadsheet {
			padding: 0 0 0 0;
			.UploadSpreadsheet_header {
				margin-top: -10px;
			}
			.upload_spreadsheet_steps_container {
				padding: 0px 0px;
				.upload_spreadsheet_steps_row {
					.upload_spreadsheet_steps_content {
						span {
							font-size: 14px;
							line-height: 16px;
						}
						a {
							padding-left: 5px;
						}
					}
				}
			}
		}
		.forEmployees {
			margin-top: 0%;
		}
	}
	.leave_approve_upload {
		.hr-upload {
			.forEmployees {
				.UploadSpreadsheet_header {
					.heading-description {
						margin-bottom: 2px;
					}
				}
			}
			.topCont {
				.newDragger {
					height: 210px;
					.ant-upload.ant-upload-drag .ant-upload {
						padding: 8px 0;
					}
				}
			}
			.whole {
				padding: 0px 0px 25px 0px;
			}
		}
	}
}
