@import '../../../constants/variables';

.edenredCard_main {
	background: #ffffff;
	border-radius: 16px;
	padding: 0px 0px 2% 0px;

	.edenredCard_desktop_view_approve_dashboard {
		width: 100%;
		height: 100%;
		border-radius: 16px;

		.card_icon {
			text-align: center;
			height: 40%;
			display: flex;
			align-items: flex-end;
			justify-content: center;

			img {
				width: 60px;
				height: 60px;
			}
		}

		.card_content_section {
			height: 50%;

			.card_title {
				display: flex;
				justify-content: center;

				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 20px;
					text-align: center;
					color: #00344e;
				}
			}

			.card_description {
				display: flex;
				justify-content: center;
				padding: 5px 9px;

				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					text-align: center;
					color: #737780;
				}
			}
		}
	}

	.edenredCard_web_view {
		height: 100%;

		.manage_team_user_detail {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0px 15px 0 0;

			.manage_team_user_name {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: $primaryColor5;
				white-space: normal;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.manage_team_user_mail {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: $greyColor7;
			}
		}

		.manage_team_user_role {
			display: flex;
			align-items: center;
			padding: 0px 15px 0 0;

			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: $primaryColor5;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.manage_team_user_role1 {
			display: flex;
			align-items: center;

			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: $primaryColor5;
			}
		}

		.manage_team_user_permission {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: $primaryColor5;
			}

			.arrow_img {
				height: 30px;
				width: 30px;
			}
		}

		.ant-card-body {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 0 !important;
		}

		.invite_team_type_role {
			display: flex;
			flex-direction: column;
			justify-content: center;

			.role {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: #00344e;
			}

			.description {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: #484b52;
				padding-top: 4px;
			}
		}

		.icon_part {
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				cursor: pointer;
			}
		}

		.payrol_content_part {
			padding-left: 3%;
			display: flex;
			flex-direction: column;

			.payroll_title {
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-size: 18px;
					font-weight: 500;
					line-height: 20px;
					color: $primaryColor5;
				}
			}

			.payroll_description {
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-size: 14px;
					font-weight: 300;
					line-height: 16px;
					color: $greyColor7;
				}
			}
		}
	}

	.edenredCard_mobile_view {
		display: none;
	}

	.edenredCard_mobile_view_payroll_channel {
		display: none;
	}

	.edenredCard_mobile_view_approve_dashboard {
		display: none;
	}

	.edenredCard_payroll_history {
		width: 100%;
		border-radius: 24px;
		height: 100%;

		.payroll_history_col_first {
			height: 100%;
			width: 10%;
			position: absolute;
			border-top-left-radius: 20px;
			border-bottom-left-radius: 20px;
		}

		.payroll_history_col_second {
			background: white;
			margin-left: 8px;
			height: 100%;
			padding: 32px 0px 32px 3.5%;
			border-top-left-radius: 20px;
			border-bottom-left-radius: 20px;

			.payroll_history_header {
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 20px;
					line-height: 24px;
					color: #00344e;
				}
			}

			.payroll_history_detail {
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 18px;
					color: #2b769c;
				}
			}

			.payroll_history_content {
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: #484b52;
				}
			}
		}

		.payroll_history_col_third {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-left: 5%;
			img {
				height: 30px;
				width: 30px;
			}
		}
	}

	.edenredCard_payroll_history_mobile_view {
		display: none;
	}

	.edenredCard_desktop_view_approve_requests {
		display: flex;
		// box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		border: 1px solid #eff1f5;
		flex-direction: column;
		// padding: 24px 32px;

		.payroll-request-upper-section {
			display: flex;
			flex-direction: row;
			padding: 24px 32px 16px;
			background-color: #fbfcfd;
			border-top-right-radius: 8px;
			border-top-left-radius: 8px;

			.payrollrequest-checkbox-container label {
				margin-top: 4px;
				padding: 0px !important;

				.payroll_plus_table_check_box {
					background: #8ef9b3;
					border-radius: 4px;
					width: 100%;
					height: 12px;
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						width: 8px;
						height: 8px;
					}
				}
			}

			.date-and-button-container {
				display: flex;
				width: 100%;
				flex-direction: row;
				padding-left: 16.83px;
				justify-content: space-between;

				.payrollrequest-date-container {
					display: flex;
					flex-direction: column;

					.month-submited-section {
						.view-detail-style {
							margin-left: 16px;
							cursor: pointer;
						}
					}
				}

				.payrollrequest-cancel-approve-container {
					display: flex;
					flex-direction: row;
				}
			}
		}

		.payroll-request-down-section {
			display: flex;
			flex-direction: column;
			padding: 16px 32px 24px 64px;
			.employee-amount-container {
				display: flex;
				flex-direction: column;
				// margin-left: 38px;

				.total-employee {
					display: flex;
					flex-direction: row;
				}

				.total-amount {
					display: flex;
					flex-direction: row;
					margin-top: 7.22px;
				}
			}
		}

		.approve_requests_select_unselect_image {
			padding-left: 32px;
			display: flex;
			justify-content: flex-start;

			img {
				cursor: pointer;
				// height: 25.04px;
				// width: 25.04px;
			}
		}

		.approve_requests_payroll_details {
			display: flex;
			flex-direction: column;

			.approve_requests_payroll_month_title {
				padding: 1% 0% 0% 0%;

				span {
					font-size: 20px;
					font-weight: 600;
					line-height: 24px;
					color: $primaryColor5;
				}
			}

			.approve_requests_payroll_submitted_date {
				padding: 1% 0% 0% 0%;

				span {
					font-size: 16px;
					font-weight: 500;
					line-height: 18px;
					color: $greyColor7;
				}
			}

			.approve_requests_payroll_total_amount {
				padding: 1% 0% 0% 0%;

				span {
					font-size: 11pt;
					font-weight: 700;
					line-height: 14pt;
					color: $greyColor7;
				}
			}

			.approve_requests_payroll_buttons {
				display: flex;
				flex-direction: row;
				padding: 1% 0% 0% 0%;

				.approve_requests_payroll_reject_button {
					padding: 2% 1% 0% 0%;

					button {
						padding: 1%;
						min-height: 36px;
						font-size: 11pt;
					}
				}

				.approve_requests_payroll_approve_button {
					padding: 2% 0% 0% 1%;

					button {
						padding: 1%;
						min-height: 36px;
						font-size: 11pt;
					}
				}
			}
		}

		.approve_requests_payroll_view_details {
			div {
				span {
					font-size: 11pt;
					font-weight: 600;
					color: $primaryColor5;
				}

				img {
					height: 40px;
					width: 35px;
				}
			}
		}
	}

	.edenredCard_mobile_view_approve_requests {
		display: none;
	}

	.edenredCard_both_view_payroll_timings {
		.payroll_timings_row {
			display: flex;
			flex-direction: row;

			.payroll_timings_salary_funds_recieved_title {
				padding: 0px 4px 6px 0px;

				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 18px;
					color: $greyColor7;
				}
			}

			.payroll_timings_expected_payout_title {
				padding: 0px 4px 6px 0px;

				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 18px;
					color: $greyColor7;
				}
			}

			.payroll_timings_salary_funds_recieved {
				padding: 0px 4px 6px 0px;

				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor7;
				}
			}

			.payroll_timings_expected_payout {
				padding: 0px 4px 6px 0px;

				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor7;
				}
			}
		}
	}

	.edenredCard_both_view_bank_details {
		padding: 16px;

		.bank_details_row {
			display: flex;
			flex-direction: row;

			.bank_details_title {
				padding-bottom: 1.5%;

				span {
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #484b52;
				}
			}

			.bank_details_value {
				padding-bottom: 1.5%;

				span {
					font-size: 11pt;
					font-weight: 500;
					line-height: 16pt;
					color: $greyColor7;
				}
			}
		}

		.bank_details_hint {
			font-size: 7pt;
			font-weight: 300;
			line-height: 12pt;
			color: $greyColor7;
		}
	}

	.edencard_web_view_useful_files {
		// width: 107%;
		justify-content: space-between;
		.usefull_file_card {
			display: flex;
			align-items: center;
			width: 89%;
			.content_useful_files {
				.title_edencard {
					span {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 20px;
						line-height: 24px;
						color: #00344e;
					}
				}
				.description_edenredcard {
					padding-top: 4px;
					span {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 18px;
						color: #484b52;
					}
				}
			}
			.content_with_description{
				display: flex;
				flex-direction: column;
				gap: 4px;
			}
		}
		.download_icon {
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 45px;
				height: 45px;
			}
		}
	}

	.edencard_mobile_view_useful_files {
		display: none;
	}

	.edencard_mobile_view_proof_of_transfer {
		display: none;
	}

	.edencard_web_view_proof_of_transfer {
		.proof_header {
			span {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 24px;
				color: #00344e;
			}
		}

		.proof_title {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 18px;
			color: #484b52;
		}

		.proof_detail {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: #484b52;
		}

		.view_submit {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			align-self: flex-start;
			text-decoration: underline;
		}

		.view-submited-proof-custom {
			.view_toast_popup_message {
				position: absolute;
				bottom: 40px;
				width: 100%;
				display: flex;
				justify-content: center;
				transition: ease-in-out 5s;
				animation: 3s ease 0s normal forwards 1 fade;
			}

			@keyframes fade {
				0% {
					opacity: 0;
				}

				60% {
					opacity: 1;
				}

				100% {
					opacity: 1;
				}
			}
		}
	}

	.employee-failed-mobile-view {
		display: none;
	}

	.company-invoices-container {
		.company-invoices-col1 {
			.company-invoices-row {
				display: flex;
				align-items: center;

				.header {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 20px;
					line-height: 24px;
					color: #00344e;
				}

				.sub-header {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: #484b52;
				}
			}
		}

		.company-view-details {
			display: flex;
			align-items: center;

			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: #00344e;
			}

			img {
				width: 32px;
				height: 32px;
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.edenredCard_main {
		padding: 16px;
		width: 100%;
		display: flex;

		.ant-card-body {
			width: 100%;
			padding: 1% !important;
		}

		.edenredCard_web_view {
			display: none;
		}

		.edenredCard_desktop_view_approve_dashboard {
			display: none !important;
		}

		.edenredCard_mobile_view {
			display: flex !important;
			width: 100%;

			.manage_team_user_details {
				display: flex;
				flex-direction: column;
				justify-content: center;

				.manage_team_user_name {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: $primaryColor5;
				}

				.manage_team_user_role {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					color: $greyColor7;
				}

				.manage_team_user_mail {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					color: $greyColor7;
				}
			}

			.manage_team_edit_permissions {
				display: flex;
				align-items: center;

				.arrow_img {
					height: 25px;
					width: 25px;
				}
			}

			.ant-card-body {
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 0 !important;
			}

			.invite_team_type_role {
				display: flex;
				flex-direction: column;

				.role {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #00344e;
				}

				.description {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					color: #484b52;
				}
			}
		}

		.edenredCard_mobile_view_payroll_channel {
			display: flex !important;
			flex-direction: row;
			height: 100%;
			align-items: center;
			justify-content: center;

			.icon_part {
				padding: 0% 0% 0% 3%;
				height: 100%;
				display: flex;
				align-items: center;

				.icon {
					cursor: pointer;
					height: 35px;
					width: 35px;
				}
			}

			.payrol_content_part {
				.payroll_title {
					span {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 16px;
						color: #00344e;
					}
				}

				.payroll_description {
					span {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 12px;
						line-height: 14px;
						color: #484b52;
					}
				}
			}
		}

		.edenredCard_mobile_view_approve_dashboard {
			display: flex;
			flex-direction: row;

			.card_icon {
				padding: 0%;
				display: flex;
				align-items: center;

				img {
					width: 38px;
				}
			}

			.card_content_section {
				display: flex;
				flex-direction: column;

				.card_title {
					span {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 16px;
						color: #00344e;
					}
				}

				.card_description {
					span {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 10px;
						line-height: 14px;
						display: flex;
						align-items: center;
						color: #484b52;
					}
				}
			}

			.right_icon {
				display: flex;
				align-items: center;

				img {
					height: 28px;
					width: 30px;
				}
			}
		}

		.edenredCard_mobile_view_check_run_payroll {
			display: flex;
			flex-direction: row;

			.card_icon_payrol_check {
				padding: 0%;
				display: flex;
				align-items: center;

				img {
					width: 38px;
				}
			}

			.card_content_section_payroll_check {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				.card_title_payroll_check {
					span {
						font-size: 14px;
						font-weight: 600;
						line-height: 8pt;
						color: $primaryColor5;
					}
				}
			}

			.right_icon_payroll_check {
				display: flex;
				align-items: center;

				img {
					height: 28px;
					width: 30px;
				}
			}
		}

		.edenredCard_payroll_history {
			display: none !important;
		}

		.edenredCard_payroll_history_mobile_view {
			display: flex !important;
			width: 100%;
			border-radius: 12px;
			height: 100%;

			.payroll_history_col_first {
				height: 100%;
				width: 10%;
				position: absolute;
				border-top-left-radius: 16px;
				border-bottom-left-radius: 16px;
			}

			.payroll_history_col_second {
				background: white;
				border-radius: 16px;
				margin-left: 10px;
				height: 100%;
				padding: 12px 0px 12px 8px;

				.payroll_history_header {
					span {
						font-size: 16px;
						color: #00344e;
					}
				}

				.payroll_history_detail {
					span {
						font-size: 12px;
						color: #2b769c;
					}
				}

				.payroll_history_content {
					span {
						font-size: 10px;
						color: #484b52;
					}
				}
			}

			.payroll_history_col_third {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				img {
					height: 30px;
					width: 30px;
				}
			}
		}

		.edenredCard_desktop_view_approve_requests {
			display: none;
		}

		.edenredCard_mobile_view_approve_requests {
			display: flex !important;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			.approve_requests_select_unselect_image {
				padding-left: 1.25%;

				img {
					height: 40px;
					width: 40px;
				}
			}

			.approve_requests_payroll_details {
				display: flex;
				flex-direction: column;
				padding: 2% 1% 0% 1%;

				.approve_requests_payroll_month_title {
					padding: 1% 0% 0% 0%;

					span {
						font-size: 10pt;
						font-weight: 900;
						line-height: 15pt;
						color: $primaryColor5;
					}
				}

				.approve_requests_payroll_submitted_employees {
					padding: 1% 0% 0% 0%;

					span {
						font-size: 11pt;
						font-weight: 500;
						line-height: 14pt;
						color: $greyColor7;
					}
				}

				.approve_requests_payroll_total_amount {
					padding: 1% 0% 0% 0%;

					span {
						font-size: 9pt;
						font-weight: 600;
						line-height: 14pt;
						color: $greyColor7;
					}
				}
			}

			.approve_requests_payroll_view_details {
				display: flex;
				justify-content: flex-end;

				img {
					height: 30px;
					width: 30px;
				}
			}

			.approve_requests_payroll_buttons {
				display: flex;
				flex-direction: row;
				padding: 1% 0% 0% 0%;

				.approve_requests_payroll_reject_button {
					padding: 3%;

					button {
						padding: 1%;
						min-height: 36px;
						font-size: 11pt;
						border: 1px solid $greyColor4;
					}
				}

				.approve_requests_payroll_approve_button {
					padding: 3%;

					button {
						padding: 1%;
						min-height: 36px;
						font-size: 11pt;
					}
				}
			}
		}

		.edenredCard_both_view_payroll_timings {
			.payroll_timings_row {
				.payroll_timings_salary_funds_recieved_title {
					span {
						font-size: 12px;
						line-height: 14px;
					}
				}

				.payroll_timings_expected_payout_title {
					span {
						font-size: 12px;
						line-height: 14px;
					}
				}

				.payroll_timings_salary_funds_recieved {
					span {
						font-size: 12px;
						line-height: 14px;
					}
				}

				.payroll_timings_expected_payout {
					span {
						font-size: 12px;
						line-height: 14px;
					}
				}
			}
		}

		.edenredCard_both_view_bank_details {
			.bank_details_row {
				display: flex;
				flex-direction: row;

				.bank_details_title {
					span {
						font-style: normal;
						font-weight: 500;
						font-size: 12px;
						line-height: 14px;

						color: #484b52;
					}
				}

				.bank_details_value {
					span {
						font-size: 9pt;
						font-weight: 500;
						line-height: 13pt;
						color: $greyColor7;
					}
				}
			}

			.bank_details_hint {
				font-size: 6pt;
				font-weight: 300;
				line-height: 12pt;
				color: $greyColor7;
			}
		}

		.edencard_web_view_useful_files {
			display: none;
		}

		.edencard_mobile_view_useful_files {
			width: 100%;
			height: 80px;
			display: flex !important;

			// padding: 20px 17px 20px 5px;
			.image_icon {
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 35px;
					height: 35px;
				}
			}

			.useful_files_mobile {
				display: flex;
				align-items: center;
				padding-right: 22px;

				.title_edencard {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #00344e;
					padding-bottom: 2.5px;
				}

				.description_edenredcard {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					color: #484b52;
					padding-top: 2.5px;
				}
			}

			.download_icon {
				display: flex;
				justify-content: flex-end;
				align-items: center;
			}
		}

		.edencard_web_view_proof_of_transfer {
			display: none !important;
		}

		.edencard_mobile_view_proof_of_transfer {
			display: flex !important;

			.proof_header {
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 20px;
					color: #00344e;
				}
			}

			.proof_title {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: #484b52;
				padding-top: 2px;
			}

			.proof_detail {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: #484b52;
				padding-top: 2px;
			}

			.view_submit {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				img {
					width: 30px;
					height: 30px;
				}
			}
		}
	}

	.runpayroll_mobile_card {
		width: 100%;

		.total_salary {
			padding: 1% 0%;

			span {
				font-size: 14px;
				color: #00344e;
			}
		}

		.arrow_img_rotate {
			width: 30px;
			height: 30px;

			::after {
				transform: rotate(45deg);
			}
		}

		.floating-input {
			height: 30px;
		}
	}

	.employee-table-mobile-view {
		.name-employe-table {
			span {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				color: #00344e;
			}
		}

		.employeid-employe-table {
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 10px;
				line-height: 14px;
				color: #484b52;
			}
		}

		.salary-employe-table {
			display: flex;
			align-items: center;

			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				text-align: right;
				color: #484b52;
			}
		}
	}

	.employee-failed-mobile-view {
		display: flex !important;
		width: 100%;

		.employee_details_section {
			display: inline-grid;

			.name-employe-failed {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				color: #00344e;
			}

			.employeid-employe-failed {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: #484b52;
			}

			.salary-employe-failed {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 10px;
				line-height: 14px;
				color: #484b52;
			}
		}

		.view_right {
			display: flex;
			align-items: center;

			img {
				height: 30px;
				width: 30px;
			}
		}
	}

	.company-invoices-container {
		.company-invoices-col1 {
			.company-invoices-row {
				display: flex;
				align-items: center;

				.header {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 20px;
					line-height: 24px;
					color: #00344e;
				}

				.sub-header {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: #484b52;
				}
			}
		}

		.company-view-details {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			span {
				display: none !important;
			}

			img {
				width: 32px;
				height: 32px;
			}
		}
	}
}
