.alert-info-box {
	background-color: #ff9691;
	padding: 12px 16px;
	border-radius: 8px;
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	// margin-bottom: 15px;
	.info-icon {
		width: 5%;
	}
	.info-content {
		width: 80%;
		padding-right: 40px;
		line-height: 17px;
	}
	.info-action {
		width: 143px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #fbfcfd;
		min-height: 32px;
		font-weight: 700;
		border-radius: 8px;
		padding: 0 15px;
		img {
			width: 18px;
		}
	}
}
.setup_dashboard_main_web {
	background: #ffffff;
	box-shadow: 0px 16px 32px -24px #bdbdbd;
	border-radius: 8px;
	padding: 18px 16px;
	height: 248px;
	max-height: 248px;
	.setup_header {
		display: flex;
		align-items: center;
		gap: 8px;
		.setup_image {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 5px;
			background: #ffffff;
			border-radius: 20px;
		}
	}
	.setup_card {
		// display: flex;
		align-items: center;
		gap: 10px;
		margin-top: 15px;
		.card_details {
			padding-right: 8px;
			display: flex;
			align-items: center;
			// justify-content: center;
			// flex-direction: column;
			border-radius: 8px;
			text-align: center;
			width: 100%;
			height: 56px;
			margin-bottom: 8px;
			padding-left: 3px;
			overflow: hidden;
			img {
				width: 40px;
			}
			.setup_count {
				font-family: 'Montserrat-Bold';
				font-style: normal;
				font-weight: 700;
				font-size: 20px;
				line-height: 24px;
				color: #181919;
				margin-top: 5px;
			}
			.setup_name {
				font-family: 'Montserrat-Medium';
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 14px;
				color: #181919;
				margin-bottom: -7px;
				padding-left: 8px;
			}
		}
		.no_data {
			background: #ff9691;
		}
		.missing_data {
			background: #ffdc91;
		}
		.completed_data {
			background: #8ef9b3;
		}
	}
	.setup_button {
		display: flex;
		justify-content: center;
		cursor: pointer;
		margin-top: 16px;
		.button {
			display: flex;
			align-items: center;
			justify-content: center;
			background: linear-gradient(93.76deg, #d10f00 1.98%, #f72717 100%);
			border: none;
			border-radius: 8px;
			font-family: 'Montserrat-Medium';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: #ffffff;
			padding: 8px 12px;
			margin-top: 15px;
			cursor: pointer;
		}
	}
	.setup_left_content {
		display: grid;
		place-items: center;
		padding-top: 12px;
	}
	.not_up_container {
		height: 64px;
		width: 64px;
		background: #fef5e8;
		border-radius: 8px;
		display: grid;
		place-items: center;
	}
}
