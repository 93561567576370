@import '../../../constants/_variables.scss';

.video_player_modal_containers {
	width: 100%;
	position: fixed;
	z-index: 10;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(255, 255, 255);
	background-color: rgba(255, 255, 255, 0.8);
	justify-content: center;
	display: flex;
	align-items: center;
	.video_modal_popup {
		background-color: #181919;
		justify-content: center;
		display: flex;
		height: 80vh;
		width: 50%;
		min-height: fit-content;
		flex-direction: column;
	}
	.video_close_btn {
		padding-top: 24px;
		padding-right: 24px;
		color: #ffffff;
		display: flex;
		width: 100%;
		justify-content: right;
		background-color: transparent;
		border: none;
	}
	.modal_video_container {
		height: 90%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

@media (min-width: 700px) and (max-width: 1700px) {
	.ant-modal-body {
		height: 80vh !important;
	}

	.replacementCardModal {
		.ant-modal-body {
			height: 15vh !important;
		}
	}
	.upload-pay-popup {
		.ant-modal-body {
			height: 20vh !important;
		}
	}
	.save-model {
		.ant-modal-body {
			height: 20vh !important;
		}
	}
}
@media (min-width: 700px) and (max-width: 900px) {
	.ant-modal-body {
		height: 60vh !important;
	}
	.video_modal_popup {
		height: 65vh !important;
	}
}
@media (min-width: 575px) and (max-width: 700px) {
	.ant-modal-body {
		height: 50vh !important;
	}
	.video_modal_popup {
		height: 50vh !important;
	}
}
@media (min-width: 200px) and (max-width: 575px) {
	.ant-modal-body {
		height: 30vh !important;
	}
	.video_modal_popup {
		height: 30vh !important;
	}
}
.video_player_modal_container {
	.ant-modal-content {
		.ant-modal-body {
			height: 70vh;
			min-height: none !important;
		}
	}
	.main_popup_video_container {
		height: 100%;
		width: 100%;
		.vimeo_video_container {
			height: 100%;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			iframe {
				width: 100%;
				height: 100%;
			}
		}
	}
}
