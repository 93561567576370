@import '../../../constants/variables';
.react_switch_main {
	display: flex;
	align-items: center;
	gap: 6px;
	.react_switch {
		width: 39px;
		height: 24px;
		border: 2.5px solid $whitecolor;
		background: $whitecolor;
		border-radius: 27.375px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
	}
	.react_switch_on {
		flex-direction: row-reverse;
		background: $blue-500;
		border-radius: 27.375px;
		border: 2.5px solid $blue-500;
	}
	.react_switch_ball {
		background: $neutral-white;
		border-radius: 75px;
		width: 21px;
		margin-left: -1px;
		margin-top: 0px;
		height: 21px;
	}
}
