@import '../../../constants/_variables.scss';

$loaderSize: 20px;

.edenRedButton {
	cursor: pointer;
	background: none;
	border-radius: 28px;
	border: none;
	padding: 14px 32px;
	font-weight: 500;
	font-size: 18px;
	line-height: 20px;
	text-align: center;
	color: $greyColor2;
	outline: none;
	width: 100%;
	min-height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	&.noShadow {
		box-shadow: none !important;
	}
	&.primary {
		background: $secondaryGradient1;
		box-shadow: 0px 10px 20px rgba(233, 180, 175, 0.75);
		&:disabled {
			background: $greyColor2;
			color: $greyColor4 !important;
			border: 2px solid $greyColor2 !important;
			cursor: not-allowed;
			box-shadow: none;
			&:hover {
				box-shadow: none;
			}
		}
		&:hover {
			// box-shadow: 0px 10px 20px rgba(233, 180, 175, 0.75);
		}
	}
	&.outline {
		border: 2px solid $secondaryColor1;
		padding: 12px 30px;
		box-sizing: border-box;
		color: $secondaryColor5;
		&:disabled {
			border: 2px solid $greyColor2 !important;
			background: $greyColor2;
			color: $greyColor7 !important;
		}
	}
	&.outline_grey {
		padding: 12px 30px;
		box-sizing: border-box;
		color: #dfe1e6;
		background: #f7f8fa;
	}
	&.outline_blue {
		width: 243px;
		min-width: 243px;
		min-height: 32px;
		padding: 0px 0px;
		height: 32px;
		font-family: Montserrat-Regular;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		text-align: center;
		color: #484b52;
		border: 1px solid #71b0d0;
	}
	&.outline-g {
		border: 2px solid $greyColor4;
		padding: 12px 30px;
		box-sizing: border-box;
		color: $primaryColor5;
		&:disabled {
			border: 2px solid $greyColor2 !important;
			background: $greyColor2;
			color: $greyColor4 !important;
			cursor: not-allowed;
			box-shadow: none;
			&:hover {
				box-shadow: none;
			}
			border: 2px solid $greyColor2;
		}
	}
	&.loading {
		background: $secondaryGradient1;
		cursor: not-allowed;
		&:hover {
			box-shadow: none;
		}
	}

	.loader {
		border: 2px solid $greyColor2;
		border-top: 2px solid transparent;
		border-radius: $loaderSize/2;
		width: $loaderSize;
		height: $loaderSize;
		animation: spin 1s linear infinite;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}
.disablebutton {
	opacity: 0.5;
    box-shadow: 0px 5px 10px rgb(220 224 224 / 25%);
    cursor: not-allowed !important;
	pointer-events: none
}
