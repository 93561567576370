@import '../../../../../../constants/variables';

.empDocument {
	overflow: scroll;
	// height: 649px;
	&::-webkit-scrollbar {
		width: 3px;
	}
	.empDocumentRow {
		background: $neutral-white;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 32px;
		margin-bottom: 24px;

		&:last-child {
			margin-bottom: 20px;
		}

		.label {
			font-family: $font-family-medium;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 20px;
			padding: 0px 0px 16px 0px;
			color: $blue-500;
		}

		.date_picker_doc_profile {
			.form_date_field_label {
				font-family: 'Montserrat-Medium';
				font-style: normal;
				font-weight: 500;
				font-size: 10px;
				line-height: 12px;
				padding: 0px 0px 8px 0px;
				color: #181919;
			}

			input {
				padding: 8px 12px;
				border: 1px solid #dfe1e6;
				border-radius: 8px;
				height: 32px;
				width: 100%;
				font-family: 'Montserrat-Medium';
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 14px;
				color: $grayscale-900;

				&::placeholder {
					font-family: 'Montserrat-Medium';
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					color: $grayscale-500;
				}

				&:focus-within {
					border-color: $blue-100;
				}
			}
		}

		.inputBox {
			margin-bottom: 8px;

			&:last-child {
				margin-bottom: 0px;
			}
		}
	}

	.dragger_emp_profile {
		margin-left: 32px;
	}

	.dragger_emp_profile2 {
		margin-left: 8px;
	}

	.subButtonRow {
		padding: 5px 18px 5px 0px;
		.cancelButton {
			font-family: 'Montserrat-Bold';
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 18px;
			text-align: center;
			background: #f7f8fa;
			color: #484b52;
			margin-top: 0px;
			box-shadow: none;
			border: 1px solid #dfe1e6;

			&:disabled {
				background-color: #ffffff !important;
				font-family: 'Montserrat-Bold';
				font-style: normal;
				font-weight: 700;
				font-size: 16px;
				line-height: 18px;
				text-align: center;
				color: #dfe1e6;
				margin-top: 0px;
				box-shadow: none;
				border: 1px solid #dfe1e6;
			}
		}

		.saveButton {
			font-family: 'Montserrat-Medium';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 20px;
		}
	}

	.dlabel {
		margin-bottom: 16px;
	}

	.previewContainer {
		.thumbnail {
			height: 172px;
			width: 100%;
			border-radius: 16px;
			object-fit: cover;
		}

		.thumbnailPdf {
			height: 172px;
			width: 100%;
			border-radius: 16px;
			border: 2px dashed #dfe1e6;
			display: 'flex !important ';
			justify-content: 'center !important ';
		}

		.buttonRow {
			display: flex;
			justify-content: center;
			position: relative;
			top: -48px;
			left: 1px;

			.buttonContainer {
				display: flex;
				gap: 12px;

				.buttonImage {
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					height: 40px;
					width: 40px;
					background: #ffffff;
					box-shadow: 0px 8px 24px -12px #bdbdbd;
					border-radius: 8px;

					img {
						width: 24px;
						height: 24px;
					}
				}
			}
		}
	}

	.ant-upload-list-text {
		display: none;
	}
	.draggerdoccontainer {
		border: 2px dashed #eff1f5;
		border-radius: 8px;
		width: 100%;
		height: 172px;
		padding: 24px;

		.ant-upload-list .ant-upload-list-text {
			display: none;
		}
		.ant-upload-btn {
			padding: 0px;
		}
		.ant-upload-drag-container {
			position: relative;
			top: -3px;
			left: -2px;
		}

		.dragcontent {
			font-family: Montserrat-bold;
			color: #00344e;
			font-size: 14px;
		}

		.forClickHere {
			font-family: Montserrat-regular;
			color: #00344e;
			font-size: 12px;
			margin-top: 8px;
		}
	}
	.showRequired {
		font-family: 'Montserrat-Light';
		font-style: normal;
		font-weight: 300;
		font-size: 10px;
		line-height: 12px;
		color: $feedbackError;
		margin: 8px 0px 0px 0px;
	}
}

.emp_doc_loader {
	display: flex;
	width: 80%;
	height: 500px;
	align-content: center;
	justify-content: center;
}
