@import '../../../constants/_variables.scss';

.slider-screen-header {
	padding-top: 40px;
	padding-left: 80px;
}

.slider_screen_main_container {
	width: 100%;
	height: 85vh;
	display: flex;
	justify-content: center;
	align-items: center;
	.carousel_view {
		.button_view {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			column-gap: 24px;
			button {
				width: fit-content;
				font-family: 'Montserrat-Medium';
				font-size: 16px;
				font-weight: 500;
			}
		}
	}
	.bubbles_left {
		.bubbles {
			height: 10%;
			display: flex;
			justify-content: space-evenly;
			align-items: center;
		}
		.circle_effect {
			height: 90%;
			display: flex;
			justify-content: flex-start;
			align-items: flex-end;
			.ellipse {
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
				.ellipse_32 {
					padding-bottom: 100%;
					margin-left: 33%;
				}
			}
		}
	}
	.slider_container {
		display: flex;
		flex-direction: column;
	}
	.slider_view {
		height: 60vh;
		color: #000;
		line-height: 10px;
		text-align: center;
		// padding-top: 20%;
		.slider {
			display: flex;
			justify-content: center;
			font-size: 14px;
			font-family: Montserrat;
			font-style: normal;
			font-weight: 300;
			line-height: 16px;
			color: $greyColor6;
			padding-bottom: 1%;
			height: 74%;
			img {
				object-fit: contain;
				width: 100%;
				height: 100%;
			}
			.minititle {
				//styleName: Paragraph/Medium/Medium;
				font-family: Montserrat-medium;
				font-size: 12px;
				font-weight: 500;
				line-height: 14px;
				letter-spacing: 0em;
				text-align: center;
				color: #f75448;
				margin-bottom: 8px;
			}
			.subtitle {
				//styleName: Mont_H3 Bold 20pt;
				font-family: Montserrat-bold;
				font-size: 20px;
				font-weight: 700;
				line-height: 24px;
				letter-spacing: 0em;
				text-align: center;
				color: #00344e;
			}
		}
		.slider_head {
			display: flex;
			justify-content: center;
			padding-bottom: 2%;
			span {
				color: $primaryColor5;
				font-size: 19pt;
				font-weight: bolder;
				line-height: 20pt;
			}
		}
	}
	.bubbles_right {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		overflow: hidden;
		.bubble_ellipse {
			display: flex;
			justify-content: flex-end;
		}
		.bubbles_right_circle {
			display: flex;
			justify-content: flex-end;
			padding-right: 10%;
		}
		.bubbles_right_first {
			display: flex;
			align-items: flex-end;
			.circle {
				padding-left: 64%;
			}
		}
	}
}

.ant-carousel {
	.slick-slider {
		.slick-dots-bottom {
			li {
				width: fit-content;
				margin: 0 10px;
			}
		}
	}
}
@media only screen and (max-width: 600px) {
	.circle_effect {
		display: none !important;
	}
	.bubbles_left {
		display: flex;
		align-items: flex-end;
		height: 5%;
		.bubbles {
			padding-left: 13%;
		}
	}

	.main_container {
		padding: 6%;
		.carousel_view {
			.button_view {
				padding: 5% 5% 0% 5%;
				margin-left: 25%;
				button {
					font-size: 13pt;
				}
			}
		}
	}
	.main_container .slider_view {
		padding-top: 2%;
		height: 69vh;
	}
	.main_container .slider_view .slider_head {
		font-size: 23px;
		line-height: 28px;
	}
	.main_container .slider_view .slider {
		height: 73%;
	}
	.ant-carousel .slick-dots-bottom {
		bottom: 4px;
	}
	.bubbles_right {
		display: none !important;
	}
	.button_next {
		width: 20%;
	}
}
@media only screen and (max-width: 350px) {
	.laptop_img {
		width: 90%;
	}
}
@media only screen and (max-width: 750px) {
	.bubbles_right {
		display: none !important;
	}
	.circle_effect {
		display: none !important;
	}
}
.ant-carousel .slick-dots li button {
	height: 0.3rem;
	width: 16%;
	border-radius: 30px;
	background: $black;
	border: 4px solid $black;
	border-radius: 87px;
}
.ant-carousel .slick-dots li.slick-active button {
	background: red;
	opacity: 1;
	height: 0.3rem;
	border: 4px solid red;
	border-radius: 87px;
	width: 16px;
}

.slider-background-image {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}
