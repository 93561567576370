.run-payroll-new-top {
	width: 100%;
	height: 100%;
	padding: 0 30px;
	.runPayroll-New-search {
		border-radius: 8px !important;
	}

	.run-payroll-new-table {
		.ant-table-thead > tr > th {
			background-color: #ea6257;
			color: #ffffff;
		}
		.ant-table-container {
			.ant-table-body {
				max-height: 284.5px;
				overflow: auto scroll;
			}
		}
		.ant-table-container {
			table > thead > tr:first-child th:first-child {
				display: table-cell;
				text-align: center !important;
			}
			.ant-table-body {
				max-height: 284.5px !important;
				overflow: auto scroll;
			}
		}
	}
	.run-payroll-clearAll {
		.run-payroll-clearAll-bottomOne {
			cursor: pointer !important;
		}
		.run-payroll-clearAll-bottomTwo {
			cursor: pointer !important;
		}
	}
	.runpayroll-previous {
		position: relative;
		.prev-payroll-text-container {
			margin: 2% 0px;
		}
		.prev-payroll-text {
			font-family: Montserrat-medium;
			font-size: 16px;
			color: #00344e;
		}
		.paper-box-container {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 10px;

			height: 206px;
		}

		.icon-box-container {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			margin-bottom: 3%;
			.icon-box1 {
				height: 32px;
				width: 32px;
				border: 1px solid #dfe1e6;
				box-sizing: border-box;
				border-radius: 8px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: white;
			}
			.icon-box1-nonactive {
				height: 32px;
				width: 32px;
				border: 1px solid #dfe1e6;
				box-sizing: border-box;
				border-radius: 8px;
				display: flex;
				justify-content: center;
				align-items: center;
				opacity: 0.5;
				background-color: transparent;
			}
			.icon-box2-nonactive {
				height: 32px;
				width: 32px;
				border: 1px solid #dfe1e6;
				box-sizing: border-box;
				border-radius: 8px;
				display: flex;
				justify-content: center;
				align-items: center;
				opacity: 0.5;
				margin-left: 10px;
				background-color: transparent;
			}
			.icon-box2 {
				height: 32px;
				width: 32px;
				border: 1px solid #dfe1e6;
				box-sizing: border-box;
				border-radius: 8px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: 10px;
				background-color: white;
			}
			.icon-pagination {
				color: #00344e;
				font-size: 16px;
			}
		}
		.orange-box {
			height: 60px;
			width: 100%;
			background-color: #ea6257;
			border-radius: 16px;
			display: flex;
			justify-content: center;
			.upicon {
				margin-top: 8px;
				width: 25px;
				height: 25.03px;
			}
		}
		.white-box {
			height: 78px;
			width: 100%;
			border-radius: 16px;
			box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
			background-color: white;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			bottom: 16px;

			.show-payroll-text {
				font-family: Montserrat-medium;
				font-size: 16px;
				color: #00344e;
			}
		}
		.paper-box {
			height: 206px;
			width: 30%;
			border: 1px solid #dfe1e6;
			box-sizing: border-box;
			border-radius: 16px;
			padding: 20px;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			animation: slide 0.5s ease-in;
			position: relative;
			animation-direction: normal;
			@keyframes slide {
				0% {
					left: 300px;
					// top: 0px;
				}

				100% {
					left: 0px;
					// top: 0px;
				}
			}
			.payroll-card-text1 {
				font-family: Montserrat-bold;
				font-size: 20px;
				color: #00344e;
			}
			.payroll-card-text2 {
				font-family: Montserrat-medium;
				font-size: 14px;
				color: #2b769c;
			}
			.payroll-card-text3 {
				font-family: Montserrat-regular;
				font-size: 12px;
				color: #484b52;
			}
			.payroll-card-text4 {
				font-family: Montserrat-medium;
				font-size: 12px;
				color: #484b52;
			}
			.line {
				border-bottom: 1px solid #dfe1e6;
			}
			.aed-container {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.payroll-card-button {
					width: auto;
					height: 32px;
					border-radius: 22px;
					border: 1px solid #71b0d0;
					background-color: white;
					padding: 0px 15px;
				}
			}
			.cards-accounts {
				display: flex;
				flex-direction: row;
				align-items: center;
				.vertical-line {
					width: 2px;
					background-color: #484b52;
					height: 11px;
					margin: 0px 4px;
					opacity: 0.5;
				}
			}
		}
	}
	.edenRedSwitch.ant-switch-checked {
		background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%);
	}
	.run-payroll-new-pagination {
		margin-top: 15px;
		display: flex;
		justify-content: flex-end;
	}
	.run-payroll-new {
		.run-payroll-typo {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			line-height: 28px;
			color: #00344e;
		}
		.run-payroll-filters-popup {
			min-height: 64px;
			background: #ffffff;
			width: 74.5%;
			box-shadow: 0px 10px 20px rgb(174 182 183 / 50%);
			border-radius: 16px;
			position: absolute;
			z-index: 10;
			padding: 15px;
			.run-payroll-new-filters-container {
				display: flex;
				justify-content: space-evenly;
				width: 100%;
				.run-payroll-new-filters-inside {
					display: flex;
					justify-content: flex-end;
				}
				.run-payroll-filter-tiles {
					min-width: 100px;
					width: 96%;
					display: flex;
					justify-content: space-between;
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					min-height: 32px;
					color: #00344e;
					margin-bottom: 10px;
				}
			}
			.run-payroll-clearAll {
				display: flex;
				justify-content: flex-end;
				padding-top: 5px;
				.run-payroll-clearAll-bottomOne {
					padding-right: 15px;
					color: #00344e;
				}
				.run-payroll-clearAll-bottomTwo {
					color: #00344e;
				}
			}
		}
		.run-payroll-new-footer {
			position: absolute;
			height: auto;
			width: 100%;
			bottom: 0;
			.bottom-bar {
				display: flex;
				justify-content: space-between;
				height: 100px;
				width: 100%;
				background: #f7f8fa;
				border-radius: 28px 28px 0 0;
				.run-payroll-new-amount {
					display: flex;
					flex-direction: column;
					justify-content: center;

					.run-payroll-new-value {
						margin-top: 5px;
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 18px;
						color: #484b52;
					}
				}
				.run-payroll-new-buttons {
					display: flex;
					align-items: center;
					justify-content: center;
					.button-payrollNew {
						margin-right: 15px;
					}
					.run-payroll-newUpload {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 20px;
						text-align: center;
						color: #484b52;
						width: 303px;
						height: 48px;
					}
					.run-payroll-newNext {
						width: 162px;
						height: 52px;
					}
				}
			}
		}
	}
	.run-payroll-filters {
		display: flex;
		justify-content: space-between;
		margin: 16px 0;
		.run-payroll-filterButton {
			width: 103px;
			height: 40px;
			border-radius: 8px;
			min-height: 40px !important;
			margin-right: 10px;
		}
		.edenRedSearch {
			width: 60%;
		}
		.run-payroll-previous {
			height: 40px;
			width: 300px;
			border-radius: 8px;
			min-height: 40px !important;
			margin-left: 10px;
			.run-payroll-down {
				margin-left: 8px;
			}
		}
	}
}

.run-payroll-dropdownMenus {
	border-radius: 24px;
}

.run-payroll-new-dropdown {
	min-height: 32px !important;
	height: 32px !important;
	width: auto;
	.run-payroll-new-dropdownIcon {
		height: 27px;
	}
}


@media (max-width: 1200px) {
	.run-payroll-newUpload,
	.run-payroll-newNext {
		width: 100% !important;
	}
}
@media (max-width: 768px) {
	.run-payroll-new-amount {
		align-items: center;
		padding: 5px 0 5px 0;
	}
}
