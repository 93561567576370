@import '../../../constants/_variables.scss';
.create_password__container {
	.left_area {
		height: 100vh;
		padding-left: 8%;
		.create_password__header {
			span {
				font-size: 28pt;
				line-height: 32pt;
				color: $primaryColor5;
			}
		}
		.logo_row {
			height: 35%;
			.logo {
				padding-top: 4%;
			}
		}
		.form_label {
			padding-top: 7%;
			.ant-form-item {
				margin-bottom: 0;
			}
			.validation {
				display: flex;
				flex-direction: column;
				padding-top: 1%;
				padding-bottom: 4%;
				.validation_principle {
					padding-top: 2%;
				}
				.line_through {
					text-decoration: line-through;
					color: $greyColor3;
					span {
						color: $greyColor3 !important;
					}
				}
				span {
					font-weight: 300;
					line-height: 10pt;
					color: $greyColor7;
				}
			}
		}
	}
	.right_area_container {
		height: 100vh;
		.right_area {
			height: 100%;
			.we_brand,
			.are_new {
				background-color: $greyColor1;
				padding: 8% 10px 10px 10px;
				.we_brand_text,
				.are_new_text {
					text-align: right;
					span {
						font-size: 80px;
						font-weight: bolder;
						line-height: 86px;
						color: $secondaryColor1;
					}
				}
				.laptop_img {
					background: none;
					width: 140%;
					margin-left: 40%;
				}
			}
			.are_new {
				background-color: $secondaryColor1;
				position: inherit;
				.are_new_text {
					text-align: left;
					span {
						color: $greyColor1;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.create_password__container {
		.left_area {
			padding: 3%;
			.create_password__header {
				padding-right: 2.5%;
				span {
					font-size: 16pt;
					line-height: 20pt;
					color: $primaryColor5;
				}
			}
			.logo_row {
				height: 12%;
				.logo {
					padding-top: 4%;
				}
			}
			.form_label {
				padding-top: 4%;
			}
		}
		.right_area_container {
			display: none;
		}
	}
}
