@import '../../../constants/variables';
.edenred-table {
	border-collapse: collapse;
	width: 100%;
	max-width: 100%;
	overflow: scroll;
	thead {
		tr {
			th {
				background: none;
			}
		}
	}
	th,
	td {
		// padding: 8px;
		padding: 10px 16px 10px 16px !important;
	}
	td {
		place-content: center;
	}
	tr {
		cursor: pointer;
		transition: all 0.3s ease;
		border-bottom: 1px solid $white;
		font-size: 12px;
		line-height: 14px;
		background: #fbfcfd;
		&:hover {
			background-color: $white-2;
			td {
				background-color: $white-2;
			}
		}
	}
	th {
		// padding-top: 12px;
		// padding-bottom: 12px;
		padding: 12px 16px;
		text-align: left;
		color: $darkblue;
	}

	.sort {
		color: $neutral-white;
	}
	.table_loading {
		border: 2px solid #484b52;
		border-top: 2px solid transparent;
		border-radius: 10px;
		width: 20px;
		height: 20px;
		animation: spin 1s linear infinite;
	}
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@mixin properties_for_sticky {
		position: sticky;
		z-index: 2;
		background: $grayscale-50;
		left: 0;

		&.index_1 {
			left: 0;
			min-width: 68px;
			max-width: 68px;
		}
		&.index_2 {
			min-width: 170px;
			max-width: 170px;
			left: 68px;
		}
		&.index_3 {
			min-width: 130px;
			max-width: 130px;
			left: 238px;
		}
		&.index_4 {
			min-width: 130px;
			max-width: 130px;
			left: 368px;
		}
	}
	.sticky_table_column {
		@include properties_for_sticky;
	}
	.sticky_table_header {
		@include properties_for_sticky;
	}

	// .sticky_table_column,
	// .sticky_table_header {
	// 	position: sticky;
	// 	z-index: 1;
	// 	background: $neutral-white;
	// 	left: 0;
	// }
}
