.setupEmpDocs {
	display: flex;
	// padding-left: 240px;
	// padding-right: 240px;
	background: #f7f8fa;
	justify-content: center;
}

.setup-payroll {
	height: 100%;
	.employeeListTable {
		// padding: ;
		display: flex;
		padding: 32px 0px 0px 0px;
		justify-content: center;
		// height: 0px;
		.bulk-employee-detail-container {
			padding: 0px;
			width: 960px;
		}
	}
}
