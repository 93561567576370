@import '../../constants/variables';

.edenred-terms-and-condition-container {
	background: #f7f8fa;
	display: flex;
	justify-content: center;
	padding-top: 40px;
	.terms-and-conditions {
		max-width: 1120px;
		width: 100%;
	}
	.header-container {
		display: flex;
		justify-content: space-between;
		margin-bottom: 24px;

		.header-buttons {
			display: flex;
			align-items: center;
			column-gap: 8px;
			.select-btn {
				cursor: pointer;
			}
		}
	}

	.main {
		background-color: #ffff;
		padding: 32px;
		border-radius: 8px;
	}
}
