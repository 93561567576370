.edenredTypo {
	font-family: 'Montserrat-Regular';
	&.thin {
		font-family: 'Montserrat-Thin';
	}
	&.regular {
		font-family: 'Montserrat-Regular';
	}
	&.medium {
		font-family: 'Montserrat-Medium';
	}
	&.bold {
		font-family: 'Montserrat-Bold';
	}
	&.h1 {
		font-size: 32pt;
	}
	&.h2 {
		font-size: 24pt;
	}
	&.h3 {
		font-size: 20pt;
	}
	&.h4 {
		font-size: 18pt;
	}
	&.h5 {
		font-size: 16pt;
	}
	&.b1 {
		font-size: 14pt;
	}
	&.b2 {
		font-size: 12pt;
	}
	&.b3 {
		font-size: 10pt;
	}
	&.extra-small {
		font-size: 12px;
	}
	&.title-color {
		color: #00344e;
	}
}
