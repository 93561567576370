.text5 {
	margin-top: 5px;
	font-family: Montserrat-Regular;
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 14px;
	color: #2b769c;
	text-decoration: underline;
}

.overlapping-text-container {
	padding-left: 10px;
	display: flex;
	flex-direction: row;

	.header-and-department-container {
		display: flex;
		flex-direction: column;
		width: 50%;
	}

	.close-icon-container {
		display: flex;
		width: 50%;
		flex-direction: row;
		justify-content: flex-end;
		align-items: flex-start;

		.close-icon-style {
			height: 24px;
			width: 24px;
			margin-top: 10px;
			cursor: pointer;
		}
	}
}

.leave-status-overlapping-container {
	width: fit-content !important;

	.overlapping-modal-web {
		max-width: 100% !important;
	}

	.ant-modal-body {
		height: unset !important;
		width: 100%;
	}

	.ant-modal-content {
		border-radius: 16px;
	}

	.anticon svg {
		color: #00344e;
		height: 17.33px;
		width: 17.33px;
		top: 7.33px;
		left: 7.33px;
	}
}

.ant-table-content {
	padding-top: 15px;
}

.overlapping-table {
	width: 100% !important;

	.ant-table {
		width: 100%;
	}

	.ant-table-body {
		box-shadow: none;
	}

	.ant-table-thead {
		th {
			color: #00344e;
			font-family: montserrat-Bold;
			font-weight: 700;
			font-style: normal;
			font-size: 12px;
			line-height: 14px;
		}
	}

	.ant-table-header {
		background-color: #ffffff;
	}

	.ant-table-cell {
		justify-content: space-between !important;
	}
}

.overlapping-text-header {
	color: #00344e;
	margin-top: 10px;
}

.overlapping-sub-text {
	font-family: Montserrat-Regular;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 18px;
	margin-top: 7px;
	color: #484b52;
}

.table-modificaton {
	display: flex;
	margin-top: 20px;
	padding-left: 10px;
	overflow-x: hidden;
	overflow-y: visible;
	align-items: flex-start;
	width: 100%;

	.edenred-table th {
		font-family: Montserrat-Medium;
		color: #00344e;
		font-weight: 500;
		font-style: normal;
		font-size: 12px !important;
		line-height: 14px !important;
		background: #ffffff;
		margin-bottom: 0;
		padding: 17px 16px;
	}

	.edenred-table td {
		padding: 17px 16px;
	}
}

.overlapping-highlighted-text {
	color: #00344e;
	background: transparent;
	margin-bottom: 0;
}

.overlapping-text {
	color: #181919;
	background: transparent;
	margin-bottom: 0;
}

.table-section {
	display: flex;
	border-top: 1px solid #f1f5ff;
	margin-top: 15px;

	.loader-style {
		display: flex;
		margin-top: 85px;
		margin-left: 50%;
	}
}

.overlapping-name-sub-text {
	font-family: Montserrat-Regular;
	color: #484b52;
	font-weight: 300;
	font-style: normal;
	font-size: 12px;
	line-height: 14px;
	background: #ffffff;
	margin-bottom: 0;
}

@media screen and (min-width: 800px) {
	.overlaping-card-mobile-main,
	.divider-mob {
		display: none;
	}
}

@media screen and (max-width: 800px) {
	.view-submited-proof-custom {
		.ant-modal-body {
			width: 100% !important;
		}
	}

	.view-submited-row-popup {
		width: 100% !important;
		justify-content: center !important;
	}

	.view-sub-col1 {
		min-width: 80% !important;
		width: 80% !important;
	}

	.view-sub-col2 {
		width: 80% !important;
	}

	.leave-status-overlapping-container {
		width: fit-content !important;

		.ant-modal-content {
			width: 100% !important;
			overflow: hidden;
		}

		.ant-modal-body {
			padding: 16px 5px 16px 16px !important;
			height: 75vh !important;
		}
	}

	.type-overlaping-text {
		color: #2b769c !important;
		font-size: 12px !important;
	}

	.overlapping-table {
		display: none;
	}

	.overlap-text-inner-date {
		font-family: 'Montserrat-Regular' !important;
		font-style: normal;
		font-weight: 300;
		font-size: 12px;
		color: #000000;
		padding-left: 5px;
	}

	.overlaping-card-mobile-main {
		overflow-y: scroll;
		height: 56vh;
	}

	.overlaping-card-mobile-main::-webkit-scrollbar {
		width: 5px !important;
		color: #9a9ea6;
		border-radius: 2px;
	}
}

@media screen and (max-width: 400px) {
	.view-sub-col1 {
		min-width: 70% !important;
		width: 70% !important;
	}

	.view-sub-col2 {
		width: 70% !important;
	}
}

.leave-modal {
	display: flex;
	/* Hidden by default */
	position: fixed;
	/* Stay in place */
	z-index: 5;
	/* Sit on top */
	justify-content: flex-end;
	align-items: flex-end;
	left: 0;
	top: 0;
	width: 100%;
	/* Full width */
	height: 100%;
	/* Full height */
	overflow: auto;
	/* Enable scroll if needed */
	background-color: rgb(0, 0, 0);
	/* Fallback color */
	background-color: rgba(0, 0, 0, 0.4);
	&::-webkit-scrollbar {
		width: 3px;
	}

	/* Black w/ opacity */
	.modal-content {
		position: relative;
		background-color: white;
		margin: 0;
		padding: 32px;
		border: 1px solid #888;
		height: 100%;
		width: 80%;
		-webkit-animation-name: animatetop;
		-webkit-animation-duration: 0.4s;
		animation-name: animatetop;
		animation-duration: 0.4s;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			width: 3px;
		}
	}
}

@-webkit-keyframes animatetop {
	from {
		top: -300px;
		opacity: 0;
	}

	to {
		top: 0;
		opacity: 1;
	}
}

@keyframes animatetop {
	from {
		right: -300px;
		opacity: 0;
	}

	to {
		right: 0;
		opacity: 1;
	}
}
