@import '../../../constants/variables';
root {
	--form-control-color: rebeccapurple;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

body {
	margin: 0;
}

.title-holder {
	display: flex;
	flex-direction: row;
	height: fit-content;
	width: fit-content;
}
.radio-button-container {
	display: flex;
	flex-direction: row;
	height: fit-content;
	width: fit-content;
	gap: 32px;
	margin-top: 8px;
	.radio {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	form {
		display: grid;
		place-content: center;
		min-height: 100vh;
	}

	.form-control {
		display: flex;
		flex-direction: row;
		padding-left: 8px;
	}

	.form-control + .form-control {
		margin-top: 1em;
	}

	.form-control:focus-within {
		color: var(--form-control-color);
	}

	input[type='radio'] {
		/* Add if not using autoprefixer */
		-webkit-appearance: none;
		/* Remove most all native input styles */
		appearance: none;
		/* For iOS < 15 */
		background-color: var(--form-background);
		/* Not removed via appearance */
		margin: 0;

		font: inherit;
		background-color: #ffebe9;
		width: 22px;
		height: 22px;
		cursor: pointer;
		border-radius: 50%;
		transform: translateY(-0.075em);

		display: grid;
		place-content: center;
	}

	input[type='radio']::before {
		content: '';
		width: 10px;
		height: 10px;
		border-radius: 50%;
		transform: scale(0);
		transition: 120ms transform ease-in-out;
		box-shadow: inset 1em 1em var(--form-control-color);
		/* Windows High Contrast Mode */
		background: $red-gradient-light;
	}

	input[type='radio']:checked::before {
		transform: scale(1);
	}
}
