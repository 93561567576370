.teampayroll {
	.ant-card-body {
		// padding: 0px 0px 0px 30px;
		.ant-row {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
		}
	}
}
.teampayroll_Card {
	display: flex;
	display: flex;
	border: 1px solid #eedcba;
	border-radius: 8px;
	padding: 10px 11px;
	.image {
		width: 35px;
		height: 35px;
	}
	.teampayroll_header {
		padding: 10px 0px 0px 10px;
		.text2 {
			color: #00344e;
			font-family: Montserrat-light;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
		}

		.text1 {
			font-family: 'Montserrat-Regular';
			font-size: 14px;
			font-weight: bold;
			line-height: 18px;
			color: #4991b6;
			text-decoration: underline;
			cursor: pointer;
		}
	}
}
