.main-title-container {
	padding-bottom: 15px;
	padding-top: 10px;
}
.Main-title-leave-status {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: bold;
	font-size: 20px !important;
	color: #00344e !important;
}
.title-leave-status {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: bold;
	font-size: 16px !important;
	color: #00344e !important;
}
.leave-status-name {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500 !important;
	font-size: 14px !important;
	color: #00344e !important;
}
.Parent-leave-status-container {
	.ant-carousel .slick-slider .slick-track,
	.ant-carousel .slick-slider .slick-list {
		box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
		border-radius: 16px;
	}
	.ant-carousel .slick-list .slick-slide.slick-active {
		// padding: 15px;
		height: max-content;
	}
}
.inner-row-leave-status {
	padding: 10px 10px 0px 15px;
	&:last-child {
		.ant-divider-horizontal {
			border-color: white;
		}
	}
}
.mob-sub-text-leave {
	color: #000000 !important;
	font-family: 'Montserrat';
	font-size: 12px !important;
	font-weight: 300 !important;
}
.subtext-leave-status {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 300;
	font-size: 12px !important;
	color: #000000 !important;
	opacity: 0.4;
}
.span-leavereason {
	color: #4991b6 !important;
	font-size: 12px !important;
	font-weight: 500 !important;
	font-family: 'Montserrat-Medium' !important;
}
.main-class-leavestatus {
	padding: 40px 20px 80px 20px;
}
.carosuel-card-leave {
	padding: 10px 0px;
}
@media screen and (min-width: 800px) {
	.main-class-leavestatus {
		display: none;
	}
}
