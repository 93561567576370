@import '../../../constants/variables';

.manage_team {
	padding: 0px 40px 0px 40px;
	align-self: flex-start;
	.manage_team_header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 40px 0px 24px 0px;
		background: $grayscale-200;
		position: sticky;
		top: 0;
		z-index: 1;
		.team_header {
			display: flex;
			flex-direction: column;
			width: 75%;
			span:nth-child(1) {
				color: $primaryColor5;
			}
			span:nth-child(2) {
				margin-top: 8px;
				color: $grayscale-700;
			}
		}
		.manage_team_header_description {
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			grid-gap: 8px;
			gap: 8px;
			align-items: flex-start;
			height: 100%;
			-ms-flex-pack: justify;
			justify-content: space-between;
		}
	}

	.new_design_table_role {
		background: $neutral-white;
		box-shadow: 0px 16px 32px -24px $plaincolor;
		border-radius: 8px;
		padding: 32px;
		margin-bottom: 40px;
		width: 100%;
		.new_design_table_header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.new_design_entries {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 6px;
				.multi-select-dropdown-component {
					width: 60px;
					.multiSelect-dropdown-container {
						.dropdown_header {
							.dropdown_inner {
								height: 32px;
								position: relative;
								border: 1px solid #dfe1e6 !important;
								.dropDown-menu {
									margin: 0 0 0 -8px;
									.dropdown_default {
										background: none;
										.dropdown_inner_list_main {
											font-family: 'Montserrat-Medium';
											color: #9a9ea6;
										}
									}
								}
								.dropdown_arrow {
									position: absolute;
									right: 8px;
									.arrow-inner {
										height: 15px;
										width: 15px;
									}
								}
							}
							.dropdown_list {
								margin: 0px;
								.dropdown_inner_list {
									div {
										justify-content: center;
										label {
											padding: 0px !important;
											span {
												div:nth-child(1) {
													width: 14px !important;
													height: 14px !important;
													border-width: 1px !important;
													border-radius: 4px !important;
												}
											}
										}
									}
								}
							}
						}
					}
				}
				p {
					font-family: 'Montserrat-Light';
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					color: #484b52;
					margin: 0px;
				}
			}
			.new_design_search_filter {
				display: flex;
				align-items: center;
				gap: 10px;
				.new_design_search {
					width: 100%;
					.new_design_input {
						img {
							margin-right: 10px;
						}
					}
				}
				.new_design_filter {
					.main_filter_button .filter_main {
						right: 0;
					}
				}
			}
		}
		.new_design_pagination_bar {
			margin-top: 16px;
			margin-bottom: 0px;
			display: flex;
			justify-content: flex-end;
			.pagination-container {
				padding: 0px;
				margin: 0px;
			}
		}
	}
	.manage_team_table_header {
		display: flex;
		flex-direction: row;
		border-bottom-color: #dfe1e6;
		margin-top: 16px;
		.edenred-table thead tr th {
			font-family: 'Montserrat-Medium';
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 14px;
			color: #00344e;
			padding: 17px 16px;
		}
		.edenred-table tbody tr td {
			padding: 17px 16px;
		}
		.table-input-1 {
			font-family: 'Montserrat-Light';
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
			color: #181919;
			P {
				margin: 0px;
			}
		}
		.table-input {
			display: flex;
			align-items: center;
			gap: 5px;
			margin: 5px 0 5px 0;
			p {
				font-family: 'Montserrat-Medium';
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				text-decoration-line: underline;
				color: #0e5274;
				margin: 0px;
			}
		}
	}
	.new_design_pagination {
		display: flex;
		align-items: center;
	}
	.manage_team_users_list {
		overflow-y: scroll;
		.manage_team_user_card_row {
			cursor: pointer;
			.manage_team_user_card {
				background: $greyColor1;
				width: 100%;
				height: 80px;
				min-height: 80px;
				padding: 4px;
				&.ant-card-bordered:last-child {
					border-bottom-color: #dfe1e6;
				}
				&.ant-card-bordered {
					border-radius: unset;
					border-bottom-color: white;
					border-left-color: white;
					border-right-color: white;
					border-top-color: #dfe1e6;
					border-width: 1px;
				}
				.ant-card-body {
					height: 100%;
					color: #00344e;
				}
			}
		}
	}
	.invite_team_member_btn_section {
		width: 100%;
		padding: 12px 0px 80px 1px;
		display: block;
		justify-content: left;
		margin-top: 10px;
		.invite_team_member_btn {
			button {
				width: 340px;
				min-width: 340px;
				min-height: 48px;
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
			}
		}
		.cancel {
			font-family: Montserrat-regular;
			font-size: 14px;
			font-weight: 300, light;
			line-height: 16px;
			letter-spacing: 0em;
			text-align: center;
			border: 1px solid #dfe1e6;
			border-radius: 16px;
			padding: 8px 24px 8px 24px;
			background-color: white;
			margin-top: 25px;
		}
	}
	.new_design_toast {
		position: absolute;
		bottom: 40px;
		left: 50%;
		.toat-outer .toast {
			padding: 10px 16px;
		}
	}
}
.InviteToaster {
	display: flex;
	flex-direction: row;
	.InviteToaster_img {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.content_toaster {
		.content_fst_toaster {
			color: #00344e;
			font-size: 16px;
		}
		.content_second_toaster {
			color: #00344e;
			font-size: 14px;
		}
	}
}
@media only screen and (max-width: 1000px) {
	.manage_team {
		padding: 50px 50px 0px 50px;
	}
}
@media only screen and (max-width: 600px) {
	.manage_team {
		padding: 64px 16px 0px 16px;
		.manage_team_header {
			padding: 12px 0px 4px 0px;
			span {
				font-size: 20px;
				line-height: 24px;
			}
			.manage_team_header_description {
				padding: 4px 0px 12px 0px;
				span {
					color: $greyColor6;
				}
			}
		}
		.manage_team_table_header {
			display: none;
		}
		.manage_team_users_list {
			padding: 4px 0px 12px 1px;
			height: 37vh;
			overflow-y: scroll;
			.manage_team_user_card_row {
				padding: 4px 0px 4px 1px;
				.manage_team_user_card {
					padding: 4px;
					.ant-card-body {
						height: 100%;
						.edenredCard_mobile_view {
							height: 100%;
						}
					}
				}
			}
		}
		.invite_team_member_btn_section {
			padding: 28px 0px 0px 0px;
			.invite_team_member_btn {
				display: flex;
				justify-content: center;
				button {
					width: 234px;
					min-width: 234px;
					border-radius: 90px;
					font-family: Montserrat-Bold;
					font-weight: bold;
					font-size: 16px;
					line-height: 18px;
				}
			}
		}
	}
	.InviteToaster {
		display: flex;
		flex-direction: row;
		.InviteToaster_img {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.content_toaster {
			.content_fst_toaster {
				padding-top: 1%;
				color: #00344e;
				font-size: 14px;
			}
			.content_second_toaster {
				color: #00344e;
				font-size: 12px;
				display: flex;
				align-items: center;
			}
		}
	}
}
.team_permission_popup {
	.ant-modal-content {
		.ant-modal-body {
			height: 165px !important;
			.main_popup_container {
				.message_container {
					padding-top: 25px !important;
				}
			}
		}
	}
}
