.landing_last {
	// justify-content: center;
	background-color: #f7f8fa;
	display: flex;
	height: 100%;
	width: 100%;
	.main-content {
		display: flex;
		align-items: center;
		flex-direction: column;

		// justify-content: center;
		background-color: #f7f8fa;
		padding: 31px 240px 0px 240px;
		.content {
			width: 960px;

			.title-and-navigation-section {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				width: 100%;
				height: fit-content;
			}

			.setup-congratulation {
				display: flex;
				width: 100%;
				height: fit-content;
				flex-direction: column;
				margin-top: 32px;
				.congratulation-cards {
					display: flex;
					width: 100%;
					flex-direction: row;
					height: fit-content;
					margin-top: 32px;
					gap: 16px;
					.congratulation-card {
						display: flex;
						flex-direction: row;
						align-items: center;
						padding: 24px;
						gap: 16px;

						width: 309.33px;
						height: 88px;

						/* Colors/Neutral/neutral-white */

						background: #ffffff;
						border-radius: 8px;
					}
				}
			}

			.setup-section-container {
				display: flex;
				flex-direction: column;
				width: 100%;
				margin-top: 32px;

				.content-section {
					display: flex;
					width: 100%;
					flex-direction: row;
					gap: 16px;
					align-items: center;
					justify-content: center;
					.content-card {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						margin-top: 32px;
						padding: 32px 24px;
						gap: 8px;

						width: 228px;
						height: 172px;

						/* Colors/Neutral/neutral-white */

						background: #ffffff;
						border-radius: 8px;
					}
				}
			}
			.card {
				border: 2px solid #daf0be;
				display: flex;
				// padding: 10px 0px 0px 0px;
				border-radius: 16px;
				background-color: #f7fdf0;
				margin-top: 90px;
				flex-wrap: wrap;
				.image {
					display: flex;
					align-items: center;
					justify-content: center;
					.tick {
						height: 35px;
						padding-left: 10px;
					}
				}
				.box {
					padding-left: 10px;
					padding-top: 10px;
					.setup {
						font-family: Montserrat-medium;
						font-size: 18px;
						font-weight: 500;
						line-height: 20px;
						letter-spacing: 0em;
						text-align: left;
						margin-bottom: 4px;
					}
					.datas {
						font-family: Montserrat-regular;
						font-size: 14px;
						font-weight: 300, light;
						line-height: 16px;
						letter-spacing: 0em;
						text-align: left;
						color: #484b52;
						padding-bottom: 0px;
					}
				}
			}
			.card-red {
				border: 2px solid #eedcba;
				display: flex;
				// padding: 10px 0px 0px 0px;
				border-radius: 16px;
				background: #fffaf0;
				margin-top: 90px;
				flex-wrap: wrap;
				.image {
					display: flex;
					align-items: center;
					justify-content: center;
					.tick {
						height: 35px;
						padding-left: 10px;
					}
				}
				.box {
					padding-left: 10px;
					padding-top: 10px;
					.setup {
						font-family: Montserrat-medium;
						font-size: 18px;
						font-weight: 500;
						line-height: 20px;
						letter-spacing: 0em;
						text-align: left;
						margin-bottom: 4px;
					}
					.datas {
						font-family: Montserrat-regular;
						font-size: 14px;
						font-weight: 300, light;
						line-height: 16px;
						letter-spacing: 0em;
						text-align: left;
						color: #484b52;
						padding-bottom: 0px;
					}
				}
			}
		}
		.portal {
			//styleName: Mont_H2 Bold 24pt;
			font-family: Montserrat-bold;
			font-size: 24px;
			font-weight: 700;
			line-height: 28px;
			letter-spacing: 0em;
			text-align: left;
			color: #00344e;
			margin-bottom: 16px;
			padding-left: 10px;
			margin-top: 56px;
		}
		.time {
			//styleName: Mont_H5 Regular 16pt;
			font-family: Montserrat-regular;
			font-size: 16px;
			font-weight: 300, light;
			line-height: 18px;
			letter-spacing: 0em;
			text-align: left;
			color: #484b52;
			padding-left: 10px;
			margin-bottom: 16px;
		}
		.rolls-card {
			background: #f7f8fa;
			border-radius: 16px;
			padding: 16px 0px 16px 15px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.card-permission {
				display: flex;
				gap: 4px;
				padding-left: 10px;

				.payslip {
					//styleName: Mont_H5 Medium 16pt;
					font-family: Montserrat-medium;
					font-size: 16px;
					font-weight: 500, medium;
					line-height: 18px;
					letter-spacing: 0em;
					text-align: left;
					width: 209px;
					margin-bottom: 0px;
					color: #00344e;
					display: flex;
					align-items: center;
				}
			}
			.leave {
				display: flex;
				gap: 8px;
				padding-left: 10px;
				.managment {
					//styleName: Mont_H5 Medium 16pt;
					font-family: Montserrat-medium;
					font-size: 16px;
					font-weight: 500, medium;
					line-height: 18px;
					letter-spacing: 0em;
					text-align: left;
					width: 158px;
					margin-bottom: 0px;
					color: #00344e;
					display: flex;
					align-items: center;
				}
			}
			.document {
				display: flex;
				gap: 8px;
				padding-left: 10px;
				.managment {
					//styleName: Mont_H5 Medium 16pt;
					font-family: Montserrat-medium;
					font-size: 16px;
					font-weight: 500, medium;
					line-height: 18px;
					letter-spacing: 0em;
					text-align: left;
					width: 198px;
					margin-bottom: 0px;
					color: #00344e;
					display: flex;
					align-items: center;
				}
			}
			.announcments {
				display: flex;
				gap: 9px;
				padding-left: 10px;
				.msg {
					//styleName: Mont_H5 Medium 16pt;
					font-family: Montserrat-medium;
					font-size: 16px;
					font-weight: 500, medium;
					line-height: 18px;
					letter-spacing: 0em;
					text-align: left;
					width: 134px;
					margin-bottom: 0px;
					color: #00344e;
					display: flex;
					align-items: center;
				}
			}
		}
		.continue-setup,
		.dashboard {
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: Montserrat-medium;
			font-size: 18px;
			font-weight: 500;
			line-height: 20px;
			text-align: center;
			margin-top: 32px;
			margin-bottom: 32px;
			width: 93%;
		}
		.continue-setup {
			color: #484b52;
			border-radius: 28px;
			padding: 25px 40px 25px 40px;
		}
		.dashboard {
			color: #ffffff;
			background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%);
			box-shadow: 0px 10px 20px 0px #e9b4afbf;
			border-radius: 28px;
			border: none;
			outline: none;
			padding: 25px 32px 25px 32px;
		}
	}
}
.setUp_status_load {
	height: 100%;
	align-items: center;
	justify-content: center;
}
