.image_slider_main {
	height: 100%;
	.slider-carousel {
		height: 100%;
		overflow: hidden;
		.slider {
			grid-column-gap: 0px;
			column-gap: 0px;
		}
	}
	.slider-container {
		height: 100%;
		.image_slider {
			display: flex;
			align-items: center;
			border-radius: 5px;
			.main_carousel {
				.mfa-img {
					width: 100%;
					border-radius: 5px;
					height: 100%;
				}
			}
		}
		.prev-button,
		.next-button {
			position: relative;
			bottom: 20px;
		}
		.left-img,
		.right-img {
			opacity: 0.5;
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
			padding: 7px;
			img {
				width: 35px;
				height: 35px;
			}
		}

		.left-img:hover,
		.right-img:hover {
			opacity: 1;
		}

		.left-img {
			top: 37%;
			left: 50px;
		}
		.right-img {
			top: 37%;
			right: 50px;
		}
	}
}
