@import '../../../constants/_variables.scss';

.page-wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: $white-2;
	align-items: center;
	.main-container {
		padding: 0;
		@media screen and (min-width: 1400px) {
			padding: 0 200px;
		}
	}
}
.approve-inner-div {
	padding: 40px 0px 26px 0px;
}
.hr_upload_preview {
	margin: 0;
}
