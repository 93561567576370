@import '../../../../constants/variables';
.wrappers {
	padding: 40px;
	width: inherit;
	display: flex;
	flex-direction: column;
	gap: 16px;
	.titles-container {
		width: inherit;
	}
	.content-wrapper {
		width: inherit;
		background-color: white;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 32px;
		display: flex;
		.hero-contents {
			width: inherit;
			padding: 60px 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 24px;
			.image-container {
				width: 40px;
				height: auto;
			}
			.text-container {
				text-align: center;
				display: flex;
				flex-direction: column;
				gap: 8px;
			}
			.buttonnew {
				gap: 14px;
				.rightIcon {
					margin-left: 0;
				}
			}
		}
	}
}
