.toast-container {
	// align-items: center;
	.toast-text {
		span {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 18px;
			color: $primaryColor5;
		}
	}
	.toast-subtext {
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.toast_modal_size {
		color: $primaryColor5;
		font-size: 16px;
		margin-top: 0px;
		.center-content {
			height: 45px;
			align-items: center;
			display: flex;
		}
	}
	.close {
		display: flex;
		justify-content: flex-end;
	}
}

.Toastify__toast-container {
	width: 65% !important;
	left: 50% !important;
	min-width: auto;
	margin-top: 50px;
	.Toastify__toast--success {
		background: $toast;
	}
	.Toastify__toast {
		margin-left: 102px;
		border-radius: 18px;
		box-shadow: none;
		width: 100%;
		height: 72px;
		min-height: 72px;
	}
}

@media only screen and (max-width: 600px) {
	.Toastify__toast-container {
		width: 100% !important;
		height: 100% !important;
		left: 0% !important;
		min-width: auto;
		display: flex;
		justify-content: center;
		margin-top: 4px;
		.Toastify__toast--success {
			background: $toast;
		}
		.Toastify__toast {
			margin-left: 0px;
			border-radius: 18px;
			box-shadow: none;
			width: 95%;
			height: 65px;
			min-height: 65px;
			position: absolute;
			bottom: 65px;
		}
	}
	.toast-container {
		.toast-text {
			span {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				display: flex;
				align-items: center;
				color: #00344e;
			}
		}
		.toast-subtext {
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: #00344e;
			}
		}
		.close {
			display: flex;
			justify-content: flex-start;
		}
		.toast_modal_size {
			.center-content {
				height: 45px;
				align-items: center;
				display: flex;
			}
		}
	}
}

.overflow-toast {
	overflow: scroll;
}
