.labour-presentational {
	padding: 0px 40px 0px 40px;
	width: 100%;
	.header {
		display: flex;
		align-items: center;
		margin-top: 42px;
		margin-bottom: 26px;
		justify-content: space-between;
		p {
			font-family: Montserrat-bold;
			font-size: 24px;
			font-weight: 700;
			line-height: 28px;
			letter-spacing: 0em;
			text-align: left;
			color: #00344e;
			margin: 0px;
		}
		.secondary-btn {
			font-family: 'Montserrat-Medium';
		}
		.buttonnew {
			width: 118px;
			img {
				margin-right: 0px;
			}
		}
	}
	.questions {
		padding: 32px 32px 32px 32px;
		background: #ffffff;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		.row-image {
			padding: 16px 27px 16px 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #f4f5f6;
			cursor: pointer;
			&:nth-child(5) {
				border: none;
			}
			.content {
				display: flex;
				justify-content: center;
				gap: 16px;
				align-items: center;
				.text {
					margin-bottom: 0px;
					color: #00344e;
					font-family: Montserrat-medium;
					font-size: 14px;
					font-weight: 500;
					line-height: 16px;
					letter-spacing: 0em;
					text-align: left;
				}
			}
		}
	}
}
