@import '../../../../constants/variables';

.edenredCard_main1 {
	background: #f7f8fa;
	// box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
	//border-radius: 16px;
	//padding: 2%;
	padding: 2% 0px 2% 0px;
	.edenredCard_desktop_view_approve_dashboard {
		width: 100%;
		height: 100%;
		border-radius: 16px;
		.card_icon {
			text-align: center;
			height: 40%;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			img {
				width: 60px;
				height: 60px;
			}
		}
		.card_content_section {
			height: 50%;
			.card_title {
				display: flex;
				justify-content: center;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 20px;
					text-align: center;
					color: #00344e;
				}
			}
			.card_description {
				display: flex;
				justify-content: center;
				padding: 5px 9px;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					text-align: center;
					color: #737780;
				}
			}
		}
	}

	.edenredCard_web_view {
		height: 100%;
		.manage_team_user_detail {
			display: flex;
			flex-direction: column;
			justify-content: center;
			.manage_team_user_name {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: $primaryColor5;
			}
			.manage_team_user_mail {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: $greyColor7;
			}
		}
		.manage_team_user_role {
			display: flex;
			align-items: center;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: $primaryColor5;
			}
		}
		.manage_team_user_permission {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: $primaryColor5;
			}
			.arrow_img {
				height: 30px;
				width: 30px;
			}
		}
		.ant-card-body {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 0 !important;
		}
		.invite_team_type_role {
			display: flex;
			flex-direction: column;
			justify-content: center;
			.role {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: #00344e;
			}
			.description {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: #484b52;
				padding-top: 4px;
			}
		}
		.icon_part {
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				cursor: pointer;
			}
		}
		.payrol_content_part {
			padding-left: 3%;
			display: flex;
			flex-direction: column;
			.payroll_title {
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-size: 18px;
					font-weight: 500;
					line-height: 20px;
					color: $primaryColor5;
				}
			}
			.payroll_description {
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-size: 14px;
					font-weight: 300;
					line-height: 16px;
					color: $greyColor7;
				}
			}
		}
	}

	.edenredCard_mobile_view {
		display: none;
	}

	.edenredCard_mobile_view_payroll_channel {
		display: none;
	}

	.edenredCard_mobile_view_approve_dashboard {
		display: none;
	}

	.edenredCard_payroll_history {
		width: 100%;
		border-radius: 24px;
		height: 100%;
		.payroll_history_col_first {
			height: 100%;
			width: 10%;
			position: absolute;
			border-top-left-radius: 20px;
			border-bottom-left-radius: 20px;
		}
		.payroll_history_col_second {
			background: white;
			margin-left: 8px;
			height: 100%;
			padding: 32px 0px 32px 3.5%;
			border-top-left-radius: 20px;
			border-bottom-left-radius: 20px;
			.payroll_history_header {
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 20px;
					line-height: 24px;
					color: #00344e;
				}
			}
			.payroll_history_detail {
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 18px;
					color: #2b769c;
				}
			}
			.payroll_history_content {
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: #484b52;
				}
			}
		}
		.payroll_history_col_third {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-left: 5%;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				text-align: right;
				color: #00344e;
			}
			img {
				height: 30px;
				width: 30px;
			}
		}
	}

	.edenredCard_payroll_history_mobile_view {
		display: none;
	}

	.edenredCard_desktop_view_approve_requests {
		display: flex;
		justify-content: center;
		align-items: center;
		.approve_requests_select_unselect_image {
			padding-left: 1.25%;
			img {
				cursor: pointer;
				// height: 25.04px;
				// width: 25.04px;
			}
		}
		.approve_requests_payroll_details {
			display: flex;
			flex-direction: column;
			.approve_requests_payroll_month_title {
				padding: 1% 0% 0% 0%;
				span {
					font-size: 20px;
					font-weight: 600;
					line-height: 24px;
					color: $primaryColor5;
				}
			}
			.approve_requests_payroll_submitted_date {
				padding: 1% 0% 0% 0%;
				span {
					font-size: 16px;
					font-weight: 500;
					line-height: 18px;
					color: $greyColor7;
				}
			}
			.approve_requests_payroll_total_amount {
				padding: 1% 0% 0% 0%;
				span {
					font-size: 11pt;
					font-weight: 700;
					line-height: 14pt;
					color: $greyColor7;
				}
			}
			.approve_requests_payroll_buttons {
				display: flex;
				flex-direction: row;
				padding: 1% 0% 0% 0%;
				.approve_requests_payroll_reject_button {
					padding: 2% 1% 0% 0%;
					button {
						padding: 1%;
						min-height: 36px;
						font-size: 11pt;
					}
				}
				.approve_requests_payroll_approve_button {
					padding: 2% 0% 0% 1%;
					button {
						padding: 1%;
						min-height: 36px;
						font-size: 11pt;
					}
				}
			}
		}
		.approve_requests_payroll_view_details {
			div {
				span {
					font-size: 11pt;
					font-weight: 600;
					color: $primaryColor5;
				}
				img {
					height: 40px;
					width: 35px;
				}
			}
		}
	}

	.edenredCard_mobile_view_approve_requests {
		display: none;
	}

	.edenredCard_both_view_payroll_timings {
		.payroll_timings_row {
			display: flex;
			flex-direction: row;
			.payroll_timings_salary_funds_recieved_title {
				padding: 0px 4px 6px 0px;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 18px;
					color: $greyColor7;
				}
			}
			.payroll_timings_expected_payout_title {
				padding: 0px 4px 6px 0px;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 18px;
					color: $greyColor7;
				}
			}
			.payroll_timings_salary_funds_recieved {
				padding: 0px 4px 6px 0px;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor7;
				}
			}
			.payroll_timings_expected_payout {
				padding: 0px 4px 6px 0px;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor7;
				}
			}
		}
	}

	.edenredCard_both_view_bank_details {
		padding: 16px;
		.bank_details_row {
			display: flex;
			flex-direction: row;
			.bank_details_title {
				padding-bottom: 1.5%;
				span {
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #484b52;
				}
			}
			.bank_details_value {
				padding-bottom: 1.5%;
				span {
					font-size: 11pt;
					font-weight: 500;
					line-height: 16pt;
					color: $greyColor7;
				}
			}
		}
		.bank_details_hint {
			font-size: 7pt;
			font-weight: 300;
			line-height: 12pt;
			color: $greyColor7;
		}
	}

	.edencard_web_view_useful_files {
		.image {
			width: 35px;
			height: 35px;
		}
		.content_useful_files {
			margin-left: 20px;
			.title_edencard {
				display: flex;
				span {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					color: #00344e;
				}
			}
			.title_edencard1 {
				display: flex;
				span {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					color: #9a9ea6;
				}
			}
			.description_edenredcard {
				padding-top: 4px;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: #484b52;
				}
			}
			.description_edenredcard1 {
				padding-top: 4px;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: #9a9ea6;
				}
			}
		}
		.content_useful_files1 {
			.title_edencard {
				display: flex;
				span {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					color: #00344e;
				}
			}
			.title_edencard1 {
				display: flex;
				span {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					color: #9a9ea6;
				}
			}
			.description_edenredcard {
				padding-top: 4px;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: #484b52;
				}
			}
			.description_edenredcard1 {
				padding-top: 4px;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: #9a9ea6;
				}
			}
		}
		.download_icon {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 15px;
			img {
				width: 45px;
				height: 45px;
			}
		}
	}

	.edencard_mobile_view_useful_files {
		display: none;
	}

	.edencard_mobile_view_proof_of_transfer {
		display: none;
	}

	.edencard_web_view_proof_of_transfer {
		.proof_header {
			span {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 24px;
				color: #00344e;
			}
		}
		.proof_title {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 18px;
			color: #484b52;
		}
		.proof_detail {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: #484b52;
		}
		.view_submit {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			span {
				cursor: pointer;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: $primaryColor5;
			}
			img {
				width: 30px;
				height: 30px;
			}
		}
	}
	.employee-failed-mobile-view {
		display: none;
	}

	.company-invoices-container {
		.company-invoices-col1 {
			.company-invoices-row {
				display: flex;
				align-items: center;
				.header {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 20px;
					line-height: 24px;
					color: #00344e;
				}
				.sub-header {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: #484b52;
				}
			}
		}
		.company-view-details {
			display: flex;
			align-items: center;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: #00344e;
			}
			img {
				width: 32px;
				height: 32px;
			}
		}
	}
}
