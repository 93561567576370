$spacing-left-right : 16px;
$spacing-top-bottom : 32px;

.ed-tab-header {
    display: flex;
    background-color: #FFFFFF;
    margin-bottom: 15px;
    padding: $spacing-left-right $spacing-top-bottom;
    border-radius: 8px;
    :nth-child(1) {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-left: 1px solid #dfe1e6;
    }
    :last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}
.ed-tab-content {
    // padding: $spacing-left-right $spacing-top-bottom;
    min-height: 250px;
}
.ed-tab-items {
    align-items: center;
    border: 1px solid #dfe1e6;
    border-left: none;
    color: #484B52;
    font-weight: 500;
    display: flex;
    font-family: "Montserrat-Medium";
    font-style: normal;
    padding: 0 16px;
    min-height: 32px;
    cursor: pointer;
    .number {
        background-color: #F7F8FA;
        padding: 2px 4px;
        margin-left: 8px;
        border: none;
    }
    &__active {
        background: linear-gradient(93.76deg, #d10f00 1.98%, #f72717 100%);
        color: #FFFFFF;
        .number {
            background-color: #F58178;
        }
    }
    
}
