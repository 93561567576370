.sucessfull-Container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	// align-items: center;
	padding-top: 7%;
	.for-success-image {
		display: flex;
		justify-content: center;
		text-align: center;
		top: -650px;
		animation: drop 2.5s ease forwards;
	}
	@keyframes drop {
		0% {
		}
		70% {
			transform: translateY(700px);
		}
		100% {
			transform: translateY(650px);
		}
	}

	.announcement-sent {
		font-family: 'Montserrat-Bold';
		font-size: 24px;
		font-weight: 700;
		line-height: 28px;
		color: #00344e;
		margin-top: 5%;
		margin-bottom: 5px;
	}
	.message-reached {
		font-family: 'Montserrat-Regular';
		font-size: 18px;
		font-weight: 300;
		line-height: 20px;
		color: #00344e;
	}
	.check-history {
		font-family: 'Montserrat-Regular';
		font-size: 14px;
		font-weight: 300;
		line-height: 16px;
		color: #484b52;
		border: 1px solid #a3a5a8;
		border-radius: 999px;
		padding: 8px 24px 8px 24px;
		background-color: white;
		cursor: pointer;
	}
}
