.leave-balance-edit {
	background-color: red;
}

.leaveEdit {
	width: 100%;
	height: 100%;
	background: #f7f8fa;

	.leave-balance-title {
		//styleName: Mont_H5 Bold 16pt;
		font-family: Montserrat-bold;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 18px;
		letter-spacing: 0em;
		text-align: left;
		color: #00344e;
		padding-left: 2%;
		margin-top: 24px;
		margin-bottom: 0px;
	}
	.card-row {
		width: 100%;
		height: 100%;
		gap: 32px;
		padding-left: 2%;
		margin-bottom: 24px;
		.card-item {
			margin-top: 24px;
		}
	}
}
.leave-butons {
	display: flex;
	padding-left: 2%;
	gap: 16px;
	margin-top: 32px;
	margin-bottom: 21px;
	.for-cancel {
		font-family: 'Montserrat-Bold';
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 18px;
		text-align: center;
		background: #f7f8fa;
		color: #484b52;
		margin-top: 0px;
		box-shadow: none;
		border: 1px solid #dfe1e6;
		&:disabled {
			background-color: #ffffff !important;
			font-family: 'Montserrat-Bold';
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 18px;
			text-align: center;
			color: #dfe1e6;
			margin-top: 0px;
			box-shadow: none;
			border: 1px solid #dfe1e6;
		}
	}
}
