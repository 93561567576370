@import '../../../constants/_variables.scss';

$switchWidth: 42px;
.edenRedSwitch {
	// min-width: $switchWidth;
	height: 24px;
	background: #dfe1e6;
	width: 40px;
	&.ant-switch-checked {
		background: linear-gradient(135deg, #006393 0%, #0088ca 100%);
	}

	.ant-switch-handle {
		top: 3px !important;
		width: 18px;
		height: 18px;
	}
}
