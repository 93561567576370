.stop_insurance_contents_container {
	display: grid;
	gap: 5px;
	align-items: baseline;
	padding-top: 5px;
	.stop_insurance_contents {
		display: flex;
		gap: 4px;
		align-items: flex-start;
	}
}
// Add employee check modal
.iloe_subscribe_check_modal {
	width: 38.4rem;
}
