.date-picker-section {
	display: flex;
	flex-direction: row;
	width: 100%;

	.tooltip {
		width: 100%;

		.tooltipup {
			left: 17%;
		}
	}

	.date-picker-container {
		display: flex;
		flex-direction: column;
		width: 100%;

		.date-picker-input {
			width: 100%;
			border: none;
			height: 32px;
			border-radius: 8px;
			height: 32px;
			color: transparent;
			position: absolute;
			z-index: 1;
			top: 0;
			background: transparent;
			cursor: pointer;
			&:disabled {
				cursor: not-allowed;
			}
		}
		.date-picher-pointer {
			cursor: pointer;
		}
		.date-picker-layout {
			display: flex;
			flex-direction: row;
			height: 32px;
			width: 100%;
			// cursor: pointer;
			border-radius: 8px;
			position: relative;
			border-radius: 8px;
			padding: 8px 12px;

			.date-icon-style {
				width: 21px;
				height: 24px;
				margin-top: -3px;
			}

			.from-to-default-style {
				color: #9a9ea6 !important;
			}

			.from-to-active-style {
				color: #181919 !important;
			}

			.from-to-container {
				display: flex;
				flex-direction: row;
				width: 100%;
				margin-left: 9.5px;

				.from-container {
					display: flex;
					flex-direction: row;
					width: 50%;
					border-right: 1px solid #dfe1e6;
					justify-content: flex-start;
				}

				.to-container {
					display: flex;
					flex-direction: row;
					width: 50%;
					padding-left: 16px;
				}
			}
		}

		.date-picker-disabled {
			cursor: not-allowed;

			.date-icon-style {
				opacity: 0.1;
			}
			.from-to-default-style {
				color: #f7f8fa !important;
			}
		}

		.date-picker-layout-active {
			display: flex;
			flex-direction: row;
			height: 32px;
			width: 100%;
			// cursor: pointer;
			border-radius: 8px;
			position: relative;
			border: 1px solid #71b0d0;
			border-radius: 8px;
			padding: 8px 12px;

			.date-icon-style {
				width: 21px;
				height: 24px;
				margin-top: -3px;
			}

			.from-to-default-style {
				color: #eff1f5 !important;
			}

			.from-to-active-style {
				color: #181919 !important;
			}

			.from-to-container {
				display: flex;
				flex-direction: row;
				width: 100%;
				margin-left: 9.5px;

				.from-container {
					display: flex;
					flex-direction: row;
					width: 50%;
					border-right: 1px solid #dfe1e6;
					justify-content: flex-start;
				}

				.to-container {
					display: flex;
					flex-direction: row;
					width: 50%;
					padding-left: 16px;
				}
			}
		}
	}
}

.custom-inner-container input {
	height: 60px;
	width: 93%;
	background: white;
	margin-top: 10px;
	padding: 0px 25px;
	border: 2px solid #f7f8fa;
	box-sizing: border-box;
	border-radius: 8px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
}

.custom-inner-container .flatpickr-input-data {
	border: 1px solid #e60a14;
}

.red-text-minus {
	color: #e60a14 !important;
}

.calender-hr-doc-icons {
	position: absolute;
	right: 10%;
	top: 30%;
}

.date-validate-picker {
	font-family: Montserrat-Light;
	font-style: normal;
	font-weight: 300;
	font-size: 10px;
	line-height: 18px;
	color: #e60a14;
}

.date-validate-picker1 {
	font-family: Montserrat-Light;
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 18px;
	color: #e60a14;
}

.flatpickr-input::placeholder {
	font-family: Montserrat-Light;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 18px;
	padding: 3px;
	color: #9a9ea6;
}

.flatpickr-input:focus {
	background: #ffffff;
	border-radius: 8px;
	outline: none;
}

.date-container-val1 {
	margin-top: -8px;
	margin-bottom: 0px;
	display: flex;
	flex-direction: row;
	font-family: Montserrat-Medium;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.5px;
	color: #041243;
	align-items: baseline;

	span {
		font-family: Montserrat-Light;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 0.5px;
		display: flex;
		align-items: center;
		color: #484b52;
		padding-left: 4px;
	}

	a {
		color: #2b769c;
		text-decoration: underline;
		padding-left: 4px;
	}
}

.date-container-val {
	display: flex;
	flex-direction: row;
	font-family: Montserrat-Medium;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.5px;
	color: #041243;
	align-items: baseline;

	span {
		font-family: Montserrat-Light;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 0.5px;
		display: flex;
		align-items: center;
		color: #484b52;
		padding-left: 4px;
	}

	a {
		color: #2b769c;
		text-decoration: underline;
		padding-left: 4px;
	}
}

@media screen and (max-width: 1050px) {
	.calender-hr-doc-icons {
		display: none;
	}
}
