@import '../../../constants/_variables.scss';

.wrappers {
	padding: 40px;
	width: inherit;
	display: flex;
	flex-direction: column;
	gap: 16px;
	.titles-container {
		width: inherit;
		display: flex;
		justify-content: space-between;
		.buttonnew {
			gap: 14px;
			.rightIcon {
				margin-left: 0;
			}
		}
	}
	.content-wrapper {
		width: inherit;
		background-color: white;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 32px;
		display: flex;
		gap: 16px;
		.hero-content {
			width: 42%;
			display: flex;
			flex-direction: column;
			gap: 32px;
			.hero {
				display: flex;
				flex-direction: column;
				gap: 16px;
				align-items: flex-start;
				.icon-wrapper {
					width: 45px;
					height: 58px;
				}
				.hero-title {
					font-size: 24px;
					font-weight: 700;
				}
			}
			.list-container {
				padding-bottom: 16px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 16px;

				.list {
					padding-left: 24px;
					display: flex;
					gap: 16px;
					align-items: center;
				}
			}
		}
		.video-wrapper {
			flex: 1;
			border-radius: 16px;
			box-shadow: 0px 19.2px 38.4px -28.8px #bdbdbd;
			.video-container-setup {
				width: 100%;
				height: 100%;
				border-radius: 32px;
				background: #000;
				box-shadow: 0px 16px 32px -24px #bdbdbd;
			}
		}
	}
}
