.documents-preview-table {
	padding: 0 40px;
	justify-content: center;
	display: flex;
	background: #f7f8fa;
	min-height: 100%;
	// height: 100%;
	.multiple_employees_submit_button_row {
		margin-top: 2%;
	}

	.approve-container {
		// width: 960px;
	}
}

.hr-upload .UploadSpreadsheet {
	padding: 0px;
}
.hr_upload_preview {
	overflow: auto;
	max-height: calc(100vh - 188px);
	padding-bottom: 20px;
}
