@import '../../../../constants/_variables.scss';

.view-employees-container {
	display: flex;
	flex-direction: column;
	width: 1120px;
	// padding: 0px 40px 0px 40px;
	// justify-content: space-between;
	align-self: flex-start;
	// background-color: red;

	.employee_profile_fixed_header {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 40px 0px 24px 0px;
		background: $grayscale-200;
		position: sticky;
		top: 0%;
		z-index: 1;

		.employee_profile_header_button {
			display: flex;
			align-items: center;
			gap: 8px;

			.Profile_save_delete_Button {
				display: flex;
				align-items: center;
				gap: 8px;
			}
		}
	}

	.view-employee-profile {
		// padding-right: 24px;

		.employee_profile_view_employee {
			display: flex;
			background: $neutral-white;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;
			padding: 32px;
			width: 100%;
			max-width: 287px;
			text-align: center;
			justify-content: center;
			// position: fixed;
			// z-index: 1;

			.profile_picture {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 24px;
				border-radius: 8px;
				background: $grayscale-200;
			}

			.emp_profile_roles {
				display: flex;
				align-items: center;
				gap: 8px;
				margin-top: 8px;
			}

			.view-employees-india {
				.view-employees-button {
					display: flex;
					justify-content: flex-end;
					align-items: center;

					span {
						background: $feedbackSuccess;
						border-radius: 8px;
						height: 33px;
						min-height: 33px;
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 16px;
						display: flex;
						align-items: center;
						justify-content: center;
						color: $greyColor2;
						min-width: 183px;
						max-width: auto;
						padding: 0px 10px;
					}
				}
			}

			.profile-change-password {
				margin-top: 32px;
				display: flex;
				align-items: center;
				padding: 8px;
				background: #f9fafb;
				border-radius: 8px;
				cursor: pointer;

				.profile_password_lock {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 8px;
				}
			}
		}

		.view-employee-username {
			padding-top: 16px;

			span {
				font-family: Montserrat-bold;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				color: $primaryColor5;
			}
		}

		.view-employee-emp-id {
			font-family: 'Montserrat-Regular';
			font-style: light;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: #484b52;
			padding-left: 5px;

			span {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 18px;
				color: $primaryColor4;
			}
		}

		.view-employee-emp-card {
			font-family: 'Montserrat-Regular';
			font-style: light;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: #484b52 !important;
			padding-left: 5px;
			padding-right: 5px;
			background-color: white !important;
		}

		.view-emp-row {
			margin-top: 4px;
			flex-direction: column;
			align-items: center;

			.emp_account_type {
				display: flex;
				align-items: center;
				justify-content: center;
				background: $grayscale-300;
				border-radius: 8px;
				padding: 3px 8px;
				margin-top: 16px;
			}
		}

		.view-emplyee-date {
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: $greyColor7;
			}
		}
	}
	.view-employee-edit {
		padding: 15px 0px;

		.view-employee-editbotton {
			button {
				height: 32px;
				min-height: 32px;
				width: 128px;
				min-width: 128px;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: $primaryColor5;
				padding: 0px 0px;
				border: 1px solid $greyColor3;
				display: flex;
				justify-content: space-evenly;

				img {
					width: 20px;
					height: 20px;
				}
			}
		}

		.view-employee-deletebotton {
			padding-left: 13px;

			button {
				border: 1px solid $greyColor3;
				height: 32px;
				min-height: 32px;
				width: 170px;
				min-width: 170px;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: $primaryColor5;
				padding: 0px 0px;
				display: flex;
				justify-content: space-evenly;

				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	.payroll-save-popup {
		width: 500px !important;

		.popup_heading_text {
			font-size: 20px !important;
		}

		.message_container {
			height: 70% !important;
		}

		.routing_container {
			height: 33% !important;
		}
	}

	.view-employee-detail-express {
		width: 107%;

		.view-employee-card-padding {
			padding: 10px 10px 10px 0px;
			cursor: pointer;

			.view-card {
				border-radius: 8px;
				height: 44px;
				min-height: 44px;
			}

			.disabled {
				opacity: 0.5;
				box-shadow: 0px 5px 10px rgb(220 224 224 / 25%);
				cursor: not-allowed !important;
			}
		}
	}

	.view-employee-tab-profile {
		display: flex;
		width: 809px;
		.ant-tabs {
			width: 100%;
		}
		.ant-tabs-nav {
			display: flex;
			background: $neutral-white;
			border-radius: 8px;
			padding: 16px 32px;
			margin-bottom: 24px;
			// position: fixed;
			// z-index: 2;
			height: 64px;
			width: 100%;
			// margin-right: 48px;

			.ant-tabs-nav-wrap {
				.ant-tabs-nav-list {
					.ant-tabs-tab {
						padding: 8px 16px;
						height: 32px;
						border: none;
						background: $neutral-white;
						border-width: 1px 1px 1px 0px;
						border-style: solid;
						border-color: #dfe1e6;
						margin: 0px;
						width: inherit;
						&:first-child {
							// width: 126px;
							border-left: 1px solid #dfe1e6;
							border-radius: 8px 0px 0px 8px;
						}

						&:nth-child(6) {
							// width: 183px;
							border-radius: 0px 8px 8px 0px;
						}

						.ant-tabs-tab-btn {
							font-family: 'Montserrat-Medium';
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 16px;
							color: $grayscale-700;
						}
					}

					.ant-tabs-tab-active {
						background: linear-gradient(93.76deg, #d10f00 1.98%, #f72717 100%);

						.ant-tabs-tab-btn {
							color: $neutral-white;
							font-size: 14px;
							line-height: 16px;
						}
					}

					.ant-tabs-ink-bar {
						display: none;
					}
				}
			}
		}
		.ant-tabs-content {
			// margin-top: 85px;
			height: 69vh;
			overflow: auto;
		}

		@media screen and (min-width: 1560px) {
			// .view-employee-tabs {
			// .ant-tabs-nav {
			// 	display: inline-block;
			// 	width: calc(100% - 96rem);
			// 	text-align: center;
			// }
		}
		// }

		.ant-tabs-ink-bar {
			position: absolute;
			background: $secondaryColor3;
		}

		.ant-tabs-tab:hover {
			color: $greyColor5;
		}

		.ant-tabs-tab-btn:focus,
		.ant-tabs-tab-remove:focus,
		.ant-tabs-tab-btn:active,
		.ant-tabs-tab-remove:active {
			color: $secondaryColor5;
		}

		.ant-tabs-top > .ant-tabs-nav::before,
		.ant-tabs-bottom > .ant-tabs-nav::before,
		.ant-tabs-top > div > .ant-tabs-nav::before,
		.ant-tabs-bottom > div > .ant-tabs-nav::before {
			position: absolute;
			right: 0;
			left: 0;
			border-bottom: none;
			content: '';
		}

		.ant-tabs-nav .ant-tabs-nav-more {
			display: none;
		}

		.sub-tap-row {
			padding-top: 20px;

			.subtab-1 {
				width: 100%;
				padding: 0px 20px 5px 0px;

				.ant-select {
					width: 100%;
				}

				.ant-select-selector {
					padding: 0px 16px;
				}
			}
		}
		.subtab-button {
			padding: 5px 18px 5px 0px;
		}
		.individual_salary_profile {
			overflow: scroll;
			height: fit-content;
			&::-webkit-scrollbar {
				width: 3px;
			}

			.emp_profile_salary_history {
				margin-bottom: 20px;
				padding: 32px;
				background: $neutral-white;
				border-radius: 8px;
				height: fit-content;
				box-shadow: 0px 16px 32px -24px #bdbdbd;
				.download-salary-history {
					display: flex;
					justify-content: flex-end;
					width: 100%;
				}

				.emp_profile_table {
					width: 100%;
					margin-top: 16px;

					.salary-history-header {
						align-items: center;
						width: 100%;
						border-bottom: 1px solid #eff1f5;

						.salary-history-header-title {
							width: 50%;
							padding: 17px 16px;
						}
					}

					.subSalary {
						align-items: center;
						width: 100%;
						border-bottom: 1px solid #eff1f5;

						.salary-history-content {
							width: 50%;
							padding: 17px 16px;
						}
					}

					.salary_history_more {
						display: flex;
						justify-content: flex-end;
						width: 100%;
						margin-top: 16px;
						text-decoration: underline;
						cursor: pointer;
					}
				}
			}
		}
	}
}
.payroll-profile-tab-alert-popup {
	.ant-modal-content {
		.ant-modal-body {
			height: 150px !important;
		}
		.main_popup_container {
			.message_container {
				height: 70%;
			}
			.routing_container {
				height: 30%;
			}
		}
	}
}
.view-employees-container_mobile_view {
	display: none;
}

// edit-profile
.edit-profile-container {
	width: 100%;
	padding: 10px 135px 0px 135px;

	.edit-profile-header {
		padding: 25px 0px;

		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.edit-profile-label {
		.ant-form-item {
			margin-bottom: 0px;
		}
	}

	.edit-profile-number {
		width: 100%;
		padding: 0px;
		margin: 0px;

		.edit-profile-frame {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 18px;
			color: $primaryColor5;

			img {
				width: 32px;
				height: 32px;
			}
		}

		.edit-profile-input {
			padding-left: 19px;

			.ant-form-item {
				margin-bottom: 0px;
			}
		}
	}

	.edit-profile-input-date {
		padding: 12px 20px 0px 0px;
	}

	.edit-profile-save {
		padding-top: 32px;
	}
}

@media only screen and (max-width: 600px) {
	.view-employees-container {
		display: none !important;
	}

	.edit-profile-container {
		display: none !important;
	}

	.view-employees-container_mobile_view {
		display: flex !important;
		padding: 65px 15px 80px 15px;

		.view-employees-india {
			width: 100%;
			text-align: center;
		}

		.view-employees-header {
			display: flex;
			justify-content: center;
			text-align: center;

			.view-employee-col {
				padding: 5px 0px;

				span {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 20px;
					line-height: 24px;
					text-align: center;
					color: $primaryColor5;
				}
			}
		}

		.view-employees-content-id {
			display: flex;
			justify-content: center;
			text-align: center;

			.view-employee-id {
				padding: 5px 0px;

				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 18px;
					text-align: center;
					color: $primaryColor4;
				}
			}
		}

		.view-employees-content-date {
			text-align: center;
			display: flex;
			justify-content: center;

			.view-employee-number {
				padding: 5px 0px;

				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					text-align: center;
					color: $greyColor7;
				}
			}
		}

		.view-employees-content-History {
			text-align: center;
			display: flex;
			justify-content: center;
			padding: 5px 0px;

			button {
				font-family: Montserrat-Light;
				font-style: normal;
				font-weight: 300;
				height: 32px;
				min-height: 32px;
				width: 195px;
				font-size: 14px;
				line-height: 16px;
				padding: 2px 2px;
				color: $primaryColor5;

				img {
					width: 30px;
					height: 30px;
				}
			}
		}

		.view-employees-green {
			text-align: center;
			display: flex;
			justify-content: center;
			padding: 5px 0px;
			width: 100%;

			.view-employees-button {
				display: flex;
				justify-content: center;
				align-items: center;

				span {
					background: $feedbackSuccess;
					border-radius: 8px;
					height: 33px;
					min-height: 33px;
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor2;
					display: flex;
					justify-content: center;
					align-items: center;
					max-width: auto;
					padding: 0px 10px;
				}
			}
		}

		.view-employees-yellow {
			background-color: #e4dd3d !important;
		}

		.view-employees-detail {
			background: $greyColor1;
			box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
			border-radius: 16px;
			padding: 15px 12px 5px 15px;

			.view-employees {
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: $primaryColor5;
				}
			}

			.view-employees-issue {
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					color: $greyColor7;
				}
			}

			.view-employee-card-padding {
				padding: 4px 0px 4px 0px;
				cursor: pointer;

				.view-card {
					border-radius: 8px;
					height: 48px;
					min-height: 48px;
				}

				.disabled {
					opacity: 0.5;
					box-shadow: 0px 5px 10px rgb(220 224 224 / 25%);
					cursor: not-allowed !important;
				}

				.edenredEmployeesCard_main {
					box-shadow: none !important;
					border: 2px solid $greyColor3;
					box-sizing: border-box;
					border-radius: 16px;
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $primaryColor5;
				}
			}
		}

		.normal-card {
			margin: 10px 0px 0px 0px;
		}

		.view-request-button {
			width: 100%;
			padding-top: 12px;
			display: flex;
			justify-content: center;

			button {
				font-family: Montserrat-Light;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: $primaryColor5;
				height: 32px;
				min-height: 32px;
				padding: 0px 0px;

				img {
					width: 30px;
					height: 30px;
				}
			}
		}

		.view-delete {
			width: 100%;
			display: flex;
			text-align: center;
			justify-content: center;
			padding-top: 10px;

			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				text-align: center;
				color: $primaryColor4;
				text-decoration: underline;
				text-underline-position: under;
			}
		}
	}
}

.word-break {
	word-break: break-all;
}

@media only screen and (max-width: 600px) {
	.view-emp-sal-btn {
		display: flex;
		justify-content: center;
	}

	.view-employees-container_mobile_view .view-employees-detail .view-employee-card-padding .edenredEmployeesCard_main {
		padding-top: 10px !important;
	}
}
