@import '../../../constants/variables';
.intro-container {
	width: inherit;
	padding: 0px 240px;
	height: 90%;
	@media screen and (max-width: 1400px) {
		padding: 0 40px;
	}
	.header-container {
		padding: 34px 0px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.header-title {
			color: $darkblue;
			font-size: 26px;
			font-family: $font-family-medium;
			font-weight: $font-weight-700;
			line-height: 28px;
			margin-bottom: 0;
			margin-top: 0;
			.header-title-sub {
				color: #f72717;
			}
		}
		.header-select-btn {
			display: flex;
			.select-btn {
				margin-right: 10px;
				cursor: pointer;
			}
		}
	}
	.intro-content-container {
		width: inherit;
		display: flex;
		height: 100%;
		.list-container {
			width: 35%;
			height: 100%;
			display: flex;
			flex-direction: column;
			gap: 10px;
			cursor: pointer;
			.list {
				width: 100%;
				display: flex;
				align-items: center;
				padding: 16px;
				.list-icon {
					width: 24px;
					height: 27px;
				}
				.list-text {
					color: $grayscale-600;
					font-size: 16px;
					font-family: $font-family-light;
					font-weight: $font-weight-300;
					line-height: 18px;
					padding-left: 16px;
					margin-bottom: 0;
					margin-top: 0;
				}
				.list-text-active {
					color: $darkblue;
					font-weight: $font-weight-500;
					font-family: $font-family-medium;
				}
			}
			.list-active {
				background: $greyColor1;
				border-radius: 8px;
			}
		}
		.list-container :hover {
			background: $greyColor1;
			border-radius: 8px;
		}

		.intro-content-video-container {
			width: 75%;
			height: 90%;
			display: flex;
			flex-direction: column;
			margin-left: 40px;
			height: 100%;
			.video-container {
				width: 100%;
				border-radius: 32px;
				background: #000;
				box-shadow: 0px 16px 32px -24px #bdbdbd;
				.intro-video {
					width: 100%;
					height: 100%;
					border-radius: 32px;
				}
				.intro-image {
					width: 100%;
					height: 100%;
					border-radius: 32px;
				}
			}
			.details-container {
				width: 100%;
				margin-top: 24px;
				.details-title {
					color: $darkblue;
					font-size: 26px;
					font-family: $font-family-medium;
					font-weight: $font-weight-700;
					line-height: 28px;
					margin-bottom: 0;
					margin-top: 0;
				}
				.details-text {
					color: $grayscale-700;
					font-size: 16px;
					font-family: $font-family-light;
					font-weight: $font-weight-300;
					line-height: 18px;
					margin-bottom: 0;
					margin-top: 8px;
				}
				.tryNow-btn-container {
					width: 100%;
					height: 32px;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					margin-top: 24px;
					.setup-time {
						color: $greyColor6;
						font-size: 12px;
						font-family: $font-family-light;
						font-weight: $font-weight-300;
						line-height: 14px;
						margin-bottom: 0;
						margin-top: 0px;
						padding-right: 16px;
					}
				}
			}
		}
	}
}
.try-now-btn {
	box-shadow: none;
}
.vimeo_video_container {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	iframe {
		width: 100%;
		height: 100%;
	}
}
.intro-image-container {
	width: 100%;
	border-radius: 32px;
	box-shadow: 0px 16px 32px -24px #bdbdbd;
}
