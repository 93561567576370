.leaveStatus {
	.ant-table {
		width: 100%;
		border-radius: 10px;
	}
}

.leavestateHead {
	font-family: 'Montserrat-Bold';
	font-size: 32px;
	color: #00344e;
	margin-bottom: 7px;
	font-weight: 700;
}

.leave-Status-Container {
	display: flex;
	justify-content: center;
	height: 90vh;
}

.currently_on_leave {
	font-family: 'Montserrat-Bold';
	font-size: 20px;
	color: #00344e;
	font-weight: 700;
	margin-top: 12px;
	margin-bottom: 12px;
}

.leaveStatus {
	.ant-table-thead > tr > th {
		font-family: 'Montserrat-Bold';
		font-size: 12px;
		line-height: 14px;
		color: #00344e;
	}
}

.leaveStatus {
	.nameDetail {
		font-family: 'Montserrat-Medium';
		line-height: 14px;
		font-size: 12px;
		color: #00344e;
		font-weight: 500;
		padding-left: 12px;

		a {
			color: #00344e !important;
		}
	}

	.departDetails {
		font-family: 'Montserrat-Regular';
		line-height: 14px;
		font-size: 12px;
		color: #484b52;
		font-weight: 300;
		letter-spacing: 0.4px;
	}
}

.leaveStatus {
	.ant-table-container table > thead > tr:first-child th:first-child {
		display: flex;
		justify-content: flex-start;
		padding-left: 20px;
	}

	.ant-table-header {
		background-color: #ffffff;
		height: 46px;
		box-shadow: none;
		padding-bottom: 2px;
	}

	.ant-table-body {
		margin-top: 0px;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
	}

	.ant-table-tbody > tr > td {
		overflow: hidden;
	}

	.ant-empty-normal {
		margin: 0px 0px;
	}
}

.emp-name-style {
	color: #484b52;
}

@media screen and (max-width: 600px) {
	.leave-status-web {
		display: none;
	}
}

@media screen and (min-width: 600px) {
	.leave-status-mobile {
		display: none;
	}
}

@media (min-width: 608px) {
	.ant-col-md-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}
}

.edenred-heading-color {
	color: #00344e;
}

.secondary-color {
	color: #ffffff !important;
}

.status-name-style {
	color: #484b52;
}

.status-other-field-style {
	color: #181919;
}

.leave-status-web {
	display: flex;
	flex-direction: column;
	align-self: flex-start;
	width: 100%;

	.leave-status-container {
		display: flex;
		flex-direction: column;
		padding: 40px 40px 0px 40px;
		width: 100%;
		height: 100%;
		align-self: flex-start;

		.leave-status-section {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			width: 100%;
			height: 100%;

			.leave-status-header {
				display: flex;
				flex-direction: row;
				height: 28px;
				width: 100%;
				align-items: center;
			}

			.tab-switch-container {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding: 16px 32px;
				gap: 16px;
				width: 100%;
				height: 70px;
				background-color: #ffffff;
				margin-top: 24px;
				border-radius: 8px;

				.tab-section {
					display: flex;
					flex-direction: row;

					.button-leave-status-left {
						border: 1px solid #dfe1e6;
						border-radius: 8px 0px 0px 8px;
						width: 166px;
						height: 32px;
						padding: 8px 16px;
					}

					.button-leave-status-right {
						border: 1px solid #dfe1e6;
						border-radius: 0px 8px 8px 0px;
						width: 169px;
						height: 32px;
						margin-left: -1px;
						padding: 8px 16px;
					}
				}
			}

			.leave-table-section {
				display: flex;
				flex-direction: column;
				width: 100%;
				padding: 32px;
				background: #ffffff;
				box-shadow: none;
				border-radius: 8px;
				margin-top: 24px;

				.page-size-selector-container {
					display: flex;
					flex-direction: row;
					height: 32px;
					width: 100%;
					justify-content: flex-end;
					align-items: center;

					.leave_dropDown_entries {
						display: flex;
						align-items: center;
						gap: 10px;

						p {
							font-family: 'Montserrat-Regular';
							font-style: normal;
							font-weight: 300;
							font-size: 12px;
							line-height: 14px;
							color: #484b52;
							margin: 0px;
						}

						.entries_check_box {
							background: #8ef9b3;
							border-radius: 4px;
							width: 100%;
							height: 13px;
							display: flex;
							align-items: center;
							justify-content: center;

							img {
								width: 6px;
								height: 6px;
							}
						}
						.multi-select-dropdown-component {
							width: 60px;
							.multiSelect-dropdown-container .dropdown_header .dropdown_inner {
								height: 32px;
								position: relative;
								border: 1px solid #dfe1e6 !important;

								.dropDown-menu {
									margin: 0 0 0 -8px;

									.dropdown_default {
										background: none;

										.dropdown_inner_list_main {
											font-family: 'Montserrat-Medium';
											color: #9a9ea6;
										}
									}
								}

								.dropdown_arrow {
									position: absolute;
									right: 8px;

									.arrow-inner {
										height: 15px;
										width: 15px;
									}
								}
							}

							.multiSelect-dropdown-container .dropdown_header .dropdown_list {
								margin: 0px;

								.dropdown_inner_list {
									div {
										justify-content: center;

										label {
											padding: 0px !important;

											span {
												div:nth-child(1) {
													width: 14px !important;
													height: 14px !important;
													border-width: 1px !important;
													border-radius: 4px !important;
												}
											}
										}
									}
								}
							}
						}
					}
				}

				.table-container-leave-workflow {
					display: flex;
					flex-direction: column;
					margin-top: 15px;
					width: 100%;
					padding: 0;

					.edenred-table tr {
						font-size: 12px;
						line-height: 14px;
						font-family: 'Montserrat-Bold';
					}

					.edenred-table tr th,
					td {
						padding: 17px 16px;
						background: #ffffff;
					}
				}

				.leave-pagination-bar {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					margin-top: 20px;

					.pagination-container {
						margin: 0px;
						padding-left: 10px;
					}
				}
			}
		}
	}
}
