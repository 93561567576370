.post_dashboard_col_1 {
	position: relative;
	display: inline-block;
	width: 100%;
	max-width: 1189px;
	overflow: hidden;
	height: 92vh;
	.post_dashboard_col_1_image {
		position: absolute;
		width: 100%;
		top: -18%;
		background-color: white;
	}
}

.post_dashboard_col_2 {
	display: grid;
	position: absolute;
	top: 75%;
	left: 59%;
	transform: translate(-50%, -50%);
	gap: 24px;
	justify-items: center;
	.text_contents {
		display: grid;
		gap: 10px;
		text-align: center;
		width: 458px;
	}
}
