@import '../../../../constants/_variables';
.edenredEmployeesCard_main {
	background: #ffffff;
	border-radius: 16px;
	width: 100%;
	border: none;
	.manage-employee-table-card {
		display: none;
	}
	.view-employee-web-card {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-around;
		.view-employee-content {
			padding: 0px 12px;
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: #00344e;
		}
		.view-employe-image {
			padding: 0px 5px;
			img {
				width: 26px;
				height: 26px;
			}
		}
	}
	.view-employee-mobile-card {
		display: none;
	}

	.edenredCard_desktop_view_approve_employee_requests {
		display: flex;
		border-radius: 8px;
		flex-direction: column;

		.employee-request-upper-section {
			display: flex;
			flex-direction: row;
			padding-top: 24px;
			padding-right: 32px;
			padding-left: 32px;
			padding-bottom: 16px;
			background-color: #fbfcfd;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;

			.employee-request-checkbox-container label {
				margin-top: 4px;
				padding: 0px !important;
				.employee_plus_table_check_box {
					background: #8ef9b3;
					border-radius: 4px;
					width: 100%;
					height: 12px;
					display: flex;
					align-items: center;
					justify-content: center;
					img {
						width: 8px;
						height: 8px;
					}
				}
			}

			.employee-date-and-button-container {
				display: flex;
				width: 100%;
				flex-direction: row;
				padding-left: 16.83px;
				justify-content: space-between;
				.employee-request-date-container {
					display: flex;
					flex-direction: column;

					.employee-month-submited-section {
						.view-detail-style {
							font-family: 'Montserrat-medium';
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							line-height: 14px;
							margin-left: 16px;
							color: #0e8aff;
							cursor: pointer;
						}
					}
					.card-ordered-date {
						color: #484b52;
						margin-top: 4px;
					}
				}

				.employee-request-cancel-approve-container {
					display: flex;
					flex-direction: row;
				}
			}
		}

		.employee-request-down-section {
			display: flex;
			flex-direction: column;
			padding-top: 16px;
			padding-left: 64px;
			padding-bottom: 24px;

			.employee-request-amount-container {
				display: flex;
				flex-direction: column;
				.total-employee-style {
					display: flex;
					flex-direction: row;
				}

				.total-amount-style {
					display: flex;
					flex-direction: row;
					margin-top: 7.22px;
				}
			}
		}
	}
	.approve-employee-request-mobile-card {
		display: none;
	}
	.normal-detail-mobile-card {
		display: none;
	}
	.add_employees_type_desktop_row {
		display: flex;
		align-items: center;
		.add_employees_type_icon {
			padding: 4px;
			img {
				height: 54px;
				width: 54px;
			}
		}
		.add_employees_type_details {
			display: flex;
			flex-direction: column;
			.add_employees_type_title {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				color: $primaryColor5;
				padding: 2px 0px 4px 0px;
			}
			.add_employees_type_description {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: $greyColor7;
				padding: 2px 0px 4px 0px;
			}
		}
	}
	.add_employees_type_mobile_row {
		display: none;
	}

	.select_payroll_channel_desktop_row {
		display: flex;
		.icon_select_option_part {
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				cursor: pointer;
			}
		}
		.select_payroll_channel_content {
			padding-left: 3%;
			display: flex;
			flex-direction: column;
			.select_payroll_channel_card_title {
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-size: 18px;
					font-weight: 500;
					line-height: 20px;
					color: $primaryColor5;
				}
			}
			.select_payroll_channel_card_description {
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-size: 14px;
					font-weight: 300;
					line-height: 16px;
					color: $greyColor7;
				}
			}
		}
	}
	.select_payroll_channel_mobile_row {
		display: none;
	}
	.approve-request-table {
		display: none;
	}
	.card_order_status_both_view_row {
		.order_details_card_column {
			.order_details_card_title {
				padding: 4px 0px;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 20px;
					line-height: 24px;
					color: $primaryColor5;
				}
			}
			.order_details_card_order_placed {
				display: flex;
				flex-direction: column;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: $greyColor7;
					padding: 4px 0px;
				}
			}
		}
		.view_order_details {
			display: flex;
			justify-content: center;
			align-items: center;
			span {
				display: inline;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: $primaryColor5;
			}
			img {
				height: 32px;
				width: 32px;
			}
		}
	}

	.view_employee_details_both_view_row {
		display: flex;
		flex-direction: row;
		align-items: center;
		.view_employee_name_code {
			display: flex;
			flex-direction: column;
			.view_employee_name {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 24px;
				color: $primaryColor5;
				padding: 4px 8px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.view_employee_code {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: $greyColor7;
				padding: 4px 8px;
			}
		}
		.view_employee_details {
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: $primaryColor5;
			}
			img {
				height: 32px;
				width: 32px;
			}
		}
	}
	.divider {
		display: flex;
		flex-direction: row;
		flex-direction: row;
		width: 100%;
		padding-left: 32px;
		padding-right: 32px;

		background: white;
		.payroll-divider {
			display: flex;
			flex-direction: row;
			flex-direction: row;
			height: 1px;
			width: 100%;
			border-top: 1px solid #eff1f5;
		}
	}
	.pending-alerts-card-section {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 72px;
		justify-content: space-between;
		align-items: center;
		// background-color: RED;

		.pending-order-contents1 {
			display: flex;
			flex-direction: row;
			gap: 16px;
			width: fit-content;
			height: fit-content;
			align-items: center;
			.alert-icon-holder {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				padding: 8px;

				/* Colors/Grayscale/Gradient/grayscale-gradient-light */

				background: linear-gradient(93.76deg, #f7f8fa 1.98%, #f9fafb 100%);
				border-radius: 8px;
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.edenredEmployeesCard_main {
		background: #ffffff;
		box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
		border-radius: 16px;
		.manage-employee-table-card {
			display: flex !important;
			padding: 10px 0px;
			.icon-col {
				display: flex;
				align-items: center;
				img {
					width: 30px;
					height: 30px;
				}
			}
			.content_col {
				.user_col {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #00344e;
				}
				.wps_Col {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					color: #484b52;
				}
				.emp_col {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 10px;
					line-height: 14px;
					color: #484b52;
				}
			}
			.right_col {
				display: flex;
				align-items: center;
				img {
					width: 30px;
					height: 30px;
				}
			}
		}
		.view-employee-web-card {
			display: none !important;
		}
		.view-employee-mobile-card {
			cursor: pointer;
			display: flex !important;
			align-items: center;
			justify-content: space-around;
			.view-employee-content {
				padding: 0px 12px;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: #00344e;
			}
			.view-employe-image {
				padding: 0px 5px;
				img {
					width: 26px;
					height: 26px;
				}
			}
		}
		.approve-employee-request-web-card {
			display: none !important;
		}
		.approve-employee-request-mobile-card {
			display: flex !important;
			.approve-employee-emptybox {
				display: flex;
				align-items: center;
				img {
					width: 30px;
					height: 30px;
				}
			}
			.approve-request-content-center {
				.approve-employee-content {
					width: 100%;
					.card {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 16px;
						color: #00344e;
						padding: 4px 0px;
					}
					.order {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 12px;
						line-height: 14px;
						color: #484b52;
						padding: 4px 0px;
					}
					.deletion {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 12px;
						line-height: 14px;
						color: #484b52;
						padding: 4px 0px;
					}
				}
			}
			.approve-request-button {
				.approve-request-arrow {
					width: 100%;
					display: flex;
					flex-direction: row;
					.approve-reject {
						button {
							width: 140px;
							height: 32px;
							min-height: 32px;
							border: 1px solid #eff1f5;
							box-sizing: border-box;
							border-radius: 90px;
							font-family: Montserrat-Light;
							font-style: normal;
							font-weight: 300;
							font-size: 14px;
							line-height: 16px;
							text-align: center;
							color: #00344e;
							padding: 0px 0px;
						}
					}
					.approve-approve {
						button {
							width: 140px;
							height: 32px;
							min-height: 32px;
							border: 1px solid #eff1f5;
							box-sizing: border-box;
							border-radius: 90px;
							font-family: Montserrat-Light;
							font-style: normal;
							font-weight: 300;
							font-size: 14px;
							line-height: 16px;
							text-align: center;
							padding: 0px 0px;
						}
					}
				}
			}
			.approve-request-rightarrow {
				display: flex;
				align-items: center;
				img {
					width: 30px;
					height: 30px;
				}
			}
		}
		.normal-detail-mobile-card {
			display: flex !important;
			width: 100%;
			.normal-detail-content {
				padding: 5px;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					color: #484b52;
				}
				.normal-header {
					span {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 16px;
						color: #00344e;
					}
				}
			}
			.normal-detail-image {
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: 30px;
					height: 30px;
				}
			}
		}
		.add_employees_type_desktop_row {
			display: none !important;
		}
		.add_employees_type_mobile_row {
			display: flex !important;
			align-items: center;
			.add_employees_type_icon {
				img {
					height: 34px;
					width: 34px;
				}
			}
			.add_employees_type_details {
				display: flex;
				flex-direction: column;
				.add_employees_type_title {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: $primaryColor5;
					padding: 2px 0px 4px 0px;
				}
				.add_employees_type_description {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					color: $greyColor6;
					padding: 2px 0px 4px 0px;
				}
			}
			.arrow_icon {
				display: flex;
				align-items: center;
				img {
					height: 24px;
					width: 24px;
				}
			}
		}

		.select_payroll_channel_desktop_row {
			display: none;
		}
		.select_payroll_channel_mobile_row {
			display: flex !important;
			.icon_select_option_part {
				img {
					height: 30px;
					width: 30px;
				}
			}
			.select_payroll_channel_card_title {
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: $primaryColor5;
				}
			}
		}

		.card_order_status_both_view_row {
			.order_details_card_column {
				.order_details_card_title {
					padding: 2px 0px;
					span {
						font-size: 18px;
						line-height: 20px;
					}
				}
				.order_details_card_order_placed {
					display: flex;
					flex-direction: column;
					span {
						font-size: 14px;
						line-height: 16px;
						padding: 2px 0px;
					}
				}
			}
			.view_order_details {
				display: flex;
				justify-content: center;
				align-items: center;
				span {
					display: none;
				}
				img {
					height: 24px;
					width: 24px;
				}
			}
		}

		.pending-alerts-card-container {
			display: flex;
			align-items: center;
			.pending-alerts-image {
				img {
					width: 35px;
					height: 35px;
				}
			}
			.pending-alerts-content {
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #00344e;
				}
			}
			.pending-alerts-right {
				display: flex;
				justify-content: flex-end;
				img {
					width: 25px;
					height: 25px;
				}
			}
		}
	}

	.approve-request-table {
		display: flex !important;
		.approve-request-table-content {
			padding: 10px;
			.approve-request-table-title {
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 20px;
					color: #00344e;
				}
			}
			.approve-request-table-subtitle {
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					color: #484b52;
				}
			}
		}
		.approve-request-tablet-image {
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 30px;
				height: 30px;
			}
		}
	}
}
