@import '../../../constants/variables';
#root {
	--primarycolor: #000;
	--butnWidth: 40px;
	--butnHeight: 40px;
	--butnColor: #ffffff;
	--arwWidth: 13px;
	--arwHeight: 13px;
}

.left-arrow,
.right-arrow,
.bottom-slide,
.carousel {
	display: flex;
	justify-content: center;
	align-items: center;
}
.slider-carousel {
	display: flex;
	flex-direction: column;
	.slider {
		position: relative;
		display: flex;
		justify-content: space-between;
		column-gap: 10px;
		align-items: center;
		width: 100%;
		height: 20rem;
		.prev-button {
			color: var(--primarycolor);
			z-index: 10;
			cursor: pointer;
			user-select: none;
			.left-arrow {
				width: var(--butnWidth);
				height: var(--butnHeight);
				border-radius: 55%;
				background: var(--butnColor);
				box-shadow: 0 4px 24px 0 rgba(152, 171, 218, 0.2);
				span {
					border: 2px solid var(--primarycolor);
					width: var(--arwWidth);
					height: var(--arwHeight);
					border-top: none;
					border-right: none;
					transform: translateX(15%) rotate(45deg);
				}
			}
		}
		.next-button {
			color: var(--primarycolor);
			z-index: 10;
			cursor: pointer;
			user-select: none;
			.right-arrow {
				width: var(--butnWidth);
				height: var(--butnHeight);
				border-radius: 55%;
				background: var(--butnColor);
				box-shadow: 0 4px 24px 0 rgba(152, 171, 218, 0.2);
				span {
					border: 2px solid var(--primarycolor);
					width: var(--arwWidth);
					height: var(--arwHeight);
					border-bottom: none;
					border-left: none;
					transform: translateX(-18%) rotate(45deg);
				}
			}
		}
		.carousel {
			width: 100%;
			.slide {
				transition-duration: 1s ease;
			}
			.active {
				display: flex;
				justify-content: center;
				width: 100%;
				transition-duration: 1s;
			}
		}
	}
	.bottom-slide {
		column-gap: 16px;
		.pagination {
			width: 8px;
			height: 8px;
			background: $gray-light;
			border-radius: 2px;
		}
		.active {
			width: 16px;
			height: 8px;
			background: $red-300;
			border-radius: 2px;
		}
	}
}
.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 15;
	display: flex;
	justify-content: center;
	padding-left: 0;
	margin-right: 15%;
	margin-left: 15%;
	list-style: none;
	.active {
		opacity: 1;
	}
	li {
		box-sizing: content-box;
		flex: 0 1 auto;
		width: 30px;
		height: 3px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		cursor: pointer;
		background-color: #fff;
		background-clip: padding-box;
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		opacity: 0.5;
		transition: opacity 0.6s ease;
	}
}
