@import '../../../constants/_variables.scss';

.loader-role {
	width: 100px;
	height: 100px;

	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	margin: auto;
}
.action-approve-pannel {
	padding: 0px 100px 0px 100px;
	height: 60vh;
	.verify {
		margin-bottom: 32px;
		color: $primaryColor5;
		font-size: 24px;
		font-family: 'Montserrat-Bold';
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 24px;
	}
	.action_type {
		font-size: 20px;
	}
	.approve_content_list {
		padding-top: 10px;
		margin-top: 20px;
		background-color: #f7f8fa;
		.user {
			padding: 12px 0px 16px 0px !important;
			display: flex;
			flex-direction: column;
			margin-left: 20px;
			margin-top: 20px;
			.user_title {
				font-family: Montserrat-Medium !important;
				font-style: normal !important;
				font-weight: 500 !important;
				font-size: 16px !important;
				line-height: 16px !important;
				color: $primaryColor5 !important;
			}
		}
		.main_content {
			width: 100%;
			.content_list {
				width: 100%;
				margin-top: 1%;
				.horizontal {
					width: 100%;
					height: 0.5px;
					background: $greyColor3;
				}
				.content_detail_toggle {
					padding: 15px 0px 15px 0px;
					.toggle_switch {
						display: flex;
						align-items: center;
					}
					.header {
						span {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 16px;
							// color: #00344e;
						}
					}
					.detail {
						span {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 12px;
							line-height: 14px;
							color: #484b52;
						}
					}
				}
				.switch {
					height: 29px;
					margin-top: 7px;
					width: 50px;
					margin-left: 20px;
					.ant-switch-handle::before {
						width: 19px;
						height: 19px;
					}
				}
			}
		}
	}
	.button-verifier-role {
		height: 60px !important;
	}
	.approve_content_list1 {
		margin-top: 20px;
		background-color: #f7f8fa;
		.user {
			padding: 12px 0px 16px 0px;
			display: flex;
			flex-direction: column;
			margin-left: 20px;
			margin-top: 20px;
			.user_title {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 16px;
				color: $primaryColor5;
			}
		}
		.main_content1 {
			width: 100%;
			.content_list {
				width: 100%;
				margin-top: 1%;
				.horizontal {
					width: 100%;
					height: 0.5px;
					background: $greyColor3;
				}
				.content_detail_toggle {
					padding: 15px 0px 15px 0px;
					.toggle_switch {
						display: flex;
						align-items: center;
					}
					.header {
						span {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 16px;
							// color: #00344e;
						}
					}
					.detail {
						span {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 12px;
							line-height: 14px;
							color: #484b52;
						}
					}
				}
				.switch {
					height: 29px;
					margin-top: 7px;
					width: 50px;
					margin-left: 20px;
					.ant-switch-handle::before {
						width: 19px;
						height: 19px;
					}
				}
			}
		}
		.main_content2 {
			width: 100%;
			margin-left: 30px;
			.content_list {
				width: 100%;
				margin-top: 1%;
				.horizontal {
					width: 100%;
					height: 0.5px;
					background: $greyColor3;
				}
				.content_detail_toggle {
					padding: 15px 0px 15px 0px;
					.toggle_switch {
						display: flex;
						align-items: center;
					}
					.header {
						span {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 16px;
							// color: #00344e;
						}
					}
					.detail {
						span {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 12px;
							line-height: 14px;
							color: #484b52;
						}
					}
				}
				.switch {
					height: 29px;
					margin-top: 7px;
					width: 50px;
					margin-left: 20px;
					.ant-switch-handle::before {
						width: 19px;
						height: 19px;
					}
				}
			}
		}
	}
	.card_action_pannel {
		width: 100%;
		height: 70px;
		border: 1px solid $primaryYellow;
		border-radius: 8px;
		margin-top: 32px;
		display: flex;
		align-items: center;
		.explem {
			width: 50px;
			height: 50px;
			margin-left: 10px;
		}
		.action_approve {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: #484b52;
		}
	}
	.delete_member {
		padding: 10px 0px 0px 0px;
		height: 60px;
		&,
		.delete-button-class {
			button {
				display: flex;
				justify-content: space-evenly;
				padding: 8px 16px;
				margin-bottom: 20px;

				div {
					margin: 0 !important;
					img {
						width: inherit !important;
					}
				}
			}
		}
		button {
			height: 32px;
			min-height: 32px;
			width: 160px;
			padding: 0px 0px;
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 16px;
			color: #00344e;
			color: $primaryColor5;
			img {
				height: 20px;
				width: 20px;
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.action-approve-pannel {
		padding: 4%;
		padding-top: 65px;
		.verify {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			line-height: 24px;
			color: #00344e;
		}
		.card_action_pannel {
			display: none !important;
		}
		.approve_content_list {
			overflow-y: scroll;
			padding-top: 4%;
			.main_content {
				.content_list {
					.content_detail_toggle {
						padding: 3% 0%;
						.header {
							span {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 14px;
								line-height: 16px;
								color: #00344e;
							}
						}
						.detail {
							span {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 10px;
								line-height: 14px;
								color: #484b52;
							}
						}
					}
				}
			}
		}
	}
}
