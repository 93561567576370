@import '../../../constants/_variables.scss';

.change_password_container {
	width: 100%;
	padding: 0px 135px 0px 135px;
	.change_password-col {
		.change_password-header {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				color: #00344e;
			}
		}
		.change_password_input {
			padding-top: 32px;
			width: 100%;
			.ant-form label {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				color: #737780;
			}
			.ant-form-item {
				margin-bottom: 0;
			}
			.validation {
				display: flex;
				flex-direction: column;
				padding-top: 1%;
				padding-bottom: 4%;
				.validation_principle {
					padding-top: 2%;
				}
				.line_through {
					text-decoration: line-through;
					color: $greyColor3;
					span {
						color: $greyColor3 !important;
					}
				}
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
				}
			}

			.form-field__label {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				color: #737780;
			}
			.ant-form-item {
				margin-bottom: 10px;
				.inputlabelrequired::after {
					content: ' *';
					color: red;
				}
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.change_password_container {
		width: 100%;
		padding: 65px 15px 0px 15px;
		.change_password-col {
			.change_password-header {
				span {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 20px;
					line-height: 24px;
					color: #00344e;
				}
			}
			.change_password_input {
				.ant-form-item {
					margin-bottom: 10px;
					.inputlabelrequired::after {
						content: ' *';
						color: red;
					}
				}
			}
		}
	}
}

.change_password_main {
	width: 100%;
	height: 100%;
	// background-color: red;
	padding: 40px;
	padding-bottom: 0;
	.header {
		// background-color: blue;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		> h1 {
			color: #00344e;
			font-family: 'Montserrat-Bold';
			font-weight: 700;
			font-size: 24px;
		}

		> div {
			display: flex;
			flex-direction: row;
			column-gap: 8px;
		}
	}

	.body {
		background-color: #ffff;
		padding: 32px;

		.inputs_area {
			display: flex;
			flex-direction: row;
			column-gap: 16px;
			.input-filed {
				width: calc(50% - 8px);
				> p {
					font-family: 'Montserrat-Medium';
					font-weight: 500;
					font-size: 10px;
					color: #181919;
					margin-bottom: 8px;
				}
			}
		}

		ul {
			margin-top: 24px;
			li {
				font-family: 'Montserrat-Medium';
				font-weight: 500;
				font-size: 12px;
				color: #484b52;
			}
		}
	}
}
