@import '../../../../constants/variables';
.add_bulk_individual_container {
	padding: 0px 40px 40px 40px;
	align-self: flex-start;
	width: 100%;
	.add_bulk_individual_card {
		padding: 16px 24px;
		background: $neutral-white;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		.add_bulk_individual_inner_card {
			display: flex;
			align-items: center;
			padding: 24px 20px;
			cursor: pointer;
			.add_bulk_individual_card_img {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 8px;
				margin-right: 16px;
				background: linear-gradient(93.76deg, #f7f8fa 1.98%, #f9fafb 100%);
				border-radius: 8px;
			}
			.add_bulk_individual_card_content {
				display: flex;
				flex-direction: column;
				width: 100%;
			}
		}
		.add_bulk_individual_card_arrow {
			img {
				width: 24px;
				height: 24px;
			}
		}
	}
}
