@import '../../../../constants/_variables.scss';
.next_page_employees_container {
	display: flex;
	flex-direction: row;
	padding: 0px 40px 40px 40px;
	align-self: flex-start;
	width: 100%;
	.next_page_employees_header {
		padding: 40px 0px 24px 0px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}
	.next_page_employees_message {
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.next_page_employees_status {
		padding: 32px;
		background: #ffffff;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		.next_page_employees_status_container {
			display: flex;
			align-items: center;
			margin-top: 16px;
			gap: 8px;
			.next_page_employees_status_icon {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 10px;
				width: 44px;
				height: 44px;
				background: linear-gradient(93.76deg, #f7f8fa 1.98%, #f9fafb 100%);
				border-radius: 8px;
				// img {
				// 	background: $greyColor1;
				// 	line-height: 14pt;
				// 	box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
				// 	border-radius: 16px;
				// }
			}
			.next_page_employees_status_content {
				display: flex;
				span {
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $grayscale-700;
				}
				a {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					text-decoration: underline;
					text-underline-position: under;
					color: $primaryColor4;
				}
			}
		}
	}
	.next_page_employees_button_row {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		padding: 1% 0% 0% 0%;
		.next_page_employees_button {
			padding: 1% 0% 0% 0%;
			button {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: $primaryColor5;
				padding: 5%;
				height: 32px;
				width: 253px;
				min-height: 32px;
				min-width: 253px;
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.next_page_employees_container {
		display: flex;
		flex-direction: row;
		padding: 64px 1% 1% 2%;
		.next_page_employees_header {
			padding: 1% 2% 0% 2%;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: $primaryColor5;
			}
		}
		.next_page_employees_message {
			padding: 3% 2% 2% 2%;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: $greyColor6;
			}
		}
		.next_page_employees_status {
			padding: 2% 1% 2% 1%;
			.next_page_employees_status_container {
				display: flex;
				align-items: center;
				padding: 1% 1% 0% 1%;
				.next_page_employees_status_icon {
					display: contents;
					img {
						background: $greyColor1;
						box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
						border-radius: 16px;
					}
				}
				.next_page_employees_status_content {
					padding: 1% 0% 0% 4%;
					span {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 16px;
						color: $greyColor7;
						a {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 14px;
							line-height: 16px;
							text-decoration: underline;
							text-underline-position: under;
							color: $primaryColor4;
						}
					}
				}
			}
		}
		.next_page_employees_button_row {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			padding: 2% 0% 2% 0%;
			.next_page_employees_button {
				padding: 1% 1% 0% 1%;
				display: flex;
				justify-content: center;
				button {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $primaryColor5;
					padding: 4%;
				}
			}
		}
	}
}
