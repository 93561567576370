.emp_individual_contract_detail_main {
	padding: 0px 40px 40px 40px;
	align-self: flex-start;
	width: 100%;
	.emp_individual_contract_detail_card {
		background: #ffffff;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 32px;
		.emp_individual_contract_title {
			width: 100%;
		}
		.emp_individual_contract_select {
			margin-top: 24px;
			.emp_individual_contract_radio {
				display: flex;
				align-items: center;
				gap: 32px;
				margin-top: 12px;
				.emp_individual_contract_radio_select {
					display: flex;
					align-items: center;
					gap: 8px;
					cursor: pointer;
				}
			}
		}
		.emp_individual_contract_date {
			margin-top: 24px;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			.emp_contract_field {
				width: 50%;
			}
			.joiningDate,
			.department {
				padding-right: 32px;
			}
			.department,
			.designation {
				margin-top: 16px;
			}
		}
	}
}
