@import '../../../../constants/variables';

.title {
	width: inherit;
	height: 96px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 700;
	font-size: 24px;
	font-family: $font-family-medium;
	color: $blue-500;
	.buttonnew {
		gap: 12px;
		.rightIcon {
			margin: 0;
		}
	}
}
.button-container {
	display: flex;
	gap: 8px;
}
