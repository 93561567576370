@import '../../../../constants/variables';
.upload-spreadsheet-col-container {
	width: 100%;
	align-self: flex-start;
	.UploadSpreadsheet {
		padding: 0px 40px 0px 40px;
		.UploadSpreadsheet_header {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 40px 0px 24px 0px;
			position: sticky;
			top: 0px;
			background: $grayscale-200;
			box-shadow: 0px 8px 24px -12px #f7f8fa;

			.title {
				font-size: 24px;
				line-height: 28px;
				font-family: 'Montserrat-Bold';
				font-style: normal;
				font-weight: 700;
				margin: 0;
				// color: yellow;
				// flex-grow: 1;
				// flex-basis: 1;
				flex: 1;
			}
		}
		.description-background-color {
			background: $grayscale-50;
		}
		.upload-description-background-color {
			background-color: rgba(255, 246, 245, 1);
		}

		.upload_spreadsheet_card {
			padding: 32px;
			background: $neutral-white;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;
			width: 100%;
			margin-bottom: 32px;
			.upload_spreadsheet_steps_container {
				padding: 8px 0px;
				.upload_spreadsheet_steps_row {
					display: flex;
					align-items: center;
					margin-top: 8px;
					.upload_spreadsheet_steps_icon {
						display: flex;
						width: 32px;
						height: 32px;
						border-radius: 8px;
						justify-content: center;
						align-items: center;
						background: $grayscale-100;
						span {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 16px;
							line-height: 18px;
							color: $primaryColor3;
						}
					}
					.upload_spreadsheet_steps_content {
						display: flex;
						padding: 0px 0px 0px 16px;
						a {
							text-decoration: underline;
							text-underline-position: under;
							color: $blue-400;
							margin-left: 3px;
						}
					}
				}
			}
			.card_yellow {
				display: flex;
				justify-content: center;
				padding: 4px 0px;
				.card_action {
					width: 100%;
					min-height: 30px;
					border: 1px solid $primaryYellow;
					border-radius: 8px;
					.ant-card-body {
						padding: 4px;
					}
					img {
						width: 70%;
					}
					.tip_web-view {
						display: flex;
						align-items: center;
					}
					.tip_mobile-view {
						display: none;
					}
				}
			}
			.card_error {
				display: flex;
				justify-content: center;
				padding: 17px 0px;
				.card_action {
					background: #fff6f5;
					border-radius: 8px;
					border: none;
					padding: 3px;
					img {
						width: 20px;
					}
					.ant-card-body {
						padding: 4px;
					}
					.tip_web-view {
						display: flex;
						align-items: center;
					}
					.tip_mobile-view {
						display: none;
					}
				}
			}
			.dragger_card_container {
				padding-top: 17px;
				background-position: top;
				background-size: 10px 1px;
				background-repeat: repeat-x;
				.main-container {
					.dragger {
						width: 100%;
					}
				}
			}
		}
		.ant-upload-list-item-info {
			bottom: 46px;
			position: absolute;
			text-align: center;
			width: 90%;
		}
		.ant-upload-list-item-error .ant-upload-list-item-name {
			color: black;
		}
		.anticon svg {
			// display: none;
			color: black;
		}
		.whole {
			padding: 25px 0px;
		}

		.dragger {
			// width: 100%;
			.Upload_row_2 {
				display: none;
			}
		}
		.ant-upload-list-text {
			width: 95%;
			.ant-upload-text-icon {
				display: none;
			}
		}
		.upload-intro-video-wrapper {
			width: 48%;
			border-radius: 18px;
			box-shadow: 0px 19.2px 38.4px -28.8px #bdbdbd;
		}
	}
	.next_button {
		padding-top: 4%;
		padding-bottom: 20px;
	}
}
