@import '../../../../constants/variables';

.add_individual_emp_container {
	padding: 0px 40px 40px 40px;
	align-self: flex-start;
	width: 100%;
	.add_individual_emp_detail_card {
		background: $neutral-white;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 32px;
		.add_individual_emp_text {
			width: 100%;
		}
		.add_individual_emp_form {
			display: flex;
			flex-wrap: wrap;
			margin-top: 24px;
			.add_individual_emp_field {
				width: 50%;
			}
			.firstName,
			.dob,
			.mobile {
				padding-right: 32px;
			}
			.dob,
			.mobile,
			.nationality,
			.email {
				margin-top: 16px;
			}
		}
	}
	.add_individual_emp_account_card {
		background: $neutral-white;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 32px;
		margin-top: 24px;
		.margin-top-8 {
			margin-top: 8px;
		}
		.margin-top-24 {
			margin-top: 24px;
		}
		.add_individual_emp_account_card_title,
		.add_individual_emp_account_card_desc,
		.add_individual_emp_account_card_select {
			width: 100%;
			display: flex;
			align-items: center;
			gap: 24px;
		}
		.add_individual_emp_account_card_select {
			.add_individual_emp_account {
				display: flex;
				align-items: center;
				gap: 16px;
				border: 1px solid #eff1f5;
				border-radius: 8px;
				padding: 16px 32px;
				width: 50%;
				height: 48px;
				cursor: pointer;
				&.show_box_shadow {
					box-shadow: 0px 8px 24px -12px #bdbdbd;
				}
			}
		}
	}
}
