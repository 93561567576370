@import '../../../constants/_variables.scss';

.viewexpand {
	font-family: Montserrat-Regular;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	color: #0e5274;
	cursor: pointer;
	float: right;
	margin-top: 10px;
}

.add_employees_container {
	padding: 0px 135px 0px 135px;
	.add_employees_title {
		padding: 0px 0px 16px 0px;
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.add_employees_type_card_section {
		.add_employee_card_column {
			padding: 8px 16px 8px 0px;
			.edenredEmployeesCard_main {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 120px;
				min-height: 120px;
				.ant-card-body {
					padding: 4px;
				}
			}
		}
	}
}

//---- multiple-employees ----
.upload-speadsheet-col-content {
	width: 100%;
	align-self: flex-start;
	.upload_employees_spreadsheet_container {
		padding: 0px 40px 0px 40px;
		width: 100%;
		.upload_employees_spreadsheet_title {
			display: flex;
			flex-direction: row;
			width: 100%;
			padding: 40px 0px 24px 0px;
			background: #f7f8fa;
			box-shadow: 0px 8px 24px -12px #f7f8fa;
			justify-content: space-between;
			.add-employee-header {
				display: flex;
				width: fit-content;
				height: fit-content;
			}
			.add-employee-button-container {
				display: flex;
				flex-direction: row;
				width: fit-content;
				height: fit-content;
			}
		}
		.upload-box-and-table-container {
			padding: 32px;
			width: 100%;
			background: #ffffff;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;
			.error_table_emp {
				.errorTable {
					border: none;
				}
			}
			.add-employee-dragger-container {
				display: flex;
				width: 100%;
				margin-top: 16px;
				.main-container-dragger {
					height: unset;
					.dragger {
						height: 132px;
					}
				}
			}
			.add-employee-download-spreadsheet {
				display: flex;
				flex-direction: row;
				width: 100%;
				margin-top: 24px;
				background: #f9fafb;
				border-radius: 8px;
				padding: 16px;
				.whole-click-here-text-style {
					display: flex;
					align-items: center;
					.Here-text {
						text-decoration: underline;
						color: #0e5274;
						margin-left: 3px;
						font-family: 'Montserrat-Medium';
					}
				}
			}
			.warning-message-container {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 8px;
				gap: 8px;
				width: 100%;
				height: 56px;
				background: #fff6f5;
				border-radius: 8px;
				margin: 24px 0px;
				.errormessage-text-style {
					margin-left: 17px;
				}
			}
		}

		/////////////////////////////

		.ant-form {
			width: 100%;
		}
		.upload_employees_dragger_container {
			padding: 16px 0px;
			padding-top: 32px;
			background-position: top;
			background-size: 10px 1px;
			background-repeat: repeat-x;
			.ant-upload.ant-upload-drag .ant-upload {
				padding: 8px 0;
			}
			.upload_dragger {
				.upload_dragger_desktop_row {
					display: flex;
					width: 100%;
					.upload_indication_image {
						padding: 6px;
						img {
							width: 120px;
							height: 120px;
						}
					}
					.upload_dragger_hint {
						padding: 16px;
						display: inline-grid;
						.drag_and_drop_text {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 20px;
							color: $primaryColor5;
							text-align: center;
							color: $primaryColor5;
							padding: 4px 0px;
						}
						.click_here_text {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 16px;
							line-height: 18px;
							color: $primaryColor5;
							text-align: center;
							color: $primaryColor5;
							padding: 4px 0px;
						}
					}
					.after_file_upload {
						padding: 16px;
						display: inline-grid;
						.file_name_icon {
							position: initial;
							.file_name {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 18px;
								line-height: 20px;
								text-align: center;
								color: $primaryColor5;
								padding: 4px 0px;
							}
							.updeleteicon {
								position: inherit;
								text-align: center;
								cursor: pointer;
								svg {
									fill: $primaryColor5;
								}
							}
						}
						.file_size {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 16px;
							line-height: 18px;
							text-align: center;
							color: $primaryColor5;
							padding: 4px 0px;
						}
					}
				}
				.upload_dragger_mobile_row {
					display: none;
				}
			}
			.ant-upload-list-item {
				display: none;
			}
		}
		.download_spreadsheet_template_hint {
			padding: 12px 0px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: $greyColor7;
				margin-bottom: 8px;
			}
		}
		.download_spreadsheet_template_button {
			padding: 12px 0px;
			button {
				height: 32px;
				min-height: 32px;
				width: 214px;
				min-width: 214px;
				padding: 4px;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 16px;
				border: 1px solid $greyColor4;
				box-sizing: border-box;
				border-radius: 22px;
				.download_image {
					height: 25px;
					width: 25px;
				}
			}
		}

		.errorNote {
			border: 1px solid $feedBackRed;
			box-sizing: border-box;
			border-radius: 8px;
			display: flex;
			align-items: center;
			flex-direction: row;
			width: 100%;
			padding: 5px;
			margin: 16px 0px;
			.anticon {
				padding: 10px 20px;
				svg {
					background: $secondaryColor6;
					border-radius: 50%;
				}
			}
			.errmsg {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: $greyColor7;
			}
		}
		.validate_table {
			width: -webkit-fill-available;
			margin: 18px 0px;
			border-radius: 8px;
			border: 1px solid $greyColor4;
			border-collapse: separate;
			thead,
			tbody {
				tr {
					th,
					td {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						padding: 10px 24px;
						border-right: 1px solid $greyColor4;
						border-bottom: 1px solid $greyColor4;
					}
					th {
						font-size: 16px;
						line-height: 18px;
						color: $greyColor5;
					}
					td {
						font-size: 14px;
						line-height: 16px;
						color: $greyColor7;
					}
				}
			}
			tr:last-child td {
				border-bottom: none !important;
			}
			tr td:last-child,
			tr:first-child th:last-child {
				border-right: none !important;
			}
			.employeeBtn {
				background: transparent;
				/* border: 1px solid grey; */
				height: 30px !important;
				width: 100% !important;
				display: flex !important;
				flex-direction: row !important;
				justify-content: center !important;
				align-items: center !important;
				border: 1px solid #dfe1e6 !important;
				cursor: pointer;
				/* padding: 5px 20px 5px 15px; */
				border-radius: 105px !important;
				font-family: 'Montserrat-Regular';
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: #0e5274;
			}
			.employeeBtnRemove {
				background: transparent;
				/* border: 1px solid grey; */
				height: 35px !important;
				width: 100% !important;
				display: flex !important;
				flex-direction: row !important;
				justify-content: center !important;
				align-items: center !important;
				border: 1px solid #dfe1e6 !important;
				// cursor: pointer;
				/* padding: 5px 20px 5px 15px; */
				border-radius: 105px !important;
				font-family: 'Montserrat-Regular';
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: #0e5274;
			}
		}

		.upload_employees_spreadsheet_next_button {
			padding: 24px 0px;
		}
	}
}

//---- multiple-employees-submit page----

.bulk-employee-detail-container {
	padding: 40px;
	width: 100%;
	height: 100%;
	display: flex;
	// flex-direction: column;
	align-self: flex-start;
	.bulk-employee-header-container {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: fit-content;
		justify-content: space-between;

		.bulk-employee-page-title {
			color: '#00344E';
		}
		.bulk-employee-button-container {
			display: flex;
			flex-direction: row;
			gap: 8px;
		}
	}

	.bulk-employee-sub-header {
		display: flex;
		width: 100%;
		flex-direction: row;
		margin-top: 8px;
	}

	.bulk-employee-info-board {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 32px;
		gap: 24px;
		width: 100%;
		margin-top: 24px;

		/* Colors/Neutral/neutral-white */

		background: #ffffff;
		/* Shadows/shadow-lg */

		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;

		.bulk-employee-search-and-show-count-container {
			display: flex;
			flex-direction: row;
			width: 100%;
			justify-content: space-between;

			.edenRedSearch {
				width: 340px;
			}
			.bulk-employee-entries-select-container {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				gap: 9px;
			}
		}

		.bulk-employee-table-card-container {
			display: flex;
			width: 100%;
			flex-direction: column;
			padding: 0px !important;
			box-shadow: none;
			.flyers-table thead tr th {
				background-color: #ffffff;
			}
		}

		.bulk-employee-card-pagination-container {
			display: flex;
			flex-direction: row;
			width: 100%;
			align-items: center;
			justify-content: flex-end;
			.pagination-container {
				margin: 0px;
				padding-left: 16px;
			}
		}
	}

	///////////////////
	.bulk-employee-detail-header {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.bulk-employee-detail-subcontent {
		padding: 24px 0px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.bulk-employee-detail-searchbutton {
		padding: 5px 0px;
		.bulk-employee-detail-row {
			width: 100%;
			.download {
				display: flex;
				justify-content: flex-end;
				button {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					text-align: center;
					color: $greyColor7;
					height: 32px;
					min-height: 32px;
					padding: 0px 0px;
					width: 219px;
				}
			}
			.approve {
				display: flex;
				justify-content: flex-end;
				button {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					text-align: center;
					color: $greyColor2;
					height: 32px;
					min-height: 32px;
					padding: 0px 0px;
					width: 187px;
				}
			}
		}
	}
	.bulk-employee-header-table {
		padding-top: 20px;
		.bulk-employee-header {
			width: 100%;
			span {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 14px;
				color: $greyColor5;
			}
		}
	}
	.bulk-employee-horizontal {
		margin: 8px 0px 12px 0px;
		background: $greyColor4;
		height: 0.1px;
	}
	.bulk-employee-table-content {
		width: 100%;
		display: flex;
		align-items: center;
		.name {
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: $primaryColor5;
			}
		}
		.view {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				text-align: right;
				color: $primaryColor5;
			}
			img {
				width: 30px;
				height: 30px;
			}
		}
		.bulk-employee-horizontal-view {
			margin: 15px 0px 15px 0px;
			background: $greyColor4;
			height: 0px;
			max-height: 0px;
		}
	}
	.bulk-employee-mobile-view {
		display: none;
	}
}

//////
.multiple_employees_submit_container {
	padding: 0px 135px 0px 135px;
	width: 100%;
	.multiple_employees_submit_button_row {
		width: 100%;
		.multiple_employees_submit_title {
			padding: 6px 0px;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				color: $primaryColor5;
			}
		}
		.multiple_employees_submit_button {
			padding: 6px 0px;
			button {
				background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%);
				box-shadow: 0px 5px 10px rgba(233, 180, 175, 0.75);
				border-radius: 16px;
				min-height: 32px;
				min-width: 99px;
				width: 99px;
				height: 32px;
				padding: 2px;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
			}
		}
	}
	.multiple_employees_submit_hint {
		padding: 8px 0 8px 0;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.submit_multiple_employees_list_section {
		padding: 8px 0 8px 0;
		.multiple_employees_list_row {
			.multiple_employees_name_employee_id_title {
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					color: $greyColor5;
				}
			}
			.multiple_employees_name_employee_id_column {
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: $primaryColor5;
				}
			}
		}
		.ant-divider-horizontal {
			margin: 16px 0px;
		}
	}
}
.multiple_employees_submit_modal {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	.Modal_Edenred .Remove_user {
		padding: 5% 10% 0% 10%;
		font-family: 'Montserrat-medium';
		color: #00344e;
	}
	.Modal_Edenred .user_login_mobile {
		margin: 0%;
	}
	.Modal_Edenred {
		width: 30%;
		.Modal_Edenred .Remove_user {
			font-size: 18px;
			color: $primaryColor5;
			padding: 2% 10%;
		}
		.edenredTypo.bold {
			font-family: 'Montserrat-Medium';
		}
		.Button_container .Yes {
			color: #ffff;
			font-weight: bold;
			background: #d52b1e;
			display: flex;
			align-items: center;
			justify-content: center;
			border-bottom-right-radius: 16px;
		}
	}
}

//---multiple-employees-next-steps-page----
.multiple_employees_next_steps_container {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	padding: 40px;
	align-self: flex-start;
	.multiple-emp-next-step-section {
		display: flex;
		width: 100%;
		flex-direction: column;
		gap: 24px;
		height: fit-content;

		.multiple_employees_next_steps_header {
			display: flex;
			flex-direction: row;
			width: 100%;
			height: fit-content;
			justify-content: space-between;
			.back-button-next-setup {
				width: 259px;
				height: 32px;
				span {
					overflow: unset;
				}
			}
		}

		.add-emp-next-step-card-container {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 32px;
			gap: 24px;

			width: 100%;

			/* Colors/Neutral/neutral-white */

			background: #ffffff;
			/* Shadows/shadow-lg */

			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;

			/* Inside auto layout */

			flex: none;
			order: 0;
			align-self: stretch;
			flex-grow: 0;

			.add-emp-next-step-sub-header {
				display: flex;
				flex-direction: column;
				width: 100%;
				height: fit-content;
				gap: 4px;
			}

			.add-emp-next-step-section {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 0px;
				gap: 8px;
				width: 100%;
				height: 44px;

				.Here-text {
					font-family: 'Montserrat-medium';
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					/* identical to box height, or 114% */

					text-decoration-line: underline;

					/* Colors/Blue/blue-400 */

					color: #0e5274;
				}
			}
		}
	}

	///////////////////
	// .multiple_employees_next_steps_header {
	// 	padding: 1% 0% 1% 0%;
	// 	span {
	// 		font-family: Montserrat-Bold;
	// 		font-style: normal;
	// 		font-weight: bold;
	// 		font-size: 24px;
	// 		line-height: 28px;
	// 		color: $primaryColor5;
	// 	}
	// }
	.multiple_employees_next_steps_message {
		padding: 1% 0% 1% 0%;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.multiple_employees_next_steps_status {
		padding: 1% 0% 1% 0%;
		.multiple_employees_next_steps_status_container {
			display: flex;
			align-items: center;
			padding: 1% 1% 1% 1%;
			.multiple_employees_next_steps_status_icon {
				display: contents;
				img {
					background: $greyColor1;
					line-height: 14pt;
					box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
					border-radius: 16px;
				}
			}
			.multiple_employees_next_steps_status_content {
				display: flex;
				padding: 1% 1% 0% 2%;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: $greyColor7;
				}
				a {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					text-transform: capitalize;
					text-decoration: underline;
					text-underline-position: under;
					color: $primaryColor4;
				}
			}
		}
	}
	.multiple_employees_next_steps_button {
		padding: 1% 0% 0% 0%;
		button {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $primaryColor5;
			padding: 1%;
			height: 32px;
			min-height: 32px;
			width: 253px;
			min-width: 253px;
		}
	}
}

@media screen and (min-width: 600px) {
	.run-payroll-table-presentation-wrapper .footer-parent-payroll {
		position: absolute;
		height: auto;
		bottom: 0px;
		width: 100%;
	}
}
