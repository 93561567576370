// emergency banner style for insurance
.helper-banner {
	display: flex;
	padding: 8px 8px 8px 16px;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	background-color: #fef5e8;
	border-radius: 8px;
	.info-icon {
		height: 24px;
		width: 24px;
	}
	.info-content {
		width: 80%;
		padding-right: 40px;
		line-height: 17px;
	}
}
