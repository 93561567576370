.card-image {
	justify-content: center;
}
.card-image-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	.top-text {
		font-family: 'Montserrat-Bold';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		color: #f75448;
	}
	.heading-text {
		font-family: 'Montserrat-Bold';
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 28px;
		color: #00344e;
	}
	.sub-heading-text {
		margin-top: 20px;
		font-family: 'Montserrat-Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 20px;
		color: #00344e;
	}
	.para-text {
		font-family: 'Montserrat-Light';
		font-style: normal;
		font-weight: 300;
		font-size: 18px;
		line-height: 20px;
		color: #484b52;
	}
	.card-list {
		margin: 20px 0 0 20px;
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 10px;
		img {
			width: 15px;
		}
		p {
			font-family: 'Montserrat-light';
			font-style: normal;
			font-weight: 300;
			font-size: 18px;
			line-height: 20px;
			color: #00344e;
			margin: 0;
		}
	}
}
