@import '../../../constants/_variables.scss';
.mainContainer {
	padding: 0px 40px 0px 40px;
	flex-direction: column;
	width: 100%;
	align-self: flex-start;
	.header_content {
		padding: 40px 0px 24px 0px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		position: sticky;
		z-index: 1;
		top: 0;
		background: $grayscale-200;
		.new_desgin_edit_roles_button {
			display: flex;
			align-items: center;
			gap: 10px;
			.new_desgin_cancel,
			.new_desgin_remove {
				font-family: 'Montserrat-Medium';
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: #484b52;
				background: #f7f8fa;
			}
		}
	}
	.header_premium_msg_div {
		border: 1px solid #eedcba;
		margin-top: 12px;
		margin-bottom: 7px;
		border-radius: 8px;
		.header_premium-div {
			display: flex;
			align-items: center;
			.header_premium {
				font-family: Montserrat-regular;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: black;
				margin-bottom: 0px;
				padding-left: 7px;
			}
		}
	}
	.userbackground {
		background: #ffffff;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 16px 0px;
		margin-bottom: 40px;
		.hover_select_all {
			margin: 0px;
			font-family: 'Montserrat-Medium';
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 14px;
			color: $grayscale-900;
			padding: 8px 16px 8px 16px;
			cursor: pointer;
		}
		.user {
			display: flex;
			align-items: center;
			gap: 10px;
			padding: 16px 32px;
			.user_title {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 16px;
				color: $primaryColor5;
			}
			.user_description {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: $greyColor7;
			}
		}
		.new_desgin_chip {
			font-family: 'Montserrat-Medium';
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 14px;
			color: $grayscale-900;
			padding: 8px 16px 8px 16px;
		}
	}
	.loader-role {
		width: 100px;
		height: 100px;

		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		margin: auto;
	}
	.new_design_bg {
		background: #f7f8fa;
	}
	.userbackground1 {
		.user {
			padding: 16px 32px;
			display: flex;
			align-items: center;
			gap: 10px;
			.user_title {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 16px;
				color: $primaryColor5;
			}
			.user_description {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: $greyColor7;
			}
		}
	}

	.align_horizontal {
		padding: 0px 0px;
	}
	.content_list {
		padding: 16px 32px;
		gap: 16px;
		align-items: center;
		cursor: pointer;
		.new-design-roles {
			div {
				border-radius: 4px !important;
				height: 14px !important;
			}
		}
		.value {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			color: #00344e !important;
		}
		.value1 {
			font-family: Montserrat-Light;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 14px;
			color: #484b52;
		}
		&:hover {
			background: $grayscale-200;
		}
	}
	.content_list1 {
		padding: 16px 32px;
		gap: 16px;
		align-items: center;
		.new-design-roles {
			div {
				border-radius: 4px !important;
				height: 14px !important;
			}
		}
		.value {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			color: #00344e !important;
		}
		.value1 {
			font-family: Montserrat-Light;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 14px;
			color: #484b52;
		}
	}

	.toggle_content1:nth-child(2) {
		.content_list1 {
			margin-left: 30px;
		}
	}
	.save_change {
		padding: 32px 0px 32px 0px;
		button {
			width: 340px;
			min-width: 340px;
			min-height: 48px;
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 20px;
			color: $greyColor2;
		}
	}

	.delete_member {
		padding: 0px 0px 32px 0px;
		&,
		.delete-button-class {
			button {
				display: flex;
				justify-content: space-evenly;
				padding: 8px 16px;
				margin-bottom: 20px;

				div {
					margin: 0 !important;
					img {
						width: inherit !important;
					}
				}
			}
		}
		button {
			height: 32px;
			min-height: 32px;
			width: 160px;
			padding: 0px 0px;
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 16px;
			color: #00344e;
			color: $primaryColor5;
			img {
				height: 20px;
				width: 20px;
			}
		}
	}
	.campany {
		font-family: Montserrat-Regular;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		color: $greyColor7;
	}
	.switch {
		height: 29px;
		margin-top: 7px;
		width: 50px;
		.ant-switch-handle::before {
			width: 19px;
			height: 19px;
		}
	}
	.button_user {
		width: 35%;
		background: $greyColor2;
		border: 0px;
		color: $greyColor4;
	}
	.remove_member {
		display: none !important;
	}
}
.modal_card_role {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	.Modal_Edenred {
		width: 470px;
		height: 194px;
		min-height: 194px;
		background: #ffffff;
		box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.5);
		border-radius: 16px;
		.user {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 20px;
			color: #00344e;
		}
		.user_login {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 18px;
			color: #484b52;
			padding: 8px 52px 32px 32px;
		}
		.Remove_user {
			padding: 32px 15px 0px 32px;
		}
		.Button_container {
			.Go_Back {
				height: 7vh;
			}
			.Yes {
				height: 7vh;
			}
		}
	}
}
.new_design_delete {
	.mainmodal {
		.container {
			margin: 8px 0px 10px 0px;
			.image-row {
				margin-bottom: 0px;
			}
			.top-image {
				img {
					padding: 13px;
					background: $grayscale-200;
					border-radius: 24px;
					width: 50px;
				}
			}
			.modal-title {
				text-align: center;
				margin: 0px;
			}
			.modal-desc {
				text-align: center;
			}
			.button-flex {
				margin-top: 8px;
			}
		}
	}
}
.new_design_last {
	.mainmodal {
		position: relative;
		.container {
			margin-top: 10px;
			margin-bottom: 10px;
			.image-row {
				position: absolute;
				right: 8px;
				top: 14px;
				// margin-bottom: 0px;
			}
			.modal-title {
				text-align: center;
				margin: 0px;
			}
			.modal-desc {
				text-align: center;
			}
		}
	}
}
.last_Employee_popup {
	.ant-modal-content {
		.ant-modal-body {
			height: 220px !important;
			.message_container {
				height: 67%;
				margin-top: 20px;
			}
			.routing_container {
				height: 24% !important;
			}
		}
	}
}
@media only screen and (max-width: 600px) {
	.mainContainer {
		padding: 12px;
		padding-top: 65px;
		.header_content {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: #00344e;
			}
		}
		.user {
			padding: 16px 32px;
			span {
				padding-bottom: 4px;
			}
		}
		.campany {
			font-size: 12px;
		}
		.save_change {
			display: flex !important;
			justify-content: center;
			padding: 24px 4px 4px 4px;
			button {
				min-height: 48px;
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 18px;
				padding: 15px 28px 15px 28px;
			}
		}
		.delete_member {
			display: flex;
			justify-content: center;
			padding: 10px 0px 60px 0px;
		}
		.remove_member {
			display: flex !important;
			justify-content: center;
			padding-top: 24px;
			button {
				height: 32px;
				min-height: 32px;
				width: 184px;
				min-width: 184px;
				font-family: Montserrat-Light;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: #00344e;
				padding: 0px 0px;
				img {
					width: 24px;
					height: 24px;
				}
			}
		}
		.content_list {
			padding: 16px 32px;
			gap: 16px;
			align-items: center;
			cursor: pointer;
			.new-design-roles {
				div {
					border-radius: 4px !important;
					height: 14px !important;
				}
			}
			.value {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: #484b52;
			}
		}
		.align_horizontal {
			margin: 10px 0px;
		}
	}

	.modal_card_role {
		display: flex;
		align-items: flex-end;
		height: 100%;
		width: 100%;
		.Modal_Edenred {
			width: 100%;
			height: 178px;
			min-height: 194px;
			background: #ffffff;
			box-shadow: 0px -10px 20px rgba(174, 182, 183, 0.25);
			border-radius: 16px;
			.user {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: #00344e;
			}
			.user_login_mobile {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: #737780;
				padding: 4px 0px 24px 16px;
				margin: 0px;
			}
			.Remove_user {
				padding: 0px 0px 0px 16px;
			}
			.gobackButton {
				padding: 0px 8px 0px 0px;
				button {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 16px;
					line-height: 18px;
					text-align: center;
					color: #484b52;
				}
			}
		}
	}
}
