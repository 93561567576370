
@use "sass:math";
.date{
    border-radius: 8px;
    // border: 1px solid #DFE1E6;
    width: 190px;
    padding: 3px 13px 3px 13px;
    flex-direction: row-reverse;
    cursor: pointer;
    position: relative;
    margin-bottom: 90px;
    color: black;
    height: 32px;
    

}
input[type=date]:focus{
outline-color: #71B0D0;
}


input::-webkit-datetime-edit {
    // position: absolute;
    // left: 30px;
   display: none;
}
input[type='date'], input[type='time'], input[type='datetime-local'], input[type='month'] {
    justify-content: left;
    -webkit-appearance: listbox;
    display: flex;
}
input[type='date'], input[type='time'], input[type='datetime-local'], input[type='month']
input:focus::-webkit-datetime-edit {
    border: 1px solid #DFE1E6 !important; 
}

input[type="date"]::before{
    color:#9A9EA6;
        @if attr(value)!= ""{
            content:attr(value);
        } @else if attr(value) =="" {
            
            content:attr(placeholder)
        }
       
    // content:attr(placeholder) ;
    left: 36px;
    position: absolute;
font-family: Montserrat-medium;
font-size: 12px;
font-weight: 500;
line-height: 14px;
letter-spacing: 0em;
text-align: left;
// background-image: url(../../../../assets/icons/not_focus/Down.svg);
// background-repeat: no-repeat;
// background-size: cover;
 }
 input[type="date"]::after{
    position: absolute;
content: url(../../../../assets/img/date.svg);
 background-repeat: no-repeat;
 background-size: cover;
 right: 9px;
 height: 18px;
 }
 input[type="date"]::-webkit-calendar-picker-indicator {

    position: absolute;
    background-image: url(../../../../assets/img/Time.svg);
     background-repeat: no-repeat;
     background-size: cover;
     left: 9px;
     height: 18px;

  
  }
//   input[type="date"]::before {

//     position: absolute;
//     content: url(../../../../assets/img/Time.svg) "         select Date";
//      background-repeat: no-repeat;
//      background-size: cover;
//      left: 9px;
//      height: 18px;
//   gap: 10px;
//   }