@import '../../../../constants/variables';
.upload-details-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	.content-containers {
		width: 100%;
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 0px 40px;

		.upload-wrapper {
			max-height: calc(100vh - 222px);
			overflow-y: auto;
			.upload-content-container {
				background: $neutral-white;
				border-radius: 8px;
				box-shadow: 0px 16px 32px -24px $gray;
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-bottom: 25px;
				.upload-info-wrapper {
					height: 48px;
					background: $secondary-teal-25;
					border-radius: 8px;
					display: flex;
					align-items: center;
					margin-top: 32px;
					padding-left: 16px;
					padding-right: 16px;
					.info-content {
						display: flex;
						align-items: center;
						.upload-info {
							color: $darkblue;
							font-size: 14px;
							font-family: $font-family-medium;
							font-weight: $font-weight-500;
							line-height: 16px;
							margin: 0px;
							margin-left: 16px;
						}
					}
					.edit-btn {
						border-radius: 8px;
						background: $grayscale-50;
						border: none;
					}
				}
				.sub-info-wrapper {
					height: 48px;
					background: rgba(206, 232, 255, 0.25);
					border-radius: 8px;
					display: flex;
					align-items: center;
					margin-top: 8px;
					padding-left: 16px;
					.info-content {
						display: flex;
						align-items: center;
						.info-icon {
							width: 35px;
							height: 35px;
							margin-left: 18px;
						}
						.description {
							color: $darkblue;
							font-size: 14px;
							font-family: $font-family-medium;
							font-weight: $font-weight-500;
							line-height: 16px;
							margin: 0px;
							margin-left: 16px;
						}
					}
				}

				.upload-spreadsheet-wrapper {
					width: 93%;
					margin: 32px;
					height: 100%;
					display: flex;
					gap: 32px;
					.upload-dragger-wrapper {
						width: 56%;
						display: flex;
						flex-direction: column;
						gap: 16px;
						.upload-description-background-color {
							background-color: rgba(255, 246, 245, 1);
						}
						.description-background-color {
							background: $grayscale-50;
						}
					}
					.upload-intro-video-wrapper {
						width: 50%;
						border-radius: 16px;
						box-shadow: 0px 19.2px 38.4px -28.8px #bdbdbd;
					}
				}
				.dragger-type-wrapper {
					width: 100%;
					display: flex;
					gap: 16px;
					flex-direction: column;
					padding: 32px;
					.edit-data {
						.edit-data-title {
							color: $blue-500;
							font-size: 12px;
							font-family: 'Montserrat-Medium';
							font-weight: 500;
							line-height: 14px;
						}
						.allowanceData {
							width: 650px;
							word-wrap: break-word;
							color: $blue-500;
							font-size: 12px;
							font-family: 'Montserrat-Medium';
							font-weight: 500;
							line-height: 14px;
						}
					}
					.description-background-color {
						background: $grayscale-50;
					}
					.dragger-type {
						width: 100%;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						.step {
							color: $blue-500;
							font-size: 12px;
							font-family: $font-family-medium;
							font-weight: $font-weight-500;
							line-height: 14px;
							margin: 0px;
							margin-bottom: 8px;
						}
						.dragger-wrapper {
							width: 100%;
							background: $grayscale-50;
							border-radius: 8px;
							justify-content: space-around;
							align-items: center;
							.main-container-dragger {
								height: 132px;
								.dragger {
									height: 132px;
								}
							}
						}
					}
				}
			}
		}
	}
}
.payroll-video-container {
	width: 100%;
	height: 100%;
	border-radius: 32px;
	background: #000;
	box-shadow: 0px 16px 32px -24px #bdbdbd;
}
