@import '../../../constants/_variables.scss';
.atmQuestion {
	font-weight: normal !important;
	font-family: 'Montserrat-Regular' !important;
}
.employee-presentational {
	padding: 0px 40px 0px 40px;
	width: 100%;
	.employee-col {
		width: 100%;
		.wps-rules-header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				color: $primaryColor5;
			}
		}
		.go-back-button {
			width: 120px;
			cursor: pointer;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 16px;
				color: $primaryColor5;
			}
		}
		.employee-questions {
			background-color: white;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;
			padding: 32px;
		}
		.questions-conter {
			margin-bottom: 16px;
			border: 1px solid #f7f8fa;
			border-radius: 8px;
			.question {
				cursor: pointer;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				height: 48px;
				background-color: #f7f8fa;
				padding: 16px 32px;
				border-radius: 8px;

				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 18px;
					color: $primaryColor5;
				}
				.icon {
					width: 12px;
					height: 12px;
					cursor: pointer;
				}
			}
			.question-item {
				color: #737780;
				padding: 10px 32px;
				.atmQuestion {
					font-weight: bold !important;
				}
				span {
					font-size: 14px;
				}
			}
			.answer {
				padding-top: 8px;
				display: flex;
				flex-direction: row;
				padding: 16px 32px;
				border-radius: 8px;
				.card {
					width: 103px;
					height: 103px;
					background: $greyColor2;
					border-radius: 24px;
				}
				.pay-atleast {
					display: flex;
					align-items: center;
					justify-content: center;
				}
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor7;
				}
			}
			.card-box {
				margin-top: 24px;
				width: 459px;
				height: 221px;
				background: $greyColor2;
			}
			.card-box2 {
				width: 326px;
				height: 221px;
				background: $greyColor2;
			}
			.middle-content {
				padding: 24px 0px;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor7;
				}
			}
		}
		.questions-conter1 {
			border: 1px solid #f7f8fa;
			border-radius: 8px;
			.question {
				cursor: pointer;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				height: 48px;
				background-color: #f7f8fa;
				padding: 16px 32px;
				border-radius: 8px;

				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 18px;
					color: $primaryColor5;
				}
				.icon {
					width: 12px;
					height: 12px;
					cursor: pointer;
				}
			}
			.question-item {
				color: #737780;
				padding: 10px 32px;
				.atmQuestion {
					font-weight: bold !important;
				}
				span {
					font-size: 14px;
				}
			}
			.answer {
				padding-top: 8px;
				display: flex;
				flex-direction: row;
				padding: 16px 32px;
				border-radius: 8px;
				.card {
					width: 103px;
					height: 103px;
					background: $greyColor2;
					border-radius: 24px;
				}
				.pay-atleast {
					display: flex;
					align-items: center;
					justify-content: center;
				}
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor7;
				}
			}
			.card-box {
				margin-top: 24px;
				width: 459px;
				height: 221px;
				background: $greyColor2;
			}
			.card-box2 {
				width: 326px;
				height: 221px;
				background: $greyColor2;
			}
			.middle-content {
				padding: 24px 0px;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor7;
				}
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.employee-presentational {
		width: 100%;
		padding: 64px 10px 67px 15px;
		.employee-col {
			width: 100%;
			padding-bottom: 65px;
			.wps-rules-header {
				margin-top: 0px;
				span {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 20px;
					line-height: 24px;
					color: $primaryColor5;
				}
			}
			.questions-conter {
				.question {
					padding-top: 24px;
					span {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 18px;
						color: $primaryColor5;
					}
				}
				.answer {
					padding-top: 8px;
					display: flex;
					flex-direction: column;
					padding-left: 3px;

					.card {
						width: 120px;
						height: 120px;
						background: $greyColor2;
						border-radius: 24px;
					}
					.pay-atleast {
						padding-left: 0px;
					}
					span {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 16px;
						color: $greyColor7;
					}
				}
			}
		}
	}
}
