@import '../../../../constants/_variables.scss';

.Request-statement-container {
	width: 100%;
	height: 80vh;
	
	.Request-statement-presentational {
		width: 100%;
		padding: 0 5% 0 5%;
		.Request-statement-content {
			padding: 0px 0px 0px 0px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 18px;
				line-height: 20px;
				color: #484b52;
			}
		}
		.Request-statement {
			display: flex;
			flex-direction: row;
			padding: 0% 0%;
			
			.statement-heading
			{
				font-size: 32px;
				line-height: 38px;
				font-weight: 700;
				color: #00344E;
			}

			.statement-sub-heading
			{
				padding: 10px 10px 10px 0px;
				font-weight:bold ;
				font-size: 18px;
				color: #484B52;
			}
			.statement-bullet-point
			{
				padding: 10px 10px 10px 0px;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;				
			}
		}
		.health_all_elements {
			padding: 40px 0px 0px 0px;
		}
		
		.health-mobile-view {
			display: none;
		}
		.mobile-view-Insurance-content {
			display: none;
		}
		.mobile-view-Insurance-heading {
			display: none;
		}
	}
}

@media only screen and (max-width: 600px) {
	.Request-statement-container {
		width: 100%;
		height: 100%;
		.Request-statement-presentational {
			width: 100%;
			padding: 65px 2% 0% 3%;
			overflow-y: scroll;
			text-align: center;
			//padding: 52px 135px 10px 135px;
			.Request-statement-content {
				padding: 30px 0px 16px 0px;
				text-align: center;
			}
			.mobile-view-Request-statement-content {
				padding: 30px 0px 16px 0px;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 18px;
					line-height: 20px;
					color: #484b52;
				}
			}
			.Request-statement {
				display: flex;
				flex-direction: column;
				padding: 0% 0%;
				
			}
			.health_all_elements {
				display: none;
			}
			.health-mobile-view,
			.mobile-view-Request-statement-content,
			.mobile-view-Insurance-heading {
				text-align: center;
				display: block;
			}
			.health-isurance-web {
				display: none;
			}
		}
	}
}
@media only screen and (max-width: 400px) {
	.Request-statement-container .Request-statement-presentational .ant-tabs-tab {
		margin: 0px 12px 0px 0px;
	}
}
