@import '../../constants/variables';
.payroll-plus-new-top {
	align-self: flex-start;
	.payroll_plus_new_header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 24px 0px 24px 0px;
		position: sticky;
		top: 0;
		// payroll header dropdown needs to override the table header so the zindex always needs to be higher than the table zindex
		z-index: 5;
		background: $grayscale-200;
		box-shadow: 0px 8px 24px -12px #f7f8fa;
		.run_payroll_plus,
		.payroll_plus_amount {
			display: flex;
			align-items: center;
			gap: 8px;
		}
		.payroll_plus_title_calendar {
			display: flex;
			.payroll_salary_dropdown {
				margin-left: 24px;
				width: 250px;
				.multiSelect-dropdown-container {
					.dropdown_header {
						width: 70.5%;
						.dropdown_inner {
							background: transparent;
							.dropDown-menu {
								flex-wrap: nowrap;
								gap: 0px;
								.dropdown_default {
									padding: 0px;
									.dropdown_inner_list_main {
										margin-left: 3px;
										font-size: 18px;
										font-weight: 700;
										line-height: 20px;
										letter-spacing: 0em;
										background-image: linear-gradient(to right, #0e5274, #4991b6);
										color: transparent;
										-webkit-background-clip: text;
										background-clip: text;
									}
								}
								.dropdown_placeHolder {
									margin-left: 5px;
								}
							}
						}
						.dropdown_list {
							.dropdown_inner_list {
								.dropdown-list-sub {
									.dropdown_inner_list {
										.dropdown_inner_list_main {
											font-family: unset;
											font-weight: 300;
										}
									}
									// while hovering dropdown value
									.dropdown_inner_list:hover {
										background: #f7f8fa;
										border-radius: 8px;
									}
								}
							}
							#selected {
								.dropdown_inner_list_main {
									font-family: 'Montserrat-Bold';
								}
							}
						}
						#dropdown_list {
							margin-top: 4px;
						}
					}
				}
			}
		}
	}
	.payroll-allowance-data-wrapper {
		padding: 8px 8px 8px 16px;
		background: rgba(204, 236, 230, 0.5);
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 8px;
		border-radius: 8px;
		.payroll-allowance-data {
			display: flex;
			align-items: center;
			gap: 16px;
		}
		.cancel-btn {
			border-radius: 8px;
			background: rgba(251, 252, 253, 1);
			border: none;
		}
	}
	.payroll-upload-employee-data-wrapper {
		padding: 8px 8px 8px 16px;
		background: rgba(254, 245, 232, 1);
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 24px;
		border-radius: 8px;
		.payroll-upload-employee-data {
			display: flex;
			align-items: center;
			gap: 16px;
		}
		.onboarding-btn {
			border-radius: 8px;
			background: rgba(251, 252, 253, 1);
			border: none;
		}
	}
	.payroll-plus-new-footer {
		position: sticky;
		width: 100%;
		bottom: 0;
		z-index: 4;
		height: unset !important;
		display: flex;
		align-items: flex-end;
		.bottom-bar {
			display: flex;
			justify-content: space-between;
			height: 100px;
			width: 100%;
			background: #f7f8fa;
			border-radius: 28px 28px 0 0;
			.payroll-plus-new-amount {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				.payroll-plus-new-value {
					margin-top: 5px;
					font-family: 'Montserrat-Medium';
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 18px;
					color: #00344e;
				}
				.payroll-plus-new-values {
					margin-top: 5px;
					font-family: 'Montserrat-Regular';
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 18px;
					color: #00344e;
					margin-left: 8px;
				}
			}
			.payroll-plus-new-buttons {
				display: flex;
				align-items: center;
				justify-content: center;
				.button-payrollNew {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.payroll-plus-newUpload {
					font-family: 'Montserrat-Medium';
					text-align: center;
					color: #484b52;
					padding: 8px 24px !important;
					width: 65px !important;
				}
				.payroll-plus-newNext {
					margin-left: 8px !important;
					padding: 8px 24px !important;
					width: 65px !important;
				}
			}
		}
	}
	.prehighlight-bolds {
		.ant-input-prefix {
			color: #00344e;
			font-family: Montserrat-Medium !important;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 14px;
		}
	}
	.payrollPlus {
		width: 108px;
	}
	.payroll-plus-new {
		padding: 0 40px;
		.ant-table-header {
			height: unset !important;
			background-color: unset !important;
		}
		.table-outer {
			padding-bottom: 40px;
			margin-top: 16px;
			height: 580px;
			.table_column:hover {
				// while hovering column it will take higher precedence to override other index
				z-index: 5;
			}
			.sticky_table_header {
				// while scrolling the table at right some columns needs to stick so z-index needs to be higher than the tbody index and below one
				z-index: 4;
			}
			th {
				background: #fbfcfd;
				position: sticky;
				top: 0;
				z-index: 3;
			}
			overflow: scroll;
			.payroll_plus_table_check_box {
				background: #8ef9b3;
				border-radius: 4px;
				width: 100%;
				height: 15px;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: 8px;
					height: 8px;
				}
			}
			.salary-exceeds-error {
				background: #ff9691;
			}
			.arrow-container {
				position: fixed;
				top: 56%;
				cursor: pointer;
				font-size: 29px;
				z-index: 1000;
				right: 4%;
				width: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 50px;
				border-radius: 50%;
				opacity: 0;
			}
			.arrow-container:hover {
				background-color: #ea6257;
				box-shadow: 0px 0px 30px 0px rgb(234, 98, 87, 0.44);
				transition: 0.3s ease-in;
				opacity: 1;
				img {
					transition: none;
					filter: brightness(0) invert(1);
				}
			}
			.edenred-table {
				thead {
					tr {
						th {
							padding: 17px 16px;
						}
					}
				}
				tbody {
					tr {
						td {
							padding: 17px 16px;
						}
					}
				}
			}
			.payroll-plus-new-table {
				.ant-table-cell-fix-left {
					background-color: #f7f8fa;
				}
				th {
					&.ant-table-cell-fix-sticky {
						background-color: #dd483d !important;
					}
				}
				.ant-table-ping-left .ant-table-cell-fix-left-last {
					&::after {
						box-shadow: none;
					}
				}
				.ant-spin-nested-loading {
					.ant-spin-container {
						margin-bottom: 32px !important;
						margin-right: -10px;
						.ant-table-pagination.ant-pagination {
							padding-right: 15px;
							padding-top: 14px;
						}
					}
				}
				.ant-table-thead {
					> tr > th {
						background-color: #ea6257;
						color: #ffffff;
					}
				}
				.ant-table-column-sorter {
					margin-right: 15px !important;
				}
				.ant-table-container {
					&::after {
						box-shadow: unset !important;
					}
					table > thead > tr:first-child th:first-child {
						display: table-cell;
						text-align: center !important;
						border-top-left-radius: 16px;
					}
					table > thead > tr:first-child th:last-child {
						border-top-right-radius: 16px;
					}
					.ant-table-header {
						border-top-left-radius: 16px !important;
						border-bottom-left-radius: 16px !important;
						box-shadow: 0 -25px 0 0 #fffefe;
					}
					.ant-table-body {
						max-height: unset !important;
					}
					.ant-table-sticky-scroll {
						display: none;
						.ant-table-sticky-scroll-bar {
							display: none;
						}
					}
				}
			}
			.table-end-opacity {
				height: 100%;
				position: absolute;
				background-color: red;
			}
			.rightIcon-scroll {
				right: 10;
				top: 50%;
				position: absolute;
			}
		}
		// If want to hide scrollbar will use this
		/* Hide scrollbar for Chrome, Safari and Opera */
		// .table-outer::-webkit-scrollbar {
		// 	display: none;
		// }

		// /* Hide scrollbar for IE, Edge and Firefox */
		// -ms-overflow-style: none; /* IE and Edge */
		// scrollbar-width: none; /* Firefox */
		.dropdown-inner-container {
			display: flex;
			flex-direction: column;
			width: 94%;
			padding-right: 16px;
			.ant-select {
				display: flex;
				font-family: Montserrat-Light;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				letter-spacing: 0.5px;
				height: 40px;
				min-height: 40px;
				color: #484b52;
				.ant-select-selector {
					display: flex;
					align-items: center;
					height: 40px;
					width: 100%;
					border: 2px solid #dfe1e6;
					box-sizing: border-box;
					border-radius: 8px;
				}
				.ant-select-selection-item {
					justify-content: space-between !important;
					align-items: center;
					display: flex;
				}
				.ant-select-selection-placeholder {
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 18px;
					letter-spacing: 0.5px;
					color: #9a9ea6 !important;
				}
				.ant-select-arrow {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 20px;
					color: #11266900;
					height: 28px;
					width: 28px;
					margin-top: -15px;
					margin-right: 0px;
				}
				.ant-select-arrow ::after {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 20px;
					color: #11266900;
					height: 28px;
					width: 28px;
					margin-top: -15px;
					margin-right: 0px;
				}
				.ant-select-item {
					font-family: 'Montserrat-Medium';
					font-size: 14pt;
					font-weight: 500;
					background: #141414;
				}
				.ant-select-dropdown {
					background: #ffffff;
					box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
					border-radius: 15px !important;
				}
			}
			.ant-select:active {
				background: #ffffff;
				box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
				border-radius: 8px;
				outline: none;
				z-index: 10;
			}
		}
		.payroll-plus-clearAll {
			.payroll-plus-clearAll-bottomOne {
				cursor: pointer !important;
			}
			.payroll-plus-clearAll-bottomTwo {
				cursor: pointer !important;
			}
		}
		.payroll-plus-typo {
			font-family: 'Montserrat-Bold';
			font-style: normal;
			font-weight: bold;
			line-height: 28px;
			color: #00344e;
		}
		.payroll-plus-filters-popup {
			min-height: 64px;
			background: #ffffff;
			width: 74.5%;
			box-shadow: 0px 10px 20px rgb(174 182 183 / 50%);
			border-radius: 16px;
			position: absolute;
			z-index: 10;
			padding: 15px;
			margin-top: 3px;
			border: 2px solid #eff1f5;
			.payroll-plus-new-filters-container {
				display: flex;
				justify-content: space-evenly;
				width: 100%;
				position: relative;
				.payroll-plus-new-filters-inside {
					display: flex;
					justify-content: flex-end;
				}
				.payroll-plus-filter-tiles {
					min-width: 100px;
					width: 96%;
					display: flex;
					justify-content: space-between;
					font-family: 'Montserrat-Regular';
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					min-height: 32px;
					color: #00344e;
					margin-bottom: 10px;
				}
			}
			.payroll-plus-clearAll {
				display: flex;
				justify-content: flex-end;
				padding-top: 5px;
				.payroll-plus-clearAll-bottomOne {
					padding-right: 15px;
					color: #00344e;
				}
				.payroll-plus-clearAll-bottomTwo {
					color: #00344e;
				}
			}
		}
		.dropdown-inner-container {
			display: flex;
			flex-direction: column;
			width: 53% !important;
			.ant-select {
				display: flex;
				font-family: 'Montserrat-Light';
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				letter-spacing: 0.5px;
				height: 40px;
				min-height: 40px;
				color: #484b52;
				.ant-select-selector {
					display: flex;
					align-items: center;
					height: 40px;
					width: 100%;
					border: 2px solid #eff1f5 !important;
					box-sizing: border-box;
					border-radius: 8px;
				}
				.ant-select-selection-item {
					justify-content: space-between !important;
					align-items: center;
					display: flex;
				}
				.ant-select-selection-placeholder {
					font-family: 'Montserrat-Light';
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					letter-spacing: 0.5px;
					color: #9a9ea6 !important;
				}
				.ant-select-arrow {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 20px;
					color: #11266900;
					content: url('../../assets/icons/not_focus/Down.svg');
					height: 28px;
					width: 28px;
					margin-top: -15px;
					margin-right: 0px;
				}
				.ant-select-arrow ::after {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 20px;
					color: #11266900;
					content: url('../../assets/icons/not_focus/Up.svg');
					height: 28px;
					width: 28px;
					margin-top: -15px;
					margin-right: 0px;
				}
				.ant-select-item {
					font-family: 'Montserrat-Medium';
					font-size: 14pt;
					font-weight: 500;
					background: #141414;
				}
				.ant-select-dropdown {
					background: #ffffff;
					box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
					border-radius: 15px !important;
				}
			}
			.ant-select:active {
				background: #ffffff;
				box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
				border-radius: 8px;
				outline: none;
				z-index: 10;
			}
		}
		.payroll_plus_new_table {
			width: 100%;
			padding: 32px;
			background: $neutral-white;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;
			.payroll_plus_new_table_header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				.payroll-plus-filters {
					display: flex;
					align-items: center;
					gap: 8px;
					width: 75%;
					.main_filter_button {
						width: unset;
						.filter_main {
							// filters needs to higher index than any other table datas.
							z-index: 5;
						}
					}
					.payroll-plus-filterButton-checked {
						width: 103px;
						height: 40px;
						border-radius: 8px;
						border: 2px solid #eff1f5;
						min-height: 40px !important;
						margin-right: 10px;
						box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.5);
					}
					.payroll-plus-dateField {
						display: flex;
						justify-content: space-between;
						max-width: 306px;
						width: 100%;
						height: 40px;
						min-height: 40px !important;
						border-radius: 8px !important;
						margin-right: 16px;
						.blueIcon {
							height: 9px;
						}
					}
					.edenRedSearch {
						height: 40px;
						min-height: 40px !important;
						width: 60%;
					}
					.payroll-plus-previous {
						font-family: 'Montserrat-Medium';
						.payroll-plus-down {
							margin-left: 8px;
						}
					}
				}
				.payroll_plus_new_entries {
					display: flex;
					align-items: center;
					gap: 10px;
					.entries_check_box {
						background: #8ef9b3;
						border-radius: 4px;
						width: 100%;
						height: 13px;
						display: flex;
						align-items: center;
						justify-content: center;
						img {
							width: 6px;
							height: 6px;
						}
					}

					.multi-select-dropdown-component {
						width: 55px;
					}
					.multiSelect-dropdown-container .dropdown_header .dropdown_inner {
						height: 32px;
						position: relative;
						border: 1px solid #dfe1e6 !important;
						.dropDown-menu {
							margin: 0 0 0 -8px;
							.dropdown_default {
								background: none;
								.dropdown_inner_list_main {
									font-family: 'Montserrat-Medium';
									// color: #9a9ea6;
								}
							}
						}
						.dropdown_arrow {
							position: absolute;
							right: 8px;
							.arrow-inner {
								height: 15px;
								width: 15px;
							}
						}
					}
					.multiSelect-dropdown-container .dropdown_header .dropdown_list {
						margin: 0px;
						.dropdown_inner_list {
							div {
								justify-content: center;
								label {
									padding: 0px !important;
									span {
										div:nth-child(1) {
											width: 14px !important;
											height: 14px !important;
											border-width: 1px !important;
											border-radius: 4px !important;
										}
									}
								}
							}
						}
					}
					.multiSelect-dropdown-container .dropdown_header {
						.dropdown_list {
							// pagination entries should need to be higher index than table index
							z-index: 5;
						}
					}
				}
			}
		}
		.payroll_plus_pagination {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-top: 24px;
			.pagination-container {
				margin: 0px;
				padding: 0px;
			}
		}
	}
}
.payroll-plus-dropdownMenus {
	border-radius: 24px;
}
.payroll-plus-new-dropdown {
	min-height: 32px !important;
	height: 32px !important;
	width: auto;
	.payroll-plus-new-dropdownIcon {
		height: 27px;
	}
}
.left-icon {
	transform: rotate(180deg);
	transition: 0.5 ease-in;
}

.payroll-fund-transfer-popup {
	display: flex;
	width: 522px !important;
	height: 492px;
	flex-direction: column;
	box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.5);
	border-radius: 40px !important;
	padding: 32px;
	background-color: white;
	.ant-modal-content {
		position: none;
		border-radius: none;
		box-shadow: none;
		.ant-modal-body {
			.fund-transfer-section {
				display: flex;
				flex-direction: column;
				width: 100%;
				align-items: center;
				justify-content: center;
				.account-number-payroll-transfund-card-section {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					padding: 24px;
					gap: 16px;
					height: 156px;
					background: #f7f8fa;
					border-radius: 32px;
					.card-title-style {
						font-family: 'Montserrat-medium';
						font-style: normal;
						font-weight: 500;
						font-size: 20px;
						line-height: 24px;
						text-align: center;
						color: #00344e;
					}
					.account-number-holder {
						display: flex;
						flex-direction: row;
						align-items: flex-start;
						padding: 16px 32px;
						// width: 242px;
						height: 68px;
						background: #ffffff;
						border-radius: 24px;
						.acct-number-style {
							font-family: 'Montserrat-medium';
							font-style: normal;
							font-weight: 700;
							font-size: 32px;
							line-height: 36px;
							text-align: center;
							color: #484b52;
						}
					}

					.reconc-account-input {
						height: 30px;
						width: 346px;
						font-weight: bolder;
						color: #484b52;
					}
					.input_img {
						bottom: 0px;
					}
				}
				.fund-transfer-info-section {
					display: flex;
					flex-direction: column;
					margin-top: 32px;
					align-items: center;
					justify-content: center;
					.info-title {
						font-family: 'Montserrat-bold';
						font-style: normal;
						font-weight: 700;
						font-size: 24px;
						line-height: 28px;
						text-align: center;
						color: #00344e;
					}

					.tranfer-fun-info-style {
						font-family: 'Montserrat-light';
						font-style: normal;
						font-weight: 300;
						font-size: 18px;
						line-height: 20px;
						margin-top: 9px;
						text-align: center;
					}

					.tranfer-fun-info-style2 {
						font-family: 'Montserrat-light';
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 16px;
						margin-top: 32px;
						text-align: center;

						.click-here-style {
							font-family: 'Montserrat-medium';
							font-style: normal;
							font-weight: 300;
							font-size: 14px;
							line-height: 16px;
							color: #0e5274;
							text-decoration: underline;
							cursor: pointer;
						}
					}
				}
				.fund-transfer-button-container {
					display: flex;
					margin-top: 32px;

					.undertood-style {
						font-family: 'Montserrat-medium';
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 20px;
						text-align: center;
						color: #f7f8fa;
					}
				}
			}
		}
	}
}
@media (max-width: 768px) {
	.payroll-plus-new-amount {
		align-items: center;
		padding: 5px 0 5px 0;
	}
}
.payroll_select {
	width: 33px;
	height: 33px;
	cursor: pointer;
}
.payroll_block_select {
	pointer-events: none;
	opacity: 0.3;
}
.table-outer {
	margin-top: 16px;
	overflow: scroll;
	&::-webkit-scrollbar {
		height: 6px !important;
	}
}
