.shedenredTypo {
	color: #00344e;
	&.light {
		font-family: 'Montserrat-Light';
		font-style: normal;
		font-weight: 300;
	}
	&.medium {
		font-family: 'Montserrat-Medium';
		font-style: normal;
		font-weight: 500;
	}
	&.bold {
		font-family: 'Montserrat-Bold';
		font-style: normal;
		font-weight: 700;
	}
	&.regular {
		font-family: 'Montserrat-Regular';
		font-style: normal;
		font-weight: 300;
	}
	&.h1 {
		font-size: 32px;
		line-height: 36px;
	}
	&.h2 {
		font-size: 24px;
		line-height: 28px;
	}
	&.h3 {
		font-size: 20px;
		line-height: 24px;
	}
	&.h4 {
		font-size: 18px;
		line-height: 20px;
	}
	&.h5 {
		font-size: 16px;
		line-height: 18px;
	}
	&.b1 {
		font-size: 14px;
		line-height: 16px;
	}
	&.b2 {
		font-size: 12px;
		line-height: 14px;
	}
	&.b3 {
		font-size: 10px;
		line-height: 12px;
	}
	&.b4 {
		font-size: 8px;
		line-height: 10px;
	}
	&.small {
		font-size: 10px;
		line-height: 12px;
	}
}
