.setup-permission {
	display: flex;
	justify-content: center;
	padding: 0px 240px 0px 240px;
	background: #f7f8fa;
	.manage_team {
		padding: 0px;
		width: 960px;
	}
}
.setup-invite-teammember {
	display: flex;
	align-items: center;
	height: 100%;
	padding: 0px 240px 0px 240px;
	background-color: #f7f8fa;
	justify-content: center;

	.invite-team-memeber {
		width: 960px;
		padding: 0px;
	}
}
