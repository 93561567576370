.for-carousel-modal {
	background: #ffffff;
	box-shadow: 0px 8px 24px -12px #bdbdbd;
	border-radius: 40px;
	.ant-modal-body {
		height: 535px !important;
		border-radius: 40px;
	}
	.ant-modal-content {
		border-radius: 40px !important;
	}
	.text-modal-head {
		margin-top: 10px;
		font-family: 'Montserrat-Bold';
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 28px;
		color: #00344e;
		text-align: center;
		margin-bottom: 25px;
	}
	.text-modal-button {
		font-family: 'Montserrat-Regular';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		color: #f7f8fa;
		padding: 8px 24px;
		text-align: center;
		background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%);
		box-shadow: 0px 5px 10px rgba(233, 180, 175, 0.75);
		border-radius: 16px;
		border: none;
		margin-top: 5%;
		cursor: pointer;
	}
}
.carousel_main_tour {
	background: #f8f9f9;
	border-radius: 40px;
	height: 83%;
	.slider_main {
		.image_slider {
			width: 79%;
			height: 285px;
			margin-top: 20px;
		}
	}
}
.slider_pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	.mainPagination {
		width: 8px;
		height: 8px;
		border-radius: 10px;
		background: #dfe1e6;
	}
	.Pagination {
		background: #d52b1e;
	}
}
