@import '../../../constants/variables';
.for_hr_document {
	align-items: unset !important;
	padding-top: 0 !important;
	display: block !important;
}
.main-dashboard-web-presentational {
	width: 100%;
	display: flex;
	padding: 40px 40px 0px 40px;
	height: 100%;
	flex-direction: column;
	row-gap: 16px;

	// padding-left: 5px;
	.main_dashboard_web_first_row {
		// display: flex;
		// justify-content: space-between;
		width: 100%;
		// column-gap: 16px;
		.available_balance_card_column {
			// padding: 0px 4px;
			// width: 30%;
			// min-height: 136px;
			.available_balance_card {
				background: #ffffff;
				border: none;
				box-shadow: 0px 16px 32px -24px #bdbdbd;
				border-radius: 8px;
				width: 95%;
				height: 136px;
				.ant-card-body {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 100%;
					padding: 16px;
					.available_balance_details_row {
						display: flex;
						padding: 2px 4px;
						width: 100%;
						.available_balance_image {
							border-radius: 8px;
							background: #ccece6;
							box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
							height: 52px;
							width: 52px;
							display: grid;
							place-items: center;
						}
						.available_balance_details {
							display: inline-grid;
							padding-left: 10px;
							.available_balance_title {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 12px;
								line-height: 14px;
								color: #181919;
							}
							.available_balance-details-row {
								display: flex;
								flex-direction: row;
								.amount_interger_part {
									font-family: Montserrat-Bold;
									font-style: normal;
									font-weight: bold;
									font-size: 32px;
									line-height: 36px;
									color: $primaryColor5;
								}
								.amount_interger_part-size,
								.amount_decimal_part_size,
								.curency_name-size {
									font-size: 20px;
									font-family: Montserrat-Bold;
									font-style: normal;
									font-weight: bold;
									line-height: 36px;
									color: $primaryColor5;
								}
								.amount_decimal_part {
									font-family: Montserrat-Bold;
									font-style: normal;
									font-weight: bold;
									font-size: 20px;
									line-height: 20px;
									color: $primaryColor5;
								}
								.curency_name {
									padding-left: 4px;
									font-family: Montserrat-Bold;
									font-style: normal;
									font-weight: bold;
									font-size: 20px;
									line-height: 20px;
									color: $primaryColor5;
								}
							}
						}
					}
					.view_button_row {
						display: flex;
						justify-content: center;
						padding-top: 16px;
						button {
							height: 32px;
							width: auto;
							min-height: 32px;
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 300;
							font-size: 14px;
							line-height: 16px;
							color: #484b52;
							padding: 8px 16px;
							border-radius: 8px;
						}
					}
				}
			}
		}

		.gauge_chart_card_column {
			// padding: 0px 4px;
			// width: 40%;
			// min-height: 136px;
			.gauge_chart_card {
				display: flex;
				// height: 124px;
				// max-height: 124px;
				background: #ffffff;
				border: none;
				box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
				border-radius: 8px;
				height: 136px;
				width: 96%;
				.ant-card-body {
					display: flex;
					align-items: center;
					width: 100%;
					padding: 14px 32px;
				}
			}
		}
		// .wps_nonwps_timing_details_card_column {
		// padding: 0px 6px;
		// width: 30%;
		// min-height: 136px;
		.wps_nonwps_timing_details_card {
			display: flex;
			background: #ffffff;
			border: none;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;
			height: 136px;
			padding: 16px;
			.change-verifier {
				width: 100%;
				// padding: 0px 16px;
				.approval_setting_text {
					height: 60px;
				}
				.change-approval-settings {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 20px;
					color: #00344e;
					margin-bottom: 0px;
				}
				.change-approval-content {
					padding-top: 4px;
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 10px;
					line-height: 12px;
					color: #181919;
					margin-bottom: 0px;
					width: 100%;
					&:hover {
						overflow: auto;
						text-overflow: inherit;
						cursor: pointer;
					}
					&::-webkit-scrollbar {
						display: none;
					}
				}
				@media screen and (max-width: 1210px) {
					.change-approval-content {
						padding-top: 4px;
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 10px;
						line-height: 12px;
						color: #181919;
						margin-bottom: 0px;
						width: 100%;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						&:hover {
							overflow: auto;
							text-overflow: inherit;
							cursor: pointer;
						}
						&::-webkit-scrollbar {
							display: none;
						}
					}
				}
				.Click_here_to_modify {
					display: flex;
					justify-content: center;
					// align-items: flex-end;
					padding-top: 12px;
					margin-bottom: 0px;
					button {
						height: 32px;
						border: 1px solid #dfe1e6;
						border-radius: 8px;
						width: auto;
						min-height: 24px;
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 16px;
						padding: 8px 16px !important;
						color: #484b52;
					}
				}
			}
			.ant-card-body {
				display: flex;
				flex-direction: column;
				/* justify-content: center; */
				align-self: center;
				align-items: normal;
				padding: 0px;
				width: 100%;
				.wps_nonwps_timing_details_row {
					// padding: 18px 4px 18px 32px;
					width: 100%;
					.wps_nonwps_timing_details_column {
						display: flex;
						flex-direction: column;
						.wps_nonwps_title {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 10px;
							line-height: 12px;
							color: #181919;
						}
						.wps_nonwps_timing {
							display: flex;
							flex-direction: column;
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 20px;
							color: #00344e;
						}
					}
				}
				.view_details_button_row {
					display: flex;
					justify-content: center;
					padding: 4px 0px;
					.Click_here_to_modify {
						display: flex;
						justify-content: center;
						// align-items: flex-end;
						padding-top: 12px;
						margin-bottom: 0px;
						button {
							height: 32px;
							border: 1px solid #dfe1e6;
							border-radius: 8px;
							width: auto;
							min-height: 24px;
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 16px;
							padding: 8px 16px !important;
							color: #484b52;
						}
					}
				}
			}
		}
		// }
	}
	.main_dashboard_web_second_row {
		display: flex;
		width: 100%;
		column-gap: 16px;
		// padding: 16px 0px 8px 0px;
		.payroll_history_card_column {
			max-width: calc(50% - 8px);
			.edenred-table td {
				padding: 6px 16px 6px 16px !important;
			}
			// padding: 0px 4px;
			.payroll_history_card {
				// height: 264px;
				// max-height: 264px;
				background: #ffffff;
				border: none;
				box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
				border-radius: 8px;
				padding: 16px;
				height: 238px;

				.ant-card-body {
					display: flex;
					flex-direction: column;
					padding: 0px 8px;
					width: 100%;
					.payroll_history_card_title_row {
						padding: 8px 0px;
						display: flex;
						justify-content: space-between;

						.payroll_history_title {
							display: flex;
							align-items: center;
							span {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 12px;
								line-height: 14px;
								color: $primaryColor5;
								padding-left: 10px;
							}
						}

						.payroll_history_icon {
							width: 24px;
							height: 24px;
							background: #fef5e8;
							border-radius: 8px;
							display: grid;
							place-content: center;
							img {
								height: 16px;
								// width: 16px;
							}
						}
						.payroll_history_card_title {
							display: flex;
							align-items: center;
							flex-direction: column;
							span {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 12px;
								line-height: 14px;
								color: $primaryColor5;
								padding-left: 10px;
							}
						}
						.navigation_icon_row {
							display: flex;
							justify-content: flex-end;
							.navigation_left_icon {
								border-radius: 50%;
								background: $greyColor2;
								height: 24px;
								width: 24px;
								margin-right: 2px;
								img {
									height: 20px;
									width: 20px;
									cursor: pointer;
								}
							}
							.navigation_right_icon {
								border-radius: 50%;
								background: $greyColor2;
								height: 24px;
								width: 24px;
								margin-left: 2px;
								img {
									height: 20px;
									width: 20px;
									cursor: pointer;
								}
							}
						}
					}
					.payroll_history_details_section {
						width: 100%;
						height: 120px;
						max-height: 120px;
						min-height: 120px;
						// display: flex;
						// align-items: center;
						.payroll_history_card_detail_row {
							padding: 7px 0px;
							width: 100%;
							display: flex !important;
							.payroll_history_status {
								box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
								border-radius: 16px;
								width: 4px;
								height: 40px;
								&.success {
									background: $feedbackSuccess;
								}
								&.warning {
									background: $feedbackWarning;
								}
								&.failed {
									background: $feedbackError;
								}
							}
							.payroll_history_card_detail_content {
								display: flex;
								flex-direction: column;
								.payroll_history_month {
									font-family: Montserrat-Medium;
									font-style: normal;
									font-weight: 500;
									font-size: 16px;
									line-height: 18px;
									color: $primaryColor5;
								}
								.payroll_status_text {
									padding-top: 8px;
									font-family: Montserrat-Regular;
									font-style: normal;
									font-weight: 300;
									font-size: 12px;
									line-height: 14px;
									color: $greyColor7;
								}
							}
						}
					}
					.view_all_button_row {
						display: flex;
						justify-content: flex-end;
						padding: 0px 0px;
						button {
							height: 24px;
							width: auto;
							min-height: 24px;
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 10px;
							line-height: 12px;
							padding: 8px 12px;
						}
					}
				}
			}
			::-webkit-scrollbar {
				display: none;
			}
		}
		.card_order_history_column {
			// padding: 0px 4px;
			max-width: calc(50% - 8px);

			.card_order_history {
				// height: 264px;
				// max-height: 264px;
				background: #ffffff;
				border: none;
				box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
				border-radius: 8px;
				padding: 16px;
				height: 238px;

				.ant-card-body {
					display: flex;
					flex-direction: column;
					padding: 0px 8px;
					width: 100%;
					.card_order_history_title_row {
						padding: 8px 0px;
						display: flex;
						justify-content: space-between;

						.card_order_title {
							display: flex;
							align-items: center;
							span {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 12px;
								line-height: 14px;
								color: $primaryColor5;
								padding-left: 10px;
							}
						}

						// .card_order_history_icon {
						.card_order_div {
							width: 24px;
							height: 24px;
							background: #e2d2fe;
							border-radius: 8px;
							display: grid;
							place-content: center;
							img {
								height: 16px;
								// width: 16px;
							}
						}
						// }
						.card_order_history_card_title {
							display: flex;
							align-items: center;
							span {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 12px;
								line-height: 14px;
								color: $primaryColor5;
								padding-left: 10px;
							}
						}
						.navigation_icon_row {
							display: flex;
							justify-content: flex-end;
							.navigation_left_icon {
								border-radius: 50%;
								background: $greyColor2;
								height: 24px;
								width: 24px;
								margin-right: 2px;
								img {
									height: 20px;
									width: 20px;
									cursor: pointer;
								}
							}
							.navigation_right_icon {
								border-radius: 50%;
								background: $greyColor2;
								height: 24px;
								width: 24px;
								margin-left: 2px;
								img {
									height: 20px;
									width: 20px;
									cursor: pointer;
								}
							}
						}
					}
					.card_order_history_details_section {
						width: 100%;
						height: 120px;
						min-height: 120px;
						max-height: 120px;
						.edenred-table td {
							padding: 6px 16px 6px 16px !important;
						}
						.card_order_history_detail_row {
							padding: 7px 0px;
							width: 100%;
							display: flex !important;
							.card_order_history_status {
								box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
								border-radius: 16px;
								width: 4px;
								height: 40px;
								&.success {
									background: $feedbackSuccess;
								}
								&.warning {
									background: $feedbackWarning;
								}
								&.failed {
									background: $feedbackError;
								}
							}
							.card_order_history_detail_content {
								display: flex;
								flex-direction: column;
								.card_order_history_no_of_cards {
									font-family: Montserrat-Medium;
									font-style: normal;
									font-weight: 500;
									font-size: 16px;
									line-height: 18px;
									color: $primaryColor5;
								}
								.card_order_history_text {
									padding-top: 8px;
									font-family: Montserrat-Regular;
									font-style: normal;
									font-weight: 300;
									font-size: 12px;
									line-height: 14px;
									color: $greyColor7;
								}
							}
						}
					}
					.view_all_button_row {
						display: flex;
						justify-content: flex-end;
						padding: 0px 0px;
						button {
							height: 24px;
							width: auto;
							min-height: 24px;
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 10px;
							line-height: 12px;
							padding: 8px 12px;
						}
					}
				}
			}
			::-webkit-scrollbar {
				display: none;
			}
		}
	}

	.main_dashboard_web_third_row {
		display: flex;
		width: 100%;
		column-gap: 16px;

		// padding: 8px 0px;
		.employee_paid_card_column {
			max-width: calc(50% - 8px);
			// padding: 0px 4px;
			.employee_paid_card {
				// height: 254px;
				// max-height: 248px;
				height: 248px;
				background: #ffffff;
				border: none;
				box-shadow: 0px 16px 32px -24px #bdbdbd;
				border-radius: 8px;
				padding: 16px;
				.ant-card-body {
					display: flex;
					flex-direction: column;
					padding: 0px 8px;
					width: 100%;
					.employee_paid_card_title_row {
						padding: 8px 0px;
						.employee_paid_icon {
							display: grid;
							place-content: center;
							.dollar_content_div {
								width: 24px;
								height: 24px;
								background: #cee8ff;
								border-radius: 8px;
								display: grid;
								place-content: center;
								img {
									height: 16px;
									// width: 16px;
								}
							}
						}
						.employee_paid_card_title {
							span {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 12px;
								line-height: 14px;
								color: $primaryColor5;
								padding-left: 10px;
								padding-bottom: 4px;
							}
						}
						.navigation_icon_row {
							display: flex;
							justify-content: flex-end;
							.navigation_left_icon {
								border-radius: 50%;
								background: $greyColor2;
								height: 24px;
								width: 24px;
								margin-right: 2px;
								img {
									height: 20px;
									width: 20px;
									cursor: pointer;
								}
							}
							.navigation_right_icon {
								border-radius: 50%;
								background: $greyColor2;
								height: 24px;
								width: 24px;
								margin-left: 2px;
								img {
									height: 20px;
									width: 20px;
									cursor: pointer;
								}
							}
						}
					}
					.employee_paid_card_details_section {
						// width: 100%;
						// padding-top: 9px;
						// height: 135px;
						// max-height: 135px;
						// min-height: 135px;
						max-height: 131px;
						overflow: auto;
						.employee_paid_current_month_detail_row {
							width: 100%;
							display: flex;
							align-items: flex-end;
							padding: 4px 0px;
							.employee_paid_month {
								span {
									font-family: Montserrat-Bold;
									font-style: normal;
									font-weight: 500;
									font-size: 16px;
									line-height: 18px;
									color: $primaryColor5;
								}
							}
							.employee_paid_percentage {
								span {
									font-family: Montserrat-Medium;
									font-style: normal;
									font-weight: 500;
									font-size: 32px;
									line-height: 36px;
									color: $greyColor7;
								}
							}
							.low_percentage {
								span {
									color: #ff9691;
								}
							}
							.employee_paid_no_of_employee {
								span {
									font-family: Montserrat-Medium;
									font-style: normal;
									font-weight: 500;
									font-size: 14px;
									line-height: 16px;
									color: #181919;
								}
							}
						}
						.employee_paid_detail_row {
							width: 100%;
							padding: 2px 0px;
							.employee_paid_month {
								span {
									font-family: Montserrat-Regular;
									font-style: normal;
									font-weight: 300;
									font-size: 14px;
									line-height: 16px;
									color: $greyColor7;
								}
							}
							.employee_paid_percentage {
								span {
									font-family: Montserrat-Medium;
									font-style: normal;
									font-weight: 500;
									font-size: 14px;
									line-height: 16px;
									color: $greyColor7;
								}
							}
							.low_percentage {
								span {
									color: #ff9691;
								}
							}
							.employee_paid_no_of_employee {
								span {
									font-family: Montserrat-Regular;
									font-style: normal;
									font-weight: 300;
									font-size: 14px;
									line-height: 16px;
									color: #181919;
								}
							}
							.employee_paid_no_of_employee_prev {
								span {
									font-family: 'Montserrat-Regular';
									font-style: normal;
									font-weight: 300;
									font-size: 14px;
									line-height: 16px;
									color: #181919;
								}
							}
						}
					}
					::-webkit-scrollbar {
						// overflow: hidden;
						display: none;
					}
					.employee_paid_card_footer {
						display: flex;
						padding: 10px 0px;
						.employee_info_text {
							font-family: 'Montserrat-Regular';
							font-style: normal;
							font-weight: 300;
							font-size: 10px;
							line-height: 12px;
							color: #00344e;
						}
						.employee_paid_info_section {
							display: inline-flex;
							flex-direction: row;
							align-items: center;
							img {
								height: 25px;
								width: 25px;
							}
							span {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 10px;
								line-height: 12px;
								color: #00344e;
								padding-left: 4px;
							}
						}
					}
				}
			}
		}
		.setup_portal_web {
			max-width: calc(50% - 8px);

			// padding: 0px 4px;
		}
		.payroll_amount_chart_card_column {
			// padding: 0px 4px;
			max-width: calc(50% - 8px);
			.payroll_history_card {
				// height: 254px;
				height: 248px;
				background: #ffffff;
				border: none;
				box-shadow: 0px 16px 32px -24px #bdbdbd;
				border-radius: 8px;
				padding: 16px;
				// height: 249px;
				// overflow-y: scroll;

				.ant-card-body {
					display: flex;
					flex-direction: column;
					padding: 0px 8px;
					width: 100%;
					.payroll_history_card_title_row {
						padding: 8px 0px;
						.payroll_history_icon {
							.payroll_content_div {
								width: 24px;
								height: 24px;
								background: #cee8ff;
								border-radius: 8px;
								display: grid;
								place-content: center;
								img {
									height: 16px;
									// width: 16px;
								}
							}
						}
						.payroll_history_card_title {
							span {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 12px;
								line-height: 14px;
								color: $primaryColor5;
								padding-left: 10px;
							}
						}
						.navigation_icon_row {
							display: flex;
							justify-content: flex-end;
							.navigation_left_icon {
								border-radius: 50%;
								background: $greyColor2;
								height: 24px;
								width: 24px;
								margin-right: 4px;
								img {
									height: 20px;
									width: 20px;
									cursor: pointer;
								}
							}
							.navigation_right_icon {
								border-radius: 50%;
								background: $greyColor2;
								height: 24px;
								width: 24px;
								margin-left: 2px;
								img {
									height: 20px;
									width: 20px;
									cursor: pointer;
								}
							}
						}
					}
					.payroll_history_details_section {
						width: 100%;
						padding-top: 9px;
						.payroll_history_card_detail_row {
							padding: 7px 0px;
							width: 100%;
							display: flex !important;
							.payroll_history_status {
								box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
								border-radius: 16px;
								width: 4px;
								height: 40px;
								&.success {
									background: $feedbackSuccess;
								}
								&.warning {
									background: $feedbackWarning;
								}
								&.failed {
									background: $feedbackError;
								}
							}
							.payroll_history_card_detail_content {
								display: flex;
								flex-direction: column;
								.payroll_history_month {
									font-family: Montserrat-Medium;
									font-style: normal;
									font-weight: 500;
									font-size: 16px;
									line-height: 18px;
									color: $primaryColor5;
								}
								.payroll_status_text {
									padding-top: 8px;
									font-family: Montserrat-Regular;
									font-style: normal;
									font-weight: 300;
									font-size: 12px;
									line-height: 14px;
									color: $greyColor7;
								}
							}
						}
					}
					.view_all_button_row {
						display: flex;
						justify-content: flex-end;
						padding: 0px 0px;
						margin-top: -55px;
						margin-right: 10px;
						button {
							height: 24px;
							width: auto;
							min-height: 24px;
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 10px;
							line-height: 12px;
							padding: 8px 12px;
						}
					}
				}
			}
		}
	}
	.main-dashboard-container-footer {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		background: #ffffff;
		box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
		border-radius: 8px;
		// margin-top: 4%;
		padding: 16px 0px;
		column-gap: 24px;
		text-align: center;

		img {
			padding-right: 10px;
			width: 33px;
			height: 32px;
		}

		.normal {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
			color: #00344e;
		}

		.bold {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 14px;
			color: #00344e;
		}
		.main-dashboard-email {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 58px;
			min-height: 58px;
			background: #ffffff;
			box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
			// border-top-left-radius: 24px;
			// border-top-right-radius: 24px;
			border-radius: 8px;
			img {
				padding-right: 10px;
				width: 33px;
				height: 32px;
			}
			.query {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: #00344e;
			}
			.email {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 14px;
				color: #00344e;
			}
		}
	}
}
.main-dashboard-mobile-presentational {
	display: none;
}
.responsive-row {
}
.main-dashboard-web-presentational
	.main_dashboard_web_second_row
	.payroll_history_card_column
	.payroll_history_card
	.ant-card-body
	.payroll_history_details_section {
	height: 156px;
	max-height: 156px;
	overflow-y: scroll;
}
.main-dashboard-web-presentational
	.main_dashboard_web_second_row
	.card_order_history_column
	.card_order_history
	.ant-card-body
	.card_order_history_details_section {
	height: 156px;
	max-height: 156px;
	overflow-y: scroll;
}
.main-div-pay {
	width: 100%;
	display: flex;
	justify-content: space-between;
	.first-div {
		width: 10%;
	}
	.secound-div {
		width: 90%;
	}
	.third-div {
		width: 20%;
		display: flex;
		justify-content: flex-end;
	}
	.serach-box {
		margin-right: 40px;
	}
}
@media only screen and (min-width: 1921px) {
	.chartjs-render-monitor {
		// height: 177px !important;
	}
	.main_dashboard_web_first_row {
		height: unset !important;
	}
	.main-dashboard-web-presentational .main_dashboard_web_second_row {
		height: unset !important;
	}
	.main-dashboard-web-presentational .main_dashboard_web_third_row {
		padding: 8px 0px 0px 0px;
		// height: 237px;
	}
}

@media only screen and (max-width: 1920px) {
	.chartjs-render-monitor {
		// height: 177px !important;
	}
	.main_dashboard_web_first_row {
		min-height: 136px;
	}
	.main-dashboard-web-presentational .main_dashboard_web_second_row {
		// height: 295px;
	}
	.main-dashboard-web-presentational .main_dashboard_web_third_row {
		padding: 0px 0px;
		// height: 196px;
		height: max-content;
	}
}
@media only screen and (min-width: 1913px) {
	.main-dashboard-web-presentational .main_dashboard_web_third_row {
		// padding: 8px 0px 0px 0px;
	}
	.chartjs-render-monitor {
		// height: 177px !important;
	}
	.main_dashboard_web_first_row {
		// height: 94px;
		height: max-content;
	}
	.main-dashboard-web-presentational .main_dashboard_web_second_row {
		// height: 263px;
		height: max-content;
	}
}
@media only screen and (min-width: 1600px) {
	.payroll_history_card_title,
	.card_order_history_card_title,
	.employee_paid_card_title {
		// padding-left: 10px;
	}
	.first-div {
		width: 17%;
	}
	.secound-div {
		width: 83%;
	}
	.third-div {
		width: 20%;
		display: flex;
		justify-content: flex-end;
	}
	.serach-box {
		margin-right: 40px;
	}
}
@media only screen and (max-width: 1680px) {
	.card-resp {
		// height: 227px !important;
	}
	.main-dashboard-web-presentational .main_dashboard_web_second_row {
		// height: 267px !important;
		// margin-top: 1%;
	}
	.first-div {
		width: 10% !important;
	}
	.secound-div {
		width: 88% !important;
	}
	.third-div {
		width: 20%;
		display: flex;
		justify-content: flex-end;
	}
	.serach-box {
		margin-right: 40px;
	}
}
@media only screen and (max-width: 1440px) {
	.main-dashboard-web-presentational .main-dashboard-container-footer .main-dashboard-email .query,
	.main-dashboard-web-presentational .main-dashboard-container-footer .main-dashboard-email .email {
		font-size: 10px !important;
	}
	.chartjs-render-monitor {
		// height: 100% !important;
		padding-bottom: 20px;
	}
	.main-dashboard-web-presentational
		.main_dashboard_web_first_row
		.wps_nonwps_timing_details_card_column
		.wps_nonwps_timing_details_card
		.ant-card-body
		.wps_nonwps_timing_details_row
		.wps_nonwps_timing_details_column
		.wps_nonwps_title {
		font-size: 9px;
	}
	.main-dashboard-web-presentational
		.main_dashboard_web_first_row
		.wps_nonwps_timing_details_card_column
		.wps_nonwps_timing_details_card
		.ant-card-body
		.wps_nonwps_timing_details_row {
		// padding: 18px 0px;
		text-align: center;
	}
	// .main_dashboard_web_first_row {
	// 	height: 130px !important;
	// }
	// .main-dashboard-web-presentational .main_dashboard_web_second_row {
	// 	height: 254px !important;
	// }
	// .main-dashboard-web-presentational .main_dashboard_web_third_row {
	// 	padding: 37px 0px;
	// }
	.first-div {
		width: 11% !important;
	}
	.secound-div {
		width: 87% !important;
	}
	.third-div {
		width: 20%;
		display: flex;
		justify-content: flex-end;
	}
}

@media only screen and (max-width: 1331px) {
	.amount_interger_part {
		font-size: 21px !important;
	}
}
@media only screen and (max-width: 1280px) {
	// .main_dashboard_web_first_row {
	// 	height: 130px !important;
	// }
	// .main-dashboard-web-presentational .main_dashboard_web_second_row {
	// 	height: 254px !important;
	// }
	// .main-dashboard-web-presentational .main_dashboard_web_third_row {
	// 	padding: 37px 0px;
	// }
}
@media only screen and (max-width: 1200px) {
	.amount_interger_part {
		font-size: 19px !important;
	}
	.main-dashboard-web-presentational
		.main_dashboard_web_first_row
		.available_balance_card_column
		.available_balance_card
		.ant-card-body
		.available_balance_details_row
		.available_balance_details
		.available_balance-details-row
		.curency_name {
		font-size: 17px !important;
	}
	.first-div {
		width: 13% !important;
	}
	.secound-div {
		width: 84% !important;
	}
	.third-div {
		width: 20%;
		display: flex;
		justify-content: flex-end;
	}
}

@media only screen and (max-width: 600px) {
	.main-dashboard-web-presentational {
		display: none !important;
	}
	.main-dashboard-mobile-presentational {
		display: flex !important;
	}
	.chartjs-render-monitor {
		// height: 100% !important;
		padding-top: 20px;
	}

	.first-div {
		width: 30% !important;
	}
	.run-payroll-table-presentation-wrapper .runpayroll-content .run-payroll-table-filter-wrapper .filter-area .search-container-1 {
		width: 100% !important;
		padding-left: 0px;
	}
	.run-payroll-table-presentation-wrapper .runpayroll-content .run-payroll-table-filter-wrapper .filter-area .serach-box {
		margin-right: 10px;
	}
	.run-payroll-table-presentation-wrapper
		.runpayroll-content
		.run-payroll-table-filter-wrapper
		.filter-area
		.repeat-container-selected
		.Repeat-text,
	.run-payroll-table-presentation-wrapper
		.runpayroll-content
		.run-payroll-table-filter-wrapper
		.filter-area
		.repeat-container-unselected
		.Repeat-text,
	.filter-button {
		font-size: 11px !important;
	}

	.run-payroll-table-presentation-wrapper .runpayroll-content .run-payroll-table-filter-wrapper .ant-input:placeholder-shown {
		font-size: 11px !important;
	}
}
.payroll_status_head {
	font-family: 'Montserrat-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #00344e;
	margin-top: 18px;
	margin-bottom: 9px;
}
@media screen and (max-width: 1170px) {
	.payroll_history_card_column {
		max-width: 100% !important;
		margin-bottom: 16px;
	}
	.card_order_history_column {
		max-width: 100% !important;
		// margin-bottom: 16px;
	}
	.employee_paid_card_column {
		max-width: 100% !important;
		margin-bottom: 16px;
	}
	.setup_portal_web {
		max-width: 100% !important;
		// margin-bottom: 16px;
	}
	.payroll_amount_chart_card_column {
		max-width: 100% !important;
		// margin-bottom: 16px;
	}
}
@media screen and (max-width: 1199px) {
	.main_dashboard_web_first_row {
		height: 100%;
		display: block !important;
	}
	.gauge_chart_card {
		margin-top: 16px;
		// margin-bottom: 16px;
		width: 100% !important;
	}
	.available_balance_card {
		width: 100% !important;
	}
	.main-dashboard-container-footer {
		display: block !important;
	}
	.wps_nonwps_timing_details_card {
		margin-top: 16px;
		// margin-bottom: 16px;
	}
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
	.wps_nonwps_timing_details_card {
		margin-top: 16px;
		// margin-bottom: 16px;
		margin-left: 16px;
	}
}
