.employeeListTable {
	padding: 0px 240px 0px 240px;
	display: flex;
	background: #f7f8fa;
	min-height: 100%;
	// height: 100%;
	.multiple_employees_submit_button_row {
		margin-top: 2%;
	}
}

.hr-upload .UploadSpreadsheet {
	padding: 0px;
}
