// for disabled
@import '../../../constants/variables';
// for prefix
.input-value {
	border-color: $grayscale-500;
	&:focus-within {
		border-color: $blue-100;
	}
}
.default-value {
	border-color: $grayscale-400;
	.prefix {
		color: $grayscale-700 !important;
	}
	.input-text-newbox {
		color: $grayscale-500 !important ;
	}
	&:focus-within {
		border-color: $blue-100;
	}
}
.input-empty {
	border-color: $grayscale-400;
	&:focus-within {
		border-color: $blue-100;
	}
}
.input-disabled {
	border-color: $grayscale-300;
	cursor: not-allowed;
	.prefix {
		color: $grayscale-400 !important;
	}
}
.input-box {
	display: flex;
	align-items: center;
	height: 32px;
	width: 100%;
	background: $neutral-white;
	border-width: 1px;
	border-style: solid;
	caret-color: $blue-100;
	border-radius: 8px;
	padding-left: 0.5em;
	overflow: hidden;
	font-family: Montserrat-medium;
	font-style: medium;
	font-weight: 500;
	font-size: 0.8em;
	line-height: 0.75em;
	color: $grayscale-900;
	padding: 8px 12px 8px 12px;
	position: relative;
	.prefix {
		font-family: 'Montserrat-Medium';
		font-size: 12px;
		font-weight: 500;
		line-height: 14px;
		color: $grayscale-900;
		padding-right: 8px;
	}
	.input-text-newbox {
		width: 100%;
		flex-grow: 1;
		background: $neutral-white;
		border: none;
		outline: none;
		font-family: 'Montserrat-Medium';
		font-size: 12px;
		font-weight: 500;
		line-height: 14px;
		color: $grayscale-900;
		resize: none;
		&:focus-within {
			border-color: $blue-100;
		}
		&:disabled {
			cursor: not-allowed;
			color: $grayscale-400;
			border-color: $grayscale-300;
		}
		&::placeholder {
			font-family: 'Montserrat-Medium';
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 14px;
			color: $grayscale-500;
		}
	}

	.input_loader {
		border: 2px solid #dfe1e6;
		border-top: 2px solid transparent;
		border-radius: 15px;
		width: 16px;
		height: 16px;
		animation: spin 1s linear infinite;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
}
.error-message {
	border-color: $feedbackError;
	&:focus-within {
		border-color: $feedbackError;
	}
}
.error-message-text {
	font-family: 'Montserrat-Light';
	font-style: normal;
	font-weight: 300;
	font-size: 8px;
	line-height: 10px;
	color: $red-500;
	margin: 8px 0px 0px 0px;
}
.input-text-eyeIcon {
	position: absolute;
	top: 1.2em;
	right: 1em;
}
.input-text-countryIcon {
	// position: absolute;
	// top: 1em;
	// left: 0.6em;
	padding-right: 8px;
}
.input-number-icon {
	font-family: 'Montserrat-Medium';
	font-size: 12px;
	font-weight: 500;
	line-height: 20px;
	color: $grayscale-900;
	padding-right: 8px;
}
// Label
.input-label-top {
	font-family: 'Montserrat-Medium';
	font-size: 10px;
	font-weight: 500;
	line-height: 12px;
	color: $grayscale-900;
	margin-bottom: 8px;
	display: flex;
}
.input-text-newbox::-webkit-outer-spin-button,
.input-text-newbox::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
