@import '../../../constants/_variables.scss';

.landing_parent {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5%;
	margin-left: 5%;
	margin-right: 5%;
}

.landing {
	width: 100%;
	height: 100vh;

	&_header {
		&_text {
			display: grid;
			font-family: 'Montserrat-Bold';
			font-size: 32px;
			font-weight: 700;
			line-height: 36px;
			letter-spacing: 0px;
			text-align: left;
			color: $landingTextColor;
			padding-bottom: 9px;
		}

		&_subtext {
			font-family: 'Montserrat-Medium';
			font-size: 18px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: 0px;
			text-align: left;
			color: $primaryColor4;
		}
	}

	&_cards {
		padding-top: 0.7rem;
		&_container {
			background-color: $landingBg;
			height: 88px;
			margin: 35px 5px;
			position: relative;
		}

		&_img {
			width: 90%;
			position: absolute;
			top: -28px;
		}

		&_sendAnnouncement {
			width: 90%;
			position: absolute;
			top: -18px;
		}

		&_desc {
			font-family: Montserrat-bold;
			font-size: 18px;
			font-weight: 700;
			line-height: 20px;
			letter-spacing: 0px;
			text-align: left;
			color: $landingTextColor;
			height: 100%;
			display: flex;
			align-items: center;
		}
	}
}

.setupBtn_container {
	margin-top: 32px;
}
