@import '../../../constants/_variables.scss';
.help-payroll-presentational-col {
	padding: 0px 40px 40px 40px;
	background: #f7f8fa;
	width: 100%;
	align-self: flex-start;
	display: block;
	.header {
		font-family: Montserrat-bold;
		font-size: 24px;
		font-weight: 700;
		line-height: 28px;
		letter-spacing: 0em;
		text-align: left;
		color: #00344e;
		display: flex;
		align-items: center;
		padding: 42px 0px 24px 0px;
		justify-content: space-between;
		position: sticky;
		top: 0%;
		z-index: 1;
		background: $grayscale-200;
		.secondary-btn {
			font-family: 'Montserrat-Medium';
		}
		.buttonnew {
			width: 118px;
			img {
				margin-right: 0px;
			}
		}
	}
	.questions {
		padding: 32px 32px 32px 32px;
		background: #ffffff;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		.accordian-main-contents {
			margin-bottom: 0px;
		}
	}
}
