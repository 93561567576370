@import '../../../constants/variables';
.announcement-wrapper {
	display: flex;
	flex-direction: column;
	background: rgba(255, 255, 255, 1);
	border-radius: 8px;
	padding: 32px;
	box-shadow: 0px 16px 32px -24px rgba(189, 189, 189, 1);

	.info-wrapper {
		padding: 8px 8px 8px 16px;
		display: flex;
		gap: 16px;
		border-radius: 8px;
		background: rgba(206, 232, 255, 0.25);
		.info-message {
			color: $darkblue;
			font-size: 14px;
			font-family: $font-family-medium;
			font-weight: $font-weight-500;
			line-height: 16px;
			margin: 0px;
		}
	}
	.announcement-container {
		display: flex;
		justify-content: space-between;
		margin-top: 32px;
		margin-left: 0;
		margin-right: 0;
		gap: 40px;
		.notification-container {
			width: 48%;
			.notification-title-container {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 8px;
				.notification-title {
					color: $darkblue;
					font-size: 10px;
					font-family: $font-family-medium;
					font-weight: $font-weight-500;
					line-height: 12px;
					margin: 0px;
				}
				.title-input {
					width: 100%;
					padding: 8px 12px;
					border-radius: 8px;
					border: 1px solid rgba(223, 225, 230, 1);
					color: rgba(24, 25, 25, 1);
					font-size: 12px;
					font-family: $font-family-medium;
					font-weight: $font-weight-500;
					line-height: 14px;
					outline: 1px solid rgba(223, 225, 230, 1);
					&:focus-within {
						border-color: $blue-100;
					}
					&::placeholder {
						font-size: 12px;
						font-family: $font-family-medium;
						font-weight: $font-weight-500;
						line-height: 14px;
						color: rgba(154, 158, 166, 1);
					}
				}
				.input-value {
					border-color: $grayscale-500;
				}
				.characters {
					text-align: end;
					font-size: 10px;
					font-family: $font-family-light;
					font-weight: $font-weight-300;
					line-height: 12px;
					color: rgba(24, 25, 25, 1);
					margin: 0px;
				}
			}
			.notification-text-container {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 8px;
				.notification-text {
					color: $darkblue;
					font-size: 10px;
					font-family: $font-family-medium;
					font-weight: $font-weight-500;
					line-height: 12px;
					margin: 0px;
				}
				.message-input {
					width: 100%;
					padding: 8px 12px;
					border-radius: 8px;
					border: 1px solid rgba(223, 225, 230, 1);
					color: rgba(24, 25, 25, 1);
					font-size: 12px;
					font-family: $font-family-medium;
					font-weight: $font-weight-500;
					line-height: 14px;
					outline: 1px solid rgba(223, 225, 230, 1);
					resize: none;
					min-height: 70px;
					&:focus-within {
						border-color: $blue-100;
					}

					input {
						align-self: flex-start;
						&::placeholder {
							font-size: 12px;
							font-family: $font-family-medium;
							font-weight: $font-weight-500;
							line-height: 14px;
							color: rgba(154, 158, 166, 1);
						}
					}
				}
				.input-value {
					border-color: $grayscale-500;
					min-height: 70px;
				}
				.characters {
					text-align: end;
					font-size: 10px;
					font-family: $font-family-light;
					font-weight: $font-weight-300;
					line-height: 12px;
					color: rgba(24, 25, 25, 1);
					margin: 0px;
				}
			}
			.tooltip {
				width: 100%;
				.announcement-sent-employee-count-container {
					width: 100%;
					padding: 8px;
					display: flex;
					align-items: center;
					background: rgba(206, 232, 255, 0.25);
					padding: 8px 16px;
					margin-top: 40px;
					border-radius: 8px;
					.sent-employee-container {
						width: 90%;
						padding-left: 8px;
						.message-sent {
							color: $darkblue;
							font-size: 10px;
							font-family: $font-family-light;
							font-weight: $font-weight-300;
							line-height: 12px;
							margin: 0px;
						}
						.message-sent-count {
							color: $darkblue;
							font-size: 14px;
							font-family: $font-family-medium;
							font-weight: $font-weight-500;
							line-height: 16px;
							margin: 0px;
						}
					}
				}
				.tooltipup {
					bottom: 80%;
					left: 25%;
					right: 15%;
				}
			}
		}
	}
}

.modal-description {
	.announcement-modal-title-container {
		color: rgb(110, 112, 118);
		font-size: 14px;
		font-family: $font-family-light;
		font-weight: $font-weight-300;
		line-height: 16px;
		margin-top: 16px;
		.announcement-modal-title {
			color: rgb(110, 112, 118);
			font-size: 14px;
			font-family: $font-family-medium;
			font-weight: $font-weight-500;
			line-height: 16px;
			margin-right: 2px;
		}
	}
	.announcement-modal-message-container {
		word-wrap: break-word;
		color: rgb(110, 112, 118);
		font-size: 14px;
		font-family: $font-family-light;
		font-weight: $font-weight-300;
		line-height: 16px;
		margin-top: 16px;
		.announcement-modal-message {
			color: rgb(110, 112, 118);
			font-size: 14px;
			font-family: $font-family-medium;
			font-weight: $font-weight-500;
			line-height: 16px;
			margin-right: 2px;
		}
	}
}
