.workflow-heading-color {
	color: #00344e;
}

.approver-style {
	color: #484b52;
}

.field-type {
	margin-top: 4px;
	color: #484b52;
}

.approval-workflow-main-header {
	display: flex;
	width: 100%;
	padding: 0px 40px 40px 40px;
	align-self: flex-start;
	.approval-workflow-header {
		display: flex;
		width: 100%;
		flex-direction: column;
		.title-and-button-container {
			display: flex;
			flex-direction: row;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			padding: 40px 0px 26px 0px;
			position: sticky;
			top: 0%;
			z-index: 1;
			background: #f7f8fa;
			.title-button-container {
				display: flex;
				flex-direction: row;
				gap: 8px;
			}
			// 	display: flex;
			// 	flex-direction: row;
			// 	align-items: center;
			// .cancel-button-style {
			// 	width: 83px;
			// 	height: 32px;
			// }
			// .Submit-button-style {
			// 	margin-left: 8px;
			// 	width: 67px;
			// 	height: 32px;
			// }
			// }
		}
		.workflow-wrapper {
			display: flex;
			flex-direction: column;
			background-color: #ffffff;
			box-shadow: 0px 16px 32px -24px #bdbdbd;
			border-radius: 8px;
			padding: 32px;
			.approval-workflow-sub-header {
				display: flex;
				width: 100%;
				.approval-workflow-empty {
					border: 2px transparent;
					height: 50px;
					width: 81%;
				}

				.approver-header-card {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					width: 100%;
					column-gap: 10px;
				}
				.approval-workflow-approver {
					display: flex;
					flex-direction: column;
					border: 2px solid #f7f8fa;
					background: #f7f8fa;
					border-radius: 8px;
					height: 50px;
					width: 100%;
					padding: 8px 12px;
					.workflow-typo-approver {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 16px;
						color: #00344e;
					}
					p {
						font-family: Montserrat-Light;
						font-style: normal;
						font-weight: 300;
						font-size: 12px;
						line-height: 14px;
					}
				}
			}
			.approval-workflow-sub-header-1 {
				display: flex;
				flex-direction: column;
				margin-top: 13px;
				width: 100%;
				.approval-workflow-head-container {
					display: flex;
					width: 100%;
					.approval-workflow-container {
						margin-bottom: 10px;
						margin-right: 10px;
						width: 25%;
						.workflow-typo-container {
							background: #fbfcfd;
							border-radius: 8px;
							width: 100%;
							height: 32px;
							padding: 8px 16px;
							display: flex;
							align-items: center;
							margin-top: 10px;
							margin-bottom: 18px;
						}
					}
					.approval-workflow-dropdown {
						box-sizing: border-box;
						border-radius: 8px;
						width: 100%;
						.approver-dropdown-select {
							display: flex;
							margin-bottom: 10px;
							column-gap: 9px;
							.multiSelect-dropdown-container {
								.dropdown_header {
									.dropdown_inner {
										margin-top: 8px;
										.dropDown-menu {
											.dropdown_default {
												background: none;
											}
										}
									}
								}
							}
						}
						.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input,
						.ant-select-selection-item {
							cursor: pointer !important;
							&:focus {
								caret-color: transparent;
							}
						}
						.ant-select {
							font-family: Montserrat-Light;
							font-style: normal;
							font-weight: 300;
							font-size: 14px;
							line-height: 16px;
							letter-spacing: 0.5px;
							height: 48px;
							min-width: 65%;
							color: #484b52;
							margin-right: 10px;
							.ant-select-selector {
								display: flex;
								align-items: center;
								height: 48px;
								border: 2px solid #dfe1e6;
								box-sizing: border-box;
								border-radius: 8px;
							}
							.ant-select-selection-item {
								padding-right: 18px;
								justify-content: space-around;
							}
							.ant-select-selection-placeholder {
								font-family: Montserrat-Light;
								font-style: normal;
								font-weight: 300;
								font-size: 16px;
								line-height: 18px;
								letter-spacing: 0.5px;
								color: #9a9ea6 !important;
							}
							.ant-select-arrow {
								display: flex;
								align-items: center;
								justify-content: center;
								font-size: 20px;
								color: #11266900;
								content: url('../../../assets/icons/not_focus/Down.svg');
								height: 28px;
								width: 28px;
								margin-top: -15px;
								margin-right: 0px;
							}
							.ant-select-arrow ::after {
								display: flex;
								align-items: center;
								justify-content: center;
								font-size: 20px;
								color: #11266900;
								content: url('../../../assets/icons/not_focus/Up.svg');
								height: 28px;
								width: 28px;
								margin-top: -15px;
								margin-right: 0px;
							}
							.ant-select-item {
								font-family: 'Montserrat-Medium';
								font-size: 14pt;
								font-weight: 500;
								background: #ffffff;
							}
							.ant-select-dropdown {
								background: #ffffff;
								box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
								border-radius: 15px !important;
							}
						}
					}
				}
			}
			.notice-card {
				display: flex;
				flex-direction: row;
				height: 56px;
				width: 100%;
				background: #f7fbff;
				border-radius: 8px;
				margin-top: 24px;
			}
			.workflow-hint {
				display: flex;
				align-items: center;
				gap: 10px;
				background: #f7fbff;
				border-radius: 8px;
				padding: 8px;
				p {
					margin: 0px;
				}
				.workflow-text {
					font-family: 'Montserrat-Light';
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: #00344e;
					span {
						font-family: 'Montserrat-Medium';
						color: #0e5274;
						cursor: pointer;
					}
				}
			}
		}
		.approval-button {
			display: flex;
			margin-top: 15px;
			padding-bottom: 3vh;
			.approval-sub-button {
				margin-right: 10px;
			}
			.approval-cancel {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 18px;
				text-align: center;
				color: #484b52;
				background: white;
				border: 2px solid;
				box-shadow: none;
				height: 48px;
				width: 91%;
				border: 2px solid #dfe1e6;
				box-sizing: border-box;
				border-radius: 90px;
				&:disabled {
					background: white !important;
				}
			}
			.approval-save {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				color: #f7f8fa;
				height: 48px;
				width: 91%;
			}
		}
	}
}
@media screen and (max-width: 1199px) {
	.approval-workflow-main-header
		.approval-workflow-header
		.approval-workflow-sub-header-1
		.approval-workflow-head-container
		.approval-workflow-dropdown
		.ant-select {
		min-width: 100% !important;
	}
}

@media screen and (max-width: 1030px) {
	.approval-workflow-main-header {
		.approval-workflow-header {
			.workflow-wrapper {
				.approval-workflow-sub-header {
					.approval-workflow-empty {
						width: 90%;
					}
				}
			}
		}
	}
}
