@import '../../../constants/variables';
@media only screen and (max-width: 600px) {
	.card-order-status-col-presentational {
		width: 100%;
		height: 100%;
		.card_order_status_container {
			padding: 65px 4px 70px 12px;
			.card_order_status_title {
				padding: 0px 0px 4px 0px;
				span {
					font-size: 20px;
					line-height: 24px;
				}
			}
			.card_order_status_description {
				padding: 4px 0px 4px 0px;
				span {
					font-size: 14px;
					line-height: 16px;
					color: $greyColor6;
				}
			}
			.card_order_status_card_section {
				padding: 4px 0px;
				.card_order_status_card_column {
					padding-left: 16px;
					width: 100%;
					margin: 4px 0px;
					border-radius: 16px;
					box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
					&.feedbackWarning {
						background: $feedbackWarning;
					}
					&.feedbackSuccess {
						background: $feedbackSuccess;
					}
					&.feedbackError {
						background: $feedbackError;
					}
					.order_details_card {
						border-radius: 16px !important;
						padding: 8px !important;
						.ant-card-body {
							padding: 4px !important;
						}
					}
				}
			}
		}
	}

	//Track Card Order Status Page
	.track_card_order_status_container {
		padding: 65px 4px 65px 12px;
		.track_card_order_status_title {
			padding: 0px 0px 4px 0px;
			span {
				font-size: 20px;
				line-height: 24px;
			}
		}
		.track_card_order_status_description {
			padding: 4px 0px;
			span {
				font-size: 12px;
				line-height: 14px;
				color: $greyColor6;
			}
		}
		.ant-divider-horizontal {
			display: none;
		}
		.track_card_order_status_steps_progress_bar {
			padding: 8px 0px 6px 0px;
			.step_container {
				.dot_content_container {
					.date_content {
						padding-left: 0px;
						.date {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 16px;
							line-height: 18px;
							color: $primaryColor5;
						}
						.content {
							span {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 12px;
								line-height: 14px;
								color: $greyColor7;
							}
						}
					}
				}
			}
		}
		.track_card_order_status_location {
			display: flex;
			align-items: center;
			padding: 8px 0px;
			img {
				height: 32px;
				width: 32px;
			}
			span {
				font-size: 10px;
				line-height: 14px;
			}
		}

		> .track_card_order_status_button_section {
			display: flex !important;
			padding: 8px 0px;
			width: 100%;
			display: flex;
			justify-content: center;
			.track_card_order_status_view_button {
				padding: 0px 16px 0px 0px;
				button {
					width: 100%;
					min-width: 183px;
					height: 32px;
					min-height: 32px;
					padding: 3px;
					border-radius: 16px;
					font-family: Montserrat-Regular;
					font-style: normal;
					font-size: 14px;
					font-weight: 300;
					line-height: 16px;
				}
			}
			.track_card_order_status_cancel_button {
				padding: 24px 0px 0px 0px;
				button {
					width: 100%;
					min-width: 149px;
					height: 32px;
					min-height: 32px;
					padding: 3px;
					border-radius: 22px;
					font-family: Montserrat-Regular;
					font-style: normal;
					font-size: 14px;
					font-weight: 300;
					line-height: 16px;
					border: none;
					text-decoration: underline;
					text-underline-position: under;
					img {
						height: 15px;
						width: 15px;
						margin-right: 6px;
					}
				}
			}
		}
	}

	//View Card Order Details Page
	.view_card_order_details_container {
		padding: 65px 4px 0px 12px;
		.view_card_order_details_title {
			padding: 0px 0px 20px 0px;
			span {
				font-size: 20px;
				line-height: 24px;
			}
		}
	}
}

.display_none_max_width_600px {
	@media screen and (max-width: 600px) {
		display: none;
	}
}
