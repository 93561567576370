@mixin text($family, $fontsize, $fontweight, $fontlineheight, $letterspacing, $text-align) {
	font-family: $family;
	font-size: $fontsize;
	font-weight: $fontweight;
	line-height: $fontlineheight;
	letter-spacing: $letterspacing;
	text-align: $text-align;
}

.setup-slider {
	.main_container .slider_view .slider_head span {
		//styleName: Paragraph/Medium/Medium;
		@include text(Montserrat-medium, 12px, 500, 14px, 0em, center);
		color: #f75448;
	}
	.main_container .slider_view .slider span {
		@include text(Montserrat-bold, 20px, 700, 24px, 0em, center);
		color: #00344e;
	}
}
