@import './../../../constants/variables';
.payslip-new {
	width: 100%;
	padding: 0px 40px 40px 40px;
	align-self: flex-start;
	.infinite-scroll-component {
		padding: 0px;
	}
	.payslip-content {
		position: sticky;
		top: 0%;
		z-index: 1;
		box-shadow: 0px 8px 24px -12px #f7f8fa;
		padding: 40px 0px 24px 0px;
		background: $grayscale-200;
		.payslip-content-container {
			display: flex;
			flex-direction: column;
		}
	}
	.payslip-container {
		width: 100%;
		justify-content: center;
		align-content: center;
		text-align: center;
		padding: 32px;
		background: $neutral-white;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		.payslip_no_record {
			display: flex;
			align-items: center;
			flex-direction: column;
			padding: 60px 0px;
		}
	}
	.payslip-content-mock {
		justify-content: center;
		.payslip-content-container {
			border-radius: 8px;
			.payslip_record_container {
				background: #ffffff;
				border-radius: 8px;
				margin-bottom: 24px;
				box-shadow: 0px 8px 24px -12px #f7f8fa;
				&:last-child {
					margin-bottom: 0px;
					background: #ffffff;
					border-radius: 8px;
					margin-bottom: 24px;
					box-shadow: 0px 8px 24px -12px #f7f8fa;
				}
				.payslip-content-year {
					padding: 17px 16px;
					border-bottom: 1px solid #eff1f5;
					.payslip-year-text {
						color: #2b769c;
					}
				}
				.payslip-content-download {
					padding: 12px 16px;
					border-bottom: 1px solid #eff1f5;
					&:last-child {
						border-bottom: none;
					}
					.payslip-content-container-div {
						display: flex;
						cursor: pointer;
						align-items: center;
						.payslip_download {
							display: flex;
							align-items: center;
							justify-content: center;
							background: #f7f8fa;
							border-radius: 4.8px;
							padding: 6px;
							margin-right: 16px;
						}
						.payslip-content-month {
							font-family: 'Montserrat-Medium';
							font-style: normal;
							font-weight: 500;
							font-size: 16px;
							line-height: 18px;
							color: #00344e;
							padding-left: 21px;
						}
					}
				}
			}
		}
	}
}
