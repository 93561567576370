.profile-dashboard-presentational .profile-dashboard-col .profile-dashboard-image .image-container {
	box-shadow: unset !important;
}
.profile-dashboard-presentational .profile-dashboard-col .profile-dashboard-image {
	padding: 0px 0px 35px 0px !important;
}

.link_text {
	left: 93% !important;
}

.main-dashboard-web-presentational
	.main_dashboard_web_second_row
	.payroll_history_card_column
	.payroll_history_card
	.ant-card-body
	.payroll_history_card_title_row
	.navigation_icon_row
	.navigation_right_icon
	img {
	margin-left: 3px;
}
.main-dashboard-web-presentational
	.main_dashboard_web_second_row
	.card_order_history_column
	.card_order_history
	.ant-card-body
	.card_order_history_title_row
	.navigation_icon_row
	.navigation_right_icon
	img {
	margin-left: 3px;
}
.empPaid,
.payrollAm {
	/* padding-top: 4px; */
}

@media (min-width: 1024px) {
	.payrollIcons {
		/* padding-left: 7px !important; */
		text-align: center;
	}
}
.dashboard-info-wrapper {
	padding: 8px 8px 8px 16px;
	background: #fef5e8;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
	border-radius: 8px;
	border: 2px solid #ffdc91;
}
.dashboard {
	display: flex;
	align-items: center;
	gap: 16px;
}
.getStart-btn {
	border-radius: 8px;
	background: rgba(251, 252, 253, 1);
	border: none;
}
