@import '../../../../constants/variables';

// Individual Employee CSS
.individual_employee_container {
	padding: 0px 135px 0px 135px;
	width: 100%;
	.individual_employee_title {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.individual_employee_details_form_section {
		padding-top: 22px;
		.ant-form {
			display: flex !important;
			flex-direction: column;
			.individual_employee_details_form_items_row {
				display: flex;
				flex-direction: row;
				padding: 6px 0px 0px 0px;
				.ant-form-item {
					margin: 0px !important;
					width: 50%;
					padding-right: 8px;
					.inputlabelrequired::after {
						content: ' *';
						color: red;
					}
				}
			}
			.individual_employee_details_form_items_column {
				display: flex;
				flex-direction: column;
				padding: 6px 0px 0px 0px;
				.mobile_number_input {
					display: flex;
					flex-direction: row;
					align-items: center;
					img {
						height: 32px;
						width: 32px;
					}
					span {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 18px;
						color: $primaryColor5;
						padding-left: 4px;
					}
					.floating-label {
						padding-left: 8px;
						width: 100%;
					}
				}

				.ant-form-item {
					margin: 0px !important;
					width: 50%;
					padding: 0px 8px 6px 0px;
				}
				.employee_details_next_button {
					width: 340px;
					min-height: 48px;
					height: 48px;
					margin-top: 25px;
				}
			}
		}
	}
}

// Individual Employee Order Card CSS
.individual_employee_order_card_container {
	padding: 0px 65px 0px 135px;
	.individual_employee_order_card_title {
		padding-bottom: 10px;
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.individual_employee_order_card_description {
		padding: 12px 0 10px 0;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.order_card_or_bank_account_button_section {
		width: 100%;
		padding: 12px 0 10px 0;
		.redirect_to_bank_account_button {
			padding-right: 8px;
			button {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				text-align: center;
				color: #484b52;
			}
		}
		.order_card_button {
			padding-left: 8px;
			button {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				text-align: center;
				color: #f7f8fa;
			}
		}
	}
}

//Select Payroll Channel Page
.select_payroll_channel_container {
	padding: 0px 135px 0px 135px;
	.select_payroll_channel_title {
		padding: 0px 0px 8px 0px;
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: 700;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.select_payroll_channel_description {
		padding: 8px 0px 8px 0px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.select_payroll_channel_card_section {
		.select_payroll_channel_card_column {
			padding: 16px 16px 16px 0px;
			.select_payroll_channel_select_card {
				background: $greyColor1;
				box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.5);
				border-radius: 24px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.select_payroll_channel_unselect_card {
				background: $greyColor1;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				border-radius: 24px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.ant-card-body {
				padding: 22px 22px;
				cursor: pointer;
			}
		}
	}
	.select_payroll_channel_button_section {
		padding: 8px 0px 8px 0px;
		button {
			font-family: 'Montserrat-Medium';
			font-size: 18px;
			font-weight: 500;
			line-height: 20px;
		}
	}
}

//add Individual Employement ID Page:
.individual_employee_employment_id_container {
	padding: 0px 135px 0px 135px;
	.individual_employee_employment_id_title {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.individual_employee_employment_id_description {
		padding: 16px 0px 0px 0px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.individual_employee_employment_id_form_section {
		padding: 8px 0px 0px 0px;
		.ant-form {
			.ant-form-item {
				margin: 0px !important;
				padding: 8px 0px;
				.inputlabelrequired::after {
					content: ' *';
					color: red;
				}
			}
		}
	}
}

//WPS_PERSON_ID Page:
.wps_person_id_container {
	padding: 0px 120px 0px 135px;
	.wps_person_id_title {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.wps_person_id_description {
		padding: 16px 0px 0px 0px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.wps_person_id_form_image_row {
		width: 100%;
		display: flex;
		flex-direction: row;
		.wps_person_id_form_section {
			padding: 8px 0px 0px 0px;
			.ant-form {
				.ant-form-item {
					margin: 0px !important;
					padding: 12px 0px;
					.inputlabelrequired::after {
						content: ' *';
						color: red;
					}
				}
			}
		}
		.wps_person_id_labour_card_image {
			display: flex;
			justify-content: flex-end;
		}
	}
	.wps_person_id_link_button {
		display: flex;
		justify-content: flex-start;
		button {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $primaryColor4;
			text-align: center;
			text-decoration: underline;
			text-underline-position: under;
			padding: 0px;
			width: auto;
		}
	}
}

//Add Establishment ID to Employee Page:
.add_establishment_id_to_employee_container {
	padding: 0px 100px 0px 135px;
	width: 100%;
	.add_establishment_id_to_employee_title {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.add_establishment_id_to_employee_description {
		display: flex;
		flex-direction: column;
		padding: 8px 0px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
			padding-bottom: 2px;
			a {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: $primaryColor4;
				text-decoration: underline;
				text-underline-position: under;
				padding-top: 2px;
			}
		}
	}
	.add_establishment_id_to_employee_form_section {
		padding: 8px 0px 0px 0px;
		.ant-form {
			.ant-form-item {
				margin: 0px !important;
				padding: 8px 0px;
				.inputlabelrequired::after {
					content: ' *';
					color: red;
				}
			}
		}
	}
}

//Add Labour Card Number Page:
.add_labour_card_number_container {
	padding: 0px 120px 0px 135px;
	.add_labour_card_number_title {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.add_labour_card_number_description {
		padding: 16px 0px 0px 0px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.add_labour_card_number_form_image_row {
		width: 100%;
		display: flex;
		flex-direction: row;
		.add_labour_card_number_form_section {
			.ant-form {
				.ant-form-item {
					margin: 0px !important;
					padding: 12px 0px;
					.inputlabelrequired::after {
						content: ' *';
						color: red;
					}
				}
			}
		}
		.add_labour_card_number_card_image {
			display: flex;
			justify-content: flex-end;
		}
	}
}

//Add EmiratesID Number Page:
.add_emirates_id_number_container {
	padding: 0px 120px 0px 135px;
	.add_emirates_id_number_title {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.add_emirates_id_number_description {
		padding: 16px 0px 0px 0px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.add_emirates_id_number_form_image_row {
		width: 100%;
		display: flex;
		flex-direction: row;
		.add_emirates_id_number_form_section {
			padding: 8px 0px 0px 0px;
			.ant-form {
				.ant-form-item {
					margin: 0px !important;
					padding: 16px 0px;
					.inputlabelrequired::after {
						content: ' *';
						color: red;
					}
				}
			}
		}
		.add_emirates_id_card_image {
			display: flex;
			justify-content: flex-end;
		}
	}
	.add_emirates_id_number_link_button {
		display: flex;
		justify-content: flex-start;
		button {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $primaryColor4;
			text-decoration: underline;
			text-underline-position: under;
			padding: 0px;
			width: auto;
		}
		.add_temporary_emirates_id_number_card {
			display: flex;
			justify-content: flex-start;
			border: 1px solid $WarningYellow2;
			box-sizing: border-box;
			border-radius: 8px;
			.add_temporary_emirates_id_number_tip_icon {
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					width: 32px;
					height: 32px;
				}
			}
			.add_temporary_emirates_id_number_hint {
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: $greyColor7;
				}
			}
		}
	}
}

//Add Passport Number Page:
.add_passport_number_container {
	padding: 0px 100px 0px 135px;
	width: 100%;
	.add_passport_number_title {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.add_passport_number_form_section {
		padding: 8px 0px 0px 0px;
		.ant-form {
			.ant-form-item {
				margin: 0px !important;
				padding: 8px 0px;
				.inputlabelrequired::after {
					content: ' *';
					color: red;
				}
			}
		}
	}
}

//Add IBAN Number Page:
.add_employee_iban_number_container {
	padding: 0px 100px 0px 135px;
	width: 100%;
	.add_employee_iban_number_title {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.add_employee_iban_number_description {
		padding: 16px 0px 0px 0px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.add_employee_iban_number_form_section {
		padding: 8px 0px 0px 0px;
		.ant-form {
			.ant-form-item {
				margin: 0px !important;
				padding: 8px 0px;
				.inputlabelrequired::after {
					content: ' *';
					color: red;
				}
			}
		}
	}
}

//Add Employee's Bank Name Page:
.add_employee_bank_name_container {
	padding: 0px 100px 0px 135px;
	width: 100%;
	.add_employee_bank_name_title {
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.add_employee_bank_name_description {
		padding: 16px 0px 0px 0px;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.add_employee_bank_name_form_section {
		padding: 8px 0px 0px 0px;
		.ant-form {
			.ant-form-item {
				margin: 0px !important;
				padding: 8px 0px;
				.inputlabelrequired::after {
					content: ' *';
					color: red;
				}
			}
		}
	}
}

//individual Employee Next steps Page:
.individual_employee_next_steps_container {
	display: flex;
	flex-direction: row;
	padding: 0% 135px 0% 135px;
	.individual_employee_next_steps_header {
		padding: 1% 0% 1% 0%;
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.individual_employee_next_steps_message {
		padding: 1% 0% 1% 0%;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.individual_employee_next_steps_status {
		padding: 1% 0% 1% 0%;
		.individual_employee_next_steps_status_container {
			display: flex;
			align-items: center;
			padding: 1% 1% 1% 1%;
			.individual_employee_next_steps_status_icon {
				display: contents;
				img {
					background: $greyColor1;
					line-height: 14pt;
					box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
					border-radius: 16px;
				}
			}
			.individual_employee_next_steps_status_content {
				display: flex;
				padding: 1% 1% 0% 2%;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: $greyColor7;
				}
				a {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					text-transform: capitalize;
					text-decoration: underline;
					text-underline-position: under;
					color: $primaryColor4;
				}
			}
		}
	}
	.individual_employee_next_steps_button {
		padding: 1% 0% 0% 0%;
		button {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $primaryColor5;
			padding: 1%;
			height: 32px;
			min-height: 32px;
			width: 253px;
			min-width: 253px;
		}
	}
}

@media only screen and (max-width: 999px) {
	//WPS_PERSON_ID Page:
	.wps_person_id_container {
		.wps_person_id_form_image_row {
			width: 100%;
			display: flex;
			flex-direction: column-reverse;
			.wps_person_id_labour_card_image {
				display: flex;
				justify-content: center;
				padding-right: 8%;
			}
		}
		.wps_person_id_link_button {
			display: flex;
			justify-content: center;
			padding: 8px 0px 0px 0px;
		}
	}

	//Add Labour Card Number Page:
	.add_labour_card_number_container {
		.add_labour_card_number_form_image_row {
			width: 100%;
			display: flex;
			flex-direction: column-reverse;
			.add_labour_card_number_card_image {
				display: flex;
				justify-content: center;
				padding-right: 8%;
			}
		}
	}

	//Add EmiratesID Number Page:
	.add_emirates_id_number_container {
		.add_emirates_id_number_form_image_row {
			width: 100%;
			display: flex;
			flex-direction: column-reverse;
			.add_emirates_id_card_image {
				display: flex;
				justify-content: center;
				padding-right: 8%;
			}
		}
		.add_emirates_id_number_link_button {
			display: flex;
			justify-content: center;
			padding: 8px 0px 0px 0px;
		}
	}
}
