.forBackground {
	.leaveStatus {
		font-family: 'Montserrat-Bold';
		font-size: 20px;
		color: #00344e;
		font-weight: 700;
		margin-top: 12px;
		margin-bottom: 12px;
		overflow: auto scroll;
	}
}
// .forBackground {
// 	margin: 10px;
// }
.forMobileEmpLeave {
	.forAlignments {
		align-items: center;
		border-radius: 16px;
		background: white;
		box-shadow: 0px 5px 10px rgb(174 182 183 / 25%);
		border: 1px solid white;
		width: 95%;
		margin-bottom: 12px;
		// padding-left: 10%;
		padding-top: 5%;
		padding-left: 10px;
	}
	.forCenter {
		display: flex;
	}
}
.forAlignments {
	.imgcolors-Annual {
		height: 40px;
		min-height: 40px;
		background-color: #fff7e8;
		border-radius: 999px;
	}
	.imgcolors-Sick {
		height: 40px;
		min-height: 40px;
		background-color: #fff1e5;
		border-radius: 999px;
	}
	.imgcolors-Unpaid {
		height: 40px;
		min-height: 40px;
		background-color: #e5fff6;
		border-radius: 999px;
	}
	.imgcolors-Maternity {
		height: 40px;
		min-height: 40px;
		background-color: #fee5ff;
		border-radius: 999px;
	}
	.imgcolors-Paternity {
		height: 40px;
		min-height: 40px;
		background-color: #e5ebff;
		border-radius: 999px;
	}
	.imgcolors-Compassionate {
		height: 40px;
		min-height: 40px;
		background-color: #f0e5ff;
		border-radius: 999px;
	}
	.imgcolors-Hajj {
		height: 40px;
		min-height: 40px;
		background-color: #fff4d4;
		border-radius: 999px;
	}
	.leaveType {
		font-family: 'Montserrat-Medium';
		font-weight: 500;
		font-size: 14px;
		color: #00344e;
		line-height: 16px;
		margin-bottom: 0px;
		margin-top: 12px;
	}
	.Remaining {
		font-family: 'Montserrat-Regular';
		font-weight: 300;
		font-size: 10px;
		color: #777e7f;
		margin-top: 5px;
		line-height: 16px;
		margin-bottom: 0px;
		letter-spacing: 0.4px;
	}
	.forDays {
		font-family: 'Montserrat-Bold';
		font-size: 18px;
		line-height: 20px;
		color: #4991b6;
		// opacity: 0.8;
		margin-bottom: 12px;
	}
}
.forMobileEmpLeave {
	flex-wrap: nowrap;
	overflow: scroll;
}
.fullContainer {
	padding: 10px;
}
.leaveHistoryContainer {
	border: 1px solid white;
	box-shadow: 0px 5px 10px rgb(174 182 183 / 50%);
	// height: 92px;
	border-radius: 10px;
	padding: 12px;
	overflow-y: scroll;
	max-height: 350px;
}
.leaveHistoryContainer {
	.leaveType {
		margin-bottom: 7px;
		font-family: 'Montserrat-Bold';
		font-size: 12px;
		line-height: 14px;
		color: #2b769c;
	}
	.leaveDays {
		margin-bottom: 7px;
		font-family: 'Montserrat-Light';
		font-size: 12px;
		line-height: 14px;
		color: #000000;
		letter-spacing: 0.4px;
	}
	.forStatusReview {
		margin-bottom: 0px;
		font-family: 'Montserrat-medium';
		font-size: 12px;
		line-height: 14px;
		color: #78be20;
		letter-spacing: 0.4px;
	}
	.forStatusReview1 {
		margin-bottom: 0px;
		font-family: 'Montserrat-medium';
		font-size: 12px;
		line-height: 14px;
		color: #e60a14;
		letter-spacing: 0.4px;
	}
	.attachments {
		margin-bottom: 0px;
		font-family: 'Montserrat-Light';
		font-size: 12px;
		line-height: 14px;
		opacity: 0.2;
		color: #000000;
	}
}
.forMobileEmpLeave::-webkit-scrollbar {
	display: none;
}

.topContainer {
	background-color: #f7f8fa;
	height: 205px;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
	padding-left: 12px;
	padding-top: 12px;
	display: flex;
	align-content: end;
	.leaveStatus {
		font-family: 'Montserrat-Bold';
		font-size: 20px;
		color: #00344e;
		font-weight: 700;
		margin-top: 12px;
		margin-bottom: 0px;
		text-decoration: underline;
	}
	.empId {
		font-family: 'Montserrat-Medium';
		font-size: 16px;
		color: #0e5274;
		font-weight: 500;
		line-height: 18px;
		margin-bottom: 0px;
		border-right: 1px solid #0e5274;
		height: 95%;
		padding-right: 2%;
	}
	.department {
		font-family: 'Montserrat-Medium';
		font-size: 16px;
		color: #0e5274;
		font-weight: 500;
		line-height: 18px;
		padding-left: 2%;
	}
	.forCenter {
		display: flex;
	}
}
