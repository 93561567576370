@import '../../../constants/variables';
.add_individual_header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: sticky;
	top: 0px;
	padding: 40px 0px 24px 0px;
	background: $grayscale-200;
	.add_individual_button {
		display: flex;
		align-items: center;
		gap: 8px;
	}
}
