.price-card {
	background: #ffffff;
	border-radius: 20px;
	padding: 20px;
	padding-bottom: 42px;
	margin: 3%;
}
.list-text {
	display: flex;
	align-items: center;
}
.price-Card-heading {
	font-family: 'Montserrat-Bold';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
	color: #00344e;
	text-align: center;
}
.price-Card-number {
	font-family: 'Montserrat-Bold';
	font-style: normal;
	font-weight: 700;
	font-size: 64px;
	line-height: 36px;
	color: #00344e;
	text-align: center;
	margin-bottom: 12px;
}
.price-Card-permonth {
	font-family: 'Montserrat-Light';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	color: #0e5274;
	text-align: center;
	margin-top: 24px;
}
.price-what-included,
.price-what-included-new {
	font-family: 'Montserrat-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	color: #f72717;
	padding-bottom: 8px;
	border-bottom: 0.8px dashed #737780;
	text-align: left;
}
.price-what-included {
	margin-top: 50px;
}
.price-what-included-new {
	margin-top: 50px;
}
.price-card-chip {
	width: fit-content;
	padding: 3px 8px 3px 8px;
	border-radius: 8px;
	text-align: center;
	font-family: 'Montserrat-Light';
	font-size: 12px;
	line-height: 14px;
	font-weight: 300;
	text-align: center;
	color: #181919;
	background-color: #eff1f5;
	border-radius: 8px;
}
.image-wrong {
	margin-left: -5px;
}
.correct,
.wrong {
	font-family: 'Montserrat-Light';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 0px;
	text-align: left;
}
.correct {
	padding: 5% 5% 5% 4%;
	color: #00344e;
}
.wrong {
	padding: 5% 5% 5% 3%;
	color: #737780;
}
.button-container,
.price-chip-div {
	display: flex;
	justify-content: center;
}
.button-container {
	margin-top: 10px;
}
.upgrade-plan {
	width: 252px;
	height: 48px;
	background: #ffffff;
	border: 2px solid #dfe1e6;
	border-radius: 8px;
	font-family: 'Montserrat-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	color: #9a9ea6;
	cursor: pointer;
	margin-bottom: 5%;
}
.upgrade-plan-active {
	width: 252px;
	height: 48px;
	background: linear-gradient(93.76deg, #d10f00 1.98%, #f72717 100%);
	// border: 2px solid #dfe1e6;
	border: none;
	outline: none;
	border-radius: 8px;
	font-family: 'Montserrat-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	color: #ffffff;
	cursor: pointer;
	margin-bottom: 5%;
}
