@import '../../../constants/variables';

.buttonnew {
	border: none;
	cursor: pointer;
	background: none;
	border-radius: 8px;
	font-size: 14px;
	font-family: 'Montserrat-Medium';
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: center;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 16px;
	padding-right: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	height: 32px;
	width: 100%;
	&.no_shadow {
		box-shadow: none !important;
	}
	span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.loader {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		display: inline-block;
		box-sizing: border-box;
		animation: rotation 1s linear infinite;
	}

	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	.primary_loader {
		border: 1px solid #f7f8fa;
		border-bottom-color: $red-color-2;
	}
	.secondary_loader {
		border: 1px solid #dfe1e6;
		border-bottom-color: $red-color-2;
	}

	// .loader {
	// 	border-top: 2px solid transparent;
	// 	border-radius: 10px;
	// 	width: 20px;
	// 	height: 20px;
	// 	animation: spin 1s linear infinite;
	// }
	// .primary_loader {
	// 	border: 1px solid #f7f8fa;
	// }
	// .secondary_loader {
	// 	border: 1px solid #dfe1e6;
	// }

	// @keyframes spin {
	// 	0% {
	// 		transform: rotate(0deg);
	// 	}

	// 	100% {
	// 		transform: rotate(360deg);
	// 	}
	// }
}

.block_click {
	pointer-events: none;
}

.primary-btn {
	background: $red-gradient-normal;
	color: $neutral-white;
	box-shadow: 0px 8px 16px -12px $red-color;

	&:disabled {
		color: $grayscale-400;
		cursor: not-allowed;
		background: $grayscale-300;
		box-shadow: none;

		&:hover {
			color: $grayscale-400;
			background: $grayscale-300;
		}
	}

	&:hover {
		background: linear-gradient(93.76deg, $red-color 1.98%, $red-color-2 100%);
		transition: all 0.8s;
		// transform: scale(1.02);
	}

	&:active {
		background: $red-gradient-dark;
	}
}

.inactive {
	background: $grayscale-200;
	color: $grayscale-300;
	cursor: not-allowed;
	box-shadow: none;

	&:hover {
		background: $grayscale-200;
		color: $grayscale-300;
		box-shadow: none;
	}
}

.secondary-btn {
	background: transparent;
	color: $grayscale-700;
	border: 1px solid $grayscale-400;

	&:disabled {
		color: $grayscale-300;
		cursor: not-allowed;
		border: 1px solid $grayscale-300;

		&:hover {
			color: $grayscale-300;
			border: 1px solid $grayscale-300;
		}
	}

	&:hover {
		border: 1px solid $grayscale-300;
		color: $grayscale-600;
		transition: all 0.8s;
		// transform: scale(1.02);
	}

	&:active {
		border: 1px solid$grayscale-500;
		color: $grayscale-900;
	}
}

.secondary-active {
	border: 1px solid $grayscale-200;
	color: $grayscale-300;
	cursor: not-allowed;

	&:hover {
		border: 1px solid $grayscale-200;
		color: $grayscale-300;
	}
}

.link-btn {
	border: none;
	background-color: $neutral-white;
	text-decoration: underline;
	color: $blue-400;
	background-color: none;

	&:hover {
		color: $blue-light !important;
	}

	&:active {
		color: $blue-500 !important;
	}

	&.btn-text {
		margin-left: 5px;
		margin-right: 5px;
	}
}

.leftIcon {
	margin-right: 0.8rem;
}

.rightIcon {
	margin-left: 0.8rem;
}

.leftIcon1 {
	margin-right: 2px;
}

.rightIcon1 {
	margin-left: 2px;
}
