.modal_container1 {
	.ant-modal-content {
		border-radius: 20px;
		height: 260px;
	}
	.main_popup_container {
		height: 277px;
		width: 100%;
		border-radius: 20px;
		overflow: hidden;

		.message_container {
			width: 100%;
			height: 83% !important;
			// background-color: yellow;
			padding: 0px 30px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.popup_heading_text {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 16px !important;
				line-height: 18px;
				color: #00344e;
				padding-top: 30px;
				// margin-left: 20px;
			}

			.popup_message_text {
				padding-top: 10px;
				font-family: Montserrat-Regular;
				font-size: 15px;
				font-style: normal;
				font-weight: 300;
				line-height: 18px;
				letter-spacing: 0em;
				text-align: left;
			}
		}

		.routing_container {
			width: 100%;
			height: 18% !important;
			background-color: #d52b1e;
			display: flex;
			justify-content: center;
			align-items: center;

			.back_router_text {
				//styleName: Mont_H4 Medium 18pt;
				font-family: Montserrat-Medium;
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px;
				letter-spacing: 0em;
				text-align: center;
				color: #f7f8fa;
				vertical-align: middle;
			}
		}
	}
}
