.invoice-bankdetails_container {
    .invoice-fund-info-and-transfer-fund-container {
        display: flex;
        margin-top: 24px;
        justify-content: space-between;
    }
    .toast {
        box-shadow: none;
    }
    .invoice-copy-message {
        font-size: 10px;
        color: green;
        font-weight: 600;
    }
    .invoice-accnumber-box {
        align-items: center;
        display: flex;
        background-color: #FFFFFF;
        border-radius: 8px;
        padding: 10px;
    }
    .acc-number-style {
        padding-right: 10px;
    }
    .invoice-info-cell {
        width: calc(60% - 16px);
        background-color: #F9FAFB;
        padding: 16px;
        .name {
            width: 40%;
        }
        .value {
            width: 60%;
        }
    }
    .bank-info {
        background-color: #F9FAFB;
        width: calc(40% - 16px);
        padding: 16px;
    }
    .invoice-each-info-cell {
        display: flex;
    }
}