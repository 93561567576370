@import '../../../constants/variables';

.hr-feature-access-container {
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	background: $grayscale-200;

	.contents {
		display: flex;
		justify-content: center;
		padding: 0px;
		width: inherit;
		height: 80%;
	}
}
