@import './../../../constants/variables';

@media only screen and (max-width: 600px) {
	.company_mobile_container {
		padding: 65px 12px 65px 12px;
		width: 100%;
		.ant-tabs-tab-btn:focus,
		.ant-tabs-tab-remove:focus,
		.ant-tabs-tab-btn:active,
		.ant-tabs-tab-remove:active {
			color: $secondaryColor5;
		}
		.ant-tabs-tab:hover {
			color: $secondaryColor5;
		}
		.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
			background: $secondaryColor5;
		}
		.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
			text-align: center;
			color: $secondaryColor5;
		}
		.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
			padding: 0px 24px 2px 24px;
			border-bottom: 2px solid $greyColor4;
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 18px;
			text-align: center;
			color: $greyColor5;
			margin: 0px 12px 0px 0px;
		}
		.ant-tabs > .ant-tabs-nav,
		.ant-tabs > div > .ant-tabs-nav {
			position: inherit;
		}
		.company_information_container {
			width: 100%;
			height: 100%;
			padding: 0px;
			.company_documents_details_row {
				padding-top: 12px;
			}
			.company_information_company_details_row {
				width: 100%;
				.company_information_title {
					padding: 0px 0px 12px 0px;
					span {
						font-family: Montserrat-Bold;
						font-style: normal;
						font-weight: bold;
						font-size: 24px;
						line-height: 28px;
						color: $primaryColor5;
					}
				}
				.company_details_row {
					width: 100%;
					padding: 12px 0px 8px 0px;
					.company_details_name {
						padding: 0px 0px 8px 0px;
					}
					.company_details_address {
						padding: 0px 0px 8px 0px;
						.floating-label {
							.floating-input {
								padding: 4px 28px 4px 4px;
								text-overflow: ellipsis;
							}
						}
					}
					.company_details_save {
						padding: 12px 0px 0px 0px;
					}
				}
			}
			.company_documents_title {
				padding: 0px 0px 12px 0px;
				span {
					font-size: 20px;
					line-height: 24px;
				}
			}
			.company_documentation_card_section {
				.company_document_card_column {
					padding-left: 0px;
					width: 100%;
					margin: 0px 0px 8px 0px;
					border-radius: 16px;
					box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
					min-height: unset;
					&.feedbackWarning {
						background: $feedbackWarning;
					}
					&.feedbackSuccess {
						background: $feedbackSuccess;
					}
					&.feedbackError {
						background: $feedbackError;
					}
					&.powerOfAttorney {
						background: $greyColor4;
					}
					.company_document_card {
						background: $greyColor1;
						box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
						border: none;
						border-radius: 16px;
						min-height: unset;
						padding: 4px;
						cursor: pointer;
						.ant-card-body {
							padding: 4px;
							display: flex;
							align-items: center;
							justify-content: center;

							.card_content_section {
								display: inline-grid;
								padding: 6px;
								span {
									padding: 2px 0px;
								}
								.card_type {
									font-size: 12px;
									line-height: 14px;
								}

								.card_title {
									font-size: 14px;
									line-height: 16px;
								}
								.license_type {
									font-size: 12px;
									line-height: 14px;
								}
								.license_id {
									font-size: 12px;
									line-height: 14px;
								}
								.hint {
									font-size: 12px;
									line-height: 14px;
								}
								.expires_on {
									font-size: 12px;
									line-height: 14px;
								}

								.document_validity_status {
									font-size: 14px;
									line-height: 16px;
									&.feedbackWarning {
										color: $feedbackWarning2;
									}
									&.feedbackError {
										color: $feedbackError;
									}
								}
							}
							.document_update_button_icon {
								display: flex;
								flex-direction: column;
								align-items: center;
								img {
									height: 40px;
									width: 40px;
								}
								a {
									button {
										font-family: Montserrat-Regular;
										font-style: normal;
										font-weight: 300;
										font-size: 12px;
										line-height: 14px;
										color: $primaryColor4;
										text-align: center;
										text-decoration: underline;
										text-underline-position: under;
										padding: 0px;
										width: auto;
										min-height: auto;
									}
								}
							}
						}
					}
				}
			}
			.add_new_main_trade_license_section {
				padding: 16px 0px 24px 0px;
				.add_new_main_trade_license_description {
					padding: 8px 0px 8px 0px;
					span {
						font-size: 14px;
						line-height: 16px;
					}
				}
				.add_new_main_trade_license_step_button_section {
					padding: 4px 12px 4px 0px;
					.add_new_main_trade_license_steps_container {
						padding: 0px 0px 4px 0px;
						.add_new_main_trade_license_step_icon {
							span {
								font-size: 16px;
								line-height: 18px;
							}
						}
						.add_new_main_trade_license_step_content {
							padding: 0px 0px 0px 6px;
						}
					}
					a {
						display: flex;
						justify-content: center;
						padding-left: 28px;
						button {
							font-family: Montserrat-ExtraLight;
							padding: 2px;
						}
					}
				}
			}
		}
	}

	// please-upload-spreadsheet
	.please-upload-spreadsheet {
		width: 100%;
		padding: 65px 15px 0px 15px;
		.upload-spreadsheet-header {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: #00344e;
			}
		}
		.dragger_card_container {
			width: 100%;
			display: flex;
			justify-content: center;
			padding: 5px 0px 5px 0px;
			.dragger {
				background: #ffffff;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				border-radius: 16px;
				width: 180px;
				.Upload_row_1 {
					display: none;
				}
				.Upload_row_2 {
					display: flex !important;
					width: 100%;
					img {
						width: 96px;
						height: 96px;
					}
					.after_file_upload {
						display: inline-grid;
						.file_name {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							line-height: 14px;
							text-align: center;
							color: $primaryColor5;
						}
						.file_size {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 12px;
							line-height: 14px;
							text-align: center;
							color: $primaryColor5;
						}
					}
				}
			}
		}
		.expiry-document {
			padding-top: 16px;
			width: 100%;
			span {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: #00344e;
			}
		}
		.input-field-expiry-date {
			padding-top: 16px;
			.form-field__label {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				color: #737780;
			}
		}
	}

	// company-branch-name
	.company-branch-name-container {
		width: 100%;
		padding: 65px 15px 10px 15px;
		.branch-name-header {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: #00344e;
			}
		}
		.branch-name-sub-header {
			padding-top: 5px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: #737780;
			}
		}
	}

	// company-authority
	.company-authority-container {
		width: 100%;
		padding: 65px 15px 10px 15px;
		.authority-header {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: #00344e;
			}
		}
		.authority-sub-header {
			padding-top: 5px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: #737780;
			}
		}
	}

	// company-establishment-id
	.company-establishment-id-container {
		width: 100%;
		padding: 65px 15px 10px 15px;
		.establishment-id-header {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: #00344e;
			}
		}
		.link-button {
			text-align: center;
			button {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: #0e5274;
				text-decoration: underline;
				text-underline-position: under;
				padding: 0px 0px;
			}
		}
	}

	// company-industry
	.company-industry-container {
		width: 100%;
		padding: 65px 15px 10px 15px;
		.industry-header {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: #00344e;
			}
		}
		.industry-button {
			text-align: center;
			button {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: #0e5274;
				text-decoration: underline;
				text-underline-position: under;
				padding: 0px 0px;
			}
		}
	}
}
