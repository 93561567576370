@import '../../../constants/_variables.scss';
.select_month_dropdown {
	div {
		div {
		}
		div {
			div {
			}
			.rc-virtual-list-scrollbar {
				display: unset !important;
			}
		}
	}
}
.payroll_channel_container {
	display: flex;
	flex-direction: column;
	padding: 0px 135px 0px 135px;
	.payroll_channel_header {
		padding: 0px 0px 8px 0px;
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: 700;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.payroll_channel_select_card_section {
		padding: 16px 0px 8px 0px;
		.payroll_channel_select_cards {
			padding: 8px 12px 8px 0px;
			cursor: pointer;

			.payroll_channel_select_card {
				background: $greyColor1;
				box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.5);
				border-radius: 24px;
				height: 120px;
				min-height: 120px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
			}
			.payroll_channel_unselect_card {
				background: $greyColor1;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				border-radius: 24px;
				height: 120px;
				min-height: 120px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
			}
		}
	}
	.payroll_channel_button_section {
		padding: 16px 0px 8px 0px;
		button {
			font-family: 'Montserrat-Medium';
			font-size: 18px;
			font-weight: 500;
			line-height: 20px;
		}
	}
}

.wps_payroll_container {
	display: flex;
	flex-direction: row;
	padding: 0px 135px 0px 135px;
	.wps_payroll_header {
		padding-bottom: 1.5%;
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: #00344e;
		}
	}
	.wps_payroll_description {
		padding-bottom: 2%;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: #484b52;
		}
	}
	.wps_payroll_select_desktop_section {
		display: flex;
		flex-direction: row;
		width: 100%;
		padding-bottom: 2%;
		.wps_payroll_month {
			padding-right: 1%;

			.ant-select {
				display: flex;
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				color: #00344e;
				.ant-select-selector {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 52px;
					width: 100%;
					border: none;
					border-radius: 16px;
					box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				}
				.ant-select-arrow {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 20px;
					color: #11266900;
					content: url('../../../assets/icons/not_focus/Down.svg');
					height: 28px;
					width: 28px;
					margin-top: -15px;
				}
				.ant-select-item {
					font-family: 'Montserrat-Medium';
					font-size: 14pt;
					font-weight: 500;
				}
			}
		}
	}
	.wps_payroll_select_mobile_section {
		display: none;
	}
	.wps_payroll_button {
		padding-top: 2%;
		button {
			font-size: 14pt;
			line-height: 16pt;
			font-family: 'Montserrat-Medium';
			font-weight: 500;
		}
	}
}

.non_wps_payroll_container {
	display: flex;
	flex-direction: row;
	padding: 0px 135px 0px 135px;
	.non_wps_payroll_header {
		padding-bottom: 1.5%;
		span {
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.non_wps_payroll_description {
		padding-bottom: 2%;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
	}
	.non_wps_payroll_select_desktop_section {
		display: flex;
		flex-direction: row;
		width: 100%;
		padding-bottom: 2%;
		.non_wps_payroll_month {
			padding: 0px 8px 4px 0px;
			.ant-select {
				display: flex;
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				color: $primaryColor5;
				.ant-select-selector {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 52px;
					width: 100%;
					border: none;
					border-radius: 16px;
					box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				}
				.ant-select-arrow {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 20px;
					color: #11266900;
					content: url('../../../assets/icons/not_focus/Down.svg');
					height: 30px;
					width: 30px;
					margin-top: -15px;
				}
				.ant-select-item {
					font-family: Montserrat-Regular;
					font-size: 16px;
					font-weight: 500;
				}
			}
		}
	}
	.non_wps_payroll_select_mobile_section {
		display: none;
	}
	.non_wps_payroll_button {
		padding-top: 1%;
		button {
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 20px;
			color: $greyColor2;
			min-width: 340px;
		}
	}
}

.run_payroll_now_later_container {
	display: flex;
	flex-direction: row;
	padding: 0% 8% 0% 9%;
	.run_payroll_now_later_header {
		padding-bottom: 10px;
		span {
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: #00344e;
		}
	}
	.run_payroll_now_later_description {
		padding: 12px 0 10px 0;
		span {
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: #484b52;
		}
	}
	.run_payroll_now_later_button_section {
		width: 100%;
		padding: 12px 0 10px 0;
		.run_payroll_later_button {
			padding-right: 8px;
			button {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				text-align: center;
				color: #484b52;
			}
		}
		.run_payroll_now_button {
			padding-left: 8px;
			button {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				text-align: center;
				color: #f7f8fa;
			}
		}
	}
	.run_payroll_later_part {
		padding-top: 2%;
		.run_payroll_now_later_header {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				color: #00344e;
			}
		}
	}
	.run_payroll_now_later_section {
		display: flex;
		flex-direction: row;
		width: 100%;
		padding-bottom: 2%;
		.run_payroll_now_later_date {
			padding-right: 1%;
			.ant-select {
				display: flex;
				font-family: 'Montserrat-Medium';
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				color: #00344e;
				.ant-select-selector {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 52px;
					width: 100%;
					border: none;
					border-radius: 16px;
					box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				}
				.ant-select-arrow {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 20px;
					color: #11266900;
					content: url('../../../assets/icons/not_focus/Down.svg');
					height: 30px;
					width: 30px;
					margin-top: -15px;
				}
				.ant-select-item {
					font-family: 'Montserrat-Medium';
					font-size: 14pt;
					font-weight: 500;
				}
			}
		}
	}
	.run_payroll_now_later_mobile_view_section {
		display: none;
	}
	.run_payroll_now_later_button {
		button {
			font-size: 18px;
			font-family: 'Montserrat-Medium';
			font-weight: 500;
			width: 266px;
		}
	}
}

.submit_payroll_container {
	display: flex;
	flex-direction: row;
	align-self: flex-start;
	padding: 0px 40px 0px 40px;
	width: 100%;
	.submit_payroll_header {
		padding: 40px 0px 24px 0px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.payroll_review_button {
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}
	.payroll_review_card {
		width: 100%;
		padding: 32px;
		background: #ffffff;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		.submit_payroll_detail_section {
			width: 100%;
			margin: 0 !important;
			padding: 2% 0% 1% 1%;
			.submit_payroll_detail {
				width: 100%;
				padding: 0% !important;
				border: 1px solid #eff1f5;
				border-radius: 8px;
				.submit_payroll_detail_table {
					display: flex;
					flex-direction: row;
					padding: 17px 16px;
					margin: 0% !important;
					border-bottom: 1px solid #eff1f5;
					&:last-child {
						border-bottom: none;
					}
					&:nth-child(even) {
						background: $grayscale-50;
					}
					.submit_payroll_detail_content {
						width: 40%;
					}
				}
				.ant-divider-horizontal {
					margin: 12px 0;
				}
				.ant-divider {
					border-top: 1.5px solid $greyColor4;
				}
			}
		}
		.submit_payroll_button {
			padding: 3% 1% 0% 0%;
			button {
				font-size: 14pt;
				line-height: 16pt;
				font-family: 'Montserrat-Medium';
				font-weight: 500;
			}
			.user_exists {
				font-family: 'Montserrat-Regular';
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 18px;
				letter-spacing: 0.1px;
				margin: 5px;
			}
		}
	}
}

.run_payroll_final_next_step {
	padding: 0% 40px 0% 40px;
	align-self: flex-start;
	.next_run_payroll_header_fixed {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: sticky;
		top: 0%;
		z-index: 1;
		background: $grayscale-200;
		padding: 40px 0px 24px 0px;
	}
	.next_page_payroll_container {
		display: flex;
		flex-direction: row;
		padding: 32px;
		background: $neutral-white;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		.next_page_payroll_info_wrapper {
			padding: 8px 8px 8px 16px;
			background: rgba(204, 236, 230, 0.25);
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 32px;
			border-radius: 8px;
			width: 100%;
			.next_page_payroll_info {
				display: flex;
				align-items: center;
				gap: 16px;
			}
		}
		.next_page_payroll_message {
			margin-top: 5px;
		}
		.next_page_payroll_status {
			.next_page_payroll_status_container {
				display: flex;
				align-items: center;
				margin-top: 16px;
				.next_page_payroll_status_icon {
					background: linear-gradient(93.76deg, #f7f8fa 1.98%, #f9fafb 100%);
					border-radius: 8px;
					padding: 10px;
					width: 44px;
					height: 44px;
					img {
						width: 24px;
						height: 24px;
					}
				}
				.next_page_payroll_status_content {
					display: flex;
					gap: 8px;
					span {
						color: $grayscale-700;
						padding-left: 8px;
					}
					a {
						text-decoration: underline;
						text-underline-position: under;
					}
				}
			}
		}
		.next_page_payroll_button_row {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			width: 100%;
			padding: 1% 0% 0% 0%;
			.next_page_payroll_button {
				padding: 1% 0% 0% 0%;
				button {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: #00344e;
					padding: 5%;
					height: 32px;
					width: 167px;
					min-height: 32px;
					min-width: 167px;
				}
			}
		}
		.next_page_payroll_footer_message {
			margin-top: 14px;
			padding: 16px;
			background: $grayscale-50;
			border-radius: 8px;
			transform: rotate(-0.09deg);
		}
	}
}

@media only screen and (max-width: 600px) {
	.alert-dismissible {
		bottom: 0 !important;
		top: unset;
	}
	.payroll_channel_container {
		padding: 1% 2% 0% 2%;
		padding-top: 64px;
		.payroll_channel_header {
			padding: 1% 1% 1% 2%;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: $primaryColor5;
			}
		}

		.payroll_channel_select_card_section {
			padding: 3% 1% 3% 2%;
			.payroll_channel_select_cards {
				padding: 2% 0% 0.5% 0%;
				.payroll_channel_select_card {
					border-radius: 16px;
					padding: 1%;
					height: 80px;
					min-height: 80px;
				}
				.payroll_channel_unselect_card {
					border-radius: 16px;
					padding: 1%;
					height: 80px;
					min-height: 80px;
				}
			}
		}
		.payroll_channel_button_section {
			padding: 2% 1% 1% 2%;
		}
	}

	.wps_payroll_container {
		padding: 65px 16px 12px 16px;
		.wps_payroll_header {
			padding-bottom: 1%;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: #00344e;
			}
		}
		.wps_payroll_description {
			padding-bottom: 4%;
			padding-right: 5px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: #737780;
			}
		}
		.wps_payroll_select_desktop_section {
			display: none;
		}
		.wps_payroll_select_mobile_section {
			display: flex !important;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			height: 55px;
			width: 100%;
			padding-bottom: 4%;
			.wps_payroll_select_month {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				padding: 2%;
				height: 100%;
				background: #ffffff;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.35);
				border-radius: 16px;
				span {
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: $primaryColor5;
				}
				img {
					width: 24px;
					height: 24px;
				}
			}
			.wps_payroll_select_year {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				padding: 2%;
				height: 100%;
				background: #ffffff;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.35);
				border-radius: 16px;
				span {
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: $primaryColor5;
				}
				img {
					width: 24px;
					height: 24px;
				}
			}
		}
		.wps_payroll_button {
			padding-top: 4%;
			button {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 18px;
			}
		}
	}

	.non_wps_payroll_container {
		padding: 65px 16px 12px 16px;
		.non_wps_payroll_header {
			padding-bottom: 4px;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: #00344e;
			}
		}
		.non_wps_payroll_description {
			padding-bottom: 12px;

			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: #737780;
			}
		}
		.non_wps_payroll_select_desktop_section {
			display: none;
		}
		.non_wps_payroll_select_mobile_section {
			display: flex !important;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding: 12px 0px 12px 0px;
			.non_wps_payroll_select_month {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 48px;
				min-height: 48px;
				padding: 4px;
				background: #ffffff;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				border-radius: 16px;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #00344e;
				}
				img {
					width: 24px;
					height: 24px;
				}
			}
			.non_wps_payroll_select_year {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				height: 48px;
				min-height: 48px;
				width: 156px;
				min-width: 156px;
				padding: 4px;
				background: #ffffff;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				border-radius: 16px;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #00344e;
				}
				img {
					width: 24px;
					height: 24px;
				}
			}
		}
		.non_wps_payroll_button {
			padding-top: 4%;

			button {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 18px;
				min-width: 100%;
			}
		}
	}

	.month_modal_button {
		padding: 3px 8px;
		min-height: 26pt;
		display: flex;
		justify-content: space-evenly;
		font-size: 14px;
		font-weight: 300;
		color: $primaryColor4;
		border: 1px solid $greyColor4;
		box-sizing: border-box;
		background: none !important;
		box-shadow: none !important;
	}
	.date_modal_button {
		border-radius: 50%;
		padding: 0;
		width: 35px;
		min-height: 35px;
	}
	.isSelected {
		background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%) !important;
		color: white;
	}

	.date_modal {
		.icon_area {
			display: flex;
			justify-content: center;
			align-items: center;
			.left_arrow {
				width: 20px;
				transform: rotate(90deg);
			}
			.right_arrow {
				width: 20px;
				transform: rotate(-90deg);
			}
		}
	}

	.run_payroll_now_later_container {
		display: flex;
		flex-direction: row;
		padding: 65px 2% 1% 2%;
		.run_payroll_now_later_header {
			padding: 1% 1% 1% 3%;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: #00344e;
			}
		}
		.run_payroll_now_later_description {
			padding: 1% 1% 1% 3%;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: #737780;
			}
		}
		.run_payroll_now_later_button_section {
			padding: 1% 1% 1% 2%;
			.run_payroll_later_button {
				button {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 16px;
					line-height: 18px;
					text-align: center;
					color: #0e5274;
				}
			}
			.run_payroll_now_button {
				button {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 16px;
					line-height: 18px;
					text-align: center;
					color: #f7f8fa;
				}
			}
		}
		.run_payroll_now_later_section {
			display: none;
		}
		.run_payroll_later_part {
			.run_payroll_now_later_header {
				span {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 20px;
					line-height: 24px;
					color: #00344e;
				}
			}
		}
		.run_payroll_now_later_mobile_view_section {
			display: flex !important;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			height: 55px;
			width: 100%;
			padding-bottom: 4%;
			padding: 1% 1% 1% 3%;
			.run_payroll_now_later_select_date {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				padding: 2%;
				height: 100%;
				background: #ffffff;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.35);
				border-radius: 16px;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #00344e;
				}
				img {
					width: 18px;
					height: 24px;
				}
			}
			.run_payroll_now_later_select_month {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				padding: 2%;
				height: 100%;
				background: #ffffff;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.35);
				border-radius: 16px;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #00344e;
				}
				img {
					width: 18px;
					height: 24px;
				}
			}
			.run_payroll_now_later_select_year {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				padding: 2%;
				height: 100%;
				background: #ffffff;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.35);
				border-radius: 16px;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #00344e;
				}
				img {
					width: 18px;
					height: 24px;
				}
			}
		}
	}

	.submit_payroll_container {
		display: flex;
		flex-direction: row;
		padding: 70px 4% 3% 4%;
		.submit_payroll_header {
			padding-bottom: 1.5%;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: #00344e;
			}
		}
		.submit_payroll_description {
			padding-bottom: 2%;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: #737780;
			}
		}
		.submit_payroll_detail_section {
			width: 100%;
			margin: 0 !important;
			padding-top: 4%;
			.submit_payroll_detail {
				width: 100%;
				padding: 0% !important;
				.submit_payroll_detail_table {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					padding: 0% !important;
					margin: 0% !important;
					.submit_payroll_detail_content {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 18px;
						color: #484b52;
					}
					.submit_payroll_detail_value {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 18px;
						text-align: right;
						color: #484b52;
					}
				}
				.ant-divider-horizontal {
					margin: 12px 0;
				}
				.ant-divider {
					border-top: 1.5px solid $greyColor4;
				}
			}
		}
		.submit_payroll_button {
			padding-top: 6%;
		}
	}

	.next_page_payroll_container {
		display: flex;
		flex-direction: row;
		padding: 64px 2% 1% 2%;
		align-self: flex-start;
		.next_page_payroll_header {
			padding: 1% 2% 0% 2%;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: $primaryColor5;
			}
		}
		.next_page_payroll_message {
			padding: 3% 2% 2% 2%;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: $greyColor6;
			}
		}
		.next_page_payroll_status {
			padding: 1% 1% 1% 1%;
			.next_page_payroll_status_container {
				display: flex;
				align-items: center;
				padding: 1% 1% 0% 1%;
				.next_page_payroll_status_icon {
					background: linear-gradient(93.76deg, #f7f8fa 1.98%, #f9fafb 100%);
					border-radius: 8px;
					padding: 10px;
					width: 44px;
					height: 44px;
					img {
					}
				}
				.next_page_payroll_status_content {
					padding: 1% 1% 0% 4%;
					span {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 16px;
						color: #484b52;
						padding-right: 1%;
						a {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 14px;
							line-height: 16px;
							text-decoration: underline;
							text-underline-position: under;
							color: $primaryColor4;
						}
					}
				}
			}
		}
		.next_page_payroll_button_row {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			padding: 2% 0% 2% 0%;
			.next_page_payroll_button {
				padding: 1% 1% 0% 1%;
				button {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: #00344e;
					padding: 4%;
				}
			}
		}
	}
}

.ant-select-dropdown {
	border-radius: 24px;
	.ant-select-item-option {
		display: flex;
		border-bottom: 1.5px solid $greyColor4;
		padding: 8px 12px 8px 12px;
		margin: 0px 12px 0px 12px;
		background: white;
		.ant-select-item-option-content {
			flex: auto;
			overflow: hidden;
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 18px;
			color: $primaryColor5;
		}
	}
	.ant-select-item-option-selected {
		.ant-select-item-option-content {
			font-family: Montserrat-Medium;
		}
	}
	.ant-select-item-option-selected::after {
		display: inline-block;
		width: 24px;
		height: 24px;
		margin-right: 5px;
		content: '';
		background: url('../../../assets/icons/focus_state/Check.svg') no-repeat 0 0;
		background-size: 100%;
	}
}

.alert-danger {
	color: #141414;
	background-color: #abe8d5;
	border-color: #f5c6cb;
}
.alert {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
}
.alert-dismissible .close {
	position: absolute;
	top: 0;
	right: 0;
	padding: 0.75rem 1.25rem;
	color: inherit;
}
button.close {
	padding: 0;
	background-color: transparent;
	border: 0;
	appearance: none;
}
.close {
	float: right;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.5;
}
