.edenredLable {
	background-color: #eedcba;
	width: 38px;
	border-radius: 5px;
	text-align: center !important;
	margin-left: 5px;
	.text {
		font-family: Montserrat-Bold;
		font-weight: 900;
		text-align: center !important;
		font-size: 12px !important;
		color: '#00344E' !important;
	}
}
