@import '../../../../constants/_variables.scss';
.wps-penalty-presentational {
	width: 100%;
	padding: 0px 40px 40px 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: flex-start;
	.wps-penalty-presentational-row {
		display: flex;
		align-items: center;
		width: 100%;
		.wps-penalty-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: sticky;
			top: 0;
			z-index: 1;
			background: #f7f8fa;
			padding: 42px 0px 26px 0px;
			.secondary-btn {
				font-family: 'Montserrat-Medium';
			}
			.buttonnew {
				width: 118px;
				img {
					margin-right: 0px;
				}
			}
		}
		.wps-penalty-presentational-new-row {
			background: $neutral-white;
			box-shadow: 0px 16px 32px -24px $gray;
			border-radius: 8px;
			padding: 32px;
			.sub-content {
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					color: $greyColor7;
				}
			}
			.delay {
				padding-top: 24px;
				padding-left: 50px;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 18px;
					color: $primaryColor5;
				}
			}
			.days-calculate {
				.count {
					border: 1px solid #dfe2e2;
					box-sizing: border-box;
					border-radius: 8px;
					height: 48px;
					width: 215px;
					display: flex;
					justify-content: center;
					align-items: center;
					span {
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 16px;
						color: $blue-300;
					}
				}
				.content {
					padding-left: 15px;
					display: flex;
					align-items: center;
					span {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 16px;
						color: $greyColor7;
					}
				}
			}
			.down-arrow {
				width: 100%;
				padding: 4px 0px;
				img {
					width: 24px;
					height: 24px;
				}
			}
			.footer-penalty-content {
				padding-top: 32px;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor7;
				}
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.wps-penalty-presentational {
		width: 100%;
		display: flex;
		align-items: flex-start;
		padding: 65px 15px 65px 15px;
		.wps-penalty-presentational-row {
			padding-bottom: 70px;
			width: 100%;
			.wps-penalty-header {
				span {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 20px;
					line-height: 24px;
					color: $primaryColor5;
				}
			}
			.sub-content {
				padding-top: 24px;
				span {
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: #737780;
				}
			}
			.delay {
				padding-top: 24px;
				padding-left: 0px;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 18px;
					color: $primaryColor5;
				}
			}
			.days-calculate {
				.count {
					border: 1px solid #dfe2e2;
					box-sizing: border-box;
					border-radius: 8px;
					height: 83px;
					width: 96px;
					display: flex;
					justify-content: center;
					align-items: center;
					span {
						font-family: Montserrat-Bold;
						font-style: normal;
						font-weight: bold;
						font-size: 16px;
						line-height: 18px;
						text-align: center;
						color: $primaryColor5;
					}
				}
				.content {
					padding-left: 15px;
					display: flex;
					align-items: center;
					span {
						font-family: Montserrat-Light;
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 16px;
						color: #737780;
					}
				}
			}
			.down-arrow {
				width: 100%;
				padding: 4px 0px;
				img {
					width: 24px;
					height: 24px;
				}
			}
			.footer-penalty-content {
				padding-top: 32px;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor7;
				}
			}
		}
	}
}
