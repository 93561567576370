@import '../../constants/_variables.scss';

.main-container {
	width: 100%;
	.left-pannel {
		.logo {
			height: 10vh;
			width: 100%;
			display: flex;
			align-items: flex-end;
			justify-content: center;
		}
		.left_bubble {
			height: 90vh;
			display: flex;
			align-items: flex-end;
		}
	}
	.center-pannel {
		padding: 30px 0px 0% 3%;
		height: 100%;
		align-self: center;
		.header_app_mobile {
			display: none;
		}
		.header_app {
			padding: 0px 15px 0px 0px;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				color: #00344e;
			}
		}
		.action_type {
			font-size: 20px;
		}
		.content_list_main {
			width: 100%;
			.content_list {
				width: 100%;
				margin-top: 10px;
				.horizontal {
					width: 100%;
					height: 0.5px;
					background: $greyColor3;
					border: 1px solid $greyColor3;
				}
				.content_detail {
					padding: 1px 0px;
					.toggle_switch {
						display: flex;
						align-items: center;
					}
					.header {
						span {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 16px;
							color: #00344e;
						}
					}
					.detail {
						span {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 12px;
							line-height: 14px;
							color: #484b52;
						}
					}
				}
				.switch {
					height: 30px;
					width: 40%;
				}
			}
		}
		.card_action {
			width: 100%;
			border: 1px solid $primaryYellow;
			border-radius: 8px;
			margin-top: 3%;
			background-color: #fff6f5;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: #484b52;
			}
		}
	}
	.right-pannel {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		.sub_pannel {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: flex-end;
		}
	}
}

.modal_card {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	.Remove_user {
		padding-top: 10%;
		.user {
			font-size: 18px;
		}
	}
}

@media only screen and (max-width: 770px) {
	.left_bubble {
		display: none !important;
	}
	.right-pannel {
		display: none !important;
	}

	.explem {
		width: 95%;
	}

	.modal_card {
		display: flex;
		align-items: flex-end;
		height: 100%;
		width: 100%;
		.Remove_user {
			padding-top: 0%;
			.user {
				font-size: 18px;
			}
		}
	}
	.main-container {
		padding-top: 65px;
	}

	.main-container .left-pannel .logo {
		height: 10vh;
		width: 100%;
		padding-left: 3%;

		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
	}
	.main-container .center-pannel {
		margin: 0px;
		padding: 32px 7% 0px 7%;
		.header_app {
			display: none !important;
		}
		.header {
			span {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: #00344e;
			}
		}
		.detail {
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 10px;
				line-height: 14px;
				color: #484b52;
			}
		}
		.header_app_mobile {
			display: flex !important;
			padding: 7px 0px;
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: #00344e;
			}
		}
		.content_list_main {
			.content_list {
				margin: 0px 0px;
				.content_detail {
					margin: 0px 0px;
					padding: 5px 0px;
					display: flex;
					align-items: center;
				}
			}
		}
		.card_action {
			width: 100%;
			border: 1px solid #e5e076;
			border-radius: 8px;
			.ant-card-body {
				padding: 1px;
			}
			.action_apporve {
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					color: #484b52;
				}
			}
		}
	}
}
@media only screen and (max-width: 700px) {
	.card_action {
		font-size: 12px;
	}
}
@media only screen and (max-width: 600px) {
	.left-pannel {
		.logo {
			display: none !important;
		}
	}
}

// toggle switch
.ant-switch-handle {
	position: absolute;
	top: 5px;
	left: 2px;
	width: 20px;
	height: 20px;
}
.ant-switch-checked .ant-switch-handle {
	left: calc(100% - 25px - 0px);
}
.ant-switch-checked {
	background: linear-gradient(135deg, #006393 0%, #0088ca 100%);
}

// card
.ant-card-body {
	padding: 0px;
}
hr.horizontal {
	border: 1px solid #eff1f5;
}
