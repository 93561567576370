@import '../../../constants/variables';

.UsefulFiles_container {
	padding: 40px 40px 0px 40px;
	width: 100%;
	align-self: flex-start;
	.UsefulFiles_header {
		padding-bottom: 20px;
		padding-left: 2px;
		span {
			color: $primaryColor5;
			font-family: Montserrat-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
		}
	}
	.card_content_list {
		background-color: white;
		padding: 32px;
		border-radius: 8px;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		.UsefulFiles_Card {
			padding: 0px;
			cursor: pointer;
			.edenredCard_main {
				cursor: pointer;
				padding: 24px 20px;
			}
			.ant-card-bordered {
				border: none;
			}
			.payrollIcons {
				display: flex;
				width: 40px;
				height: 40px;
				align-items: center;
				justify-content: center;
				background-color: #f7f8fa;
				padding: 8px;
				margin-right: 16px;
				border-radius: 8px;
			}
			.download_icon {
				width: 40px;
				img {
					width: 24px;
					height: 24px;
				}
			}
		}
	}
}

.disable_download_card {
	cursor: not-allowed;
	.edenredCard_main {
		background-color: #dee1e561;
		pointer-events: none;
	}
}
@media only screen and (max-width: 600px) {
	.UsefulFiles_container {
		padding: 0% 3%;
		padding-top: 65px;
		.UsefulFiles_header {
			padding-bottom: 12px;
			span {
				font-size: 20px;
				color: $primaryColor5;
			}
		}
		.card_content_list {
			overflow-y: scroll;
			height: 71vh;
			.UsefulFiles_Card {
				padding: 4px 0px;
				.edenredCard_main {
					padding: 0px;
				}
			}
		}
	}
}
.salaryreports {
	border-radius: 24px !important;
}
@media only screen and (min-width: 600px) and (max-width: 1200px) {
	.UsefulFiles_container {
		//padding: 0px 100px 0px 100px !important;
	}
	.edenredCard_main .edencard_web_view_useful_files .content_useful_files .title_edencard span {
		font-size: 16px !important;
	}
	.edenredCard_main .edencard_web_view_useful_files .content_useful_files .description_edenredcard {
		font-size: 13px !important;
	}
}
