@import '../../../../constants/variables';

@media only screen and (max-width: 600px) {
	.individual_employee_container {
		padding: 65px 0px 0px 0px;
		.individual_employee_title {
			padding: 8px 0px 12px 10px;
			span {
				font-size: 20px;
				line-height: 24px;
			}
		}
		.individual_employee_details_form_section {
			padding: 0px 6px;
			.ant-form {
				display: flex !important;
				flex-direction: column;
				.individual_employee_details_form_items_row {
					display: flex;
					flex-direction: column;
					padding: 0px;
					.ant-form-item {
						margin: 0px !important;
						width: 100%;
						padding: 4px;
					}
				}
			}
			.individual_employee_details_form_items_column {
				padding: 4px 0px 0px 0px;
				.ant-form-item {
					margin: 0px !important;
					width: 100% !important;
					padding: 4px !important;
				}
				.employee_details_next_button {
					width: 100% !important;
					min-height: 48px;
					height: 48px;
				}
			}
		}
	}

	// Individual Employee Order Card CSS
	.individual_employee_order_card_container {
		padding: 65px 4px 4px 4px;
		.individual_employee_order_card_title {
			padding: 8px 0px 8px 10px;
			span {
				font-size: 20px;
				line-height: 24px;
			}
		}
		.individual_employee_order_card_description {
			padding: 4px 28px 10px 10px;
			span {
				font-size: 16px;
				line-height: 18px;
				color: $greyColor6;
			}
		}
		.order_card_or_bank_account_button_section {
			width: 100%;
			padding: 4px 8px 10px 8px;
			.redirect_to_bank_account_button {
				padding-right: 8px;
				button {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 20px;
					text-align: center;
					color: #484b52;
				}
			}
			.order_card_button {
				padding-left: 8px;
				button {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 20px;
					text-align: center;
					color: #f7f8fa;
				}
			}
		}
	}

	// Select Payroll Channel Page
	.select_payroll_channel_container {
		padding: 65px 0px 0px 0px;
		.select_payroll_channel_title {
			padding: 4px 16px;
			span {
				font-size: 20px;
				line-height: 24px;
			}
		}
		.select_payroll_channel_description {
			padding: 4px 16px;
			span {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: $greyColor7;
			}
		}
		.select_payroll_channel_card_section {
			padding: 8px;
			width: 100%;
			.select_payroll_channel_card_column {
				padding: 8px 8px 8px 8px;
				.select_payroll_channel_select_card {
					background: $greyColor1;
					box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.5);
					border-radius: 24px;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 78px;
					min-height: 78px;
					.ant-card-body {
						width: 100%;
						.select_payroll_channel_mobile_row {
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
						}
					}
				}
				.select_payroll_channel_unselect_card {
					background: $greyColor1;
					box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
					border-radius: 24px;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 78px;
					min-height: 78px;
					.ant-card-body {
						width: 100%;
						.select_payroll_channel_mobile_row {
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
						}
					}
				}
			}
		}
		.select_payroll_channel_button_section {
			padding: 8px 10px 8px 10px;
			button {
				font-family: 'Montserrat-Medium';
				font-size: 18px;
				font-weight: 500;
				line-height: 20px;
			}
		}
	}

	//Individual Employement ID:
	.individual_employee_employment_id_container {
		padding: 65px 12px 0px 12px;
		.individual_employee_employment_id_title {
			padding: 0px 0px 8px 0px;
			span {
				font-size: 20px;
				line-height: 24px;
			}
		}
		.individual_employee_employment_id_description {
			padding: 0px 0px 0px 0px;
			span {
				display: inline-grid;
				font-size: 16px;
				line-height: 18px;
				span {
					padding: 8px 0px;
				}
			}
		}
		.individual_employee_employment_id_form_section {
			padding: 8px 0px 0px 0px;
			.ant-form {
				.ant-form-item {
					padding: 8px 0px;
				}
			}
		}
	}

	//WPS_PERSON_ID Page:
	.wps_person_id_container {
		padding: 65px 16px 0px 16px;
		.wps_person_id_title {
			span {
				font-size: 20px;
				line-height: 24px;
			}
		}
		.wps_person_id_description {
			padding: 8px 0px 0px 0px;
			span {
				font-size: 14px;
				line-height: 16px;
			}
		}
		.wps_person_id_form_image_row {
			width: 100%;
			display: flex;
			flex-direction: column-reverse;
			.wps_person_id_form_section {
				padding: 4px 0px 0px 0px;
				.ant-form {
					.ant-form-item {
						padding: 4px 0px;
					}
				}
			}
			.wps_person_id_labour_card_image {
				display: flex;
				justify-content: center;
				padding: 8px;
				img {
					width: 200px;
					height: 120px;
				}
			}
		}
		.wps_person_id_link_button {
			display: flex;
			justify-content: center;
			padding: 6px 0px 0px 0px;
			button {
				text-align: center;
			}
		}
	}

	//Add Establishment ID to Employee Container:
	.add_establishment_id_to_employee_container {
		padding: 65px 12px 0px 12px;
		.add_establishment_id_to_employee_title {
			padding: 0px 0px 8px 0px;
			span {
				font-size: 20px;
				line-height: 24px;
			}
		}
		.add_establishment_id_to_employee_description {
			padding: 8px 0px;
			span {
				font-size: 16px;
				line-height: 18px;
			}
		}
		.add_establishment_id_to_employee_form_section {
			padding: 8px 0px 0px 0px;
			.ant-form {
				.ant-form-item {
					padding: 8px 0px;
				}
			}
		}
	}

	//Add Labour Card Number Page:
	.add_labour_card_number_container {
		padding: 65px 16px 0px 16px;
		.add_labour_card_number_title {
			span {
				font-size: 20px;
				line-height: 24px;
			}
		}
		.add_labour_card_number_description {
			padding: 8px 0px 0px 0px;
			span {
				font-size: 14px;
				line-height: 16px;
			}
		}
		.add_labour_card_number_form_image_row {
			width: 100%;
			display: flex;
			flex-direction: column-reverse;
			.add_labour_card_number_form_section {
				padding: 4px 0px 0px 0px;
				.ant-form {
					.ant-form-item {
						padding: 4px 0px;
					}
				}
			}
			.add_labour_card_number_card_image {
				display: flex;
				justify-content: center;
				padding: 8px;
				img {
					width: 200px;
					height: 120px;
				}
			}
		}
	}

	//Add EmiratesID Number Page:
	.add_emirates_id_number_container {
		padding: 65px 16px 0px 16px;
		.add_emirates_id_number_title {
			span {
				font-size: 20px;
				line-height: 24px;
			}
		}
		.add_emirates_id_number_description {
			padding: 8px 0px 0px 0px;
			span {
				font-size: 14px;
				line-height: 16px;
			}
		}
		.add_emirates_id_number_form_image_row {
			width: 100%;
			display: flex;
			flex-direction: column-reverse;
			.add_emirates_id_number_form_section {
				padding: 4px 0px 0px 0px;
				.ant-form {
					.ant-form-item {
						padding: 4px 0px;
					}
				}
			}
			.add_emirates_id_card_image {
				display: flex;
				justify-content: center;
				padding: 8px;
				img {
					width: 200px;
					height: 120px;
				}
			}
		}
		.add_emirates_id_number_link_button {
			display: flex;
			justify-content: center;
			padding: 6px 0px 0px 0px;
			.add_temporary_emirates_id_number_card {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				.add_temporary_emirates_id_number_tip_icon {
					display: flex;
					justify-content: center;
					img {
						width: 32px;
						height: 32px;
					}
				}
				.add_temporary_emirates_id_number_hint {
					display: flex;
					span {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 12px;
						line-height: 16px;
						color: $greyColor6;
						text-align: center;
					}
				}
			}
		}
	}

	//Add Passport Number Page:
	.add_passport_number_container {
		padding: 65px 12px 0px 12px;
		.add_passport_number_title {
			padding: 0px 0px 6px 0px;
			span {
				font-size: 20px;
				line-height: 24px;
			}
		}
		.add_passport_number_form_section {
			padding: 4px 0px 0px 0px;
			.ant-form {
				.ant-form-item {
					padding: 6px 0px;
				}
			}
		}
	}

	//Add IBAN Number Page:
	.add_employee_iban_number_container {
		padding: 65px 12px 0px 12px;
		.add_employee_iban_number_title {
			padding: 0px 0px 6px 0px;
			span {
				font-size: 20px;
				line-height: 24px;
			}
		}
		.add_employee_iban_number_description {
			padding: 8px 0px 0px 0px;
			span {
				font-size: 14px;
				line-height: 16px;
			}
		}
		.add_employee_iban_number_form_section {
			padding: 4px 0px 0px 0px;
			.ant-form {
				.ant-form-item {
					padding: 6px 0px;
				}
			}
		}
	}

	//Add Employee Bank Name Page:
	.add_employee_bank_name_container {
		padding: 65px 12px 0px 12px;
		.add_employee_bank_name_title {
			padding: 0px 0px 6px 0px;
			span {
				font-size: 20px;
				line-height: 24px;
			}
		}
		.add_employee_bank_name_description {
			padding: 8px 0px 0px 0px;
			span {
				font-size: 14px;
				line-height: 16px;
			}
		}
		.add_employee_bank_name_form_section {
			padding: 4px 0px 0px 0px;
			.ant-form {
				.ant-form-item {
					padding: 6px 0px;
				}
			}
		}
	}

	//Individual Employee Next steps Page:
	.individual_employee_next_steps_container {
		display: flex;
		flex-direction: row;
		padding: 64px 2% 1% 2%;
		.individual_employee_next_steps_header {
			padding: 1% 2% 0% 2%;
			span {
				font-size: 20px;
				line-height: 24px;
			}
		}
		.individual_employee_next_steps_message {
			padding: 3% 2% 2% 2%;
			span {
				font-size: 16px;
				line-height: 18px;
				color: $greyColor6;
			}
		}
		.individual_employee_next_steps_status {
			padding: 1% 1% 1% 1%;
			.individual_employee_next_steps_status_container {
				display: flex;
				align-items: center;
				padding: 1% 1% 0% 1%;
				.individual_employee_next_steps_status_icon {
					display: contents;
					img {
						background: $greyColor1;
						box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
						border-radius: 16px;
					}
				}
				.individual_employee_next_steps_status_content {
					padding: 1% 1% 0% 4%;
					span {
						font-size: 14px;
						line-height: 16px;
						color: $greyColor7;
						a {
							font-size: 14px;
							line-height: 16px;
							color: $primaryColor4;
							text-transform: lowercase;
						}
					}
				}
			}
		}
		.individual_employee_next_steps_button {
			display: flex;
			justify-content: center;
			padding: 4% 1% 0% 1%;
			button {
				font-size: 14px;
				line-height: 16px;
				color: $primaryColor5;
				padding: 1%;
				width: 238px;
				min-width: 238px;
			}
		}
	}
}
