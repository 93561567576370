.payroll-setting-dropdown {
	.css-1rpz77w {
		position: absolute;
		top: 8px;
		left: 90.5%;
		border-color: #9a9ea6 !important;
		border-bottom-color: transparent !important;
	}
}
.payroll-setting-sub-dropdown {
	.css-1rpz77w {
		position: absolute;
		top: 8px;
		left: 79%;
		border-color: #9a9ea6;
		border-bottom-color: transparent !important;
	}
}

.payroll-setting-dropdown-list {
	border-radius: 16px !important;
	.dropdown-settings {
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		text-align: center;
		color: #00344e;
		a {
			text-decoration: underline;
		}
	}
	.rc-virtual-list-holder {
		overflow-y: auto !important;
		// max-height: 180px !important;
	}
	.rc-virtual-list-scrollbar {
		overflow: scroll !important;
	}
	.ant-select-item-option {
		display: flex;
		align-items: center;
		padding: 18px 0 !important;
		margin-left: 24px !important;
		margin-right: 24px !important;
		&:last-child {
			border: none !important;
		}
	}
	.ant-select-item-option-content {
		width: 80%;
	}
	.ant-select-item-option .ant-select-item-option-content {
		width: 20px !important;
	}
	.ant-select-item-option::after {
		display: inline-block;
		width: 26px;
		height: 26px;
		margin-right: 5px;
		content: '';
		background: url('../../../assets/icons/not_focus/EmptyBox.svg') no-repeat 0 0;
		background-size: 100%;
	}
	.ant-select-item-option-selected::before {
		display: none !important;
	}
	.ant-select-item-option-selected::after {
		display: inline-block;
		width: 24px;
		height: 24px;
		margin-right: 5px;
		content: '';
		background: url('../../../assets/icons/focus_state/Check.svg') no-repeat 0 0;
		background-size: 100%;
	}
}
