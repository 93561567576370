@import '../../../constants/variables';

.mfa-wrapper {
	display: flex;
	width: 100%;
	height: 100vh;

	.mfa-main-container {
		width: 42%;
		padding: 0% 1% 0% 5%;
		.edenred-logo {
			height: 35%;
			padding-top: 5%;
		}
		.setup-mfa-container {
			display: grid;
			width: 70%;
			gap: 32px;
			.setup-mfa-text {
				display: grid;
				gap: 18px;
				.setup-mfa-description {
					.setup-mfa-description-text {
						color: #737780;
					}
				}
			}
			.setup-mfa-number-field-wrapper {
				display: grid;
				gap: 32px;
				.setup-mfa-number-field {
					.error-msg {
						color: #f72717;
						width: 100%;
						display: flex;
						justify-content: end;
						margin-top: 10px;
					}
				}
				.setup-mfa-otp-button-container {
					display: flex;
					justify-content: end;
					.setup-mfa-otp-button {
						max-width: 103px;
					}
				}
			}
		}
		.otp-container {
			height: 40%;
			width: 70%;
			gap: 32px;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			.otp-header {
				display: grid;
				gap: 18px;
				.otp-title {
					display: flex;
					flex-direction: column;
				}
				// otp description for MFA modes setup and required
				.otp-description-setup {
					display: grid;
					grid-template-columns: 1.9fr 1fr;
				}
				.otp-description-required {
					display: flex;
					justify-content: space-between;
				}
				.otp-description {
					align-items: center;
					.otp-msg {
						color: #737780;
					}
					.otp-info-icon {
						img {
							height: 30px;
							width: 30px;
						}
						.tooltipup {
							width: 193px;
							height: 44px;
							left: -80%;
							padding: 8px;
							.tooltip-text {
								font-size: 12px;
								line-height: 14px;
							}
						}
					}
					.change_number_text {
						cursor: pointer;
						text-decoration: underline;
					}
				}
			}
			.otp-input-container {
				display: grid;
				gap: 18px;
				.otp-input-wrapper {
					.otp-input {
						display: flex;
						justify-content: space-between;
						.input-field {
							height: 40px;
							width: 40px;
							font-size: 24px;
							line-height: 28px;
							padding: 10px;
							text-align: center;
							border-radius: 5px;
							font-weight: bold;
							outline: none;
							transition: all 0.1s;
						}
						.input-field:focus {
							border: 1px solid #71b0d0;
						}
					}
					.error-msg {
						color: #f72717;
						width: 100%;
						display: flex;
						justify-content: end;
						margin-top: 10px;
					}
				}
				.remember-device-checkbox {
					display: flex;
					align-items: center;
				}
			}
			.otp-validation {
				display: flex;
				align-items: center;
				justify-content: space-between;
				cursor: pointer;
				.resend-otp-text {
					text-decoration: underline;
					color: #0e5274;
				}
				.verify-btn {
					span {
						overflow: unset;
					}
					.verify-button {
						background-color: #eff1f5;
						color: #dfe1e6;
						font-family: inherit;
						font-style: normal;
						border: none;
					}
				}
			}
		}
	}
	.mfa-image-container {
		width: 58%;
		height: inherit;
	}
}
.input-field-value {
	border: 1px solid #1890ff;
}
.error-input {
	border: 1px solid #d40a1e;
}
.border {
	border: 1px solid #dfe1e6;
}
.try-again-container {
	.try-again-msg {
		color: #737780;
	}
	height: 40%;
	width: 70%;
	display: flex;
	flex-direction: column;
	gap: 32px;
	.try-again-text {
		display: grid;
		gap: 18px;
	}
	.try-again-btn-wrapper {
		.try-again-btn {
			font-family: inherit;
		}
		width: 30%;
		min-width: 98px;
	}
}

@media only screen and (min-width: 769px) and (max-width: 1200px) { 
	.mfa-wrapper {
		.mfa-main-container {
			.otp-container {
				width: 100%;
			}
			.setup-mfa-container {
				width: 100%;
			}
			.try-again-container {
				width: 100%
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.mfa-image-container {
		display: none;
	}

	.mfa-wrapper {
		justify-content: center;
		.mfa-main-container {
			width: 90%;
			padding: 0% 5% 0% 5%;

			.otp-container {
				width: 100%;
			}
			.setup-mfa-container {
				width: 100%;
			}
			.try-again-container {
				width: 100%
			}
		}
	}
}

@media only screen and (max-width: 550px) { 
	.mfa-wrapper {
		.mfa-main-container {
			width: 100%;
		}
	}
}