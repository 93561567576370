.add_emp_document_main {
	padding: 0px 40px 40px 40px;
	align-self: flex-start;
	width: 100%;
	.add_emp_document_card {
		background: #ffffff;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 32px;
		.add_emp_personal_info {
			background: #f9fafb;
			border-radius: 8px;
			padding: 16px 24px;
			margin-bottom: 24px;
			.add_emp_personal_header {
				display: flex;
				justify-content: space-between;
				.add_emp_name {
					display: flex;
					flex-direction: column;
				}
			}
			.add_emp_personal_detail {
				margin-top: 16px;
				display: flex;
				width: 100%;
				.emp_personal_text {
					display: flex;
					flex-direction: column;
					width: 25%;
					padding-right: 24px;
				}
			}
		}
		.document_error {
			margin-bottom: 24px;
			.hint_card {
				padding: 16px;
				margin-bottom: 8px;
			}
		}
		.add_emp_bank_info {
			margin-bottom: 16px;
			.add_emp_bank_details {
				margin-top: 16px;
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				row-gap: 16px;
				.add_emp_bank_filed {
					width: 50%;
					padding-right: 32px;
				}
			}
		}
		.add_emp_document_info {
			.add_emp_document_details {
				margin-top: 16px;
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				row-gap: 16px;
				.add_emp_doc_filed {
					width: 50%;
					padding-right: 32px;
				}
			}
		}
		.add_emp_doc_bottom {
			margin-top: 8px;
		}
	}
}
