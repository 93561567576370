@import '../../../constants/variables';
.progessbar-inner {
	margin-top: 30px;
	.title {
		font-family: Montserrat-bold;
		font-size: 12px;
		font-weight: 700;
		line-height: 14px;
		letter-spacing: 0em;
		text-align: left;
		margin-top: 8px;
		margin-bottom: 4px;
		margin-left: 9px;
		color: $blue-500;
	}
	.disable-title {
		font-family: Montserrat-bold;
		font-size: 12px;
		font-weight: 700;
		line-height: 14px;
		letter-spacing: 0em;
		text-align: left;
		margin-top: 8px;
		margin-bottom: 4px;
		margin-left: 9px;
		color: $grayscale-300;
	}
	.desc,
	.disable {
		font-family: Montserrat-light;
		font-size: 10px;
		font-weight: 300;
		line-height: 12px;
		letter-spacing: 0em;
		text-align: left;
		margin-left: 9px;
	}
	.desc {
		color: $blue-400;
	}
	.disable {
		color: $grayscale-300;
	}

	.progessbar {
		.border {
			width: 222px;
			height: 8px;
			border-radius: 8px;
			margin-left: 9px;
		}
		.success {
			background: $feedbox-success;
		}
		.disable {
			background-color: $grayscale-300;
		}
	}
}
