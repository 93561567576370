.image-alignment {
	text-align: center;
	padding: 3%;
}
.image-text {
	font-family: 'Montserrat-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 20px;
	color: #00344e;
}
