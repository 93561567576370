@mixin bank {
	height: 100%;
	width: 100%;
	display: flex;
}
@mixin bordered($fontfamily, $font, $weight, $lineheight, $letterspacing, $textalign, $size) {
	font-family: $fontfamily;
	font-style: $font;
	font-weight: $weight;
	line-height: $lineheight;
	letter-spacing: $letterspacing;
	text-align: $textalign;
	font-size: $size;
}
.cardholder {
	@include bank;
	.main-box {
		margin: auto;
		max-width: 1200px;
		@include bank;
		align-self: center;
		.cardholder-card {
			align-self: center;
			.card-name {
				@include bordered('Montserrat-bold', normal, '700', '28px', '0em', 'left', 24px);
				margin-bottom: 24px;
			}
			.card-title {
				@include bordered('Montserrat-regular', normal, '300', '16px', '0em', 'left', 14px);
				margin-top: 24px;
				color: #484b52;
			}
			.card-description {
				@include bordered('Montserrat-regular', normal, '300', '16px', '0em', 'left', 14px);

				margin-top: 15px;
				color: #484b52;
			}
			.card-two-button {
				display: flex;
				gap: 16px;
				margin-top: 32px;

				.card-button {
					border: none;
					background-color: white;
					border: 2px solid #eff1f5;
					color: #484b52;
					border-radius: 28px;
					width: -webkit-fill-available;
					height: 48px;
					cursor: pointer;
				}
				.card-button-primary {
					box-shadow: none;
				}
			}
		}
	}
}
