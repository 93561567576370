@import '../../../../constants/variables';

@media only screen and (max-width: 600px) {
	.Manage-employee-container {
		display: none !important;
	}
	.delete_employees_in_bulk_container {
		display: none;
	}
	.manage-employee-mobile-view {
		display: flex !important;
		width: 100%;
		padding: 65px 11px 120px 13px;
		.manage-employee-mobile-header {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: $primaryColor5;
			}
		}
		.manage-employee-mobile-filtersearch {
			padding-top: 12px;
			.manage-employee-mobile-filtersearchcol {
				.mobile-filter {
					.manage-employee-mobile-filter {
						cursor: pointer;
						width: 85%;
						height: 40px;
						border: 2px solid $greyColor3;
						box-sizing: border-box;
						border-radius: 8px;
						display: flex;
						align-items: center;
						justify-content: space-evenly;
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 16px;
						color: $greyColor7;
						img {
							width: 25px;
							height: 25px;
						}
					}
				}
				.emp-search {
					margin-left: 5px;
					border: 2px solid $greyColor3;
					box-sizing: border-box;
					border-radius: 8px;
					height: 40px;
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $greyColor6;
					img {
						width: 25px;
						height: 25px;
					}
				}
			}
		}
		.manage-employee-mobile-tab {
			padding-top: 12px;
			.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: $secondaryColor5;
			}
			.ant-tabs-ink-bar {
				position: absolute;
				background: $secondaryColor3;
			}
			.ant-tabs-tab {
				width: 185px;
				cursor: pointer;
				display: flex;
				justify-content: center;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				text-align: center;
				color: $greyColor5;
				border-bottom: 2px solid $greyColor4;
				padding: 5px 0px;
				margin: 0px 6px 0px 0px;
			}
			.ant-tabs-tab:hover {
				color: $greyColor5;
			}
			.ant-tabs-tab-btn:focus,
			.ant-tabs-tab-remove:focus,
			.ant-tabs-tab-btn:active,
			.ant-tabs-tab-remove:active {
				color: $secondaryColor5;
			}
			.ant-tabs-top > .ant-tabs-nav::before,
			.ant-tabs-bottom > .ant-tabs-nav::before,
			.ant-tabs-top > div > .ant-tabs-nav::before,
			.ant-tabs-bottom > div > .ant-tabs-nav::before {
				position: absolute;
				right: 0;
				left: 0;
				border-bottom: none;
				content: '';
			}
			.ant-tabs-nav .ant-tabs-nav-more {
				display: none;
			}

			.select {
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 10px;
					line-height: 14px;
					color: $primaryColor5;
				}
			}
			.card_content {
				margin: 10px 0px;
				min-height: 84px;
				height: 84px;
			}
		}
		.manage-employee-mobile-add {
			display: flex;
			justify-content: center;
			margin-top: 12px;
			button {
				box-shadow: none;
				width: 134px;
				height: 32px;
				min-width: 134px;
				min-height: 34px;
				font-family: Montserrat-Light;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: $greyColor2;
				padding: 0px 0px;
			}
		}
		.manage-employee-mobile-download {
			button {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				text-align: center;
				color: $primaryColor4;
				text-decoration: underline;
				text-underline-position: under;
				margin-top: 10px;
				padding: 15px;
				min-height: auto;
			}
		}
		.delete_show {
			// position: absolute;
			width: 100%;
			// height: 104px;
			bottom: 0px;
			background: $greyColor1;
			// box-shadow: 0px -10px 20px rgba(174, 182, 183, 0.25);
			z-index: 2;
			left: 0;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;
			padding: 20px;
			.delete_employees_button {
				button {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 16px;
					line-height: 18px;
					border-radius: 90px;
					padding: 12px;
				}
			}
		}
	}
	.employe-dropdown {
		display: none !important;
	}

	.modal_card_emploee {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		height: 100%;
		width: 100%;
		box-shadow: 0px -10px 20px rgba(174, 182, 183, 0.25);
		.Modal_Edenred .user_login {
			padding: 0% 10% 5% 10%;
			font-family: Montserrat-Medium;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 18px;
			color: $greyColor6;
		}
		.Remove_user {
			padding-top: 8px;
			.user {
				padding-top: 5%;
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: $primaryColor5;
			}
			.user_login {
				padding-top: 0px;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				color: $greyColor6;
			}
		}
		.Modal_Edenred .user_login_mobile {
			margin-top: 0px;
		}
		.Modal_Edenred .button_mobile_role {
			display: flex !important;
			justify-content: space-evenly;
			width: 100%;
			padding: 0px 15px 10% 8px;
		}
		.Modal_Edenred .horizontal_mobile {
			display: flex !important;
			justify-content: center;
			align-items: center;
			margin-top: 15px;
		}
		.Modal_Edenred .hori {
			width: 13%;
			border: 2px solid $greyColor4;
			border-radius: 12px;
			background: $greyColor4;
		}
	}
	.ReactModal__Overlay.ReactModal__Overlay--after-open {
		z-index: 2;
		position: absolute;
		.Modal_Edenred {
			width: 100%;
			box-shadow: 0px -10px 20px rgba(174, 182, 183, 0.25);
		}
	}
	// .manage-employee-filter
	.manage-employee-filter-container {
		display: flex !important;
		padding: 65px 15px 65px 15px;
		.Filter {
			font-size: 20px;
		}
		.scroll-filter {
			.wps_nonwps {
				padding: 1% 1%;
				button {
					padding: 0% 15px;
					min-height: 32px;
					display: flex;
					justify-content: space-evenly;
					font-size: 14px;
					img {
						width: 25px;
						height: 25px;
					}
				}
			}
		}
		.appy_buttom {
			padding-left: 27%;
			padding-top: 2%;
			padding-bottom: 2%;
			button {
				width: 55%;
				font-size: 16px;
				padding: 0px;
				font-weight: bold;
				border: none;
			}
			.appy_button_apply {
				button {
					width: 55%;
					font-size: 16px;
					padding: 0px;
					font-weight: bold;
					background: $greyColor2;
					color: $greyColor4;
					border: none;
				}
			}
		}
	}

	//---- Delete Employees In Bulk submit page----
	.delete-employee-in-bulk-submit {
		width: 100%;
		height: 100%;
		.delete_multiple_employees_submit_container {
			padding: 65px 0px 0px 0px;
			width: 100%;
			.delete_multiple_employees_submit_button_row {
				width: 100%;
				display: flex;
				align-items: center;
				padding: 8px 4px 6px 12px;
				.delete_multiple_employees_submit_title {
					span {
						font-size: 20px;
						line-height: 24px;
					}
				}
				.delete_multiple_employees_submit_button {
					button {
						font-size: 12px;
						line-height: 14px;
					}
				}
			}
			.delete_multiple_employees_submit_hint {
				padding: 4px 4px 4px 12px;
				span {
					color: $greyColor6;
				}
			}
			.delete_multiple_employees_submit_list_section {
				padding: 4px 4px 4px 12px;
				.delete_multiple_employees_list_row {
					.delete_multiple_employees_name_employee_id_title {
						span {
							font-size: 14px;
							line-height: 16px;
						}
					}
					.delete_multiple_employees_name_employee_id_column {
						span {
							font-size: 16px;
							line-height: 18px;
						}
					}
				}
				.ant-divider-horizontal {
					margin: 12px 0px;
				}
			}
		}
	}
	.delete_multiple_employees_submit_modal {
		width: 100%;
		height: 100%;
		button {
			font-weight: bold;
		}

		.Modal_Edenred {
			width: 100%;
			position: absolute;
			bottom: 0;
			z-index: 10;
		}
		.Modal_Edenred .Remove_user {
			padding: 0% 5%;
			color: $primaryColor5;
		}
		.Modal_Edenred .user_login_mobile {
			padding: 1% 0% 6% 5%;
			margin: 0%;
			color: $greyColor6;
		}
	}

	//---- Update Employees Emirates ID Page ----
	.update_employees_emirates_id_container {
		display: none !important;
	}
}

.display_none_max_width_600px {
	@media only screen and (max-width: 600px) {
		display: none;
	}
}
