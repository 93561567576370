@import '../../../constants/variables';

.date-text-family {
	font-family: $font-family-medium;
	font-style: $font-style-normal;
	font-weight: $font-weight-500;
}
.date-text-family-light {
	font-family: $font-family-light;
	font-style: $font-style-normal;
	font-weight: $font-weight-300;
}

.date-text-size-10 {
	font-size: $font-size-10px;
	line-height: $line-height-12px;
}

.date-text-size-12 {
	font-size: $font-size-12px;
	line-height: $line-height-14px;
}

.date_single_picker {
	.date_label {
		display: flex;
		@extend .date-text-family;
		@extend .date-text-size-10;
		color: $grayscale-900;
		margin-bottom: 0px 0px 8px 0px;
	}

	.date_field_error_message {
		@extend .date-text-family-light;
		@extend .date-text-size-10;
		color: $red-500;
		margin: 8px 0px 0px 0px;
	}

	.disabled_date {
		opacity: 0.3;
		cursor: not-allowed;
	}

	.active_date_field {
		border: 1px solid $grayscale-400;
		cursor: pointer;
	}

	.non_empty_date_field {
		border: 1px solid $grayscale-500;
		cursor: pointer;
	}
	.default_date_field {
		border: 1px solid $grayscale-400;
		cursor: pointer;
		input {
			color: $grayscale-500 !important ;
		}
		&:focus-within {
			border: 1px solid $blue-100;
		}
	}

	.empty_date_field {
		border: 1px solid $grayscale-400;
		cursor: pointer;
		&:focus-within {
			border: 1px solid $blue-100;
		}
	}

	.date_error_field {
		border: 1px solid $feedbackError;
		cursor: pointer;
	}

	.date_picker_main {
		display: flex;
		align-items: center;
		background: $neutral-white;
		border-radius: 8px;
		padding: 8px 12px;
		height: 32px;
		position: relative;

		&:focus-within {
			border: 1px solid $blue-100;
		}
		.date_picker_calender_icon {
			margin-right: 8px;
		}
		input {
			border: none;
			outline: none;
			width: 100%;
			@extend .date-text-family;
			@extend.date-text-size-12;
			color: $grayscale-900;

			&::placeholder {
				@extend .date-text-family;
				@extend.date-text-size-12;
				color: $grayscale-500;
			}

			&:disabled {
				background: transparent;
			}
		}

		.arrow-inner {
			width: 16px;
			height: 16px;
		}
	}
}

.range_date_picker_container {
	width: 100%;

	.date_label {
		display: flex;
		@extend .date-text-family;
		@extend .date-text-size-10;
		color: $grayscale-900;
		margin-bottom: 0px 0px 8px 0px;
	}

	.date_range_picker {
		.date_field_error_message {
			@extend .date-text-family-light;
			@extend .date-text-size-10;
			color: $red-500;
			margin: 8px 0px 0px 0px;
		}

		.disabled_date {
			opacity: 0.3;
			cursor: not-allowed;
		}

		.active_date_field {
			border: 1px solid $grayscale-400;
			cursor: pointer;
		}

		.non_empty_date_field {
			border: 1px solid $grayscale-500;
			cursor: pointer;
		}

		.empty_date_field {
			border: 1px solid $grayscale-400;
			cursor: pointer;
		}

		.date_error_field {
			border: 1px solid $feedbackError;
			cursor: pointer;
		}

		.range_date_input {
			position: absolute;
			color: transparent;
			background: transparent;
			border: none;
			outline: none;
			width: 100%;

			&:focus-within {
				background: transparent;
			}
		}

		.date_picker_main {
			display: flex;
			align-items: center;
			background: $neutral-white;
			border-radius: 8px;
			padding: 8px 12px;
			height: 32px;

			.date_range_picker_content {
				width: 100%;
				display: flex;
				align-items: center;
				margin-left: 8px;

				.ranger_picker_divider {
					border: 1px solid #dfe1e6;
					height: 14px;
					margin-right: 16px;
				}

				.range_from_container {
					flex-grow: 1;
				}

				.range_to_container {
					flex-grow: 1;
				}
			}

			.arrow-inner {
				width: 16px;
				height: 16px;
			}
		}
	}
}
