@import '../../constants/_variables.scss';
.home_main_page {
	width: 100%;
	align-self: flex-start;
	padding: 0px 40px 40px 40px;
	.home_main_header {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 40px 0px 24px 0px;
		position: sticky;
		top: 0%;
		z-index: 1;
		background: $grayscale-200;
		box-shadow: 0px 8px 24px -12px #f7f8fa;
		.home_header_button {
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}
	.home-view-row {
		display: flex;
		align-items: center;
		.home-view-id {
			padding: 5px 5px 0 0;
			font-family: 'Montserrat-Regular';
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			color: $greyColor7;
		}
		.home-view-card {
			margin-top: 5px;
		}
	}
	.home-view-username {
		padding-top: 15px;
		span {
			font-family: Montserrat-bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor5;
		}
	}
	.home_card {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 32px;
		background: #ffffff;
		border-radius: 8px;
		margin-bottom: 25px;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		.home_leaves_to_approve {
			flex-grow: 1;
			.leaves_to_approve_card {
				margin-top: 24px;
				display: flex;
				align-items: center;
				gap: 8px;
				.leaves_to_approve_count {
					background: #f7f8fa;
					border-radius: 8px;
					padding: 8px 13px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: $grayscale-200;
				}
				.leaves_to_approve_text {
					display: flex;
					flex-direction: column;
					a {
						color: $blue-400;
						text-decoration: underline;
					}
				}
			}
		}
		.home_card_main {
			width: 98%;
		}
		.home_card_detail {
			background: $greyColor2;
			padding: 20px 20px 20px 25px;
			span {
				font-style: normal;
				font-weight: 700;
				font-size: 16px;
				line-height: 18px;
				color: $primaryColor5;
			}
			p {
				font-family: 'Montserrat-Regular';
				font-style: normal;
				font-weight: 300;
				font-size: 18px;
				line-height: 24px;
				color: $greyColor7;
				margin: 0;
			}
		}
	}
	.home-Request-Leave {
		margin-top: -15px;
		button {
			min-height: 32px;
			height: 32px;
			width: 171px;
			min-width: 171px;
			font-family: Montserrat-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
			text-align: center;
			color: $greyColor2;
			padding: 0px 0px;
		}
	}
	.button_position {
		margin-top: -37px;
	}
	// }
}
