@import '../../../../constants/_variables.scss';
.gratuity-calculate-presentational {
	padding: 0px 40px 40px 40px;
	background: #f7f8fa;
	width: 100%;
	align-self: flex-start;
	display: block;
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: sticky;
		top: 0;
		z-index: 1;
		background: #f7f8fa;
		padding: 42px 0px 24px 0px;
		p {
			font-family: Montserrat-bold;
			font-size: 24px;
			font-weight: 700;
			line-height: 28px;
			letter-spacing: 0em;
			text-align: left;
			color: #00344e;
			margin-bottom: 8px;
		}
		.secondary-btn {
			font-family: 'Montserrat-Medium';
		}
		.buttonnew {
			width: 118px;
			img {
				margin-right: 0px;
			}
		}
	}
	.questions {
		padding: 32px 32px 32px 32px;
		background: #ffffff;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		.gratuity {
			font-family: Montserrat-medium;
			font-size: 18px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: 0em;
			text-align: left;
			color: #00344e;
			margin-bottom: 16px;
		}
		.terminated {
			//styleName: Paragraph/Light/Large;
			font-family: Montserrat-light;
			font-size: 14px;
			font-weight: 300;
			line-height: 16px;
			letter-spacing: 0em;
			text-align: left;
			color: #737780;
			margin-bottom: 16px;
		}
		.contract {
			//styleName: Paragraph/Light/Large;
			font-family: Montserrat-light;
			font-size: 14px;
			font-weight: 300;
			line-height: 16px;
			letter-spacing: 0em;
			text-align: left;
			color: #737780;
			margin-bottom: 16px;
			margin-top: 16px;
		}
		.table {
			border: 1px solid #dfe1e6;
			border-radius: 8px;
			.outer-row {
				border-bottom: 1px solid #dfe1e6;
				padding: 16px 24px 16px 24px;
				display: flex;
				.outer-one {
					.long {
						font-family: Montserrat-medium;
						font-size: 14px;
						font-weight: 500;
						line-height: 16px;
						letter-spacing: 0em;
						text-align: left;
						color: #2b769c;
						margin-bottom: 0px;
					}
					.year {
						font-family: Montserrat-light;
						font-size: 14px;
						font-weight: 300;
						line-height: 16px;
						letter-spacing: 0em;
						text-align: left;
						color: #181919;
						margin-bottom: 0px;
					}
				}
				.outer-two {
					.year {
						font-family: Montserrat-light;
						font-size: 14px;
						font-weight: 300;
						line-height: 16px;
						letter-spacing: 0em;
						text-align: left;
						color: #181919;
						margin-bottom: 0px;
					}
				}
			}
		}
	}
}
