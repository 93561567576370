@import '../../../constants/variables';

.fadeinOut {
	visibility: visible;
	opacity: 1;
	transition: opacity 2s linear;
}

.display-none {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s 2s, opacity 2s linear;
}

.toat-outer {
	display: flex;
	align-items: center;
	justify-content: center;
	.toast {
		padding: 16px 32px;
		font-family: 'Montserrat-light';
		font-size: 14px;
		font-weight: 300;
		line-height: 16px;
		color: $grayscale-700;
		letter-spacing: 0em;
		display: flex;
		position: absolute;
		z-index: 20;
		gap: 8px;
		justify-content: center;
		box-shadow: 0px 8px 24px -12px $plaincolor;
		border-radius: 4px;
		text-align: center;
	}

	.top {
		top: 65px;
	    z-index: 9999;
	}
	.bottom {
		bottom: 40px;
	}
}
