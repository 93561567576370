@import '../../../constants/_variables.scss';
// .main-dashboard-barchart {
// 	display: none !important;
// }
@media only screen and (max-width: 600px) {
	.main-dashboard-mobile {
		padding: 65px 10px 65px 15px;
		width: 100%;
		//Pending Alerts
		.mobile_view_pending_alerts {
			// padding: 8px 0px;
			padding: 8px 0px 20px 0px;
			.mobile_view_pending_alerts_card {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				padding: 8px;
				background-color: $feedBackYellow;
				border-radius: 10px;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				.mobile_view_pending_alert_img {
					img {
						border-radius: 5rem;
						background: $WarningYellow;
						box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
						height: 35px;
						width: 35px;
					}
				}
				.pending_alert_link_column {
					a {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						.mobile_view_pending_alert_msg {
							width: 100%;
							span {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 12px;
								line-height: 14px;
								color: $primaryColor5;
							}
						}
						.mobile_view_pending_alert_arrow {
							display: flex;
							align-items: center;
							justify-content: flex-end;
							span {
								font-family: Montserrat-Regular;
								font-style: normal;
								font-weight: 300;
								font-size: 10px;
								line-height: 12px;
								color: $greyColor7;
							}
							img {
								height: 25px;
								width: 25px;
							}
						}
					}
				}
			}
		}
		// first container
		.main-dashboard-logo {
			text-align: center;
			width: 100%;
			display: flex;
			justify-content: center;
			.main-dashboard-image {
				img {
					background: $imageBackground;
					border-radius: 56px;
				}
			}
			.main-dashboard-available-balance {
				padding: 5px 0px;
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					color: $primaryColor3;
				}
			}
			.main-dashboard-balance-amount {
				display: flex;
				flex-direction: row;
				align-items: baseline;
				justify-content: center;
				.amount {
					font-family: Montserrat-Bold;
					font-style: normal;
					font-weight: bold;
					font-size: 32px;
					line-height: 36px;
					color: $primaryColor5;
				}
				.point {
					text-align: bottom;
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 20px;
					line-height: 24px;
					color: $primaryColor5;
				}
				.aed {
					padding-left: 8px;
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 20px;
					line-height: 24px;
					color: $primaryColor5;
				}
			}
			.main-dashboard-view-transaction-history {
				display: flex;
				justify-content: center;
				padding: 18px 0px 24px 0px;
				button {
					height: 32px;
					min-height: 32px;
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: #00344e;
					padding: 0px 0px;
					width: 232px;
					min-width: 232px;
					border: 1px solid $greyColor3;
					img {
						width: 24px;
						height: 24px;
					}
				}
			}
		}
		// payroll history
		.main-dashboard-history-payroll-card {
			background: $greyColor1;
			box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
			border-radius: 16px;
			height: 272px;
			min-height: 272px;
			padding: 16px 16px 16px 16px;
			width: 100%;
			.main-dashboard-payrol-icon {
				height: 32px;
				width: 32px;
				min-width: 32px;
				min-height: 32px;
				border-radius: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #f8d4c0;
				img {
					width: 24px;
					height: 24px;
				}
			}
			.main-dashboard-amount {
				padding-left: 7px;
				padding-top: 5px;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					color: #00344e;
				}
			}
			.main-dashboard-carousel-container {
				width: 100%;
				padding-top: 9px;
				.main-dashboard-Carousel {
					padding: 12px 0px;
					width: 100% !important;
					display: flex !important;
					.card_status_color_column {
						display: flex;
						align-items: center;
						.background {
							box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
							border-radius: 16px;
							width: 4px;
							height: 40px;
						}
					}
					.main-dashboard-carosel-content {
						display: flex;
						flex-direction: column;
						.wps-month {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 15px;
							line-height: 16px;
							color: $primaryColor5;
						}
						.payroll-submit {
							padding-top: 8px;
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 11px;
							line-height: 12px;
							color: $greyColor7;
						}
					}
				}
			}
			.main-dashboard-view-all {
				display: flex;
				justify-content: center;
				align-items: center;
				button {
					width: 88px;
					min-width: 88px;
					height: 32px;
					min-height: 32px;
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $primaryColor5;
					padding: 0px 0px;
					border: 1px solid #eff1f5;
				}
			}
		}
		// cut off
		.main-dashboard-cut-off {
			width: 100%;
			margin: 16px 0px;
			padding: 16px 13px 16px 16px;
			background: $greyColor2;
			border-radius: 16px;
			height: 147px;
			min-height: 147px;
			.change-your-approval {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 18px;
				color: #00344e;
			}
			.change-content {
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				color: #484b52;
			}
			.cut-off-container {
				padding: 0px 3px 0px 0px;
				.wps-cut-off {
					.wps-label {
						span {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 10px;
							line-height: 14px;
							color: $primaryColor3;
						}
					}
					.wps-timing {
						padding-top: 2px;
						span {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							line-height: 14px;
							display: flex;
							align-items: flex-end;
							color: #00344e;
						}
					}
				}
			}
			.view-details {
				display: flex;
				justify-content: center;
				align-items: center;
				button {
					width: 117px;
					min-width: 117px;
					height: 32px;
					min-height: 32px;
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $primaryColor5;
					padding: 0px 0px;
					background: #f7f8fa;
					border: none;
				}
				.modify_button {
					width: auto;
					min-width: auto;
				}
			}
		}
		// payroll amount
		.main-dashboard-payroll-amount {
			height: 342px;
			min-height: 342px;
			width: 100%;
			box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
			border-radius: 16px;
			padding: 16px;
			.main-dashboard-payrol-icon {
				background: #cbf8c0;
				height: 32px;
				width: 32px;
				min-width: 32px;
				min-height: 32px;
				border-radius: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: 24px;
					height: 24px;
				}
			}
			.main-dashboard-amount {
				padding-left: 7px;
				padding-top: 5px;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					color: #00344e;
				}
			}
		}
		// card order history
		.main-dashboard-history-card-order {
			background: $greyColor1;
			box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
			border-radius: 16px;
			height: 272px;
			min-height: 272px;
			padding: 16px;
			width: 100%;
			margin: 16px 0px;
			.main-dashboard-payrol-icon {
				background: #f8d4c0;
				height: 32px;
				width: 32px;
				min-width: 32px;
				min-height: 32px;
				border-radius: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: 24px;
					height: 24px;
				}
			}
			.main-dashboard-amount {
				padding-left: 7px;
				padding-top: 5px;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					color: #00344e;
				}
			}
			.main-dashboard-carousel-container {
				width: 100%;
				padding-top: 9px;
				.main-dashboard-Carousel {
					padding: 8px 0px;
					width: 100% !important;
					display: flex !important;
					.card_status_color_column {
						display: flex;
						align-items: center;
						.background {
							box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
							border-radius: 16px;
							width: 4px;
							height: 40px;
						}
					}
					.main-dashboard-carosel-content {
						display: flex;
						flex-direction: column;
						.wps-month {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 16px;
							line-height: 18px;
							color: #00344e;
						}
						.payroll-submit {
							padding-top: 8px;
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 12px;
							line-height: 14px;
							color: #484b52;
						}
					}
				}
			}
			.main-dashboard-view-all {
				display: flex;
				justify-content: center;
				align-items: center;
				button {
					width: 88px;
					min-width: 88px;
					height: 32px;
					min-height: 32px;
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $primaryColor5;
					padding: 0px 0px;
					border: 1px solid #eff1f5;
				}
			}
		}
		// employee paid month
		.main-dashboard-employee-paid-month {
			box-shadow: 0px 10px 20px rgba(174, 182, 183, 0.25);
			border-radius: 16px;
			background: #ffffff;
			height: 299px;
			min-height: 299px;
			width: 100%;
			padding: 16px 13px 16px 16px;
			.main-dashboard-payrol-icon {
				background: #f8d4c0;
				height: 32px;
				width: 32px;
				min-width: 32px;
				min-height: 32px;
				border-radius: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: 24px;
					height: 24px;
				}
			}
			.main-dashboard-amount {
				padding-left: 7px;
				padding-top: 5px;
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					color: #00344e;
				}
			}
			.main_dashboard_carousel_container {
				width: 100%;
				padding-top: 5px;
				.employee_paid_current_month_detail_row {
					width: 100% !important;
					display: flex !important;
					align-items: flex-end;
					padding: 0px 0px;
					.employee_paid_month {
						span {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 16px;
							color: $primaryColor5;
						}
					}
					.employee_paid_percentage {
						// text-align: center;
						span {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 24px;
							line-height: 28px;
							color: $greyColor7;
						}
					}
					.low_percentage {
						span {
							color: $secondaryColor3;
						}
					}
					.employee_paid_no_of_employee {
						span {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 10px;
							line-height: 14px;
							color: #00344e;
						}
					}
				}
				.employee_paid_detail_row {
					width: 100% !important;
					display: flex !important;
					align-items: flex-end;
					padding: 2px 0px;
					.employee_paid_month {
						span {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 12px;
							line-height: 14px;
							color: $greyColor7;
						}
					}
					.employee_paid_percentage {
						span {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 20px;
							color: $greyColor7;
						}
					}
					.low_percentage {
						span {
							color: $secondaryColor3;
						}
					}
					.employee_paid_no_of_employee {
						span {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 10px;
							line-height: 14px;
							color: $greyColor6;
						}
					}
				}
			}
			.info {
				display: flex;
				flex-direction: row;
				align-items: center;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 10px;
				line-height: 14px;
				color: #737780;
				padding-top: 5px;

				img {
					width: 20px;
					height: 20px;
				}
			}
			.main-dashboard-view-all {
				display: flex;
				justify-content: center;
				align-items: center;
				padding-top: 14px;
				button {
					width: 88px;
					min-width: 88px;
					height: 32px;
					min-height: 32px;
					font-family: Montserrat-Light;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $primaryColor5;
					padding: 0px 0px;
					border: 1px solid #eff1f5;
				}
			}
		}
		// threshold charts
		.main-dashboard-threshold-chart {
			height: 272px;
			min-height: 272px;
			width: 100%;
			background: #f7f8fa;
			border-radius: 16px;
			margin: 16px 0px;
			padding: 15px;
			.gauge_chart_container {
				margin: 0px 0px;
				.percentage_text {
					position: absolute;
					top: 20px;
					// padding-right: 17px;
					text-align: center;
					width: 100%;
				}
				.label_text {
					padding-top: 5px;
					position: absolute;
					top: 40px;
					// padding-right: 17px;
					text-align: center;
					width: 100%;
				}
			}
		}
		// email-support
		.main-dashboard-email-support {
			text-align: center;
			display: flex;
			justify-content: center;
			width: 100%;
			padding: 10px 0px;
			.queries {
				span {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					text-align: center;
					color: #00344e;
				}
			}
			.email {
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					text-align: center;
					color: #00344e;
				}
			}
		}
		.ant-carousel .slick-dots-bottom {
			bottom: 0px;
		}
	}
}

@media only screen and (max-width: 320px) {
	.main-dashboard-mobile
		.main-dashboard-history-payroll-card
		.main-dashboard-carousel-container
		.main-dashboard-Carousel
		.main-dashboard-carosel-content
		.wps-month {
		font-family: Montserrat-Medium;
		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		line-height: 18px;
		color: #00344e;
	}
	.main-dashboard-mobile
		.main-dashboard-history-card-order
		.main-dashboard-carousel-container
		.main-dashboard-Carousel
		.main-dashboard-carosel-content
		.wps-month {
		font-family: Montserrat-Medium;
		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		line-height: 18px;
		color: #00344e;
	}
	.main-dashboard-threshold-chart {
		height: 272px;
		min-height: 272px;
		width: 100%;
		background: #f7f8fa;
		border-radius: 16px;
		margin: 16px 0px;
		padding: 15px;
		.gauge_chart_container {
			margin: 0px 0px;
			.percentage_text {
				position: absolute;
				top: 20px;
				// left: 8px;
				// padding-right: 9px;
				text-align: center;
				width: 100%;
			}
			.label_text {
				padding-top: 5px;
				position: absolute;
				top: 40px;
				// padding-right: 9px;
				// left: 8px;
				text-align: center;
				width: 100%;
			}
		}
	}
}
