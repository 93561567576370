.update_employees_container {
	padding: 0px 40px 40px 40px;
	overflow-x: hidden;
	align-self: flex-start;
	padding: 40px;
	.update_employees_container_col_1 {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-top: 24px;
	}
	.update_employees_container_col_2 {
		display: flex;
		justify-content: space-between;
	}
	.update_employees_container_col_3 {
		display: grid;
		gap: 16px;
	}
	.download_sheet_text_content {
		display: grid;
		gap: 8px;
	}
	.upload_sheet_text_content {
		display: grid;
		gap: 8px;
		.upload_sheet_desc {
			line-height: 24px;
		}
	}
	.white_outer {
		margin-top: 24px;
		background: #ffffff;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 32px;
	}
}

.employee_preview_container {
	padding: 0px 40px 40px 40px;
	overflow-x: hidden;
	align-self: flex-start;
	padding: 40px;
	.employee_preview_container_col_1 {
		display: flex;
		justify-content: space-between;
		.employee_preview_container_col_1_header {
			display: grid;
			gap: 8px;
		}
		.employee_preview_container_col_1_btns {
			display: flex;
			gap: 8px;
		}
	}
	.employee_preview_container_col_2 {
		.employee_table_header_container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 16px;
			.edenRedSearch {
				width: 165%;
			}
			.show_entries {
				display: flex;
				align-items: center;
				gap: 10px;
			}
		}
		.no_data_found {
			display: flex;
			justify-content: center;
		}
	}
	.white_outer {
		margin-top: 24px;
		background: #ffffff;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		border-radius: 8px;
		padding: 32px;
	}
}
