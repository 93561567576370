@import '../../../constants/_variables.scss';
.ant-layout-content {
	margin: 0px !important;
	.view_all_wrapper {
		width: 100%;
		align-self: flex-start;
		// padding: 0px 40px  0px 40px;

		.view_all_header {
			width: 100%;
			justify-content: center;
			// display: flex;
			// align-items: flex-end;
			// padding: 0px 5vw 0px 5vw;
			// .header_font {
			// 	font-family: Montserrat-Bold;
			// 	font-style: normal;
			// 	font-weight: bold;
			// 	font-size: 24px;
			// 	line-height: 28px;
			// 	color: $primaryColor5;
			// }
		}
		.header_text {
			// height: 7vh;
			// width: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			// background-color: red;
			// padding: 0px 5vw 0px 5vw;
			.header_font {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				color: $primaryColor5;
				width: 100%;
			}
		}
		.header_container_alignments {
			padding-left: 1%;
			padding-right: 2%;
			padding-top: 40px;
		}
		.view_all_video_container {
			height: 70vh;
			width: 100%;
			overflow: auto;
			display: flex;
			flex-wrap: wrap;
			margin-top: 20px;
			padding: 0px 40px 0px;

			.video_wrapper {
				height: 100%;
				width: 100%;
				cursor: pointer;

				.main_video_container {
					background-color: #ffffff;
					box-shadow: 0px 16px 32px -24px #bdbdbd;
					border-radius: 8px;
					padding: 32px;
					padding-top: 0px;
					.video_and_title_holder {
						height: 28vh;
						width: 100%;
						padding-right: 15px;

						.video_holder {
							height: 22vh;
							width: 100%;
							background-color: white;

							.viewall_img_container {
								min-width: 100%;
								height: 22vh;
								object-fit: contain;
							}
						}
						.title_holder {
							height: 6vh;
							width: 100%;
							span {
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 400;
								font-size: 14px;
								line-height: 16px;
								color: #00344e;
								padding-top: 10px;
							}
						}
					}
				}
			}
		}
	}
	.title_holder span:hover {
		text-decoration: underline;
	}
	@media (max-height: 649px) {
		.video_and_title_holder {
			height: 35vh !important;
		}
	}

	@media (min-width: 700px) and (max-width: 1200px) {
		.video_and_title_holder {
			height: 35vh !important;
		}
	}
}
