@import '../../../constants/variables';

.multi-select-dropdown-component {
	width: 100%;
	height: fit-content;
	.multiSelect_dropdown_label {
		font-family: 'Montserrat-Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 10px;
		line-height: 12px;
		color: $grayscale-900;
		margin-bottom: 8px;
		display: flex;
	}

	.multiSelect-dropdown-container {
		width: 100%;
		position: relative;
		.dropdown_header {
			width: 100%;
			cursor: pointer;
			.tooltip {
				width: 100%;
				.tooltipup {
					left: 17%;
				}
			}

			.dropdown_disabled {
				background: transparent;
				cursor: not-allowed;
				.dropdown_placeHolder {
					color: $grayscale-300 !important;
				}
				.dropdown_arrow {
					img {
						opacity: 0.2;
					}
				}
			}
			.dropdown_inner {
				-webkit-transition: 0.5s;
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				height: 32px;
				padding: 8px 12px;
				background: $neutral-white;
				border-radius: 8px;
				min-height: 32px;
				position: relative;
				.dropDown-menu {
					display: flex;
					align-items: center;
					// flex-wrap: wrap;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					gap: 10px;
					.selectCount {
						font-family: 'Montserrat-bold';
						font-style: normal;
						font-weight: 500;
						font-size: 12px;
						color: $grayscale-900;
						margin: 2px;
					}
					.dropdown_default_bg {
						background: $grayscale-200;
					}
					.dropdown_status_icon_flex {
						display: flex;
						align-items: flex-start;
					}
					.dropdown_default {
						width: 100%;
						border-radius: 4px;
						margin-right: 10px;
						margin: 2px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						.dropdown_status_icon {
							width: 6px;
							height: 6px;
							border-radius: 100%;
							background: #8ef9b3;
							margin-left: 4px;
						}
						.dropdown_inner_list_main {
							font-family: 'Montserrat-Medium';
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							color: $grayscale-900;
							line-height: 14px;

							&.select_active {
								color: $grayscale-900;
							}

							&.select_inactive {
								color: $grayscale-500 !important;
							}
						}
						.dropdown_none {
							font-family: 'Montserrat-Medium';
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							line-height: 14px;
							color: $grayscale-500;
						}
						.dropdown_inner_list_sub {
							font-family: 'Montserrat-light';
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							color: $grayscale-500;
							line-height: 14px;
							margin-left: 8px;
							margin-right: 8px;
						}
					}

					.dropdown_placeHolder {
						font-family: 'Montserrat-Light';
						font-style: normal;
						font-weight: 500;
						font-size: 12px;
						line-height: 14px;
						color: $grayscale-500;
					}
				}
			}

			.dropdown_inner_image {
				height: 18px;
				width: 18px;
			}

			.dropdown_arrow {
				.arrow-inner {
					height: 1.5em;
					width: 1.5em;
				}

				.dropdown-clear {
					font-family: 'Montserrat-bold';
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					color: $blue-400;
					text-decoration-line: underline;
				}

				.dropdown_loader {
					border: 2px solid #dfe1e6;
					border-top: 2px solid transparent;
					border-radius: 15px;
					width: 16px;
					height: 16px;
					animation: spin 1s linear infinite;
				}

				@keyframes spin {
					0% {
						transform: rotate(0deg);
					}

					100% {
						transform: rotate(360deg);
					}
				}
			}

			.dropdown {
				width: 100%;
				border: none;
				outline: none;
				text-decoration: none;
			}

			.dropdown_list {
				position: absolute;
				z-index: 2;
				margin-top: 4px;
				margin-left: 2.5px;
				// height: auto;
				height: 160px;
				box-shadow: 0px 16px 32px -24px $gray;
				background: $neutral-white;
				border-radius: 8px;
				overflow: auto;
				max-height: 11em;

				.dropdown_inner_list {
					overflow: auto;

					&::-webkit-scrollbar {
						width: 2px;
					}
				}

				.dropdown_list_search_main {
					width: 100%;
					height: 32px;
					border: 1px solid $grayscale-400;
					border-radius: 8px;
					padding: 9px 12px;
					display: flex;
					align-items: center;
					-webkit-transition: 0.5s;
					position: sticky;
					top: 0;
					background-color: $neutral-white;

					input {
						color: $grayscale-900;
						font-family: 'Montserrat-Regular';
						font-weight: 500;
						font-size: 12px;
						line-height: 14px;
						padding-left: 9.31px;

						&::placeholder {
							color: $grayscale-500;
							font-family: 'Montserrat-light';
							font-size: 12px;
							line-height: 14px;
						}
					}
				}

				.dropdown_list_search_main:focus-within {
					border: 1px solid $blue-100;
				}

				.dropdown-list-sub {
					display: flex;
					justify-content: space-between;
					width: 100%;
					.dropdown_inner_list {
						display: flex;
						padding: 8px 10px 8px 8px;
						cursor: pointer;

						.dropdown_inner_list_main {
							font-family: 'Montserrat-Medium';
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							color: $grayscale-900;
							line-height: 14px;
							margin-right: 8px;

							&select_active {
								color: $grayscale-900;
							}

							&select_inactive {
								padding: 30px;

								color: $grayscale-500 !important;
							}
						}
						.inactive_option {
							color: #dfe1e6 !important;
						}
						.dropdown_inner_list_sub {
							display: flex;
							align-items: flex-start;
							font-family: 'Montserrat-light';
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							line-height: 14px;
							.option_status {
								width: 6px;
								height: 6px;
								border-radius: 100%;
								background: #8ef9b3;
								margin-left: 4px;
							}
						}
					}

					img {
						padding-right: 8px;
					}
				}
			}
		}
	}
}
.dropdown-inner-list-sub-data {
	color: $grayscale-500;
}
.dropdown-inner-list-sub-due-data {
	color: #181919;
}
