@mixin bank {
	height: 100%;
	width: 100%;
	display: flex;
}
@mixin bordered($fontfamily, $font, $weight, $lineheight, $letterspacing, $textalign, $size) {
	font-family: $fontfamily;
	font-style: $font;
	font-weight: $weight;
	line-height: $lineheight;
	letter-spacing: $letterspacing;
	text-align: $textalign;
	font-size: $size;
}
.card-holder-banknumber {
	@include bank;
	.main-box {
		@include bank;
		margin: auto;
		max-width: 1200px;
		align-self: center;
		.cardholder-card {
			align-self: center;
			.card-name {
				@include bordered('Montserrat-bold', normal, '700', '28px', '0em', 'left', 24px);
				margin-bottom: 24px;
			}
			.card-title {
				@include bordered('Montserrat-regular', normal, 300, 16px, '0em', 'left', 14px);
				margin-top: 24px;
				color: #484b52;
			}
			.text-box {
				margin-top: 32px;
				margin-bottom: 32px;
				border-radius: 8px;
				height: 48px;

				.ant-select {
					width: 100%;
				}
				.ant-select-selector {
					border-radius: 8px;
					height: 48px;
					padding-top: 7px;
				}
			}
		}
	}
	.bank-dropdown-select{
		.ant-select {
			caret-color: transparent;
		}
	}
}
