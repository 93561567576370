@import './../../../../constants/variables';
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	width: 0px !important;
}
.web-View-leave {
	.image-row {
		margin-bottom: 0px;
	}
	.modal-desc {
		margin-top: 0px;
	}
}
.leaveStatusContainer {
	display: flex;
	justify-content: center;
	width: 100%;
	align-content: baseline;
	overflow: scroll;
	// height: 649px;
	margin-bottom: 20px;
	&::-webkit-scrollbar {
		width: 3px;
	}
	.edit {
		font-size: 14px;
		color: #00344e;
		line-height: 16px;
	}
	.view-leave-editbotton {
		.for-button-padding {
			padding: 6px 16px !important;
			font-family: 'Montserrat-Regular';
			font-size: 14px;
			line-height: 16px;
			font-weight: 300;
			color: #00344e;
			.for-edit-button {
				margin-bottom: 0px;
				padding-left: 5px;
			}
		}
	}
	.edenRedButton {
		min-height: 0px;
	}
}
.forMobileEmpLeave {
	.ant-table-header {
		background-color: #f7f8fa;
		height: 46px;
		padding-bottom: 2px;
	}
}
.leaveStatusContainer {
	.leaveStatus {
		font-family: 'Montserrat-Bold';
		font-size: 24px;
		color: #00344e;
		font-weight: 700;
		margin-top: 12px;
		margin-bottom: 12px;
	}
	.departDetails-history {
		.leavehistory-text {
			font-size: 12px;
			font-family: 'Montserrat-Medium';
		}
	}
	.attachments {
		cursor: pointer;
		text-decoration: underline;
		color: rgb(43, 118, 156);
		.leavehistory-text {
			font-size: 12px;
			font-family: 'Montserrat-Medium';
		}
	}
	.attachments1 {
		cursor: not-allowed;
	}
	.leaveBalance {
		font-family: 'Montserrat-Bold';
		font-size: 16px;
		line-height: 18px;
		color: #00344e;
		margin-top: 24px;
		margin-bottom: 0px;
	}
	.leaveRow {
		background-color: #f7f8fa;
		background: #ffffff;
		border-radius: 8px;
		padding: 32px;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		.personalRowNew {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.leaves_status_details {
			margin-top: 24px;
			width: 100%;
			row-gap: 16px !important;
			.leaveBalanceContainer {
				display: flex;
				flex-wrap: wrap;
				width: 33%;
				row-gap: 16px;
				.leaveType {
					font-family: 'Montserrat-Medium';
					font-weight: 500;
					font-size: 14px;
					color: #00344e;
					line-height: 16px;
					margin-bottom: 0px;
				}
				.Remainings {
					font-family: 'Montserrat-Light';
					font-weight: 300;
					font-size: 10px;
					color: #737780;
					opacity: 0.8;
					line-height: 16px;
					margin-bottom: 12px;
				}
				.Remainings-unpaid {
					font-family: 'Montserrat-Light';
					font-weight: 300;
					font-size: 10px;
					color: #f7f8fa;
					opacity: 0.8;
					line-height: 16px;
					margin-bottom: 12px;
				}
				.forDays {
					font-family: 'Montserrat-Bold';
					font-size: 18px;
					line-height: 20px;
					color: #2b769c;
					opacity: 0.8;
					margin-bottom: 0px;
				}
				.leave_border {
					height: 40px;
					width: 40px;
					background: $grayscale-200;
					border-radius: 8px;
					padding: 8px;
					margin-right: 8px;
				}
				.forAlignments {
					display: flex;
					align-items: center;
					width: 100%;
					padding-right: 32px;
				}
				.forAlignments1 {
					opacity: 0.6;
					display: flex;
					align-items: center;
					width: 100%;
					padding-right: 32px;
				}
				.forPadding {
					display: flex;
					flex-direction: column;
				}
			}
		}
	}
	.forLeaveHistory {
		font-family: 'Montserrat-Bold';
		font-size: 16px;
		color: #00344e;
		font-weight: 700;
		margin-top: 12px;
		margin-bottom: 12px;
		padding-left: 2%;
	}

	.leaveHistory {
		.ant-table {
			width: 100%;
			box-shadow: 0px 3px 10px rgba(174, 182, 183, 0.5);
			border-radius: 10px;
		}
	}
	.leaveStatusContainer {
		display: flex;
		width: 100%;
		height: fit-content;
		background: $neutral-white;
		border-radius: 8px;
		padding: 32px;
		margin-top: 25px;
		box-shadow: 0px 16px 32px -24px #bdbdbd;
		.leaveHistoryTop {
			.view-more-text {
				display: flex;
				padding-top: 8px;
				justify-content: flex-end;
				text-decoration: underline;
				color: #0e5274;
				cursor: pointer;
			}
			.edenred-table {
				margin-top: 16px;
				thead {
					tr {
						th {
							padding: 17px 16px;
							background: $neutral-white;
							font-family: 'Montserrat-Medium';
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							line-height: 14px;
							color: $blue-500;
						}
					}
				}
				tbody {
					tr {
						td {
							padding: 17px 16px;
							word-break: break-all;
						}
					}
				}
			}
			// .leave_history_status_icon {
			.approvalColor {
				background: $feedback-success !important;
				border-radius: 4px;
				width: 8px;
				height: 8px;
			}
			.approvalColorReject {
				background: $feedback-error !important;
				border-radius: 4px;
				width: 8px;
				height: 8px;
			}
			// }
		}
	}
	.forColorsSummary {
		height: 32px;
		color: #fff7e8;
	}

	.viewMore {
		display: flex;
		justify-content: flex-end;
	}
	.viewMore {
		.forViewMore {
			font-family: 'Montserrat-Regular';
			font-weight: 500;
			font-size: 10px;
			color: #00344e;
			line-height: 14px;
			margin-bottom: 0px;
			margin-top: 16px;
		}
		.downArrow {
			height: 30.03px;
			width: auto;
			margin-top: 8px;
		}
	}
	.SecondContainer {
		width: 100%;
		margin-top: 12px;
	}
	.leaveHistory {
		font-family: 'Montserrat-Bold';
		font-size: 24px;
		color: #00344e;
		font-weight: 700;
		margin-top: 12px;
		margin-bottom: 12px;
	}
}
.emphistory {
	.ant-table-thead > tr > th {
		font-family: 'Montserrat-Bold';
		font-size: 12px;
		line-height: 14px;
		color: #00344e;
	}
}
.emphistory {
	.nameDetail {
		font-family: 'Montserrat-Medium';
		line-height: 14px;
		font-size: 12px;
		color: #00344e;
		font-weight: 500;
		padding-left: 12px;
	}
}
.emphistory {
	.ant-table-container table > thead > tr:first-child th:first-child {
		display: flex;
		justify-content: flex-start;
		padding-left: 20px;
	}
	.ant-table-header {
		background-color: #f7f8fa;
		height: 46px;
		box-shadow: 0px 2px 5px rgba(174, 182, 183, 25%);
		padding-bottom: 2px;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
	}
	.ant-table-body {
		margin-top: 0px;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		background-color: #f7f8fa;
		box-shadow: none;
	}
	.ant-table {
		width: 100%;

		border-radius: 0px;
	}
}

@media screen and (max-width: 600px) {
	.web-View-leave {
		display: none;
	}
}
@media screen and (min-width: 600px) {
	.mob-view-leave {
		display: none;
	}
}
