.add-new-signatory-container {
	width: 100%;
	padding: 0px 135px 0px 135px;
	display: flex;
	align-items: center;
	.add-new-row {
		width: 100%;
		padding-top: 10px;
		.signatory {
			span {
				font-family: Montserrat-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				color: #00344e;
			}
		}
		.card-owner {
			padding-top: 32px;
			.no-data {
				span {
					font-family: Montserrat-Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 20px;
					line-height: 24px;
					color: #00344e;
				}
			}
			.card-add-new-signatory {
				background: #ffffff;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				border-radius: 24px;
				margin-top: 8px;
				.add {
					height: 80px;
					min-height: 80px;
					display: flex;
					align-items: center;
					width: 100%;
					.username {
						padding-left: 24px;
						span {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 20px;
							line-height: 24px;
							color: #00344e;
						}
					}
					.button-delete {
						button {
							height: 32px;
							min-height: 32px;
							width: 93px;
							padding: 8px 24px;
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 14px;
							line-height: 16px;
						}
					}
					.owner-button {
						button {
							height: 48px;
							min-height: 48px;
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 20px;
							width: 334px;
							max-width: 334px;
						}
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 600px) {
	.add-new-signatory-container {
		padding: 64px 10px 0px 10px;
		width: 100%;
	}
}
