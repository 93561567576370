@import '../../../constants/_variables.scss';

.proof_of_transfer_presentational_container {
	width: 100%;
	align-self: flex-start;

	.Proof-of-Transfer-presentational {
		width: 100%;
		padding: 0px 40px 40px 40px;

		.proof_of_find_transfer_header {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: sticky;
			top: 0%;
			z-index: 1;
			background: $grayscale-200;
			padding: 40px 0px 28px 0px;
			height: 100px;
			.buttonnew {
				width: 87px;
			}
		}

		.proof_of_find_transfer_tab {
			.ant-tabs-nav {
				margin: 0px;
				background: $neutral-white;
				border-radius: 8px;
				padding: 16px;

				.ant-tabs-nav-wrap {
					.ant-tabs-nav-list {
						.ant-tabs-tab {
							padding: 8px 16px;
							height: 32px;
							border: none;
							background: $neutral-white;
							border-width: 1px;
							border-style: solid;
							border-color: #dfe1e6;
							margin: 0px;

							&:nth-child(1) {
								width: 126px;
								border-radius: 8px 0px 0px 8px;
							}

							&:nth-child(2) {
								width: 183px;
								border-radius: 0px 8px 8px 0px;
							}
							.ant-tabs-tab-btn {
								font-family: 'Montserrat-Medium';
								font-style: normal;
								font-weight: 500;
								font-size: 14px;
								line-height: 16px;
								color: $grayscale-700;
							}
						}

						.ant-tabs-tab-active {
							background: linear-gradient(93.76deg, #d10f00 1.98%, #f72717 100%);
							border: none;
							.ant-tabs-tab-btn {
								color: $neutral-white;
								font-size: 14px;
								line-height: 16px;
							}
						}

						.ant-tabs-ink-bar {
							display: none;
						}
					}
				}
			}

			.upload_proof_of_fund_transfer {
				margin-top: 24px;
				padding: 32px;
				background: $neutral-white;
				box-shadow: 0px 16px 32px -24px #bdbdbd;
				border-radius: 8px;

				.proof_of_fund_text {
					font-size: 18px;
				}

				.dragger_submit {
					padding-top: 16px;

					.dragger_row {
						width: 100%;
						padding: 0%;

						img {
							width: 100px;
							height: 100px;
						}
					}

					.whole {
						padding: 0px 0px;

						.click_here {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 20px;
							color: #00344e;
						}

						.upload-type {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 16px;
							line-height: 18px;
							color: #00344e;

							.max-file {
								font-size: 12px;
								line-height: 14px;
							}
						}
					}

					.click_here_mobile_view {
						display: none;
					}

					.ant-upload-list {
						justify-content: center;
						display: flex;
						position: absolute;
						bottom: 47px;
						z-index: 10;
						width: 100%;

						.ant-upload-list-item-name {
							font-family: Montserrat-Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							line-height: 14px;
							text-align: center;
							color: #00344e;
							white-space: pre-line;
						}
					}

					.ant-upload-list-text .ant-upload-text-icon .anticon {
						display: none;
					}

					.ant-upload-list-item-card-actions .anticon {
						color: black;
					}

					.ant-upload.ant-upload-drag {
						text-align: center;
						border: 2px dashed #d9d9d9;
						background: white;
						border-radius: 40px;
						height: 221px;
					}
				}
			}

			.fund_transfer_history {
				margin-top: 24px;
				padding: 32px;
				background: $neutral-white;
				box-shadow: 0px 16px 32px -24px #bdbdbd;
				border-radius: 8px;
			}

			.cheques_mobile_view {
				display: none;
			}

			.ProofOfTransfer_payment {
				display: flex;
				flex-direction: row;
				margin-top: 24px;
				align-items: center;

				.data_slip {
					padding: 0px 0px 0px 24px;

					.Payroll_slip {
						display: flex;
						flex-direction: row;
						width: 95%;
						padding-top: 12px;
						padding-bottom: 2%;

						.payroll_slip_data_date {
							padding-right: 16px;

							.ant-select {
								display: flex;
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 18px;
								line-height: 20px;
								height: 52px;
								min-height: 52px;
								color: $primaryColor5;

								// width: 119px;
								.ant-select-selector {
									// background: pink;
									display: flex;
									align-items: center;
									height: 52px;
									width: 100%;
									border: none;
									border-radius: 16px;
									box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
								}

								.ant-select-selection-item {
									padding-right: 18px;
									display: flex;
									justify-content: space-around;
								}

								.ant-select-arrow {
									display: flex;
									align-items: center;
									justify-content: center;
									font-size: 20px;
									color: #11266900;
									content: url('../../../assets/icons/not_focus/Down.svg');
									height: 28px;
									width: 28px;
									margin-top: -15px;
									margin-right: 17px;
								}

								.ant-select-item {
									font-family: 'Montserrat-Medium';
									font-size: 14pt;
									font-weight: 500;
								}
							}
						}

						.payroll_slip_data {
							padding-right: 16px;

							.ant-select {
								display: flex;
								font-family: Montserrat-Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 18px;
								line-height: 20px;
								height: 52px;
								min-height: 52px;
								color: $primaryColor5;

								.ant-select-selector {
									display: flex;
									align-items: center;
									justify-content: center;
									height: 52px;
									width: 100%;
									border: none;
									border-radius: 16px;
									box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
								}

								.ant-select-arrow {
									display: flex;
									align-items: center;
									justify-content: center;
									font-size: 20px;
									color: #11266900;
									content: url('../../../assets/icons/not_focus/Down.svg');
									height: 28px;
									width: 28px;
									margin-top: -15px;
								}

								.ant-select-item {
									font-family: 'Montserrat-Medium';
									font-size: 14pt;
									font-weight: 500;
								}
							}
						}
					}

					.payroll_slip_mobile_view_section {
						display: none;
					}
				}
			}

			.cheques {
				padding-top: 24px;
			}

			.fund_transfer_tab2 {
				padding-left: 16px;
				width: 100%;
				margin: 24px 0px 0px 0px;
				border-radius: 8px;
				// box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);

				.card_history {
					background: $neutral-white;
					box-shadow: 0px 16px 32px -24px #bdbdbd;
					border-radius: 8px;
					padding: 24px 32px 24px 0px;
					cursor: pointer;
					border: none;

					.ant-card-body {
						padding: 0px;

						.edencard_web_view_proof_of_transfer {
							align-items: center;
							justify-content: space-between;
							.proof_history_left_card {
								padding-left: 24px;
								width: 70%;
								display: flex;
								align-items: center;
								.proof_history_image {
									display: flex;
									align-items: center;
									justify-content: center;
									width: 48px;
									height: 48px;
									background: $grayscale-200;
									border-radius: 8px;
									margin: 0px 24px 0px 0px;
									padding: 8px;

									img {
										width: 32px;
									}
								}
							}
						}
					}
				}
			}

			.ant-tabs > .ant-tabs-nav,
			.ant-tabs > div > .ant-tabs-nav {
				position: inherit;
			}

			.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
				font-family: Montserrat-Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 18px;
				text-align: center;
				color: $secondaryColor5;
			}

			.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
				padding: 0px 35px 2px 35px;
				border-bottom: 2px solid $greyColor4;
				font-family: Montserrat-Regular;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 18px;
				text-align: center;
				color: $greyColor5;
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.proof_of_transfer_presentational_container {
		width: 100%;
		align-self: flex-start;

		.Proof-of-Transfer-presentational {
			width: 100%;
			padding: 0px 40px 40px 40px;

			.fund_transfer_history {
				overflow-y: unset;
				height: unset;
			}

			.upload {
				span {
					font-size: 20px;
					line-height: 24px;
					font-weight: 700;
				}
			}

			.cheques_mobile_view {
				display: flex !important;
			}

			.cheques {
				display: none !important;
			}

			.dragger_submit {
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
				width: 60%;
				border-radius: 16px;
				margin: 10px 65px 10px 65px;

				.ant-upload-list {
					justify-content: center;
					display: flex;
					position: absolute;
					bottom: 20px;
					width: 100%;
				}

				.ant-upload-list-item-name {
					color: black;
				}

				.anticon svg {
					display: none;
				}

				.ant-upload-list-item-info {
					padding: 1%;
				}

				.dragger_row {
					padding: 0%;
				}

				.ant-upload.ant-upload-drag {
					background: none;
					box-shadow: none;
					border: none;
				}

				.ant-upload.ant-upload-drag {
					text-align: center;
					background: white;
					border-radius: 40px;
					height: 180px;
				}

				.whole {
					font-family: Montserrat-Regular;
					font-style: normal;
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					text-align: center;
					color: #00344e;
					display: flex;
					flex-direction: column;
					justify-content: center;
					width: 100%;
					color: $primaryColor5;

					.click_here_mobile_view {
						display: flex !important;
						justify-content: center;
						padding-bottom: 8px;
						font-family: Montserrat-Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 16px;
						color: #00344e;
					}

					.click_here {
						display: none !important;
					}

					.upload-type {
						font-family: Montserrat-Regular;
						font-style: normal;
						font-weight: 300;
						font-size: 12px;
						line-height: 14px;
						color: #00344e;

						.max-file {
							font-family: Montserrat-Regular;
							font-style: normal;
							font-weight: 300;
							font-size: 12px;
							line-height: 14px;
							color: #00344e;
						}
					}
				}

				.ant-upload.ant-upload-drag .ant-upload {
					padding: 0px 0;
				}
			}

			.ProofOfTransfer_payment {
				display: flex;
				flex-direction: column;
				padding: 0% 0%;
				align-items: center;

				.Payment_amount {
					padding-top: 7%;
				}

				.data_slip {
					padding: 0% 0%;

					.Payroll_slip {
						display: none;
					}

					.payroll_slip_mobile_view_section {
						display: flex !important;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						height: 55px;
						width: 100%;
						padding-top: 2%;
						padding-bottom: 2%;

						.payroll_slip_select_date {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: center;
							padding: 4%;
							height: 100%;
							background: #ffffff;
							box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
							border-radius: 16px;

							span {
								font-size: 11pt;
								font-weight: 900;
								line-height: 12pt;
								color: $primaryColor5;
							}

							img {
								width: 18px;
								height: 24px;
							}
						}

						.payroll_slip_select_month {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: center;
							padding: 3%;
							height: 100%;
							background: #ffffff;
							box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
							border-radius: 16px;

							span {
								font-size: 11pt;
								font-weight: 900;
								line-height: 12pt;
								color: $primaryColor5;
							}

							img {
								width: 18px;
								height: 24px;
							}
						}

						.payroll_slip_select_year {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: center;
							padding: 4%;
							height: 100%;
							background: #ffffff;
							box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);
							border-radius: 16px;

							span {
								font-size: 11pt;
								font-weight: 900;
								line-height: 12pt;
								color: $primaryColor5;
							}

							img {
								width: 18px;
								height: 24px;
							}
						}
					}
				}
			}

			.submit_button {
				padding: 12px 0px 24px 0px;
			}

			.fund_transfer_tab2 {
				padding-left: 8px;
				width: 96%;
				margin-top: 4px;
				border-radius: 18px;
				box-shadow: 0px 5px 10px rgba(174, 182, 183, 0.25);

				.card_history {
					border-radius: 18px !important;
					padding: 8px !important;

					.ant-card-body {
						padding: 4px !important;
					}
				}
			}

			.ant-tabs-tab {
				margin: 0px 24px 0px 0px;
			}

			.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
				font-size: 14px;
				line-height: 18px;
			}

			.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
				padding: 0px 10px 2px 10px;
				font-size: 14px;
				line-height: 18px;
			}
		}
	}
}

.month_modal_button {
	padding: 3px 8px;
	min-height: 26pt;
	display: flex;
	justify-content: space-evenly;
	font-size: 14px;
	font-weight: 300;
	color: $primaryColor4;
	border: 1px solid $greyColor4;
	box-sizing: border-box;
	background: none !important;
	box-shadow: none !important;
}

.date_modal_button {
	border-radius: 50%;
	border-radius: 60%;
	padding: 0;
	width: 35px;
	min-height: 35px;
}

.isSelected {
	background: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%) !important;
	color: white;
}

.date_modal {
	.icon_area {
		display: flex;
		justify-content: center;
		align-items: center;

		.left_arrow {
			width: 20px;
			transform: rotate(90deg);
		}

		.right_arrow {
			width: 20px;
			transform: rotate(-90deg);
		}
	}
}

@media only screen and (max-width: 400px) {
	.proof_of_transfer_presentational_container .Proof-of-Transfer-presentational {
		.dragger_submit {
			width: 100%;
			margin: 0px;
		}

		.proof_of_find_transfer_tab {
			.ant-tabs-tab-btn {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100px;
			}

			.ProofOfTransfer_payment {
				flex-direction: column;

				.data_slip {
					margin-top: 20px;
					padding: 0px;
					width: 217px;
				}
			}
		}
	}
}

@media only screen and (min-width: 770px) and (max-width: 1200px) {
	.proof_of_transfer_presentational_container
		.Proof-of-Transfer-presentational
		.ProofOfTransfer_payment
		.data_slip
		.Payroll_slip
		.payroll_slip_data_date
		.ant-select
		.ant-select-arrow {
		margin-right: 7px;
	}
}
