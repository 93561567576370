.company-search-dropdown-container {
	cursor: pointer;
	img {
		height: 26px;
		position: absolute;
		top: 30%;
		left: 0;
		z-index: 2;
	}
	.company-search-dropdown {
		width: -webkit-fill-available;
		.ant-select-selector {
			box-shadow: none !important;
			outline: none;
			border: none !important;
			.ant-select-selection-item {
				box-shadow: none;
				outline: none;
				border: none;
				font-size: 16px;
				font-family: Montserrat-Medium;
				color: #00344e;
				cursor: pointer;
				// input {
				//  font-size: 16px;
				//  font-family: Montserrat-Medium;
				// }
				.ant-input-prefix {
					span {
						svg {
							font-size: 15px !important;
						}
					}
				}
			}
		}
		&.ant-select-show-search {
			padding-left: 19px;
			font-size: 16px;
			font-family: Montserrat-Medium;
			color: #00344e;
		}
	}

	.company-search-dropdown-text-area {
		display: flex;
		align-items: center;
		cursor: pointer;
		.value {
			padding-left: 31px;
			font-size: 16px;
			font-family: Montserrat-Medium;
			color: #00344e;
		}
		.company-id {
			padding-left: 15px;
		}
	}
}

.company-id {
	display: inline-block;
	font-family: Montserrat-Regular;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 18px;
	color: #9a9ea6 !important;
}

.company-search-dropdown-list {
	border-radius: 16px !important;
	.company-id {
		padding-left: 10px;
	}
	.ant-select-item-option {
		display: flex;
		align-items: center;
		padding: 18px 0 !important;
		margin-left: 24px !important;
		margin-right: 24px !important;
		&:last-child {
			border: none !important;
		}
	}
	.ant-select-item-option::before {
		display: inline-block;
		width: 26px;
		height: 26px;
		margin-right: 5px;
		content: '';
		background: url('../../../assets/icons/not_focus/EmptyBox.svg') no-repeat 0 0;
		background-size: 100%;
	}
	.ant-select-item-option-selected::after {
		display: none !important;
	}
	.ant-select-item-option-selected::before {
		display: inline-block;
		width: 24px;
		height: 24px;
		margin-right: 5px;
		content: '';
		background: url('../../../assets/icons/focus_state/Check.svg') no-repeat 0 0;
		background-size: 100%;
	}
}
